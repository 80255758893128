import { Form } from 'common-front/src/components/form/form';
import { Page } from 'common-front/src/components/page/page';
import { Button } from 'common-front/src/designSystem/components/button';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EventWeezeventInput, FieldProperty } from 'common/src/generated/types';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import {
    useEventWeezeventUpdateMutation,
    useWeezeventV2InfosQuery
} from '../../generated/graphqlHooks';

const EXCLUDED_CUSTOM_FIELDS = [
    FieldProperty.Eid,
    FieldProperty.FirstName,
    FieldProperty.LastName,
    FieldProperty.Population
];

export const WeezeventV2 = () => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { event } = useEventContext();
    useTitle(`${event.name} Weezevent`);
    const { data } = useWeezeventV2InfosQuery({ organizationId, eventId });
    const { mutate } = useEventWeezeventUpdateMutation();
    const customFieldsOptions = React.useMemo(() => {
        const customFields = sortBy(
            data.organization?.customFields.nodes ?? [],
            (cf) => cf.name
        ).filter((cf) => !EXCLUDED_CUSTOM_FIELDS.includes(cf.slug));

        return customFields.map((cf) => (
            <option key={cf.slug} value={cf.slug}>
                {cf.name}
            </option>
        ));
    }, [data]);
    const segmentsOptions = React.useMemo(() => {
        const folders = data.event?.segmentsFolders ?? [];

        return folders.map((f) => (
            <optgroup key={f.id} label={f.name}>
                {f.segments.map((s) => (
                    <option key={s.id} value={s.id}>
                        {s.name}
                    </option>
                ))}
            </optgroup>
        ));
    }, [data]);

    return (
        <Page.Root>
            <Page.Header>
                <Box font="gray900 displayXs medium">Weezevent</Box>
            </Page.Header>

            <Page.Content>
                <Form
                    initialValues={{
                        customFieldsSlugs: data.event?.weezevent?.customFieldsSlugs || [],
                        segmentsIds: data.event?.weezevent?.segmentsIds || []
                    }}
                    render={({ handleSubmit, submitting }) => (
                        <Flex direction="column" width={1}>
                            <RichSelect
                                isSearchVisible={true}
                                label={translate('liste_des_champ_73852')}
                                multiple={true}
                                name="customFieldsSlugs"
                            >
                                {customFieldsOptions}
                            </RichSelect>

                            <Spacer height="6" />

                            <RichSelect
                                isSearchVisible={true}
                                label={translate('liste_des_vues_82829')}
                                multiple={true}
                                name="segmentsIds"
                            >
                                {segmentsOptions}
                            </RichSelect>

                            <Spacer height="6" />

                            <Flex>
                                <Button isLoading={submitting} onClick={handleSubmit}>
                                    {translate('enregistrer_06519')}
                                </Button>
                            </Flex>
                        </Flex>
                    )}
                    onSubmit={(values: EventWeezeventInput) =>
                        mutate({
                            eventId,
                            weezevent: values
                        })
                    }
                />
            </Page.Content>
        </Page.Root>
    );
};
