import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Emptyable } from 'common/src/util/emptyable';
import { DocumentContainerElement } from 'common/src/vo/documentBuilder';
import * as React from 'react';
import { useDocumentBuilderContext } from '../documentBuilderContext';

interface IDocumentBuilderLeftPanelAddElementProps {
    container: Emptyable<DocumentContainerElement>;
}

export const DocumentBuilderLeftPanelAddElement = (
    props: IDocumentBuilderLeftPanelAddElementProps
) => {
    const { translate } = useHeavent();
    const { addContainerElement, addImageElement, addTextElement } = useDocumentBuilderContext();

    return (
        <Flex
            css={{
                bd: true
            }}
            direction="column"
        >
            <Flex
                align="center"
                css={{
                    borderBottom: '1px solid $gray200',
                    cursor: 'pointer',
                    padding: '$4 $6'
                }}
                gap="3"
                onClick={() => {
                    addTextElement(props.container);
                }}
            >
                <Flex align="center" height={24} justify="center" width={24}>
                    <I icon="text" />
                </Flex>

                <Box css={{ flex: '1' }} font="gray800 textSm medium">
                    {translate('Text')}
                </Box>

                <Button color="white" leftIcon="plus" size="sm" />
            </Flex>

            <Flex
                align="center"
                css={{
                    borderBottom: '1px solid $gray200',
                    cursor: 'pointer',
                    padding: '$4 $6'
                }}
                gap="3"
                onClick={() => {
                    addImageElement(props.container);
                }}
            >
                <Flex align="center" height={24} justify="center" width={24}>
                    <I icon="image" />
                </Flex>

                <Box css={{ flex: '1' }} font="gray800 textSm medium">
                    {translate('image_40624')}
                </Box>

                <Button color="white" leftIcon="plus" size="sm" />
            </Flex>

            <Flex
                align="center"
                css={{ cursor: 'pointer', padding: '$4 $6' }}
                gap="3"
                onClick={() => {
                    addContainerElement(props.container);
                }}
            >
                <Flex align="center" height={24} justify="center" width={24}>
                    <I icon="chart-simple" />
                </Flex>

                <Box css={{ flex: '1' }} font="gray800 textSm medium">
                    {translate('conteneur_33078')}
                </Box>

                <Button color="white" leftIcon="plus" size="sm" />
            </Flex>
        </Flex>
    );
};
