import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Button } from '../button';
import { DateInput } from './dateInput';

type IDatePrevNextInputProps = {
    max: DateTime;
    min: DateTime;
    value: DateTime;

    onChange(value: DateTime): void;
};

export const DatePrevNextInput = (props: IDatePrevNextInputProps) => {
    const prevDisabled = React.useMemo(
        () => props.min.startOf('week').equals(props.value.startOf('week')),
        [props.min, props.value]
    );
    const nextDisabled = React.useMemo(
        () => props.max.endOf('week').equals(props.value.endOf('week')),
        [props.max, props.value]
    );

    return (
        <Flex gap="2">
            <Button
                color="white"
                disabled={prevDisabled}
                leftIcon="chevron-left"
                onClick={() => {
                    props.onChange(props.value.minus({ week: 1 }));
                }}
            />

            <Box width={160}>
                <DateInput {...props} />
            </Box>

            <Button
                color="white"
                disabled={nextDisabled}
                leftIcon="chevron-right"
                onClick={() => {
                    props.onChange(props.value.plus({ week: 1 }));
                }}
            />
        </Flex>
    );
};
