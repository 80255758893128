import { sortBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { RichText } from '../../../designSystem/components/richEditor/richText';
import { Spacer } from '../../../designSystem/components/spacer';
import { CommonEnvVars } from '../../../envVars';
import {
    AsoassignmentDocumentOptions,
    DocumentUserCustomBadgeFragment,
    EventId
} from '../../../generated/types';
import { googleMapsHref } from '../../../util/googleMaps';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';
import { hasAddress } from '../../../vo/position';

const SEMI_ID = 4427 as EventId;
const MARATHON_ID = 4848 as EventId;
const _10K_ID = -1 as EventId;

const getBackground = (eventId: EventId) => {
    if (eventId === SEMI_ID) {
        return 'https://assets.recrewteer.com/badges/aso/2025_v4/semi.jpg';
    } else if (eventId === MARATHON_ID) {
        return 'https://assets.recrewteer.com/badges/aso/2025_v4/marathon.jpg';
    } else if (eventId === _10K_ID) {
        return 'https://assets.recrewteer.com/badges/aso/2025_v4/10k.jpg';
    } else {
        return '';
    }
};

const getTitleCss = (eventId: EventId) => {
    if (eventId === SEMI_ID) {
        return { color: '#ff5501' };
    } else if (eventId === MARATHON_ID) {
        return { color: '#005a41' };
    } else if (eventId === _10K_ID) {
        return { color: '#1e4850' };
    } else {
        return {};
    }
};

const getNameAndLeftCotnentCss = (eventId: EventId) => {
    if (eventId === SEMI_ID) {
        return { color: 'white' };
    } else if (eventId === MARATHON_ID) {
        return { color: '#005a41' };
    } else if (eventId === _10K_ID) {
        return { color: '#1e4850' };
    } else {
        return {};
    }
};

interface IAsoAssignmentPageProps {
    eventId: EventId;
    options: AsoassignmentDocumentOptions;
    psuis: DocumentUserCustomBadgeFragment['volunteerRegistration']['positionsSlotsUsersInfos'];
    userInfo: DocumentUserCustomBadgeFragment['volunteerRegistration']['userInfo'];
}

export const AsoAssignmentPage = (props: IAsoAssignmentPageProps) => {
    const position = props.psuis[0].position;
    const slots = sortBy(
        props.psuis.map(({ positionSlot }) => positionSlot),
        (slot) => slot.range.start!.toMillis()
    );
    const leader = position.leaders[0];

    return (
        <Flex
            css={{
                background: `url(${getBackground(props.eventId)}) no-repeat`,
                backgroundSize: 'cover',
                pageBreakBefore: 'always',
                position: 'relative'
            }}
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
        >
            <Box
                css={{
                    fontSize: '24px',
                    left: '298px',
                    position: 'absolute',
                    top: '101px',
                    ...getNameAndLeftCotnentCss(props.eventId)
                }}
                textAlign="center"
                width={409}
            >
                {props.userInfo.name}
            </Box>

            <Box
                css={{
                    fontSize: '12px',
                    left: '46px',
                    position: 'absolute',
                    top: '374px'
                }}
            >
                {isNonEmptyString(props.options.habits) && (
                    <>
                        <Box
                            css={{
                                ...getNameAndLeftCotnentCss(props.eventId),
                                '& .h-rich-text > p': {
                                    margin: '0'
                                }
                            }}
                        >
                            <RichText text={props.options.habits} />
                        </Box>
                    </>
                )}
            </Box>

            {leader && (
                <>
                    <Box
                        css={{
                            color: '#005a41',
                            left: '46px',
                            position: 'absolute',
                            top: '581px',
                            ...getNameAndLeftCotnentCss(props.eventId)
                        }}
                        textAlign="center"
                        width={150}
                    >
                        {leader.userInfo.name}
                    </Box>

                    <Box
                        css={{
                            left: '46px',
                            position: 'absolute',
                            top: '602px',
                            ...getNameAndLeftCotnentCss(props.eventId)
                        }}
                        textAlign="center"
                        width={150}
                    >
                        {leader.userInfo.phone?.internationalFormat}
                    </Box>
                </>
            )}

            <Flex
                css={{
                    left: '228px',
                    position: 'absolute',
                    padding: '32px',
                    top: '167px'
                }}
                direction="column"
                width={550}
            >
                <Box
                    css={{
                        fontSize: '28px',
                        fontWeight: '700',
                        ...getTitleCss(props.eventId)
                    }}
                    textAlign="center"
                >
                    {position.name}
                </Box>

                <Spacer height="7" />

                <Box
                    css={{
                        fontWeight: '700',
                        textTransform: 'uppercase',
                        ...getTitleCss(props.eventId)
                    }}
                >
                    Date et heure de la mission
                </Box>

                <Spacer height="1" />

                <Flex css={{ fontSize: '12px' }} direction="column" gap="1">
                    {slots.map((slot, index) => {
                        const start = slot.range.start!;

                        return (
                            <Box key={index}>
                                {start.toFormat('cccc dd MMMM yyyy', { locale: 'fr' })} à{' '}
                                {start.toFormat(`H'h'mm`, { locale: 'fr' })}
                            </Box>
                        );
                    })}
                </Flex>

                <Spacer height="6" />

                <Box
                    css={{
                        fontWeight: '700',
                        textTransform: 'uppercase',
                        ...getTitleCss(props.eventId)
                    }}
                >
                    Détail de la mission
                </Box>

                <Spacer height="1" />

                {isNonEmptyString(position.description) && (
                    <>
                        <Box
                            css={{
                                color: 'black',
                                fontSize: '12px',
                                '& .h-rich-text > p': {
                                    margin: '0'
                                }
                            }}
                        >
                            <RichText text={position.description} />
                        </Box>
                    </>
                )}

                <Spacer height="6" />

                <Box
                    css={{
                        fontWeight: '700',
                        textTransform: 'uppercase',
                        ...getTitleCss(props.eventId)
                    }}
                >
                    Lieu de rendez-vous
                </Box>

                <Spacer height="1" />

                {hasAddress(position) && (
                    <>
                        <Box css={{ fontSize: '12px' }}>{position.address}</Box>

                        <Spacer height="1" />
                    </>
                )}

                <Flex gap="3" width={1}>
                    <Box height={180} width={1}>
                        <a
                            href={googleMapsHref(position.latitude!, position.longitude!)}
                            style={{
                                background: `url(${CommonEnvVars.HEAVENT_API_URL}/image/event/${props.eventId}/position/${position.id}.png)`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                display: 'block',
                                height: '100%',
                                width: '100%'
                            }}
                        />
                    </Box>

                    {isNonEmptyString(position.fields?.cf45815?.url) && (
                        <Box
                            css={{
                                background: `url(${position.fields.cf45815.url}) no-repeat`,
                                backgroundSize: 'contain'
                            }}
                            height={180}
                            width={204}
                        />
                    )}
                </Flex>

                {isNonEmptyString(position.fields.cf44423) && (
                    <>
                        <Spacer height="6" />

                        <Box
                            css={{
                                fontWeight: '700',
                                textTransform: 'uppercase',
                                ...getTitleCss(props.eventId)
                            }}
                        >
                            Ton kit bénévole
                        </Box>

                        <Spacer height="1" />

                        <Box
                            css={{
                                fontSize: '12px'
                            }}
                            dangerouslySetInnerHTML={{
                                __html: position.fields.cf44423.replace(/\n/g, '<br />')
                            }}
                        />
                    </>
                )}

                <Spacer height="6" />

                <Box
                    css={{
                        fontWeight: '700',
                        textTransform: 'uppercase',
                        ...getTitleCss(props.eventId)
                    }}
                >
                    Un imprévu ?
                </Box>

                <Spacer height="1" />

                <Box
                    css={{
                        fontSize: '12px'
                    }}
                >
                    Pour des raisons pratiques, il ne sera pas possible de procéder à des
                    changements d’affectation sur le terrain. Merci de bien vouloir nous informer en
                    cas d’indisponibilité le plus rapidement possible, afin de ne pas pénaliser le
                    bon déroulement de l’épreuve
                </Box>
            </Flex>
        </Flex>
    );
};
