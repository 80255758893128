import { FullPageContent } from 'common-front/src/components/mobileFullPage/fullPageContent';
import { FullPageHeader } from 'common-front/src/components/mobileFullPage/fullPageHeader';
import { FullPageHeaderText } from 'common-front/src/components/mobileFullPage/fullPageHeaderText';
import { MobileFullPage } from 'common-front/src/components/mobileFullPage/mobileFullPage';
import { useHistory, useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { PositionSlotMembersCheckOutListMobile } from './positionSlotMembersCheckOutListMobile';

export const PositionSlotMembersCheckOutMobile = () => {
    const { organizationId, eventId, positionId, positionSlotId } = useParams();
    const history = useHistory();
    const translate = useTranslate();

    return (
        <MobileFullPage>
            <FullPageHeader
                back={() => {
                    history.push(
                        HeaventPaths.POSITION_SLOT_MEMBERS(
                            organizationId,
                            eventId,
                            positionId,
                            positionSlotId
                        )
                    );
                }}
            >
                <FullPageHeaderText>{translate('d_pointer_des_m_31557')}</FullPageHeaderText>
            </FullPageHeader>
            <FullPageContent css={{ margin: '0', overflow: 'auto' }}>
                <PositionSlotMembersCheckOutListMobile />
            </FullPageContent>
        </MobileFullPage>
    );
};
