import { Flex } from 'common/src/designSystem/components/flex';
import { NominalType } from 'common/src/util/nominalType';
import { DateTime, Interval } from 'luxon';
import * as React from 'react';
import { PlanningRow, PlanningSlot } from './types';

interface ISlotProps<T extends { id: TId }, TId extends NominalType<number, any>, U> {
    date: DateTime;
    slot: PlanningSlot<U>;
    row: PlanningRow<T, TId, U>;

    renderSlot(slot: PlanningSlot<U>, row: PlanningRow<T, TId, U>): React.ReactNode;
}

export const Slot = <T extends { id: TId }, TId extends NominalType<number, any>, U>(
    props: ISlotProps<T, TId, U>
) => {
    const shouldDisplay = React.useMemo(
        () =>
            Interval.fromDateTimes(props.date.startOf('day'), props.date.endOf('day')).overlaps(
                props.slot.range
            ),
        [props.date, props.slot]
    );
    const finalRange = React.useMemo(() => {
        if (shouldDisplay) {
            return Interval.fromDateTimes(
                DateTime.max(props.date.startOf('day'), props.slot.range.start!),
                DateTime.min(props.date.endOf('day'), props.slot.range.end!)
            );
        } else {
            return props.slot.range;
        }
    }, [shouldDisplay, props.date, props.slot]);
    const gridColumnStart = React.useMemo(
        () => 1 + (finalRange.start?.hour ?? 0) * 60 + (finalRange.start?.minute ?? 0),
        [finalRange]
    );
    const gridColumnEnd = React.useMemo(
        () => 1 + (finalRange.end?.hour ?? 0) * 60 + (finalRange.end?.minute ?? 0),
        [finalRange]
    );

    if (shouldDisplay) {
        return (
            <Flex
                key={finalRange.toISO()}
                css={{
                    gridColumnStart,
                    gridColumnEnd
                }}
                height={32}
            >
                {props.renderSlot(props.slot, props.row)}
            </Flex>
        );
    } else {
        return null;
    }
};
