import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Grid } from 'common/src/designSystem/components/grid';
import { I } from 'common/src/designSystem/components/i';
import { NominalType } from 'common/src/util/nominalType';
import { range } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Slot } from './slot';
import { PlanningRow as PlanningRowType, PlanningSlot } from './types';

interface IPlanningRowProps<T extends { id: TId }, TId extends NominalType<number, any>, U> {
    date: DateTime;
    row: PlanningRowType<T, TId, U>;
    showPlus: boolean;

    onPlus?(startDate: DateTime, row: PlanningRowType<T, TId, U>): void;
    renderLeftCell(row: PlanningRowType<T, TId, U>): React.ReactNode;
    renderSlot(slot: PlanningSlot<U>, row: PlanningRowType<T, TId, U>): React.ReactNode;
}

export const PlanningRow = <T extends { id: TId }, TId extends NominalType<number, any>, U>(
    props: IPlanningRowProps<T, TId, U>
) => {
    const [boundingRect, setBoundingRect] = React.useState<DOMRect | null>(null);
    const gridRef = React.useCallback((node: HTMLDivElement | null) => {
        if (node) {
            setBoundingRect(node.getBoundingClientRect());
        }
    }, []);
    const [displayPlus, setDisplayPlus] = React.useState(false);
    const [plusGridColumnStart, setPlusGridColumnStart] = React.useState(1);
    const onMouseMove = React.useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            const x = Math.floor(
                ((e.clientX - (boundingRect?.left ?? 0)) * 1440) / (boundingRect?.width ?? 0)
            );
            const gridColumnStart = x - (x % 60) + 1;

            if (gridColumnStart !== plusGridColumnStart) {
                setPlusGridColumnStart(gridColumnStart);
            }
        },
        [boundingRect, plusGridColumnStart, setPlusGridColumnStart]
    );
    const onClick = React.useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            const x = Math.floor(
                ((e.clientX - (boundingRect?.left ?? 0)) * 1440) / (boundingRect?.width ?? 0)
            );
            const minute = x - (x % 60);
            const startDate = props.date.startOf('day').plus({ minute });

            props.onPlus?.(startDate, props.row);
        },
        [props.date, props.row, props.onPlus, boundingRect]
    );

    return (
        <Flex
            className="h-planning-data-row"
            css={{ borderBottom: '1px solid $gray200' }}
            width={1}
        >
            <Flex
                css={{
                    borderRight: '1px solid $gray200',
                    width: `calc(2 * $cellWidth)`
                }}
            >
                {props.renderLeftCell(props.row)}
            </Flex>

            <Flex css={{ flex: '1', py: '$3', position: 'relative' }}>
                <Grid
                    ref={gridRef}
                    css={{
                        cursor: props.showPlus ? 'pointer' : 'inherit',
                        gridAutoFlow: 'column',
                        overflow: 'hidden',
                        rowGap: '$2',
                        zIndex: 10
                    }}
                    gridtemplatecolumns="repeat(1440, 1fr)"
                    width={1}
                    onClick={props.showPlus ? onClick : undefined}
                    onMouseMove={props.showPlus ? onMouseMove : undefined}
                    onMouseOut={() => {
                        setDisplayPlus(false);
                    }}
                    onMouseOver={() => {
                        setDisplayPlus(true);
                    }}
                >
                    {props.row.slots.map((slot, index) => (
                        <Slot
                            key={index}
                            date={props.date}
                            renderSlot={props.renderSlot}
                            row={props.row}
                            slot={slot}
                        />
                    ))}
                </Grid>

                {props.showPlus && (
                    <Grid
                        css={{
                            bottom: '0',
                            left: '0',
                            position: 'absolute',
                            right: '0',
                            top: '0',
                            zIndex: 5
                        }}
                        gridtemplatecolumns="repeat(1440, 1fr)"
                    >
                        {displayPlus && (
                            <Flex
                                align="center"
                                css={{
                                    gridColumn: `${plusGridColumnStart} / span 60`,
                                    padding: '$1'
                                }}
                                height={1}
                                justify="center"
                            >
                                <Flex
                                    align="center"
                                    css={{
                                        background: '$gray100',
                                        borderRadius: '$1',
                                        color: '$gray800',
                                        cursor: 'pointer',
                                        fontSize: '$textMd'
                                    }}
                                    height={1}
                                    justify="center"
                                    width={1}
                                >
                                    <I icon="plus" />
                                </Flex>
                            </Flex>
                        )}
                    </Grid>
                )}

                <Grid
                    css={{
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0',
                        zIndex: 1
                    }}
                    gridtemplatecolumns="repeat(1440, 1fr)"
                >
                    {range(0, 23).map((i) => (
                        <Box
                            key={i}
                            css={{
                                borderRightColor: '$gray200',
                                borderRightStyle: i % 2 === 0 ? 'dashed' : 'solid',
                                borderRightWidth: '1px',
                                gridColumn: `${i * 60 + 1} / ${i * 60 + 61}`
                            }}
                        />
                    ))}
                </Grid>
            </Flex>
        </Flex>
    );
};
