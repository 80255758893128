import { Button } from 'common-front/src/designSystem/components/button';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CommonEnvVars } from 'common/src/envVars';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

export const UnsubscribeEmail = () => {
    const translate = useTranslate();
    const params = new URL(location.href).searchParams;
    const name = params.get('eventName') || params.get('organizationName') || '';
    const baseLink = `${CommonEnvVars.HEAVENT_API_URL}/users/${params.get('userId') || ''}/`;
    const link = params.get('eventId')
        ? `${baseLink}blacklist-event?eventId=${params.get('eventId')}`
        : `${baseLink}blacklist-organization?organizationId=${params.get('organizationId')}`;

    return (
        <Flex
            align="center"
            css={{
                background: '$gray50',
                overflow: 'hidden'
            }}
            height={1}
            justify="center"
            width={1}
        >
            <Flex
                align="center"
                css={{
                    background: 'white',
                    bd: true,
                    padding: '$7'
                }}
                direction="column"
                width={640}
            >
                <Box font="gray900 textXl semiBold">{translate('d_sinscription_61475')}</Box>

                <Spacer height="1" />

                <Box color="gray500" textAlign="center">
                    {translate('vous_tes_sur_l_71517', name)}
                </Box>

                <Spacer height="7" />

                <Box width={1}>
                    <Button
                        color="error"
                        textAlign="center"
                        onClick={() => {
                            window.open(link, '_blank');
                        }}
                    >
                        {translate('oui_je_souhait_20141')}
                    </Button>
                </Box>
            </Flex>
        </Flex>
    );
};
