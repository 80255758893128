import { UpdateLinkTextCell } from 'common-front/src/components/cells/updateLinkTextCell';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { LinkText } from 'common/src/designSystem/components/linkText';
import { DelegationProperty, DelegationsDelegationFragment } from 'common/src/generated/types';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import * as React from 'react';
import { DelegationsCategoryCellInput } from './delegationsCategoryCellInput';
import { DelegationsUpdateCell } from './delegationsUpdateCell';

interface IDelegationsCategoryCellProps {
    delegation: DelegationsDelegationFragment;

    reload(): void;
}

export const DelegationsCategoryCell = (props: IDelegationsCategoryCellProps) => {
    const {
        params: { organizationId, eventId }
    } = useHeavent();
    const { isEditMode } = useSegmentsContext();

    return (
        <DelegationsUpdateCell
            delegation={props.delegation}
            initialValue={props.delegation.delegationCategory.id}
            property={DelegationProperty.Category}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <DelegationsCategoryCellInput setValue={setValue} value={value} />
            )}
            renderValue={() => (
                <UpdateLinkTextCell
                    isEditMode={isEditMode}
                    title={props.delegation.delegationCategory.name}
                    updatable={true}
                >
                    <LinkText
                        to={DelegationsPaths.DELEGATION_CATEGORY({
                            organizationId,
                            eventId,
                            delegationCategoryId: props.delegation.delegationCategory.id
                        })}
                    >
                        {props.delegation.delegationCategory.name}
                    </LinkText>
                </UpdateLinkTextCell>
            )}
        />
    );
};
