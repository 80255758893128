import { injectable } from 'inversify';
import * as yup from 'yup';
import {
    ALL_EMAILTYPE,
    EmailsTemplateInput,
    EmailType,
    EventId,
    FormId,
    OrganizationId
} from '../generated/types';
import { heaventEnvDisplayName } from '../heaventEnv';
import { TranslationService } from '../services/translationService';
import { assertUnreachable } from '../util/assertUnreachable';
import { Emptyable } from '../util/emptyable';
import { getSpaceHref, Url } from '../util/url';
import { InputService } from './inputService';

export interface IUpdateEmailTemplateValues {
    emailTemplate: EmailsTemplateInput;
}

@injectable()
export class EmailTemplateInputService extends InputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    emailTemplateDefault(
        emailType: EmailType,
        appUrl: Url,
        emailTemplate: Emptyable<EmailsTemplateInput>,
        organizationId: OrganizationId,
        eventId: Emptyable<EventId>,
        formId: Emptyable<FormId>
    ): EmailsTemplateInput {
        const subjectContent = this.emailTypeToDefault(
            emailType,
            appUrl,
            organizationId,
            eventId,
            formId
        );

        if (emailTemplate) {
            subjectContent.subject = emailTemplate.subject;
            subjectContent.content = emailTemplate.content;
        }

        return {
            useDefault: emailTemplate?.useDefault ?? true,
            emailType,
            emailSenderId: emailTemplate?.emailSenderId,
            attachments: emailTemplate?.attachments ?? [],
            ...subjectContent
        };
    }

    emailTemplateSchema() {
        return yup.object().shape({
            useDefault: yup.boolean().required(''),
            emailType: yup
                .string()
                .required(this.t('le_type_d_e_mai_84281'))
                .oneOf(ALL_EMAILTYPE, this.t('le_type_d_e_mai_84281')),
            subject: yup.string().when('useDefault', {
                is: false,
                then: (s) => s.required(this.t('le_sujet_est_re_18923'))
            }),
            content: yup.string().when('useDefault', {
                is: false,
                then: (s) =>
                    s
                        .required(this.t('le_contenu_est_45048'))
                        .test(
                            'contains-code',
                            this.t('le_contenu_doit_64437'),
                            function (this: { parent: EmailsTemplateInput }) {
                                if (
                                    !this.parent.useDefault &&
                                    this.parent.emailType === EmailType.Code
                                ) {
                                    return this.parent.content.includes('{code}');
                                } else {
                                    return true;
                                }
                            }
                        )
            }),
            attachments: yup.array().of(yup.string())
        });
    }

    updateEmailTemplateSchema() {
        return yup.object().shape({
            emailTemplate: this.emailTemplateSchema()
        });
    }

    private emailTypeToDefault(
        emailType: EmailType,
        appUrl: Url,
        organizationId: OrganizationId,
        eventId: Emptyable<EventId>,
        formId: Emptyable<FormId>
    ) {
        switch (emailType) {
            case EmailType.Accreditations:
                return this.accreditationsDefault();
            case EmailType.AccreditationsRefused:
                return this.accreditationsRefusedDefault();
            case EmailType.Assignments:
                return this.assignmentsDefault();
            case EmailType.Code:
                return this.codeDefault(eventId, formId);
            case EmailType.FormInvite:
                return this.formInviteDefault();
            case EmailType.FormSubmit:
                return this.formSubmitDefault(eventId);
            case EmailType.NotifyLeaders:
                return this.notifyLeadersDefault(appUrl);
            case EmailType.DelegationLeaderAssign:
                return this.delegationLeaderAssign(organizationId);
            case EmailType.PositionCategoryLeaderAssign:
                return this.positionCategoryLeaderAssignDefault(appUrl);
            case EmailType.PositionLeaderAssign:
                return this.positionLeaderAssign(appUrl);
            case EmailType.Refused:
                return this.refusedDefault();
            case EmailType.VolunteerRegistration:
                return this.volunteerRegistrationDefault();
            case EmailType.Campaign:
            case EmailType.DelegationsImport:
            case EmailType.FilesImport:
            case EmailType.Documents:
            case EmailType.EventInvite:
            case EmailType.Export:
            case EmailType.MembersImport:
            case EmailType.NewMember:
            case EmailType.NewVolunteer:
            case EmailType.OrganizationInvite:
            case EmailType.PositionsImport:
                throw new Error('Impossible to change this email');
            default:
                return assertUnreachable(emailType);
        }
    }

    private accreditationsDefault() {
        return {
            subject: this.t('_event_name_49280'),
            content: this.t('accreditations_default')
        };
    }

    private accreditationsRefusedDefault() {
        return {
            subject: this.t('_event_name_01805'),
            content: this.t('accreditations_refused_default')
        };
    }

    private assignmentsDefault() {
        return {
            subject: this.t('_event_name_72063'),
            content: this.t('assignments_default')
        };
    }

    private codeDefault(eventId: Emptyable<EventId>, formId: Emptyable<FormId>) {
        return {
            subject:
                eventId && !formId
                    ? this.t('code_de_v_rific_41117')
                    : this.t('code_de_connexi_57121'),
            content: this.t('code_default')
        };
    }

    private formInviteDefault() {
        return {
            subject: this.t('form_invite_subject'),
            content: this.t('form_invite_content')
        };
    }

    private formSubmitDefault(eventId: Emptyable<EventId>) {
        if (eventId) {
            return {
                subject: this.t('_event_name_12686'),
                content: this.t('form_submit_event_default')
            };
        } else {
            return {
                subject: this.t('_organization_n_21926'),
                content: this.t('form_submit_organization_default')
            };
        }
    }

    private notifyLeadersDefault(appUrl: Url) {
        return {
            subject: this.t('_event_name_95901'),
            content: this.t('notify_leaders_default_1', appUrl.href, heaventEnvDisplayName())
        };
    }

    private delegationLeaderAssign(organizationId: OrganizationId) {
        return {
            subject: this.t('_event_name_80802'),
            content: this.t(
                'delegation_leader_assign_default',
                getSpaceHref(organizationId, `/organization/${organizationId}/delegations/auth`)
            )
        };
    }

    private positionCategoryLeaderAssignDefault(appUrl: Url) {
        return {
            subject: this.t('_event_name_63708'),
            content: this.t(
                'category_leader_assign_default_1',
                heaventEnvDisplayName(),
                appUrl.href,
                heaventEnvDisplayName()
            )
        };
    }

    private positionLeaderAssign(appUrl: Url) {
        return {
            subject: this.t('_event_name_60486'),
            content: this.t(
                'leader_assign_default_1',
                heaventEnvDisplayName(),
                appUrl.href,
                heaventEnvDisplayName()
            )
        };
    }

    private refusedDefault() {
        return {
            subject: this.t('_event_name_59248'),
            content: this.t('refused_default')
        };
    }

    private volunteerRegistrationDefault() {
        return {
            subject: this.t('_event_name_12686'),
            content: this.t('volunteer_registration_default')
        };
    }
}
