import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { FormsPaths } from 'common/src/util/paths/formsPaths';
import * as React from 'react';

interface IUseFormParametersCloseReturn {
    isNotSavedModalOpen: boolean;

    onClose(): void;
    onNotSavedClose(): void;
    onSave(): void;
}

export function useFormParametersClose<T>(
    initialValues: T,
    values: T,
    handleSubmit: () => void
): IUseFormParametersCloseReturn {
    const {
        history,
        params: { organizationId, eventId }
    } = useHeavent();
    const [isNotSavedModalOpen, setIsNotSavedModalOpen] = React.useState(false);
    const [initialValuesString, setInitialValuesString] = React.useState(
        JSON.stringify(initialValues)
    );
    const onClose = React.useCallback(() => {
        const valuesString = JSON.stringify(values);

        if (initialValuesString === valuesString) {
            history.goBack(FormsPaths.FORMS({ organizationId, eventId }));
        } else {
            setIsNotSavedModalOpen(true);
        }
    }, [initialValuesString, values]);
    const onNotSavedClose = React.useCallback(() => {
        setIsNotSavedModalOpen(false);
    }, [setIsNotSavedModalOpen]);
    const onSave = React.useCallback(() => {
        handleSubmit();

        setInitialValuesString(JSON.stringify(values));
    }, [handleSubmit, values]);

    React.useEffect(() => {
        setInitialValuesString(JSON.stringify(initialValues));
    }, [initialValues]);

    return {
        isNotSavedModalOpen,
        onClose,
        onNotSavedClose,
        onSave
    };
}
