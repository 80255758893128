import { OrganizationId } from './generated/types';

export const PARTAGE_TA_PASSION_HOST = 'www.partagetapassion.com';
export const RNCB_HOST = 'communaute.rncb.fr';
export const THIRD_PARTY_DOMAINS = [PARTAGE_TA_PASSION_HOST, RNCB_HOST];

export const thirdPartyDomainToOrganizationId: Record<string, OrganizationId> = {
    [PARTAGE_TA_PASSION_HOST]: 858 as OrganizationId,
    [RNCB_HOST]: 1773 as OrganizationId
};

export const organizationIdToThirdPartyDomain: Map<number, string> = new Map([
    [858, PARTAGE_TA_PASSION_HOST],
    [1773, RNCB_HOST]
]);
