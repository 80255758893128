import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Link } from 'common/src/designSystem/components/link';
import * as React from 'react';

interface IOnboardingRowProps {
    isDone: boolean;
    path: string;
    subtitle: string;
    title: string;
}

export const OnboardingRow = (props: IOnboardingRowProps) => (
    <Flex
        align="center"
        css={{
            background: 'white',
            bd: true,
            cursor: 'pointer',
            padding: '$3 $4'
        }}
        gap="3"
        width={1}
    >
        <Link to={props.path} width={1}>
            <Flex
                align="center"
                css={{
                    background: props.isDone ? '$success100' : '',
                    border: props.isDone ? '' : '1px solid $gray300',
                    borderRadius: '20px',
                    color: '$success700',
                    fontSize: '10px'
                }}
                height={20}
                justify="center"
                width={20}
            >
                {props.isDone && <I icon="check" />}
            </Flex>

            <Flex css={{ flex: '1' }} direction="column" gap="1">
                <Box
                    color="gray800"
                    css={{
                        textDecoration: props.isDone ? 'line-through' : ''
                    }}
                    fontWeight="medium"
                >
                    {props.title}
                </Box>

                <Box color="gray500">{props.subtitle}</Box>
            </Flex>

            <Box color="gray900" css={{ cursor: 'pointer' }}>
                <I icon="arrow-right" />
            </Box>
        </Link>
    </Flex>
);
