import { theme } from '../designSystem/components/stitches';
import {
    AsoassignmentDocumentOptions,
    BadgeDocumentOptions,
    BruleursDocumentOptions,
    CertificateDocumentOptions,
    OrganizationCertificateDefaultFragment,
    PdfFormat,
    PecbadgeDocumentOptions,
    PositionDocumentOptions,
    T24assignmentDocumentOptions,
    UserAssignmentsDocumentOptions,
    UserPlanningDaysOptions,
    UserPlanningListOptions,
    VolunteersListingDocumentOptions
} from '../generated/types';

export function getDefaultFileOptions() {
    return {
        name: '',
        content: ''
    };
}

export function getDefaultBadgeOptions(): BadgeDocumentOptions {
    return {
        format: PdfFormat.A6Portrait,
        logo: getDefaultFileOptions(),
        color: theme.colors.gray900.value,
        name: '',
        infos: '',
        showAssignments: true
    };
}

export function getDefaultPositionOptions(): PositionDocumentOptions {
    return {
        format: PdfFormat.A4Portrait,
        showPositionData: true,
        showVolunteersLists: true,
        slugsToDisplay: ['fullName', 'phone']
    };
}

export function getDefaultVolunteersListingOptions(): VolunteersListingDocumentOptions {
    return {
        header: getDefaultFileOptions(),
        slugsToDisplay: ['fullName', 'phone']
    };
}

export function getDefaultCertificateOptions(
    options: OrganizationCertificateDefaultFragment
): CertificateDocumentOptions {
    return {
        address: `${options.name}\n${options.street || ''}\n${options.postalCode || ''}\n${
            options.city || ''
        }`.trim(),
        header: getDefaultFileOptions(),
        infos: '',
        name: options.adminName || '',
        place: 'Montpellier',
        signature: getDefaultFileOptions(),
        title: `Président de ${options.name}`
    };
}

export function getDefaultUserPlanningDaysOptions(): UserPlanningDaysOptions {
    return {
        logo: getDefaultFileOptions()
    };
}

export function getDefaultUserPlanningListOptions(): UserPlanningListOptions {
    return {
        showHours: true
    };
}

export function getDefaultUserAssignmentsOptions(): UserAssignmentsDocumentOptions {
    return {
        background: getDefaultFileOptions(),
        fontFamily: 'Open Sans',
        headerColor: '#ffffff',
        informationsContent: '',
        informationsTitle: '',
        informationsContent2: '',
        informationsTitle2: '',
        leadersData: 'phone',
        positionCustomFields: [],
        showInformations: false,
        showInformations2: false,
        showLeaders: false,
        showQrCode: false,
        signature: '',
        subtitleColor: '#1d2939',
        textColor: '#1d2939',
        title: '',
        titleColor: '#1d2939',
        titleFontFamily: 'Open Sans'
    };
}

export function getDefaultAsoassignmentOptions(): AsoassignmentDocumentOptions {
    return {
        habits: ''
    };
}

export function getDefaultPecbadgeOptions(): PecbadgeDocumentOptions {
    return {
        header: getDefaultFileOptions()
    };
}

export function getDefaultT24assignmentOptions(): T24assignmentDocumentOptions {
    return {
        logo: getDefaultFileOptions()
    };
}

export function getDefaultBruleursDocumentOptions(): BruleursDocumentOptions {
    return {
        logo: getDefaultFileOptions()
    };
}
