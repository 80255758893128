import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { getToken } from 'common-front/src/util/aws/cognito';
import { Box } from 'common/src/designSystem/components/box';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CampaignEventPositionsRecipientsQuery } from 'common/src/generated/types';
import { CampaignRecipients } from 'common/src/input/campaignInput';
import { IntervalService } from 'common/src/services/intervalService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { groupBy, sortBy } from 'lodash-es';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import { executeCampaignEventPositionsRecipientsQuery } from '../../../generated/graphqlHooks';

interface ICampaignEventPositionsRecipientsProps {
    recipients: CampaignRecipients;
}

export const CampaignEventPositionsRecipients = (props: ICampaignEventPositionsRecipientsProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const intervalService = useService(IntervalService);
    const { isEventAdmin, isPositionCategoryAdmin } = useEventContext();
    const [isFirstTime, setIsFirstTime] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(true);
    const [categories, setCategories] = React.useState<
        CampaignEventPositionsRecipientsQuery['event']['positionsCategories']
    >([]);
    const [positionsGrouped, setPositionsGrouped] = React.useState<Array<[string, any[]]>>([]);
    const [slotsGrouped, setSlotsGrouped] = React.useState<Array<[string, any[]]>>([]);
    const [tags, setTags] = React.useState<
        CampaignEventPositionsRecipientsQuery['organization']['tags']['nodes']
    >([]);

    React.useEffect(() => {
        (async () => {
            if (props.recipients === 'positions' && isFirstTime) {
                const data = await executeCampaignEventPositionsRecipientsQuery(
                    { organizationId, eventId },
                    await getToken()
                );

                setCategories(sortBy(data.event.positionsCategories, (c) => c.name));
                setPositionsGrouped(
                    Object.entries(
                        groupBy(data.event.positions.nodes, (p) => p.positionCategoryName)
                    )
                );
                setSlotsGrouped(
                    data.event.positions.nodes.map((position) => {
                        const slots = position.slots.map((slot) => {
                            const slotName = isNonEmptyString(slot.name) ? `${slot.name} - ` : '';
                            const slotRange = intervalService.toDisplayString(slot.range, {
                                formats: { displayWeekday: true }
                            });

                            return {
                                id: slot.id,
                                name: `${slotName}${slotRange} - ${position.name}`
                            };
                        });

                        return [position.name, slots];
                    })
                );
                setTags(data.organization.tags.nodes);
                setIsFirstTime(false);
                setIsLoading(false);
            }
        })();
    }, [props.recipients, isFirstTime]);

    if (props.recipients === CampaignRecipients.Positions) {
        return (
            <>
                <Spacer height="2" />

                <Box css={{ marginLeft: '$6' }}>
                    {isLoading ? (
                        <>
                            <Skeleton borderRadius="$1" height={40} width={1} />
                            <Spacer height="2" />
                            <Skeleton borderRadius="$1" height={40} width={1} />
                            <Spacer height="2" />
                            <Skeleton borderRadius="$1" height={40} width={1} />
                            <Spacer height="2" />
                            <Skeleton borderRadius="$1" height={40} width={1} />
                        </>
                    ) : (
                        <>
                            {isPositionCategoryAdmin() && (
                                <>
                                    <RichSelect
                                        isSearchVisible={true}
                                        multiple={true}
                                        name="campaign.positionsCategoriesIds"
                                        placeholder={translate('ajouter_des_cat_15537')}
                                    >
                                        {categories.map(({ id, name }) => (
                                            <option key={id} value={id}>
                                                {name}
                                            </option>
                                        ))}
                                    </RichSelect>

                                    <Spacer height="2" />
                                </>
                            )}

                            <RichSelect
                                isSearchVisible={true}
                                multiple={true}
                                name="campaign.positionsIds"
                                placeholder={translate('ajouter_des_mis_44522')}
                            >
                                {positionsGrouped.map(([categoryName, positions], index) => (
                                    <optgroup key={index} label={categoryName}>
                                        {positions.map((position) => (
                                            <option key={position.id} value={position.id}>
                                                {position.name}
                                            </option>
                                        ))}
                                    </optgroup>
                                ))}
                            </RichSelect>

                            <Spacer height="2" />

                            <RichSelect
                                isSearchVisible={true}
                                multiple={true}
                                name="campaign.positionsSlotsIds"
                                placeholder={translate('ajouter_des_cr_25566')}
                            >
                                {slotsGrouped.map(([positionName, slots], index) => (
                                    <optgroup key={index} label={positionName}>
                                        {slots.map((slot) => (
                                            <option key={slot.id} value={slot.id}>
                                                {slot.name}
                                            </option>
                                        ))}
                                    </optgroup>
                                ))}
                            </RichSelect>

                            {isEventAdmin && (
                                <>
                                    <Spacer height="2" />

                                    <RichSelect
                                        isSearchVisible={true}
                                        multiple={true}
                                        name="campaign.tagsIds"
                                        placeholder={translate('ajouter_des_tag_57392')}
                                    >
                                        {tags.map(({ id, name }) => (
                                            <option key={id} value={id}>
                                                {name}
                                            </option>
                                        ))}
                                    </RichSelect>
                                </>
                            )}
                        </>
                    )}
                </Box>
            </>
        );
    } else {
        return null;
    }
};
