import { Form } from 'common-front/src/components/form/form';
import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { OrganizationId } from 'common/src/generated/types';
import { DesignInputService, IDesignInputValues } from 'common/src/input/designInput';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { CreateUpdateDesign } from '../../../../designs/create/createUpdateDesign';
import {
    useFormParametersDesignQuery,
    useFormParametersDesignUpdateMutation
} from '../../../../generated/graphqlHooks';
import { FormContainer } from '../../../show/formContainer';
import { FormParametersContainer } from '../formParametersContainer';
import { useFormParametersClose } from '../useFormParametersClose';

interface IFormParametersDesignInnerProps {
    initialValues: IDesignInputValues;
    isMutateLoading: boolean;
    loader: React.JSX.Element | null;
    name: string;
    organizationId: OrganizationId;
    values: IDesignInputValues;

    handleSubmit(): void;
}

const FormParametersDesignInner = (props: IFormParametersDesignInnerProps) => {
    const translate = useTranslate();
    const { isNotSavedModalOpen, onClose, onNotSavedClose, onSave } = useFormParametersClose(
        props.initialValues,
        props.values,
        props.handleSubmit
    );

    return (
        <FormContainer
            button={
                <Button isLoading={props.isMutateLoading} onClick={onSave}>
                    {translate('enregistrer_06519')}
                </Button>
            }
            title={props.name}
            onClose={onClose}
        >
            <FormParametersContainer
                isNotSavedOpen={isNotSavedModalOpen}
                onNotSavedClose={onNotSavedClose}
            >
                {props.loader || (
                    <CreateUpdateDesign
                        organizationId={props.organizationId}
                        prefix="design."
                        showLogo={false}
                        title={translate('images_de_votre_00576')}
                    />
                )}
            </FormParametersContainer>
        </FormContainer>
    );
};

export const FormParametersDesign = () => {
    const {
        params: { organizationId, eventId, formId }
    } = useHeavent();
    const { data, loader } = useFormParametersDesignQuery({ organizationId, formId });
    useTitle(data.organization?.form.name ?? '');
    const designInput = useService(DesignInputService);
    const { mutate, isLoading } = useFormParametersDesignUpdateMutation();
    const initialValues = React.useMemo(
        () => ({
            design: designInput.formParametersDesignInputDefault(data.organization?.form.design)
        }),
        [data.organization]
    );

    return (
        <Form
            direction="column"
            height={1}
            initialValues={initialValues}
            render={({ handleSubmit, values }) => (
                <FormParametersDesignInner
                    handleSubmit={handleSubmit}
                    initialValues={initialValues}
                    isMutateLoading={isLoading}
                    loader={loader}
                    name={data.organization?.form.name ?? ''}
                    organizationId={organizationId}
                    values={values}
                />
            )}
            width={1}
            onSubmit={(values: IDesignInputValues) =>
                mutate({
                    organizationId,
                    eventId,
                    formId,
                    design: values.design
                })
            }
        />
    );
};
