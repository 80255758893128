import { groupBy } from 'lodash-es';
import * as React from 'react';
import { AccreditationCategory } from '../../components/accreditations/v2/accreditationCategory';
import { AccreditationsCategories } from '../../components/accreditations/v2/accreditationsCategories';
import { UserPosition } from '../userPosition';
import { usePositionsRightPanelContext } from './positionsRightPanelContext';

export const PositionsRightPanelAssignedTab = () => {
    const {
        assignedCategories,
        assignedPsuis,
        canCheckIn,
        canDelete,
        onAssignedPsuiDelete,
        reloadCheckIn
    } = usePositionsRightPanelContext();
    const idToAssignedPsuis = React.useMemo(
        () => groupBy(assignedPsuis, (psui) => psui.positionCategory.id),
        [assignedPsuis]
    );

    return (
        <AccreditationsCategories>
            {assignedCategories.map((category) => {
                const psuis = idToAssignedPsuis[category.id];

                return (
                    <AccreditationCategory key={category.id} category={category}>
                        {psuis.map((psui) => (
                            <UserPosition
                                key={psui.id}
                                canCheckIn={
                                    typeof canCheckIn === 'function'
                                        ? canCheckIn(psui.positionCategoryId, psui.position.id)
                                        : canCheckIn
                                }
                                canDelete={canDelete(psui.positionCategoryId, psui.position.id)}
                                positionSlotUserInfo={psui}
                                reloadCheckIn={reloadCheckIn}
                                onDelete={onAssignedPsuiDelete}
                            />
                        ))}
                    </AccreditationCategory>
                );
            })}
        </AccreditationsCategories>
    );
};
