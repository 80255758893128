import { Empty } from 'common-front/src/components/empty/empty';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { BackofficeInvoices } from './invoices/backofficeInvoices';
import { BackofficeOrganizations } from './organizations/list/backofficeOrganizations';
import { UpdateBackofficeOrganization } from './organizations/update/updateBackofficeOrganization';

export const Backoffice = () => {
    const translate = useTranslate();
    useTitle(translate('back_office_83072'));

    return (
        <Switch>
            <Route exact path={HeaventPaths.BACKOFFICE_ORGANIZATIONS}>
                <BackofficeOrganizations />
            </Route>

            <Route exact path={HeaventPaths.EDIT_BACKOFFICE_ORGANIZATION(':organizationId')}>
                <UpdateBackofficeOrganization />
            </Route>

            <Route exact path={HeaventPaths.BACKOFFICE_INVOICES}>
                <BackofficeInvoices />
            </Route>

            <Route>
                <Empty path={HeaventPaths.BACKOFFICE_ORGANIZATIONS} replace={true} />
            </Route>
        </Switch>
    );
};
