import {
    DelegationId,
    EventId,
    MassActionSelectedsInput,
    MassAssignStrategy
} from 'common/src/generated/types';
import { IMassAccreditValues, MassAccreditInputService } from 'common/src/input/massAccreditInput';
import { useService } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { FormRenderProps } from 'react-final-form';
import { CenteredContainer } from '../../components/centeredContainer/centeredContainer';
import { FormWizard, FormWizardPage } from '../../components/form/formWizard';
import { FullScreenPopup } from '../../components/fullScreenPopup/fullScreenPopup';
import { Portal } from '../../components/portal';
import { useVolunteersRegistrationsMassWishedAccreditationsUpdateMutation } from '../../generated/graphqlHooks';
import { useHeavent } from '../../hooks/useHeavent';
import { MassAccreditStep1 } from '../massAccredit/massAccreditStep1';
import { MassAccreditStep2 } from '../massAccredit/massAccreditStep2';

interface IMassWishedAccreditationsUpdateModalProps {
    delegationId: DelegationId;
    eventId: EventId;
    numberOfSelected: number;
    selecteds: MassActionSelectedsInput;

    onClose(): void;
    onSuccess(): void;
}

export const MassWishedAccreditationsUpdateModal = (
    props: IMassWishedAccreditationsUpdateModalProps
) => {
    const { translate } = useHeavent();
    const massAccreditInput = useService(MassAccreditInputService);
    const { mutate } = useVolunteersRegistrationsMassWishedAccreditationsUpdateMutation();

    return (
        <Portal>
            <FullScreenPopup
                category={translate('Accreditation')}
                title={translate('update_desired_accreditations')}
                onClose={props.onClose}
            >
                <CenteredContainer css={{ py: '$7' }}>
                    <FormWizard
                        initialValues={{
                            massAccredit: {
                                selecteds: props.selecteds,
                                strategy: MassAssignStrategy.Add,
                                accreditationsSlotsIds: []
                            }
                        }}
                        isEdit={false}
                        onSubmit={async (values: IMassAccreditValues) => {
                            await mutate({
                                eventId: props.eventId,
                                delegationId: props.delegationId,
                                massAccredit: values.massAccredit
                            });

                            props.onSuccess();
                            props.onClose();
                        }}
                    >
                        <FormWizardPage
                            render={({ handleSubmit }) => (
                                <MassAccreditStep1 handleSubmit={handleSubmit} />
                            )}
                            schema={massAccreditInput.massAccreditStep1Schema()}
                            title={translate('initialisation_38640')}
                        />

                        <FormWizardPage
                            render={({
                                form,
                                handleSubmit,
                                values,
                                submitting
                            }: FormRenderProps<IMassAccreditValues>) => (
                                <MassAccreditStep2
                                    buttonText={
                                        values.massAccredit.strategy === MassAssignStrategy.Delete
                                            ? translate('supprimer_les_a_93519')
                                            : translate('update_desired_accreditations')
                                    }
                                    change={form.change}
                                    delegationId={props.delegationId}
                                    eventId={props.eventId}
                                    handleSubmit={handleSubmit}
                                    numberOfSelected={props.numberOfSelected}
                                    submitting={submitting}
                                    values={values}
                                />
                            )}
                            schema={massAccreditInput.massAccreditStep2Schema()}
                            title={translate('accr_ditations_39450')}
                        />
                    </FormWizard>
                </CenteredContainer>
            </FullScreenPopup>
        </Portal>
    );
};
