import { AvatarNameEmail } from 'common-front/src/components/avatarNameEmail';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { customFieldTypeToIcon } from 'common-front/src/vo/customField';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Cell } from 'common/src/designSystem/components/table/cell';
import {
    CustomFieldFragment,
    CustomFieldVariety,
    OrganizationId,
    SegmentId
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { varietyToTranslation } from 'common/src/vo/customField';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import { CustomFieldsCategoryCell } from './cells/customFieldsCategoryCell';
import { CustomFieldsLabelCell } from './cells/customFieldsLabelCell';
import { CustomFieldsNameCell } from './cells/customFieldsNameCell';
import { CustomFieldsPrivacyCell } from './cells/customFieldsPrivacyCell';

interface ICustomFieldCellsProps {
    column: PossibleColumn;
    organizationId: OrganizationId;
    row: CustomFieldFragment;
    segmentId: SegmentId;

    reloadRow(): void;
}

export const CustomFieldCells = (props: ICustomFieldCellsProps) => {
    const { history, translate } = useHeavent();
    const dateTimeService = useService(DateTimeService);

    return props.column.slug === 'id' ? (
        <Cell width={100}>{props.row.id}</Cell>
    ) : props.column.slug === 'name' ? (
        <CustomFieldsNameCell customField={props.row} reload={props.reloadRow} />
    ) : props.column.slug === 'label' ? (
        <CustomFieldsLabelCell customField={props.row} reload={props.reloadRow} />
    ) : props.column.slug === 'category' ? (
        <CustomFieldsCategoryCell customField={props.row} reload={props.reloadRow} />
    ) : props.column.slug === 'fieldType' ? (
        <Cell width={250}>
            <Flex>
                <Box color="gray800" css={{ width: '18px' }}>
                    <I icon={customFieldTypeToIcon(props.row.fieldType, props.row.fieldProperty)} />
                </Box>

                <Spacer width="2" />

                <Box color="gray800">{translate(props.row.fieldType)}</Box>
            </Flex>
        </Cell>
    ) : props.column.slug === 'numberOfTimesUsed' ? (
        <Cell width={200}>
            {props.row.numberOfTimesUsed === 0 ? (
                props.row.numberOfTimesUsed
            ) : (
                <Box
                    color="primary700"
                    css={{
                        cursor: 'pointer',
                        textDecoration: 'underline'
                    }}
                    onClick={() => {
                        history.push(
                            HeaventPaths.CUSTOM_FIELD_USAGE(
                                props.organizationId,
                                props.segmentId,
                                props.row.id
                            )
                        );
                    }}
                >
                    {props.row.numberOfTimesUsed}
                </Box>
            )}
        </Cell>
    ) : props.column.slug === 'state' ? (
        <CustomFieldsPrivacyCell customField={props.row} reload={props.reloadRow} />
    ) : props.column.slug === 'values' ? (
        <Cell>{props.row.values.map(({ value }) => value).join(', ')}</Cell>
    ) : props.column.slug === 'variable' ? (
        <Cell>
            {props.row.variety === CustomFieldVariety.UserInfo ? `{user.${props.row.slug}}` : ''}
        </Cell>
    ) : props.column.slug === 'variety' ? (
        <Cell>{varietyToTranslation(translate, props.row.variety)}</Cell>
    ) : props.column.slug === 'createdBy' ? (
        <Cell>{props.row.createdBy && <AvatarNameEmail userInfo={props.row.createdBy} />}</Cell>
    ) : props.column.slug === 'createdAt' ? (
        <Cell>
            {dateTimeService.toLocaleString(props.row.insertedAt.toLocal(), LocaleFormats.DateTime)}
        </Cell>
    ) : (
        <Cell />
    );
};
