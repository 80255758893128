import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { percentColor } from 'common-front/src/designSystem/components/progressBar';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { PositionPlanningFragment } from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { fullName } from 'common/src/vo/positionSlot';
import * as React from 'react';
import { PositionsPlanningSlotModal } from './positionsPlanningSlotModal';

interface IPositionsPlanningSlotProps {
    position: PositionPlanningFragment;
    slot: PositionPlanningFragment['slots'][number];
}

export const PositionsPlanningSlot = (props: IPositionsPlanningSlotProps) => {
    const intervalService = useService(IntervalService);
    const name = fullName(intervalService, props.slot, '', {
        interval: { options: { hideDate: true } },
        slot: { includeRange: true }
    });

    return (
        <Dropdown>
            <Trigger>
                <Flex
                    align="center"
                    css={{
                        background: percentColor(props.slot.fillingRate / 100),
                        borderRadius: '$1',
                        cursor: 'pointer',
                        px: '$2',
                        userSelect: 'none'
                    }}
                    height={1}
                    width={1}
                >
                    <Box css={{ ellipsis: true }} font="white textXs medium" title={name} width={1}>
                        {name}
                    </Box>
                </Flex>
            </Trigger>

            <Menu css={{ padding: '0' }} placement="bottom-start" renderOnPortal={true} width={460}>
                <PositionsPlanningSlotModal
                    category={props.position.category}
                    position={props.position}
                    slot={props.slot}
                />
            </Menu>
        </Dropdown>
    );
};
