import { Calendar } from 'common-front/src/components/calendar/calendar';
import { UserPositionsQuery } from 'common/src/generated/types';
import { DateTime } from 'luxon';
import * as React from 'react';
import { UserPositionsAssignedCalendarPosition } from './userPositionsAssignedCalendarPosition';

interface IUserPositionsAssignedCalendarProps {
    assignments: UserPositionsQuery['event']['volunteerRegistration']['positionsSlotsUsersInfos'];
    endAt: DateTime;
    selectedDay: DateTime;
    startAt: DateTime;
}

export const UserPositionsAssignedCalendar = (props: IUserPositionsAssignedCalendarProps) => (
    <Calendar.Root
        css={{
            borderTop: '1px solid $gray200'
        }}
        date={props.selectedDay}
        maxDate={props.endAt}
        minDate={props.startAt}
    >
        {props.assignments.map((psui) => (
            <Calendar.Event
                key={psui.id}
                endAt={psui.positionSlot.range.start!}
                startAt={psui.positionSlot.range.start!}
            >
                <UserPositionsAssignedCalendarPosition psui={psui} />
            </Calendar.Event>
        ))}
    </Calendar.Root>
);
