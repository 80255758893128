import { compact, sortBy, uniqBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../designSystem/components/box';
import { Flex } from '../../designSystem/components/flex';
import { RichText } from '../../designSystem/components/richEditor/richText';
import { Spacer } from '../../designSystem/components/spacer';
import { Span } from '../../designSystem/components/span';
import { CommonEnvVars } from '../../envVars';
import {
    AssigmentEmailFragment,
    CustomFieldWithConditionFragment,
    DocumentUserAssignmentsFragment,
    EventId,
    FieldType,
    OrganizationId,
    UserAssignmentsDocumentOptions
} from '../../generated/types';
import { isValidColor } from '../../util/color';
import { useService, useTranslate } from '../../util/dependencies/dependencies';
import { googleMapsHref } from '../../util/googleMaps';
import { isNonEmptyString } from '../../util/string';
import { FieldService } from '../../vo/field';
import { hasAddress, hasAddressOrCoordinates, hasCoordinates } from '../../vo/position';
import { UserAssignmentsContainer } from './userAssignmentsContainer';

interface IUserAssignmentsPageProps {
    customFields: CustomFieldWithConditionFragment[];
    eventId: EventId;
    options: UserAssignmentsDocumentOptions;
    organizationId: OrganizationId;
    psuis: AssigmentEmailFragment[];
    ticketId: string;
    userInfo: DocumentUserAssignmentsFragment['organization']['userInfo'];
}

export const UserAssignmentsPage = (props: IUserAssignmentsPageProps) => {
    const translate = useTranslate();
    const fieldService = useService(FieldService);
    const position = props.psuis[0].position;
    const slots = sortBy(
        props.psuis.map(({ positionSlot }) => positionSlot),
        (slot) => slot.range.start!.toMillis()
    );
    const isEcoTrail = props.organizationId === 168;
    const isExaequo = props.organizationId === 573;
    const isAso = props.organizationId === 858;
    const subtitlesStyle = {
        color: isValidColor(props.options.subtitleColor) ? props.options.subtitleColor : '$gray800',
        fontFamily: props.options.titleFontFamily || 'Open Sans',
        fontSize: '14px',
        fontWeight: '700',
        textTransform: 'uppercase'
    };
    const leaders = uniqBy(
        position.leaders.concat(position.category.leaders),
        (l) => l.userInfo.id
    );
    const showLeaderEmail =
        props.options.leadersData === 'both' || props.options.leadersData === 'email';
    const showLeaderPhone =
        props.options.leadersData === 'both' || props.options.leadersData === 'phone';

    return (
        <>
            <UserAssignmentsContainer
                options={props.options}
                organizationId={props.organizationId}
                psuis={props.psuis}
                ticketId={props.ticketId}
                userInfo={props.userInfo}
            >
                {props.options.showLeaders && !isExaequo && !isAso && (
                    <>
                        <Spacer height="1" />

                        <Box
                            css={{
                                color: isValidColor(props.options.subtitleColor)
                                    ? props.options.subtitleColor
                                    : '$gray800',
                                fontSize: '14px',
                                fontWeight: '700'
                            }}
                            textAlign="center"
                            width={1}
                        >
                            {translate('ton_responsable_16222')}{' '}
                            {leaders
                                .map((leader) =>
                                    compact([
                                        leader.userInfo.name,
                                        showLeaderEmail ? leader.userInfo.email : '',
                                        showLeaderPhone
                                            ? (leader.userInfo.phone?.internationalFormat ?? '')
                                            : ''
                                    ]).join(' ')
                                )
                                .join(', ')}
                        </Box>
                    </>
                )}

                <Spacer height="7" />

                {!isAso && (
                    <Flex css={{ gap: '2px' }} direction="column">
                        <Box css={subtitlesStyle}>{translate('date_de_ta_miss_18706')}</Box>

                        <Flex direction="column" gap="1">
                            {slots.map((slot, index) => {
                                const start = slot.range.start!;
                                const end = slot.range.end!;
                                const isSameDay =
                                    start.startOf('day').toMillis() ===
                                    end.startOf('day').toMillis();

                                return (
                                    <Box key={index}>
                                        {isNonEmptyString(slot.name) ? `${slot.name} | ` : ''}
                                        <Span css={{ textTransform: 'capitalize' }}>
                                            {start.toFormat('cccc d MMMM yyyy HH:mm', {
                                                locale: 'fr'
                                            })}
                                        </Span>{' '}
                                        -{' '}
                                        <Span css={{ textTransform: 'capitalize' }}>
                                            {isSameDay
                                                ? end.toFormat('HH:mm')
                                                : end.toFormat('cccc d MMMM yyyy HH:mm', {
                                                      locale: 'fr'
                                                  })}
                                        </Span>
                                    </Box>
                                );
                            })}
                        </Flex>
                    </Flex>
                )}

                {isAso && (
                    <Flex css={{ gap: '2px' }} direction="column">
                        <Box css={subtitlesStyle}>Rendez-vous</Box>

                        <Flex direction="column" gap="1">
                            {slots.map((slot, index) => (
                                <Box key={index}>
                                    <Span css={{ textTransform: 'capitalize' }} fontWeight="bold">
                                        {slot.range.start!.toFormat(`cccc d MMMM yyyy à H'h'mm`, {
                                            locale: 'fr'
                                        })}
                                    </Span>{' '}
                                    (fin de mission esimtée à {slot.range.end!.toFormat(`H'h'mm`)})
                                </Box>
                            ))}
                        </Flex>
                    </Flex>
                )}

                <Spacer height="6" />

                <Flex css={{ gap: '2px' }} direction="column">
                    <Box css={subtitlesStyle}>{translate('description_de_15187')}</Box>

                    <Flex direction="column">
                        {isNonEmptyString(position.description) && (
                            <>
                                <Box
                                    css={{
                                        '& .h-rich-text > p': {
                                            margin: '0'
                                        }
                                    }}
                                >
                                    <RichText text={position.description} />
                                </Box>
                            </>
                        )}

                        {hasAddressOrCoordinates(position) && (
                            <>
                                <Spacer height="6" />

                                <Box css={subtitlesStyle}>
                                    {isExaequo
                                        ? 'Point de rendez-vous'
                                        : translate('lieu_de_rendez_30016')}
                                </Box>

                                {hasAddress(position) && (
                                    <>
                                        <Spacer height="1" />

                                        <Box>{position.address}</Box>
                                    </>
                                )}

                                {hasCoordinates(position) && isExaequo && (
                                    <Box>
                                        Point GPS : {position.latitude}, {position.longitude}
                                    </Box>
                                )}

                                {hasCoordinates(position) && (
                                    <>
                                        <Spacer height="1" />

                                        <Flex gap="3" width={1}>
                                            <Box css={{ flex: '1' }} height={180}>
                                                <a
                                                    href={googleMapsHref(
                                                        position.latitude!,
                                                        position.longitude!
                                                    )}
                                                    style={{
                                                        background: `url(${CommonEnvVars.HEAVENT_API_URL}/image/event/${props.eventId}/position/${position.id}.png)`,
                                                        backgroundPosition: 'center',
                                                        backgroundSize: 'cover',
                                                        display: 'block',
                                                        height: '100%',
                                                        width: '100%'
                                                    }}
                                                />
                                            </Box>

                                            {isEcoTrail &&
                                                isNonEmptyString(position.fields?.cf44697?.url) && (
                                                    <Box
                                                        css={{
                                                            background: `url(${position.fields.cf44697.url}) no-repeat`,
                                                            backgroundSize: 'contain'
                                                        }}
                                                        height={180}
                                                        width={204}
                                                    />
                                                )}

                                            {isExaequo &&
                                                isNonEmptyString(position.fields?.cf46814?.url) && (
                                                    <Box
                                                        css={{
                                                            background: `url(${position.fields.cf46814.url}) no-repeat`,
                                                            backgroundSize: 'contain'
                                                        }}
                                                        height={180}
                                                        width={246}
                                                    />
                                                )}

                                            {isAso &&
                                                isNonEmptyString(position.fields?.cf45815?.url) && (
                                                    <Box
                                                        css={{
                                                            background: `url(${position.fields.cf45815.url}) no-repeat`,
                                                            backgroundSize: 'contain'
                                                        }}
                                                        height={180}
                                                        width={204}
                                                    />
                                                )}
                                        </Flex>
                                    </>
                                )}

                                {isExaequo && isNonEmptyString(position.fields?.cf46813) && (
                                    <>
                                        <Spacer height="1" />

                                        <Box>{position.fields.cf46813}</Box>
                                    </>
                                )}
                            </>
                        )}
                    </Flex>
                </Flex>

                {isExaequo && (
                    <>
                        <Spacer height="6" />

                        <Flex css={{ gap: '2px' }} direction="column">
                            <Box css={subtitlesStyle}>UNE QUESTION OU UN PROBLÈME ?</Box>

                            <Box>
                                Nous sommes là pour vous aider. Contactez votre responsable de
                                secteur :{' '}
                                <Span fontWeight="bold">
                                    {leaders[0]?.userInfo.name} :{' '}
                                    {leaders[0]?.userInfo.phone?.internationalFormat}
                                </Span>
                            </Box>
                        </Flex>
                    </>
                )}

                {props.customFields.map((cf) => {
                    const valueString = fieldService.getValueString(cf, position.fields, {
                        fileReturnValue: 'url'
                    });

                    if (
                        props.options.positionCustomFields?.includes(cf.id) &&
                        isNonEmptyString(valueString)
                    ) {
                        return (
                            <>
                                <Spacer height="6" />

                                <Flex css={{ gap: '2px' }} direction="column">
                                    <Box css={subtitlesStyle}>{cf.name}</Box>

                                    {cf.fieldType === FieldType.File ? (
                                        <Box
                                            css={{
                                                background: `url(${valueString}) no-repeat`,
                                                backgroundPosition: 'center',
                                                backgroundSize: 'cover'
                                            }}
                                            height={180}
                                            width={1}
                                        />
                                    ) : (
                                        <Box
                                            dangerouslySetInnerHTML={{
                                                __html: valueString.replace(/\n/g, '<br />')
                                            }}
                                        />
                                    )}
                                </Flex>
                            </>
                        );
                    } else {
                        return null;
                    }
                })}

                <Box css={{ flex: '1' }} />

                {isNonEmptyString(props.options.signature) && (
                    <>
                        <Box
                            css={{
                                '& .h-rich-text > p': {
                                    margin: '0'
                                }
                            }}
                        >
                            <RichText text={props.options.signature} />
                        </Box>
                    </>
                )}
            </UserAssignmentsContainer>

            {isAso && isNonEmptyString(position.fields?.cf24781?.url) && (
                <UserAssignmentsContainer
                    options={props.options}
                    organizationId={props.organizationId}
                    psuis={props.psuis}
                    ticketId={props.ticketId}
                    userInfo={props.userInfo}
                >
                    <Spacer height="1" />

                    <Box
                        css={{
                            color: isValidColor(props.options.titleColor)
                                ? props.options.titleColor
                                : '$gray800',
                            fontFamily: props.options.titleFontFamily || 'Open Sans',
                            fontSize: '16px',
                            fontWeight: '700',
                            textAlign: 'center'
                        }}
                    >
                        (Annexe)
                    </Box>

                    <Spacer height="7" />

                    <Box
                        css={{
                            background: `url(${position.fields.cf24781.url}) no-repeat`,
                            backgroundSize: 'contain'
                        }}
                        height={1}
                        width={1}
                    />
                </UserAssignmentsContainer>
            )}
        </>
    );
};
