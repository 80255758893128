import { AvatarNameEmail } from 'common-front/src/components/avatarNameEmail';
import { Flex } from 'common/src/designSystem/components/flex';
import { DocumentUsersPlanningFragment } from 'common/src/generated/types';
import * as React from 'react';

interface IVolunteersPlanningLeftCellProps {
    vr: DocumentUsersPlanningFragment;
}

export const VolunteersPlanningLeftCell = (props: IVolunteersPlanningLeftCellProps) => (
    <Flex
        align="center"
        css={{ padding: '$2 $4' }}
        direction="column"
        height={1}
        justify="center"
        width={1}
    >
        <AvatarNameEmail size={32} userInfo={props.vr.userInfo} />
    </Flex>
);
