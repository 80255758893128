import { Accordion } from 'common-front/src/designSystem/components/accordion';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';
import { useDocumentBuilderContext } from '../documentBuilderContext';
import { DocumentBuilderLeftPanelAddElement } from './documentBuilderLeftPanelAddElement';
import { DocumentBuilderLeftPanelConditions } from './documentBuilderLeftPanelConditions';

export const DocumentLeftPanelPage = () => {
    const { translate } = useHeavent();
    const { getSelectedPage, updatePageProperty } = useDocumentBuilderContext();
    const selectedPage = getSelectedPage()!;

    return (
        <Flex direction="column">
            <Accordion css={{ padding: '$4 $6' }} title={translate('initialisation_38640')}>
                <TextInput
                    label={translate('nom_de_la_page_82146')}
                    value={selectedPage.name || ''}
                    onChange={(newName: string) => {
                        updatePageProperty('name', newName);
                    }}
                />

                <DocumentBuilderLeftPanelAddElement container={null} />
            </Accordion>

            <DocumentBuilderLeftPanelConditions
                element={selectedPage}
                elementId={selectedPage.id}
                updateProperty={(_id, key, value) => {
                    updatePageProperty(key, value);
                }}
            />
        </Flex>
    );
};
