import { DateTime } from 'luxon';
import * as React from 'react';
import { AllDayInBadge } from '../documents/customBadges/allDayIn/allDayInBadge';
import { BadgeAmbert } from '../documents/customBadges/ambert/badgeAmbert';
import { PlanningAmbert } from '../documents/customBadges/ambert/planningAmbert';
import { AnimajeConvention } from '../documents/customBadges/animaje/animajeConvention';
import { AsoAssignment } from '../documents/customBadges/aso/asoAssignment';
import { BadgeAuborddeleau } from '../documents/customBadges/auborddeleau/badgeAuborddeleau';
import { BadgeBobital } from '../documents/customBadges/bobital/badgeBobital';
import { BadgeBruleursA6 } from '../documents/customBadges/bruleurs/badgeBruleursA6';
import { BadgeElephants } from '../documents/customBadges/bruleurs/badgeElephants';
import { BadgesBruleurs } from '../documents/customBadges/bruleurs/badgesBruleurs';
import { CercleLivraison } from '../documents/customBadges/cercle/cercleLivraison';
import { CercleOpCom } from '../documents/customBadges/cercle/cercleOpCom';
import { CerclePressAndPartenaire } from '../documents/customBadges/cercle/cerclePressAndParternaire';
import { CercleStaff } from '../documents/customBadges/cercle/cercleStaff';
import { BadgeSeattle } from '../documents/customBadges/christmas/badgeSeattle';
import { BadgeVancouver } from '../documents/customBadges/christmas/badgeVancouver';
import { BadgeDelta } from '../documents/customBadges/delta/badgeDelta';
import { BadgeDfcoA4 } from '../documents/customBadges/dfco/badgeDfcoA4';
import { BadgeDfcoSmall } from '../documents/customBadges/dfco/badgeDfcoSmall';
import { Eurockeennes } from '../documents/customBadges/eurockeennes/eurockeennes';
import { EurockeennesA4 } from '../documents/customBadges/eurockeennes/eurockeennesA4';
import { BadgeFcg } from '../documents/customBadges/fcg/badgeFcg';
import { BadgesFcg } from '../documents/customBadges/fcg/badgesFcg';
import { BadgeFrancofoliesEsch } from '../documents/customBadges/francofoliesEsch/badgeFrancofoliesEsch';
import { BadgeGarorock } from '../documents/customBadges/garorock/badgeGarorock';
import { PlanningGarorock } from '../documents/customBadges/garorock/planningGarorock';
import { BadgeGoldenCoast } from '../documents/customBadges/goldenCoast/badgeGoldenCoast';
import { BadgeHbcLdc } from '../documents/customBadges/hbc/badgeHbcLcd';
import { BadgeHbcLnh } from '../documents/customBadges/hbc/badgeHbcLnh';
import { BadgeKermesse } from '../documents/customBadges/kermesse/badgeKermesse';
import { BadgeLnh } from '../documents/customBadges/lnh/badgeLnh';
import { BadgeMoorea } from '../documents/customBadges/moorea/badgeMoorea';
import { BadgeNuitsChampagne } from '../documents/customBadges/nuitsChampagne/badgeNuitsChampagne';
import { BadgeNuitsSecretes } from '../documents/customBadges/nuitsSecretes/badgeNuitsSecretes';
import { BadgeOpenBrest } from '../documents/customBadges/openBrest/badgeOpenBrest';
import { P2nAssignment } from '../documents/customBadges/p2n/p2nAssignment';
import { P2nAssignmentMontageDemontage } from '../documents/customBadges/p2n/p2nAssignmentMontageDemontage';
import { P2NBadge } from '../documents/customBadges/p2n/p2nBadge';
import { BadgeParis20km } from '../documents/customBadges/paris20km/badgeParis20km';
import { Paris20KmAssignment } from '../documents/customBadges/paris20km/paris20kmAssignment';
import { Paris20KmContremarque } from '../documents/customBadges/paris20km/paris20kmContremarque';
import { BadgePastourelle } from '../documents/customBadges/pastourelle/badgePastourelle';
import { BadgePastourelleexposant } from '../documents/customBadges/pastourelle/badgePastourelleexposant';
import { BadgePec } from '../documents/customBadges/pec/badgePec';
import { PlaygroundAssignment } from '../documents/customBadges/playground/playgroundAssignment';
import { BadgeRes2024Parking } from '../documents/customBadges/res/badgeRes2024Parking';
import { ContremarqueRes2024 } from '../documents/customBadges/res/contremarqueRes2024';
import { BadgeRoiArthur } from '../documents/customBadges/roiArthur/badgeRoiArthur';
import { BadgeRose } from '../documents/customBadges/rose/badgeRose';
import { BadgeRoseParking } from '../documents/customBadges/rose/badgeRoseParking';
import { BadgeSalamandreA4 } from '../documents/customBadges/salamandre/badgeSalamandreA4';
import { BadgesSalamandre } from '../documents/customBadges/salamandre/badgesSalamandre';
import { T24Assignment } from '../documents/customBadges/t24/t24Assignment';
import { BadgeUtopialesA4 } from '../documents/customBadges/utopiales/badgeUtopialesA4';
import { BadgeUtopialesImprimeur } from '../documents/customBadges/utopiales/badgeUtopialesImprimeur';
import { ContremarqueVandb } from '../documents/customBadges/vanb/contremarqueVandb';
import { BagdeVanne } from '../documents/customBadges/vannes/bagdeVanne';
import { VannesAssignment } from '../documents/customBadges/vannes/vannesAssignment';
import { BadgeVendanges } from '../documents/customBadges/vendanges/badgeVendanges';
import { BadgeVendeeGlobe } from '../documents/customBadges/vendeeGlobe/badgeVendeeGlobe';
import { ContremarqueVendeeGlobe } from '../documents/customBadges/vendeeGlobe/contremarqueVendeeGlobe';
import { CustomBadge } from '../generated/types';
import { assertUnreachable } from '../util/assertUnreachable';
import { Emptyable } from '../util/emptyable';
import { getDefaultBruleursDocumentOptions } from './eventDocument';

export function getCustomBadgeReactNode(
    badgeType: CustomBadge,
    event: any,
    options: Emptyable<any>,
    date: Emptyable<DateTime>
) {
    switch (badgeType) {
        case CustomBadge.Alldayin:
            return <AllDayInBadge event={event} />;
        case CustomBadge.Animajeconvention:
            return <AnimajeConvention event={event} />;
        case CustomBadge.Asoassignment:
            return <AsoAssignment date={date} event={event} options={options!} />;
        case CustomBadge.Auborddeleau:
            return <BadgeAuborddeleau event={event} />;
        case CustomBadge.Bobital:
            return <BadgeBobital event={event} />;
        case CustomBadge.Bruleurs:
            return <BadgeBruleursA6 event={event} options={options!} />;
        case CustomBadge.Cerclestaff:
            return <CercleStaff event={event} />;
        case CustomBadge.Cerclelivraison:
            return <CercleLivraison event={event} />;
        case CustomBadge.Cerclepressandpartenaire:
            return <CerclePressAndPartenaire event={event} />;
        case CustomBadge.Cercleopcom:
            return <CercleOpCom event={event} />;
        case CustomBadge.Delta:
            return <BadgeDelta event={event} />;
        case CustomBadge.Dfcoa4:
            return <BadgeDfcoA4 event={event} />;
        case CustomBadge.Dfcosmall:
            return <BadgeDfcoSmall event={event} />;
        case CustomBadge.Elephants:
            return <BadgeElephants event={event} />;
        case CustomBadge.Eurockeennes:
            return <EurockeennesA4 event={event} />;
        case CustomBadge.Fcg:
            return <BadgeFcg event={event} />;
        case CustomBadge.Francofoliesesch:
            return <BadgeFrancofoliesEsch event={event} />;
        case CustomBadge.Garorockbadge:
            return <BadgeGarorock event={event} />;
        case CustomBadge.Garorockplanning:
            return <PlanningGarorock event={event} />;
        case CustomBadge.Goldencoast:
            return <BadgeGoldenCoast event={event} />;
        case CustomBadge.Hbcldc:
            return <BadgeHbcLdc event={event} />;
        case CustomBadge.Hbclnh:
            return <BadgeHbcLnh event={event} />;
        case CustomBadge.Kermesse:
            return <BadgeKermesse event={event} />;
        case CustomBadge.Lnh:
            return <BadgeLnh event={event} />;
        case CustomBadge.Moorea:
            return <BadgeMoorea event={event} />;
        case CustomBadge.Nuitschampagne:
            return <BadgeNuitsChampagne event={event} />;
        case CustomBadge.Nuitssecretes:
            return <BadgeNuitsSecretes event={event} />;
        case CustomBadge.Openbrest:
            return <BadgeOpenBrest event={event} />;
        case CustomBadge.P2nassignment:
            return <P2nAssignment event={event} />;
        case CustomBadge.P2nassignmentmontagedemontage:
            return <P2nAssignmentMontageDemontage event={event} />;
        case CustomBadge.P2nbadge:
            return <P2NBadge event={event} />;
        case CustomBadge.Paris20km:
            return <BadgeParis20km event={event} />;
        case CustomBadge.Paris20kmassignment:
            return <Paris20KmAssignment date={date} event={event} />;
        case CustomBadge.Paris20kmcontremarque:
            return <Paris20KmContremarque event={event} />;
        case CustomBadge.Pastourelle:
            return <BadgePastourelle event={event} />;
        case CustomBadge.Pastourelleexposant:
            return <BadgePastourelleexposant event={event} />;
        case CustomBadge.Pec:
            return <BadgePec event={event} options={options!} />;
        case CustomBadge.Playground:
            return <PlaygroundAssignment event={event} />;
        case CustomBadge.Res2024contremarque:
            return <ContremarqueRes2024 event={event} />;
        case CustomBadge.Res2024parking:
            return <BadgeRes2024Parking event={event} />;
        case CustomBadge.Roiarthur:
            return <BadgeRoiArthur event={event} />;
        case CustomBadge.Rose:
            return <BadgeRose event={event} />;
        case CustomBadge.Roseparking:
            return <BadgeRoseParking event={event} />;
        case CustomBadge.Salamandre:
            return <BadgeSalamandreA4 event={event} />;
        case CustomBadge.Seattle:
            return <BadgeSeattle event={event} />;
        case CustomBadge.T24assignment:
            return <T24Assignment event={event} options={options!} />;
        case CustomBadge.Utopialesimprimeur:
            return <BadgeUtopialesImprimeur event={event} />;
        case CustomBadge.Utopialesa4:
            return <BadgeUtopialesA4 event={event} />;
        case CustomBadge.Vancouver:
            return <BadgeVancouver event={event} />;
        case CustomBadge.Vandbcontremarque:
            return <ContremarqueVandb event={event} />;
        case CustomBadge.Vannes:
            return <BagdeVanne event={event} />;
        case CustomBadge.Vannesassignment:
            return <VannesAssignment event={event} />;
        case CustomBadge.Vendanges:
            return <BadgeVendanges event={event} />;
        case CustomBadge.Vendeeglobebadge:
            return <BadgeVendeeGlobe event={event} />;
        case CustomBadge.Vendeeglobecontremarque:
            return <ContremarqueVendeeGlobe event={event} />;
        case CustomBadge.Wfa24:
            return <BadgeAmbert event={event} />;
        case CustomBadge.Wfa24planning:
            return <PlanningAmbert event={event} />;
        default:
            return assertUnreachable(badgeType);
    }
}

export function getCustomBadgesReactNode(badgeType: CustomBadge, event: any) {
    switch (badgeType) {
        case CustomBadge.Alldayin:
            throw new Error('Not implemented');
        case CustomBadge.Animajeconvention:
            throw new Error('Not implemented');
        case CustomBadge.Asoassignment:
            throw new Error('Not implemented');
        case CustomBadge.Auborddeleau:
            throw new Error('Not implemented');
        case CustomBadge.Bobital:
            throw new Error('Not implemented');
        case CustomBadge.Bruleurs:
            return <BadgesBruleurs event={event} options={getDefaultBruleursDocumentOptions()} />;
        case CustomBadge.Cerclestaff:
        case CustomBadge.Cerclelivraison:
        case CustomBadge.Cerclepressandpartenaire:
        case CustomBadge.Cercleopcom:
            throw new Error('Not implemented');
        case CustomBadge.Delta:
        case CustomBadge.Dfcoa4:
        case CustomBadge.Dfcosmall:
            throw new Error('Not implemented');
        case CustomBadge.Elephants:
            throw new Error('Not implemented');
        case CustomBadge.Eurockeennes:
            return <Eurockeennes event={event} />;
        case CustomBadge.Fcg:
            return <BadgesFcg event={event} />;
        case CustomBadge.Francofoliesesch:
            throw new Error('Not implemented');
        case CustomBadge.Garorockbadge:
        case CustomBadge.Garorockplanning:
            throw new Error('Not implemented');
        case CustomBadge.Goldencoast:
            throw new Error('Not implemented');
        case CustomBadge.Hbcldc:
        case CustomBadge.Hbclnh:
            throw new Error('Not implemented');
        case CustomBadge.Kermesse:
        case CustomBadge.Lnh:
        case CustomBadge.Moorea:
        case CustomBadge.Nuitschampagne:
        case CustomBadge.Nuitssecretes:
        case CustomBadge.Openbrest:
        case CustomBadge.P2nassignment:
        case CustomBadge.P2nassignmentmontagedemontage:
        case CustomBadge.P2nbadge:
            throw new Error('Not implemented');
        case CustomBadge.Paris20km:
        case CustomBadge.Paris20kmassignment:
        case CustomBadge.Paris20kmcontremarque:
            throw new Error('Not implemented');
        case CustomBadge.Pastourelle:
            throw new Error('Not implemented');
        case CustomBadge.Pastourelleexposant:
            throw new Error('Not implemented');
        case CustomBadge.Pec:
        case CustomBadge.Playground:
        case CustomBadge.Res2024contremarque:
        case CustomBadge.Res2024parking:
            throw new Error('Not implemented');
        case CustomBadge.Roiarthur:
            throw new Error('Not implemented');
        case CustomBadge.Rose:
        case CustomBadge.Roseparking:
            throw new Error('Not implemented');
        case CustomBadge.Salamandre:
            return <BadgesSalamandre event={event} />;
        case CustomBadge.Seattle:
            throw new Error('Not implemented');
        case CustomBadge.T24assignment:
            throw new Error('Not implemented');
        case CustomBadge.Utopialesimprimeur:
        case CustomBadge.Utopialesa4:
            throw new Error('Not implemented');
        case CustomBadge.Vancouver:
            throw new Error('Not implemented');
        case CustomBadge.Vandbcontremarque:
        case CustomBadge.Vannes:
        case CustomBadge.Vannesassignment:
            throw new Error('Not implemented');
        case CustomBadge.Vendanges:
            throw new Error('Not implemented');
        case CustomBadge.Vendeeglobebadge:
        case CustomBadge.Vendeeglobecontremarque:
            throw new Error('Not implemented');
        case CustomBadge.Wfa24:
        case CustomBadge.Wfa24planning:
            throw new Error('Not implemented');
        default:
            return assertUnreachable(badgeType);
    }
}

export function hasGroupedExport(badgeType: CustomBadge) {
    try {
        getCustomBadgesReactNode(badgeType, {});

        return true;
    } catch (e) {
        return e.message !== 'Not implemented';
    }
}

export function getDisplayName(badgeType: CustomBadge) {
    switch (badgeType) {
        case CustomBadge.Alldayin:
            return `Badge All Day In`;
        case CustomBadge.Animajeconvention:
            return `Animaje Convention`;
        case CustomBadge.Asoassignment:
            return `Fiche d'affectation ASO`;
        case CustomBadge.Auborddeleau:
            return `Badge Festival Week End au Bord de l'Eau`;
        case CustomBadge.Bobital:
            return `Badge Bobital`;
        case CustomBadge.Bruleurs:
            return `Badge Brûleurs de Loups`;
        case CustomBadge.Cerclestaff:
            return `Cercle Staff & Visiteurs`;
        case CustomBadge.Cerclelivraison:
            return `Cercle Livraison`;
        case CustomBadge.Cerclepressandpartenaire:
            return `Cercle Press & Partenaire`;
        case CustomBadge.Cercleopcom:
            return `Cercle OPCom`;
        case CustomBadge.Delta:
            return `Badge Delta`;
        case CustomBadge.Dfcoa4:
            return `Badge Dijon DFCO A4`;
        case CustomBadge.Dfcosmall:
            return `Badge Dijon DFCO Carte`;
        case CustomBadge.Elephants:
            return `Badge Elephants`;
        case CustomBadge.Eurockeennes:
            return `Planning Les Eurockéennes`;
        case CustomBadge.Fcg:
            return `Accréditation FCG`;
        case CustomBadge.Francofoliesesch:
            return `Contremarque Francofolies Esch/Alzette`;
        case CustomBadge.Garorockbadge:
            return `Badge Garorock`;
        case CustomBadge.Garorockplanning:
            return `Planning Garorock`;
        case CustomBadge.Goldencoast:
            return `Badge Golden Coast`;
        case CustomBadge.Hbcldc:
            return `Badge HBC Ligue des Champions`;
        case CustomBadge.Hbclnh:
            return `Badge HBC LNH`;
        case CustomBadge.Kermesse:
            return `Badge Kermesse`;
        case CustomBadge.Lnh:
            return `Badge LNH`;
        case CustomBadge.Moorea:
            return `Badge Moorea`;
        case CustomBadge.Nuitschampagne:
            return `Badge Nuits de Champagne`;
        case CustomBadge.Nuitssecretes:
            return `Badge Nuits Secrètes`;
        case CustomBadge.Openbrest:
            return `Badge Open de Brest`;
        case CustomBadge.P2nassignment:
            return `Fiche d'affectation P2N (Exploit)`;
        case CustomBadge.P2nassignmentmontagedemontage:
            return `Fiche d'affectation P2N (Montage/Démontage)`;
        case CustomBadge.P2nbadge:
            return `Badge P2N`;
        case CustomBadge.Paris20km:
            return `Badge 20km de Paris`;
        case CustomBadge.Paris20kmassignment:
            return `Fiche d'affectation 20km de Paris`;
        case CustomBadge.Paris20kmcontremarque:
            return `Contremarque 20km de Paris`;
        case CustomBadge.Pastourelle:
            return `Badge Pastourelle bénévoles`;
        case CustomBadge.Pastourelleexposant:
            return `Badge Pastourelle staffs`;
        case CustomBadge.Pec:
            return 'Badge Paris Entertainment Company';
        case CustomBadge.Playground:
            return `Fiche d'affectation Playground`;
        case CustomBadge.Res2024contremarque:
            return 'Contremarque et parkings Rock en Seine 2024';
        case CustomBadge.Res2024parking:
            return 'Parkings seulement Rock en Seine 2024';
        case CustomBadge.Roiarthur:
            return `Badge Roi Arthur`;
        case CustomBadge.Rose:
            return `Contremarque Rose Festival`;
        case CustomBadge.Roseparking:
            return `Rose Festival Pass Parking`;
        case CustomBadge.Salamandre:
            return `Badge Salamandre`;
        case CustomBadge.Seattle:
            return `Seattle Christmas Market`;
        case CustomBadge.T24assignment:
            return `Fiche d'affectation T24`;
        case CustomBadge.Utopialesimprimeur:
            return `Badge Utopiales (version imprimeur)`;
        case CustomBadge.Utopialesa4:
            return `Badge Utopiales (version A4)`;
        case CustomBadge.Vancouver:
            return `Vancouver Christmas Market`;
        case CustomBadge.Vandbcontremarque:
            return `V and B Fest' Convocation`;
        case CustomBadge.Vannes:
            return `Badge Marathon de Vannes`;
        case CustomBadge.Vannesassignment:
            return `Fiche d'affectation Marathon de Vannes`;
        case CustomBadge.Vendanges:
            return `Badge Les Vendanges Musicales`;
        case CustomBadge.Vendeeglobebadge:
            return `Badge Vendée Globe`;
        case CustomBadge.Vendeeglobecontremarque:
            return `Contremarque Vendée Globe`;
        case CustomBadge.Wfa24:
            return `Contremarque WFA24`;
        case CustomBadge.Wfa24planning:
            return `Planning WFA24`;
        default:
            return assertUnreachable(badgeType);
    }
}
