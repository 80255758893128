import { closeOtherDropdowns } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import { WeezeventRulesQuery } from 'common/src/generated/types';
import { TranslateFn } from 'common/src/services/translationService';
import * as React from 'react';

const getValue = (
    rule: WeezeventRulesQuery['event']['weezeventRules']['nodes'][0],
    translate: TranslateFn
) => {
    if (rule.idQuestion === 'ticket') {
        return rule.field?.choices?.find((c) => c.id === rule.value)?.label ?? '';
    } else {
        return typeof rule.value === 'boolean'
            ? rule.value
                ? translate('true')
                : translate('false')
            : rule.value;
    }
};

interface IWeezeventRuleRowProps {
    rule: WeezeventRulesQuery['event']['weezeventRules']['nodes'][0];
}

export const WeezeventRuleRow = (props: IWeezeventRuleRowProps) => {
    const { translate } = useHeavent();

    return (
        <Row
            css={{
                '&:hover': {
                    background: '$gray100'
                }
            }}
            onMouseLeave={(e) => {
                closeOtherDropdowns(e.target);
            }}
        >
            <Cell css={{ color: '$gray900' }}>{props.rule.name}</Cell>
            <Cell>{props.rule.segment.name}</Cell>
            <Cell>{props.rule.field?.name ?? props.rule.idQuestion}</Cell>
            <Cell>{getValue(props.rule, translate)}</Cell>
        </Row>
    );
};
