import { Blank } from 'common-front/src/components/blank/blank';
import { SelectSegment } from 'common-front/src/segments/massActions/selectSegment';
import { SearchRow } from 'common-front/src/segments/searchRow';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { SegmentTableHeader } from 'common-front/src/segments/segmentTableHeader';
import { Svgs } from 'common-front/src/util/assets';
import { PositionsRendering } from 'common-front/src/vo/positionsRendering';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { PositionsSegmentsQuery, SegmentCustomFieldFragment } from 'common/src/generated/types';
import { useLocation, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { PositionsSlotsList } from '../../positionsSlots/list/positionsSlotsList';
import { PositionsActions } from './list/positionsActions';
import { PositionsList } from './list/positionsList';
import { PositionsMapContainer } from './map/positionsMapContainer';
import { PositionsPlanning } from './planning/positionsPlanning';
import { PositionsHeader } from './positionsHeader';

interface IPositionsContentProps {
    customFields: SegmentCustomFieldFragment[];
    event: PositionsSegmentsQuery['event'];
}

export const PositionsContent = (props: IPositionsContentProps) => {
    const translate = useTranslate();
    const location = useLocation();
    const { isEventAdmin, isPositionCategoryAdmin, updateLocalEvent } = useEventContext();
    const { limit, nameDebounced, predicates, rendering, segment } = useSegmentsContext();
    const showEditColumns =
        isPositionCategoryAdmin() &&
        (rendering === PositionsRendering.List || rendering === PositionsRendering.SlotsList);
    const showEditMode =
        isEventAdmin &&
        (rendering === PositionsRendering.Calendar ||
            rendering === PositionsRendering.List ||
            rendering === PositionsRendering.SlotsList);

    React.useEffect(() => {
        updateLocalEvent({
            positionBackPath: location.pathname,
            positionCategoryBackPath: location.pathname
        });
    }, [segment]);

    return (
        <>
            <PositionsHeader
                name={segment.name}
                numberOfCategories={props.event.positionsCategories.length}
                segment={segment}
            />

            {props.event.numberOfPositions === 0 && rendering !== PositionsRendering.Map ? (
                <Box css={{ flex: '1', overflow: 'hidden' }}>
                    <Blank
                        imageSrc={Svgs.PositionsBlank}
                        subtitle={translate('plus_besoin_de_08254')}
                        title={translate('vous_n_avez_pas_08610')}
                    />
                </Box>
            ) : (
                <>
                    <Spacer height="7" />

                    <SegmentTableHeader
                        searchRow={
                            <SearchRow
                                actions={<PositionsActions />}
                                searchPlaceholder={translate('rechercher_une_69998')}
                                showEditColumns={showEditColumns}
                                showEditMode={showEditMode}
                                showSearchColumns={true}
                            />
                        }
                        selectSegment={
                            <SelectSegment
                                pageSelectedText={translate('les_1_mission_95719', limit)}
                                segmentSelectedText={translate(
                                    'les_1_mission_38484',
                                    segment.count,
                                    segment.name
                                )}
                            />
                        }
                    />

                    {rendering === PositionsRendering.List ? (
                        <PositionsList customFields={props.customFields} />
                    ) : rendering === PositionsRendering.Map ? (
                        <PositionsMapContainer name={nameDebounced} predicates={predicates} />
                    ) : rendering == PositionsRendering.SlotsList ? (
                        <PositionsSlotsList customFields={props.customFields} />
                    ) : (
                        <PositionsPlanning
                            endAt={props.event.endAt}
                            startAt={props.event.startAt}
                        />
                    )}
                </>
            )}
        </>
    );
};
