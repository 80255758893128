import { useVolunteersRegistrationsMassAccreditMutation } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import {
    AccreditationsSlotId,
    MassAssignStrategy,
    UsersInfoId,
    VolunteerRegistrationAccreditationsMatrixFragment
} from 'common/src/generated/types';
import * as React from 'react';

interface IVolunteersAccreditationsMatrixRowProps {
    slotsIds: AccreditationsSlotId[];
    vr: VolunteerRegistrationAccreditationsMatrixFragment;

    reloadRow(id: UsersInfoId): void;
}

export const VolunteersAccreditationsMatrixRow = (
    props: IVolunteersAccreditationsMatrixRowProps
) => {
    const {
        translate,
        params: { eventId }
    } = useHeavent();
    const { isEditMode } = useSegmentsContext();
    const { mutate: massAccredit } = useVolunteersRegistrationsMassAccreditMutation();
    const autoAssignedSlotsIds = React.useMemo(
        () =>
            props.vr.accreditationsUsersInfos.flatMap((aui) =>
                aui.isAutoAccredit ? [aui.accreditationSlotId] : []
            ),
        [props.vr]
    );
    const assignedSlotsIds = React.useMemo(
        () =>
            props.vr.accreditationsUsersInfos.flatMap((aui) =>
                aui.isAutoAccredit ? [] : [aui.accreditationSlotId]
            ),
        [props.vr]
    );
    const wishedSlotsIds = React.useMemo(
        () => props.vr.accreditationsSlots.map((s) => s.id),
        [props.vr]
    );

    return (
        <Flex css={{ borderTop: '1px solid $gray200', height: '$cellHeightSm' }}>
            {props.slotsIds.map((slotId) => {
                const isAutoAssigned = autoAssignedSlotsIds.includes(slotId);
                const isAssigned = assignedSlotsIds.includes(slotId);
                const isWished = wishedSlotsIds.includes(slotId);

                return (
                    <Flex
                        key={slotId}
                        align="center"
                        css={{
                            borderRight: '1px solid $gray200',
                            padding: '$3 $4',
                            width: '$cellWidth',
                            '& > .h-accreditations-matrix-cell': {
                                display: 'flex'
                            },
                            '&:hover > .h-accreditations-matrix-cell': {
                                display: 'none'
                            },
                            '& > .h-accreditations-matrix-edit-cell': {
                                display: 'none'
                            },
                            '&:hover > .h-accreditations-matrix-edit-cell': {
                                display: 'flex'
                            }
                        }}
                        height={1}
                    >
                        {isEditMode ? (
                            isAutoAssigned || isAssigned ? (
                                <Flex
                                    align="center"
                                    className="h-accreditations-matrix-edit-cell"
                                    css={{
                                        background: '$error200',
                                        bd: 'error500 1',
                                        color: '$error700',
                                        cursor: 'pointer'
                                    }}
                                    height={1}
                                    justify="center"
                                    width={1}
                                    onClick={async () => {
                                        await massAccredit({
                                            eventId,
                                            massAccredit: {
                                                accreditationsSlotsIds: [slotId],
                                                selecteds: { ids: [props.vr.userInfo.id] },
                                                strategy: MassAssignStrategy.Delete
                                            }
                                        });

                                        props.reloadRow(props.vr.userInfo.id);
                                    }}
                                >
                                    <I icon="trash-can" />
                                </Flex>
                            ) : (
                                <Flex
                                    align="center"
                                    className="h-accreditations-matrix-edit-cell"
                                    css={{
                                        background: '$primary200',
                                        bd: 'primary500 1',
                                        color: '$primary700',
                                        cursor: 'pointer'
                                    }}
                                    height={1}
                                    justify="center"
                                    width={1}
                                    onClick={async () => {
                                        await massAccredit({
                                            eventId,
                                            massAccredit: {
                                                accreditationsSlotsIds: [slotId],
                                                selecteds: { ids: [props.vr.userInfo.id] },
                                                strategy: MassAssignStrategy.Add
                                            }
                                        });

                                        props.reloadRow(props.vr.userInfo.id);
                                    }}
                                >
                                    <I icon="plus" />
                                </Flex>
                            )
                        ) : null}

                        {isAutoAssigned || isAssigned ? (
                            <Flex
                                align="center"
                                className={isEditMode ? 'h-accreditations-matrix-cell' : ''}
                                css={{
                                    background: '$success200',
                                    bd: 'success500 1',
                                    color: '$success700',
                                    fontSize: '$textMd'
                                }}
                                height={1}
                                justify="center"
                                width={1}
                            >
                                <I
                                    icon={
                                        autoAssignedSlotsIds.includes(slotId)
                                            ? 'bolt-auto'
                                            : 'check'
                                    }
                                />
                            </Flex>
                        ) : isWished ? (
                            <Flex
                                align="center"
                                className={isEditMode ? 'h-accreditations-matrix-cell' : ''}
                                css={{
                                    background: 'white',
                                    bd: 'gray300 1',
                                    borderStyle: 'dashed'
                                }}
                                height={1}
                                justify="center"
                                width={1}
                            >
                                <Box font="gray800 textXs medium">
                                    {translate('souhait_e_52100')}
                                </Box>
                            </Flex>
                        ) : null}
                    </Flex>
                );
            })}
        </Flex>
    );
};
