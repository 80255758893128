import { Button } from 'common-front/src/designSystem/components/button';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useEnumToOptions } from 'common-front/src/hooks/useEnumToOptions';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { getToken } from 'common-front/src/util/aws/cognito';
import { LocalStorageKeys } from 'common-front/src/util/localStorage';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { CommonEnvVars } from 'common/src/envVars';
import { ALL_INVOICESTATE, InvoiceState } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useLocalStorage } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { HeaventHeaders } from 'common/src/util/headers';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useBackofficeInvoicesQuery } from '../../generated/graphqlHooks';
import { BackofficeInvoiceRow } from './backofficeInvoiceRow';
import { BackofficeInvoicesColumns } from './backofficeInvoicesColumns';

export const BackofficeInvoices = () => {
    const { history, translate } = useHeavent();
    const localStorage = useLocalStorage();
    const enumToOptions = useEnumToOptions();
    const [id, idDebounced, setId] = useStateDebounce('');
    const [states, setStates] = React.useState<InvoiceState[]>([]);
    const [offset, setOffset] = React.useState(0);
    const { data, isLoading, reload } = useBackofficeInvoicesQuery({
        name: isNonEmptyString(idDebounced) ? idDebounced : undefined,
        states: isNonEmptyArray(states) ? states : undefined,
        offset
    });
    const [selectedColumns, _setSelectedColumns] = React.useState(() => {
        const localStorageColumns = localStorage.getItem(
            LocalStorageKeys.BACKOFFICE_INVOICES_COLUMNS
        );

        if (localStorageColumns) {
            return JSON.parse(localStorageColumns) as BackofficeInvoicesColumns[];
        } else {
            return [];
        }
    });
    const setSelectedColumns = React.useCallback(
        (newSelectedColumns: BackofficeInvoicesColumns[]) => {
            _setSelectedColumns(newSelectedColumns);
            localStorage.setItem(
                LocalStorageKeys.BACKOFFICE_INVOICES_COLUMNS,
                JSON.stringify(newSelectedColumns)
            );
        },
        [_setSelectedColumns]
    );
    const exportInvoices = React.useCallback(
        async (date: Emptyable<string>) => {
            try {
                const params = new URLSearchParams();

                if (date) {
                    params.set('date', date);
                } else if (isNonEmptyArray(states)) {
                    params.set('states', states.join(','));
                }

                const result = await fetch(
                    `${CommonEnvVars.HEAVENT_API_URL}/invoice/all?${params}`,
                    {
                        method: 'post',
                        headers: {
                            [HeaventHeaders.UserToken]: (await getToken()) || ''
                        }
                    }
                );
                const { link } = await result.json();

                window.open(link, '_blank');
            } catch {
                window.alert(translate('impossible_d_ex_55872'));
            }
        },
        [states]
    );
    const showColumn = (column: BackofficeInvoicesColumns) => selectedColumns.includes(column);

    return (
        <Box css={{ overflowY: 'auto' }} height={1} width={1}>
            <Flex css={{ padding: '$7' }} direction="column" width={1}>
                <Flex gap="4">
                    <Box
                        color="gray900"
                        css={{ flex: '1' }}
                        fontSize="displayXs"
                        fontWeight="semiBold"
                    >
                        {translate('factures_03552')}
                    </Box>

                    <Box>
                        <RichSelect
                            isSelectAllVisible={true}
                            multiple={true}
                            placeholder="Colonnes visibles"
                            values={selectedColumns}
                            onChange={setSelectedColumns}
                        >
                            <option value="organization">{translate('organisation_71863')}</option>
                            <option value="customerName">{translate('nom_du_client_28343')}</option>
                            <option value="date">{translate('Date')}</option>
                            <option value="paidAt">{translate('pay_e_le_92377')}</option>
                            <option value="amount">{translate('montant_55595')}</option>
                            <option value="amountTTC">{translate('montant_ttc_86677')}</option>
                            <option value="state">{translate('status_06428')}</option>
                            <option value="transactionId">
                                {translate('id_de_la_transa_98869')}
                            </option>
                        </RichSelect>
                    </Box>

                    <Button
                        onClick={() => {
                            history.push(HeaventPaths.HOME);
                        }}
                    >
                        Home
                    </Button>

                    <Dropdown>
                        <Trigger>
                            <Button color="white" leftIcon="ellipsis-vertical" />
                        </Trigger>

                        <Menu placement="bottom-end">
                            <ItemIcon
                                icon="eye"
                                onClick={() => {
                                    history.push(HeaventPaths.BACKOFFICE_ORGANIZATIONS);
                                }}
                            >
                                {translate('voir_les_organi_78756')}
                            </ItemIcon>

                            <ItemIcon icon="file-export" onClick={() => exportInvoices(null)}>
                                Exporter les factures sélectionnées
                            </ItemIcon>

                            <ItemIcon
                                icon="file-export"
                                onClick={() => exportInvoices('this-month')}
                            >
                                {translate('exporter_les_fa_11017')}
                            </ItemIcon>

                            <ItemIcon
                                icon="file-export"
                                onClick={() => exportInvoices('last-month')}
                            >
                                {translate('exporter_les_fa_01571')}
                            </ItemIcon>

                            <ItemIcon icon="file-export" onClick={() => exportInvoices('all')}>
                                {translate('exporter_toutes_46318')}
                            </ItemIcon>
                        </Menu>
                    </Dropdown>
                </Flex>

                <Spacer height="7" />

                <TableFilters
                    filters={
                        <Flex align="center" gap="3">
                            <Box width={320}>
                                <TextInput
                                    icon="magnifying-glass"
                                    placeholder={translate('id_ou_nom_25064')}
                                    value={id}
                                    onChange={(newId: string) => {
                                        setId(newId);
                                        setOffset(0);
                                    }}
                                />
                            </Box>

                            <Box width={200}>
                                <RichSelect
                                    isSearchVisible={true}
                                    multiple={true}
                                    values={states}
                                    onChange={setStates}
                                >
                                    {enumToOptions(ALL_INVOICESTATE)}
                                </RichSelect>
                            </Box>
                        </Flex>
                    }
                    headerCells={
                        <>
                            <HeaderCell>{translate('num_ro_de_factu_68150')}</HeaderCell>
                            {showColumn(BackofficeInvoicesColumns.Organization) && (
                                <HeaderCell>{translate('organisation_71863')}</HeaderCell>
                            )}
                            {showColumn(BackofficeInvoicesColumns.CustomerName) && (
                                <HeaderCell>{translate('nom_du_client_28343')}</HeaderCell>
                            )}
                            {showColumn(BackofficeInvoicesColumns.Date) && (
                                <HeaderCell>{translate('Date')}</HeaderCell>
                            )}
                            {showColumn(BackofficeInvoicesColumns.PaidAt) && (
                                <HeaderCell>{translate('pay_e_le_92377')}</HeaderCell>
                            )}
                            {showColumn(BackofficeInvoicesColumns.Amount) && (
                                <HeaderCell>{translate('montant_55595')}</HeaderCell>
                            )}
                            {showColumn(BackofficeInvoicesColumns.AmountTTC) && (
                                <HeaderCell>{translate('montant_ttc_86677')}</HeaderCell>
                            )}
                            {showColumn(BackofficeInvoicesColumns.State) && (
                                <HeaderCell>{translate('status_06428')}</HeaderCell>
                            )}
                            {showColumn(BackofficeInvoicesColumns.TransactionId) && (
                                <HeaderCell>{translate('id_de_la_transa_98869')}</HeaderCell>
                            )}
                            <HeaderCell />
                            <HeaderCell />
                        </>
                    }
                    numberOfPages={data.backoffice?.invoices.numberOfPages ?? 0}
                    offset={offset}
                    rows={
                        isLoading ? (
                            <>
                                <RowSkeleton bx={true} />
                                <RowSkeleton bx={true} />
                                <RowSkeleton bx={true} />
                            </>
                        ) : (
                            <>
                                {data.backoffice.invoices.nodes.map((invoice) => (
                                    <BackofficeInvoiceRow
                                        key={invoice.id}
                                        invoice={invoice}
                                        reload={reload}
                                        showColumn={showColumn}
                                    />
                                ))}
                            </>
                        )
                    }
                    setOffset={setOffset}
                    title={translate('liste_des_factu_86735')}
                    totalCount={data.backoffice?.invoices.totalCount ?? 0}
                />
            </Flex>
        </Box>
    );
};
