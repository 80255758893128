import { Calendar } from 'common-front/src/components/calendar/calendar';
import { UserProfileAccreditationsQuery } from 'common/src/generated/types';
import { DateTime } from 'luxon';
import * as React from 'react';
import { UserAccreditationsAssignedCalendarAccreditation } from './userAccreditationsAssignedCalendarAccreditation';

interface IUserAccreditationsAssignedCalendarProps {
    event: UserProfileAccreditationsQuery['event'];
    selectedDay: DateTime;
}

export const UserAccreditationsAssignedCalendar = (
    props: IUserAccreditationsAssignedCalendarProps
) => {
    const allEventSlots = React.useMemo(
        () =>
            props.event.volunteerRegistration.accreditationsUsersInfos.filter(
                (aui) => !aui.accreditationSlot.date?.isValid
            ),
        [props.event]
    );
    const daysSlots = React.useMemo(
        () =>
            props.event.volunteerRegistration.accreditationsUsersInfos.filter(
                (aui) => aui.accreditationSlot.date?.isValid
            ),
        [props.event]
    );

    return (
        <Calendar.Root
            css={{
                borderTop: '1px solid $gray200'
            }}
            date={props.selectedDay}
            maxDate={props.event.endAt}
            minDate={props.event.startAt}
        >
            {allEventSlots.map((aui) => (
                <Calendar.Event key={aui.id}>
                    <UserAccreditationsAssignedCalendarAccreditation aui={aui} />
                </Calendar.Event>
            ))}

            {daysSlots.map((aui) => (
                <Calendar.Event
                    key={aui.id}
                    endAt={aui.accreditationSlot.date!}
                    startAt={aui.accreditationSlot.date!}
                >
                    <UserAccreditationsAssignedCalendarAccreditation aui={aui} />
                </Calendar.Event>
            ))}
        </Calendar.Root>
    );
};
