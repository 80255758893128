import { Button } from 'common-front/src/designSystem/components/button';
import { Images } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I, IIcon } from 'common/src/designSystem/components/i';
import { LinkText } from 'common/src/designSystem/components/linkText';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';

interface IFeatureProps {
    description: string;
    icon: IIcon;
    title: string;
}

const Feature = (props: IFeatureProps) => (
    <Flex direction="column" width={1}>
        <Box css={{ color: 'black' }} fontSize="displayMd" textAlign="center">
            <I icon={props.icon} />
        </Box>

        <Spacer height="5" />

        <Box
            color="primary700"
            css={{
                fontFamily: '$montserrat',
                fontSize: '$textXl',
                textTransform: 'uppercase',
                fontWeight: '$bold',
                '@tablet': {
                    fontSize: '$displayXs'
                }
            }}
            fontSize="textXl"
            textAlign="center"
        >
            {props.title}
        </Box>

        <Spacer height="2" />

        <Box
            color="primary700"
            css={{
                fontFamily: '$madurai',
                fontSize: '$textMd',
                '@tablet': {
                    fontSize: '$displayXs'
                }
            }}
            fontSize="textMd"
            textAlign="center"
        >
            {props.description}
        </Box>
    </Flex>
);

/*
interface IFunctionalityProps {
    title: string;
    text: string;
}

const Functionality = (props: IFunctionalityProps) => {
    return (
        <Flex
            direction="column"
            gap="2"
            css={{
                background: 'white',
                borderRadius: '$2',
                boxShadow: '$xs',
                padding: '$5 $4',
                width: '100%',
                '@tablet': {
                    width: '540px'
                },
                '@desktop': {
                    width: '100%'
                }
            }}
        >
            <Box
                color="primary700"
                fontWeight="bold"
                textAlign="center"
                css={{ fontFamily: '$montserrat', fontSize: '20px' }}
            >
                {props.title}
            </Box>

            <Box
                color="primary700"
                textAlign="center"
                css={{
                    fontFamily: '$madurai',
                    fontSize: '16px',
                    '@tablet': {
                        fontSize: '20px'
                    }
                }}
            >
                {props.text}
            </Box>
        </Flex>
    );
};
*/

export const About = () => (
    <Box css={{ overflowY: 'auto' }} height={1} width={1}>
        <Flex
            css={{
                background: '$cyan',
                padding: '$9 $4',
                '@tablet': {
                    padding: '72px 68px'
                },
                '@desktop': {
                    padding: '96px 0'
                }
            }}
            justify="center"
            width={1}
        >
            <Flex
                css={{
                    flexDirection: 'column',
                    gap: '56px',
                    width: '100%',
                    '@tablet': {
                        gap: '88px'
                    },
                    '@desktop': {
                        flexDirection: 'row',
                        gap: '144px',
                        width: '1280px'
                    }
                }}
            >
                <Flex
                    css={{
                        justifyContent: 'center',
                        width: '100%',
                        '@desktop': {
                            alignItems: 'center',
                            height: '100%',
                            width: '440px'
                        }
                    }}
                >
                    <Box
                        css={{
                            height: '74px',
                            width: '180px',
                            '@tablet': {
                                height: '180px',
                                width: '440px'
                            },
                            '@desktop': {
                                height: '180px',
                                width: '440px'
                            }
                        }}
                    >
                        <img alt="Logo Vabene" height="100%" src={Images.MsoLogo1} width="100%" />
                    </Box>
                </Flex>

                <Flex direction="column">
                    <Box
                        color="white"
                        css={{
                            fontFamily: '$montserrat',
                            fontSize: '36px',
                            textAlign: 'center',
                            textTransform: 'uppercase',
                            '@tablet': {
                                fontSize: '40px'
                            },
                            '@desktop': {
                                fontSize: '40px',
                                textAlign: 'left'
                            }
                        }}
                        fontWeight="bold"
                    >
                        La gestion de bénévoles n’a jamais été aussi facile...
                    </Box>

                    <Spacer height="3" />

                    <Box
                        color="white"
                        css={{
                            fontFamily: '$montserrat',
                            fontSize: '16px',
                            '@tablet': {
                                fontSize: '20px'
                            },
                            '@desktop': {
                                fontSize: '20px',
                                textAlign: 'left'
                            }
                        }}
                        textAlign="center"
                    >
                        Vabene est la solution complète pour la gestion de bénévoles sur vos
                        événements sportifs et culturels.
                    </Box>

                    <Spacer height="7" />

                    <Flex
                        css={{
                            flexDirection: 'column',
                            gap: '$4',
                            px: '$6',
                            '@tablet': {
                                flexDirection: 'row',
                                justifyContent: 'center',
                                px: '0'
                            },
                            '@desktop': {
                                justifyContent: 'flex-start'
                            }
                        }}
                    >
                        <Flex
                            css={{
                                gap: '$1',
                                '@tablet': {
                                    gap: '$2'
                                }
                            }}
                            direction="column"
                        >
                            <Box css={{ width: '100%', '@tablet': { width: '160px' } }}>
                                <Button
                                    size="xl"
                                    textAlign="center"
                                    to={'/auth/sign-up/credentials'}
                                >
                                    Essayer 14 jours
                                </Button>
                            </Box>

                            <Box
                                color="white"
                                css={{ textAlign: 'center', '@tablet': { textAlign: 'left' } }}
                                fontSize="textXs"
                            >
                                *Version desktop uniquement
                            </Box>
                        </Flex>

                        <Flex>
                            <Box css={{ width: '100%', '@tablet': { width: '160px' } }}>
                                <Button size="xl" textAlign="center" to={'mailto:vabene@mso.swiss'}>
                                    Nous contacter
                                </Button>
                            </Box>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>

        <Flex
            css={{
                padding: '$9 $7',
                '@tablet': {
                    padding: '72px 84px'
                },
                '@desktop': {
                    padding: '96px 0'
                }
            }}
            justify="center"
            width={1}
        >
            <Flex
                css={{
                    gap: '40px',
                    width: '100%',
                    '@tablet': {
                        gap: '64px'
                    },
                    '@desktop': {
                        gap: '64px',
                        width: '1280px'
                    }
                }}
                direction="column"
            >
                <Flex direction="column">
                    <Box
                        color="primary700"
                        css={{
                            fontFamily: '$montserrat',
                            fontSize: '$textXl',
                            textTransform: 'uppercase',
                            '@tablet': {
                                fontSize: '$displayXs'
                            }
                        }}
                        fontWeight="bold"
                        textAlign="center"
                    >
                        Une gestion
                    </Box>

                    <Box
                        color="primary700"
                        css={{
                            fontFamily: '$montserrat',
                            fontSize: '$displayXs',
                            textTransform: 'uppercase',
                            '@tablet': {
                                fontSize: '40px'
                            }
                        }}
                        fontWeight="bold"
                        textAlign="center"
                    >
                        Tout-en-un
                    </Box>
                </Flex>

                <Flex
                    css={{
                        flexDirection: 'column',
                        gap: '56px',
                        '@desktop': {
                            flexDirection: 'row',
                            justifyContent: 'center',
                            gap: '$7'
                        }
                    }}
                >
                    <Feature
                        description="Gérez tout depuis un tableau de bord en ligne."
                        icon="arrows-to-dot"
                        title="Centralisée"
                    />

                    <Feature
                        description="Travaillez simultanément avec vos équipes sur le même évenement."
                        icon="comments"
                        title="Collaborative"
                    />

                    <Feature
                        description="Gardez vos bénévoles informés à n’importe quel moment."
                        icon="lightbulb-on"
                        title="Proactive"
                    />
                </Flex>
            </Flex>
        </Flex>

        <Flex
            css={{
                background: '$cyan',
                padding: '$9 $7',
                '@tablet': {
                    padding: '72px 84px'
                },
                '@desktop': {
                    padding: '96px 0'
                }
            }}
            justify="center"
            width={1}
        >
            <Flex
                css={{
                    flexDirection: 'column',
                    gap: '40px',
                    width: '100%',
                    '@tablet': {
                        gap: '64px'
                    },
                    '@desktop': {
                        alignItems: 'center',
                        flexDirection: 'row',
                        gap: '64px',
                        width: '1280px'
                    }
                }}
            >
                <Flex
                    css={{
                        gap: '$2',
                        '@tablet': {
                            gap: '$4'
                        },
                        '@desktop': {
                            gap: '$4'
                        }
                    }}
                    direction="column"
                    width={1}
                >
                    <Box
                        color="primary700"
                        css={{
                            fontFamily: '$montserrat',
                            fontSize: '$displayXs',
                            textAlign: 'center',
                            textTransform: 'uppercase',
                            '@tablet': {
                                fontSize: '40px'
                            },
                            '@desktop': {
                                fontSize: '40px',
                                textAlign: 'left'
                            }
                        }}
                        fontWeight="bold"
                    >
                        Création des postes
                    </Box>

                    <Box
                        color="white"
                        css={{
                            fontFamily: '$madurai',
                            fontSize: '$textMd',
                            textAlign: 'center',
                            '@tablet': {
                                fontSize: '$displayXs'
                            },
                            '@desktop': {
                                fontSize: '$displayXs',
                                textAlign: 'left'
                            }
                        }}
                    >
                        Listez facilement toutes les tâches de l’événement.
                    </Box>
                </Flex>

                <Box
                    css={{
                        height: '300px',
                        '@tablet': {
                            height: '576px'
                        },
                        ' @desktop': {
                            height: '576px'
                        }
                    }}
                    textAlign="center"
                    width={1}
                >
                    <img alt="Création des postes" height="100%" src={Images.MsoCreatePositions} />
                </Box>
            </Flex>
        </Flex>

        <Flex
            css={{
                padding: '$9 $7',
                '@tablet': {
                    padding: '72px 84px'
                },
                '@desktop': {
                    padding: '96px 0'
                }
            }}
            justify="center"
            width={1}
        >
            <Flex
                css={{
                    flexDirection: 'column',
                    gap: '40px',
                    width: '100%',
                    '@tablet': {
                        gap: '64px'
                    },
                    '@desktop': {
                        alignItems: 'center',
                        flexDirection: 'row-reverse',
                        gap: '64px',
                        width: '1280px'
                    }
                }}
            >
                <Flex direction="column" gap="4" width={1}>
                    <Box
                        color="primary700"
                        css={{
                            fontFamily: '$montserrat',
                            fontSize: '$displayXs',
                            textAlign: 'center',
                            textTransform: 'uppercase',
                            '@tablet': {
                                fontSize: '40px'
                            },
                            '@desktop': {
                                fontSize: '40px',
                                textAlign: 'left'
                            }
                        }}
                        fontWeight="bold"
                    >
                        Gérez vos bénévoles
                    </Box>

                    <Box
                        color="primary700"
                        css={{
                            fontFamily: '$madurai',
                            fontSize: '$textMd',
                            textAlign: 'center',
                            '@tablet': {
                                fontSize: '$displayXs'
                            },
                            '@desktop': {
                                fontSize: '$displayXs',
                                textAlign: 'left'
                            }
                        }}
                    >
                        Affectez vos bénévoles à vos postes en quelques clics et trouvez rapidement
                        vos bénévoles disponibles grâce aux statuts d’affectation.
                    </Box>
                </Flex>

                <Box
                    css={{
                        height: '162px',
                        '@tablet': {
                            height: '324px'
                        },
                        '@desktop': {
                            height: '324px'
                        }
                    }}
                    textAlign="center"
                    width={1}
                >
                    <img alt="Gérez vos bénévoles" height="100%" src={Images.MsoVolunteers} />
                </Box>
            </Flex>
        </Flex>

        <Flex
            css={{
                background: '$cyan',
                padding: '$9 $7',
                '@tablet': {
                    padding: '72px 84px'
                },
                '@desktop': {
                    padding: '96px 0'
                }
            }}
            justify="center"
            width={1}
        >
            <Flex
                css={{
                    flexDirection: 'column',
                    gap: '40px',
                    width: '100%',
                    '@tablet': {
                        gap: '64px'
                    },
                    '@desktop': {
                        alignItems: 'center',
                        flexDirection: 'row',
                        gap: '64px',
                        width: '1280px'
                    }
                }}
            >
                <Flex
                    css={{
                        gap: '$2',
                        '@tablet': {
                            gap: '$4'
                        },
                        '@desktop': {
                            gap: '$4'
                        }
                    }}
                    direction="column"
                    width={1}
                >
                    <Box
                        color="primary700"
                        css={{
                            fontFamily: '$montserrat',
                            fontSize: '$displayXs',
                            textAlign: 'center',
                            textTransform: 'uppercase',
                            '@tablet': {
                                fontSize: '40px'
                            },
                            '@desktop': {
                                fontSize: '40px',
                                textAlign: 'left'
                            }
                        }}
                        fontWeight="bold"
                    >
                        Carte intéractive
                    </Box>

                    <Box
                        color="white"
                        css={{
                            fontFamily: '$madurai',
                            fontSize: '$textMd',
                            textAlign: 'center',
                            '@tablet': {
                                fontSize: '$displayXs'
                            },
                            '@desktop': {
                                fontSize: '$displayXs',
                                textAlign: 'left'
                            }
                        }}
                    >
                        Visualisez vos postes et vos bénévoles sur une carte interactive pour une
                        vue globale de votre événement en tout temps.
                    </Box>
                </Flex>

                <Box
                    css={{
                        height: '300px',
                        '@tablet': {
                            height: '576px'
                        },
                        ' @desktop': {
                            height: '576px'
                        }
                    }}
                    textAlign="center"
                    width={1}
                >
                    <img
                        alt="Carte intéractive"
                        height="100%"
                        src="https://assets.recrewteer.com/badges/mso/carte.png"
                    />
                </Box>
            </Flex>
        </Flex>

        <Flex
            css={{
                padding: '$9 $7',
                '@tablet': {
                    padding: '72px 84px'
                },
                '@desktop': {
                    padding: '96px 0'
                }
            }}
            justify="center"
            width={1}
        >
            <Flex
                css={{
                    flexDirection: 'column',
                    gap: '40px',
                    width: '100%',
                    '@tablet': {
                        gap: '64px'
                    },
                    '@desktop': {
                        alignItems: 'center',
                        flexDirection: 'row-reverse',
                        gap: '64px',
                        width: '1280px'
                    }
                }}
            >
                <Flex direction="column" gap="4" width={1}>
                    <Box
                        color="primary700"
                        css={{
                            fontFamily: '$montserrat',
                            fontSize: '$displayXs',
                            textAlign: 'center',
                            textTransform: 'uppercase',
                            '@tablet': {
                                fontSize: '40px'
                            },
                            '@desktop': {
                                fontSize: '40px',
                                textAlign: 'left'
                            }
                        }}
                        fontWeight="bold"
                    >
                        Badges & Accréditations
                    </Box>

                    <Box
                        color="primary700"
                        css={{
                            fontFamily: '$madurai',
                            fontSize: '$textMd',
                            textAlign: 'center',
                            '@tablet': {
                                fontSize: '$displayXs'
                            },
                            '@desktop': {
                                fontSize: '$displayXs',
                                textAlign: 'left'
                            }
                        }}
                    >
                        Générez des modèles de badges pour vos bénévoles prêts à imprimer et
                        assignez des accréditations spéciales à vos bénévoles selon vos besoins.
                    </Box>
                </Flex>

                <Box
                    css={{
                        height: '162px',
                        '@tablet': {
                            height: '324px'
                        },
                        '@desktop': {
                            height: '324px'
                        }
                    }}
                    textAlign="center"
                    width={1}
                >
                    <img
                        alt="Badges & Accréditations"
                        height="100%"
                        src="https://assets.recrewteer.com/badges/mso/carte.png"
                    />
                </Box>
            </Flex>
        </Flex>

        <Flex
            css={{
                background: '$cyan',
                padding: '$9 $7',
                '@tablet': {
                    padding: '72px 84px'
                },
                '@desktop': {
                    padding: '96px 0'
                }
            }}
            justify="center"
            width={1}
        >
            <Flex
                css={{
                    flexDirection: 'column',
                    gap: '40px',
                    width: '100%',
                    '@tablet': {
                        gap: '64px'
                    },
                    '@desktop': {
                        alignItems: 'center',
                        flexDirection: 'row',
                        gap: '64px',
                        width: '1280px'
                    }
                }}
            >
                <Flex
                    css={{
                        gap: '$2',
                        '@tablet': {
                            gap: '$4'
                        },
                        '@desktop': {
                            gap: '$4'
                        }
                    }}
                    direction="column"
                    width={1}
                >
                    <Box
                        color="primary700"
                        css={{
                            fontFamily: '$montserrat',
                            fontSize: '$displayXs',
                            textAlign: 'center',
                            textTransform: 'uppercase',
                            '@tablet': {
                                fontSize: '40px'
                            },
                            '@desktop': {
                                fontSize: '40px',
                                textAlign: 'left'
                            }
                        }}
                        fontWeight="bold"
                    >
                        Campagnes
                    </Box>

                    <Box
                        color="white"
                        css={{
                            fontFamily: '$madurai',
                            fontSize: '$textMd',
                            textAlign: 'center',
                            '@tablet': {
                                fontSize: '$displayXs'
                            },
                            '@desktop': {
                                fontSize: '$displayXs',
                                textAlign: 'left'
                            }
                        }}
                    >
                        Besoin d’informer tous vos bénévoles ou certains segments parmi eux ? Rien
                        de plus facile avec notre module de campagne par e-mail ou SMS.
                    </Box>
                </Flex>

                <Box
                    css={{
                        height: '300px',
                        '@tablet': {
                            height: '576px'
                        },
                        ' @desktop': {
                            height: '576px'
                        }
                    }}
                    textAlign="center"
                    width={1}
                >
                    <img
                        alt="Campagnes"
                        height="100%"
                        src="https://assets.recrewteer.com/badges/mso/campagne.png"
                    />
                </Box>
            </Flex>
        </Flex>

        <Flex
            css={{
                padding: '$9 $7',
                '@tablet': {
                    padding: '72px 84px'
                },
                '@desktop': {
                    padding: '96px 0'
                }
            }}
            justify="center"
            width={1}
        >
            <Flex
                css={{
                    flexDirection: 'column',
                    gap: '40px',
                    width: '100%',
                    '@tablet': {
                        gap: '64px'
                    },
                    '@desktop': {
                        alignItems: 'center',
                        flexDirection: 'row-reverse',
                        gap: '64px',
                        width: '1280px'
                    }
                }}
            >
                <Flex direction="column" gap="4" width={1}>
                    <Box
                        color="primary700"
                        css={{
                            fontFamily: '$montserrat',
                            fontSize: '$displayXs',
                            textAlign: 'center',
                            textTransform: 'uppercase',
                            '@tablet': {
                                fontSize: '40px'
                            },
                            '@desktop': {
                                fontSize: '40px',
                                textAlign: 'left'
                            }
                        }}
                        fontWeight="bold"
                    >
                        Intégrations
                    </Box>

                    <Box
                        color="primary700"
                        css={{
                            fontFamily: '$madurai',
                            fontSize: '$textMd',
                            textAlign: 'center',
                            '@tablet': {
                                fontSize: '$displayXs'
                            },
                            '@desktop': {
                                fontSize: '$displayXs',
                                textAlign: 'left'
                            }
                        }}
                    >
                        Intégrez vos différents systèmes tels qu’Excel, solutions de contrôle
                        d’accès ou solutions cashless directement dans la plateforme.
                    </Box>
                </Flex>

                <Box
                    css={{
                        height: '162px',
                        '@tablet': {
                            height: '324px'
                        },
                        '@desktop': {
                            height: '324px'
                        }
                    }}
                    textAlign="center"
                    width={1}
                >
                    <img
                        alt="Intégrations"
                        height="100%"
                        src="https://assets.recrewteer.com/badges/mso/integration.png"
                    />
                </Box>
            </Flex>
        </Flex>

        <Flex
            css={{
                background: '$cyan',
                padding: '$9 $4',
                '@tablet': {
                    padding: '72px 52px'
                },
                '@desktop': {
                    padding: '96px 0'
                }
            }}
            justify="center"
            width={1}
        >
            <Flex
                css={{
                    width: '100%',
                    '@desktop': {
                        width: '1280px'
                    }
                }}
                direction="column"
            >
                <Box
                    color="white"
                    css={{
                        fontFamily: '$montserrat',
                        fontSize: '$displayXs',
                        '@tablet': {
                            fontSize: '40px'
                        },
                        '@desktop': {
                            fontSize: '40px'
                        }
                    }}
                    fontWeight="bold"
                    textAlign="center"
                >
                    Curieux de savoir ce que <br />
                    notre plateforme peut vous apporter ?
                </Box>

                <Spacer height="8" />

                <Flex
                    align="center"
                    css={{
                        gap: '$1',
                        '@tablet': {
                            gap: '$2'
                        }
                    }}
                    direction="column"
                    justify="center"
                    width={1}
                >
                    <Button size="xl" textAlign="center" to={'/auth/sign-up/credentials'}>
                        Essayer 14 jours
                    </Button>

                    <Box
                        color="white"
                        css={{ textAlign: 'center', '@tablet': { textAlign: 'left' } }}
                        fontSize="textXs"
                    >
                        *Version desktop uniquement
                    </Box>
                </Flex>
            </Flex>
        </Flex>

        <Flex
            css={{
                padding: '$9 $4',
                '@tablet': {
                    padding: '96px 52px'
                },
                '@desktop': {
                    padding: '96px 0'
                }
            }}
            justify="center"
            width={1}
        >
            <Flex
                css={{
                    gap: '40px',
                    width: '100%',
                    '@tablet': {
                        gap: '64px'
                    },
                    '@desktop': {
                        gap: '64px',
                        width: '1280px'
                    }
                }}
                direction="column"
            >
                <Flex direction="column" gap="5">
                    <Box
                        color="primary700"
                        css={{
                            fontFamily: '$montserrat',
                            fontSize: '$displayXs',
                            textTransform: 'uppercase',
                            '@tablet': {
                                fontSize: '40px'
                            }
                        }}
                        fontWeight="bold"
                        textAlign="center"
                    >
                        Simplifiez-vous la vie
                    </Box>

                    <Box
                        color="primary700"
                        css={{
                            fontFamily: '$madurai',
                            '@tablet': {
                                fontSize: '$displayXs'
                            }
                        }}
                        fontSize="textMd"
                        textAlign="center"
                    >
                        Contactez-nous dès maintenant pour une introduction détaillée à notre
                        solution de gestion de bénévoles.
                    </Box>
                </Flex>

                <Flex
                    css={{
                        flexDirection: 'column',
                        gap: '$8',
                        '@tablet': {
                            flexDirection: 'row',
                            justifyContent: 'center',
                            padding: '$7'
                        }
                    }}
                >
                    <Flex direction="column" gap="5">
                        <Flex justify="center" width={1}>
                            <Flex
                                align="center"
                                css={{
                                    border: '2px solid black',
                                    borderRadius: '48px',
                                    color: 'black',
                                    fontSize: '$displayXs'
                                }}
                                height={48}
                                justify="center"
                                width={48}
                            >
                                <I icon="envelope" />
                            </Flex>
                        </Flex>

                        <LinkText to="mailto:johnathan@mso.swiss">
                            <Box
                                color="gray900"
                                css={{
                                    fontSize: '$textMd',
                                    '@tablet': {
                                        fontSize: '$displayXs'
                                    }
                                }}
                                fontWeight="medium"
                                textAlign="center"
                            >
                                jonathan@mso.swiss
                            </Box>
                        </LinkText>
                    </Flex>

                    <Flex direction="column" gap="5">
                        <Flex justify="center" width={1}>
                            <Flex
                                align="center"
                                css={{
                                    border: '2px solid black',
                                    borderRadius: '48px',
                                    color: 'black',
                                    fontSize: '$displayXs'
                                }}
                                height={48}
                                justify="center"
                                width={48}
                            >
                                <I icon="phone" />
                            </Flex>
                        </Flex>

                        <LinkText to="tel:+41775372049">
                            <Box
                                color="gray900"
                                css={{
                                    fontSize: '$textMd',
                                    '@tablet': {
                                        fontSize: '$displayXs'
                                    }
                                }}
                                fontWeight="medium"
                                textAlign="center"
                            >
                                +41 77 537 20 49
                            </Box>
                        </LinkText>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>

        <Flex
            css={{
                background: '$primary700',
                padding: '$9 $7'
            }}
        >
            <Box textAlign="center" width={1}>
                <img alt="Logo Vabene" height="50px" src={Images.MsoLogo3} />
            </Box>
        </Flex>
    </Box>
);
