import { Empty } from 'common-front/src/components/empty/empty';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useLocalOrganizationState } from 'common-front/src/hooks/useLocalOrganization';
import { useMobileQuery } from 'common-front/src/hooks/useMobileQuery';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { Box } from 'common/src/designSystem/components/box';
import { Flex, SectioningElement } from 'common/src/designSystem/components/flex';
import { Feature } from 'common/src/generated/types';
import { PositionPermission } from 'common/src/graphql/permissions/dsl';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { AccreditationsPaths } from 'common/src/util/paths/accreditationsPaths';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import { FormsPaths } from 'common/src/util/paths/formsPaths';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import { UsersPaths } from 'common/src/util/paths/usersPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import {
    CreateAccreditation,
    UpdateAccreditation
} from '../../accreditations/create/createUpdateAccreditation';
import { AccreditationsHasFeature } from '../../accreditations/list/accreditationsHasFeature';
import { AccreditationLoader } from '../../accreditations/show/accreditationLoader';
import { Accredit } from '../../accreditationsAssignments/accredit/accredit';
import { AccreditationsAssignments } from '../../accreditationsAssignments/list/accreditationsAssignments';
import { AccreditationCategoryLoader } from '../../accreditationsCategories/show/accreditationCategoryLoader';
import { AccreditationsSlot } from '../../accreditationsSlots/show/accreditationsSlot';
import { Assignment } from '../../assignments/assign/assignment';
import { Assignments } from '../../assignments/list/assignments';
import { TeamAssignment } from '../../assignments/teamAssignment/teamAssignment';
import { CreateCampaign, UpdateCampaign } from '../../campaigns/create/createUpdateCampaign';
import { Campaigns } from '../../campaigns/list/campaigns';
import { EventCampaign } from '../../campaigns/show/campaign';
import { Dashboard } from '../../dashboard/dashboard';
import {
    CreateEventDelegation,
    UpdateEventDelegation
} from '../../delegations/create/createUpdateDelegation';
import { DelegationsImport } from '../../delegations/import/delegationsImport';
import { DelegationsHasFeature } from '../../delegations/list/delegationsHasFeature';
import { DelegationLoader } from '../../delegations/show/delegationLoader';
import { DelegationCategoryLoader } from '../../delegationsCategories/show/delegationCategoryLoader';
import { DocumentBuilder } from '../../documentBuilder/documentBuilder';
import { DocumentsLoader } from '../../documents/list/documentsLoader';
import { PositionPlanningDocument } from '../../documents/show/positionPlanningDocument';
import { PositionSheetDocument } from '../../documents/show/positionSheetDocument';
import { PositionsPlanningDocument } from '../../documents/show/positionsPlanningDocument';
import { PositionsVolunteersPlanningDocument } from '../../documents/show/positionsVolunteersPlanningDocument';
import { UserAssignmentsDocument } from '../../documents/show/userAssignmentsDocument';
import { UserBadgeDocument } from '../../documents/show/userBadgeDocument';
import { UserCertificateDocument } from '../../documents/show/userCertificateDocument';
import { UserCustomBadge } from '../../documents/show/userCustomBadge';
import { UserCustomDocument } from '../../documents/show/userCustomDocument';
import { UserPlanningDaysDocument } from '../../documents/show/userPlanningDaysDocument';
import { UserPlanningDocument } from '../../documents/show/userPlanningDocument';
import { UserPlanningListDocument } from '../../documents/show/userPlanningListDocument';
import { UsersListingDocument } from '../../documents/show/usersListingDocument';
import { Emails } from '../../emails/emails';
import { FilesImport } from '../../files/import/filesImport';
import { EventForms } from '../../forms/list/forms';
import { FormIntegration } from '../../forms/show/integration/formIntegration';
import { FormPreview } from '../../forms/show/preview/formPreview';
import { FormElements } from '../../forms/update/elements/formElements';
import { FormEmails } from '../../forms/update/emails/formEmails';
import { FormParametersAccreditations } from '../../forms/update/parameters/accreditations/formParametersAccreditations';
import { FormParametersAvailabilities } from '../../forms/update/parameters/availabilities/formParametersAvailabilities';
import { FormParametersBroadcastEvent } from '../../forms/update/parameters/broadcast/formParametersBroadcast';
import { FormParametersDesign } from '../../forms/update/parameters/design/formParametersDesign';
import { FormParametersGeneral } from '../../forms/update/parameters/general/formParametersGeneral';
import { FormParametersMissions } from '../../forms/update/parameters/missions/formParametersMissions';
import { FormParametersState } from '../../forms/update/parameters/state/formParametersState';
import { useEventQuery } from '../../generated/graphqlHooks';
import { Api } from '../../options/api/api';
import { Weezevent } from '../../options/integration/weezevent/weezevent';
import { WeezeventV2 } from '../../options/weezeventV2/weezeventV2';
import { OrganizationComponent } from '../../organization/organizationComponent';
import { useOrganizationContext } from '../../organization/organizationContext';
import { CreatePosition, UpdatePosition } from '../../positions/create/createUpdatePosition';
import { PositionsCsvImport } from '../../positions/import/csv/positionsCsvImport';
import { PositionsLoader } from '../../positions/list/positionsLoader';
import { PositionLoader } from '../../positions/show/positionLoader';
import { PositionCategoryLoader } from '../../positionsCategories/show/positionCategoryLoader';
import { PositionsSlotsAssignVolunteers } from '../../positionsSlots/assignVolunteers/positionsSlotsAssignVolunteers';
import { UserOverlayEvent } from '../../users/overlay/userOverlay';
import { UpdateUserLoaderEvent } from '../../users/update/updateUserLoader';
import { UserUpdateAccreditationsLoader } from '../../users/updateAccreditations/userUpdateAccreditationsLoader';
import { UserUpdateMissionsLoader } from '../../users/updateMissions/userUpdateMissionsLoader';
import { UserUpdateSlotsLoader } from '../../users/updateSlots/userUpdateSlotsLoader';
import { EventVolunteersImport } from '../../volunteers/import/volunteersImport';
import { VolunteersLoader } from '../../volunteers/list/volunteersLoader';
import { EventContextProvider, useEventContext } from './eventContext';
import { EventMobile } from './eventMobile';
import { EventMenu } from './menu/eventMenu';
import { EventMenuToggler } from './menu/eventMenuToggler';
import { isEventAdmin } from './rules';

const EventComponent = () => {
    const {
        params: { organizationId, eventId },
        translate
    } = useHeavent();
    const { hasFeature } = useOrganizationContext();
    const { event, isEventAdmin, isPositionAdmin, hasPositionPermissions } = useEventContext();
    useTitle(event.name);
    const [isMenuOpen, setIsMenuOpen] = useLocalOrganizationState(
        organizationId,
        'isEventMenuOpen',
        false
    );
    const [canAssign] = hasPositionPermissions([PositionPermission.ASSIGN_MEMBER]);

    return (
        <Flex height={1} width={1}>
            <Flex
                aria-label={translate('menu_de_l_v_ne_05526')}
                as={SectioningElement.Nav}
                css={{ borderRight: '1px solid $gray200' }}
                direction="column"
                width={isMenuOpen ? 240 : 64}
            >
                <Flex css={{ flex: '1', overflowY: isMenuOpen ? 'hidden' : undefined }} width={1}>
                    <EventMenu isOpen={isMenuOpen} />
                </Flex>

                <EventMenuToggler
                    onToggle={() => {
                        setIsMenuOpen(!isMenuOpen);
                    }}
                />
            </Flex>

            <Box
                css={{
                    flex: '1',
                    height: '100%',
                    overflowX: 'hidden'
                }}
                tabIndex={-1}
            >
                <Switch>
                    <Route exact path={HeaventPaths.DASHBOARD(':organizationId', ':eventId')}>
                        <Dashboard />
                    </Route>

                    <Route
                        path={PositionsPaths.POSITIONS({
                            organizationId: ':organizationId',
                            eventId: ':eventId'
                        })}
                    >
                        <PositionsLoader />
                    </Route>

                    <Route
                        path={PositionsPaths.POSITION_CATEGORY({
                            organizationId: ':organizationId',
                            eventId: ':eventId',
                            positionCategoryId: ':positionCategoryId'
                        })}
                    >
                        <PositionCategoryLoader />
                    </Route>

                    <Route
                        path={PositionsPaths.POSITION({
                            organizationId: ':organizationId',
                            eventId: ':eventId',
                            positionId: ':positionId'
                        })}
                    >
                        <PositionLoader />
                    </Route>

                    <Route path={HeaventPaths.VOLUNTEERS(':organizationId', ':eventId')}>
                        <VolunteersLoader />
                    </Route>

                    <Route
                        path={HeaventPaths.EDIT_USER(':organizationId', ':eventId', ':userInfoId')}
                    >
                        <UpdateUserLoaderEvent />
                    </Route>

                    {isEventAdmin && (
                        <Route
                            path={HeaventPaths.EDIT_USER_DELEGATION(
                                ':organizationId',
                                ':eventId',
                                ':delegationId',
                                ':userInfoId'
                            )}
                        >
                            <UpdateUserLoaderEvent />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            path={HeaventPaths.EDIT_USER_ACCREDITATIONS(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                        >
                            <UserUpdateAccreditationsLoader />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            path={HeaventPaths.EDIT_USER_MISSIONS(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                        >
                            <UserUpdateMissionsLoader />
                        </Route>
                    )}

                    <Route
                        path={HeaventPaths.EDIT_USER_SLOTS(
                            ':organizationId',
                            ':eventId',
                            ':userInfoId'
                        )}
                    >
                        <UserUpdateSlotsLoader />
                    </Route>

                    {hasFeature(Feature.Delegation) && isEventAdmin && (
                        <Route
                            path={DelegationsPaths.DELEGATIONS({
                                organizationId: ':organizationId',
                                eventId: ':eventId'
                            })}
                        >
                            <DelegationsHasFeature />
                        </Route>
                    )}

                    <Route
                        path={UsersPaths.USER({
                            organizationId: ':organizationId',
                            eventId: ':eventId',
                            userInfoId: ':userInfoId'
                        })}
                    >
                        <UserOverlayEvent />
                    </Route>

                    {hasFeature(Feature.Delegation) && isEventAdmin && (
                        <Route
                            path={DelegationsPaths.DELEGATION_CATEGORY({
                                organizationId: ':organizationId',
                                eventId: ':eventId',
                                delegationCategoryId: ':delegationCategoryId'
                            })}
                        >
                            <DelegationCategoryLoader isEvent={true} />
                        </Route>
                    )}

                    {hasFeature(Feature.Delegation) && isEventAdmin && (
                        <Route
                            path={DelegationsPaths.DELEGATION({
                                organizationId: ':organizationId',
                                eventId: ':eventId',
                                delegationId: ':delegationId'
                            })}
                        >
                            <DelegationLoader />
                        </Route>
                    )}

                    {canAssign && (
                        <Route path={HeaventPaths.ASSIGNMENTS(':organizationId', ':eventId')}>
                            <Assignments />
                        </Route>
                    )}

                    {canAssign && (
                        <Route
                            path={HeaventPaths.ASSIGNMENT(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                        >
                            <Assignment isEdit={false} />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            path={HeaventPaths.EDIT_ASSIGNMENT(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                        >
                            <Assignment isEdit={true} />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            path={HeaventPaths.TEAM_ASSIGNMENT(
                                ':organizationId',
                                ':eventId',
                                ':teamCode'
                            )}
                        >
                            <TeamAssignment />
                        </Route>
                    )}

                    {canAssign && (
                        <Route
                            path={HeaventPaths.PRE_ASSIGNMENT(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                        >
                            <Assignment isEdit={false} />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            path={HeaventPaths.ACCREDITATIONS_ASSIGNMENTS(
                                ':organizationId',
                                ':eventId'
                            )}
                        >
                            <AccreditationsAssignments />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            path={HeaventPaths.ACCREDIT(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                        >
                            <Accredit isEdit={false} />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            path={HeaventPaths.EDIT_ACCREDIT(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                        >
                            <Accredit isEdit={true} />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            path={FormsPaths.FORMS({
                                organizationId: ':organizationId',
                                eventId: ':eventId'
                            })}
                        >
                            <EventForms />
                        </Route>
                    )}

                    {isPositionAdmin() && (
                        <Route path={HeaventPaths.CAMPAIGNS(':organizationId', ':eventId')}>
                            <Campaigns />
                        </Route>
                    )}

                    {isPositionAdmin() && (
                        <Route
                            path={HeaventPaths.CAMPAIGN(
                                ':organizationId',
                                ':eventId',
                                ':campaignId'
                            )}
                        >
                            <EventCampaign />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route path={HeaventPaths.EVENT_EMAILS(':organizationId', ':eventId')}>
                            <Emails />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_USER_PLANNING(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                        >
                            <UserPlanningDocument />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_USER_PLANNING_LIST(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                        >
                            <UserPlanningListDocument />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_USER_PLANNING_DAYS(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                        >
                            <UserPlanningDaysDocument />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_ASSIGNMENTS(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                        >
                            <UserAssignmentsDocument />
                        </Route>
                    )}

                    <Route
                        exact
                        path={HeaventPaths.DOCUMENTS_VOLUNTEERS_LISTING(
                            ':organizationId',
                            ':eventId',
                            ':segmentId'
                        )}
                    >
                        <UsersListingDocument />
                    </Route>

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_BADGE(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId',
                                'generic'
                            )}
                        >
                            <UserBadgeDocument />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_BADGE(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId',
                                ':badgeType'
                            )}
                        >
                            <UserCustomBadge />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_CERTIFICATE(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                        >
                            <UserCertificateDocument />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_POSITION_PLANNING(
                                ':organizationId',
                                ':eventId',
                                ':positionId'
                            )}
                        >
                            <PositionPlanningDocument />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_POSITIONS_VOLUNTEERS_PLANNING(
                                ':organizationId',
                                ':eventId',
                                ':segmentId'
                            )}
                        >
                            <PositionsVolunteersPlanningDocument />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_POSITION(
                                ':organizationId',
                                ':eventId',
                                ':positionId'
                            )}
                        >
                            <PositionSheetDocument />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_PLANNING(
                                ':organizationId',
                                ':eventId',
                                ':segmentId'
                            )}
                        >
                            <PositionsPlanningDocument />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_USER_CUSTOM_DOCUMENT(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId',
                                ':customDocumentId'
                            )}
                        >
                            <UserCustomDocument />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route exact path={HeaventPaths.DOCUMENTS(':organizationId', ':eventId')}>
                            <DocumentsLoader />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route path={HeaventPaths.OPTIONS_WEEZEVENT(':organizationId', ':eventId')}>
                            <Weezevent />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            path={HeaventPaths.OPTIONS_WEEZEVENT_V2(':organizationId', ':eventId')}
                        >
                            <WeezeventV2 />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route path={HeaventPaths.OPTIONS_API(':organizationId', ':eventId')}>
                            <Api />
                        </Route>
                    )}

                    {isEventAdmin && (
                        <Route
                            path={AccreditationsPaths.ACCREDITATIONS({
                                organizationId: ':organizationId',
                                eventId: ':eventId'
                            })}
                        >
                            <AccreditationsHasFeature />
                        </Route>
                    )}

                    {hasFeature(Feature.Accreditation) && isEventAdmin && (
                        <Route
                            path={AccreditationsPaths.ACCREDITATION_SLOT({
                                organizationId: ':organizationId',
                                eventId: ':eventId',
                                accreditationId: ':accreditationId',
                                accreditationSlotId: ':accreditationsSlotId'
                            })}
                        >
                            <AccreditationsSlot />
                        </Route>
                    )}

                    {hasFeature(Feature.Accreditation) && isEventAdmin && (
                        <Route
                            path={AccreditationsPaths.ACCREDITATION({
                                organizationId: ':organizationId',
                                eventId: ':eventId',
                                accreditationId: ':accreditationId'
                            })}
                        >
                            <AccreditationLoader />
                        </Route>
                    )}

                    {hasFeature(Feature.Accreditation) && isEventAdmin && (
                        <Route
                            path={AccreditationsPaths.ACCREDITATION_CATEGORY({
                                organizationId: ':organizationId',
                                eventId: ':eventId',
                                accreditationCategoryId: ':accreditationsCategoryId'
                            })}
                        >
                            <AccreditationCategoryLoader />
                        </Route>
                    )}

                    <Route>
                        <Empty
                            path={HeaventPaths.DASHBOARD(organizationId, eventId)}
                            replace={true}
                        />
                    </Route>
                </Switch>
            </Box>
        </Flex>
    );
};

const EventRoutes = () => {
    const { hasFeature } = useOrganizationContext();
    const { isEventAdmin, isPositionAdmin, hasPositionPermissions } = useEventContext();
    const [canEditPosition, canAssign] = hasPositionPermissions([
        PositionPermission.EDIT_POSITION,
        PositionPermission.ASSIGN_MEMBER
    ]);
    const [canEditPositionCategory] = hasPositionPermissions([PositionPermission.EDIT_POSITION], {
        positionCategoryId: 'ANY'
    });

    return (
        <Switch>
            {canEditPositionCategory && (
                <Route
                    exact
                    path={HeaventPaths.CREATE_POSITION(':organizationId', ':eventId', ':options?')}
                >
                    <CreatePosition />
                </Route>
            )}

            {canEditPosition && (
                <Route
                    exact
                    path={HeaventPaths.EDIT_POSITION(':organizationId', ':eventId', ':positionId')}
                >
                    <UpdatePosition />
                </Route>
            )}

            {canAssign && (
                <Route
                    path={HeaventPaths.POSITION_SLOT_ASSIGN_VOLUNTEERS(
                        ':organizationId',
                        ':eventId',
                        ':positionId',
                        ':positionSlotId'
                    )}
                >
                    <PositionsSlotsAssignVolunteers />
                </Route>
            )}

            {isPositionAdmin() && (
                <Route
                    exact
                    path={HeaventPaths.CREATE_CAMPAIGN(':organizationId', ':eventId', ':options?')}
                >
                    <CreateCampaign />
                </Route>
            )}

            {isPositionAdmin() && (
                <Route
                    exact
                    path={HeaventPaths.EDIT_CAMPAIGN(':organizationId', ':eventId', ':campaignId')}
                >
                    <UpdateCampaign />
                </Route>
            )}

            {isEventAdmin && (
                <Route
                    exact
                    path={HeaventPaths.CREATE_ACCREDITATION(
                        ':organizationId',
                        ':eventId',
                        ':options?'
                    )}
                >
                    <CreateAccreditation />
                </Route>
            )}

            {isEventAdmin && (
                <Route
                    exact
                    path={HeaventPaths.EDIT_ACCREDITATION(
                        ':organizationId',
                        ':eventId',
                        ':accreditationId'
                    )}
                >
                    <UpdateAccreditation />
                </Route>
            )}

            {isEventAdmin && (
                <Route
                    path={HeaventPaths.EDIT_CUSTOM_DOCUMENT(
                        ':organizationId',
                        ':eventId',
                        ':customDocumentId'
                    )}
                >
                    <DocumentBuilder />
                </Route>
            )}

            {isEventAdmin && (
                <Route
                    exact
                    path={HeaventPaths.CREATE_DELEGATION(
                        ':organizationId',
                        ':eventId',
                        ':options?'
                    )}
                >
                    <CreateEventDelegation />
                </Route>
            )}

            {isEventAdmin && (
                <Route
                    exact
                    path={HeaventPaths.EDIT_DELEGATION(
                        ':organizationId',
                        ':eventId',
                        ':delegationId'
                    )}
                >
                    <UpdateEventDelegation />
                </Route>
            )}

            {isEventAdmin && (
                <Route
                    path={FormsPaths.FORM_ELEMENTS({
                        organizationId: ':organizationId',
                        eventId: ':eventId',
                        formId: ':formId'
                    })}
                >
                    <FormElements />
                </Route>
            )}

            {isEventAdmin && (
                <Route
                    path={FormsPaths.FORM_PARAMETERS_GENERAL({
                        organizationId: ':organizationId',
                        eventId: ':eventId',
                        formId: ':formId'
                    })}
                >
                    <FormParametersGeneral />
                </Route>
            )}

            {isEventAdmin && (
                <Route
                    path={FormsPaths.FORM_PARAMETERS_DESIGN({
                        organizationId: ':organizationId',
                        eventId: ':eventId',
                        formId: ':formId'
                    })}
                >
                    <FormParametersDesign />
                </Route>
            )}

            {isEventAdmin && (
                <Route
                    path={FormsPaths.FORM_PARAMETERS_AVAILABILITIES({
                        organizationId: ':organizationId',
                        eventId: ':eventId',
                        formId: ':formId'
                    })}
                >
                    <FormParametersAvailabilities />
                </Route>
            )}

            {isEventAdmin && (
                <Route
                    path={FormsPaths.FORM_PARAMETERS_MISSIONS({
                        organizationId: ':organizationId',
                        eventId: ':eventId',
                        formId: ':formId'
                    })}
                >
                    <FormParametersMissions />
                </Route>
            )}

            {isEventAdmin && hasFeature(Feature.Accreditation) && (
                <Route
                    path={FormsPaths.FORM_PARAMETERS_ACCREDITATIONS({
                        organizationId: ':organizationId',
                        eventId: ':eventId',
                        formId: ':formId'
                    })}
                >
                    <FormParametersAccreditations />
                </Route>
            )}

            {isEventAdmin && (
                <Route
                    path={FormsPaths.FORM_PARAMETERS_BROADCAST({
                        organizationId: ':organizationId',
                        eventId: ':eventId',
                        formId: ':formId'
                    })}
                >
                    <FormParametersBroadcastEvent />
                </Route>
            )}

            {isEventAdmin && (
                <Route
                    path={FormsPaths.FORM_PARAMETERS_STATE({
                        organizationId: ':organizationId',
                        eventId: ':eventId',
                        formId: ':formId'
                    })}
                >
                    <FormParametersState />
                </Route>
            )}

            {isEventAdmin && (
                <Route
                    path={FormsPaths.FORM_INTEGRATION({
                        organizationId: ':organizationId',
                        eventId: ':eventId',
                        formId: ':formId'
                    })}
                >
                    <FormIntegration />
                </Route>
            )}

            {isEventAdmin && (
                <Route
                    path={FormsPaths.FORM_EMAILS({
                        organizationId: ':organizationId',
                        eventId: ':eventId',
                        formId: ':formId'
                    })}
                >
                    <FormEmails />
                </Route>
            )}

            {isEventAdmin && (
                <Route
                    path={FormsPaths.FORM_PREVIEW({
                        organizationId: ':organizationId',
                        eventId: ':eventId',
                        formId: ':formId'
                    })}
                >
                    <FormPreview />
                </Route>
            )}

            {isEventAdmin && (
                <Route path={HeaventPaths.EVENT_DELEGATIONS_IMPORT(':organizationId', ':eventId')}>
                    <DelegationsImport />
                </Route>
            )}

            {isEventAdmin && (
                <Route path={HeaventPaths.EVENT_VOLUNTEERS_IMPORT(':organizationId', ':eventId')}>
                    <EventVolunteersImport />
                </Route>
            )}

            {isEventAdmin && (
                <Route
                    path={PositionsPaths.POSITION_IMPORT_CSV({
                        organizationId: ':organizationId',
                        eventId: ':eventId'
                    })}
                >
                    <PositionsCsvImport />
                </Route>
            )}

            {isEventAdmin && (
                <Route path={HeaventPaths.EVENT_FILES_IMPORT(':organizationId', ':eventId')}>
                    <FilesImport />
                </Route>
            )}

            <Route>
                <OrganizationComponent>
                    <EventComponent />
                </OrganizationComponent>
            </Route>
        </Switch>
    );
};

export const Event = () => {
    const { organizationId, eventId } = useParams();
    const { data, loader } = useEventQuery({ organizationId, eventId });
    const { setSelectedEventId } = useOrganizationContext();
    const onMobile = useMobileQuery();

    React.useEffect(() => {
        setSelectedEventId(eventId);

        return () => {
            setSelectedEventId();
        };
    }, [eventId]);

    if (loader) {
        return loader;
    } else {
        const assignmentsStats = data.event.assignmentsStats;

        return (
            <EventContextProvider
                event={data.event}
                numberOfWaitingAccreditations={
                    data.event.accreditationsStats.numberOfWaitingAccreditation
                }
                numberOfWaitingAssigments={
                    isEventAdmin(data.user)
                        ? assignmentsStats.numberOfWaitingAssignment +
                          assignmentsStats.numberOfPreAssigned
                        : assignmentsStats.numberOfPreAssigned
                }
                user={data.user}
            >
                {onMobile ? <EventMobile /> : <EventRoutes />}
            </EventContextProvider>
        );
    }
};
