import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { useOrganizationContext } from '../../organization/organizationContext';

export const EventsHeader = () => {
    const {
        history,
        translate,
        params: { organizationId }
    } = useHeavent();
    const { segment } = useSegmentsContext();
    const { isOrganizationAdmin } = useOrganizationContext();

    return (
        <Flex gap="4" width={1}>
            <Box css={{ flex: '1' }} font="gray900 displaySm semiBold">
                {segment.name}
            </Box>

            {isOrganizationAdmin && (
                <Button
                    onClick={() => {
                        history.push(HeaventPaths.CREATE_EVENT(organizationId));
                    }}
                >
                    {translate('cr_er_un_v_nem_28126')}
                </Button>
            )}
        </Flex>
    );
};
