import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { copy } from 'common-front/src/util/commands';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Link } from 'common/src/designSystem/components/link';
import { Emptyable } from 'common/src/util/emptyable';
import { isNonEmptyString } from 'common/src/util/string';
import { getBarcodeHref, getWeezTicketParticipantHref } from 'common/src/util/url';
import * as React from 'react';

interface IUserInformationsWeezeventProps {
    weezevent: {
        eventId: number;
        organizationId: number;
        participantId: Emptyable<number>;
        ticketId: string;
        shortTag: Emptyable<string>;
    };
}

export const UserInformationsWeezevent = (props: IUserInformationsWeezeventProps) => {
    const { translate } = useHeavent();
    const link = getWeezTicketParticipantHref(
        props.weezevent.organizationId,
        props.weezevent.eventId,
        props.weezevent.participantId,
        props.weezevent.ticketId
    );

    return (
        <Flex
            css={{
                background: 'white',
                bd: true,
                padding: '$5 $6'
            }}
            direction="column"
            gap="4"
            width={1}
        >
            <Flex align="center" gap="2">
                <I icon="memo-circle-info" />

                <Box font="gray900 textMd medium">{translate('informations_22954')}</Box>
            </Flex>

            <Flex align="center" gap="5">
                <Box height={64} width={64}>
                    <img
                        height="100%"
                        src={getBarcodeHref(props.weezevent.ticketId)}
                        width="100%"
                    />
                </Box>

                <Flex direction="column" gap="1">
                    <Box color="gray500">{translate('code_barres_60437')}</Box>

                    <Dropdown>
                        <Trigger>
                            <Badge
                                color="white"
                                cursor="pointer"
                                leftIcon="ticket"
                                rightIcon="chevron-down"
                                size="md"
                            >
                                {props.weezevent.ticketId}
                            </Badge>
                        </Trigger>

                        <Menu placement="bottom-start">
                            <ItemIcon
                                icon="copy"
                                onClick={() => {
                                    copy(props.weezevent.ticketId);
                                }}
                            >
                                {translate('copier_75889')}
                            </ItemIcon>

                            <Link to={link}>
                                <ItemIcon icon="arrow-up-right-from-square">
                                    {translate('ouvrir_dans_wee_97529')}
                                </ItemIcon>
                            </Link>
                        </Menu>
                    </Dropdown>
                </Flex>

                <Flex direction="column" gap="1">
                    <Box color="gray500">{translate('num_ro_de_puce_50039')}</Box>
                    <Box font="gray800 textSm medium">
                        {isNonEmptyString(props.weezevent.shortTag)
                            ? props.weezevent.shortTag
                            : '-'}
                    </Box>
                </Flex>
            </Flex>
        </Flex>
    );
};
