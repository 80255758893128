import * as React from 'react';

export const preventDefault = (
    e: React.MouseEvent<any, MouseEvent> | React.KeyboardEvent<any> | React.FormEvent<any>,
    shouldPreventDefault: boolean = true
): void => {
    // Stops any wrapping `<Link/>` from redirecting.
    //
    // `preventDefault` prevents all default browser behaviour, not just links, so it sometimes has to be conditional because otherwise things break, e.g.:
    // - menu items that are also links (`<Link/>`s/`<a/>`s) will stop navigating (@see {@link https://weezevent.slack.com/archives/C05D6125HPD/p1737001022087649})
    // - components within modals will stop working (@see {@link https://weezevent.slack.com/archives/C05D6125HPD/p1739269685698819} and {@link https://weezevent.slack.com/archives/C05D6125HPD/p1739269064590069})
    // - components within overlays will stop working (@see {@link https://weezevent.slack.com/archives/C06AMAXFF16/p1739291909361879})
    // - components within right panels will stop working (@see {@link https://weezevent.slack.com/archives/C05D6125HPD/p1739339965978629})
    if (shouldPreventDefault) {
        e.preventDefault();
    }

    // Stops any wrapping `onClick()`s from firing.
    //
    // These were present before the refactor that added this utility method, so these should always be safe to fire.
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();
};
