import { Flex } from 'common/src/designSystem/components/flex';
import { DocumentsQuery, Feature, PositionId, UsersInfoId } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import * as React from 'react';
import { useOrganizationContext } from '../../organization/organizationContext';
import { CustomDocumentsList } from './customDocuments/customDocumentsList';
import { DocumentsEvent } from './documentsEvent';
import { DocumentsMembers } from './documentsMembers';
import { DocumentsPositions } from './documentsPositions';

interface IDocumentsProps {
    event: DocumentsQuery['event'];

    reload(): void;
}

export const Documents = (props: IDocumentsProps) => {
    const { hasFeature } = useOrganizationContext();
    const userInfoId =
        props.event.volunteersRegistrations.nodes[0]?.userInfoId ?? (-1 as UsersInfoId);

    return (
        <Flex direction="column" gap="7">
            {hasFeature(Feature.CustomDocument) && isNonEmptyArray(props.event.customDocuments) && (
                <CustomDocumentsList
                    customDocuments={props.event.customDocuments}
                    numberOfVolunteersRegistrations={props.event.volunteersRegistrations.totalCount}
                    reload={props.reload}
                    userInfoId={userInfoId}
                />
            )}

            <DocumentsMembers
                assignedUserInfoId={
                    props.event.assignedVolunteersRegistrations.nodes[0]?.userInfoId ??
                    (-1 as UsersInfoId)
                }
                numberOfAssignedVolunteersRegistrations={
                    props.event.assignedVolunteersRegistrations.totalCount
                }
                numberOfVolunteersRegistrations={props.event.volunteersRegistrations.totalCount}
                userInfoId={userInfoId}
            />

            <DocumentsPositions
                numberOfPositions={props.event.positions.totalCount}
                positionId={props.event.positions.nodes[0]?.id ?? (-1 as PositionId)}
            />

            <DocumentsEvent
                positionsSegmentId={props.event.positionsSegments[0].id}
                volunteersSegmentId={props.event.volunteersSegments[0].id}
            />
        </Flex>
    );
};
