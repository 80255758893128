import { CommonEnvVars } from '../envVars';
import {
    DelegationId,
    Event,
    EventId,
    FormId,
    Organization,
    OrganizationId,
    SegmentId,
    UserId
} from '../generated/types';
import { organizationIdToThirdPartyDomain } from '../thirdPartyDomains';
import { FormPreviewState } from '../vo/form';
import { Emptyable } from './emptyable';
import { isNonEmptyString } from './string';

/*
 *  The default `URL` API has an annoying feature wherein an 'invisible segment' is added
 *  to the path when no path is present.
 *  @see https://developer.mozilla.org/en-US/docs/Web/API/URL/pathname#:~:text=HTTPS%2C%20HTTP%2C%20or%20other%20URLs
 *
 *  This is a pain because we may or may not have an app-wise base to prepend paths with.
 *  If there is no basename set, then the `URL.pathname` or `URL.href` will return a slash-terminated string (i.e. '/')
 *  If there is a basename set, then they will return a non-slash-terminated string (e.g. '/crew')
 *  So we can't be sure, when append a further path, whether or not to start it with a slash or not.
 *
 *  The easiest and neatest solution, I think, is to extend the `URL` class and guarantee that `pathname`
 *  and `href` will *always* be non-slash-terminating. Doing this also gives us some other benefits, such as
 *  handling subdomains separately from the rest of the domain, which are not possible with the default `URL` class.
 */
export class Url extends URL {
    constructor(
        url: string | URL,
        base?: string | URL,
        public subdomain?: string,
        public basename?: string
    ) {
        if (subdomain) {
            if (typeof url === 'string') {
                url = `${subdomain}.${url}`;
            } else if (typeof url === 'object') {
                url.host = `${subdomain}.${url.host}`;
            }
        }
        if (basename) {
            if (typeof url === 'string') {
                url = `${url}/${basename}`;
            } else if (typeof url === 'object') {
                url.pathname = `/${basename}`;
            }
        }

        if (!URL.canParse(url)) {
            url = `https://${url}`;
            if (!URL.canParse(url)) {
                throw new Error(`Invalid URL ${url}`);
            }
        }

        super(url, base);
    }

    set pathname(path: string) {
        super.pathname = `${this.basename ? this.basename : ''}${path}`;
    }

    get pathname(): string {
        return super.pathname !== '/' ? super.pathname : '';
    }

    get pathnameOptional(): string | undefined {
        return super.pathname !== '/' ? super.pathname : undefined;
    }

    get href(): string {
        return super.pathname !== '/' ? super.href : super.href.substring(0, super.href.length - 1);
    }

    toString(): string {
        return this.href;
    }
}

export const getSpaceHref = (
    organizationId: OrganizationId,
    path?: string,
    isV2: boolean = true
): string => {
    if (!path?.startsWith('/')) {
        console.warn(`Path '${path}' missing leading slash`);
        path = '/' + path;
    }
    const url = new Url(CommonEnvVars.HEAVENT_PUBLIC_URL);

    if (CommonEnvVars.HEAVENT_ENV && organizationIdToThirdPartyDomain.has(organizationId)) {
        url.host = organizationIdToThirdPartyDomain.get(organizationId)!;
    }

    url.pathname += `${isV2 ? '/v2' : ''}${path}`;

    return url.href;
};

interface IGetFormHrefOptions {
    organizationId: OrganizationId;
    eventId?: Emptyable<EventId>;
    delegationId?: Emptyable<DelegationId>;
    formId: FormId;
    teamCode?: Emptyable<string>;
    query?: { preview?: boolean; previewState?: FormPreviewState };
}

export const getFormHref = ({
    organizationId,
    eventId,
    delegationId,
    formId,
    teamCode,
    query
}: IGetFormHrefOptions): string => {
    const formUrl = new Url(CommonEnvVars.HEAVENT_PUBLIC_URL);

    if (CommonEnvVars.HEAVENT_ENV && organizationIdToThirdPartyDomain.has(organizationId)) {
        formUrl.host = organizationIdToThirdPartyDomain.get(organizationId)!;
    }

    formUrl.pathname += `/v2${(() => {
        if (delegationId) {
            return typeof eventId === 'number'
                ? `/organization/${organizationId}/event/${eventId}/form/${formId}/delegation/${delegationId}`
                : `/organization/${organizationId}/form/${formId}/delegation/${delegationId}`;
        } else {
            const basePath =
                typeof eventId === 'number'
                    ? `/organization/${organizationId}/event/${eventId}/form/${formId}`
                    : `/organization/${organizationId}/form/${formId}`;

            return isNonEmptyString(teamCode) ? `${basePath}/team/${teamCode}` : basePath;
        }
    })()}`;

    if (query?.preview) {
        formUrl.searchParams.set('preview', query?.preview?.toString());
    }
    if (query?.previewState) {
        formUrl.searchParams.set('state', query?.previewState?.toString());
    }

    return formUrl.href;
};

export enum BarcodeTypes {
    QR = 'qr',
    Code39 = 'code39'
}

export const getBarcodeHref = (
    text: string,
    params?: {
        barcodeType?: BarcodeTypes;
        color?: string;
        height?: number | null;
        width?: number | null;
    }
): string => {
    const barcodeUrl = new Url(CommonEnvVars.HEAVENT_API_URL);

    barcodeUrl.pathname += `/barcode/${params?.barcodeType ?? BarcodeTypes.QR}`;

    barcodeUrl.searchParams.set('text', text);
    barcodeUrl.searchParams.set('color', params?.color ?? '000000');
    if (params?.height) {
        barcodeUrl.searchParams.set('height', params?.height?.toString());
    }
    if (params?.width) {
        barcodeUrl.searchParams.set('width', params?.width?.toString());
    }

    return barcodeUrl.href;
};

export const getExportHref = ({
    organizationId,
    eventId,
    segmentId,
    key
}: {
    organizationId: OrganizationId;
    eventId: Emptyable<EventId>;
    segmentId: SegmentId;
    key: string;
}): string => {
    const exportUrl = new Url(CommonEnvVars.HEAVENT_API_URL);

    exportUrl.pathname += eventId
        ? `/export/organization/${organizationId}/event/${eventId}/volunteers/${segmentId}`
        : `/export/organization/${organizationId}/members/${segmentId}`;

    exportUrl.searchParams.set('key', key);

    return exportUrl.href;
};

export const getUnsubscribeHref = ({
    userId,
    organization,
    event
}: {
    userId: UserId;
    organization: Partial<Pick<Organization, 'id' | 'name'>>;
    event: Partial<Pick<Event, 'id' | 'name'>>;
}): string => {
    const unsubscribeUrl = new Url(CommonEnvVars.HEAVENT_APP_URL);

    unsubscribeUrl.pathname += '/unsubscribe-email';

    unsubscribeUrl.searchParams.set('userId', userId.toString());
    if (event.id) {
        unsubscribeUrl.searchParams.set('eventId', event.id.toString());
        unsubscribeUrl.searchParams.set('eventName', event.name ?? '');
    } else if (organization.id) {
        unsubscribeUrl.searchParams.set('organizationId', organization.id.toString());
        unsubscribeUrl.searchParams.set('organizationName', organization.name ?? '');
    }

    return unsubscribeUrl.href;
};

interface IDocumentQueryOptions {
    lang?: string;
    date?: string;
    endDate?: string;
}

export function getDocumentHref(
    link: string,
    extension: 'html' | 'pdf',
    query: IDocumentQueryOptions = {}
): string {
    const documentUrl = new Url(CommonEnvVars.HEAVENT_API_URL);

    documentUrl.pathname += `/documents/${link}.${extension}`;

    const searchParams = new URLSearchParams({ ...query });
    for (const [key, value] of searchParams) {
        documentUrl.searchParams.set(key, value);
    }

    return documentUrl.href;
}

export function getWeezTicketParticipantHref(
    weezeventOrganizationId: number,
    weezeventEventId: number,
    weezeventParticipantId: Emptyable<number>,
    ticketId: string
): string {
    if (weezeventParticipantId) {
        const participantUrl = new Url(
            `https://admin.${CommonEnvVars.WEEZEVENT_DOMAIN}`,
            undefined,
            undefined,
            `ticket/O${weezeventOrganizationId}/E${weezeventEventId}/manager/attendees/${weezeventParticipantId}`
        );

        return participantUrl.href;
    } else {
        const participantUrl = new Url(
            `https://admin.${CommonEnvVars.WEEZEVENT_DOMAIN}`,
            undefined,
            undefined,
            `ticket/O${weezeventOrganizationId}/E${weezeventEventId}/manager/attendees?search=${ticketId}`
        );

        return participantUrl.href;
    }
}
