import { CustomFieldsMassEditModal } from 'common-front/src/components/massEdit/massEditModal';
import { Button } from 'common-front/src/designSystem/components/button';
import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useCustomFieldsMassEditMutation } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import {
    CustomFieldId,
    CustomFieldsSortAttributes,
    MassEditInput
} from 'common/src/generated/types';
import * as React from 'react';
import { useCustomFieldsMassDeleteMutation } from '../../generated/graphqlHooks';

export const CustomFieldsActions = () => {
    const { translate } = useHeavent();
    const { massActions, onMassEditSuccess, organizationId, predicates, reload, segment } =
        useSegmentsContext<CustomFieldId, CustomFieldsSortAttributes>();
    const { mutate: customFieldsMassEdit } = useCustomFieldsMassEditMutation();
    const { mutate: customFieldsMassDelete } = useCustomFieldsMassDeleteMutation();
    const [isMassEditVisible, setIsMassEditVisible] = React.useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const selectedsInput = massActions.getSelectedsInput(predicates, null);
    const finalNumberOfSelected = massActions.isAllSelected
        ? segment.count
        : massActions.numberOfSelected;

    return (
        <>
            <Button
                onClick={() => {
                    setIsMassEditVisible(true);
                }}
            >
                {translate('_diter_une_prop_26724')}
            </Button>

            <Dropdown>
                <Trigger>
                    <Button color="white" leftIcon="ellipsis-vertical" />
                </Trigger>

                <Menu placement="bottom-end">
                    <ItemIcon
                        color="red"
                        icon="trash-can"
                        onClick={() => {
                            setIsDeleteModalOpen(true);
                        }}
                    >
                        {translate('supprimer_43083')}
                    </ItemIcon>
                </Menu>
            </Dropdown>

            {isMassEditVisible && (
                <CustomFieldsMassEditModal
                    mutate={(massEdit: MassEditInput) =>
                        customFieldsMassEdit({
                            organizationId,
                            massEdit
                        })
                    }
                    organizationId={organizationId}
                    selecteds={selectedsInput}
                    onClose={() => {
                        setIsMassEditVisible(false);
                    }}
                    onSuccess={onMassEditSuccess}
                />
            )}

            {isDeleteModalOpen && (
                <DeleteModal
                    buttonText={translate('supprimer_43083')}
                    subtext={[translate('_tes_vous_s_r_d_63402')]}
                    text={translate('suppression_de_98457', finalNumberOfSelected)}
                    onClose={() => {
                        setIsDeleteModalOpen(false);
                    }}
                    onDelete={async () => {
                        await customFieldsMassDelete({
                            organizationId,
                            selecteds: selectedsInput
                        });

                        reload();
                    }}
                />
            )}
        </>
    );
};
