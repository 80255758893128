import { Page } from 'common-front/src/components/page/page';
import { Tab } from 'common-front/src/designSystem/components/tabs/tab';
import { TabList } from 'common-front/src/designSystem/components/tabs/tabList';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { DelegationCategoryQuery } from 'common/src/generated/types';
import { DelegationCategoryParams, DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import * as React from 'react';
import { DelegationCategoryDashboard } from './dashboard/delegationCategoryDashboard';
import { DelegationCategoryHeader } from './delegationCategoryHeader';

interface IDelegationCategoryProps {
    delegationCategory: DelegationCategoryQuery['organization']['delegationCategory'];

    reload(): void;
}

export const DelegationCategory = (props: IDelegationCategoryProps) => {
    const {
        translate,
        params: { organizationId, eventId, delegationCategoryId }
    } = useHeavent();
    const getDelegationCategoryParams = (isRouteComponent: boolean): DelegationCategoryParams => ({
        organizationId: isRouteComponent ? ':organizationId' : organizationId,
        eventId: eventId ? (isRouteComponent ? ':eventId' : eventId) : undefined,
        delegationCategoryId: isRouteComponent ? ':delegationCategoryId' : delegationCategoryId
    });

    return (
        <Page.Root>
            <DelegationCategoryHeader category={props.delegationCategory} reload={props.reload} />

            <Tabs css={{ flex: '1' }}>
                <TabList css={{ px: '$7' }}>
                    <Tab
                        path={DelegationsPaths.DELEGATION_CATEGORY_DASHBOARD(
                            getDelegationCategoryParams(false)
                        )}
                    >
                        {translate('tableau_de_bord_24627')}
                    </Tab>
                </TabList>

                <TabPanel
                    path={DelegationsPaths.DELEGATION_CATEGORY_DASHBOARD(
                        getDelegationCategoryParams(true)
                    )}
                >
                    <DelegationCategoryDashboard delegationCategory={props.delegationCategory} />
                </TabPanel>
            </Tabs>
        </Page.Root>
    );
};
