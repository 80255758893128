import { Avatar, AvatarSize } from 'common/src/components/avatar/avatar';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { LinkText } from 'common/src/designSystem/components/linkText';
import { styled } from 'common/src/designSystem/components/stitches';
import { UsersInfoId } from 'common/src/generated/types';
import { UsersPaths } from 'common/src/util/paths/usersPaths';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useHeavent } from '../hooks/useHeavent';

const _Flex = styled(Flex, {
    gap: '$3',
    variants: {
        avatarOnTop: {
            true: {
                flexDirection: 'column',
                gap: '$2'
            },
            false: {
                alignItems: 'center',
                flexDirection: 'row'
            }
        }
    }
});

interface IAvatarNameEmailProps {
    badge?: React.ReactNode;
    disabled?: boolean;
    isClickDisabled?: boolean;
    size?: AvatarSize;
    userInfo: { id: UsersInfoId; email: string; picture?: any; name: string };
}

export const AvatarNameEmail = (props: IAvatarNameEmailProps) => {
    const {
        params: { organizationId, eventId }
    } = useHeavent();
    const name = React.useMemo(
        () => (isNonEmptyString(props.userInfo.name) ? props.userInfo.name : '-'),
        [props.userInfo.name]
    );
    const renderName = () => (
        <Box
            color={props.disabled ? 'gray300' : 'gray800'}
            css={{
                ellipsis: ''
            }}
            disabled={props.disabled}
            fontWeight="medium"
            title={name}
        >
            {name}
        </Box>
    );

    return (
        <_Flex avatarOnTop={{ '@initial': !!props.badge, '@tablet': false }} width={1}>
            <Avatar
                email={props.userInfo.email}
                image={props.userInfo.picture?.url}
                name={props.userInfo.name}
                size={props.size || 40}
            />

            <Flex css={{ flex: '1', overflow: 'hidden' }} direction="column" justify="center">
                <Flex align="center" gap="2">
                    {props.isClickDisabled ? (
                        renderName()
                    ) : (
                        <LinkText
                            to={UsersPaths.USER_INFORMATIONS({
                                organizationId,
                                eventId,
                                userInfoId: props.userInfo.id
                            })}
                        >
                            {renderName()}
                        </LinkText>
                    )}

                    <Box css={{ flexShrink: 0 }}>{props.badge}</Box>
                </Flex>

                <Box
                    color={props.disabled ? 'gray300' : 'gray500'}
                    css={{ ellipsis: '' }}
                    fontSize="textSm"
                    title={props.userInfo.email}
                >
                    {props.userInfo.email}
                </Box>
            </Flex>
        </_Flex>
    );
};
