import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';
import { UserPositionsWished } from '../../users/information/userPositionsWished';
import { usePositionsRightPanelContext } from './positionsRightPanelContext';

export const PositionsRightPanelWishedTab = () => {
    const { formsUsersInfos } = usePositionsRightPanelContext();

    return (
        <Flex css={{ background: '$gray50', padding: '$6' }} height={1} width={1}>
            <UserPositionsWished formsUsersInfos={formsUsersInfos} />
        </Flex>
    );
};
