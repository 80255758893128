import * as React from 'react';
import { Field } from 'react-final-form';
import { CheckboxText as CheckboxTextComponent } from '../components/checkbox';

export const CheckboxText = ({
    name,
    children,
    ...props
}: { name: string } & Omit<
    React.ComponentProps<typeof CheckboxTextComponent>,
    'onClick' | 'state'
>) => (
    <Field
        name={name}
        render={(fieldProps) => {
            const state = fieldProps.input.checked === true ? 'checked' : 'unchecked';

            return (
                <CheckboxTextComponent
                    {...props}
                    state={state}
                    onClick={() => {
                        fieldProps.input.onChange(!fieldProps.input.checked);
                    }}
                >
                    {children}
                </CheckboxTextComponent>
            );
        }}
        type={'checkbox'}
    />
);
