import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CommonEnvVars } from 'common/src/envVars';
import { CustomFieldSlug } from 'common/src/generated/types';
import * as React from 'react';
import { useUserFieldUpdateQuery } from './generated/graphqlHooks';

export const UserFieldUpdate = () => {
    const {
        params: { organizationId, userInfoId, customFieldSlug, value }
    } = useHeavent<{ customFieldSlug: CustomFieldSlug; value: any }>();
    const { data, loader } = useUserFieldUpdateQuery({ organizationId, slug: customFieldSlug });
    const [isLoading, setIsLoading] = React.useState(false);
    const [isDisabled, setIsDisabled] = React.useState(false);
    const name = data.organization?.customField.name ?? '';
    const description = data.organization?.customField.description ?? '';
    const link = `${CommonEnvVars.HEAVENT_API_URL}/users/${userInfoId}/field/${customFieldSlug}/update/${value}`;
    const onClick = async () => {
        setIsLoading(true);
        await fetch(link);
        setIsDisabled(true);
        setIsLoading(false);
    };

    return (
        <Flex
            align="center"
            css={{
                background: '$gray50',
                overflow: 'hidden'
            }}
            height={1}
            justify="center"
            width={1}
        >
            {loader || (
                <Flex
                    align="center"
                    css={{
                        background: 'white',
                        bd: true,
                        padding: '$7'
                    }}
                    direction="column"
                    width={640}
                >
                    <Box font="gray900 textXl semiBold">{name}</Box>

                    <Spacer height="1" />

                    <Box color="gray500" textAlign="center">
                        {description}
                    </Box>

                    <Spacer height="7" />

                    <Flex justify="center" width={1}>
                        <Button
                            disabled={isDisabled}
                            isLoading={isLoading}
                            leftIcon={isDisabled ? 'check' : undefined}
                            textAlign="center"
                            onClick={onClick}
                        >
                            {name}
                        </Button>
                    </Flex>
                </Flex>
            )}
        </Flex>
    );
};
