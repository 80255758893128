import { CommonEnvVars } from 'common/src/envVars';
import { Emptyable } from 'common/src/util/emptyable';
import { isNonEmptyString } from 'common/src/util/string';
import { Settings } from 'luxon';
import * as React from 'react';
import { IUserContext } from '../../userContext';
import { loadScriptPromise } from '../../util/loadScript';
import { CurrentUser, currentUser } from '../../vo/user';
import { Loader } from '../loader/loader';

const loadUser = async (): Promise<IUserContext> => {
    try {
        const userContext = await currentUser();

        Settings.defaultLocale = userContext.user.language.toLowerCase();

        return userContext;
    } catch {
        return {
            user: null
        };
    }
};

// @internal
export const loadLang = (user: CurrentUser | null): Promise<boolean> => {
    const url = new URL(location.href);
    const urlLang = url.searchParams.get('lang');
    let language: Emptyable<string> = null;

    if (user) {
        language = user.language.toLowerCase();
    }

    if (isNonEmptyString(urlLang)) {
        language = urlLang;
    }

    const langParams = language ? `lang=${language}` : '';

    return loadScriptPromise(
        `${CommonEnvVars.HEAVENT_API_URL}/translation/language.js?${langParams}`
    );
};

const loadAll = async (): Promise<IUserContext> => {
    const { user } = await loadUser();
    await loadLang(user);

    return {
        user
    };
};

interface IMainLoaderProps {
    render(userContext: IUserContext): JSX.Element;
}

export const MainLoader = (props: IMainLoaderProps) => {
    const [userContext, setUserContext] = React.useState<IUserContext | null>(null);
    React.useEffect(() => {
        let isMounted = true;

        loadAll()
            .then((uc) => {
                if (isMounted) {
                    setUserContext(uc);
                }
            })
            .catch(() => {
                alert('Could not load application, please try again.');
            });

        return () => {
            isMounted = false;
        };
    }, []);

    if (userContext) {
        return props.render(userContext);
    } else {
        return <Loader />;
    }
};
