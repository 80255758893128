import { CustomFieldSlug, SegmentCustomFieldFragment } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { Fields, FieldService } from 'common/src/vo/field';
import * as React from 'react';
import { RichSelect } from '../../designSystem/components/richSelect/richSelect';
import { useCustomFieldOptions } from '../../hooks/useCustomFieldOptions';
import { UpdateCell } from './updateCell';
import { useUpdateCellMutate } from './useUpdateCellMutate';

interface IUpdateSelectCellProps {
    customField: SegmentCustomFieldFragment;
    fields: Fields;
    updatable: boolean;

    mutate(value: string, slug: CustomFieldSlug): Promise<any>;
    reload(): void;
}

export const UpdateSelectCell = (props: IUpdateSelectCellProps) => {
    const translate = useTranslate();
    const fieldService = useService(FieldService);
    const valueString = fieldService.getValueString(props.customField, props.fields);
    const initialValue = React.useMemo(() => {
        const value = fieldService.getValue<any>(props.customField, props.fields);

        if (props.customField.canSelectMultiple) {
            return isNonEmptyArray(value) ? value : [];
        } else {
            return value || '';
        }
    }, []);
    const options = useCustomFieldOptions(props.customField);
    const mutate = useUpdateCellMutate(props.customField, props.mutate);

    return (
        <UpdateCell
            initialValue={initialValue}
            mutate={mutate}
            reload={props.reload}
            renderInput={(value, setValue) => {
                if (props.customField.canSelectMultiple) {
                    return (
                        <RichSelect
                            isSearchVisible={true}
                            isSelectAllVisible={true}
                            label={props.customField.name}
                            multiple={true}
                            renderOnPortal={true}
                            values={value}
                            onChange={setValue}
                        >
                            {options}
                        </RichSelect>
                    );
                } else {
                    return (
                        <RichSelect
                            isSearchVisible={true}
                            label={props.customField.name}
                            multiple={false}
                            placeholder={translate('choisir_une_val_00277')}
                            renderOnPortal={true}
                            values={[value]}
                            onChange={(newValues) => {
                                if (isNonEmptyArray(newValues)) {
                                    setValue(newValues[0]);
                                } else {
                                    setValue(null);
                                }
                            }}
                        >
                            {options}
                        </RichSelect>
                    );
                }
            }}
            renderValue={valueString}
            updatable={props.updatable}
        />
    );
};
