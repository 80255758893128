import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { DelegationsCategory, EventId, OrganizationId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';
import { useDelegationCategoryDeleteMutation } from '../../generated/graphqlHooks';

interface IDeleteDelegationCategoryProps {
    delegationCategory: Pick<DelegationsCategory, 'id' | 'name'>;
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;

    onSuccess(): void;
    onClose(): void;
}

export const DeleteDelegationCategory = (props: IDeleteDelegationCategoryProps) => {
    const translate = useTranslate();
    const { mutate } = useDelegationCategoryDeleteMutation();

    return (
        <DeleteModal
            buttonText={translate('supprimer_43083')}
            subtext={[translate('_tes_vous_s_r_d_50954'), translate('cela_supprimera_35330')]}
            text={translate('suppression_de_73669', props.delegationCategory.name)}
            onClose={props.onClose}
            onDelete={async () => {
                await mutate({
                    organizationId: props.organizationId,
                    eventId: props.eventId,
                    delegationCategoryId: props.delegationCategory.id
                });

                props.onSuccess();
            }}
        />
    );
};
