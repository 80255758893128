import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I, IIcon } from 'common/src/designSystem/components/i';
import { preventDefault } from 'common/src/util/links';
import { UUID } from 'common/src/util/uuid';
import { DocumentElement } from 'common/src/vo/documentBuilder';
import * as React from 'react';
import { useDocumentBuilderContext } from '../documentBuilderContext';

const getIcon = (element: DocumentElement): IIcon => {
    if (element.type === 'container') {
        return element.direction === 'column' ? 'chart-simple-horizontal' : 'chart-simple';
    } else if (element.type === 'image') {
        return 'image';
    } else {
        return 'text';
    }
};

interface IDocumentBuilderRightPanelElementProps {
    element: DocumentElement;
    pageId: UUID;
}

export const DocumentBuilderRightPanelElement = (props: IDocumentBuilderRightPanelElementProps) => {
    const { deleteElement, duplicateElement, selectedElementId, selectElement } =
        useDocumentBuilderContext();

    return (
        <>
            <Flex
                align="center"
                css={{
                    background: selectedElementId === props.element.id ? '$primary300' : 'none',
                    borderRadius: '$1',
                    color: selectedElementId === props.element.id ? '$primary700' : '$gray800',
                    cursor: 'pointer',
                    padding: '$2',
                    '& .h-duplicate, & .h-delete': {
                        display: 'none'
                    },
                    '&:hover': {
                        background: '$primary300',
                        color: '$primary700',
                        '& .h-duplicate, & .h-delete': {
                            display: 'block'
                        }
                    }
                }}
                gap="3"
                onClick={() => {
                    selectElement(props.element.id, props.pageId);
                }}
            >
                <Box fontSize="textMd">
                    <I icon={getIcon(props.element)} />
                </Box>

                <Box
                    css={{
                        flex: '1'
                    }}
                    fontSize="textSm"
                    fontWeight="medium"
                >
                    {props.element.name}
                </Box>

                <Box
                    className="h-duplicate"
                    onClick={(e) => {
                        preventDefault(e);

                        duplicateElement(props.element.id);
                    }}
                >
                    <I icon="copy" />
                </Box>

                <Box
                    className="h-delete"
                    onClick={(e) => {
                        preventDefault(e);

                        deleteElement(props.element.id);
                    }}
                >
                    <I icon="trash-can" />
                </Box>
            </Flex>

            {props.element.type === 'container' && (
                <Flex css={{ paddingLeft: '$8' }} direction="column">
                    {props.element.elements.map((element) => (
                        <DocumentBuilderRightPanelElement
                            key={element.id}
                            element={element}
                            pageId={props.pageId}
                        />
                    ))}
                </Flex>
            )}
        </>
    );
};
