import { Accordion } from 'common-front/src/designSystem/components/accordion';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { preventDefault } from 'common/src/util/links';
import { isNonEmptyString } from 'common/src/util/string';
import { DocumentPage } from 'common/src/vo/documentBuilder';
import * as React from 'react';
import { useDocumentBuilderContext } from '../documentBuilderContext';
import { DocumentBuilderRightPanelElement } from './documentBuilderRightPanelElement';

interface IDocumentBuilderRightPanelPageProps {
    index: number;
    page: DocumentPage;
}

export const DocumentBuilderRightPanelPage = (props: IDocumentBuilderRightPanelPageProps) => {
    const { translate } = useHeavent();
    const { selectedPageId, selectPage } = useDocumentBuilderContext();
    const name = isNonEmptyString(props.page.name)
        ? props.page.name
        : translate('page_1_33637', props.index + 1);

    return (
        <Accordion
            css={{ gap: '0' }}
            title={
                <Flex
                    align="center"
                    css={{
                        background: selectedPageId === props.page.id ? '$primary300' : '',
                        borderRadius: '$1',
                        color: '$gray800',
                        flex: '1',
                        fontWeight: '$medium',
                        padding: '$2',
                        '&:hover': {
                            background: '$primary300'
                        }
                    }}
                    gap="3"
                    onClick={(e) => {
                        preventDefault(e);

                        selectPage(props.page.id);
                    }}
                >
                    <Box fontSize="textMd">
                        <I icon="frame" />
                    </Box>

                    <Box>{name}</Box>
                </Flex>
            }
            titleCss={{
                gap: '$2'
            }}
        >
            <Flex css={{ paddingLeft: '$8' }} direction="column">
                {props.page.elements.map((element) => (
                    <DocumentBuilderRightPanelElement
                        key={element.id}
                        element={element}
                        pageId={props.page.id}
                    />
                ))}
            </Flex>
        </Accordion>
    );
};
