import { Button } from 'common-front/src/designSystem/components/button';
import { Icon } from 'common-front/src/designSystem/components/icon';
import { ProgressBar } from 'common-front/src/designSystem/components/progressBar';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { LinkText } from 'common/src/designSystem/components/linkText';
import { PositionPlanningFragment, PositionsSlot } from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface IPositionsPlanningSlotModalProps {
    category: PositionPlanningFragment['category'];
    position: Pick<PositionPlanningFragment, 'id' | 'name' | 'color' | 'icon'>;
    slot: Pick<PositionsSlot, 'id' | 'name' | 'range'> & {
        assignedResources?: Emptyable<number>;
        fillingRate?: Emptyable<number>;
        resources?: Emptyable<number>;
    };
}

export const PositionsPlanningSlotModal = (props: IPositionsPlanningSlotModalProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const intervalService = useService(IntervalService);
    const showResources =
        typeof props.slot.assignedResources === 'number' &&
        typeof props.slot.fillingRate === 'number' &&
        typeof props.slot.resources === 'number';

    return (
        <Flex direction="column" width={1}>
            <Flex
                css={{
                    borderBottom: '1px solid $gray200',
                    padding: '$4 $3 $3 $6'
                }}
                gap="4"
            >
                <Icon color={props.position.color} icon={props.position.icon} />

                <Flex direction="column">
                    <LinkText
                        to={PositionsPaths.POSITION({
                            organizationId,
                            eventId,
                            positionId: props.position.id
                        })}
                    >
                        <Box css={{ ellipsis: true }} width={1}>
                            {props.position.name}
                        </Box>
                    </LinkText>
                    <Box color="gray500">{props.category.name}</Box>
                </Flex>
            </Flex>

            <Flex css={{ padding: '$5 $6 $4 $6' }} direction="column" gap="6">
                <Flex direction="column" gap="1">
                    <Box color="gray500">{translate('nom_du_cr_neau_54351')}</Box>
                    <Box font="gray800 textSm medium">
                        {isNonEmptyString(props.slot.name) ? props.slot.name : '-'}
                    </Box>
                </Flex>

                <Flex direction="column" gap="1">
                    <Box color="gray500">{translate('cr_neau_horaire_28150')}</Box>
                    <Box css={{ textTransform: 'capitalize' }} font="gray800 textSm medium">
                        {intervalService.toDisplayString(props.slot.range, {
                            formats: { displayWeekday: true }
                        })}
                    </Box>
                </Flex>

                {showResources && (
                    <Flex direction="column" gap="1">
                        <Flex justify="between">
                            <Box color="gray500">{translate('membres_11310')}</Box>
                            <Box color="gray500">
                                {props.slot.assignedResources}/{props.slot.resources}
                            </Box>
                        </Flex>

                        <Box css={{ textTransform: 'capitalize' }} font="gray800 textSm medium">
                            <ProgressBar percent={props.slot.fillingRate! / 100} />
                        </Box>
                    </Flex>
                )}
            </Flex>

            <Flex css={{ padding: '$4' }} gap="4" justify="end">
                <Button
                    to={HeaventPaths.POSITION_SLOT_ASSIGN_VOLUNTEERS(
                        organizationId,
                        eventId,
                        props.position.id,
                        props.slot.id
                    )}
                >
                    {translate('affecter_sur_le_19590')}
                </Button>
            </Flex>
        </Flex>
    );
};
