import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { SegmentsGrid } from 'common-front/src/segments/segmentsGrid';
import { CustomFieldsSegmentsQuery, SegmentId, SegmentType } from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { DEFAULT_PAGINATION_LIMIT } from 'common/src/vo/pagination';
import { CustomFieldsSegmentsService } from 'common/src/vo/segments/customFieldsSegmentsService';
import * as React from 'react';
import { useOrganizationContext } from '../../organization/organizationContext';
import { CustomFieldsContent } from './customFieldsContent';
import { CustomFieldsContextProvider } from './customFieldsContext';

interface ICustomFieldsProps {
    organization: CustomFieldsSegmentsQuery['organization'];

    reload(): void;
}

export const CustomFields = (props: ICustomFieldsProps) => {
    const {
        history,
        translate,
        params: { organizationId, segmentId }
    } = useHeavent();
    useTitle(translate('champs_de_formu_64126'));
    const { localOrganization, updateLocalOrganization } = useOrganizationContext();
    const segmentService = useService(CustomFieldsSegmentsService);
    const filters = React.useMemo(
        () => segmentService.getCustomFieldsFilters(props.organization),
        [props.organization]
    );
    const possibleColumns = React.useMemo(
        () => segmentService.getCustomFieldsPossibleColumns(),
        []
    );

    return (
        <CustomFieldsContextProvider organizationId={organizationId}>
            <SegmentsGrid
                filters={filters}
                filtersButtonText={translate('filtrer_les_cha_91574')}
                filtersSubtitle={translate('appliquer_des_f_27562')}
                getSegmentPath={(id: SegmentId) =>
                    HeaventPaths.CUSTOM_FIELDS_SEGMENT(organizationId, id)
                }
                initialLimit={localOrganization?.customFieldsLimit ?? DEFAULT_PAGINATION_LIMIT}
                initialSegmentsOpen={localOrganization?.areCustomFieldsSegmentsOpen ?? true}
                isAdmin={true}
                organizationId={organizationId}
                possibleColumns={possibleColumns}
                reload={props.reload}
                segmentId={segmentId}
                segmentType={SegmentType.CustomFields}
                segmentsFolders={props.organization.segmentsFolders}
                setLimit={(customFieldsLimit) => {
                    updateLocalOrganization({ customFieldsLimit });
                }}
                onSegmentClick={(selectedSegmentId) => {
                    updateLocalOrganization({ customFieldsSegmentId: selectedSegmentId });
                }}
                onSegmentDelete={async () => {
                    await updateLocalOrganization({ customFieldsSegmentId: null });

                    history.replace(HeaventPaths.CUSTOM_FIELDS(organizationId));
                }}
                onSegmentsToggle={async (areSegmentsOpen) => {
                    await updateLocalOrganization({ areCustomFieldsSegmentsOpen: areSegmentsOpen });
                }}
            >
                <CustomFieldsContent />
            </SegmentsGrid>
        </CustomFieldsContextProvider>
    );
};
