import { DropdownFilters } from 'common-front/src/designSystem/components/dropdownFilters';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useEnumToOptions } from 'common-front/src/hooks/useEnumToOptions';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    ALL_TRANSLATIONMODULE,
    ALL_TRANSLATIONSTATUS,
    EventId,
    OrganizationTranslationsStatsQuery,
    TranslationModule,
    TranslationStatus
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { sortBy } from 'lodash-es';
import * as React from 'react';

interface IOrganizationTranslationsFiltersProps {
    content: string;
    eventsIds: EventId[];
    modules: TranslationModule[];
    organization: OrganizationTranslationsStatsQuery['organization'];
    translationStatus: TranslationStatus;

    setContent(content: string): void;
    setEventsIds(eventsIds: EventId[]): void;
    setModules(modules: TranslationModule[]): void;
    setTranslationStatus(translationStatus: TranslationStatus): void;
}

export const OrganizationTranslationsFilters = (props: IOrganizationTranslationsFiltersProps) => {
    const translate = useTranslate();
    const enumToOptions = useEnumToOptions();
    const [areFiltersOpen, setAreFiltersOpen] = React.useState(false);
    const [eventsIds, setEventsIds] = React.useState(props.eventsIds);
    const [modules, setModules] = React.useState(props.modules);
    const [translationStatus, setTranslationStatus] = React.useState(props.translationStatus);
    const events = React.useMemo(
        () => sortBy(props.organization.events.nodes, (e) => e.name.toLowerCase()),
        [props.organization]
    );
    const apply = () => {
        props.setEventsIds(eventsIds);
        props.setModules(modules);
        props.setTranslationStatus(translationStatus);

        setAreFiltersOpen(false);
    };
    const reinit = () => {
        props.setEventsIds([]);
        setEventsIds([]);
        props.setModules([]);
        setModules([]);
        props.setTranslationStatus(TranslationStatus.TranslatedAndNotTranslated);
        setTranslationStatus(TranslationStatus.TranslatedAndNotTranslated);

        setAreFiltersOpen(false);
    };

    return (
        <Flex
            css={{
                background: 'white',
                bd: true,
                padding: '$4'
            }}
            gap="3"
        >
            <TextInput
                icon="search"
                placeholder={translate('rechercher_une_22159')}
                value={props.content}
                onChange={props.setContent}
            />

            <DropdownFilters
                apply={apply}
                color="gray"
                isOpen={areFiltersOpen}
                reinit={reinit}
                showButtons={true}
                onStateChange={setAreFiltersOpen}
            >
                <RichSelect
                    isSearchVisible={true}
                    label={translate('_v_nements_53672')}
                    multiple={true}
                    renderOnPortal={true}
                    values={eventsIds}
                    onChange={setEventsIds}
                >
                    {events.map((event) => (
                        <option key={event.id} value={event.id}>
                            {event.name}
                        </option>
                    ))}
                </RichSelect>

                <RichSelect
                    isSearchVisible={true}
                    label={translate('module_67984')}
                    multiple={true}
                    renderOnPortal={true}
                    values={modules}
                    onChange={setModules}
                >
                    {enumToOptions(ALL_TRANSLATIONMODULE)}
                </RichSelect>

                <Select
                    label={translate('statut_de_tradu_58384')}
                    value={translationStatus as string}
                    onChange={setTranslationStatus as any}
                >
                    {enumToOptions(ALL_TRANSLATIONSTATUS)}
                </Select>
            </DropdownFilters>
        </Flex>
    );
};
