import { NoFeature } from 'common-front/src/components/noFeature';
import { Page } from 'common-front/src/components/page/page';
import { Alert } from 'common-front/src/designSystem/components/alert';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Feature } from 'common/src/generated/types';
import { heaventEnvDisplayName } from 'common/src/heaventEnv';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { useEventKeyQuery } from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';
import { Keys } from './keys';

export const Api = () => {
    const {
        translate,
        params: { eventId }
    } = useHeavent();
    const {
        event: { name: eventName }
    } = useEventContext();
    useTitle(`${eventName} API`);
    const { hasFeature } = useOrganizationContext();
    const { data, loader } = useEventKeyQuery({ eventId });

    return (
        loader || (
            <Page.Root>
                <Page.Header>
                    <Box font="gray900 displayXs medium">API</Box>
                </Page.Header>

                <Page.Content>
                    <Alert leftIcon="circle-exclamation">
                        {translate(`l_api_permet_de_66672`, heaventEnvDisplayName())}
                    </Alert>

                    <Spacer height="7" />

                    {hasFeature(Feature.Api) ? (
                        <Keys eventKey={data.event.key} />
                    ) : (
                        <Flex
                            align="center"
                            css={{ flex: '1' }}
                            height={1}
                            justify="center"
                            width={1}
                        >
                            <NoFeature icon="code-branch" />
                        </Flex>
                    )}
                </Page.Content>
            </Page.Root>
        )
    );
};
