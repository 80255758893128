import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { pxToMm } from 'common/src/util/number';
import { A4_SIZES, A6_SIZES, CB_SIZES } from 'common/src/util/pdf';
import { getFormat } from 'common/src/vo/documentBuilder';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { useDocumentBuilderContext } from '../documentBuilderContext';

export const DocumentBuilderLeftPanelParameters = () => {
    const { translate } = useHeavent();
    const { customDocument, population, setDimensions, setName, setPopulations, setSlug } =
        useDocumentBuilderContext();
    const height = customDocument.configuration.height;
    const width = customDocument.configuration.width;
    const format = getFormat(height, width);
    const populations = React.useMemo(
        () => sortBy(population.values, (v) => v.value.toLowerCase()),
        [population]
    );

    return (
        <Flex css={{ px: '$4' }} direction="column" gap="4" width={1}>
            <TextInput
                label={translate('nom_du_document_73868')}
                value={customDocument.name}
                onChange={setName}
            />

            <Select
                label={translate('format_du_docum_28991')}
                value={format}
                onChange={(newFormat) => {
                    if (newFormat === 'a4') {
                        setDimensions(A4_SIZES['96dpi'].height, A4_SIZES['96dpi'].width);
                    } else if (newFormat === 'a6') {
                        setDimensions(A6_SIZES['96dpi'].height, A6_SIZES['96dpi'].width);
                    } else if (newFormat === 'cb') {
                        setDimensions(CB_SIZES['96dpi'].height, CB_SIZES['96dpi'].width);
                    } else {
                        setDimensions(0, 0);
                    }
                }}
            >
                <option value="a4">A4 (794 * 1123 px) (210 * 297 mm)</option>
                <option value="a6">A6 (397 * 561 px) (105 * 148 mm)</option>
                <option value="cb">CB (204 * 325 px) (54 * 86 mm)</option>
                <option value="custom">{translate('personnalis_40360')}</option>
            </Select>

            {format === 'custom' && (
                <Flex gap="4">
                    <TextInput
                        hint={`${pxToMm(customDocument.configuration.width)}mm`}
                        label={translate('largeur_54184')}
                        rightText="px"
                        shouldParseAsInt={true}
                        value={customDocument.configuration.width}
                        onChange={(newWidth) => {
                            setDimensions(height, newWidth);
                        }}
                    />

                    <TextInput
                        hint={`${pxToMm(customDocument.configuration.height)}mm`}
                        label={translate('hauteur_56576')}
                        rightText="px"
                        shouldParseAsInt={true}
                        value={customDocument.configuration.height}
                        onChange={(newHeight) => {
                            setDimensions(newHeight, width);
                        }}
                    />
                </Flex>
            )}

            <Separator direction="horizontal" />

            <TextInput
                hint={translate('ne_doit_conteni_53122')}
                label={translate('nom_de_la_varia_81999')}
                value={customDocument.slug}
                onChange={setSlug}
            />

            <RichSelect
                isSearchVisible={true}
                label={translate('lier_ce_documen_79055')}
                multiple={true}
                values={customDocument.populationsIds}
                onChange={setPopulations}
            >
                {populations.map((pop) => (
                    <option key={pop.id} value={pop.id}>
                        {pop.value}
                    </option>
                ))}
            </RichSelect>
        </Flex>
    );
};
