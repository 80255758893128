import { Blank } from 'common-front/src/components/blank/blank';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Svgs } from 'common-front/src/util/assets';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { Row } from 'common/src/designSystem/components/table/row';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { SynchronizationState } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useWeezeventRulesQuery } from '../../../generated/graphqlHooks';
import { WeezeventRuleRow } from './weezeventRuleRow';

export const Weezevent = () => {
    const {
        params: { eventId },
        translate
    } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const [name, nameDebounced, setName] = useStateDebounce('');
    const [offset, setOffset] = React.useState(0);
    const { data, isLoading } = useWeezeventRulesQuery({
        eventId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        offset
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.event?.weezeventRules);

    return (
        <Flex css={{ padding: '$7' }} direction="column" height={1} width={1}>
            <Flex align="center" gap="3">
                <Box color="gray900" fontSize="displayXs" fontWeight="semiBold">
                    Weezevent
                </Box>

                {data.event?.weezeventConnectionInfos?.synchronizationState ===
                SynchronizationState.Synchronized ? (
                    <Badge color="success" leftIcon="circle" leftIconStyle="solid">
                        {translate(
                            'derni_re_synchr_32849',
                            dateTimeService.toLocaleString(
                                data.event?.weezeventConnectionInfos?.updatedAt.toLocal(),
                                LocaleFormats.DateTime
                            )
                        )}
                    </Badge>
                ) : (
                    <Badge color="warning" leftIcon="arrows-rotate" leftIconStyle="solid">
                        {translate('en_cours_de_syn_69246')}
                    </Badge>
                )}

                <Box css={{ flex: '1' }} />
            </Flex>

            <Spacer height="7" />

            <TableFilters
                filters={
                    <>
                        <Box width={320}>
                            <TextInput
                                icon="magnifying-glass"
                                placeholder={translate('rechercher_une_48744')}
                                value={name}
                                onChange={(newName: string) => {
                                    setName(newName);
                                    setOffset(0);
                                }}
                            />
                        </Box>
                    </>
                }
                headerCells={
                    <>
                        <HeaderCell>{translate('nom_de_la_r_gle_35380')}</HeaderCell>
                        <HeaderCell>{translate('vue_utilis_e_93529')}</HeaderCell>
                        <HeaderCell>{translate('champ_weezevent_64904')}</HeaderCell>
                        <HeaderCell>{translate('valeur_22437')}</HeaderCell>
                    </>
                }
                numberOfPages={numberOfPages}
                offset={offset}
                rows={
                    isLoading ? (
                        <>
                            <RowSkeleton bx={true} />
                            <RowSkeleton bx={true} />
                            <RowSkeleton bx={true} />
                        </>
                    ) : data.event.weezeventRules.totalCount === 0 ? (
                        <Row css={{ height: '300px' }}>
                            <Blank
                                imageSrc={Svgs.WeezeventBlank}
                                subtitle={translate('cr_er_une_nouve_83893')}
                                title={translate('vous_n_avez_pas_86157')}
                            />
                        </Row>
                    ) : (
                        data.event.weezeventRules.nodes.map((rule) => (
                            <WeezeventRuleRow key={rule.id} rule={rule} />
                        ))
                    )
                }
                setOffset={setOffset}
                title={
                    <Flex direction="column">
                        <Box font="gray900 textMd semiBold">
                            {translate('liste_des_r_gle_61640')}
                        </Box>

                        <Box font="gray500 textSm regular">
                            {translate(
                                '_v_nement_weeze_68833',
                                `${data.event?.weezeventConnectionInfos?.event ?? '-'} (${
                                    data.event?.weezeventConnectionInfos?.idEvent ?? ''
                                })`,
                                data.event?.weezeventConnectionInfos?.ticket ?? '-'
                            )}
                        </Box>
                    </Flex>
                }
                totalCount={totalCount}
            />

            <Spacer height="7" />
        </Flex>
    );
};
