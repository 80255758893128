import { CenteredContainer } from 'common-front/src/components/centeredContainer/centeredContainer';
import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import { Button } from 'common-front/src/designSystem/components/button';
import { RadioText } from 'common-front/src/designSystem/form/radio';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useUniqueIds } from 'common-front/src/hooks/useUniqueIds';
import { LocalStorageKeys } from 'common-front/src/util/localStorage';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    AccreditationImportFragment,
    CustomFieldImportFragment,
    CustomFieldVariety,
    VolunteersImportInput,
    VolunteersImportMode
} from 'common/src/generated/types';
import { CsvSettings, DEFAULT_CSV_SETTINGS } from 'common/src/input/csvInput';
import {
    MEMBERS_IMPORT_FIELDS_DEFAULT,
    VolunteersImportInputService
} from 'common/src/input/volunteersImportInput';
import { ValidateService } from 'common/src/services/validateService';
import { useLocalStorage, useParams, useService } from 'common/src/util/dependencies/dependencies';
import { VolunteerDefaultColumns } from 'common/src/vo/segments/volunteersSegmentsService';
import { FormApi } from 'final-form';
import { parse } from 'papaparse';
import * as React from 'react';
import { CSVFormBox } from '../../common/import/csvFormBox';
import { ImportProcessingAlert } from '../../common/import/importProcessingAlert';
import { MappingsFormBoxes } from '../../common/import/mappingsFormBoxes';
import { StoredMappings } from '../../common/import/types';
import {
    useEventVolunteersImportInfosQuery,
    useOrganizationImportInfosQuery,
    useVolunteersImportMutation
} from '../../generated/graphqlHooks';
import { useNotificationContext } from '../../notifications/notificationContext';

const isFileValid = (form: FormApi<{ input: VolunteersImportInputWithFile }>): boolean =>
    !!form.getFieldState('input.csv.key')?.valid && !!form.getFieldState('input.csv.file')?.valid;

const removeFileFromInput = (input: VolunteersImportInputWithFile) => {
    const csvCopy = { ...input.csv };

    delete csvCopy.file;

    return {
        ...input,
        csv: csvCopy
    };
};

type VolunteersImportInputWithFile = VolunteersImportInput & {
    csv: {
        file?: File;
    };
};

type VolunteersImportValues = { input: VolunteersImportInputWithFile };

interface IVolunteersImportInnerProps {
    accreditations: AccreditationImportFragment[];
    centeredContainerRef: React.Ref<HTMLDivElement>;
    customFields: CustomFieldImportFragment[];
    form: FormApi<VolunteersImportValues>;
    submitting: boolean;
    values: VolunteersImportValues;

    handleSubmit(): void;
}

const VolunteersImportInner = (props: IVolunteersImportInnerProps) => {
    const { translate } = useHeavent();
    const ids = useUniqueIds();
    const onChange = React.useCallback(
        (file: File) => {
            parse<string[]>(file, {
                delimiter: props.values.input.csv.delimiter,
                preview: 1,
                newline: props.values.input.csv.newline as CsvSettings['newline'],
                quoteChar: props.values.input.csv.quoteChar as CsvSettings['quoteChar'],
                complete: ({ data, errors }) => {
                    let storedMappings: StoredMappings;

                    // Errors are handled elsewhere
                    if (!errors.length) {
                        const columns = data[0];
                        const automaticMappings = columns.map((columnName) => {
                            if (props.values.input.mode === VolunteersImportMode.UpdateFromId) {
                                // This is just in case we change the default from Email to ID in future
                                // We could have another `onChange` handler for the `<MappingsFormBox/>`
                                // component to change the auto-mapped field on mode change, but I think
                                // we should avoid this and only do the auto-mapping once, on first file
                                // upload, so as to be more predictable and not overwrite possible user
                                // choices made before changing the mode.
                                if (columnName === VolunteerDefaultColumns.Id) {
                                    return 'id';
                                }
                            } else {
                                if (columnName === VolunteerDefaultColumns.Email) {
                                    return 'email';
                                }
                            }

                            return (
                                props.customFields.find(
                                    (cf) =>
                                        cf.slug.toLowerCase() === columnName.toLowerCase() ||
                                        cf.name.toLowerCase() === columnName.toLowerCase()
                                )?.slug ?? null
                            );
                        });

                        try {
                            storedMappings = JSON.parse(
                                localStorage.getItem(
                                    LocalStorageKeys.VOLUNTEERS_IMPORT_MAPPINGS(file.name)
                                ) ?? '[]'
                            );
                        } catch (e) {
                            console.debug(`LocalStorage not available: ${e.toString()}`);
                        }

                        props.form.change(
                            'input.mappings',
                            columns.map(
                                (column, idx) => storedMappings[column] ?? automaticMappings[idx]
                            )
                        );
                    }
                }
            });

            try {
                props.form.change(
                    'input.presets',
                    JSON.parse(
                        localStorage.getItem(
                            LocalStorageKeys.VOLUNTEERS_IMPORT_PRESETS(file.name)
                        ) ?? '[]'
                    )
                );
            } catch (e) {
                console.debug(`LocalStorage not available: ${e.toString()}`);
            }
        },
        [props.customFields, props.form, props.values]
    );

    return (
        <FullScreenPopup
            button={
                <Button isLoading={props.submitting} onClick={props.handleSubmit}>
                    {translate('importer_la_lis_12866')}
                </Button>
            }
            category={translate('import_de_membr_48618')}
            color="dark"
            title={translate('nouvel_import_48306')}
        >
            <CenteredContainer ref={props.centeredContainerRef}>
                {props.form.getState().submitSucceeded && <ImportProcessingAlert />}
                <FormErrors errorId={ids.errorId} />

                <Flex direction="column" gap="5">
                    <CSVFormBox file={props.values.input.csv.file} ids={ids} onChange={onChange} />

                    {props.values.input.csv?.file && isFileValid(props.form) && (
                        <MappingsFormBoxes<VolunteersImportInputWithFile>
                            accreditations={props.accreditations}
                            customFields={props.customFields}
                            defaultFields={MEMBERS_IMPORT_FIELDS_DEFAULT}
                            file={props.values.input.csv.file}
                            form={props.form}
                            modeSelection={
                                <>
                                    {props.values.input.mode && (
                                        <Flex direction="column" gap="2">
                                            <Box color="gray800" fontWeight="medium">
                                                {translate('que_souhaitez_v_33936')}
                                            </Box>
                                            <RadioText
                                                name="input.mode"
                                                value={VolunteersImportMode.Create}
                                            >
                                                {translate('ajouter_un_memb_41735')}
                                            </RadioText>
                                            <RadioText
                                                name="input.mode"
                                                value={VolunteersImportMode.CreateUpdateFromEmail}
                                            >
                                                {translate('ajouter_un_memb_91120')}
                                            </RadioText>
                                            <RadioText
                                                name="input.mode"
                                                value={VolunteersImportMode.UpdateFromId}
                                            >
                                                {translate('n_ajouter_aucun_41072')}
                                            </RadioText>
                                        </Flex>
                                    )}
                                </>
                            }
                            requiredFieldSlugs={
                                props.values.input.mode === VolunteersImportMode.UpdateFromId
                                    ? [MEMBERS_IMPORT_FIELDS_DEFAULT.Id]
                                    : [MEMBERS_IMPORT_FIELDS_DEFAULT.Email]
                            }
                            values={props.values}
                            onToggle={(isOpen) => {
                                const file = props.values.input.csv.file;
                                if (!file) {
                                    // This path shouldn't be reachable.
                                    throw new Error('No file in input!');
                                } else {
                                    try {
                                        isOpen
                                            ? props.form.change(
                                                  'input.presets',
                                                  JSON.parse(
                                                      localStorage.getItem(
                                                          LocalStorageKeys.VOLUNTEERS_IMPORT_PRESETS(
                                                              file.name
                                                          )
                                                      ) ?? '[]'
                                                  )
                                              )
                                            : props.form.change('input.presets', []);
                                    } catch (e) {
                                        console.debug(
                                            `LocalStorage not available: ${e.toString()}`
                                        );
                                    }
                                }
                            }}
                        />
                    )}
                </Flex>
            </CenteredContainer>
        </FullScreenPopup>
    );
};

interface IVolunteersImportProps {
    accreditations: AccreditationImportFragment[];
    customFields: CustomFieldImportFragment[];
}

const VolunteersImport = (props: IVolunteersImportProps) => {
    const { eventId, organizationId } = useParams();
    const localStorage = useLocalStorage();
    const volunteersImportInput = useService(VolunteersImportInputService);
    const validateService = useService(ValidateService);
    const centeredContainerRef = React.useRef<HTMLDivElement | null>(null);
    const { mutate } = useVolunteersImportMutation();
    const { checkVolunteersImport } = useNotificationContext();

    const updateLocaleStorage = (
        input: Pick<VolunteersImportInputWithFile, 'csv' | 'mappings' | 'presets'>
    ) => {
        const file = input.csv.file;
        if (!file) {
            // This path shouldn't be reachable.
            throw new Error('No file in input!');
        } else {
            parse<string[]>(file, {
                preview: 1,
                complete: ({ data, errors }) => {
                    // Errors are handled elsewhere
                    if (!errors.length) {
                        const columns = data[0];
                        const storedMappings: StoredMappings = {};

                        for (const [index, mapping] of input.mappings.entries()) {
                            storedMappings[columns[index]] = mapping;
                        }

                        try {
                            localStorage.setItem(
                                LocalStorageKeys.VOLUNTEERS_IMPORT_MAPPINGS(file.name),
                                JSON.stringify(storedMappings)
                            );
                        } catch (e) {
                            console.debug(`LocalStorage not available: ${e.toString()}`);
                        }
                    }
                }
            });

            try {
                localStorage.setItem(
                    LocalStorageKeys.VOLUNTEERS_IMPORT_PRESETS(file.name),
                    JSON.stringify(input.presets)
                );
            } catch (e) {
                console.debug(`LocalStorage not available: ${e.toString()}`);
            }
        }
    };

    return (
        <Form
            height={1}
            hideErrors={true}
            initialValues={{
                input: volunteersImportInput.default().input as VolunteersImportInputWithFile
            }}
            render={({ form, handleSubmit, submitting, values }) => (
                <VolunteersImportInner
                    accreditations={props.accreditations}
                    centeredContainerRef={centeredContainerRef}
                    customFields={props.customFields}
                    form={form}
                    handleSubmit={handleSubmit}
                    submitting={submitting}
                    values={values}
                />
            )}
            validate={({ input }) =>
                validateService.validateForForm(
                    volunteersImportInput.schema({
                        delimiter: input.csv.delimiter ?? DEFAULT_CSV_SETTINGS.delimiter,
                        quoteChar: input.csv.quoteChar ?? undefined,
                        newline: (input.csv.newline as any) ?? undefined // `any` cast needed because `newline` here might be any string
                    })
                )
            }
            onShowErrors={() => {
                if (centeredContainerRef.current) {
                    centeredContainerRef.current.scrollTop = 0;
                }
            }}
            onSubmit={async ({ input }) => {
                updateLocaleStorage(input);

                const inputWithoutFile = removeFileFromInput(input);

                try {
                    const { jobId } = await mutate({
                        eventId,
                        organizationId,
                        input: inputWithoutFile
                    });
                    if (centeredContainerRef.current) {
                        centeredContainerRef.current.scrollTop = 0;
                    }
                    checkVolunteersImport(organizationId, jobId);
                } catch (e) {
                    /* Already displayed in <FormErrors />. */
                }
            }}
        />
    );
};

export const OrganizationVolunteersImport = () => {
    const {
        params: { organizationId }
    } = useHeavent();
    const { data, loader } = useOrganizationImportInfosQuery({
        organizationId,
        variety: CustomFieldVariety.UserInfo
    });

    return (
        loader || (
            <VolunteersImport
                accreditations={[]}
                customFields={data.organization.customFields.nodes}
            />
        )
    );
};

export const EventVolunteersImport = () => {
    const {
        params: { organizationId, eventId }
    } = useHeavent();
    const { data, loader } = useEventVolunteersImportInfosQuery({
        organizationId,
        variety: CustomFieldVariety.UserInfo,
        eventId
    });

    return (
        loader || (
            <VolunteersImport
                accreditations={data.event.accreditations.nodes}
                customFields={data.organization.customFields.nodes}
            />
        )
    );
};
