import { Tab } from 'common-front/src/designSystem/components/tabs/tab';
import { TabList } from 'common-front/src/designSystem/components/tabs/tabList';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { UsersPaths } from 'common/src/util/paths/usersPaths';
import * as React from 'react';
import { UserHistoryCampaigns } from './userHistoryCampaigns';
import { UserHistoryEvents } from './userHistoryEvents';
import { UserHistoryForms } from './userHistoryForms';

interface IUserHistoryProps {
    isEvent: boolean;
}

export const UserHistory = (props: IUserHistoryProps) => {
    const { translate, params } = useHeavent();
    const tabPanelPathParams: any = {
        organizationId: ':organizationId',
        eventId: props.isEvent ? ':eventId' : undefined,
        userInfoId: ':userInfoId'
    };

    return (
        <Flex
            css={{
                background: 'white',
                bd: true,
                overflow: 'hidden',
                paddingTop: '$5'
            }}
            direction="column"
            gap="5"
        >
            <Box css={{ px: '$6' }} font="gray900 textMd semiBold">
                Historique
            </Box>

            <Tabs css={{ flex: '1' }}>
                <TabList css={{ px: '$6' }}>
                    <Tab path={UsersPaths.USER_HISTORY_EVENTS(params)}>{translate('Event')}</Tab>
                    <Tab path={UsersPaths.USER_HISTORY_CAMPAIGNS(params)}>
                        {translate('campagnes_31372')}
                    </Tab>
                    <Tab path={UsersPaths.USER_HISTORY_FORMS(params)}>
                        {translate('Form_plural')}
                    </Tab>
                </TabList>

                <TabPanel path={UsersPaths.USER_HISTORY_EVENTS(tabPanelPathParams)}>
                    <UserHistoryEvents />
                </TabPanel>

                <TabPanel path={UsersPaths.USER_HISTORY_CAMPAIGNS(tabPanelPathParams)}>
                    <UserHistoryCampaigns />
                </TabPanel>

                <TabPanel path={UsersPaths.USER_HISTORY_FORMS(tabPanelPathParams)}>
                    <UserHistoryForms />
                </TabPanel>
            </Tabs>
        </Flex>
    );
};
