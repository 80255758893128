import * as React from 'react';
import Calendar from 'svgComponents/fontawesome/calendar.svg';
import LocationDot from 'svgComponents/fontawesome/locationDot.svg';
import Sitemap from 'svgComponents/fontawesome/sitemap.svg';
import Tag from 'svgComponents/fontawesome/tag.svg';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { RichText } from '../../../designSystem/components/richEditor/richText';
import { Spacer } from '../../../designSystem/components/spacer';
import { Span } from '../../../designSystem/components/span';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment, EventId } from '../../../generated/types';
import { DateTimeService } from '../../../services/dateTimeService';
import { useService } from '../../../util/dependencies/dependencies';
import { googleMapsHref } from '../../../util/googleMaps';
import { LocaleFormats } from '../../../util/luxon';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

interface IVannesAssignmentPageProps {
    eventId: EventId;
    positionSlotUserInfo: DocumentUserCustomBadgeFragment['volunteerRegistration']['positionsSlotsUsersInfos'][0];
    userInfo: DocumentUserCustomBadgeFragment['volunteerRegistration']['userInfo'];
}

export const VannesAssignmentPage = (props: IVannesAssignmentPageProps) => {
    const dateTimeService = useService(DateTimeService);
    const slot = props.positionSlotUserInfo.positionSlot;
    const position = props.positionSlotUserInfo.position;
    const category = props.positionSlotUserInfo.positionCategory;
    const leader = position.leaders.concat(category.leaders)[0];

    return (
        <Flex
            css={{
                background:
                    'url(https://assets.recrewteer.com/badges/vannes/2024/assignemnt_v3.jpg) no-repeat',
                backgroundSize: 'cover',
                position: 'relative'
            }}
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
        >
            <Box
                css={{
                    background: '#00e5ff',
                    borderRadius: '$1',
                    color: 'white',
                    fontSize: '24px',
                    fontWeight: '700',
                    padding: '10px',
                    position: 'absolute',
                    right: '44px',
                    top: '32px'
                }}
            >
                {position.name}
            </Box>

            <Flex
                css={{
                    left: '56px',
                    padding: '$7',
                    position: 'absolute',
                    top: '152px'
                }}
                direction="column"
                width={682}
            >
                <Flex justify="between" width={1}>
                    <Flex
                        css={{
                            color: '#1d2939',
                            fontSize: '24px',
                            fontWeight: '700',
                            lineHeight: '28px'
                        }}
                        direction="column"
                    >
                        <Box>{props.userInfo.firstName}</Box>
                        <Box>{props.userInfo.lastName}</Box>
                    </Flex>

                    <Flex align="end" css={{ lineHeight: '28px' }} direction="column">
                        <Box
                            css={{
                                fontSize: '22px',
                                fontWeight: '600',
                                textTransform: 'capitalize'
                            }}
                        >
                            {dateTimeService.toLocaleString(
                                slot.range.start!,
                                LocaleFormats.DateOnly.WeekdayLongMonthLong
                            )}
                        </Box>

                        <Box
                            css={{
                                fontSize: '16px',
                                fontWeight: '700'
                            }}
                        >
                            {category.name}
                        </Box>
                    </Flex>
                </Flex>

                <Spacer height="7" />

                <Box color="gray800">
                    Nous te remercions chaleureusement d’avoir accepté de participer à
                    l’organisation de la 24ème édition du{' '}
                    <Span fontWeight="bold">Marathon de Vannes</Span> en tant que bénévole.
                </Box>

                <Spacer height="6" />

                <Flex
                    css={{
                        border: '1px solid $gray200',
                        borderRadius: '5px',
                        boxShadow: '$xs',
                        padding: '$4'
                    }}
                >
                    <Flex css={{ flex: '1' }} direction="column" gap="4">
                        <Flex align="center" gap="2">
                            <Box
                                css={{
                                    svg: {
                                        fill: '$gray700',
                                        height: '100%',
                                        width: '100%'
                                    }
                                }}
                                height={16}
                                width={16}
                            >
                                <Sitemap />
                            </Box>

                            <Flex css={{ flex: '1' }} direction="column">
                                <Box color="gray800" fontWeight="semiBold">
                                    Mission affectée
                                </Box>

                                <Box color="gray800">{position.name}</Box>
                            </Flex>
                        </Flex>

                        <Flex align="center" gap="2">
                            <Box
                                css={{
                                    svg: {
                                        fill: '$gray700',
                                        height: '100%',
                                        width: '100%'
                                    }
                                }}
                                height={16}
                                width={16}
                            >
                                <Calendar />
                            </Box>

                            <Flex css={{ flex: '1' }} direction="column">
                                <Box color="gray800" fontWeight="semiBold">
                                    Heure de la mission
                                </Box>

                                <Box color="gray800">
                                    De{' '}
                                    {dateTimeService.toLocaleString(
                                        slot.range.start!,
                                        LocaleFormats.TimeOnly
                                    )}{' '}
                                    à{' '}
                                    {dateTimeService.toLocaleString(
                                        slot.range.end!,
                                        LocaleFormats.TimeOnly
                                    )}
                                </Box>
                            </Flex>
                        </Flex>

                        <Flex align="center" gap="2">
                            <Box
                                css={{
                                    svg: {
                                        fill: '$gray700',
                                        height: '100%',
                                        width: '100%'
                                    }
                                }}
                                height={16}
                                width={16}
                            >
                                <LocationDot />
                            </Box>

                            <Flex css={{ flex: '1' }} direction="column">
                                <Box color="gray800" fontWeight="semiBold">
                                    Lieu de rendez-vous
                                </Box>

                                <Box color="gray800">{position.address}</Box>
                            </Flex>
                        </Flex>

                        <Flex align="center" gap="2">
                            <Box
                                css={{
                                    svg: {
                                        fill: '$gray700',
                                        height: '100%',
                                        width: '100%'
                                    }
                                }}
                                height={16}
                                width={16}
                            >
                                <Tag />
                            </Box>

                            <Flex css={{ flex: '1' }} direction="column">
                                <Box color="gray800" fontWeight="semiBold">
                                    Coordonnées GPS
                                </Box>

                                <Box color="gray800">
                                    {position.latitude}
                                    <br />
                                    {position.longitude}
                                </Box>
                            </Flex>
                        </Flex>
                    </Flex>

                    <Box height={255} width={308}>
                        <a
                            href={googleMapsHref(position.latitude!, position.longitude!)}
                            style={{
                                background: `url(${CommonEnvVars.HEAVENT_API_URL}/image/event/${props.eventId}/position/${position.id}.png)`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                display: 'block',
                                height: '100%',
                                width: '100%'
                            }}
                        />
                    </Box>
                </Flex>

                <Spacer height="4" />

                <Flex css={{ lineHeight: '20px' }} direction="column">
                    <Box fontWeight="bold">Description de la mission</Box>

                    {isNonEmptyString(position.description) && (
                        <>
                            <Box
                                color="gray800"
                                css={{
                                    '& .h-rich-text > p': {
                                        margin: '0'
                                    }
                                }}
                            >
                                <RichText text={position.description} />
                            </Box>
                        </>
                    )}
                </Flex>

                <Spacer height="4" />

                <Flex css={{ lineHeight: '20px' }} direction="column">
                    <Box fontWeight="bold">Coordonnées des responsables</Box>
                    <Box>
                        Merci de te présenter avec cette convocation auprès de ton responsable sur
                        place :
                    </Box>
                </Flex>

                <Spacer height="2" />

                <Box fontSize="textMd" fontWeight="bold" textAlign="center" width={1}>
                    {leader
                        ? `${leader.userInfo.name} : ${leader.userInfo.phone?.internationalFormat}`
                        : ''}
                </Box>
            </Flex>
        </Flex>
    );
};
