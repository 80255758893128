import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import {
    CustomFieldWithConditionFragment,
    EventId,
    FormsUsersInfosWishedFragment,
    OrganizationId,
    UsersInfo
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { AssignmentUserPanelAccreditationsWished } from './assignmentUserPanelAccreditationsWished';
import { AssignmentUserPanelComments } from './assignmentUserPanelComments';
import { AssignmentUserPanelInfos } from './assignmentUserPanelInfos';
import { AssignmentUserPanelPositionsWished } from './assignmentUserPanelPositionsWished';
import { AssignmentUserPanelSlotsWished } from './assignmentUserPanelSlotsWished';

interface IAssignmentUserPanelProps {
    customFields: CustomFieldWithConditionFragment[];
    eventId: EventId;
    formsUsersInfos: FormsUsersInfosWishedFragment['formsUsersInfos'];
    organizationId: OrganizationId;
    showWishedAccreditations: boolean;
    showWishedPositions: boolean;
    userInfo: Pick<UsersInfo, 'id' | 'email' | 'name' | 'fields'>;

    onCollapse(): void;
}

export const AssignmentUserPanel = (props: IAssignmentUserPanelProps) => {
    const translate = useTranslate();
    const formsIds = React.useMemo(
        () => props.formsUsersInfos.map((fui) => fui.form.id),
        [props.formsUsersInfos]
    );

    return (
        <Flex direction="column" height={1} width={1}>
            <Flex align="center" css={{ padding: '$6' }} gap="3">
                <Tooltip>
                    <Trigger>
                        <Box
                            color="gray800"
                            css={{ cursor: 'pointer' }}
                            fontSize="textLg"
                            onClick={props.onCollapse}
                        >
                            <I icon="sidebar" />
                        </Box>
                    </Trigger>

                    <Content placement="top">{translate('cacher_les_info_51832')}</Content>
                </Tooltip>

                <Box font="gray900 textMd semiBold">
                    {translate('_propos_de_1_50761', props.userInfo.name)}
                </Box>
            </Flex>

            <AssignmentUserPanelComments />

            <AssignmentUserPanelInfos
                customFields={props.customFields}
                formsIds={formsIds}
                title={translate('informations_22954')}
                userInfo={props.userInfo}
            />

            <AssignmentUserPanelSlotsWished formsUsersInfos={props.formsUsersInfos} />

            {props.showWishedPositions && (
                <AssignmentUserPanelPositionsWished
                    eventId={props.eventId}
                    formsUsersInfos={props.formsUsersInfos}
                    organizationId={props.organizationId}
                />
            )}

            {props.showWishedAccreditations && (
                <AssignmentUserPanelAccreditationsWished
                    eventId={props.eventId}
                    formsUsersInfos={props.formsUsersInfos}
                    organizationId={props.organizationId}
                />
            )}
        </Flex>
    );
};
