import { useTitle } from 'common-front/src/hooks/useTitle';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { AssignmentsEventAdmin } from './assignmentsEventAdmin';
import { AssignmentsPositionAdmin } from './assignmentsPositionAdmin';

export const Assignments = () => {
    const translate = useTranslate();
    const {
        event: { name: eventName },
        isEventAdmin,
        updateLocalEvent
    } = useEventContext();
    useTitle(`${eventName} ${translate('mes_affectation_73224')}`);
    React.useEffect(() => {
        (async () => {
            await Promise.all([
                updateLocalEvent({ 'assignment.filters.positionsCategoriesIds': null }),
                updateLocalEvent({ 'assignment.filters.positionsIds': null }),
                updateLocalEvent({ 'assignment.filters.date': null }),
                updateLocalEvent({ 'assignment.filters.tagsIds': null }),
                updateLocalEvent({ 'assignment.filters.segmentId': null }),
                updateLocalEvent({ 'assignment.filters.excludeFull': false })
            ]);
        })();
    }, []);

    return isEventAdmin ? <AssignmentsEventAdmin /> : <AssignmentsPositionAdmin />;
};
