import { DependenciesContext } from 'common/src/util/dependencies/dependenciesContext';
import * as React from 'react';
import { useExecuteQueryImpl } from '../../components/graphql/graphql';
import { useMutationImpl } from '../../components/graphql/useMutation';
import { useQueryImpl } from '../../components/graphql/useQuery';
import { useHistoryImpl, useLocationImpl, useParamsImpl } from '../../hooks/reactRouterHooks';
import { useLocalStorageImpl } from '../../hooks/useLocalStorage';
import { getCommonFrontContainer } from './commonFrontContainer';

export const FrontDependenciesProvider = (props: { children: React.ReactNode }) => (
    <DependenciesContext.Provider
        value={{
            container: getCommonFrontContainer(),
            useQuery: useQueryImpl,
            useMutation: useMutationImpl,
            useExecuteQuery: useExecuteQueryImpl,
            useParams: useParamsImpl,
            useHistory: useHistoryImpl,
            useLocation: useLocationImpl,
            useLocalStorage: useLocalStorageImpl
        }}
    >
        {props.children}
    </DependenciesContext.Provider>
);
