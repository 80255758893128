import {
    EventQuery,
    PositionId,
    PositionsCategoryId,
    RoleId,
    RoleType
} from 'common/src/generated/types';
import { PositionPermission } from 'common/src/graphql/permissions/dsl';
import { isNonEmptyArray } from 'common/src/util/array';

export function isEventAdmin(user: EventQuery['user']) {
    return user.roles.some(
        (r) => r.type === RoleType.OrganizationAdmin || r.type === RoleType.EventAdmin
    );
}

export function isPositionCategoryAdmin(
    user: EventQuery['user'],
    positionCategoryId?: PositionsCategoryId
): boolean {
    const adminUsersPositionsCategories = user.usersPositionsCategories.filter(
        (upc) => upc.role.editPosition
    );

    if (positionCategoryId) {
        return (
            isEventAdmin(user) ||
            adminUsersPositionsCategories.some(
                (upc) => upc.positionCategoryId === positionCategoryId
            )
        );
    } else {
        return isEventAdmin(user) || isNonEmptyArray(adminUsersPositionsCategories);
    }
}

export function isPositionAdmin(
    user: EventQuery['user'],
    positionCategoryId?: PositionsCategoryId,
    positionId?: PositionId
) {
    const adminUsersPositions = user.usersPositions.filter((up) => up.role.editPosition);

    if (positionCategoryId && positionId) {
        return (
            isPositionCategoryAdmin(user, positionCategoryId) ||
            adminUsersPositions.some((up) => up.positionId === positionId)
        );
    } else {
        return isPositionCategoryAdmin(user) || isNonEmptyArray(adminUsersPositions);
    }
}

export function isPositionCategoryViewer(
    user: EventQuery['user'],
    positionCategoryId?: PositionsCategoryId
): boolean {
    if (positionCategoryId) {
        return (
            isPositionCategoryAdmin(user, positionCategoryId) ||
            user.usersPositionsCategories.some(
                (upc) => upc.positionCategoryId === positionCategoryId
            )
        );
    } else {
        return isEventAdmin(user) || isNonEmptyArray(user.usersPositionsCategories);
    }
}

export function isPositionViewer(
    user: EventQuery['user'],
    positionCategoryId?: PositionsCategoryId,
    positionId?: PositionId
) {
    if (positionCategoryId && positionId) {
        return (
            isPositionAdmin(user, positionCategoryId, positionId) ||
            isPositionCategoryViewer(user, positionCategoryId) ||
            user.usersPositions.some((up) => up.positionId === positionId)
        );
    } else {
        return isPositionCategoryViewer(user) || isNonEmptyArray(user.usersPositions);
    }
}

function isPositionRoleOnScope(
    user: EventQuery['user'],
    role: { id: RoleId; type: RoleType.Custom | RoleType.PositionEditor | RoleType.PositionReader },
    scope:
        | { positionCategoryId: PositionsCategoryId; positionId: PositionId }
        | { positionCategoryId: PositionsCategoryId | 'ANY' }
): boolean {
    const inCategory = user.usersPositionsCategories.some((upc) => {
        if (scope.positionCategoryId === 'ANY') {
            return role.id === upc.role.id;
        } else {
            return role.id === upc.role.id && upc.positionCategoryId === scope.positionCategoryId;
        }
    });
    if ('positionId' in scope) {
        const inPosition = user.usersPositions.some(
            (upc) => role.id === upc.role.id && upc.positionId === scope.positionId
        );

        return inCategory || inPosition;
    } else {
        return inCategory;
    }
}

export function hasPositionPermissions(
    user: EventQuery['user'],
    permissions: PositionPermission[],
    scope?:
        | { positionCategoryId: PositionsCategoryId; positionId: PositionId }
        | { positionCategoryId: PositionsCategoryId | 'ANY' }
): boolean[] {
    let roles = user.roles;
    if (scope !== undefined) {
        roles = roles.filter((role) => {
            switch (role.type) {
                case RoleType.Custom:
                case RoleType.PositionEditor:
                case RoleType.PositionReader:
                    return isPositionRoleOnScope(user, { id: role.id, type: role.type }, scope);
                default:
                    return true;
            }
        });
    }

    return permissions.map((permission) =>
        roles.some((role) => role.positionPermissions[permission] === '1')
    );
}
