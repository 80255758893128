import { Page } from 'common-front/src/components/page/page';
import { Tab } from 'common-front/src/designSystem/components/tabs/tab';
import { TabList } from 'common-front/src/designSystem/components/tabs/tabList';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePermissions } from 'common-front/src/hooks/usePermissions';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { Feature } from 'common/src/generated/types';
import { canCommentFn } from 'common/src/graphql/permissions/checks';
import { UsersPaths } from 'common/src/util/paths/usersPaths';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { useUserOverlayQuery } from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';
import { Comments } from '../show/comments/comments';
import { UserAccreditations } from './accreditations/userAccreditations';
import { UserOverlayActionsEvent } from './actions/userOverlayActionsEvent';
import { UserOverlayActionsOrganization } from './actions/userOverlayActionsOrganization';
import { UserHeader } from './header/userHeader';
import { UserHeaderSkeleton } from './header/userHeaderSkeleton';
import { UserHistory } from './history/userHistory';
import {
    UserInformationsEvent,
    UserInformationsOrganization
} from './informations/userInformations';
import { UserPositions } from './positions/userPositions';
import { UserOverlaySlots } from './slots/userOverlaySlots';

interface IUserOverlayProps {
    canEditEmail: boolean;
    isEvent: boolean;
    showAccreditations: boolean;
    showActions: boolean;
}

const UserOverlay = (props: IUserOverlayProps) => {
    const { params, translate } = useHeavent();
    const { data, isLoading: overlayQueryIsLoading, reload } = useUserOverlayQuery(params);
    const numberOfComments = React.useMemo(
        () => data.organization?.userInfo.numberOfComments ?? 0,
        [data.organization]
    );
    const tabPanelPathParams: any = {
        organizationId: ':organizationId',
        eventId: props.isEvent ? ':eventId' : undefined,
        userInfoId: ':userInfoId'
    };
    useTitle(data.organization?.userInfo?.nameOrEmail || '');
    const {
        params: { eventId, organizationId, userInfoId }
    } = useHeavent();
    const {
        permissions: [canComment],
        isLoading: permissionsQueryIsLoading
    } = usePermissions(
        canCommentFn({
            eventId,
            organizationId,
            userInfoId
        })
    );
    const isLoading = overlayQueryIsLoading || permissionsQueryIsLoading;

    return (
        <Page.Root>
            <Page.Header hideBorder={true}>
                {isLoading ? (
                    <UserHeaderSkeleton />
                ) : (
                    <UserHeader
                        accreditationState={
                            data.organization?.userInfo.volunteerRegistration?.accreditationState
                        }
                        assignmentState={data.organization?.userInfo.volunteerRegistration?.state}
                        canEditEmail={props.canEditEmail}
                        reload={reload}
                        userInfo={data.organization.userInfo}
                    />
                )}
            </Page.Header>

            <Tabs css={{ flex: '1' }}>
                <TabList css={{ px: '$7' }}>
                    <Tab path={UsersPaths.USER_INFORMATIONS(params)}>
                        {translate('informations_22954')}
                    </Tab>

                    {props.isEvent && (
                        <Tab path={UsersPaths.USER_AVAILABILITIES(params)}>
                            {translate('disponibilit_s_49923')}
                        </Tab>
                    )}

                    {props.isEvent && (
                        <Tab path={UsersPaths.USER_POSITIONS(params)}>
                            {translate('missions_63972')}
                        </Tab>
                    )}

                    {props.showAccreditations && (
                        <Tab path={UsersPaths.USER_ACCREDITATIONS(params)}>
                            {translate('accr_ditations_39450')}
                        </Tab>
                    )}

                    {props.showActions && (
                        <Tab path={UsersPaths.USER_ACTIONS(params)}>
                            {translate('actions_21689')}
                        </Tab>
                    )}

                    {canComment && (
                        <Tab path={UsersPaths.USER_COMMENTS(params)}>
                            {translate('commentaires_97857', numberOfComments)}
                        </Tab>
                    )}

                    <Tab path={UsersPaths.USER_HISTORY_EVENTS(params)}>
                        {translate('historique_06296')}
                    </Tab>
                </TabList>

                <TabPanel path={UsersPaths.USER_INFORMATIONS(tabPanelPathParams)}>
                    {props.isEvent ? (
                        <Page.Content>
                            <UserInformationsEvent />
                        </Page.Content>
                    ) : (
                        <Page.Content>
                            <UserInformationsOrganization />
                        </Page.Content>
                    )}
                </TabPanel>

                {props.isEvent && (
                    <TabPanel path={UsersPaths.USER_AVAILABILITIES(tabPanelPathParams)}>
                        <Page.Content>
                            <UserOverlaySlots />
                        </Page.Content>
                    </TabPanel>
                )}

                {props.isEvent && (
                    <TabPanel path={UsersPaths.USER_POSITIONS(tabPanelPathParams)}>
                        <Page.Content>
                            <UserPositions reload={reload} />
                        </Page.Content>
                    </TabPanel>
                )}

                {props.showAccreditations && (
                    <TabPanel path={UsersPaths.USER_ACCREDITATIONS(tabPanelPathParams)}>
                        <Page.Content>
                            <UserAccreditations />
                        </Page.Content>
                    </TabPanel>
                )}

                {props.showActions && (
                    <TabPanel path={UsersPaths.USER_ACTIONS(tabPanelPathParams)}>
                        {props.isEvent ? (
                            <Page.Content>
                                <UserOverlayActionsEvent />
                            </Page.Content>
                        ) : (
                            <Page.Content>
                                <UserOverlayActionsOrganization />
                            </Page.Content>
                        )}
                    </TabPanel>
                )}

                {canComment && (
                    <TabPanel path={UsersPaths.USER_COMMENTS(tabPanelPathParams)}>
                        <Page.Content>
                            <Comments showEvent={true} />
                        </Page.Content>
                    </TabPanel>
                )}

                <TabPanel path={UsersPaths.USER_HISTORY(tabPanelPathParams)}>
                    <Page.Content>
                        <UserHistory isEvent={props.isEvent} />
                    </Page.Content>
                </TabPanel>
            </Tabs>
        </Page.Root>
    );
};

export const UserOverlayEvent = () => {
    const { hasFeature } = useOrganizationContext();
    const { isEventAdmin, isPositionAdmin } = useEventContext();

    return (
        <UserOverlay
            canEditEmail={isEventAdmin}
            isEvent={true}
            showAccreditations={hasFeature(Feature.Accreditation) && isEventAdmin}
            showActions={isPositionAdmin()}
        />
    );
};

export const UserOverlayOrganization = () => (
    <UserOverlay
        canEditEmail={true}
        isEvent={false}
        showAccreditations={false}
        showActions={true}
    />
);
