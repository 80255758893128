import { UpdateCell } from 'common-front/src/components/cells/updateCell';
import { useDelegationsMassEditMutation } from 'common-front/src/generated/graphqlHooks';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import {
    DelegationProperty,
    DelegationsDelegationFragment,
    MassAssignStrategy
} from 'common/src/generated/types';
import * as React from 'react';

interface IDelegationsUpdateCellProps<T> {
    delegation: DelegationsDelegationFragment;
    initialValue: T;
    property: DelegationProperty;

    reload(): void;
    renderInput(value: T, setValue: (v: T) => void): React.ReactNode;
    renderValue: string | (() => React.ReactNode);
}

export const DelegationsUpdateCell = <T extends {}>(props: IDelegationsUpdateCellProps<T>) => {
    const { eventId, organizationId } = useSegmentsContext();
    const { mutate } = useDelegationsMassEditMutation();

    return (
        <UpdateCell
            initialValue={props.initialValue}
            mutate={async (value) =>
                mutate({
                    organizationId,
                    eventId,
                    massEdit: {
                        reset: false,
                        selecteds: { ids: [props.delegation.id] },
                        slug: props.property,
                        strategy: MassAssignStrategy.Replace,
                        value
                    }
                })
            }
            reload={props.reload}
            renderInput={props.renderInput}
            renderValue={props.renderValue}
            updatable={true}
        />
    );
};
