import { DateInput } from 'common-front/src/designSystem/components/date/dateInput';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Box } from 'common/src/designSystem/components/box';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { AccreditationsSlotsSortAttributes } from 'common/src/generated/types';
import { isNonEmptyString } from 'common/src/util/string';
import { Sort } from 'common/src/vo/sort';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useAccreditationSlotsQuery } from '../../../../generated/graphqlHooks';
import { localStateDateTimeSerde, useLocalEventState } from '../../../../hooks/useLocalState';
import { AccreditationSlotsSlotRow } from './accreditationSlotsSlotRow';

export const AccreditationSlotsCard = () => {
    const {
        translate,
        params: { eventId, accreditationId }
    } = useHeavent();
    const [name, nameDebounced, setName] = useStateDebounce('');
    const [date, setDate] = useLocalEventState(
        'accreditation.slots.date',
        DateTime.invalid('Invalid'),
        localStateDateTimeSerde
    );
    const [offset, setOffset] = React.useState(0);
    const [sort, _setSort] = useLocalEventState<Sort<AccreditationsSlotsSortAttributes> | null>(
        'accreditation.slots.sort',
        null
    );
    const { data, isLoading, reload } = useAccreditationSlotsQuery({
        eventId,
        accreditationId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        date: date?.isValid ? date : undefined,
        offset,
        sort
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.event?.accreditationsSlots);
    const setSort = React.useCallback(
        (newSort) => {
            _setSort(newSort);
            setOffset(0);
        },
        [_setSort, setOffset]
    );

    return (
        <TableFilters
            filters={
                <>
                    <Box width={320}>
                        <TextInput
                            icon="magnifying-glass"
                            placeholder={translate('rechercher_un_c_70424')}
                            value={name}
                            onChange={setName}
                        />
                    </Box>

                    <Box width={200}>
                        <DateInput
                            max={data.event?.endAt}
                            min={data.event?.startAt}
                            value={date}
                            onChange={setDate}
                        />
                    </Box>
                </>
            }
            headerCells={
                <>
                    <HeaderCellSort
                        attribute={AccreditationsSlotsSortAttributes.Name}
                        setSort={setSort}
                        sort={sort}
                    >
                        {translate('nom_du_cr_neau_54351')}
                    </HeaderCellSort>
                    <HeaderCellSort
                        attribute={AccreditationsSlotsSortAttributes.Date}
                        setSort={setSort}
                        sort={sort}
                    >
                        {translate('Date')}
                    </HeaderCellSort>
                    <HeaderCell>{translate('remplissage_75916')}</HeaderCell>
                    <HeaderCell width={100} />
                </>
            }
            numberOfPages={numberOfPages}
            offset={offset}
            rows={
                isLoading ? (
                    <>
                        <RowSkeleton bx={true} />
                        <RowSkeleton bx={true} />
                        <RowSkeleton bx={true} />
                    </>
                ) : (
                    data.event.accreditationsSlots.nodes.map((slot) => (
                        <AccreditationSlotsSlotRow
                            key={slot.id}
                            event={data.event}
                            reload={reload}
                            slot={slot}
                        />
                    ))
                )
            }
            setOffset={setOffset}
            title={translate('liste_des_cr_ne_66393')}
            totalCount={totalCount}
        />
    );
};
