import { CenteredContainer } from 'common-front/src/components/centeredContainer/centeredContainer';
import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import { Button } from 'common-front/src/designSystem/components/button';
import { EmptyFormBox, FormBox } from 'common-front/src/designSystem/components/formBox';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    CreatePositionCustomFieldsFragment,
    CreatePositionTagsFragment,
    CustomFieldWithConditionFragment,
    EventId,
    PositionEventInfosQuery,
    PositionId,
    PositionInput,
    PositionsCategoryId,
    PositionsSlotInput
} from 'common/src/generated/types';
import { ICreateUpdatePositionValues, PositionInputService } from 'common/src/input/positionInput';
import { ValidateService } from 'common/src/services/validateService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useParams, useService } from 'common/src/util/dependencies/dependencies';
import { DEFAULT_ZOOM } from 'common/src/util/map';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { omit } from 'lodash-es';
import * as React from 'react';
import { ConditionsForm } from '../../accreditations/create/conditionsForm';
import { LocationForm } from '../../accreditations/create/locationForm';
import { TagsForm } from '../../accreditations/create/tagsForm';
import {
    usePositionCreateMutation,
    usePositionEventInfosQuery,
    usePositionToEditQuery,
    usePositionUpdateMutation
} from '../../generated/graphqlHooks';
import { CreatePositionCustomFields } from './createPositionCustomFields';
import { CreatePositionInformations } from './createPositionInformations';
import { CreatePositionPrivateNote } from './createPositionPrivateNote';
import { CreatePositionSlots } from './createPositionSlots';

interface ICreateUpdatePositionProps {
    event: PositionEventInfosQuery['event'];
    initialValues: ICreateUpdatePositionValues;
    isEdit: boolean;
    positionCustomFields: CustomFieldWithConditionFragment[];
    tags: CreatePositionTagsFragment['tags']['nodes'];
    userInfoCustomFields: CreatePositionCustomFieldsFragment['customFields'];

    mutate(args: { eventId: EventId; position: PositionInput }): Promise<PositionId>;
}

const CreateUpdatePosition = (props: ICreateUpdatePositionProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const positionInput = useService(PositionInputService);
    const validateService = useService(ValidateService);
    const centeredContainerRef = React.useRef<HTMLDivElement | null>(null);
    const category = React.useMemo(
        () =>
            props.isEdit
                ? translate('mise_jour_de_17055', props.initialValues.position.name)
                : translate('cr_ation_d_une_10675'),
        [props.isEdit, props.initialValues]
    );
    useTitle(category);
    const isLocalisationOpen = React.useMemo(
        () =>
            isNonEmptyArray(props.initialValues.position.tracesIds) ||
            isNonEmptyString(props.initialValues.position.address) ||
            typeof props.initialValues.position.latitude === 'number' ||
            typeof props.initialValues.position.longitude === 'number',
        [props.isEdit, props.initialValues]
    );
    const areConditionsOpen = React.useMemo(
        () =>
            props.isEdit
                ? isNonEmptyArray(props.initialValues.position.positionsCustomFields)
                : false,
        [props.isEdit, props.initialValues]
    );
    const areCustomFieldsOpen = React.useMemo(
        () =>
            props.isEdit
                ? isNonEmptyArray(Object.keys(props.initialValues.position.fields))
                : false,
        [props.isEdit, props.initialValues]
    );
    const isPrivateNoteOpen = React.useMemo(
        () => (props.isEdit ? isNonEmptyString(props.initialValues.position.privateNote) : false),
        [props.isEdit, props.initialValues]
    );
    const areTagsOpen = React.useMemo(
        () => (props.isEdit ? isNonEmptyArray(props.initialValues.position.tagsIds) : false),
        [props.isEdit, props.initialValues]
    );

    return (
        <Form
            height={1}
            hideErrors={true}
            initialValues={props.initialValues}
            render={({ form, handleSubmit, submitting, values }) => (
                <FullScreenPopup
                    button={
                        props.isEdit && (
                            <Button isLoading={submitting} onClick={handleSubmit}>
                                {translate('mettre_jour_l_19061')}
                            </Button>
                        )
                    }
                    category={category}
                    color="dark"
                    title={
                        props.isEdit
                            ? props.initialValues.position.name
                            : translate('nouvelle_missio_60727')
                    }
                >
                    <CenteredContainer ref={centeredContainerRef}>
                        <FormErrors />

                        <FormBox
                            hideToggle={true}
                            initialIsOpen={true}
                            title={translate('informations_su_61022')}
                        >
                            <CreatePositionInformations change={form.change} event={props.event} />
                        </FormBox>

                        <Spacer height="5" />

                        <FormBox
                            hideToggle={true}
                            initialIsOpen={true}
                            subtitle={translate('ajouter_les_dif_22779')}
                            title={translate('cr_neaux_horair_62996')}
                        >
                            <CreatePositionSlots
                                change={form.change}
                                event={props.event}
                                values={values}
                            />
                        </FormBox>

                        <Spacer height="5" />

                        <FormBox
                            initialIsOpen={isLocalisationOpen}
                            subtitle={translate('localiser_le_li_46319')}
                            title={translate('localisation_38416')}
                            onToggle={(isOpen) => {
                                if (!isOpen) {
                                    form.change('position.layer', props.event.layer);
                                    form.change('position.zoom', DEFAULT_ZOOM);
                                    form.change('position.tracesIds', []);
                                    form.change('position.address', null);
                                    form.change('position.latitude', null);
                                    form.change('position.longitude', null);
                                }
                            }}
                        >
                            <LocationForm
                                center={props.event}
                                layer={values.position.layer}
                                name="position."
                                point={values.position}
                                traces={props.event.traces}
                                tracesIds={values.position.tracesIds}
                            />
                        </FormBox>

                        <Spacer height="5" />

                        <FormBox
                            initialIsOpen={areConditionsOpen}
                            subtitle={translate('ajouter_des_con_75357')}
                            title={translate('conditions_77756')}
                            onToggle={(isOpen) => {
                                if (!isOpen) {
                                    form.change('position.positionsCustomFields', []);
                                }
                            }}
                        >
                            <ConditionsForm
                                change={form.change}
                                conditionsOperator={values.position.conditionsOperator}
                                customFields={props.userInfoCustomFields}
                                customFieldsValues={values.position.positionsCustomFields}
                                name="position.positionsCustomFields"
                                prefix="position."
                            />
                        </FormBox>

                        <Spacer height="5" />

                        <FormBox
                            initialIsOpen={areCustomFieldsOpen}
                            subtitle={translate('ajouter_des_inf_14340')}
                            title={translate('champs_de_formu_64126')}
                            onToggle={(isOpen) => {
                                if (!isOpen) {
                                    form.change('position.fields', {});
                                }
                            }}
                        >
                            <CreatePositionCustomFields
                                change={form.change}
                                customFields={props.positionCustomFields}
                                prefix="position."
                            />
                        </FormBox>

                        <Spacer height="5" />

                        <FormBox
                            initialIsOpen={isPrivateNoteOpen}
                            subtitle={translate('ajouter_une_not_81707')}
                            title={translate('note_priv_e_06303')}
                            onToggle={(isOpen) => {
                                if (!isOpen) {
                                    form.change('position.privateNote', '');
                                }
                            }}
                        >
                            <CreatePositionPrivateNote />
                        </FormBox>

                        <Spacer height="5" />

                        <FormBox
                            initialIsOpen={areTagsOpen}
                            subtitle={translate('ajouter_des_tag_64526')}
                            title={translate('tags_79499')}
                            onToggle={(isOpen) => {
                                if (!isOpen) {
                                    form.change('position.tagsIds', []);
                                }
                            }}
                        >
                            <TagsForm
                                change={form.change}
                                name="position."
                                organizationId={organizationId}
                                tags={props.tags}
                                tagsIds={values.position.tagsIds}
                            />
                        </FormBox>

                        <Spacer height="5" />

                        <EmptyFormBox>
                            <Button
                                isLoading={submitting}
                                textAlign="center"
                                onClick={handleSubmit}
                            >
                                {props.isEdit
                                    ? translate('mettre_jour_l_19061')
                                    : translate('cr_er_la_missio_82644')}
                            </Button>
                        </EmptyFormBox>
                    </CenteredContainer>
                </FullScreenPopup>
            )}
            validate={validateService.validateForForm(positionInput.createUpdatePositionSchema())}
            width={1}
            onShowErrors={() => {
                if (centeredContainerRef.current) {
                    centeredContainerRef.current.scrollTop = 0;
                }
            }}
            onSubmit={async (values: ICreateUpdatePositionValues) => {
                const id = await props.mutate({
                    eventId,
                    position: {
                        ...values.position,
                        slots: values.position.slots.map(
                            (slot) => omit(slot, ['index']) as PositionsSlotInput
                        )
                    }
                });

                if (props.isEdit) {
                    history.goBack(
                        PositionsPaths.POSITION({
                            organizationId,
                            eventId,
                            positionId: id
                        })
                    );
                } else {
                    history.replace(
                        PositionsPaths.POSITION({
                            organizationId,
                            eventId,
                            positionId: id
                        })
                    );
                }
            }}
        />
    );
};

const parseThirdParameter = (param?: string) => {
    const [latitudeString, longitudeString] = (param ?? '').split(',');

    if (isNonEmptyString(latitudeString) && isNonEmptyString(longitudeString)) {
        return {
            latitude: parseFloat(latitudeString),
            longitude: parseFloat(longitudeString)
        };
    } else if (isNonEmptyString(param)) {
        return { positionCategoryId: parseInt(param, 10) as PositionsCategoryId };
    } else {
        return {};
    }
};

export const CreatePosition = () => {
    const positionInput = useService(PositionInputService);
    const { organizationId, eventId, options } = useParams<{ options: string }>();
    const { data, loader } = usePositionEventInfosQuery({ organizationId, eventId });
    const { mutate } = usePositionCreateMutation({ redirectOnSuccess: true });
    const { positionCategoryId, latitude, longitude } = parseThirdParameter(options);

    if (loader) {
        return loader;
    } else {
        return (
            <CreateUpdatePosition
                event={data.event}
                initialValues={{
                    position: positionInput.positionInputDefault(null, {
                        layer: data.event.layer,
                        startAt: data.event.startAt,
                        positionCategoryId,
                        latitude,
                        longitude
                    })
                }}
                isEdit={false}
                mutate={async (values) => {
                    const {
                        positionCreate: { id }
                    } = await mutate(values);

                    return id;
                }}
                positionCustomFields={data.organization.positionCustomFields.nodes}
                tags={data.organization.tags.nodes}
                userInfoCustomFields={data.organization.customFields}
            />
        );
    }
};

export const UpdatePosition = () => {
    const { organizationId, eventId, positionId } = useParams();
    const { data, loader } = usePositionToEditQuery({ organizationId, eventId, positionId });
    const positionInput = useService(PositionInputService);
    const { mutate } = usePositionUpdateMutation({ redirectOnSuccess: true });

    return (
        loader || (
            <CreateUpdatePosition
                event={data.event}
                initialValues={{
                    position: positionInput.positionInputDefault(data.event.position, {
                        layer: data.event.layer,
                        startAt: data.event.startAt
                    })
                }}
                isEdit={true}
                mutate={async (values) => {
                    const {
                        positionUpdate: { id }
                    } = await mutate({
                        ...values,
                        positionId
                    });

                    return id;
                }}
                positionCustomFields={data.organization.positionCustomFields.nodes}
                tags={data.organization.tags.nodes}
                userInfoCustomFields={data.organization.customFields}
            />
        )
    );
};
