import {
    AccreditationsSlotId,
    DelegationId,
    EventId,
    UsersInfoId,
    VolunteerRegistrationFragment
} from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';
import { RightPanel } from '../../../designSystem/components/rightPanel/rightPanel';
import { RightPanelBody } from '../../../designSystem/components/rightPanel/rightPanelBody';
import { RightPanelHeader } from '../../../designSystem/components/rightPanel/rightPanelHeader';
import { Tab } from '../../../designSystem/components/tabs/tab';
import { TabList } from '../../../designSystem/components/tabs/tabList';
import { TabPanel } from '../../../designSystem/components/tabs/tabPanel';
import { Tabs } from '../../../designSystem/components/tabs/tabs';
import { useHeavent } from '../../../hooks/useHeavent';
import { AccreditationsRightPanelAssignedTab } from './accreditationsRightPanelAssignedTab';
import { AccreditationsRightPanelContextProvider } from './accreditationsRightPanelContext';

interface IAccreditationsRightPanelProps {
    basePath: string;
    canAccredit: boolean;
    canEditWished: boolean;
    defaultAccreditationsSlotsIds: AccreditationsSlotId[];
    delegationId: Emptyable<DelegationId>;
    eventId: EventId;
    volunteerRegistration: Pick<
        VolunteerRegistrationFragment,
        'accreditationsUsersInfos' | 'accreditationsSlots'
    >;
    userInfoId: UsersInfoId;

    onClose(): void;
}

export const AccreditationsRightPanel = (props: IAccreditationsRightPanelProps) => {
    const { translate } = useHeavent();

    return (
        <AccreditationsRightPanelContextProvider
            canAccredit={props.canAccredit}
            canEditWished={props.canEditWished}
            defaultAccreditationsSlotsIds={props.defaultAccreditationsSlotsIds}
            delegationId={props.delegationId}
            eventId={props.eventId}
            userInfoId={props.userInfoId}
            volunteerRegistration={props.volunteerRegistration}
        >
            <RightPanel size="md" onClose={props.onClose}>
                <RightPanelHeader>{translate('accr_ditations_39450')}</RightPanelHeader>

                <RightPanelBody css={{ padding: '0' }}>
                    <Tabs height={1}>
                        <TabList center={true}>
                            <Tab path={`${props.basePath}/assigned`}>
                                {translate('attribu_es_48167')}
                            </Tab>

                            <Tab path={`${props.basePath}/wished`}>
                                {translate('souhait_es_78101')}
                            </Tab>
                        </TabList>

                        <TabPanel path={`${props.basePath}/assigned`}>
                            <AccreditationsRightPanelAssignedTab state="assigned" />
                        </TabPanel>

                        <TabPanel path={`${props.basePath}/wished`}>
                            <AccreditationsRightPanelAssignedTab state="wished" />
                        </TabPanel>
                    </Tabs>
                </RightPanelBody>
            </RightPanel>
        </AccreditationsRightPanelContextProvider>
    );
};
