import { BadgesPlus } from 'common-front/src/components/badgesPlus/badgesPlus';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { DelegationsDelegationFragment } from 'common/src/generated/types';
import { sortBy } from 'lodash-es';
import * as React from 'react';

interface IDelegationsLeadersCellProps {
    delegation: DelegationsDelegationFragment;
}

export const DelegationsLeadersCell = (props: IDelegationsLeadersCellProps) => {
    const badges = React.useMemo(
        () =>
            sortBy(props.delegation.leaders, (l) => l.nameOrEmail.toLowerCase()).map(
                (l, index) => ({
                    id: index,
                    color: 'gray',
                    icon: null,
                    text: l.nameOrEmail
                })
            ),
        [props.delegation]
    );

    return (
        <Cell>
            <BadgesPlus badges={badges} />
        </Cell>
    );
};
