import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';
import { useUniqueIds } from '../../hooks/useUniqueIds';

interface IFieldSetProps {
    children: React.ReactNode;
    description?: string;
    role?: React.AriaRole;
    title?: string;
}

export const FieldSet = ({ children, description, role, title }: IFieldSetProps) => {
    const { descId } = useUniqueIds();

    return (
        <Flex
            aria-describedby={descId}
            as="fieldset"
            direction="column"
            role={role}
            style={{
                border: 'inherit',
                margin: 'inherit'
            }}
        >
            <Box as="legend" color="gray800" fontSize="textMd" fontWeight="semiBold">
                {title}
            </Box>

            <Spacer height="1" />

            <Box color="gray500" id={descId}>
                {description}
            </Box>

            <Box css={{ paddingBlockStart: '$5' }}>{children}</Box>
        </Flex>
    );
};
