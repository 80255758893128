import { CommonEnvVars } from 'common/src/envVars';
import { CampaignQuery } from 'common/src/generated/types';
import * as React from 'react';
import { EmailPreview } from '../../emails/preview/emailPreview';

interface ICampaignEmailPreviewProps {
    campaign: CampaignQuery['organization']['campaign'];
}

export const CampaignEmailPreview = (props: ICampaignEmailPreviewProps) => {
    const creator = React.useMemo(() => {
        if (props.campaign.createdBy) {
            return `${props.campaign.createdBy.name} (${props.campaign.createdBy.email})`;
        } else {
            return null;
        }
    }, [props.campaign]);
    const from = React.useMemo(() => {
        if (props.campaign.emailSender) {
            return `${props.campaign.emailSender.fromName} (${props.campaign.emailSender.fromEmail})`;
        } else {
            return CommonEnvVars.EMAIL_FROM;
        }
    }, [props.campaign]);

    return (
        <EmailPreview
            attachments={props.campaign.attachments}
            content={props.campaign.preview}
            createdBy={creator}
            documents={props.campaign.documents}
            from={from}
            subject={props.campaign.subject}
            to={props.campaign}
        />
    );
};
