import { Button } from 'common-front/src/designSystem/components/button';
import {
    closeOtherDropdowns,
    Dropdown
} from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { Modal } from 'common-front/src/designSystem/components/modal/modal';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { LinkText } from 'common/src/designSystem/components/linkText';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EventsQuery } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { EventsPaths } from 'common/src/util/paths/eventsPaths';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { getStateColor, startAtEndAtToString } from 'common/src/vo/event';
import * as React from 'react';
import { useOrganizationContext } from '../../organization/organizationContext';
import { EventArchiveModal } from './eventArchiveModal';
import { EventDuplicateModal } from './eventDuplicateModal';

interface IEventsEventProps {
    event: EventsQuery['organization']['events']['nodes'][0];

    reload(): void;
}

export const EventsEvent = (props: IEventsEventProps) => {
    const {
        params: { organizationId },
        translate
    } = useHeavent();
    const { isOrganizationAdmin } = useOrganizationContext();
    const dateTimeService = useService(DateTimeService);
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const [isDuplicateOpen, setIsDuplicateOpen] = React.useState(false);
    const [isArchiveOpen, setIsArchiveOpen] = React.useState(false);

    return (
        <>
            <Flex
                css={{
                    border: '1px solid $gray200',
                    borderRadius: '12px',
                    boxShadow: '$xs',
                    padding: '$4'
                }}
                gap="3"
            >
                <Flex css={{ flex: '1' }} direction="column">
                    <Box css={{ width: 'fit-content' }}>
                        <Badge color={getStateColor(props.event.state)}>
                            {translate(props.event.state)}
                        </Badge>
                    </Box>

                    <Spacer height="1" />

                    <LinkText
                        css={{
                            color: '$gray900',
                            fontSize: '$textLg',
                            fontWeight: '$semiBold',
                            inlineSize: 'fit-content'
                        }}
                        to={EventsPaths.EVENT({ organizationId, eventId: props.event.id })}
                    >
                        {props.event.name}
                    </LinkText>

                    <Spacer height="4" />

                    <Flex gap="10">
                        <Flex direction="column" gap="1">
                            <Flex align="center" css={{ color: '$gray500' }} gap="2">
                                <I icon="calendar" />

                                <Box>{translate('date_de_l_v_ne_69973')}</Box>
                            </Flex>

                            <Box font="gray800 textSm medium">
                                {startAtEndAtToString(
                                    dateTimeService,
                                    props.event.startAt,
                                    props.event.endAt
                                )}
                            </Box>
                        </Flex>

                        <Flex direction="column" gap="1">
                            <Flex align="center" css={{ color: '$gray500' }} gap="2">
                                <I icon="tag" />

                                <Box>{translate('tags_79499')}</Box>
                            </Flex>

                            <Flex gap="2">
                                {isNonEmptyArray(props.event.tags) ? (
                                    props.event.tags.map((tag) => (
                                        <Badge key={tag.id} size="sm">
                                            {tag.name}
                                        </Badge>
                                    ))
                                ) : (
                                    <Box font="gray800 textSm medium">-</Box>
                                )}
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>

                <Button to={EventsPaths.EVENT({ organizationId, eventId: props.event.id })}>
                    {translate('voir_l_v_nemen_72358')}
                </Button>

                {isOrganizationAdmin && (
                    <Dropdown isOpen={isDropdownOpen} onStateChange={setIsDropdownOpen}>
                        <Trigger>
                            <Button color="white" leftIcon="ellipsis-vertical" />
                        </Trigger>

                        <Menu placement="bottom-end">
                            <ItemIcon
                                icon="pen"
                                to={HeaventPaths.EDIT_EVENT(organizationId, props.event.id)}
                            >
                                {translate('_diter_62574')}
                            </ItemIcon>

                            <ItemIcon
                                icon="clone"
                                onClick={(e) => {
                                    closeOtherDropdowns(e.target);
                                    setIsDuplicateOpen(true);
                                }}
                            >
                                {translate('dupliquer_28256')}
                            </ItemIcon>

                            <ItemIcon
                                color="red"
                                icon="trash-can"
                                onClick={(e) => {
                                    closeOtherDropdowns(e.target);
                                    setIsArchiveOpen(true);
                                }}
                            >
                                {translate('archiver_26131')}
                            </ItemIcon>
                        </Menu>
                    </Dropdown>
                )}
            </Flex>

            <Modal.Root
                isOpen={isDuplicateOpen}
                onClose={() => {
                    setIsDuplicateOpen(false);
                }}
            >
                <Modal.Portal>
                    <EventDuplicateModal
                        event={props.event}
                        reload={props.reload}
                        onClose={() => {
                            setIsDuplicateOpen(false);
                        }}
                    />
                </Modal.Portal>
            </Modal.Root>

            {isArchiveOpen && (
                <EventArchiveModal
                    event={props.event}
                    onClose={() => setIsArchiveOpen(false)}
                    onSuccess={props.reload}
                />
            )}
        </>
    );
};
