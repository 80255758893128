import { injectable } from 'inversify';
import { sortBy } from 'lodash-es';
import {
    ALL_CUSTOMFIELDVARIETY,
    ALL_FIELDTYPE,
    CustomFieldsSegmentsQuery,
    CustomFieldsSortAttributes,
    FilterType
} from '../../generated/types';
import { CountriesService } from '../../services/countriesService';
import { DateTimeService } from '../../services/dateTimeService';
import { TranslationService } from '../../services/translationService';
import { varietyToTranslation } from '../customField';
import { Filter, PossibleColumn, SegmentService } from '../segment';

@injectable()
export class CustomFieldsSegmentsService extends SegmentService {
    constructor(
        countriesService: CountriesService,
        dateTimeService: DateTimeService,
        translationService: TranslationService
    ) {
        super(countriesService, dateTimeService, translationService);
    }

    getCustomFieldsFilters(infos: CustomFieldsSegmentsQuery['organization']): Filter[] {
        return [
            {
                slug: 'name',
                name: this.t('nom_du_champ_48329'),
                category: this.t('champs_de_formu_64126'),
                fieldType: FilterType.Text,
                hideEmpty: true
            },
            {
                slug: 'label',
                name: this.t('label_08243'),
                category: this.t('champs_de_formu_64126'),
                fieldType: FilterType.Text
            },
            {
                slug: 'customFieldCategory',
                name: this.t('cat_gorie_00291'),
                category: this.t('champs_de_formu_64126'),
                fieldType: FilterType.Select,
                values: infos.customFieldsCategories.nodes,
                hideEmpty: true
            },
            {
                slug: 'fieldType',
                name: this.t('type_35427'),
                category: this.t('champs_de_formu_64126'),
                fieldType: FilterType.Select,
                values: ALL_FIELDTYPE.map((fieldType) => ({
                    id: fieldType,
                    name: fieldType
                })),
                hideEmpty: true
            },
            {
                slug: 'variety',
                name: this.t('typologie_34041'),
                category: this.t('champs_de_formu_64126'),
                fieldType: FilterType.Select,
                values: sortBy(
                    ALL_CUSTOMFIELDVARIETY.map((variety) => ({
                        id: variety,
                        name: varietyToTranslation(this.t.bind(this), variety)
                    })),
                    ({ name }) => name
                ),
                hideEmpty: true
            },
            {
                slug: 'privacy',
                name: this.t('Privacy'),
                category: this.t('champs_de_formu_64126'),
                fieldType: FilterType.Checkbox,
                hideEmpty: true
            },

            {
                slug: 'condition',
                name: 'Condition',
                category: this.t('champs_de_formu_64126'),
                fieldType: FilterType.Select,
                values: infos.customFields.nodes
            }
        ];
    }

    getCustomFieldsPossibleColumns(): PossibleColumn[] {
        return [
            {
                slug: 'id',
                name: this.t('id_51738'),
                isSortable: true,
                sortAttribute: CustomFieldsSortAttributes.Id,
                width: 100
            },
            {
                slug: 'name',
                name: this.t('nom_du_champ_48329'),
                isSortable: true,
                sortAttribute: CustomFieldsSortAttributes.Name
            },
            {
                slug: 'label',
                name: this.t('label_08243'),
                isSortable: true,
                sortAttribute: CustomFieldsSortAttributes.Label
            },
            {
                slug: 'category',
                name: this.t('Category'),
                isSortable: true,
                sortAttribute: CustomFieldsSortAttributes.Category
            },
            {
                slug: 'variety',
                name: this.t('typologie_34041')
            },
            {
                slug: 'fieldType',
                name: this.t('type_35427'),
                isSortable: true,
                sortAttribute: CustomFieldsSortAttributes.Type,
                width: 250
            },
            {
                slug: 'numberOfTimesUsed',
                name: this.t('nombre_d_utilis_58389'),
                width: 200
            },
            {
                slug: 'state',
                name: this.t('Privacy'),
                isSortable: true,
                sortAttribute: CustomFieldsSortAttributes.Status,
                width: 200
            },
            {
                slug: 'values',
                name: this.t('valeurs_34314')
            },
            {
                slug: 'variable',
                name: this.t('variable_pour_l_45711')
            },
            {
                slug: 'createdBy',
                name: this.t('cr_par_41994')
            },
            {
                slug: 'createdAt',
                name: this.t('cr_le_85046'),
                isSortable: true,
                sortAttribute: CustomFieldsSortAttributes.CreatedAt
            }
        ];
    }
}
