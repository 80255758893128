import { buildPath } from './commonPaths';
import { CommunityPaths } from './communityPaths';
import { EventParams, EventsPaths } from './eventsPaths';
import { OrganizationParams } from './organizationsPaths';
import { UserInfoIdPathParam } from './pathsTypes';

const slugs = {
    ITEMS: {
        USER: {
            PLURAL: 'users',
            SINGLE: 'user'
        }
    },
    SECTIONS: {
        ACCREDITATIONS: 'accreditations',
        ACTIONS: 'actions',
        AVAILABILITIES: 'availabilities',
        CAMPAIGNS: 'campaigns',
        COMMENTS: 'comments',
        HISTORY: 'history',
        INFORMATIONS: 'informations',
        POSITIONS: 'positions'
    },
    SUB_SECTIONS: {
        CAMPAIGNS: 'campaigns',
        EVENTS: 'events',
        FORMS: 'forms'
    }
};

const itemPaths = {
    USER(userInfoId: UserInfoIdPathParam) {
        return buildPath([slugs.ITEMS.USER.SINGLE, userInfoId]);
    }
};

export type UserInfoParams = OrganizationParams &
    Partial<EventParams> & {
        userInfoId: UserInfoIdPathParam;
    };

export const UsersPaths = {
    //
    // READ
    //

    USER({ eventId, userInfoId, ...params }: UserInfoParams) {
        return buildPath([
            eventId ? EventsPaths.EVENT({ ...params, eventId }) : CommunityPaths.COMMUNITY(params),
            itemPaths.USER(userInfoId)
        ]);
    },

    // tabs
    USER_INFORMATIONS(params: UserInfoParams) {
        return buildPath([UsersPaths.USER(params), slugs.SECTIONS.INFORMATIONS]);
    },
    USER_ACTIONS(params: UserInfoParams) {
        return buildPath([UsersPaths.USER(params), slugs.SECTIONS.ACTIONS]);
    },
    USER_AVAILABILITIES(params: UserInfoParams) {
        return buildPath([UsersPaths.USER(params), slugs.SECTIONS.AVAILABILITIES]);
    },
    USER_COMMENTS(params: UserInfoParams) {
        return buildPath([UsersPaths.USER(params), slugs.SECTIONS.COMMENTS]);
    },
    USER_HISTORY(params: UserInfoParams) {
        return buildPath([UsersPaths.USER(params), slugs.SECTIONS.HISTORY]);
    },
    USER_HISTORY_CAMPAIGNS(params: UserInfoParams) {
        return buildPath([
            UsersPaths.USER(params),
            slugs.SECTIONS.HISTORY,
            slugs.SUB_SECTIONS.CAMPAIGNS
        ]);
    },
    USER_HISTORY_EVENTS(params: UserInfoParams) {
        return buildPath([
            UsersPaths.USER(params),
            slugs.SECTIONS.HISTORY,
            slugs.SUB_SECTIONS.EVENTS
        ]);
    },
    USER_HISTORY_FORMS(params: UserInfoParams) {
        return buildPath([
            UsersPaths.USER(params),
            slugs.SECTIONS.HISTORY,
            slugs.SUB_SECTIONS.FORMS
        ]);
    },
    USER_POSITIONS(params: UserInfoParams) {
        return buildPath([UsersPaths.USER(params), slugs.SECTIONS.POSITIONS]);
    },
    USER_ACCREDITATIONS(params: UserInfoParams) {
        return buildPath([UsersPaths.USER(params), slugs.SECTIONS.ACCREDITATIONS]);
    }
};
