import { Box } from 'common/src/designSystem/components/box';
import { Link } from 'common/src/designSystem/components/link';
import { useLocation } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface ITabProps {
    basePath?: string;
    children: React.ReactNode;
    path: string;
}

export const Tab = (props: ITabProps) => {
    const location = useLocation();
    const isSelected = location.pathname.includes(props.basePath || props.path);

    return (
        <>
            <Box
                color={isSelected ? 'primary700' : 'gray500'}
                css={{
                    borderBottom: isSelected ? '2px solid $primary700' : 'unset',
                    cursor: isSelected ? 'inherit' : 'pointer',
                    paddingBlock: '$3',
                    '&:hover': {
                        color: isSelected ? '$primary800' : '$gray800'
                    }
                }}
            >
                <Link replace={true} to={props.path}>
                    {props.children}
                </Link>
            </Box>

            <Box width={24} />
        </>
    );
};
