import { Form } from 'common-front/src/components/form/form';
import { Button } from 'common-front/src/designSystem/components/button';
import { HorizontalSpacerSeparator } from 'common-front/src/designSystem/components/separator';
import { CheckboxText as CheckboxTextForm } from 'common-front/src/designSystem/form/checkbox';
import { RadioText } from 'common-front/src/designSystem/form/radio';
import { RichEditor } from 'common-front/src/designSystem/form/richEditor';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { ToggleText } from 'common-front/src/designSystem/form/toggle';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    EventId,
    RegisterAccreditationDisplay,
    RegisterPositionFilter,
    RegisterSlotDisplay
} from 'common/src/generated/types';
import {
    FormParametersAccreditationsInputService,
    IUpdateFormParametersAccreditationsValues
} from 'common/src/input/formParametersAccreditationsInput';
import { ValidateService } from 'common/src/services/validateService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import {
    useFormParametersAccreditationsQuery,
    useFormParametersAccreditationsUpdateMutation
} from '../../../../generated/graphqlHooks';
import { FormContainer } from '../../../show/formContainer';
import { FormParametersContainer } from '../formParametersContainer';
import { useFormParametersClose } from '../useFormParametersClose';
import { FormParametersAccreditationsHiddenDisplayed } from './hiddenDisplayed/formParametersAccreditationsHiddenDisplayed';

interface IFormParametersAccreditationsInnerProps {
    eventId: EventId;
    initialValues: IUpdateFormParametersAccreditationsValues;
    isMutateLoading: boolean;
    loader: React.JSX.Element | null;
    name: string;
    slotDisplay: RegisterSlotDisplay;
    values: IUpdateFormParametersAccreditationsValues;

    change(name: string, value: any): void;
    handleSubmit(): void;
}

const FormParametersAccreditationsInner = (props: IFormParametersAccreditationsInnerProps) => {
    const translate = useTranslate();
    const { isNotSavedModalOpen, onClose, onNotSavedClose, onSave } = useFormParametersClose(
        props.initialValues,
        props.values,
        props.handleSubmit
    );
    const showAccreditationFilter =
        props.slotDisplay !== RegisterSlotDisplay.Hide &&
        props.values.form.accreditationDisplay !== RegisterAccreditationDisplay.None;

    return (
        <FormContainer
            button={
                <Button isLoading={props.isMutateLoading} onClick={onSave}>
                    {translate('enregistrer_06519')}
                </Button>
            }
            title={props.name}
            onClose={onClose}
        >
            <FormParametersContainer
                isNotSavedOpen={isNotSavedModalOpen}
                onNotSavedClose={onNotSavedClose}
            >
                {props.loader || (
                    <>
                        <Box font="gray800 textMd semiBold">
                            {translate('param_tres_des_90955')}
                        </Box>

                        <Spacer height="6" />

                        <Box color="gray800" fontWeight="medium">
                            {translate('comment_souhait_18471')}
                        </Box>

                        <Spacer height="2" />

                        <RadioText
                            name="form.accreditationDisplay"
                            value={RegisterAccreditationDisplay.Accreditation}
                        >
                            {translate('afficher_les_ac_56952')}
                        </RadioText>

                        <Spacer height="3" />

                        <RadioText
                            name="form.accreditationDisplay"
                            value={RegisterAccreditationDisplay.None}
                        >
                            {translate('ne_rien_affiche_81467')}
                        </RadioText>

                        {props.values.form.accreditationDisplay !==
                            RegisterAccreditationDisplay.None && (
                            <>
                                <Spacer height="6" />

                                <Box color="gray800" fontWeight="medium">
                                    {translate('personnalisatio_86385')}
                                </Box>

                                <Spacer height="2" />

                                <ToggleText name="form.showFullAccreditation">
                                    {translate('afficher_les_ac_56062')}
                                </ToggleText>

                                <Spacer height="3" />

                                <FormParametersAccreditationsHiddenDisplayed
                                    change={props.change}
                                    eventId={props.eventId}
                                    values={props.values}
                                />

                                <Spacer height="6" />

                                <Box color="gray800" fontWeight="medium">
                                    {translate('affichage_des_d_57491')}
                                </Box>

                                <Spacer height="2" />

                                <CheckboxTextForm name="form.showAccreditationDescription">
                                    {translate('afficher_la_des_94587')}
                                </CheckboxTextForm>
                            </>
                        )}

                        {showAccreditationFilter && (
                            <>
                                <HorizontalSpacerSeparator height="7" />

                                <Box font="gray800 textMd semiBold">
                                    {translate('filtrer_les_acc_57840')}
                                </Box>

                                <Spacer height="1" />

                                <Box color="gray500">{translate('d_cider_si_le_c_31187')}</Box>

                                <Spacer height="2" />

                                <RadioText
                                    name="form.accreditationFilter"
                                    value={RegisterPositionFilter.FilterWithSlots}
                                >
                                    {translate('filtrer_les_acc_66152')}
                                </RadioText>

                                <Spacer height="3" />

                                <RadioText
                                    name="form.accreditationFilter"
                                    value={RegisterPositionFilter.None}
                                >
                                    {translate('ne_pas_filtrer_97321')}
                                </RadioText>
                            </>
                        )}

                        {props.values.form.accreditationDisplay !==
                            RegisterAccreditationDisplay.None && (
                            <>
                                <HorizontalSpacerSeparator height="7" />

                                <Box font="gray800 textMd semiBold">
                                    {translate('personnalisatio_15893')}
                                </Box>

                                <Spacer height="1" />

                                <Box color="gray500">{translate('personnaliser_l_44808')}</Box>

                                <Spacer height="4" />

                                <TextInput
                                    label={translate('nom_de_l_tape_85862')}
                                    name="form.accreditationTitle"
                                />

                                <Spacer height="6" />

                                <RichEditor
                                    label={translate('description_58935')}
                                    name="form.accreditationSubtitle"
                                />
                            </>
                        )}
                    </>
                )}
            </FormParametersContainer>
        </FormContainer>
    );
};

export const FormParametersAccreditations = () => {
    const {
        params: { organizationId, eventId, formId }
    } = useHeavent();
    const { data, loader } = useFormParametersAccreditationsQuery({
        organizationId,
        formId
    });
    useTitle(data.organization?.form.name ?? '');
    const validateService = useService(ValidateService);
    const formParametersAccreditationsInput = useService(FormParametersAccreditationsInputService);
    const { mutate, isLoading } = useFormParametersAccreditationsUpdateMutation();
    const initialValues = React.useMemo(
        () => ({
            form: formParametersAccreditationsInput.formParametersAccreditationsInputDefault(
                data.organization?.form
            )
        }),
        [data.organization]
    );

    return (
        <Form
            direction="column"
            height={1}
            initialValues={initialValues}
            render={({ form, handleSubmit, values }) => (
                <FormParametersAccreditationsInner
                    change={form.change}
                    eventId={eventId}
                    handleSubmit={handleSubmit}
                    initialValues={initialValues}
                    isMutateLoading={isLoading}
                    loader={loader}
                    name={data.organization?.form.name ?? ''}
                    slotDisplay={data.organization?.form.slotDisplay || RegisterSlotDisplay.Hide}
                    values={values}
                />
            )}
            validate={validateService.validateForForm(
                formParametersAccreditationsInput.updateFormParametersAccreditationsSchema()
            )}
            width={1}
            onSubmit={(values: IUpdateFormParametersAccreditationsValues) =>
                mutate({
                    organizationId,
                    eventId,
                    formId,
                    form: values.form
                })
            }
        />
    );
};
