import { buildPath } from './commonPaths';
import { OrganizationIdPathParam } from './pathsTypes';

export const slugs = {
    ITEMS: {
        ORGANIZATIONS: {
            PLURAL: '/organizations',
            SINGLE: '/organization'
        }
    },
    ACTIONS: {
        ARCHIVE: 'archive'
    },
    SECTIONS: {
        SETTINGS: {
            _BASE: 'parameters',
            MEMBERS_SPACE: {
                _BASE: 'members-space',
                DESIGN: 'design',
                INTEGRATION: 'integration',
                EMAIL_TEMPLATES: 'emails',
                SETTINGS: 'settings'
            },
            DELEGATION_SPACE: {
                _BASE: 'delegation-space',
                DESIGN: 'design',
                INTEGRATION: 'integration',
                EMAIL_TEMPLATES: 'emails',
                SETTINGS: 'settings'
            }
        }
    },
    STATES: {
        BLOCKED: 'blocked',
        EXPIRED: 'expired'
    }
};

export type OrganizationParams = {
    organizationId: OrganizationIdPathParam;
};

const itemPaths = {
    ORGANIZATIONS: slugs.ITEMS.ORGANIZATIONS.PLURAL,
    ORGANIZATION: (organizationId: OrganizationIdPathParam) =>
        buildPath([slugs.ITEMS.ORGANIZATIONS.SINGLE, organizationId])
};

export const OrganizationsPaths = {
    //
    // CREATE
    //

    //
    // READ
    //

    ORGANIZATIONS: itemPaths.ORGANIZATIONS,
    ORGANIZATION: ({ organizationId }: OrganizationParams) =>
        itemPaths.ORGANIZATION(organizationId),

    // Backoffice space

    // Backoffice space -> Settings

    ORGANIZATION_SETTINGS: (params: OrganizationParams) =>
        buildPath([OrganizationsPaths.ORGANIZATION(params), slugs.SECTIONS.SETTINGS._BASE]),

    // Backoffice space -> Settings -> Members' space

    ORGANIZATION_SETTINGS_MEMBERS_SPACE: (params: OrganizationParams) =>
        buildPath([
            OrganizationsPaths.ORGANIZATION_SETTINGS(params),
            slugs.SECTIONS.SETTINGS.MEMBERS_SPACE._BASE
        ]),
    ORGANIZATION_SETTINGS_MEMBERS_SPACE_DESIGN: (params: OrganizationParams) =>
        buildPath([
            OrganizationsPaths.ORGANIZATION_SETTINGS_MEMBERS_SPACE(params),
            slugs.SECTIONS.SETTINGS.MEMBERS_SPACE.DESIGN
        ]),
    ORGANIZATION_SETTINGS_MEMBERS_SPACE_INTEGRATION: (params: OrganizationParams) =>
        buildPath([
            OrganizationsPaths.ORGANIZATION_SETTINGS_MEMBERS_SPACE(params),
            slugs.SECTIONS.SETTINGS.MEMBERS_SPACE.INTEGRATION
        ]),
    ORGANIZATION_SETTINGS_MEMBERS_SPACE_EMAIL_TEMPLATES: (params: OrganizationParams) =>
        buildPath([
            OrganizationsPaths.ORGANIZATION_SETTINGS_MEMBERS_SPACE(params),
            slugs.SECTIONS.SETTINGS.MEMBERS_SPACE.EMAIL_TEMPLATES
        ]),
    ORGANIZATION_SETTINGS_MEMBERS_SPACE_SETTINGS: (params: OrganizationParams) =>
        buildPath([
            OrganizationsPaths.ORGANIZATION_SETTINGS_MEMBERS_SPACE(params),
            slugs.SECTIONS.SETTINGS.MEMBERS_SPACE.SETTINGS
        ]),

    // Back office -> Settings -> Delegation space

    ORGANIZATION_SETTINGS_DELEGATION_SPACE: (params: OrganizationParams) =>
        buildPath([
            OrganizationsPaths.ORGANIZATION_SETTINGS(params),
            slugs.SECTIONS.SETTINGS.DELEGATION_SPACE._BASE
        ]),
    ORGANIZATION_SETTINGS_DELEGATION_SPACE_DESIGN: (params: OrganizationParams) =>
        buildPath([
            OrganizationsPaths.ORGANIZATION_SETTINGS_DELEGATION_SPACE(params),
            slugs.SECTIONS.SETTINGS.DELEGATION_SPACE.DESIGN
        ]),
    ORGANIZATION_SETTINGS_DELEGATION_SPACE_INTEGRATION: (params: OrganizationParams) =>
        buildPath([
            OrganizationsPaths.ORGANIZATION_SETTINGS_DELEGATION_SPACE(params),
            slugs.SECTIONS.SETTINGS.DELEGATION_SPACE.INTEGRATION
        ]),
    ORGANIZATION_SETTINGS_DELEGATION_SPACE_EMAIL_TEMPLATES: (params: OrganizationParams) =>
        buildPath([
            OrganizationsPaths.ORGANIZATION_SETTINGS_DELEGATION_SPACE(params),
            slugs.SECTIONS.SETTINGS.DELEGATION_SPACE.EMAIL_TEMPLATES
        ]),
    ORGANIZATION_SETTINGS_DELEGATION_SPACE_SETTINGS: (params: OrganizationParams) =>
        buildPath([
            OrganizationsPaths.ORGANIZATION_SETTINGS_DELEGATION_SPACE(params),
            slugs.SECTIONS.SETTINGS.DELEGATION_SPACE.SETTINGS
        ]),

    // States

    IS_BLOCKED: (path: string) => buildPath([path, slugs.STATES.BLOCKED]),
    IS_EXPIRED: (path: string) => buildPath([path, slugs.STATES.EXPIRED])

    //
    // UDPATE
    //

    //
    // DELETE
    //
};
