import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I, IIcon } from 'common/src/designSystem/components/i';
import { styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

export const AlertTitled = styled('div', {
    alignItems: 'center',
    display: 'grid',
    gap: '6px 8px',
    gridTemplateColumns: 'min-content auto'
});

export const AlertTitle = ({ children, icon }: { children: React.ReactNode; icon: IIcon }) => (
    <>
        <I icon={icon} iconStyle="solid" />
        <Box color="gray800">{children}</Box>
    </>
);

export const AlertText = ({ children }: { children: React.ReactNode }) => (
    <Box color="gray800" css={{ gridColumn: '2 / 3' }} fontWeight="regular">
        {children}
    </Box>
);

const _Alert = styled(Flex, {
    alinItems: 'center',
    borderRadius: '$2',
    fontWeight: '$medium',
    gap: '$3',
    padding: '$3 $4',
    '& i': {
        fontSize: '$textMd'
    },
    variants: {
        color: {
            primary: {
                background: '$primary50',
                border: '1px solid $primary400',
                color: '$primary700',
                [`${AlertTitled}`]: {
                    color: '$primary500'
                }
            },
            gray: {
                background: '$gray50',
                border: '1px solid $gray400',
                color: '$gray700',
                [`${AlertTitled}`]: {
                    color: '$gray500'
                }
            },
            error: {
                background: '$error50',
                border: '1px solid $error400',
                color: '$error700',
                [`${AlertTitled}`]: {
                    color: '$error500'
                }
            },
            warning: {
                background: '$warning50',
                border: '1px solid $warning400',
                color: '$warning700',
                [`${AlertTitled}`]: {
                    color: '$warning500'
                }
            },
            success: {
                background: '$success50',
                border: '1px solid $success400',
                color: '$success700',
                [`${AlertTitled}`]: {
                    color: '$success500'
                }
            }
        }
    },
    defaultVariants: {
        color: 'primary'
    }
});

type IAlertColor = 'primary' | 'gray' | 'error' | 'warning' | 'success';

interface IAlertProps extends React.HTMLProps<HTMLDivElement> {
    color?: IAlertColor;
    leftIcon?: IIcon;
    rightIcon?: IIcon;
    width?: number | string;
    children: React.ReactNode;

    rightIconClick?(): void;
}

export const Alert = (props: IAlertProps) => (
    <_Alert
        aria-live={props['aria-live'] ?? 'polite'}
        color={props.color}
        role="alert"
        width={props.width}
    >
        {props.leftIcon && (
            <Flex align="center">
                <I icon={props.leftIcon} />
            </Flex>
        )}

        <Box css={{ flex: '1' }}>{props.children}</Box>

        {props.rightIcon && (
            <Flex
                align="center"
                css={{
                    cursor: props.rightIconClick ? 'pointer' : 'default'
                }}
                onClick={() => {
                    props.rightIconClick?.();
                }}
            >
                <I icon={props.rightIcon} />
            </Flex>
        )}
    </_Alert>
);
