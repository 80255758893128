import { CheckboxText } from 'common-front/src/designSystem/form/checkbox';
import { useUserContext } from 'common-front/src/userContext';
import { getLanguage } from 'common-front/src/util/language';
import { heaventEnvDisplayName, heaventEnvTermsUrl } from 'common/src/heaventEnv';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

export const TermsForm = () => {
    const translate = useTranslate();
    const { user } = useUserContext();

    return (
        <CheckboxText name="organization.termsAccepted">
            <span
                dangerouslySetInnerHTML={{
                    __html: translate(
                        'en_cr_ant_votre_88363',
                        heaventEnvTermsUrl(
                            getLanguage(user?.language, new URL(location.href).searchParams)
                        ),
                        heaventEnvDisplayName()
                    )
                }}
            />
        </CheckboxText>
    );
};
