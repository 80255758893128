import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import * as React from 'react';

export const UserHeaderSkeleton = () => (
    <Flex gap="4" width={1}>
        <Skeleton size={88} variant="circular" />

        <Flex css={{ flex: '1' }} direction="column" gap="3">
            <Skeleton borderRadius="$1" height={28} width={200} />

            <Skeleton borderRadius="$1" height={48} width={1} />
        </Flex>
    </Flex>
);
