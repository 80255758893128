import { BackButton } from 'common-front/src/components/buttons/backButton';
import { Page } from 'common-front/src/components/page/page';
import { Button } from 'common-front/src/designSystem/components/button';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { DelegationCategoryQuery } from 'common/src/generated/types';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { useDelegationCategoryDuplicateMutation } from '../../generated/graphqlHooks';
import { UpdateDelegationCategory } from '../create/createUpdateDelegationCategory';
import { DeleteDelegationCategory } from '../delete/deleteDelegationCategory';

interface IDelegationCategoryHeaderProps {
    category: DelegationCategoryQuery['organization']['delegationCategory'];

    reload(): void;
}

export const DelegationCategoryHeader = (props: IDelegationCategoryHeaderProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { mutate: delegationCategoryDuplicate } = useDelegationCategoryDuplicateMutation();
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
    const [isEditModalOpen, setIsEditOpen] = React.useState(false);

    return (
        <Page.Header align="center" gap="3" hideBorder={true}>
            <BackButton
                returnPathFallback={DelegationsPaths.DELEGATIONS({ organizationId, eventId })}
            />

            <Box css={{ flex: '1' }} font="gray900 displayXs medium">
                {props.category.name}
            </Box>

            <Button color="white" onClick={() => setIsEditOpen(true)}>
                {translate('_diter_62574')}
            </Button>

            <Button
                onClick={() => {
                    history.push(
                        eventId
                            ? HeaventPaths.CREATE_DELEGATION(
                                  organizationId,
                                  eventId,
                                  props.category.id
                              )
                            : HeaventPaths.COMMUNITY_CREATE_DELEGATION(
                                  organizationId,
                                  props.category.id
                              )
                    );
                }}
            >
                {translate('ajouter_une_d_l_79133')}
            </Button>

            <Dropdown>
                <Trigger>
                    <Button color="white" leftIcon="ellipsis-vertical" />
                </Trigger>

                <Menu placement="bottom-end">
                    <ItemIcon
                        icon="copy"
                        onClick={async () => {
                            const {
                                delegationCategoryDuplicate: { id: newDelegationCategoryId }
                            } = await delegationCategoryDuplicate({
                                organizationId,
                                eventId,
                                delegationCategoryId: props.category.id
                            });

                            history.push(
                                DelegationsPaths.DELEGATION_CATEGORY({
                                    organizationId,
                                    eventId,
                                    delegationCategoryId: newDelegationCategoryId
                                })
                            );
                        }}
                    >
                        {translate('dupliquer_28256')}
                    </ItemIcon>

                    <ItemIcon
                        color="red"
                        icon="trash-can"
                        onClick={() => {
                            setIsDeleteOpen(true);
                        }}
                    >
                        {translate('supprimer_43083')}
                    </ItemIcon>
                </Menu>
            </Dropdown>

            {isEditModalOpen && (
                <UpdateDelegationCategory
                    delegationCategory={props.category}
                    eventId={eventId}
                    organizationId={organizationId}
                    onClose={() => {
                        setIsEditOpen(false);
                    }}
                    onSuccess={props.reload}
                />
            )}

            {isDeleteOpen && (
                <DeleteDelegationCategory
                    delegationCategory={props.category}
                    eventId={eventId}
                    organizationId={organizationId}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                    onSuccess={() => {
                        history.replace(DelegationsPaths.DELEGATIONS({ organizationId, eventId }));
                    }}
                />
            )}
        </Page.Header>
    );
};
