import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { DelegationUserProfileFragment } from 'common/src/generated/types';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';

interface IUserInformationsDelegationsProps {
    delegations: DelegationUserProfileFragment[];
}

export const UserInformationsDelegations = (props: IUserInformationsDelegationsProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();

    return (
        <Flex
            css={{
                background: 'white',
                bd: true,
                padding: '$5 $6'
            }}
            direction="column"
            gap="3"
        >
            <Flex align="center" gap="2">
                <I icon="people-group" />

                <Box font="gray900 textMd medium">{translate('d_l_gations_78318')}</Box>
            </Flex>

            <Flex gap="2" wrap="wrap">
                {props.delegations.map((delegation) => {
                    const name = eventId
                        ? delegation.name
                        : delegation.event
                          ? `${delegation.event.name} : ${delegation.name}`
                          : delegation.name;

                    return (
                        <Badge
                            key={delegation.id}
                            color="white"
                            cursor="pointer"
                            ellipsis={true}
                            size="md"
                            onClick={() => {
                                history.push(
                                    delegation.event
                                        ? DelegationsPaths.DELEGATION({
                                              organizationId,
                                              eventId: delegation.event.id,
                                              delegationId: delegation.id
                                          })
                                        : HeaventPaths.COMMUNITY_DELEGATION(
                                              organizationId,
                                              delegation.id
                                          )
                                );
                            }}
                        >
                            {name}
                        </Badge>
                    );
                })}
            </Flex>
        </Flex>
    );
};
