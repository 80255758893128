import { Checkbox } from 'common-front/src/designSystem/components/checkbox';
import { PaginationCell } from 'common-front/src/designSystem/components/pagination/paginationCell';
import { PaginationRow } from 'common-front/src/designSystem/components/pagination/paginationRow';
import { executeVolunteerQuery, useVolunteersQuery } from 'common-front/src/generated/graphqlHooks';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { getToken } from 'common-front/src/util/aws/cognito';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { TableGrid } from 'common/src/designSystem/components/table/tableGrid';
import {
    SegmentCustomFieldFragment,
    UsersInfoId,
    VolunteerRegistrationFragment,
    VolunteersRegistrationId,
    VolunteersRegistrationsSortAttributes
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { isNonEmptyString } from 'common/src/util/string';
import {
    columnsToIncludes,
    VolunteerDefaultColumns
} from 'common/src/vo/segments/volunteersSegmentsService';
import { compact } from 'lodash-es';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import { VolunteerRow } from './volunteerRow';

interface IVolunteersListProps {
    customFields: SegmentCustomFieldFragment[];
    weezevent: Emptyable<{
        eventId: number;
        organizationId: number;
    }>;
}

export const VolunteersList = React.memo(function VolunteersList(props: IVolunteersListProps) {
    const { organizationId, eventId, segmentId } = useParams();
    const { isEventAdmin } = useEventContext();
    const {
        columns,
        columnsSearch,
        limit,
        massActions,
        nameDebounced,
        offset,
        possibleColumns,
        predicates,
        reloadKey,
        setLimit,
        setOffset,
        setSort,
        sort
    } = useSegmentsContext<UsersInfoId, VolunteersRegistrationsSortAttributes>();
    const includes = React.useMemo(
        () => ({
            ...columnsToIncludes(columns),
            includeCanRegistrationStateUpdate: true,
            includeIsEditable: true
        }),
        [columns]
    );
    const { data, isLoading, reload } = useVolunteersQuery({
        ...includes,
        eventId,
        predicates,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : null,
        columnsSearch: isNonEmptyArray(columnsSearch) ? columnsSearch : null,
        limit,
        offset,
        sort
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.data?.rows);
    React.useEffect(() => {
        if (reloadKey > 1) {
            reload();
        }
    }, [reloadKey]);
    const selectedFields = React.useMemo(
        () => columns.flatMap((slug) => compact([possibleColumns.find((pc) => pc.slug === slug)])),
        [possibleColumns, columns]
    );
    const [volunteersRegistrations, setVolunteersRegistrations] = React.useState<
        VolunteerRegistrationFragment[]
    >([]);
    const [idToLoading, setIdToLoading] = React.useState<Record<VolunteersRegistrationId, boolean>>(
        {}
    );
    const reloadVolunteer = React.useCallback(
        async (id: VolunteersRegistrationId) => {
            setIdToLoading((ids) => ({ ...ids, [id]: true }));

            const {
                data: { row }
            } = await executeVolunteerQuery(
                {
                    ...includes,
                    eventId,
                    id
                },
                await getToken()
            );

            setVolunteersRegistrations((vrs) => vrs.map((vr) => (vr.id === id ? row : vr)));
            setIdToLoading((ids) => ({ ...ids, [id]: false }));
        },
        [includes, setIdToLoading, setVolunteersRegistrations]
    );

    React.useEffect(() => {
        setVolunteersRegistrations(data.data?.rows.nodes ?? []);
    }, [data.data]);

    React.useEffect(() => {
        massActions.setStates(
            Object.fromEntries(
                (data.data?.rows.nodes ?? []).map(({ userInfo }) => [
                    `ui${userInfo.id}`,
                    {
                        id: userInfo.id,
                        state: 'unchecked'
                    }
                ])
            )
        );
    }, [data.data, massActions.setStates]);

    return (
        <Flex css={{ flex: '1', overflow: 'hidden' }} width={1}>
            <TableGrid
                headerRow={
                    <HeaderRow>
                        {isEventAdmin && (
                            <HeaderCell justify="center" width={48}>
                                <Checkbox
                                    state={massActions.selectAllState}
                                    onClick={massActions.toggleSelectAll}
                                />
                            </HeaderCell>
                        )}

                        {selectedFields.map((field) =>
                            field.slug === VolunteerDefaultColumns.AccreditationState ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={
                                        VolunteersRegistrationsSortAttributes.AccreditationState
                                    }
                                    setSort={setSort}
                                    sort={sort}
                                    width={240}
                                >
                                    {field.name}
                                </HeaderCellSort>
                            ) : field.slug === 'firstName' ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={VolunteersRegistrationsSortAttributes.FirstName}
                                    setSort={setSort}
                                    sort={sort}
                                >
                                    {field.name}
                                </HeaderCellSort>
                            ) : field.slug === 'lastName' ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={VolunteersRegistrationsSortAttributes.LastName}
                                    setSort={setSort}
                                    sort={sort}
                                >
                                    {field.name}
                                </HeaderCellSort>
                            ) : field.slug === VolunteerDefaultColumns.Id ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={VolunteersRegistrationsSortAttributes.Id}
                                    setSort={setSort}
                                    sort={sort}
                                    width={100}
                                >
                                    {field.name}
                                </HeaderCellSort>
                            ) : field.slug === VolunteerDefaultColumns.InsertedAt ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={VolunteersRegistrationsSortAttributes.InsertedAt}
                                    setSort={setSort}
                                    sort={sort}
                                    width={230}
                                >
                                    {field.name}
                                </HeaderCellSort>
                            ) : field.slug.startsWith('form_inserted_at') ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={field.slug}
                                    setSort={setSort}
                                    sort={sort}
                                    width={230}
                                >
                                    {field.name}
                                </HeaderCellSort>
                            ) : field.slug === VolunteerDefaultColumns.Name ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={VolunteersRegistrationsSortAttributes.Name}
                                    setSort={setSort}
                                    sort={sort}
                                >
                                    {field.name}
                                </HeaderCellSort>
                            ) : field.slug === VolunteerDefaultColumns.NumberOfAccreditations ? (
                                <HeaderCell key={field.slug} width={230}>
                                    {field.name}
                                </HeaderCell>
                            ) : field.slug === VolunteerDefaultColumns.NumberOfAssignments ? (
                                <HeaderCell key={field.slug} width={230}>
                                    {field.name}
                                </HeaderCell>
                            ) : field.slug === VolunteerDefaultColumns.NumberOfAssignmentsDays ? (
                                <HeaderCell key={field.slug} width={230}>
                                    {field.name}
                                </HeaderCell>
                            ) : field.slug === VolunteerDefaultColumns.NumberOfAvailableDays ? (
                                <HeaderCell key={field.slug} width={230}>
                                    {field.name}
                                </HeaderCell>
                            ) : field.slug === 'picture' ? (
                                <HeaderCell key={field.slug} width={80}>
                                    {field.name}
                                </HeaderCell>
                            ) : field.slug == VolunteerDefaultColumns.TeamCode ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={VolunteersRegistrationsSortAttributes.TeamCode}
                                    setSort={setSort}
                                    sort={sort}
                                >
                                    {field.name}
                                </HeaderCellSort>
                            ) : field.slug === VolunteerDefaultColumns.VolunteerMinutes ? (
                                <HeaderCell key={field.slug} width={230}>
                                    {field.name}
                                </HeaderCell>
                            ) : field.slug ===
                              VolunteerDefaultColumns.VolunteerRegistrationState ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={VolunteersRegistrationsSortAttributes.State}
                                    setSort={setSort}
                                    sort={sort}
                                    width={230}
                                >
                                    {field.name}
                                </HeaderCellSort>
                            ) : field.isSortable ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={field.slug}
                                    setSort={setSort}
                                    sort={sort}
                                >
                                    <Box css={{ ellipsis: true }} title={field.name}>
                                        {field.name}
                                    </Box>
                                </HeaderCellSort>
                            ) : (
                                <HeaderCell key={field.slug}>
                                    <Box css={{ ellipsis: true }} title={field.name}>
                                        {field.name}
                                    </Box>
                                </HeaderCell>
                            )
                        )}

                        <HeaderCell width={100} />
                    </HeaderRow>
                }
                paginationRow={
                    <PaginationRow css={{ borderTop: '1px solid $gray200' }}>
                        <PaginationCell
                            limit={limit}
                            numberOfPages={numberOfPages}
                            offset={offset}
                            setLimit={setLimit}
                            setOffset={setOffset}
                            showLimits={true}
                            totalCount={totalCount}
                        />
                    </PaginationRow>
                }
            >
                {isLoading ? (
                    <>
                        <RowSkeleton />
                        <RowSkeleton />
                        <RowSkeleton />
                    </>
                ) : (
                    volunteersRegistrations.map((volunteerRegistration) => {
                        if (idToLoading[volunteerRegistration.id]) {
                            return <RowSkeleton key={volunteerRegistration.id} />;
                        } else {
                            return (
                                <VolunteerRow
                                    key={volunteerRegistration.id}
                                    columns={selectedFields}
                                    customFields={props.customFields}
                                    eventId={eventId}
                                    organizationId={organizationId}
                                    reload={reload}
                                    reloadVolunteer={reloadVolunteer}
                                    segmentId={segmentId}
                                    state={
                                        massActions.states[`ui${volunteerRegistration.userInfo.id}`]
                                            ?.state ?? 'unchecked'
                                    }
                                    toggleRow={massActions.toggleRow}
                                    volunteerRegistration={volunteerRegistration}
                                    weezevent={props.weezevent}
                                />
                            );
                        }
                    })
                )}
            </TableGrid>
        </Flex>
    );
});
