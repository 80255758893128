import * as React from 'react';
import { Field } from 'react-final-form';
import { DateInput as DateInputComponent } from '../../components/date/dateInput';
import { IDatetimeInputProps } from '../../components/date/datetimeInput';
import { IFormInputProps } from '../../components/input/commonInputProps';

export const DateInput = React.memo(function DateInput({
    name,
    hint,
    state,
    ...rest
}: IFormInputProps<IDatetimeInputProps>) {
    return (
        <Field
            name={name}
            render={({ input, meta }) => {
                const invalid =
                    (meta.data?.forceDisplayError && meta.invalid) ||
                    (meta.touched && meta.invalid);

                const handleChange = React.useCallback(input.onChange, [input.onChange]);

                return (
                    <DateInputComponent
                        hint={invalid ? meta.error : hint}
                        name={name}
                        state={invalid ? 'error' : state}
                        value={input.value}
                        onBlur={input.onBlur}
                        onChange={handleChange}
                        onFocus={input.onFocus}
                        {...rest}
                    />
                );
            }}
        />
    );
});
