import { Blank } from 'common-front/src/components/blank/blank';
import { Button } from 'common-front/src/designSystem/components/button';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Svgs } from 'common-front/src/util/assets';
import { getWishedPositions } from 'common-front/src/vo/formUserInfo';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { Row } from 'common/src/designSystem/components/table/row';
import { FormPositionRanking, UserPositionsQuery } from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { EventFormEditPath } from 'common/src/util/paths/membersPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { noop } from 'lodash-es';
import * as React from 'react';
import { useMobileQuery } from '../../hooks/useMobileQuery';
import { NoLongerEditableAlert } from '../../v2/members/registrations/noLongerEditableAlert';
import { UserFormBadge } from './userFormBadge';

interface IUserPositionsWishedProps {
    editPath?: string;
    formsUsersInfos: UserPositionsQuery['organization']['userInfo']['formsUsersInfos'];
    isEditDisabled?: boolean;

    editFormPath?: EventFormEditPath;
}

export const UserPositionsWished = ({
    editFormPath,
    editPath,
    formsUsersInfos,
    isEditDisabled = false
}: IUserPositionsWishedProps) => {
    const {
        history,
        params: { organizationId, eventId, userInfoId },
        translate
    } = useHeavent();
    const intervalService = useService(IntervalService);
    const wishedPositions = React.useMemo(
        () => getWishedPositions(formsUsersInfos, intervalService, organizationId, eventId),
        [formsUsersInfos]
    );
    const isMobile = useMobileQuery();

    return (
        <Flex direction="column" gap="6" width={1}>
            {isEditDisabled && (
                <NoLongerEditableAlert
                    message={translate('you_are_already_assigned_to_missions')}
                    title={translate('wished_positions_can_no_longer_be_changed')}
                />
            )}

            <TableFilters
                filters={
                    isNonEmptyString(editPath) && (
                        <Button color="white" disabled={isEditDisabled} size="sm" to={editPath}>
                            {translate('_diter_62574')}
                        </Button>
                    )
                }
                headerCells={
                    <>
                        <HeaderCell>{translate('nom_de_la_missi_64605')}</HeaderCell>
                        {!isMobile && <HeaderCell>{translate('Date')}</HeaderCell>}
                        {!isMobile && <HeaderCell>{translate('Form')}</HeaderCell>}
                    </>
                }
                numberOfPages={0}
                offset={0}
                rows={
                    <>
                        {isNonEmptyArray(wishedPositions) ? (
                            wishedPositions.map((wishedPosition, index) => (
                                <Row key={index}>
                                    <Cell
                                        align="start"
                                        css={{
                                            color: '$gray800',
                                            fontWeight: '$medium',
                                            fontSize: '$textSm'
                                        }}
                                        direction="column"
                                        gap="1"
                                        justify="center"
                                    >
                                        <Box
                                            color="gray900"
                                            css={{ cursor: 'pointer' }}
                                            fontWeight="medium"
                                            onClick={() => {
                                                history.push(wishedPosition.path);
                                            }}
                                        >
                                            {wishedPosition.name}
                                        </Box>

                                        {wishedPosition.form.positionRanking ===
                                            FormPositionRanking.Ranked && (
                                            <Box color="gray500">
                                                {translate('choix_n_1_00613', wishedPosition.rank)}
                                            </Box>
                                        )}

                                        {isMobile && (
                                            <Box
                                                color={'gray500'}
                                                css={{
                                                    fontWeight: 400,
                                                    lineHeight: '20px'
                                                }}
                                                fontSize="textSm"
                                            >
                                                {wishedPosition.range}
                                            </Box>
                                        )}
                                    </Cell>
                                    {!isMobile && <Cell>{wishedPosition.range}</Cell>}
                                    {!isMobile && (
                                        <Cell>
                                            <UserFormBadge
                                                editDisabled={isEditDisabled}
                                                editPath={editFormPath}
                                                eventId={eventId}
                                                form={wishedPosition.form}
                                                organizationId={organizationId}
                                                userInfoId={userInfoId}
                                            />
                                        </Cell>
                                    )}
                                </Row>
                            ))
                        ) : (
                            <Row css={{ height: '200px' }}>
                                <Cell>
                                    <Blank
                                        imageSrc={Svgs.FormsElementsBlank}
                                        title={translate('aucune_mission_61179')}
                                    />
                                </Cell>
                            </Row>
                        )}
                    </>
                }
                setOffset={noop}
                title={translate('missions_souhai_23225')}
                totalCount={0}
            />
        </Flex>
    );
};
