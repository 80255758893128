import { Authenticate } from 'common-front/src/authenticate';
import { Empty } from 'common-front/src/components/empty/empty';
import { MagicLink } from 'common-front/src/components/magicLink/magicLink';
import { Box } from 'common/src/designSystem/components/box';
import { overriddenTheme } from 'common/src/designSystem/components/stitches';
import { CommonEnvVars } from 'common/src/envVars';
import { HeaventEnv } from 'common/src/heaventEnv';
import { CommonPaths } from 'common/src/util/paths/commonPaths';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { OrganizationsPaths } from 'common/src/util/paths/organizationsPaths';
import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AccreditationMap } from './accreditations/map/accreditationMap';
import { Auth } from './auth/auth';
import { CognitoCallback } from './auth/cognitoCallback';
import { Backoffice } from './backoffice/backoffice';
import { CreateEventOrganization } from './events/create/createEventOrganization';
import { Home } from './home/home';
import { About } from './mso/about';
import { CreateOrganization } from './organization/create/createOrganization';
import { Organization } from './organization/organization';
import { ExportPositionsMap } from './positions/list/map/export/exportPositionsMap';
import { PositionMap } from './positions/map/positionMap';
import { UnsubscribeEmail } from './unsubscribeEmail';
import { UserFieldUpdate } from './userFieldUpdate';
import { UserFieldUpdateByTicketId } from './userFieldUpdateByTicketId';

const Authenticated = (props: { children: React.ReactNode }) => (
    <Authenticate getRedirectPath={() => HeaventPaths.AUTH_SIGN_IN} shouldHaveUser={true}>
        {props.children}
    </Authenticate>
);

const Unauthenticated = (props: { children: React.ReactNode }) => (
    <Authenticate getRedirectPath={() => HeaventPaths.HOME} shouldHaveUser={false}>
        {props.children}
    </Authenticate>
);

export const App = () => (
    <Box
        className={overriddenTheme}
        css={{
            color: '$gray800',
            height: '100%',
            overflow: 'hidden',
            position: 'relative',
            width: '100%'
        }}
    >
        <BrowserRouter basename={CommonEnvVars.HEAVENT_APP_URL.pathnameOptional}>
            <Switch>
                <Route exact path={HeaventPaths.HOME}>
                    <Authenticated>
                        <Home />
                    </Authenticated>
                </Route>

                <Route
                    path={HeaventPaths.USER_UPDATE_BY_TICKET_ID(
                        ':organizationId',
                        ':customFieldSlug'
                    )}
                >
                    <UserFieldUpdateByTicketId />
                </Route>

                <Route
                    path={HeaventPaths.USER_FIELD_UPDATE(
                        ':organizationId',
                        ':userInfoId',
                        ':customFieldSlug',
                        ':value'
                    )}
                >
                    <UserFieldUpdate />
                </Route>

                <Route path={HeaventPaths.BACKOFFICE}>
                    <Authenticated>
                        <Backoffice />
                    </Authenticated>
                </Route>

                <Route path={HeaventPaths.POSITION_MAP(':eventId', ':positionId')}>
                    <PositionMap />
                </Route>

                <Route path={HeaventPaths.ACCREDITATION_MAP(':eventId', ':accreditationId')}>
                    <AccreditationMap />
                </Route>

                <Route path={HeaventPaths.EXPORT_POSITIONS_MAP(':eventId', ':segmentId')}>
                    <ExportPositionsMap />
                </Route>

                <Route path={CommonPaths.CREATE(OrganizationsPaths.ORGANIZATIONS)}>
                    <Authenticated>
                        <CreateOrganization />
                    </Authenticated>
                </Route>

                <Route exact path={HeaventPaths.CREATE_EVENT_WITH_ORGANIZATION}>
                    <Authenticated>
                        <CreateEventOrganization />
                    </Authenticated>
                </Route>

                <Route
                    path={OrganizationsPaths.ORGANIZATION({ organizationId: ':organizationId' })}
                >
                    <Authenticated>
                        <Organization />
                    </Authenticated>
                </Route>

                <Route path={HeaventPaths.AUTH}>
                    <Unauthenticated>
                        <Auth />
                    </Unauthenticated>
                </Route>

                <Route path={HeaventPaths.COGNITO_CALLBACK}>
                    <Unauthenticated>
                        <CognitoCallback />
                    </Unauthenticated>
                </Route>

                <Route path={HeaventPaths.MAGIC_LINK(':code', ':source')}>
                    <Unauthenticated>
                        <MagicLink />
                    </Unauthenticated>
                </Route>

                <Route path={HeaventPaths.UNSUBSCRIBE_EMAIL}>
                    <UnsubscribeEmail />
                </Route>

                {/* MSO specific routes */}

                {CommonEnvVars.HEAVENT_ENV === HeaventEnv.Mso && (
                    <Route path="/about">
                        <About />
                    </Route>
                )}

                {/* Default redirect */}
                <Route>
                    <Empty path={HeaventPaths.HOME} />
                </Route>
            </Switch>
        </BrowserRouter>
    </Box>
);
