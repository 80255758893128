import { Form } from 'common-front/src/components/form/form';
import { Button } from 'common-front/src/designSystem/components/button';
import { HorizontalSpacerSeparator } from 'common-front/src/designSystem/components/separator';
import { DatetimeInput } from 'common-front/src/designSystem/form/date/datetimeInput';
import { RadioText } from 'common-front/src/designSystem/form/radio';
import { RichEditor } from 'common-front/src/designSystem/form/richEditor';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { FormState } from 'common/src/generated/types';
import {
    FormParametersStateInputService,
    IUpdateFormParametersStateValues
} from 'common/src/input/formParametersStateInput';
import { ValidateService } from 'common/src/services/validateService';
import { useService } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { OnChange } from 'react-final-form-listeners';
import {
    useFormParametersStateQuery,
    useFormParametersStateUpdateMutation
} from '../../../../generated/graphqlHooks';
import { FormContainer } from '../../../show/formContainer';
import { FormParametersContainer } from '../formParametersContainer';
import { useFormParametersClose } from '../useFormParametersClose';

interface IFormParametersStateInnerProps {
    initialValues: IUpdateFormParametersStateValues;
    isMutateLoading: boolean;
    loader: React.JSX.Element | null;
    name: string;
    values: IUpdateFormParametersStateValues;

    change(name: string, value: any): void;
    handleSubmit(): void;
}

const FormParametersStateInner = (props: IFormParametersStateInnerProps) => {
    const {
        translate,
        params: { eventId }
    } = useHeavent();
    const { isNotSavedModalOpen, onClose, onNotSavedClose, onSave } = useFormParametersClose(
        props.initialValues,
        props.values,
        props.handleSubmit
    );

    return (
        <FormContainer
            button={
                <Button isLoading={props.isMutateLoading} onClick={onSave}>
                    {translate('enregistrer_06519')}
                </Button>
            }
            title={props.name}
            onClose={onClose}
        >
            <FormParametersContainer
                isNotSavedOpen={isNotSavedModalOpen}
                onNotSavedClose={onNotSavedClose}
            >
                {props.loader || (
                    <>
                        <OnChange name="form.state">
                            {(newState: FormState) => {
                                if (newState !== FormState.ClosedAt) {
                                    props.change('form.closedAt', null);
                                }
                            }}
                        </OnChange>

                        <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                            {translate('cl_turer_le_for_46483')}
                        </Box>

                        <Spacer height="1" />

                        <Box color="gray500">{translate('choisir_si_le_f_14519')}</Box>

                        <Spacer height="4" />

                        <RadioText name="form.state" value={FormState.Open}>
                            {translate('ne_pas_cl_turer_46870')}
                        </RadioText>

                        <Spacer height="3" />

                        {eventId && (
                            <>
                                <RadioText name="form.state" value={FormState.CloseWhenEventDone}>
                                    {translate('cl_turer_le_for_61719')}
                                </RadioText>

                                <Spacer height="3" />
                            </>
                        )}

                        <RadioText name="form.state" value={FormState.Closed}>
                            {translate('cl_turer_le_for_10120')}
                        </RadioText>

                        <Spacer height="3" />

                        <RadioText name="form.state" value={FormState.ClosedAt}>
                            {translate('cl_turer_le_for_19176')}
                        </RadioText>

                        {props.values.form.state === FormState.ClosedAt && (
                            <>
                                <Spacer height="2" />

                                <Flex>
                                    <Spacer width="6" />

                                    <DatetimeInput name="form.closedAt" />
                                </Flex>
                            </>
                        )}

                        {props.values.form.state !== FormState.Open && (
                            <>
                                <HorizontalSpacerSeparator height="6" />

                                <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                                    {translate('personnalisatio_15893')}
                                </Box>

                                <Spacer height="4" />

                                <TextInput
                                    label={translate('nom_de_l_tape_85862')}
                                    name="form.closedTitle"
                                />

                                <Spacer height="6" />

                                <RichEditor
                                    hint={translate('ce_message_sera_03472')}
                                    label={translate('message_de_cl_t_95276')}
                                    name="form.closedMessage"
                                />
                            </>
                        )}
                    </>
                )}
            </FormParametersContainer>
        </FormContainer>
    );
};

export const FormParametersState = () => {
    const {
        params: { organizationId, eventId, formId }
    } = useHeavent();
    const { data, loader } = useFormParametersStateQuery({ organizationId, formId });
    useTitle(data.organization?.form.name ?? '');
    const validateService = useService(ValidateService);
    const formParametersStateInput = useService(FormParametersStateInputService);
    const { mutate, isLoading } = useFormParametersStateUpdateMutation();
    const initialValues = React.useMemo(
        () => ({
            form: formParametersStateInput.formParametersStateInputDefault(data.organization?.form)
        }),
        [data.organization]
    );

    return (
        <Form
            direction="column"
            height={1}
            initialValues={initialValues}
            render={({ form, handleSubmit, values }) => (
                <FormParametersStateInner
                    change={form.change}
                    handleSubmit={handleSubmit}
                    initialValues={initialValues}
                    isMutateLoading={isLoading}
                    loader={loader}
                    name={data.organization?.form.name ?? ''}
                    values={values}
                />
            )}
            validate={validateService.validateForForm(
                formParametersStateInput.updateFormParametersStateSchema()
            )}
            width={1}
            onSubmit={(values: IUpdateFormParametersStateValues) =>
                mutate({
                    organizationId,
                    eventId,
                    formId,
                    form: values.form
                })
            }
        />
    );
};
