import { injectable } from 'inversify';
import { compact, intersection } from 'lodash-es';
import { DateTime } from 'luxon';
import { CustomFieldWithConditionFragment, FieldType, Phone } from '../generated/types';
import { CountriesService } from '../services/countriesService';
import { DateTimeService } from '../services/dateTimeService';
import { TranslationService } from '../services/translationService';
import { isNonEmptyArray, toArray } from '../util/array';
import { assertUnreachable } from '../util/assertUnreachable';
import { LocaleFormats } from '../util/luxon';
import { isNonEmptyString } from '../util/string';

type GetValueStringField = Pick<CustomFieldWithConditionFragment, 'fieldType' | 'slug' | 'values'>;

export type Fields = { [slug: string]: any };

export type UserInfo = { fields: Fields };

interface IGetValueStringOptions {
    fileReturnValue?: 'url' | 'name';
    dateFormat?: string;
    datetimeFormat?: string;
}

@injectable()
export class FieldService {
    constructor(
        private countriesService: CountriesService,
        private dateTimeService: DateTimeService,
        private translationService: TranslationService
    ) {}

    getValueString(
        field: GetValueStringField,
        fields: Fields,
        options: IGetValueStringOptions = {}
    ): string {
        switch (field.fieldType) {
            case FieldType.Text:
            case FieldType.Textarea:
            case FieldType.Time:
            case FieldType.Address:
                return fields[field.slug] || '';
            case FieldType.Sex:
                return this.getSexValue(field, fields);
            case FieldType.Language:
                return this.getLanguageValue(field, fields);
            case FieldType.Nationality:
                return this.getNationalityValue(field, fields);
            case FieldType.Country:
                return this.getCountryValue(field, fields);
            case FieldType.Select:
                return this.getSelectValue(field, fields);
            case FieldType.Checkbox:
            case FieldType.Validation:
                return this.getBooleanValue(field, fields);
            case FieldType.Date:
                return this.getDateValue(field, fields, options.dateFormat);
            case FieldType.Datetime:
                return this.getDatetimeValue(field, fields, options.datetimeFormat);
            case FieldType.File:
                if (options.fileReturnValue === 'name') {
                    return fields[field.slug]?.name || '';
                } else {
                    return fields[field.slug]?.url || '';
                }
            case FieldType.Phone:
                return this.getPhoneValue(field, fields);
            case FieldType.Number:
                return this.getIntValue(field, fields);
            default:
                return assertUnreachable(field.fieldType);
        }
    }

    getValue<T>(field: GetValueStringField, fields: Fields): T | undefined {
        return fields[field.slug];
    }

    private getSexValue(field: GetValueStringField, fields: Fields): string {
        return this.translationService.translate(this.getValue(field, fields) ?? '');
    }

    private getLanguageValue(field: GetValueStringField, fields: Fields): string {
        return this.translationService.translate(this.getValue(field, fields) ?? '');
    }

    private getNationalityValue(field: GetValueStringField, fields: Fields): string {
        return this.countriesService.getNationality(this.getValue(field, fields) ?? '') ?? '';
    }

    private getCountryValue(field: GetValueStringField, fields: Fields): string {
        return this.countriesService.getName(this.getValue(field, fields) ?? '') ?? '';
    }

    private getBooleanValue(field: GetValueStringField, fields: Fields): string {
        const value = this.getValue<boolean>(field, fields);

        if (value === true) {
            return this.translationService.translate('oui_54361');
        } else if (value === false) {
            return this.translationService.translate('non_33516');
        } else {
            return '';
        }
    }

    private getDateValue(field: GetValueStringField, fields: Fields, dateFormat?: string): string {
        let dateValue = this.getValue<DateTime | string>(field, fields);

        if (isNonEmptyString(dateValue)) {
            dateValue = DateTime.fromISO(dateValue, { zone: 'utc' });
        }

        return DateTime.isDateTime(dateValue) && dateValue.isValid
            ? isNonEmptyString(dateFormat)
                ? dateValue.toFormat(dateFormat)
                : this.dateTimeService.toLocaleString(dateValue, LocaleFormats.DateOnly.MonthLong)
            : '';
    }

    private getDatetimeValue(
        field: GetValueStringField,
        fields: Fields,
        datetimeFormat?: string
    ): string {
        let datetimeValue = this.getValue<DateTime | string>(field, fields);

        if (isNonEmptyString(datetimeValue)) {
            datetimeValue = DateTime.fromISO(datetimeValue, { zone: 'utc' });
        }

        return DateTime.isDateTime(datetimeValue) && datetimeValue.isValid
            ? isNonEmptyString(datetimeFormat)
                ? datetimeValue.toFormat(datetimeFormat)
                : this.dateTimeService.toLocaleString(datetimeValue, LocaleFormats.DateTime)
            : '';
    }

    private getPhoneValue(field: GetValueStringField, fields: Fields): string {
        return this.getValue<Phone>(field, fields)?.internationalFormat ?? '';
    }

    private getIntValue(field: GetValueStringField, fields: Fields): string {
        return this.getValue<number>(field, fields)?.toString() ?? '';
    }

    private getSelectValue(field: GetValueStringField, fields: Fields): string {
        const valuesIds = toArray(this.getValue<number | number[]>(field, fields) ?? []);

        return compact(
            valuesIds.map((valueId) => field.values.find((v) => v.id === valueId)?.value)
        ).join(', ');
    }
}

export const CONDITION_FIELD_TYPES = [
    FieldType.Country,
    FieldType.Language,
    FieldType.Nationality,
    FieldType.Select
];

export function shouldDisplay(
    field: CustomFieldWithConditionFragment,
    values: Fields,
    fields: CustomFieldWithConditionFragment[]
): boolean {
    if (field.hasCondition && field.conditionCustomField) {
        const parentField = fields.find((f) => f.id === field.conditionCustomField!.id);

        if (parentField) {
            const shouldDisplayParent = shouldDisplay(parentField, values, fields);

            if (shouldDisplayParent) {
                const conditionFieldValue = values[field.conditionCustomField.slug];

                if (
                    typeof conditionFieldValue === 'boolean' &&
                    field.conditionCustomField.fieldType === FieldType.Checkbox
                ) {
                    return conditionFieldValue === field.conditionValue;
                } else if (
                    CONDITION_FIELD_TYPES.includes(field.conditionCustomField.fieldType) &&
                    isNonEmptyArray(field.conditionValue)
                ) {
                    return (
                        intersection(toArray(conditionFieldValue), field.conditionValue).length > 0
                    );
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    } else {
        return true;
    }
}
