import { injectable } from 'inversify';
import { intersection, pick } from 'lodash-es';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import {
    ALL_LANGUAGE,
    ALL_SEX,
    CustomFieldWithConditionFragment,
    FieldProperty,
    FieldType
} from '../generated/types';
import { CountriesService, IDefaultCountryValues } from '../services/countriesService';
import { TranslationService } from '../services/translationService';
import { isNonEmptyArray, toArray } from '../util/array';
import { assertUnreachable } from '../util/assertUnreachable';
import { isValidEid } from '../util/eid';
import { DateTimeSchemaType } from '../util/yup/dateTimeSchemaType';
import { CONDITION_FIELD_TYPES, Fields } from '../vo/field';
import { USABLE_LANGUAGES } from '../vo/supportedLanguage';
import { DOCUMENT_INPUT_KEYS, DocumentInputService } from './documentInput';
import { InputService } from './inputService';
import { PHONE_INPUT_KEYS, PhoneInputService } from './phoneInput';

export type EventFieldForSchema = {
    field: CustomFieldWithConditionFragment;
    isMandatory: boolean;
};

const VALID_LANGUAGES = (USABLE_LANGUAGES as string[]).concat(ALL_LANGUAGE as string[]);

@injectable()
export class FieldInputService extends InputService {
    constructor(
        private countriesService: CountriesService,
        private documentInputService: DocumentInputService,
        private phoneInputService: PhoneInputService,
        protected translationService: TranslationService
    ) {
        super(translationService);
    }

    fieldsUpdateInputDefault(
        userInfoFields: Fields,
        customFields: CustomFieldWithConditionFragment[],
        countryValues: IDefaultCountryValues,
        checkboxDefaultEmpty?: boolean
    ) {
        return Object.fromEntries(
            customFields.map((customField) => {
                const value = this.getFieldValue(userInfoFields, customField);

                return [
                    customField.slug,
                    value ??
                        this.fieldUpdateInputDefault(
                            customField,
                            countryValues,
                            checkboxDefaultEmpty
                        )
                ];
            })
        );
    }

    fieldSchema(
        customField: CustomFieldWithConditionFragment,
        isMandatory: boolean
    ): yup.Schema<any> {
        const getSchema = (
            requiredSchema: () => yup.Schema<any>,
            notRequiredSchema: () => yup.Schema<any>
        ) => {
            if (customField.conditionCustomField) {
                return yup.object().when(customField.conditionCustomField.slug, ([value]) => {
                    if (isMandatory) {
                        if (
                            customField.conditionCustomField!.fieldType === FieldType.Checkbox &&
                            value === customField.conditionValue
                        ) {
                            return requiredSchema();
                        } else if (
                            CONDITION_FIELD_TYPES.includes(
                                customField.conditionCustomField!.fieldType
                            ) &&
                            isNonEmptyArray(customField.conditionValue)
                        ) {
                            if (
                                intersection(toArray(value), customField.conditionValue).length > 0
                            ) {
                                return requiredSchema();
                            } else {
                                return notRequiredSchema();
                            }
                        } else {
                            return notRequiredSchema();
                        }
                    } else {
                        return notRequiredSchema();
                    }
                });
            } else {
                let schema: yup.Schema<any>;

                if (isMandatory) {
                    schema = requiredSchema();
                } else {
                    schema = notRequiredSchema();
                }

                return schema;
            }
        };

        switch (customField.fieldType) {
            case FieldType.File:
                return getSchema(
                    () => this.documentInputService.documentInputSchema(customField.name),
                    () => this.documentInputService.documentInputSchemaNotRequired()
                );
            case FieldType.Phone:
                return getSchema(
                    () => this.phoneInputService.phoneInputSchema(),
                    () => this.phoneInputService.phoneInputSchemaNotRequired()
                );
            case FieldType.Text:
            case FieldType.Textarea:
            case FieldType.Time:
            case FieldType.Address:
                if (customField.fieldProperty === FieldProperty.Eid) {
                    return getSchema(
                        () =>
                            yup
                                .string()
                                .required(
                                    this.translationService.translate(
                                        '_1_est_requis_99856',
                                        customField.name
                                    )
                                )
                                .typeError(
                                    this.translationService.translate(
                                        '_1_est_requis_99856',
                                        customField.name
                                    )
                                )
                                .test('valid-eid', this.t('le_num_ro_de_re_71488'), isValidEid),
                        () => yup.string()
                    );
                } else {
                    return getSchema(
                        () =>
                            yup
                                .string()
                                .required(
                                    this.translationService.translate(
                                        '_1_est_requis_99856',
                                        customField.name
                                    )
                                )
                                .typeError(
                                    this.translationService.translate(
                                        '_1_est_requis_99856',
                                        customField.name
                                    )
                                ),
                        () => yup.string()
                    );
                }
            case FieldType.Number:
                return getSchema(
                    () =>
                        yup
                            .number()
                            .required(
                                this.translationService.translate(
                                    '_1_est_requis_99856',
                                    customField.name
                                )
                            )
                            .typeError(
                                this.translationService.translate(
                                    '_1_est_requis_99856',
                                    customField.name
                                )
                            ),
                    () => yup.number().nullable()
                );
            case FieldType.Checkbox:
                return getSchema(
                    () =>
                        yup
                            .boolean()
                            .required(
                                this.translationService.translate(
                                    '_1_est_requis_99856',
                                    customField.name
                                )
                            )
                            .typeError(
                                this.translationService.translate(
                                    '_1_est_requis_99856',
                                    customField.name
                                )
                            ),
                    () => yup.boolean()
                );
            case FieldType.Validation:
                return getSchema(
                    () =>
                        yup
                            .boolean()
                            .required(
                                this.translationService.translate(
                                    '_1_est_requis_99856',
                                    customField.name
                                )
                            )
                            .typeError(
                                this.translationService.translate(
                                    '_1_est_requis_99856',
                                    customField.name
                                )
                            )
                            .oneOf(
                                [true],
                                this.translationService.translate(
                                    '_1_doit_obliga_82862',
                                    customField.name
                                )
                            ),
                    () => yup.boolean()
                );
            case FieldType.Select:
                if (customField.canSelectMultiple) {
                    return getSchema(
                        () =>
                            yup
                                .array()
                                .of(yup.number().required())
                                .required(
                                    this.translationService.translate(
                                        '_1_est_requis_99856',
                                        customField.name
                                    )
                                )
                                .min(
                                    1,
                                    this.translationService.translate(
                                        `vous_devez_s_le_55757`,
                                        customField.name
                                    )
                                ),
                        () => yup.array().of(yup.number())
                    );
                } else {
                    return getSchema(
                        () =>
                            yup
                                .number()
                                .oneOf(
                                    customField.values!.map(({ id }) => id),
                                    this.oneOfMessage(
                                        customField.name,
                                        customField.values.map(({ value }) => value)
                                    )
                                )
                                .required(
                                    this.translationService.translate(
                                        '_1_est_requis_99856',
                                        customField.name
                                    )
                                )
                                .typeError(
                                    this.translationService.translate(
                                        '_1_est_requis_99856',
                                        customField.name
                                    )
                                ),
                        () =>
                            yup.number().oneOf(
                                customField.values!.map(({ id }) => id),
                                this.oneOfMessage(
                                    customField.name,
                                    customField.values.map(({ value }) => value)
                                )
                            )
                    );
                }
            case FieldType.Date:
            case FieldType.Datetime:
                return getSchema(
                    () =>
                        DateTimeSchemaType.required(
                            this.translationService.translate(
                                '_1_est_requis_99856',
                                customField.name
                            )
                        ).typeError(
                            this.translationService.translate(
                                '_1_est_requis_99856',
                                customField.name
                            )
                        ),
                    () => yup.mixed().nullable()
                );
            case FieldType.Sex:
                return getSchema(
                    () =>
                        yup
                            .string()
                            .oneOf(ALL_SEX, this.oneOfMessage(customField.name, ALL_SEX))
                            .required(
                                this.translationService.translate(
                                    '_1_est_requis_99856',
                                    customField.name
                                )
                            )
                            .typeError(
                                this.translationService.translate(
                                    '_1_est_requis_99856',
                                    customField.name
                                )
                            ),
                    () => yup.string().oneOf(ALL_SEX, this.oneOfMessage(customField.name, ALL_SEX))
                );
            case FieldType.Language:
                return getSchema(
                    () =>
                        yup
                            .string()
                            .oneOf(
                                VALID_LANGUAGES,
                                this.oneOfMessage(customField.name, VALID_LANGUAGES)
                            )
                            .required(
                                this.translationService.translate(
                                    '_1_est_requis_99856',
                                    customField.name
                                )
                            )
                            .typeError(
                                this.translationService.translate(
                                    '_1_est_requis_99856',
                                    customField.name
                                )
                            ),
                    () =>
                        yup
                            .string()
                            .oneOf(
                                VALID_LANGUAGES,
                                this.oneOfMessage(customField.name, VALID_LANGUAGES)
                            )
                );
            case FieldType.Nationality:
                return getSchema(
                    () =>
                        yup
                            .string()
                            .oneOf(
                                this.countriesService.countriesCodes,
                                this.oneOfMessage(
                                    customField.name,
                                    this.countriesService.countriesCodes
                                )
                            )
                            .required(this.t('_1_est_requis_99856', customField.name))
                            .typeError(this.t('_1_est_requis_99856', customField.name)),
                    () =>
                        yup
                            .string()
                            .oneOf(
                                this.countriesService.countriesCodes,
                                this.oneOfMessage(
                                    customField.name,
                                    this.countriesService.countriesCodes
                                )
                            )
                );
            case FieldType.Country:
                return getSchema(
                    () =>
                        yup
                            .string()
                            .oneOf(
                                this.countriesService.countriesCodes,
                                this.oneOfMessage(
                                    customField.name,
                                    this.countriesService.countriesCodes
                                )
                            )
                            .required(
                                this.translationService.translate(
                                    '_1_est_requis_99856',
                                    customField.name
                                )
                            )
                            .typeError(
                                this.translationService.translate(
                                    '_1_est_requis_99856',
                                    customField.name
                                )
                            ),
                    () =>
                        yup
                            .string()
                            .oneOf(
                                this.countriesService.countriesCodes,
                                this.oneOfMessage(
                                    customField.name,
                                    this.countriesService.countriesCodes
                                )
                            )
                );
            default:
                return assertUnreachable(customField.fieldType);
        }
    }

    fieldUpdateInputDefault(
        customField: CustomFieldWithConditionFragment,
        countryValues: IDefaultCountryValues,
        checkboxDefaultEmpty?: boolean
    ) {
        switch (customField.fieldType) {
            case FieldType.Checkbox:
            case FieldType.Validation:
                return checkboxDefaultEmpty ? undefined : false;
            case FieldType.File:
                return this.documentInputService.documentInputDefault();
            case FieldType.Phone:
                return this.phoneInputService.phoneInputDefault(countryValues);
            case FieldType.Select:
                if (customField.canSelectMultiple) {
                    return [];
                } else {
                    return undefined;
                }
            case FieldType.Address:
            case FieldType.Date:
            case FieldType.Datetime:
            case FieldType.Language:
            case FieldType.Number:
            case FieldType.Sex:
            case FieldType.Text:
            case FieldType.Textarea:
            case FieldType.Time:
                return undefined;
            case FieldType.Country:
                return countryValues.user.country;
            case FieldType.Nationality:
                return countryValues.user.nationality;
            default:
                return assertUnreachable(customField.fieldType);
        }
    }

    private getFieldValue(userInfoFields: Fields, customField: CustomFieldWithConditionFragment) {
        const value = userInfoFields[customField.slug];

        if (customField.fieldType === FieldType.Phone && value) {
            return pick(value, PHONE_INPUT_KEYS);
        } else if (customField.fieldType === FieldType.File) {
            return pick(value, DOCUMENT_INPUT_KEYS);
        } else if (customField.fieldType === FieldType.Date && value) {
            return DateTime.fromISO(value, { zone: 'UTC' });
        } else {
            return value;
        }
    }
}
