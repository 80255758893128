import { BadgesPlus } from 'common-front/src/components/badgesPlus/badgesPlus';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { PositionsPositionFragment } from 'common/src/generated/types';
import * as React from 'react';

interface IPositionCategoryLeadersCellProps {
    position: PositionsPositionFragment;
}

export const PositionCategoryLeadersCell = (props: IPositionCategoryLeadersCellProps) => {
    const badges = React.useMemo(
        () =>
            (props.position.category?.leaders ?? []).map((leader) => ({
                id: leader.userInfo.id,
                color: 'gray',
                icon: null,
                text: leader.userInfo.name
            })),
        [props.position]
    );

    return (
        <Cell>
            <BadgesPlus badges={badges} />
        </Cell>
    );
};
