import { CommonEnvVars } from './envVars';
import { SupportedLanguage } from './generated/types';
import { assertUnreachable } from './util/assertUnreachable';

export enum HeaventEnv {
    Amicale = 'amicale',
    Mso = 'mso',
    Recrewteer = 'recrewteer',
    Weezcrew = 'weezcrew'
}

export function heaventEnvDisplayName() {
    switch (CommonEnvVars.HEAVENT_ENV) {
        case HeaventEnv.Amicale:
            return 'Basile';
        case HeaventEnv.Mso:
            return 'Vabene';
        case HeaventEnv.Recrewteer:
            return 'Recrewteer';
        case HeaventEnv.Weezcrew:
            return 'WeezCrew';
        default:
            return assertUnreachable(CommonEnvVars.HEAVENT_ENV);
    }
}

export function heaventEnvLogo() {
    switch (CommonEnvVars.HEAVENT_ENV) {
        case HeaventEnv.Amicale:
            return 'https://assets.recrewteer.com/logos/amicale.png';
        case HeaventEnv.Mso:
            return 'https://assets.recrewteer.com/logos/vabene.png';
        case HeaventEnv.Recrewteer:
            return 'https://assets.recrewteer.com/logos/recrewteer.png';
        case HeaventEnv.Weezcrew:
            return 'https://assets.recrewteer.com/logos/weezcrew.png';
        default:
            return assertUnreachable(CommonEnvVars.HEAVENT_ENV);
    }
}

export function heaventEnvTermsUrl(language: SupportedLanguage) {
    switch (CommonEnvVars.HEAVENT_ENV) {
        case HeaventEnv.Amicale:
            return 'https://basile.amicaledesbenevoles.org/';
        case HeaventEnv.Mso:
            return 'https://www.mso.swiss/documents/term-of-use.pdf';
        case HeaventEnv.Recrewteer:
        case HeaventEnv.Weezcrew:
            if (language === SupportedLanguage.Fr) {
                return 'https://weezevent.com/fr/cgus/';
            } else if (language === SupportedLanguage.Nl) {
                return 'https://weezevent.com/nl/gebruiksvoorwaarden-diensten/';
            } else if (language === SupportedLanguage.Es) {
                return 'https://weezevent.com/es/cgus/';
            } else {
                return 'https://weezevent.com/en-gb/terms-of-use/';
            }
        default:
            return assertUnreachable(CommonEnvVars.HEAVENT_ENV);
    }
}

export function heaventEnvSupportEmail() {
    switch (CommonEnvVars.HEAVENT_ENV) {
        case HeaventEnv.Amicale:
        case HeaventEnv.Mso:
            return CommonEnvVars.EMAIL_FROM;
        case HeaventEnv.Recrewteer:
            return 'support@recrewteer.com';
        case HeaventEnv.Weezcrew:
            return `crew@weezevent.com`;
        default:
            return assertUnreachable(CommonEnvVars.HEAVENT_ENV);
    }
}
