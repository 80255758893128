import { Page } from 'common-front/src/components/page/page';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';
import { AccreditationsCategoryAccreditationsCard } from '../cards/accreditationsCategoryAccreditationsCard/accreditationsCategoryAccreditationsCard';
import { AccreditationsCategoryChartCard } from '../cards/accreditationsCategoryChartCard/accreditationsCategoryChartCard';

export const AccreditationCategoryDashboard = () => (
    <Page.Content>
        <Flex direction="column" gap="6">
            <Flex width={1}>
                <AccreditationsCategoryChartCard />
            </Flex>

            <Box css={{ flex: '1' }}>
                <AccreditationsCategoryAccreditationsCard />
            </Box>
        </Flex>
    </Page.Content>
);
