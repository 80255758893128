import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Link } from 'common/src/designSystem/components/link';
import { CheckInState, UserPositionsQuery } from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { getColorPalette } from 'common/src/util/color';
import { useService } from 'common/src/util/dependencies/dependencies';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import { fullName } from 'common/src/vo/positionSlot';
import * as React from 'react';

interface IUserPositionsAssignedCalendarPositionProps {
    psui: UserPositionsQuery['event']['volunteerRegistration']['positionsSlotsUsersInfos'][number];
}

export const UserPositionsAssignedCalendarPosition = (
    props: IUserPositionsAssignedCalendarPositionProps
) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const intervalService = useService(IntervalService);
    const colorCss = React.useMemo(() => {
        const palette = getColorPalette(props.psui.position.color);

        return {
            background: palette['50'],
            color: palette['700']
        };
    }, [props.psui]);
    const slotName = React.useMemo(
        () =>
            fullName(intervalService, props.psui.positionSlot, '', {
                interval: {
                    options: { hideDate: true }
                },
                slot: { includeRange: true }
            }),
        [props.psui]
    );

    return (
        <Link
            style={{ width: '100%' }}
            to={PositionsPaths.POSITION({
                organizationId,
                eventId,
                positionId: props.psui.position.id
            })}
        >
            <Flex
                css={{ ...colorCss, borderRadius: '$1', padding: '$1 $2' }}
                direction="column"
                gap="1"
                justify="center"
                width={1}
            >
                <Flex align="center" css={{ fontSize: '10px' }} gap="1">
                    <Box>{slotName}</Box>

                    {props.psui.state === CheckInState.CheckedOut && (
                        <I icon="stopwatch" title={translate('d_pointer_25328')} />
                    )}

                    {props.psui.state === CheckInState.CheckedIn && (
                        <I icon="timer" title={translate('pointer_80927')} />
                    )}
                </Flex>

                <Box fontSize="textXs" fontWeight="medium">
                    {props.psui.position.name}
                </Box>
            </Flex>
        </Link>
    );
};
