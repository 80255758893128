import { AvatarNameEmail } from 'common-front/src/components/avatarNameEmail';
import { Button } from 'common-front/src/designSystem/components/button';
import { copy } from 'common-front/src/util/commands';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Link } from 'common/src/designSystem/components/link';
import { Span } from 'common/src/designSystem/components/span';
import { FormUserInfoUserProfileFragment } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { getFormHref } from 'common/src/util/url';
import * as React from 'react';
import { useState } from 'react';

interface IUserInformationsTeamProps {
    team: FormUserInfoUserProfileFragment &
        Required<Pick<FormUserInfoUserProfileFragment, 'teamCode'>>;
}

export const UserInformationsTeam = ({
    team: { organizationId, eventId, formId, teamCode, teamMembers }
}: IUserInformationsTeamProps) => {
    const translate = useTranslate();
    const link = getFormHref({
        organizationId,
        eventId,
        formId,
        teamCode
    });
    const [isLoading, setIsLoading] = useState(false);
    const copyLink = React.useCallback(() => {
        setIsLoading(true);
        copy(
            getFormHref({
                organizationId,
                eventId,
                formId,
                teamCode
            })
        );

        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    }, [organizationId, eventId, formId, teamCode, setIsLoading]);

    return (
        <Flex
            css={{
                background: 'white',
                bd: true,
                padding: '$4 $6'
            }}
            direction="column"
            gap="3"
        >
            <Flex align="center" gap="2">
                <I icon="user-group" />

                <Box font="gray900 textMd medium">
                    {translate('_quipe_76857')}{' '}
                    <Span font="gray500 textSm regular">({teamCode})</Span>
                </Box>
            </Flex>

            {teamMembers.map((teamMember) => (
                <AvatarNameEmail key={teamMember.id} size={32} userInfo={teamMember} />
            ))}

            <Flex align="center" gap="3" justify="end">
                <Link to={link}>{translate('lien_d_invitati_45319')}</Link>
                <Button
                    color="white"
                    isLoading={isLoading}
                    rightIcon="copy"
                    size="sm"
                    onClick={copyLink}
                />
            </Flex>
        </Flex>
    );
};
