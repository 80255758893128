import { Label } from 'common-front/src/designSystem/components/input/label';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePromise } from 'common-front/src/hooks/usePromise';
import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import * as React from 'react';
import { useCustomFieldsContext } from '../customFieldsContext';

interface ICustomFieldsCategoryCellInputProps {
    value: number;

    setValue(value: number): void;
}

export const CustomFieldsCategoryCellInput = (props: ICustomFieldsCategoryCellInputProps) => {
    const { translate } = useHeavent();
    const { getCustomFieldsCategories } = useCustomFieldsContext();
    const { result: customFieldsCategories, isLoading } = usePromise(getCustomFieldsCategories);

    return isLoading ? (
        <Flex direction="column" gap="1" width={1}>
            <Label>{translate('Category')}</Label>
            <Skeleton borderRadius="$1" height={38} width={1} />
        </Flex>
    ) : (
        <Select
            label={translate('Category')}
            shouldParseAsInt={true}
            value={props.value}
            onChange={props.setValue}
        >
            {customFieldsCategories.map((category) => (
                <option key={category.id} value={category.id}>
                    {category.name}
                </option>
            ))}
        </Select>
    );
};
