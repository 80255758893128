import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { I } from 'common/src/designSystem/components/i';
import { VolunteersImportInput } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { getEditDistance, MAX_EDIT_DISTANCE } from 'common/src/util/string';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { ImportFieldCategory } from './types';

interface IColumnMappingProps {
    column: {
        name: string;
        index: number;
    };
    defaultFields: Record<string, string>;
    categories: ImportFieldCategory[];
    requiredFieldSlugs: string[];
    values: VolunteersImportInput['mappings'];
}

export const ColumnMapping = (props: IColumnMappingProps) => {
    const translate = useTranslate();
    const sortedCategories = React.useMemo(
        () =>
            props.categories.map((category) => ({
                ...category,
                fields: sortBy(category.fields, (field) => {
                    const fieldName = field.name.toLowerCase();
                    const fieldSlug = field.slug.toLowerCase() as string;
                    const columnName = props.column.name.toLowerCase();
                    const minEditDistance = Math.min(
                        getEditDistance(fieldName, columnName),
                        getEditDistance(fieldSlug, columnName)
                    );

                    return minEditDistance <
                        Math.min(fieldName.length, fieldSlug.length, columnName.length)
                        ? minEditDistance <= MAX_EDIT_DISTANCE
                            ? minEditDistance
                            : Infinity
                        : Infinity;
                })
            })),
        [props.categories, props.column]
    );
    const renderValue = React.useCallback(
        (value: any, name: string) => {
            const field = Object.values(props.defaultFields).find((val) => val === value);

            if (field && props.requiredFieldSlugs.includes(field)) {
                return <strong>{name}</strong>;
            } else {
                return name;
            }
        },
        [props.defaultFields, props.requiredFieldSlugs]
    );

    return (
        <>
            <TextInput state="disabled" value={props.column.name} onChange={() => undefined} />

            <I icon="arrow-right" />

            <RichSelect
                isSearchVisible={true}
                multiple={false}
                name={`input.mappings.${props.column.index}`}
                placeholder={translate('s_lectionnez_un_12007')}
                renderValue={renderValue}
            >
                <optgroup label={translate('d_faut_82104')}>
                    {Object.entries(props.defaultFields).map(([name, slug]) => (
                        <option
                            key={`default-${slug}`}
                            style={{
                                fontWeight: props.requiredFieldSlugs.includes(slug)
                                    ? 'bold'
                                    : 'inherit',
                                opacity: props.values.includes(slug) ? 0.5 : 1
                            }}
                            value={slug}
                        >
                            {name}
                        </option>
                    ))}
                </optgroup>

                {sortedCategories.map((category) => (
                    <optgroup key={category.id} label={category.name}>
                        {category.fields.map((field) => (
                            <option
                                key={field.id}
                                style={{
                                    fontWeight: props.requiredFieldSlugs.includes(field.slug)
                                        ? 'bold'
                                        : 'inherit',
                                    opacity: props.values.includes(field.slug) ? 0.5 : 1
                                }}
                                value={field.slug}
                            >
                                {field.name}
                            </option>
                        ))}
                    </optgroup>
                ))}
            </RichSelect>
        </>
    );
};
