import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import { UserHistoryFormsQuery } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import * as React from 'react';

interface IUserHistoryFormRowProps {
    fui: UserHistoryFormsQuery['organization']['userInfo']['formsUsersInfos'][number];
}

export const UserHistoryFormRow = (props: IUserHistoryFormRowProps) => {
    const { translate } = useHeavent();
    const dateTimeService = useService(DateTimeService);

    return (
        <Row>
            <Cell align="start" direction="column" justify="center">
                <Box>{props.fui.form.name}</Box>

                <Box color="gray500">
                    {translate(
                        'rempli_le_1_29292',
                        dateTimeService.toLocaleString(
                            props.fui.insertedAt.toLocal(),
                            LocaleFormats.DateTime
                        )
                    )}
                </Box>
            </Cell>

            <Cell>
                <Box css={{ ellipsis: true }}>
                    {props.fui.event?.name ?? translate('communaut_20896')}
                </Box>
            </Cell>
        </Row>
    );
};
