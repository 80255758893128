import { UpdateLinkTextCell } from 'common-front/src/components/cells/updateLinkTextCell';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { LinkText } from 'common/src/designSystem/components/linkText';
import { PositionId, PositionProperty, PositionsCategory } from 'common/src/generated/types';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import * as React from 'react';
import { PositionsCategoryCellInput } from './positionsCategoryCellInput';
import { PositionsUpdateCell } from './positionsUpdateCell';

interface IPositionsCategoryCellProps {
    positionCategory: Pick<PositionsCategory, 'id' | 'name'>;
    positionId: PositionId;
    updatable: boolean;

    reload(): void;
}

export const PositionsCategoryCell = (props: IPositionsCategoryCellProps) => {
    const {
        params: { organizationId, eventId }
    } = useHeavent();
    const { isEditMode } = useSegmentsContext();

    return (
        <PositionsUpdateCell
            initialValue={props.positionCategory.id}
            positionId={props.positionId}
            property={PositionProperty.Category}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <PositionsCategoryCellInput setValue={setValue} value={value} />
            )}
            renderValue={() => (
                <UpdateLinkTextCell
                    isEditMode={isEditMode}
                    title={props.positionCategory.name}
                    updatable={props.updatable}
                >
                    <LinkText
                        to={PositionsPaths.POSITION_CATEGORY({
                            organizationId,
                            eventId,
                            positionCategoryId: props.positionCategory.id
                        })}
                    >
                        {props.positionCategory.name}
                    </LinkText>
                </UpdateLinkTextCell>
            )}
            updatable={props.updatable}
        />
    );
};
