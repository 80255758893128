import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { Feature } from 'common/src/generated/types';
import { PositionPermission } from 'common/src/graphql/permissions/dsl';
import { AccreditationsPaths } from 'common/src/util/paths/accreditationsPaths';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import { FormsPaths } from 'common/src/util/paths/formsPaths';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import * as React from 'react';
import { useOrganizationContext } from '../../../organization/organizationContext';
import { useEventContext } from '../eventContext';
import { EventMenuGroup } from './eventMenuGroup';
import { EventMenuItem } from './eventMenuItem';

interface IEventMenuProps {
    isOpen: boolean;
}

export const EventMenu = (props: IEventMenuProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { hasFeature } = useOrganizationContext();
    const {
        isEventAdmin,
        isPositionAdmin,
        hasPositionPermissions,
        numberOfWaitingAccreditations,
        numberOfWaitingAssigments,
        updateLocalEvent
    } = useEventContext();
    const isMembersActive = React.useCallback(
        (pathname: string) =>
            pathname.endsWith('/volunteers') ||
            pathname.match(/\/volunteers\/\d+/) !== null ||
            (pathname.match(/\/user\/\d+/) !== null &&
                (pathname.endsWith('informations') ||
                    pathname.endsWith('availabilities') ||
                    pathname.endsWith('positions') ||
                    pathname.endsWith('accreditations') ||
                    pathname.endsWith('actions') ||
                    pathname.endsWith('comments') ||
                    pathname.includes('history'))),
        []
    );
    const isDelegationsActive = React.useCallback(
        (pathname) =>
            pathname.match(/\/delegations\/\d+/) !== null ||
            pathname.match(/\/delegation\/\d+/) !== null ||
            pathname.match(/\/delegation-category\/\d+/) !== null,
        []
    );
    const isPopulationActive = React.useCallback(
        (pathname: string) => isMembersActive(pathname) || isDelegationsActive(pathname),
        [isMembersActive, isDelegationsActive]
    );
    const isMissionsActive = React.useCallback(
        (pathname: string) =>
            (pathname.match(/\/positions\/\d+/) !== null ||
                pathname.match(/\/position\/\d+/) !== null ||
                pathname.match(/\/position-category\/\d+/) !== null) &&
            !pathname.includes('documents'),
        []
    );
    const isAssignmentsActive = React.useCallback(
        (pathname: string) =>
            pathname.endsWith('/assignments') ||
            pathname.endsWith('/assignments/blank') ||
            pathname.match(/\/assignment\/\d+/) !== null ||
            pathname.match(/\/pre-assignment\/\d+/) !== null,
        []
    );
    const isMissionsGroupActive = React.useCallback(
        (pathname: string) => isMissionsActive(pathname) || isAssignmentsActive(pathname),
        [isMissionsActive, isAssignmentsActive]
    );
    const isAccreditationsActive = React.useCallback(
        (pathname: string) =>
            pathname.match(/\/accreditations\/\d+/) !== null ||
            pathname.match(/\/accreditation\/\d+/) !== null ||
            pathname.match(/\/accreditation-category\/\d+/) !== null,
        []
    );
    const isAccreditationsAssignmentsActive = React.useCallback(
        (pathname: string) =>
            pathname.endsWith('/accreditations-assignments') ||
            pathname.match(/\/accredit\/\d+/) !== null,
        []
    );
    const isAccreditationsGroupActive = React.useCallback(
        (pathname: string) =>
            isAccreditationsActive(pathname) || isAccreditationsAssignmentsActive(pathname),
        [isAccreditationsActive, isAccreditationsAssignmentsActive]
    );
    const isFormsActive = React.useCallback((pathname: string) => pathname.endsWith('/forms'), []);
    const isCampaignsActive = React.useCallback(
        (pathname: string) =>
            (pathname.endsWith('/campaigns') && !pathname.endsWith('/history/campaigns')) ||
            pathname.match(/\/campaign\/\d+/) !== null,
        []
    );
    const isEmailsActive = React.useCallback(
        (pathname: string) =>
            pathname.endsWith('/emails/parameters') ||
            pathname.endsWith('/emails/templates') ||
            pathname.endsWith('/emails/dns'),
        []
    );
    const isDocumentsActive = React.useCallback(
        (pathname: string) =>
            pathname.endsWith('/documents') || pathname.match(/\/documents\//) !== null,
        []
    );
    const isCommunicationActive = React.useCallback(
        (pathname: string) =>
            isFormsActive(pathname) ||
            isCampaignsActive(pathname) ||
            isEmailsActive(pathname) ||
            isDocumentsActive(pathname),
        [isFormsActive, isCampaignsActive, isEmailsActive, isDocumentsActive]
    );
    const isApiActive = React.useCallback(
        (pathname: string) => pathname.endsWith('/options/api'),
        []
    );
    const isWeezeventActive = React.useCallback(
        (pathname: string) => pathname.endsWith('options/weezevent-v2'),
        []
    );
    const isOptionsActive = React.useCallback(
        (pathname: string) => isApiActive(pathname) || isWeezeventActive(pathname),
        [isApiActive, isWeezeventActive]
    );
    const showDelegations = React.useMemo(
        () => hasFeature(Feature.Delegation) && isEventAdmin,
        [hasFeature, isEventAdmin]
    );
    const showAccreditations = React.useMemo(
        () => hasFeature(Feature.Accreditation) && isEventAdmin,
        [hasFeature, isEventAdmin]
    );
    const showCommunication = React.useMemo(() => isPositionAdmin(), [isPositionAdmin]);
    const showForms = isEventAdmin;
    const showEmailsParameters = isEventAdmin;
    const showDocuments = isEventAdmin;
    const showApi = hasFeature(Feature.Api);
    const showOptions = isEventAdmin;
    const [canAssign] = hasPositionPermissions([PositionPermission.ASSIGN_MEMBER]);

    return (
        <Flex
            css={{
                overflowY: props.isOpen ? 'auto' : undefined,
                paddingTop: '$5',
                px: '$3'
            }}
            direction="column"
            gap="1"
            width={1}
        >
            <EventMenuGroup
                icon="objects-column"
                isMenuOpen={props.isOpen}
                title={translate('tableau_de_bord_24627')}
                to={HeaventPaths.DASHBOARD(organizationId, eventId)}
            />

            <EventMenuGroup
                icon="user-group"
                isActive={isPopulationActive}
                isMenuOpen={props.isOpen}
                title={translate('population_38615')}
            >
                <EventMenuItem
                    isActive={isMembersActive}
                    to={HeaventPaths.VOLUNTEERS(organizationId, eventId)}
                    onClick={() => updateLocalEvent({ volunteersSegmentId: null })}
                >
                    {translate('membres_11310')}
                </EventMenuItem>

                {showDelegations && (
                    <EventMenuItem
                        isActive={isDelegationsActive}
                        to={DelegationsPaths.DELEGATIONS({ organizationId, eventId })}
                        onClick={() => updateLocalEvent({ delegationsSegmentId: null })}
                    >
                        {translate('d_l_gations_78318')}
                    </EventMenuItem>
                )}
            </EventMenuGroup>

            <EventMenuGroup
                icon="sitemap"
                isActive={isMissionsGroupActive}
                isMenuOpen={props.isOpen}
                title={translate('missions_63972')}
            >
                <EventMenuItem
                    isActive={isMissionsActive}
                    to={PositionsPaths.POSITIONS({
                        organizationId,
                        eventId
                    })}
                    onClick={() => updateLocalEvent({ positionsSegmentId: null })}
                >
                    {translate('missions_63972')}
                </EventMenuItem>

                {canAssign && (
                    <EventMenuItem
                        isActive={isAssignmentsActive}
                        to={HeaventPaths.ASSIGNMENTS(organizationId, eventId)}
                    >
                        {`${translate('affectation_plural')} (${numberOfWaitingAssigments})`}
                    </EventMenuItem>
                )}
            </EventMenuGroup>

            {showAccreditations && (
                <EventMenuGroup
                    icon="badge-check"
                    isActive={isAccreditationsGroupActive}
                    isMenuOpen={props.isOpen}
                    title={translate('accr_ditations_39450')}
                >
                    <EventMenuItem
                        isActive={isAccreditationsActive}
                        to={AccreditationsPaths.ACCREDITATIONS({ organizationId, eventId })}
                        onClick={() => updateLocalEvent({ accreditationsSegmentId: null })}
                    >
                        {translate('accr_ditations_39450')}
                    </EventMenuItem>

                    <EventMenuItem
                        isActive={isAccreditationsAssignmentsActive}
                        to={HeaventPaths.ACCREDITATIONS_ASSIGNMENTS(organizationId, eventId)}
                    >
                        {`${translate('mod_ration_09947')} (${numberOfWaitingAccreditations})`}
                    </EventMenuItem>
                </EventMenuGroup>
            )}

            {showCommunication && (
                <EventMenuGroup
                    icon="message"
                    isActive={isCommunicationActive}
                    isMenuOpen={props.isOpen}
                    title={translate('communication_05277')}
                >
                    {showForms && (
                        <EventMenuItem
                            isActive={isFormsActive}
                            to={FormsPaths.FORMS({ organizationId, eventId })}
                        >
                            {translate('Form_plural')}
                        </EventMenuItem>
                    )}

                    <EventMenuItem
                        isActive={isCampaignsActive}
                        to={HeaventPaths.CAMPAIGNS(organizationId, eventId)}
                    >
                        {translate('campagnes_31372')}
                    </EventMenuItem>

                    {showEmailsParameters && (
                        <EventMenuItem
                            isActive={(pathname: string) =>
                                pathname.endsWith('/emails/parameters') ||
                                pathname.endsWith('/emails/templates') ||
                                pathname.endsWith('/emails/dns')
                            }
                            to={HeaventPaths.EVENT_EMAILS_PARAMETERS(organizationId, eventId)}
                        >
                            {translate('e_mails_09850')}
                        </EventMenuItem>
                    )}

                    {showDocuments && (
                        <EventMenuItem
                            isActive={isDocumentsActive}
                            to={HeaventPaths.DOCUMENTS(organizationId, eventId)}
                        >
                            {translate('documents_87028')}
                        </EventMenuItem>
                    )}
                </EventMenuGroup>
            )}

            {showOptions && (
                <EventMenuGroup
                    icon="sliders-up"
                    isActive={isOptionsActive}
                    isMenuOpen={props.isOpen}
                    title={translate('options_53186')}
                >
                    {showApi && (
                        <EventMenuItem
                            isActive={isApiActive}
                            to={HeaventPaths.OPTIONS_API(organizationId, eventId)}
                        >
                            API
                        </EventMenuItem>
                    )}

                    <EventMenuItem
                        isActive={isWeezeventActive}
                        to={HeaventPaths.OPTIONS_WEEZEVENT_V2(organizationId, eventId)}
                    >
                        Weezevent
                    </EventMenuItem>
                </EventMenuGroup>
            )}
        </Flex>
    );
};
