import {
    AuthenticationDetails,
    CognitoAccessToken,
    CognitoIdToken,
    CognitoRefreshToken,
    CognitoUser,
    CognitoUserAttribute,
    CognitoUserPool,
    CognitoUserSession,
    IAuthenticationCallback
} from 'amazon-cognito-identity-js';
import { CommonEnvVars } from 'common/src/envVars';
import { isNonEmptyString } from 'common/src/util/string';
import { uuidv4 } from 'common/src/util/uuid';
import { reportLog } from '../log';

export const USER_SESSION_KEY = 'heavent-user-session-key';
export const USER_EMAIL_KEY = 'heavent-user-email';
const USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException';

let userPool: CognitoUserPool | null = null;

export const getUserPool = (): CognitoUserPool => {
    if (userPool === null) {
        userPool = new CognitoUserPool({
            UserPoolId: CommonEnvVars.COGNITO_USER_POOL_ID,
            ClientId: CommonEnvVars.COGNITO_USER_POOL_CLIENT_ID
        });
    }

    return userPool;
};

interface ICognitoUserAndToken {
    user: CognitoUser;
    token: string;
}

function currentUser(): Promise<ICognitoUserAndToken> {
    return new Promise<ICognitoUserAndToken>((resolve, reject) => {
        const user = getUserPool().getCurrentUser();

        if (user !== null) {
            user.getSession((error: Error | null, session: CognitoUserSession | null) => {
                if (error || session?.isValid() === false) {
                    reject(new Error('Could not get current user'));
                } else {
                    resolve({
                        user,
                        token: session!.getIdToken().getJwtToken()
                    });
                }
            });
        } else {
            reject(new Error('Could not get current user'));
        }
    });
}

export async function getToken(): Promise<string | undefined> {
    try {
        const token = localStorage.getItem('heavent-test-token');

        if (isNonEmptyString(token)) {
            return token;
        } else {
            const user = await currentUser();

            return user.token;
        }
    } catch {
        return undefined;
    }
}

function signUpOnly(email: string, password: string): Promise<CognitoUser> {
    const finalEmail = email.trim().toLowerCase();

    return new Promise<CognitoUser>((resolve, reject) => {
        const attributes = [
            new CognitoUserAttribute({
                Name: 'email',
                Value: finalEmail
            })
        ];

        getUserPool().signUp(finalEmail, password, attributes, [], (error: any, result) => {
            if (error) {
                reject(error);
            } else {
                resolve(result!.user);
            }
        });
    });
}

export function signOut(): void {
    currentUser()
        .then(({ user }) => {
            user.signOut();

            location.reload();
        })
        .catch(() => {
            alert('Something wrong happened ');
        });
}

export function signOutNoRedirect(): Promise<any> {
    return currentUser().then(({ user }) => {
        user.signOut();
    });
}

export function signInSso(tokens: any) {
    const session = new CognitoUserSession({
        IdToken: new CognitoIdToken({ IdToken: tokens.idToken }),
        RefreshToken: new CognitoRefreshToken({
            RefreshToken: tokens.refreshToken
        }),
        AccessToken: new CognitoAccessToken({ AccessToken: tokens.accessToken })
    });
    const finalEmail = tokens.email.trim().toLowerCase();

    const user = new CognitoUser({
        Username: finalEmail,
        Pool: getUserPool()
    });

    user.setSignInUserSession(session);

    return user;
}

export interface IInitiateAuthResult {
    user: CognitoUser;
    callbacks: IAuthenticationCallback;
}

function initiateAuth(
    email: string,
    onSuccess: (jwtToken: string) => void | undefined,
    onFailure: (error: any) => void | undefined
): Promise<IInitiateAuthResult> {
    return new Promise((resolve, reject) => {
        const user = new CognitoUser({
            Username: email,
            Pool: getUserPool()
        });
        const authenticationDetails = new AuthenticationDetails({
            Username: email
        });
        const callbacks: IAuthenticationCallback = {
            onSuccess(result: CognitoUserSession) {
                onSuccess(result.getIdToken().getJwtToken());
            },
            onFailure(error: any) {
                if (error.code === USER_NOT_FOUND_EXCEPTION) {
                    reject(USER_NOT_FOUND_EXCEPTION);
                } else {
                    onFailure(error);
                }
            },
            customChallenge() {
                localStorage.setItem(USER_EMAIL_KEY, email);
                localStorage.setItem(USER_SESSION_KEY, (user as any).Session);

                resolve({
                    user,
                    callbacks
                });
            }
        };

        user.setAuthenticationFlowType('CUSTOM_AUTH');

        user.initiateAuth(authenticationDetails, callbacks);
    });
}

export async function initiateAuthWithSignUp(
    email: string,
    onSuccess: (jwtToken: string) => void | undefined,
    onFailure: (error: any) => void | undefined
): Promise<IInitiateAuthResult> {
    try {
        return await initiateAuth(email, onSuccess, onFailure);
    } catch (e) {
        if (e === USER_NOT_FOUND_EXCEPTION) {
            try {
                await signUpOnly(email, uuidv4());

                return await initiateAuth(email, onSuccess, onFailure);
            } catch {
                throw new Error('Auth failure');
            }
        } else {
            throw e;
        }
    }
}

export function authenticateUser(email: string, password: string): Promise<string> {
    return new Promise((resolve, reject) => {
        const user = new CognitoUser({
            Username: email,
            Pool: getUserPool()
        });

        user.authenticateUser(
            new AuthenticationDetails({
                Username: email,
                Password: password
            }),
            {
                onSuccess(result: CognitoUserSession) {
                    resolve(result.getIdToken().getJwtToken());
                },
                onFailure(error: any) {
                    reject(error);
                }
            }
        );
    });
}

export function forgotPassword(email: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
        const user = new CognitoUser({
            Username: email,
            Pool: getUserPool()
        });

        user.forgotPassword({
            onSuccess() {
                resolve(true);
            },
            async onFailure(error: any) {
                if (error.code === USER_NOT_FOUND_EXCEPTION) {
                    try {
                        await signUpOnly(email, uuidv4());
                        await forgotPassword(email);

                        resolve(true);
                    } catch (e) {
                        reject(e);
                    }
                } else {
                    reject(error);
                }
            }
        });
    });
}

export function confirmPassword(email: string, code: string, password: string): Promise<string> {
    return new Promise((resolve, reject) => {
        const user = new CognitoUser({
            Username: email,
            Pool: getUserPool()
        });

        user.confirmPassword(code, password, {
            async onSuccess() {
                resolve(await authenticateUser(email, password));
            },
            onFailure(error: any) {
                reject(error);
            }
        });
    });
}

export function sendCustomChallengeAnswer({
    code,
    user,
    callbacks
}: { code: string | undefined } & IInitiateAuthResult) {
    const promise = new Promise<Parameters<IAuthenticationCallback['onSuccess']>>(
        (resolve, reject) => {
            const onSuccess = (...args: Parameters<IAuthenticationCallback['onSuccess']>) => {
                callbacks.onSuccess(...args);
                resolve(args);
            };
            const onFailure = (error: Parameters<IAuthenticationCallback['onFailure']>[0]) => {
                callbacks.onFailure(error);
                reject({ type: 'FAILED', error });
            };
            const customChallenge = (
                ...args: Parameters<Required<IAuthenticationCallback>['customChallenge']>
            ) => {
                if (callbacks.customChallenge) {
                    callbacks.customChallenge(...args);
                }
                reject({ type: 'RETRY' });
            };
            user.sendCustomChallengeAnswer(code, {
                ...callbacks,
                onSuccess,
                onFailure,
                customChallenge
            });
        }
    );

    return promise.then(([session]) => session.getIdToken().getJwtToken());
}

export function reportAuthError(errorMessage: string, email: string, challengeStartTime: number) {
    reportLog({
        message: 'Could not auth',
        errorMessage,
        email,
        location: location.href,
        diff: Math.round((Date.now() - challengeStartTime) / 1000),
        userAgent: navigator.userAgent
    });
}
