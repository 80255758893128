import { HorizontalTabs } from 'common-front/src/designSystem/components/horizontalTabs/horizontalTabs';
import { Tab } from 'common-front/src/designSystem/components/horizontalTabs/tab';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';
import { useDocumentBuilderContext } from '../documentBuilderContext';
import { DocumentBuilderLeftPanelContainer } from './documentBuilderLeftPanelContainer';
import { DocumentBuilderLeftPanelDesign } from './documentBuilderLeftPanelDesign';
import { DocumentBuilderLeftPanelImage } from './documentBuilderLeftPanelImage';
import { DocumentBuilderLeftPanelParameters } from './documentBuilderLeftPanelParameters';
import { DocumentBuilderLeftPanelText } from './documentBuilderLeftPanelText';

export const DocumentBuilderLeftPanel = () => {
    const { translate } = useHeavent();
    const { getSelectedElement, tab, setTab } = useDocumentBuilderContext();
    const selectedElement = getSelectedElement();

    return selectedElement?.type === 'container' ? (
        <DocumentBuilderLeftPanelContainer container={selectedElement} />
    ) : selectedElement?.type === 'image' ? (
        <DocumentBuilderLeftPanelImage image={selectedElement} />
    ) : selectedElement?.type === 'text' ? (
        <DocumentBuilderLeftPanelText text={selectedElement} />
    ) : (
        <Flex
            css={{
                boxShadow: '$sm',
                overflow: 'hidden auto',
                py: '$6',
                paddingBottom: '$11'
            }}
            direction="column"
            gap="6"
            height={1}
            width={1}
        >
            <Flex css={{ px: '$4' }}>
                <HorizontalTabs selectedItem={tab} onChange={setTab}>
                    <Tab item="parameters">{translate('param_tres_78420')}</Tab>
                    <Tab item="design">{translate('design_15043')}</Tab>
                </HorizontalTabs>
            </Flex>

            {tab === 'parameters' && <DocumentBuilderLeftPanelParameters />}

            {tab === 'design' && <DocumentBuilderLeftPanelDesign />}
        </Flex>
    );
};
