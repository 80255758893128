import { closeOtherDropdowns } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { DropdownEllipsis } from 'common-front/src/designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePositionDuplicate } from 'common-front/src/vo/position';
import { PositionsPositionFragment } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import { UpdatePositionCategory } from '../../../positionsCategories/create/createUpdatePositionCategory';
import { DeletePosition } from '../../deletePosition';

interface IPositionRowDropdownProps {
    position: PositionsPositionFragment;

    reload(): void;
}

export const PositionRowDropdown = (props: IPositionRowDropdownProps) => {
    const {
        params: { organizationId, eventId },
        translate
    } = useHeavent();
    const positionDuplicate = usePositionDuplicate();
    const { isEventAdmin } = useEventContext();
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
    const [isRenameOpen, setIsRenameOpen] = React.useState(false);

    return (
        <>
            <DropdownEllipsis>
                <ItemIcon
                    icon="pen"
                    to={HeaventPaths.EDIT_POSITION(organizationId, eventId, props.position.id)}
                >
                    {translate('_diter_62574')}
                </ItemIcon>

                <ItemIcon
                    icon="clone"
                    onClick={async () => {
                        await positionDuplicate(props.position.id);
                    }}
                >
                    {translate('dupliquer_28256')}
                </ItemIcon>

                <ItemIcon
                    color="red"
                    icon="trash-can"
                    onClick={(e) => {
                        setIsDeleteOpen(true);
                        closeOtherDropdowns(e.target);
                    }}
                >
                    {translate('supprimer_43083')}
                </ItemIcon>

                <Separator color="gray100" direction="horizontal" />

                <ItemIcon
                    icon="plus"
                    to={HeaventPaths.CREATE_POSITION(
                        organizationId,
                        eventId,
                        props.position.category.id
                    )}
                >
                    {translate('ajouter_une_mis_99800')}
                </ItemIcon>

                <ItemIcon
                    icon="eye"
                    to={PositionsPaths.POSITION_CATEGORY({
                        organizationId,
                        eventId,
                        positionCategoryId: props.position.category.id
                    })}
                >
                    {translate('voir_1_90465', props.position.category.name)}
                </ItemIcon>

                {isEventAdmin && (
                    <ItemIcon
                        icon="pen"
                        onClick={() => {
                            setIsRenameOpen(true);
                        }}
                    >
                        {translate('renommer_1_40542', props.position.category.name)}
                    </ItemIcon>
                )}
            </DropdownEllipsis>

            {isDeleteOpen && (
                <DeletePosition
                    position={props.position}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                    onSuccess={props.reload}
                />
            )}

            {isRenameOpen && (
                <UpdatePositionCategory
                    eventId={eventId}
                    positionCategoryId={props.position.category.id}
                    positionCategoryName={props.position.category.name}
                    onClose={() => {
                        setIsRenameOpen(false);
                    }}
                    onSuccess={props.reload}
                />
            )}
        </>
    );
};
