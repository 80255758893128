import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Segment } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';

interface ICommunityDelegationsHeaderProps {
    segment: Pick<Segment, 'id' | 'name'>;
}

export const CommunityDelegationsHeader = (props: ICommunityDelegationsHeaderProps) => {
    const {
        history,
        translate,
        params: { organizationId }
    } = useHeavent();

    return (
        <Flex align="center" gap="4" width={1}>
            <Box
                css={{ ellipsis: true, flex: '1' }}
                font="gray900 displaySm semiBold"
                title={props.segment.name}
            >
                {props.segment.name}
            </Box>

            <Button
                onClick={() => {
                    history.push(HeaventPaths.COMMUNITY_CREATE_DELEGATION(organizationId));
                }}
            >
                {translate('cr_er_une_d_l_g_23035')}
            </Button>
        </Flex>
    );
};
