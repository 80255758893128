import { Select } from 'common-front/src/designSystem/components/select/select';
import { DelegationProperty, DelegationsDelegationFragment } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { DelegationsUpdateCell } from './delegationsUpdateCell';

interface IDelegationsIsLockedCellProps {
    delegation: DelegationsDelegationFragment;

    reload(): void;
}

export const DelegationsIsLockedCell = (props: IDelegationsIsLockedCellProps) => {
    const translate = useTranslate();

    return (
        <DelegationsUpdateCell
            delegation={props.delegation}
            initialValue={props.delegation.lockedAt?.isValid ?? false}
            property={DelegationProperty.IsLocked}
            reload={props.reload}
            renderInput={(value: boolean, setValue) => (
                <Select
                    label={translate('IsLocked')}
                    shouldParseAsBoolean={true}
                    value={value}
                    onChange={setValue}
                >
                    <option value="true">{translate('oui_54361')}</option>
                    <option value="false">{translate('non_33516')}</option>
                </Select>
            )}
            renderValue={
                props.delegation.lockedAt?.isValid ? translate('oui_54361') : translate('non_33516')
            }
        />
    );
};
