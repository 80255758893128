import { Empty } from 'common-front/src/components/empty/empty';
import { useVolunteersSegmentsQuery } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { CustomFieldPrivacy } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { useEventContext } from '../../events/show/eventContext';
import { Volunteers } from './volunteers';

export const VolunteersLoader = React.memo(function VolunteersLoader() {
    const {
        params: { organizationId, eventId },
        translate
    } = useHeavent();
    const {
        event: { name: eventName },
        localEvent
    } = useEventContext();
    useTitle(`${eventName} ${translate('membres_11310')}`);
    const { data, loader, reload } = useVolunteersSegmentsQuery({
        organizationId,
        eventId,
        privacy: CustomFieldPrivacy.All,
        loadForms: true,
        loadSegments: true
    });
    const segmentsIds = React.useMemo(
        () => (data.event?.segmentsFolders ?? []).flatMap((sf) => sf.segments).map((s) => s.id),
        [data.event]
    );

    if (loader) {
        return loader;
    } else {
        return (
            <Switch>
                <Route
                    path={HeaventPaths.VOLUNTEERS_SEGMENT(
                        ':organizationId',
                        ':eventId',
                        ':segmentId'
                    )}
                >
                    <Volunteers
                        event={data.event}
                        organization={data.organization}
                        reload={reload}
                    />
                </Route>

                <Route>
                    <Empty
                        path={HeaventPaths.VOLUNTEERS_SEGMENT(
                            organizationId,
                            eventId,
                            localEvent?.volunteersSegmentId &&
                                segmentsIds.includes(localEvent?.volunteersSegmentId)
                                ? localEvent.volunteersSegmentId
                                : segmentsIds[0]
                        )}
                        replace={true}
                    />
                </Route>
            </Switch>
        );
    }
});
