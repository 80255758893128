import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Link } from 'common/src/designSystem/components/link';
import { PositionsPositionFragment } from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { useParams, useService } from 'common/src/util/dependencies/dependencies';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import * as React from 'react';

export const POSITION_POPUP_HEIGHT = 70;

interface IPositionPopupProps {
    position: PositionsPositionFragment;
}

export const PositionPopup = (props: IPositionPopupProps) => {
    const { organizationId, eventId } = useParams();
    const intervalService = useService(IntervalService);

    return (
        <Flex
            align="center"
            css={{
                background: 'white',
                borderRadius: '$1',
                boxShadow: '$xs',
                padding: '$4'
            }}
            gap="4"
            height={POSITION_POPUP_HEIGHT}
        >
            <Link
                to={PositionsPaths.POSITION({
                    organizationId,
                    eventId,
                    positionId: props.position.id
                })}
            >
                <Flex direction="column">
                    <Box color="gray800">{props.position.name}</Box>
                    <Box color="gray500" fontSize="textXs">
                        {props.position.range
                            ? intervalService.toDisplayString(props.position.range)
                            : '-'}
                    </Box>
                </Flex>

                <Box>
                    <I icon="chevron-right" />
                </Box>
            </Link>
        </Flex>
    );
};
