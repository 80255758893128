import { LinkText } from 'common/src/designSystem/components/linkText';
import {
    ALL_LANGUAGE,
    ALL_SEX,
    CustomFieldWithConditionFragment,
    FieldProperty,
    FieldType,
    OrganizationId
} from 'common/src/generated/types';
import { FieldInputService } from 'common/src/input/fieldInput';
import { CountriesService } from 'common/src/services/countriesService';
import { assertUnreachable } from 'common/src/util/assertUnreachable';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { isNonEmptyString } from 'common/src/util/string';
import { USABLE_LANGUAGES } from 'common/src/vo/supportedLanguage';
import { DateTime } from 'luxon';
import * as React from 'react';
import { LabelOptional } from '../../../designSystem/components/input/labelOptional';
import { AddressInput } from '../../../designSystem/form/addressInput';
import { CheckboxText } from '../../../designSystem/form/checkbox';
import { DateInput } from '../../../designSystem/form/date/dateInput';
import { DatetimeInput } from '../../../designSystem/form/date/datetimeInput';
import { TimeInput } from '../../../designSystem/form/date/timeInput';
import { FileS3Input } from '../../../designSystem/form/file/fileS3Input';
import { ImageInput } from '../../../designSystem/form/image/imageInput';
import { PhoneInput } from '../../../designSystem/form/phoneInput';
import { RichSelect } from '../../../designSystem/form/richSelect';
import { Select } from '../../../designSystem/form/select/select';
import { Textarea } from '../../../designSystem/form/textarea';
import { TextInput } from '../../../designSystem/form/textInput';
import { useEnumToOptions } from '../../../hooks/useEnumToOptions';
import { Accept } from '../../../util/accept';

interface IFieldProps {
    countryCode?: Emptyable<string>;
    display: boolean;
    field: CustomFieldWithConditionFragment;
    fileAcl?: 'public-read' | 'private';
    isMandatory: boolean;
    organizationId: OrganizationId;
    prefix: string;

    change(name: string, value: any): void;
}

export const UserCreateField = (props: IFieldProps) => {
    const translate = useTranslate();
    const enumToOptions = useEnumToOptions();
    const countriesService = useService(CountriesService);
    const fieldInputService = useService(FieldInputService);
    const label = props.isMandatory ? (
        props.field.name
    ) : (
        <LabelOptional>{props.field.name}</LabelOptional>
    );
    const name = `${props.prefix}${props.field.slug}`;
    const description = props.field.description;
    const isRegisterForm = React.useMemo(
        () => name.startsWith('formRegister.userInfo.fields.'),
        [name]
    );

    React.useEffect(() => {
        if (!props.display) {
            props.change(
                name,
                fieldInputService.fieldUpdateInputDefault(
                    props.field,
                    countriesService.getDefaultCountryValues()
                )
            );
        }
    }, [props.display, props.field, props.change]);

    if (props.display) {
        switch (props.field.fieldType) {
            case FieldType.Text:
                return <TextInput description={description} label={label} name={name} />;
            case FieldType.Textarea:
                return <Textarea description={description} label={label} name={name} />;
            case FieldType.Checkbox:
                return (
                    <CheckboxText name={name} subtext={description}>
                        {label}
                    </CheckboxText>
                );
            case FieldType.Validation:
                return (
                    <CheckboxText
                        name={name}
                        subtext={
                            <>
                                {isNonEmptyString(description) && <span>{description}</span>}

                                {isNonEmptyString(description) && props.field.document && <br />}

                                {props.field.document && (
                                    <LinkText
                                        css={{ color: '$primary700' }}
                                        download={props.field.document.name}
                                        to={props.field.document.url}
                                        onClick={(e) => {
                                            // By NOT using `e.preventDefault()` here, we allow the link to
                                            // be clicked but stop the checkbox from being toggled (but do retain
                                            // the default behaviour for the rest of the `<label>`).
                                            e.stopPropagation();
                                            e.nativeEvent.stopImmediatePropagation();
                                        }}
                                    >
                                        {translate('consulter_le_do_69928')}
                                    </LinkText>
                                )}
                            </>
                        }
                    >
                        {label}
                    </CheckboxText>
                );
            case FieldType.Select:
                if (props.field.canSelectMultiple) {
                    return (
                        <RichSelect
                            description={description}
                            isSearchVisible={true}
                            isSelectAllVisible={true}
                            label={label}
                            multiple={true}
                            name={name}
                        >
                            {props.field.values.map(({ id, value }) => (
                                <option key={id} value={id}>
                                    {value}
                                </option>
                            ))}
                        </RichSelect>
                    );
                } else {
                    return (
                        <RichSelect
                            description={description}
                            isSearchVisible={true}
                            label={label}
                            name={name}
                            placeholder={translate('choisir_une_val_00277')}
                        >
                            {props.field.values.map(({ id, value }) => (
                                <option key={id} value={id}>
                                    {value}
                                </option>
                            ))}
                        </RichSelect>
                    );
                }
            case FieldType.Date:
                if (props.field.slug === 'dateOfBirth') {
                    return (
                        <DateInput
                            autoComplete={isRegisterForm ? 'bday' : undefined}
                            description={description}
                            label={label}
                            max={DateTime.now()}
                            name={name}
                        />
                    );
                } else {
                    return <DateInput description={description} label={label} name={name} />;
                }
            case FieldType.Datetime:
                return <DatetimeInput description={description} label={label} name={name} />;
            case FieldType.Time:
                return <TimeInput description={description} label={label} name={name} />;
            case FieldType.Phone:
                return <PhoneInput description={description} label={label} prefix={`${name}.`} />;
            case FieldType.Number:
                return (
                    <TextInput
                        description={description}
                        label={label}
                        name={name}
                        shouldParseAsInt={true}
                    />
                );
            case FieldType.Sex:
                return (
                    <Select
                        autoComplete={isRegisterForm ? 'sex' : undefined}
                        description={description}
                        label={label}
                        name={name}
                    >
                        <option disabled={true} value="">
                            {translate('choisir_une_val_00277')}
                        </option>

                        {enumToOptions(ALL_SEX)}
                    </Select>
                );
            case FieldType.Language:
                if (props.field.fieldProperty === FieldProperty.Language) {
                    return (
                        <RichSelect
                            autoComplete={isRegisterForm ? 'language' : undefined}
                            description={description}
                            isSearchVisible={true}
                            label={label}
                            name={name}
                            placeholder={translate('choisir_une_val_00277')}
                        >
                            {enumToOptions(USABLE_LANGUAGES)}
                        </RichSelect>
                    );
                } else {
                    return (
                        <RichSelect
                            description={description}
                            isSearchVisible={true}
                            label={label}
                            name={name}
                            placeholder={translate('choisir_une_val_00277')}
                        >
                            {enumToOptions(ALL_LANGUAGE)}
                        </RichSelect>
                    );
                }
            case FieldType.Nationality:
                return (
                    <RichSelect
                        description={description}
                        isSearchVisible={true}
                        label={label}
                        name={name}
                        placeholder={translate('choisir_une_val_00277')}
                    >
                        {countriesService
                            .getNationalitiesIdName(props.countryCode)
                            .map((idName) => (
                                <option key={idName.id} value={idName.id}>
                                    {idName.name}
                                </option>
                            ))}
                    </RichSelect>
                );
            case FieldType.Address:
                return (
                    <AddressInput
                        addressName={name}
                        autoComplete={isRegisterForm ? 'street-address' : undefined}
                        description={description}
                        label={label}
                        placeName="place"
                    />
                );
            case FieldType.Country:
                return (
                    <RichSelect
                        autoComplete={isRegisterForm ? 'country' : undefined}
                        description={description}
                        isSearchVisible={true}
                        label={label}
                        name={name}
                        placeholder={translate('choisir_une_val_00277')}
                    >
                        {countriesService.getCountriesIdName(props.countryCode).map((idName) => (
                            <option key={idName.id} value={idName.id}>
                                {idName.name}
                            </option>
                        ))}
                    </RichSelect>
                );
            case FieldType.File:
                if (props.field.fieldProperty === FieldProperty.Picture) {
                    return (
                        <ImageInput
                            allowCrop={true}
                            capture="user"
                            hint={description}
                            label={label}
                            organizationId={props.organizationId}
                            prefix={`${name}.`}
                        />
                    );
                } else {
                    return (
                        <FileS3Input
                            accept={[Accept.Images, Accept.Pdf]}
                            acl={props.fileAcl ?? 'private'}
                            description={description}
                            label={label}
                            organizationId={props.organizationId}
                            prefix={`${name}.`}
                        />
                    );
                }
            default:
                return assertUnreachable(props.field.fieldType);
        }
    } else {
        return null;
    }
};
