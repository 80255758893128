import { DetailBox } from 'common-front/src/designSystem/components/detailBox';
import { ProgressGauge } from 'common-front/src/designSystem/components/progressGauge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Maybe } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IFillingRateCardProprs {
    assignedResources: number;
    maxResources?: Maybe<number>;
}

export const FillingRateCard = ({ assignedResources, maxResources }: IFillingRateCardProprs) => {
    const translate = useTranslate();

    return (
        <DetailBox
            css={{
                flex: '1 1'
            }}
            title={translate('limite_de_membr_42978')}
        >
            <Flex css={{ justifyContent: 'space-between' }} direction={'column'} height={'100%'}>
                <Box css={{ margin: 'auto' }}>
                    <ProgressGauge count={assignedResources} total={maxResources ?? Infinity} />
                </Box>
            </Flex>
        </DetailBox>
    );
};
