import { DropdownEllipsis } from 'common-front/src/designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { OrganizationCategoriesDelegationsQuery } from 'common/src/generated/types';
import * as React from 'react';
import { UpdateDelegationCategory } from '../../delegationsCategories/create/createUpdateDelegationCategory';
import { DeleteDelegationCategory } from '../../delegationsCategories/delete/deleteDelegationCategory';

interface IOrganizationCategoriesDelegationRowDropdownProps {
    delegationCategory: OrganizationCategoriesDelegationsQuery['organization']['delegationsCategories']['nodes'][number];

    reload(): void;
}

export const OrganizationCategoriesDelegationRowDropdown = (
    props: IOrganizationCategoriesDelegationRowDropdownProps
) => {
    const { translate } = useHeavent();
    const [isRenameOpen, setIsRenameOpen] = React.useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

    return (
        <>
            <DropdownEllipsis>
                <ItemIcon
                    icon="pen"
                    onClick={() => {
                        setIsRenameOpen(true);
                    }}
                >
                    {translate('renommer_42135')}
                </ItemIcon>

                <ItemIcon
                    color="red"
                    icon="trash-can"
                    onClick={() => {
                        setIsDeleteOpen(true);
                    }}
                >
                    {translate('supprimer_43083')}
                </ItemIcon>
            </DropdownEllipsis>

            {isRenameOpen && (
                <UpdateDelegationCategory
                    delegationCategory={props.delegationCategory}
                    eventId={props.delegationCategory.eventId}
                    organizationId={props.delegationCategory.organizationId}
                    onClose={() => {
                        setIsRenameOpen(false);
                    }}
                    onSuccess={props.reload}
                />
            )}

            {isDeleteOpen && (
                <DeleteDelegationCategory
                    delegationCategory={props.delegationCategory}
                    eventId={props.delegationCategory.eventId}
                    organizationId={props.delegationCategory.organizationId}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                    onSuccess={props.reload}
                />
            )}
        </>
    );
};
