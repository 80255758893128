import { BackButton } from 'common-front/src/components/buttons/backButton';
import { Page } from 'common-front/src/components/page/page';
import { Button } from 'common-front/src/designSystem/components/button';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { AccreditationsCategoryQuery } from 'common/src/generated/types';
import { AccreditationsPaths } from 'common/src/util/paths/accreditationsPaths';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { useAccreditationCategoryDuplicateMutation } from '../../generated/graphqlHooks';
import { UpdateAccreditationCategory } from '../create/createUpdateAccreditationCategory';
import { DeleteAccreditationCategory } from '../delete/deleteAccreditationCategory';

interface IAccreditationsCategoryHeaderProps {
    category: AccreditationsCategoryQuery['event']['accreditationCategory'];

    reload(): void;
}

export const AccreditationsCategoryHeader = ({
    category,
    reload
}: IAccreditationsCategoryHeaderProps) => {
    const {
        history,
        params: { organizationId, eventId },
        translate
    } = useHeavent();
    const { mutate: accreditationCategoryDuplicate } = useAccreditationCategoryDuplicateMutation();
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
    const [isEditModalOpen, setIsEditOpen] = React.useState(false);

    return (
        <Page.Header align="center" gap="3" hideBorder={true}>
            <BackButton
                returnPathFallback={AccreditationsPaths.ACCREDITATIONS({
                    organizationId,
                    eventId
                })}
            />

            <Box css={{ flex: '1' }} font="gray900 displayXs medium">
                {category.name}
            </Box>

            <Button color="white" onClick={() => setIsEditOpen(true)}>
                {translate('_diter_62574')}
            </Button>

            <Button
                onClick={() => {
                    history.push(
                        HeaventPaths.CREATE_ACCREDITATION(organizationId, eventId, category.id)
                    );
                }}
            >
                {translate('add_accreditation')}
            </Button>

            <Dropdown>
                <Trigger>
                    <Button color="white" leftIcon="ellipsis-vertical" />
                </Trigger>

                <Menu placement="bottom-end">
                    <ItemIcon
                        icon="copy"
                        onClick={async () => {
                            const {
                                accreditationCategoryDuplicate: { id: newAccreditationCategoryId }
                            } = await accreditationCategoryDuplicate({
                                eventId,
                                accreditationCategoryId: category.id
                            });

                            history.push(
                                AccreditationsPaths.ACCREDITATION_CATEGORY({
                                    organizationId,
                                    eventId,
                                    accreditationCategoryId: newAccreditationCategoryId
                                })
                            );
                        }}
                    >
                        {translate('dupliquer_28256')}
                    </ItemIcon>

                    <ItemIcon
                        color="red"
                        icon="trash-can"
                        onClick={() => {
                            setIsDeleteOpen(true);
                        }}
                    >
                        {translate('supprimer_43083')}
                    </ItemIcon>
                </Menu>
            </Dropdown>

            {isEditModalOpen && (
                <UpdateAccreditationCategory
                    accreditationCategory={category}
                    eventId={eventId}
                    onClose={() => {
                        setIsEditOpen(false);
                    }}
                    onSuccess={reload}
                />
            )}

            {isDeleteOpen && (
                <DeleteAccreditationCategory
                    accreditationCategory={category}
                    eventId={eventId}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                    onSuccess={() => {
                        history.replace(
                            AccreditationsPaths.ACCREDITATIONS({ organizationId, eventId })
                        );
                    }}
                />
            )}
        </Page.Header>
    );
};
