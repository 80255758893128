import { uniqBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../designSystem/components/box';
import { Flex } from '../../designSystem/components/flex';
import { RichText } from '../../designSystem/components/richEditor/richText';
import {
    AssigmentEmailFragment,
    DocumentUserAssignmentsFragment,
    OrganizationId,
    UserAssignmentsDocumentOptions
} from '../../generated/types';
import { isValidColor } from '../../util/color';
import { useTranslate } from '../../util/dependencies/dependencies';
import { A4_SIZES } from '../../util/pdf';
import { isNonEmptyString } from '../../util/string';
import { getBarcodeHref } from '../../util/url';

interface IUserAssignmentsContainerProps {
    children: React.ReactNode;
    options: UserAssignmentsDocumentOptions;
    organizationId: OrganizationId;
    psuis: AssigmentEmailFragment[];
    ticketId: string;
    userInfo: DocumentUserAssignmentsFragment['organization']['userInfo'];
}

export const UserAssignmentsContainer = (props: IUserAssignmentsContainerProps) => {
    const translate = useTranslate();
    const position = props.psuis[0].position;
    const ui = props.userInfo;
    const isEcoTrail = props.organizationId === 168;
    const isAso = props.organizationId === 858 || props.organizationId === 1;
    const headerColor = isValidColor(props.options.headerColor)
        ? props.options.headerColor
        : 'white';
    const titleStyle = {
        color: isValidColor(props.options.titleColor) ? props.options.titleColor : '$gray800',
        fontFamily: isEcoTrail ? '$moonstruck' : props.options.titleFontFamily || 'Open Sans',
        fontSize: isEcoTrail ? '64px' : '24px',
        fontWeight: isEcoTrail ? '500' : '800',
        lineHeight: isEcoTrail ? '48px' : undefined,
        textAlign: 'center'
    };
    const subtitlesStyle = {
        color: isValidColor(props.options.subtitleColor) ? props.options.subtitleColor : '$gray800',
        fontFamily: props.options.titleFontFamily || 'Open Sans',
        fontSize: '14px',
        fontWeight: '700',
        textTransform: 'uppercase'
    };
    const showLeft =
        props.options.showInformations ||
        props.options.showInformations2 ||
        props.options.showQrCode;
    const leaders = uniqBy(
        position.leaders.concat(position.category.leaders),
        (l) => l.userInfo.id
    );

    return (
        <Flex
            css={{
                color: isValidColor(props.options.textColor) ? props.options.textColor : '$gray800',
                background: isNonEmptyString(props.options.background?.content)
                    ? `url(${props.options.background?.content}) no-repeat`
                    : '$gray900',
                backgroundSize: 'cover',
                fontFamily: props.options.fontFamily || 'Open Sans',
                fontSize: '12px',
                pageBreakBefore: 'always',
                position: 'relative'
            }}
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
        >
            <Flex
                align="center"
                css={{
                    gap: '2px',
                    left: '210px',
                    position: 'absolute',
                    top: '51px'
                }}
                direction="column"
                width={568}
            >
                <Box
                    css={{
                        color: headerColor,
                        fontSize: '14px',
                        fontWeight: '700',
                        lineHeight: '18px'
                    }}
                >
                    {isNonEmptyString(props.options.title)
                        ? props.options.title
                        : translate('Assignments')}
                </Box>

                <Box
                    css={{
                        color: headerColor,
                        fontSize: '28px',
                        fontWeight: '700',
                        lineHeight: '38px'
                    }}
                >
                    {ui.name}
                </Box>
            </Flex>

            <Flex
                css={{
                    paddingLeft: isAso ? '46px' : '$4',
                    paddingRight: '$4',
                    position: 'absolute',
                    top: '132px'
                }}
                gap="4"
                height={971}
                width={1}
            >
                {showLeft && (
                    <Flex
                        css={{ paddingTop: isAso ? '180px' : '73px', position: 'relative' }}
                        direction="column"
                        gap="5"
                        width={isAso ? 157 : 178}
                    >
                        {props.options.showInformations && (
                            <Flex
                                css={{
                                    background: isAso ? undefined : 'white',
                                    borderRadius: '$2',
                                    px: isAso ? '0' : '$3',
                                    py: '$4'
                                }}
                                direction="column"
                                gap={isAso ? '1' : '3'}
                                width={1}
                            >
                                <Box
                                    css={{
                                        ...subtitlesStyle,
                                        textAlign: 'center'
                                    }}
                                    width={1}
                                >
                                    {isNonEmptyString(props.options.informationsTitle)
                                        ? props.options.informationsTitle
                                        : translate('informations_22954')}
                                </Box>

                                {isNonEmptyString(props.options.informationsContent) && (
                                    <>
                                        <Box
                                            css={{
                                                '& .h-rich-text > p': {
                                                    margin: '0'
                                                }
                                            }}
                                        >
                                            <RichText text={props.options.informationsContent} />
                                        </Box>
                                    </>
                                )}
                            </Flex>
                        )}

                        {props.options.showInformations2 && (
                            <Flex
                                css={{
                                    background: 'white',
                                    borderRadius: '$2',
                                    padding: '$4 $3'
                                }}
                                direction="column"
                                gap="3"
                                width={1}
                            >
                                <Box
                                    css={{
                                        ...subtitlesStyle,
                                        textAlign: 'center'
                                    }}
                                    width={1}
                                >
                                    {isNonEmptyString(props.options.informationsTitle2)
                                        ? props.options.informationsTitle2
                                        : translate('informations_se_69499')}
                                </Box>

                                {isNonEmptyString(props.options.informationsContent2) && (
                                    <>
                                        <Box
                                            css={{
                                                '& .h-rich-text > p': {
                                                    margin: '0'
                                                }
                                            }}
                                        >
                                            <RichText text={props.options.informationsContent2} />
                                        </Box>
                                    </>
                                )}
                            </Flex>
                        )}

                        {props.options.showQrCode && (
                            <Flex
                                align="center"
                                css={{
                                    background: 'white',
                                    borderRadius: '$2',
                                    padding: '$4 $3'
                                }}
                                direction="column"
                                gap="3"
                                width={1}
                            >
                                <Box
                                    css={{
                                        ...subtitlesStyle,
                                        textAlign: 'center'
                                    }}
                                    width={1}
                                >
                                    QR Code
                                </Box>

                                <Box height={104} width={104}>
                                    <img
                                        height="100%"
                                        src={getBarcodeHref(props.ticketId, {
                                            height: 90,
                                            width: 90
                                        })}
                                        width="100%"
                                    />
                                </Box>
                            </Flex>
                        )}

                        {isAso && props.options.showLeaders && leaders[0] && (
                            <Box
                                css={{
                                    position: 'absolute',
                                    top: '450px'
                                }}
                                width={1}
                            >
                                {leaders[0].userInfo.name}
                                <br />
                                {leaders[0].userInfo.phone?.internationalFormat}
                            </Box>
                        )}
                    </Flex>
                )}

                <Flex
                    css={{
                        background: isAso ? undefined : 'white',
                        borderRadius: '$3',
                        flex: '1',
                        fontSize: '12px',
                        padding: '32px 40px'
                    }}
                    direction="column"
                    height={1}
                >
                    <Box css={titleStyle} width={1}>
                        {position.name}
                    </Box>

                    {props.children}
                </Flex>
            </Flex>
        </Flex>
    );
};
