import { Page } from 'common-front/src/components/page/page';
import { KpiCard } from 'common-front/src/designSystem/components/kpiCard';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { DelegationCategoryQuery } from 'common/src/generated/types';
import * as React from 'react';
import { DelegationCategoryDelegations } from '../delegations/delegationCategoryDelegations';

interface IDelegationCategoryDashboardProps {
    delegationCategory: DelegationCategoryQuery['organization']['delegationCategory'];
}

export const DelegationCategoryDashboard = (props: IDelegationCategoryDashboardProps) => {
    const { translate } = useHeavent();

    return (
        <Page.Content>
            <Flex direction="column" gap="6">
                <Flex gap="6" width={1}>
                    <KpiCard
                        text={translate('nombre_de_d_l_g_45708')}
                        value={props.delegationCategory.numberOfDelegations}
                    />

                    <KpiCard
                        text={translate('number_of_membe_24698')}
                        value={props.delegationCategory.numberOfResources}
                    />
                </Flex>

                <Flex>
                    <Box css={{ flex: '1' }}>
                        <DelegationCategoryDelegations />
                    </Box>
                </Flex>
            </Flex>
        </Page.Content>
    );
};
