import { CSS } from '@stitches/react';
import { Cell } from 'common/src/designSystem/components/table/cell';
import * as React from 'react';
import { useRichTableContext } from '../../richTable/richTableContext';
import { useSegmentsContext } from '../../segments/segmentsContext';
import { UpdateCellDropdown } from './updateCellDropdown';

interface IUpdateCellProps<T> {
    cellCss?: CSS;
    initialValue: T;
    updatable: boolean;

    mutate(value: T): Promise<any>;
    reload(): void;
    renderInput(value: T, setValue: (v: T) => void): React.ReactNode;
    renderValue: string | (() => React.ReactNode);
}

export const UpdateCell = <T extends {}>(props: IUpdateCellProps<T>) => {
    // TODO: temporary solution
    // TODO: use only useRichTableContext() once refacto is done
    const { isEditMode: segmentsIsEditMode } = useSegmentsContext();
    const { isEditMode: richTableIsEditMode } = useRichTableContext();
    const isEditMode = segmentsIsEditMode || richTableIsEditMode;
    const onSave = async (value: T) => {
        await props.mutate(value);

        props.reload();
    };

    if (isEditMode && props.updatable) {
        return (
            <UpdateCellDropdown
                css={props.cellCss}
                initialValue={props.initialValue}
                renderInput={props.renderInput}
                renderValue={props.renderValue}
                onSave={onSave}
            />
        );
    } else {
        return (
            <Cell
                css={{
                    ...props.cellCss,
                    border: '2px solid transparent'
                }}
            >
                {typeof props.renderValue === 'string' ? props.renderValue : props.renderValue()}
            </Cell>
        );
    }
};
