import { Flex } from 'common/src/designSystem/components/flex';
import { preventDefault } from 'common/src/util/links';
import { UUID } from 'common/src/util/uuid';
import { DocumentContainerElement } from 'common/src/vo/documentBuilder';
import * as React from 'react';
import { useDocumentBuilderContext } from '../documentBuilderContext';
import { DocumentBuilderElement } from './documentBuilderElement';

interface IDocumentBuilderContainerProps {
    container: DocumentContainerElement;
    pageId: UUID;
}

export const DocumentBuilderContainer = (props: IDocumentBuilderContainerProps) => {
    const { selectedElementId, selectElement } = useDocumentBuilderContext();

    return (
        <Flex
            align={props.container.align}
            css={{
                backgroundColor: props.container.hasBackground
                    ? props.container.backgroundColor
                    : 'none',
                border:
                    props.container.borderSize > 0
                        ? `${props.container.borderSize}px solid ${props.container.borderColor}`
                        : '',
                borderRadius:
                    props.container.borderRadius > 0 ? `${props.container.borderRadius}px` : '0',
                gap: `${props.container.gap}px`,
                left: `${props.container.left}px`,
                outline: selectedElementId === props.container.id ? '2px solid $primary700' : '',
                overflow: 'hidden',
                paddingBottom: `${props.container.py || 0}px`,
                paddingLeft: `${props.container.px || 0}px`,
                paddingRight: `${props.container.px || 0}px`,
                paddingTop: `${props.container.py || 0}px`,
                position: props.container.inContainer ? 'static' : 'absolute',
                top: `${props.container.top}px`,
                zIndex: props.container.zIndex
            }}
            direction={props.container.direction}
            height={props.container.height}
            justify={props.container.justify}
            width={props.container.width}
            onClick={(e) => {
                preventDefault(e);

                selectElement(props.container.id, props.pageId);
            }}
        >
            {props.container.elements.map((element) => (
                <DocumentBuilderElement key={element.id} element={element} pageId={props.pageId} />
            ))}
        </Flex>
    );
};
