import { Separator } from 'common-front/src/designSystem/components/separator';
import { useMobileQuery } from 'common-front/src/hooks/useMobileQuery';
import { UserPosition } from 'common-front/src/positions/userPosition';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    EventId,
    OrganizationId,
    PositionId,
    PositionsCategoryId,
    UserInfoMobileQuery,
    VolunteerRegistrationState
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { DurationService } from 'common/src/services/durationService';
import { IntervalService } from 'common/src/services/intervalService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import { sortBy } from 'lodash-es';
import { Interval } from 'luxon';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import { TitleBox } from '../titleBox';
import { RegistrationInfo } from './registrationInfo';

interface IRegistrationProps {
    organizationId: OrganizationId;
    eventId: EventId;
    volunteerRegistration: UserInfoMobileQuery['event']['volunteerRegistration'];

    reload(): void;
}

export const Registration = (props: IRegistrationProps) => {
    const translate = useTranslate();
    const { isPositionAdmin, isPositionViewer } = useEventContext();
    const dateTimeService = useService(DateTimeService);
    const durationService = useService(DurationService);
    const intervalService = useService(IntervalService);
    const onMobile = useMobileQuery();
    const assignments = sortBy(props.volunteerRegistration.positionsSlotsUsersInfos, (psui) =>
        psui.positionSlot.range.start!.toMillis()
    );
    const preAssigned = sortBy(
        props.volunteerRegistration.preassignPositions
            .map(({ id, name, range }) => ({
                id: id as number,
                name,
                range: range as Interval | null | undefined,
                isPosition: true
            }))
            .concat(
                props.volunteerRegistration.preassignPositionsCategories.map(
                    ({ id, name, range }) => ({
                        id: id as number,
                        name,
                        range,
                        isPosition: false
                    })
                )
            ),
        (p) => p.range?.start!.toMillis() ?? 0
    );

    return (
        <Flex direction="column">
            <Spacer height="6" />

            {isNonEmptyArray(assignments) && (
                <>
                    <TitleBox>{translate('affectation_plural')}</TitleBox>

                    <Spacer height="3" />

                    {assignments.map((positionSlotUserInfo, index) => (
                        <React.Fragment key={index}>
                            <UserPosition
                                canCheckIn={isPositionViewer(
                                    positionSlotUserInfo.positionCategoryId,
                                    positionSlotUserInfo.position.id
                                )}
                                canDelete={isPositionAdmin()}
                                positionSlotUserInfo={positionSlotUserInfo}
                                reloadCheckIn={props.reload}
                                onDelete={props.reload}
                            />

                            <Spacer height={onMobile ? 2 : 3} />
                        </React.Fragment>
                    ))}

                    <Spacer height="3" />

                    {onMobile === false && <Separator direction="horizontal" />}

                    <Spacer height={onMobile ? 5 : 6} />
                </>
            )}

            {isNonEmptyArray(preAssigned) && (
                <>
                    <TitleBox>{translate('missions_et_cat_88954')}</TitleBox>

                    <Spacer height="3" />

                    {preAssigned.map((position, index) => (
                        <RegistrationInfo
                            key={index}
                            path={
                                position.isPosition
                                    ? PositionsPaths.POSITION({
                                          organizationId: props.organizationId,
                                          eventId: props.eventId,
                                          positionId: position.id as PositionId
                                      })
                                    : PositionsPaths.POSITION_CATEGORY({
                                          organizationId: props.organizationId,
                                          eventId: props.eventId,
                                          positionCategoryId: position.id as PositionsCategoryId
                                      })
                            }
                            subtext={
                                position.range
                                    ? intervalService.toDisplayString(position.range)
                                    : '-'
                            }
                            text={position.name}
                        />
                    ))}

                    {onMobile === false && <Separator direction="horizontal" />}

                    <Spacer height={onMobile ? 5 : 6} />
                </>
            )}

            {(isNonEmptyArray(props.volunteerRegistration.positionsCategories) ||
                isNonEmptyArray(props.volunteerRegistration.positions) ||
                isNonEmptyArray(props.volunteerRegistration.positionsSlots)) && (
                <>
                    <TitleBox>{translate('missions_souhai_23225')}</TitleBox>

                    <Spacer height="3" />

                    {sortBy(
                        props.volunteerRegistration.positionsCategories,
                        (c) => c.range?.start?.toMillis() ?? 0
                    ).map((category, index) => (
                        <RegistrationInfo
                            key={index}
                            path={PositionsPaths.POSITION_CATEGORY({
                                organizationId: props.organizationId,
                                eventId: props.eventId,
                                positionCategoryId: category.id
                            })}
                            subtext={
                                category.range
                                    ? intervalService.toDisplayString(category.range)
                                    : '-'
                            }
                            text={category.name}
                        />
                    ))}

                    {sortBy(
                        props.volunteerRegistration.positions,
                        (p) => p.range?.start?.toMillis() ?? 0
                    ).map((position, index) => (
                        <RegistrationInfo
                            key={index}
                            path={PositionsPaths.POSITION({
                                organizationId: props.organizationId,
                                eventId: props.eventId,
                                positionId: position.id
                            })}
                            subtext={
                                position.range
                                    ? intervalService.toDisplayString(position.range)
                                    : '-'
                            }
                            text={position.name}
                        />
                    ))}

                    {sortBy(props.volunteerRegistration.positionsSlots, (s) =>
                        s.range.start!.toMillis()
                    ).map((slot, index) => (
                        <RegistrationInfo
                            key={index}
                            path={PositionsPaths.POSITION({
                                organizationId: props.organizationId,
                                eventId: props.eventId,
                                positionId: slot.position.id
                            })}
                            subtext={slot.nameOrRange}
                            text={slot.position.name}
                        />
                    ))}

                    {onMobile === false && <Separator direction="horizontal" />}

                    <Spacer height={onMobile ? 5 : 6} />
                </>
            )}

            {isNonEmptyArray(props.volunteerRegistration.slots) && (
                <>
                    <TitleBox>{translate('disponibilit_s_49923')}</TitleBox>

                    <Spacer height="3" />

                    {props.volunteerRegistration.slots.map((slot, index) => (
                        <RegistrationInfo
                            key={index}
                            subtext={intervalService.toDisplayString(slot.range)}
                            text={translate('disponibilit_n_50484', index + 1)}
                        />
                    ))}

                    {onMobile === false && <Separator direction="horizontal" />}

                    <Spacer height={onMobile ? 5 : 6} />
                </>
            )}

            {props.volunteerRegistration.state === VolunteerRegistrationState.Assigned && (
                <>
                    <TitleBox>{translate('statistiques_98073')}</TitleBox>

                    <Spacer height="3" />

                    <RegistrationInfo
                        subtext={durationService.minutesToHoursMinutesString(
                            props.volunteerRegistration.volunteerMinutes
                        )}
                        text={translate('nombre_d_heures_36999')}
                    />

                    {onMobile === false && <Separator direction="horizontal" />}

                    <Spacer height={onMobile ? 5 : 6} />
                </>
            )}

            <TitleBox>{translate('inscription_48144')}</TitleBox>

            <Spacer height="3" />

            <RegistrationInfo
                subtext={dateTimeService.toLocaleString(
                    props.volunteerRegistration.insertedAt.toLocal(),
                    LocaleFormats.DateTime
                )}
                text={translate('date_d_inscript_31369')}
            />
        </Flex>
    );
};
