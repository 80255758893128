import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { DropdownEllipsis } from 'common-front/src/designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { usePositionsSlotsUsersInfosCheckInMutation } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    CheckInStrategy,
    Event,
    PositionSlotsQuery,
    PositionsSlotInput
} from 'common/src/generated/types';
import { PositionPermission } from 'common/src/graphql/permissions/dsl';
import { PositionsSlotInputService } from 'common/src/input/positionsSlotInput';
import { IntervalService } from 'common/src/services/intervalService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import {
    usePositionSlotDeleteMutation,
    usePositionSlotUpdateMutation
} from '../../../generated/graphqlHooks';
import { CreatePositionCreateSlotModal } from '../../create/createPositionCreateSlotModal';
import { PositionRowResources } from '../../list/list/positionRowResources';

interface IPositionSlotsSlotRowProps {
    event: Pick<Event, 'startAt' | 'endAt'>;
    slot: PositionSlotsQuery['event']['positionsSlots']['nodes'][0];

    reload(): void;
}

export const PositionSlotsSlotRow = (props: IPositionSlotsSlotRowProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const intervalService = useService(IntervalService);
    const { hasPositionPermissions } = useEventContext();
    const positionsSlotInput = useService(PositionsSlotInputService);
    const { mutate: positionSlotUpdate } = usePositionSlotUpdateMutation();
    const { mutate: checkIn } = usePositionsSlotsUsersInfosCheckInMutation();
    const { mutate: positionSlotDelete } = usePositionSlotDeleteMutation();
    const [isEditOpen, setIsEditOpen] = React.useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const rangeString = React.useMemo(
        () =>
            intervalService.toDisplayString(props.slot.range, {
                formats: { displayWeekday: true }
            }),
        [props.slot]
    );
    const [canEdit, canAssign] = hasPositionPermissions(
        [PositionPermission.EDIT_POSITION, PositionPermission.ASSIGN_MEMBER],
        { positionCategoryId: props.slot.positionCategoryId, positionId: props.slot.positionId }
    );

    return (
        <>
            <Row
                css={{
                    cursor: 'pointer'
                }}
                onClick={() => {
                    history.push(
                        HeaventPaths.POSITION_SLOT_MEMBERS(
                            organizationId,
                            eventId,
                            props.slot.positionId,
                            props.slot.id
                        )
                    );
                }}
            >
                <Cell align="start" direction="column" justify="center">
                    <Box font="gray900 textSm medium">
                        {isNonEmptyString(props.slot.name) ? props.slot.name : '-'}
                    </Box>
                </Cell>

                <Cell css={{ textTransform: 'capitalize' }}>{rangeString}</Cell>

                <Cell>
                    <PositionRowResources
                        assignedResources={props.slot.assignedResources}
                        resources={props.slot.resources}
                    />
                </Cell>

                <CellControls justify="end">
                    <DropdownEllipsis>
                        {canAssign && (
                            <ItemIcon
                                icon="wand-magic-sparkles"
                                to={HeaventPaths.POSITION_SLOT_ASSIGN_VOLUNTEERS(
                                    organizationId,
                                    eventId,
                                    props.slot.positionId,
                                    props.slot.id
                                )}
                            >
                                {translate('affecter_des_me_30349')}
                            </ItemIcon>
                        )}

                        {canEdit && (
                            <ItemIcon
                                icon="pen"
                                onClick={() => {
                                    setIsEditOpen(true);
                                }}
                            >
                                {translate('_diter_62574')}
                            </ItemIcon>
                        )}

                        {canEdit && (
                            <ItemIcon
                                color={props.slot.assignedResources > 0 ? 'disabled' : 'red'}
                                icon="trash-can"
                                onClick={() => {
                                    setIsDeleteModalOpen(true);
                                }}
                            >
                                {translate('supprimer_43083')}
                            </ItemIcon>
                        )}

                        <ItemIcon
                            icon="clock"
                            onClick={async () => {
                                await checkIn({
                                    eventId,
                                    checkIn: {
                                        strategy: CheckInStrategy.CheckIn,
                                        positionsSlotsIds: [props.slot.id]
                                    }
                                });
                            }}
                        >
                            {translate('pointer_les_mem_50347')}
                        </ItemIcon>

                        <ItemIcon
                            icon="clock"
                            onClick={async () => {
                                await checkIn({
                                    eventId,
                                    checkIn: {
                                        strategy: CheckInStrategy.CheckOut,
                                        positionsSlotsIds: [props.slot.id]
                                    }
                                });
                            }}
                        >
                            {translate('d_pointer_les_m_17150')}
                        </ItemIcon>
                    </DropdownEllipsis>
                </CellControls>
            </Row>

            {isEditOpen && (
                <CreatePositionCreateSlotModal
                    event={props.event}
                    slot={positionsSlotInput.positionsSlotInputDefault(
                        props.event.startAt,
                        props.slot
                    )}
                    onClose={() => {
                        setIsEditOpen(false);
                    }}
                    onSuccess={async (updatedSlot: PositionsSlotInput) => {
                        await positionSlotUpdate({
                            eventId,
                            positionId: props.slot.positionId,
                            positionSlotId: props.slot.id,
                            positionSlot: updatedSlot
                        });

                        props.reload();
                    }}
                />
            )}

            {isDeleteModalOpen && (
                <DeleteModal
                    buttonText={translate('supprimer_43083')}
                    subtext={[translate('_tes_vous_s_r_d_48876')]}
                    text={translate('suppression_de_73669', rangeString)}
                    onClose={() => {
                        setIsDeleteModalOpen(false);
                    }}
                    onDelete={async () => {
                        await positionSlotDelete({
                            eventId,
                            positionId: props.slot.positionId,
                            positionSlotId: props.slot.id
                        });

                        props.reload();
                    }}
                />
            )}
        </>
    );
};
