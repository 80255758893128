import { Cell } from 'common/src/designSystem/components/table/cell';
import { VolunteerRegistrationFragment } from 'common/src/generated/types';
import { getWishedAccreditationsBadges } from 'common/src/vo/accreditation';
import * as React from 'react';
import { BadgesPlus } from '../../../components/badgesPlus/badgesPlus';

interface IVolunteerRegistrationWishedAccreditationsProps {
    volunteerRegistration: VolunteerRegistrationFragment;

    onClick?(): void;
}

export const VolunteerRegistrationWishedAccreditations = ({
    onClick,
    volunteerRegistration
}: IVolunteerRegistrationWishedAccreditationsProps) => {
    const badges = React.useMemo(
        () => getWishedAccreditationsBadges(volunteerRegistration?.accreditationsSlots || []),
        [volunteerRegistration]
    );

    return (
        <Cell>
            <BadgesPlus badges={badges} doNotSort={true} onClick={onClick} />
        </Cell>
    );
};
