import { Button } from 'common-front/src/designSystem/components/button';
import { useVolunteersRegistrationsMassAssignMutation } from 'common-front/src/generated/graphqlHooks';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    EventId,
    MassAssignStrategy,
    UserPositionsAvailablePositionsSlotsQuery,
    UsersInfoId
} from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface IUserPositionsAvailablePositionsSlotsRowProps {
    eventId: EventId;
    slot: UserPositionsAvailablePositionsSlotsQuery['event']['volunteerRegistration']['availablePositionsSlots'][0];
    userInfoId: UsersInfoId;

    reload(): void;
}

export const UserPositionsAvailablePositionsSlotsRow = (
    props: IUserPositionsAvailablePositionsSlotsRowProps
) => {
    const translate = useTranslate();
    const intervalService = useService(IntervalService);
    const { mutate, isLoading } = useVolunteersRegistrationsMassAssignMutation();
    const slotName = isNonEmptyString(props.slot.name)
        ? `${props.slot.name} - ${intervalService.toDisplayString(props.slot.range)}`
        : intervalService.toDisplayString(props.slot.range);

    return (
        <Row>
            <Cell>{props.slot.position.name}</Cell>
            <Cell>{slotName}</Cell>
            <Cell justify="end" width={200}>
                <Button
                    color="white"
                    isLoading={isLoading}
                    size="sm"
                    onClick={async () => {
                        await mutate({
                            eventId: props.eventId,
                            massAssign: {
                                strategy: MassAssignStrategy.Add,
                                selecteds: {
                                    ids: [props.userInfoId]
                                },
                                positionsSlotsIds: [props.slot.id]
                            }
                        });

                        props.reload();
                    }}
                >
                    {translate('affecter_94255')}
                </Button>
            </Cell>
        </Row>
    );
};
