import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { FormElementsElementFragment } from 'common/src/generated/types';
import { FormsPaths } from 'common/src/util/paths/formsPaths';
import * as React from 'react';
import { NotSavedModal } from '../../../documentBuilder/notSavedModal';
import {
    useFormElementsQuery,
    useFormElementsUpdateMutation
} from '../../../generated/graphqlHooks';
import { FormContainer } from '../../show/formContainer';
import { FormElementsContent } from './formElementsContent';
import { FormElementsSkeleton } from './formElementsSkeleton';

export const FormElements = () => {
    const {
        history,
        translate,
        params: { organizationId, eventId, formId }
    } = useHeavent();
    const { data, isLoading } = useFormElementsQuery({ organizationId, formId });
    useTitle(data.organization?.form.name ?? '');
    const { mutate, isLoading: isFormElementsUpdateLoading } = useFormElementsUpdateMutation();
    const [elements, setElements] = React.useState<FormElementsElementFragment[]>([]);
    const [initialElementsString, setInitialElementsString] = React.useState<string>('');
    const onSave = React.useCallback(async () => {
        await mutate({
            organizationId,
            eventId,
            formId,
            elements: elements.map((element, index) => ({
                id: element.id,
                elementType: element.elementType,
                customFieldId: element.customFieldId,
                section: element.section,
                text: element.text,
                isMandatory: element.isMandatory,
                index
            }))
        });

        setInitialElementsString(JSON.stringify(elements));
    }, [mutate, elements]);
    const [isNotSavedModalOpen, setIsNotSavedModalOpen] = React.useState(false);
    const onClose = React.useCallback(() => {
        const elementsString = JSON.stringify(elements);

        if (elementsString === initialElementsString) {
            history.goBack(
                FormsPaths.FORMS({
                    organizationId,
                    eventId
                })
            );
        } else {
            setIsNotSavedModalOpen(true);
        }
    }, [elements, initialElementsString]);

    React.useEffect(() => {
        setElements(data.organization?.form.elements ?? []);
        setInitialElementsString(JSON.stringify(data.organization?.form.elements ?? []));
    }, [data.organization]);

    return (
        <>
            <FormContainer
                button={
                    <Button isLoading={isFormElementsUpdateLoading} onClick={onSave}>
                        {translate('enregistrer_06519')}
                    </Button>
                }
                title={data.organization?.form.name ?? '-'}
                onClose={onClose}
            >
                <Flex justify="center" width={1}>
                    <Flex direction="column" width="$container">
                        <Spacer height="8" />

                        {isLoading ? (
                            <FormElementsSkeleton />
                        ) : (
                            <FormElementsContent
                                customFields={data.organization.customFields.nodes}
                                elements={elements}
                                setElements={setElements}
                                onSave={onSave}
                            />
                        )}

                        <Spacer height="8" />
                    </Flex>
                </Flex>
            </FormContainer>

            <NotSavedModal
                fallbackPath={FormsPaths.FORMS({
                    organizationId,
                    eventId
                })}
                isOpen={isNotSavedModalOpen}
                onClose={() => {
                    setIsNotSavedModalOpen(false);
                }}
            />
        </>
    );
};
