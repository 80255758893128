import * as React from 'react';
import { Link as _Link, LinkProps } from 'react-router-dom';
import { convertToPercentOrPx } from '../util/unit';

export interface ILinkProps extends LinkProps {
    children: React.ReactNode;
    height?: number | string;
    width?: number | string;
}

export const Link = ({ children, height, replace, style, to, width, ...props }: ILinkProps) => {
    // The `base` param is overridden if the `url` param is a full href (e.g., when we link
    // from the main app to the public app)
    const url = new URL(to.toString(), location.origin);

    const css: any = {};

    if (height) {
        css.height = convertToPercentOrPx(height);
    }

    if (width) {
        css.width = convertToPercentOrPx(width);
    }

    const finalStyle = {
        display: 'inherit',
        justifyContent: 'inherit',
        alignItems: 'inherit',
        color: 'inherit',
        flexDirection: 'inherit',
        gap: 'inherit',
        flexWrap: 'inherit',

        cursor: 'pointer',
        textDecoration: 'none',
        ...css,
        ...style
    };

    return url.origin !== location.origin ? (
        <a href={url.href} rel="noreferrer" style={finalStyle} target="_blank" {...props}>
            {children}
        </a>
    ) : (
        <_Link
            replace={replace}
            style={finalStyle}
            to={{ pathname: url.pathname, hash: url.hash, search: url.search }}
            {...props}
        >
            {children}
        </_Link>
    );
};
Link.displayName = 'LinkWrapper';
