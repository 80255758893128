import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Cell } from 'common/src/designSystem/components/table/cell';
import {
    AccreditationDisplay,
    AccreditationsSlotId,
    AccreditationState,
    EventId,
    MassAssignStrategy,
    UpdateCellAccreditationsSlotsQuery,
    VolunteerRegistrationFragment
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { getAccreditationsBadges } from 'common/src/vo/accreditation';
import { fullName } from 'common/src/vo/accreditationSlot';
import * as React from 'react';
import { BadgesPlus } from '../../../components/badgesPlus/badgesPlus';
import { UpdateCellDropdown } from '../../../components/cells/updateCellDropdown';
import { RichSelect } from '../../../designSystem/components/richSelect/richSelect';
import {
    executeUpdateCellAccreditationsSlotsQuery,
    useVolunteersRegistrationsMassAccreditationStateUpdateMutation,
    useVolunteersRegistrationsMassAccreditMutation
} from '../../../generated/graphqlHooks';
import { useRichTableContext } from '../../../richTable/richTableContext';
import { useSegmentsContext } from '../../../segments/segmentsContext';
import { getToken } from '../../../util/aws/cognito';

interface IVolunteerRegistrationAccreditationsProps {
    eventId: EventId;
    updatable: boolean;
    volunteerRegistration: VolunteerRegistrationFragment;

    onClick?(): void;
    reload(): void;
}

export const VolunteerRegistrationAccreditations = ({
    eventId,
    onClick,
    reload,
    updatable,
    volunteerRegistration
}: IVolunteerRegistrationAccreditationsProps) => {
    const translate = useTranslate();
    const { mutate: massAccredit } = useVolunteersRegistrationsMassAccreditMutation();
    const { mutate: stateUpdate } =
        useVolunteersRegistrationsMassAccreditationStateUpdateMutation();
    const dateTimeService = useService(DateTimeService);
    // TODO: temporary solution
    // TODO: use only useRichTableContext() once refacto is done
    const { isEditMode: segmentsIsEditMode } = useSegmentsContext();
    const { isEditMode: richTableIsEditMode } = useRichTableContext();
    const isEditMode = segmentsIsEditMode || richTableIsEditMode;
    const [isFirstTime, setIsFirstTime] = React.useState(true);
    const [accreditations, setAccreditations] = React.useState<
        UpdateCellAccreditationsSlotsQuery['event']['accreditations']['nodes']
    >([]);
    const auis = React.useMemo(
        () => volunteerRegistration?.accreditationsUsersInfos || [],
        [volunteerRegistration]
    );
    const badges = React.useMemo(() => getAccreditationsBadges(auis), [auis]);
    const initialValue = React.useMemo(() => auis.map((aui) => aui.accreditationSlot.id), [auis]);
    const onStateChange = React.useCallback(
        async (isOpen) => {
            if (isOpen && isFirstTime) {
                const { event } = await executeUpdateCellAccreditationsSlotsQuery(
                    { eventId: eventId },
                    await getToken()
                );
                const finalAccreditations = event.accreditations.nodes.map((accreditation) => ({
                    ...accreditation,
                    slots: (accreditation.hasSlots
                        ? accreditation.slots
                        : [accreditation.hiddenSlot]
                    ).map((slot) => ({
                        id: slot.id,
                        name: fullName(dateTimeService, slot, accreditation.name, {
                            accreditation: { includeNameAtEnd: true }
                        })
                    }))
                }));

                setAccreditations(finalAccreditations);
                setIsFirstTime(false);
            }
        },
        [isFirstTime]
    );
    const onSave = React.useCallback(
        async (value: AccreditationsSlotId[]) => {
            if (isNonEmptyArray(value)) {
                await massAccredit({
                    eventId,
                    massAccredit: {
                        accreditationsSlotsIds: value,
                        selecteds: { ids: [volunteerRegistration.userInfo.id] },
                        strategy: MassAssignStrategy.Replace
                    }
                });
            } else {
                await stateUpdate({
                    eventId,
                    massStateUpdate: {
                        selecteds: { ids: [volunteerRegistration.userInfo.id] },
                        state: AccreditationState.WaitingAccreditation
                    }
                });
            }

            reload();
        },
        [eventId, volunteerRegistration, massAccredit, reload]
    );

    if (isEditMode && updatable) {
        return (
            <UpdateCellDropdown
                css={{ gap: '$2' }}
                initialValue={initialValue}
                renderInput={(value, setValue) =>
                    isFirstTime ? (
                        <Flex direction="column" gap="1" width={1}>
                            <Skeleton borderRadius="$1" height={20} width={1} />
                            <Skeleton borderRadius="$1" height={40} width={1} />
                        </Flex>
                    ) : (
                        <RichSelect
                            isSearchVisible={true}
                            label={translate('accr_ditations_39450')}
                            multiple={true}
                            renderOnPortal={true}
                            values={value}
                            onChange={setValue}
                        >
                            {accreditations.map((accreditation, index) => {
                                const dataRadio =
                                    accreditation.accreditationDisplay ===
                                    AccreditationDisplay.Radio
                                        ? accreditation.name
                                        : undefined;

                                return (
                                    <optgroup key={index} label={accreditation.name}>
                                        {accreditation.slots.map((slot) => (
                                            <option
                                                key={slot.id}
                                                data-radio={dataRadio}
                                                value={slot.id}
                                            >
                                                {slot.name}
                                            </option>
                                        ))}
                                    </optgroup>
                                );
                            })}
                        </RichSelect>
                    )
                }
                renderValue={() => <BadgesPlus badges={badges} doNotSort={true} />}
                onSave={onSave}
                onStateChange={onStateChange}
            />
        );
    } else {
        return (
            <Cell css={{ border: '2px solid transparent' }}>
                <BadgesPlus badges={badges} doNotSort={true} onClick={onClick} />
            </Cell>
        );
    }
};
