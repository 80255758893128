import { Button } from 'common-front/src/designSystem/components/button';
import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';

interface IEventMenuTogglerProps {
    onToggle(): void;
}

export const EventMenuToggler = (props: IEventMenuTogglerProps) => (
    <Flex align="center" css={{ px: '$4' }} height={48} justify="end">
        <Button
            color="white"
            css={{ border: 'none' }}
            leftIcon="sidebar"
            size="sm"
            onClick={props.onToggle}
        />
    </Flex>
);
