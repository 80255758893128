import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import { CampaignType, UserHistoryCampaignsQuery } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { getStateBadgeColorIcon as emailBagde } from 'common/src/vo/email';
import { getStateBadgeColorIcon as textMessageBadge } from 'common/src/vo/textMessage';
import { DateTime } from 'luxon';
import * as React from 'react';

interface IUserHistoryCampaignRowProps {
    campaign: UserHistoryCampaignsQuery['organization']['userInfo']['campaigns']['nodes'][number];
}

export const UserHistoryCampaignRow = (props: IUserHistoryCampaignRowProps) => {
    const { history, translate } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const sentAt = props.campaign.sentAt?.setZone(DateTime.local().zone);
    const emailState = React.useMemo(
        () =>
            props.campaign.campaignType === CampaignType.Email && props.campaign.emails.nodes[0]
                ? props.campaign.emails.nodes[0].state
                : null,
        [props.campaign]
    );
    const textMessageState = React.useMemo(
        () =>
            props.campaign.campaignType === CampaignType.Sms && props.campaign.textMessages.nodes[0]
                ? props.campaign.textMessages.nodes[0].state
                : null,
        [props.campaign]
    );

    return (
        <Row
            css={{
                cursor: 'pointer',
                '&:hover': {
                    background: '$gray100'
                }
            }}
            onClick={() => {
                if (props.campaign.eventId) {
                    history.push(
                        HeaventPaths.CAMPAIGN(
                            props.campaign.organizationId,
                            props.campaign.eventId,
                            props.campaign.id
                        )
                    );
                } else {
                    history.push(
                        HeaventPaths.COMMUNITY_CAMPAIGN(
                            props.campaign.organizationId,
                            props.campaign.id
                        )
                    );
                }
            }}
        >
            <Cell>
                <Flex direction="column" width={1}>
                    <Box color="gray800">{props.campaign.name}</Box>

                    {sentAt && (
                        <Box color="gray500">
                            {translate(
                                'envoy_e_le_1_31494',
                                dateTimeService.toLocaleString(
                                    sentAt,
                                    LocaleFormats.DateOnly.MonthLong
                                ),
                                dateTimeService.toLocaleString(sentAt, LocaleFormats.TimeOnly)
                            )}
                        </Box>
                    )}
                </Flex>
            </Cell>
            <Cell width={150}>
                <Box width={22}>
                    {props.campaign.campaignType === CampaignType.Email ? (
                        <I icon="at" />
                    ) : (
                        <I icon="message" />
                    )}
                </Box>

                {props.campaign.campaignType}
            </Cell>
            <Cell width={200}>
                {emailState && <Badge {...emailBagde(emailState)}>{translate(emailState)}</Badge>}

                {textMessageState && (
                    <Badge {...textMessageBadge(textMessageState)}>
                        {translate(textMessageState)}
                    </Badge>
                )}
            </Cell>
            <Cell>
                <Box css={{ ellipsis: true }}>
                    {props.campaign.event?.name ?? translate('communaut_20896')}
                </Box>
            </Cell>
        </Row>
    );
};
