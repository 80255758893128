import { DetailBox } from 'common-front/src/designSystem/components/detailBox';
import { ProgressGauge } from 'common-front/src/designSystem/components/progressGauge';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useMobileQuery } from 'common-front/src/hooks/useMobileQuery';
import { Badge } from 'common/src/designSystem/components/badge';
import { Flex } from 'common/src/designSystem/components/flex';
import { PositionsCategory } from 'common/src/generated/types';
import * as React from 'react';

interface IPositionCategoryGaugeProps {
    category: Pick<PositionsCategory, 'assignedResources' | 'resources' | 'preassignedResources'>;
    title: string;
}

export const PositionCategoryGauge = (props: IPositionCategoryGaugeProps) => {
    const { translate } = useHeavent();
    const onMobile = useMobileQuery();

    return (
        <DetailBox title={props.title}>
            <Flex
                align="center"
                direction="column"
                {...(onMobile ? { gap: '9' } : { gap: '7' })}
                gap="4"
                width={1}
            >
                <ProgressGauge
                    count={props.category.assignedResources}
                    total={props.category.resources}
                />

                <Badge leftIcon="user">
                    {translate('_1_membre_pr_32812', props.category.preassignedResources)}
                </Badge>
            </Flex>
        </DetailBox>
    );
};
