import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { Table } from 'common/src/designSystem/components/table/table';
import * as React from 'react';
import { useUserHistoryEventsQuery } from '../../../generated/graphqlHooks';
import { UserHistoryEventRow } from './userHistoryEventRow';

export const UserHistoryEvents = () => {
    const {
        translate,
        params: { organizationId, userInfoId }
    } = useHeavent();
    const { data, isLoading } = useUserHistoryEventsQuery({ organizationId, userInfoId });

    return (
        <Table hideBorder={true}>
            <HeaderRow>
                <HeaderCell>{translate('nom_de_l_v_nem_08711')}</HeaderCell>
                <HeaderCell>{translate('missions_63972')}</HeaderCell>
                <HeaderCell>{translate('accr_ditations_39450')}</HeaderCell>
                <HeaderCell>{translate('Delegation')}</HeaderCell>
            </HeaderRow>

            {isLoading ? (
                <>
                    <RowSkeleton />
                    <RowSkeleton />
                    <RowSkeleton />
                </>
            ) : (
                data.organization.userInfo.volunteersRegistrations.map((vr) => (
                    <UserHistoryEventRow key={vr.id} vr={vr} />
                ))
            )}
        </Table>
    );
};
