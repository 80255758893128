import { parseInt } from 'lodash-es';
import { DateTime } from 'luxon';
import { Emptyable } from './emptyable';
import { isNonEmptyString } from './string';

const EID_REGEX = /^[0-9]{11}$/i;
const CURRENT_YEAR = DateTime.now().year - 2_000;

export function isValidEid(value: Emptyable<string>) {
    if (isNonEmptyString(value) && EID_REGEX.test(value)) {
        const year = parseInt(value.substring(0, 2), 10);
        let toValidate = parseInt(value.substring(0, 9), 10);
        const key = parseInt(value.substring(9), 10);

        if (year <= CURRENT_YEAR) {
            toValidate = 2_000_000_000 + toValidate;
        }

        const keyControl = 97 - (toValidate % 97);

        return key === keyControl;
    } else {
        return false;
    }
}
