import { CSS } from '@stitches/react';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Emptyable } from 'common/src/util/emptyable';
import { isNonEmptyString } from 'common/src/util/string';
import { noop } from 'lodash-es';
import * as React from 'react';
import { Checkbox } from '../checkbox';
import { Radio } from '../radio';

interface IRichSelectItemProps extends React.HTMLAttributes<HTMLDivElement & HTMLAnchorElement> {
    children: string;
    css?: CSS;
    isCheckbox: boolean;
    isRadio: boolean;
    isSelected: boolean;
    subtext?: Emptyable<string>;
    // eslint-disable-next-line react/no-unused-prop-types
    value: any; // Prop value accessed in `richSelect.tsx`.

    onClick(): void;
}

export const RichSelectItem = ({
    children,
    css,
    isCheckbox,
    isRadio,
    isSelected,
    onClick,
    subtext,
    value,
    ...rest
}: IRichSelectItemProps) => (
    <Flex
        align="center"
        aria-checked={isCheckbox ? isSelected : undefined}
        aria-label={children}
        aria-selected={isSelected}
        css={{
            cursor: 'pointer',
            minHeight: '40px',
            padding: '$2 $3',
            userSelect: 'none',
            '&:hover': {
                background: '$gray100',
                borderRadius: '$1'
            },
            ...css
        }}
        gap="3"
        role="option"
        tabIndex={0}
        width={1}
        onClick={onClick}
        onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
                onClick?.();
            }
        }}
        {...rest}
    >
        {isCheckbox && (
            <Checkbox
                shouldPreventDefault={false}
                state={isSelected ? 'checked' : 'unchecked'}
                onClick={noop}
            />
        )}

        {isRadio && (
            <Radio
                shouldPreventDefault={false}
                state={isSelected ? 'checked' : 'unchecked'}
                onClick={noop}
            />
        )}

        <Flex css={{ flex: '1', overflow: 'hidden' }} direction="column" gap="1">
            <Box color="gray800" css={{ ellipsis: true }} title={children} width={1}>
                {children}
            </Box>

            {isNonEmptyString(subtext) && (
                <Box color="gray500" css={{ ellipsis: true }} title={subtext} width={1}>
                    {subtext}
                </Box>
            )}
        </Flex>

        {!isCheckbox && !isRadio && isSelected && (
            <Box color="primary700">
                <I icon="check" />
            </Box>
        )}
    </Flex>
);
