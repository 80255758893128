import { Emptyable } from '../emptyable';
import { buildPath } from './commonPaths';
import { CommunityPaths } from './communityPaths';
import { EventParams, EventsPaths } from './eventsPaths';
import { OrganizationParams } from './organizationsPaths';
import { EventIdPathParam, FormIdPathParam } from './pathsTypes';

const slugs = {
    ITEMS: {
        FORM: {
            PLURAL: 'forms',
            SINGLE: 'form'
        }
    },
    SECTIONS: {
        ELEMENTS: 'elements',
        EMAILS: 'emails',
        INTEGRATION: 'integration',
        PARAMETERS: 'parameters',
        PREVIEW: 'preview'
    },
    SUB_SECTIONS: {
        GENERAL: 'general',
        DESIGN: 'design',
        AVAILABILITIES: 'availabilities',
        MISSIONS: 'missions',
        ACCREDITATIONS: 'accreditations',
        BROADCAST: 'broadcast',
        STATE: 'state'
    }
};

const itemPaths = {
    FORMS: slugs.ITEMS.FORM.PLURAL,
    FORM(formId: FormIdPathParam) {
        return buildPath([slugs.ITEMS.FORM.SINGLE, formId]);
    }
};

export type FormParams = OrganizationParams & { eventId?: Emptyable<EventIdPathParam> } & {
    formId: FormIdPathParam;
};

export const FormsPaths = {
    // read
    FORMS({ eventId, ...params }: OrganizationParams & Partial<EventParams>) {
        return buildPath([
            eventId ? EventsPaths.EVENT({ ...params, eventId }) : CommunityPaths.COMMUNITY(params),
            itemPaths.FORMS
        ]);
    },
    FORM({ formId, eventId, ...params }: FormParams) {
        return buildPath([
            eventId ? EventsPaths.EVENT({ ...params, eventId }) : CommunityPaths.COMMUNITY(params),
            itemPaths.FORM(formId)
        ]);
    },

    // tabs
    FORM_ELEMENTS(params: FormParams) {
        return buildPath([FormsPaths.FORM(params), slugs.SECTIONS.ELEMENTS]);
    },
    FORM_INTEGRATION(params: FormParams) {
        return buildPath([FormsPaths.FORM(params), slugs.SECTIONS.INTEGRATION]);
    },
    FORM_EMAILS(params: FormParams) {
        return buildPath([FormsPaths.FORM(params), slugs.SECTIONS.EMAILS]);
    },
    FORM_PREVIEW(params: FormParams) {
        return buildPath([FormsPaths.FORM(params), slugs.SECTIONS.PREVIEW]);
    },
    FORM_PARAMETERS(params: FormParams) {
        return buildPath([FormsPaths.FORM(params), slugs.SECTIONS.PARAMETERS]);
    },
    FORM_PARAMETERS_GENERAL(params: FormParams) {
        return buildPath([FormsPaths.FORM_PARAMETERS(params), slugs.SUB_SECTIONS.GENERAL]);
    },
    FORM_PARAMETERS_DESIGN(params: FormParams) {
        return buildPath([FormsPaths.FORM_PARAMETERS(params), slugs.SUB_SECTIONS.DESIGN]);
    },
    FORM_PARAMETERS_AVAILABILITIES(params: FormParams) {
        return buildPath([FormsPaths.FORM_PARAMETERS(params), slugs.SUB_SECTIONS.AVAILABILITIES]);
    },
    FORM_PARAMETERS_MISSIONS(params: FormParams) {
        return buildPath([FormsPaths.FORM_PARAMETERS(params), slugs.SUB_SECTIONS.MISSIONS]);
    },
    FORM_PARAMETERS_ACCREDITATIONS(params: FormParams) {
        return buildPath([FormsPaths.FORM_PARAMETERS(params), slugs.SUB_SECTIONS.ACCREDITATIONS]);
    },
    FORM_PARAMETERS_BROADCAST(params: FormParams) {
        return buildPath([FormsPaths.FORM_PARAMETERS(params), slugs.SUB_SECTIONS.BROADCAST]);
    },
    FORM_PARAMETERS_STATE(params: FormParams) {
        return buildPath([FormsPaths.FORM_PARAMETERS(params), slugs.SUB_SECTIONS.STATE]);
    }
};
