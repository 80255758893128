import { Accordion } from 'common-front/src/designSystem/components/accordion';
import { getWishedAccreditations } from 'common-front/src/vo/formUserInfo';
import { Box } from 'common/src/designSystem/components/box';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Row } from 'common/src/designSystem/components/table/row';
import { Table } from 'common/src/designSystem/components/table/table';
import { EventId, FormsUsersInfosWishedFragment, OrganizationId } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useLocalEventState } from '../../../hooks/useLocalState';

interface IAssignmentUserPanelAccreditationsWishedProps {
    eventId: EventId;
    formsUsersInfos: FormsUsersInfosWishedFragment['formsUsersInfos'];
    organizationId: OrganizationId;
}

export const AssignmentUserPanelAccreditationsWished = (
    props: IAssignmentUserPanelAccreditationsWishedProps
) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);
    const [isOpen, setIsOpen] = useLocalEventState(
        'assignment.rightPanel.areAccreditationsOpen',
        true
    );
    const wishedAccreditations = React.useMemo(
        () =>
            getWishedAccreditations(
                props.formsUsersInfos,
                dateTimeService,
                props.organizationId,
                props.eventId
            ),
        [props.formsUsersInfos, props.organizationId, props.eventId]
    );

    return (
        <Accordion isOpen={isOpen} setIsOpen={setIsOpen} title={translate('accr_ditations_74375')}>
            <Table>
                <HeaderRow>
                    <HeaderCell>{translate('accr_ditation_s_00595')}</HeaderCell>
                </HeaderRow>

                {wishedAccreditations.map((accreditation, index) => (
                    <Row key={index}>
                        <Cell align="start" direction="column" gap="1" justify="center">
                            <Box color="gray900" fontWeight="medium">
                                {accreditation.name}
                            </Box>

                            <Box color="gray500">{accreditation.subtitle}</Box>
                        </Cell>
                    </Row>
                ))}
            </Table>
        </Accordion>
    );
};
