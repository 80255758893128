import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { UserPositionsQuery } from 'common/src/generated/types';
import { DurationService } from 'common/src/services/durationService';
import { useService } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IUserPositionsStatsProps {
    numberOfAssignments: number;
    volunteerRegistration: UserPositionsQuery['event']['volunteerRegistration'];
}

export const UserPositionsStats = (props: IUserPositionsStatsProps) => {
    const { translate } = useHeavent();
    const durationService = useService(DurationService);

    return (
        <Flex gap="4">
            <Flex
                css={{
                    background: 'white',
                    bd: true,
                    padding: '$6'
                }}
                direction="column"
                gap="2"
                width={1}
            >
                <Box font="gray500 textSm medium">{translate('nombre_d_heures_46954')}</Box>

                <Box font="gray900 displaySm semiBold">
                    {durationService.minutesToHoursMinutesString(
                        props.volunteerRegistration.volunteerMinutes
                    )}
                </Box>
            </Flex>

            <Flex
                css={{
                    background: 'white',
                    bd: true,
                    padding: '$6'
                }}
                direction="column"
                gap="2"
                width={1}
            >
                <Box font="gray500 textSm medium">{translate('nombre_de_missi_80367')}</Box>

                <Box font="gray900 displaySm semiBold">{props.numberOfAssignments}</Box>
            </Flex>
        </Flex>
    );
};
