import { injectable } from 'inversify';
import { omit, pick } from 'lodash-es';
import * as yup from 'yup';
import {
    CustomFieldWithConditionFragment,
    FormId,
    ProfileQuery,
    SupportedLanguage,
    TeamCode,
    UserInfoAndTermsInput,
    UserInfoQuery,
    UsersInfo,
    UsersInfoInput,
    UsersInfoProfileInput
} from '../generated/types';
import { CountriesService } from '../services/countriesService';
import { TranslationService } from '../services/translationService';
import { Emptyable } from '../util/emptyable';
import { USABLE_LANGUAGES } from '../vo/supportedLanguage';
import { DOCUMENT_INPUT_KEYS, DocumentInputService } from './documentInput';
import { EventFieldForSchema, FieldInputService } from './fieldInput';
import { PHONE_INPUT_KEYS, PhoneInputService } from './phoneInput';

export type FieldFormElement = {
    isMandatory: boolean;
    customField: CustomFieldWithConditionFragment;
};

export interface IUpdateUserInfoValues {
    filledFormsIds: FormId[];
    formKeyToTeamCode: Record<string, TeamCode>;
    userInfo: UsersInfoInput;
}

export interface IUpdateProfileValues {
    userInfo: UsersInfoProfileInput;
}

export interface IUpdateUserInfoAndTermsValues {
    userInfoAndTerms: UserInfoAndTermsInput;
}

interface IUserInfoUpdateInputDefaultOptions {
    checkboxDefaultEmpty?: boolean;
    countryCode?: Emptyable<string>;
}

@injectable()
export class UserInfoInputService {
    constructor(
        private countriesService: CountriesService,
        private documentInputService: DocumentInputService,
        private fieldInputService: FieldInputService,
        private phoneInputService: PhoneInputService,
        private translationService: TranslationService
    ) {}

    userInfoUpdateInputDefault(
        userInfo: Emptyable<Pick<UsersInfo, 'id' | 'fields'>>,
        customFields: CustomFieldWithConditionFragment[],
        options: IUserInfoUpdateInputDefaultOptions = {}
    ): UsersInfoInput {
        const countryValues = this.countriesService.getDefaultCountryValues(options.countryCode);

        return {
            id: userInfo?.id ?? null,
            fields: this.fieldInputService.fieldsUpdateInputDefault(
                userInfo?.fields || {},
                customFields,
                countryValues,
                options.checkboxDefaultEmpty
            )
        };
    }

    formUserInfoInputSchema(formElements: FieldFormElement[]) {
        return yup.object().shape({
            fields: yup
                .object()
                .shape(
                    Object.fromEntries(
                        formElements.map(({ isMandatory, customField }) => [
                            customField.slug,
                            this.fieldInputService.fieldSchema(customField, isMandatory)
                        ])
                    )
                )
        });
    }

    userInfoInputSchema(
        eventsFields: EventFieldForSchema[],
        privateCustomFields?: CustomFieldWithConditionFragment[]
    ) {
        const fieldsSchemas: { [key: string]: yup.Schema<any> } = {};

        eventsFields.forEach((eventField) => {
            const field = eventField.field;
            const schema = this.fieldInputService.fieldSchema(
                eventField.field,
                eventField.isMandatory
            );

            fieldsSchemas[field.slug] = schema;
        });

        if (privateCustomFields) {
            privateCustomFields.forEach((customField) => {
                fieldsSchemas[customField.slug] = this.fieldInputService.fieldSchema(
                    customField,
                    false
                );
            });
        }

        return yup.object().shape({
            fields: yup.object().shape({
                ...fieldsSchemas
            })
        });
    }

    userInfoProfileInputDefault(user: ProfileQuery['user']): UsersInfoProfileInput {
        const countryValues = this.countriesService.getDefaultCountryValues();

        return {
            email: user.email,
            fields: {
                firstName: user.userInfo?.fields?.firstName ?? '',
                lastName: user.userInfo?.fields?.lastName ?? '',
                language:
                    user.userInfo?.fields?.language ??
                    user.language ??
                    countryValues.user.language ??
                    SupportedLanguage.En,
                picture: user.userInfo.fields.picture
                    ? pick(user.userInfo.fields.picture, DOCUMENT_INPUT_KEYS)
                    : this.documentInputService.documentInputDefault(),
                phone: user.userInfo.fields.phone
                    ? pick(user.userInfo.fields.phone, PHONE_INPUT_KEYS)
                    : this.phoneInputService.phoneInputDefault(countryValues)
            }
        };
    }

    userInfoFields() {
        return {
            fields: yup.object().shape({
                firstName: yup.string().nullable(),
                lastName: yup.string().nullable(),
                picture: this.documentInputService.documentInputSchemaNotRequired(),
                language: yup
                    .string()
                    .required(this.translationService.translate('les_langues_doi_27712'))
                    .oneOf(
                        USABLE_LANGUAGES,
                        this.translationService.translate('les_langues_doi_27712')
                    ),
                phone: this.phoneInputService.phoneInputSchemaNotRequired()
            })
        };
    }

    userInfoProfileInputSchema() {
        return yup.object().shape({
            email: yup
                .string()
                .email(this.translationService.translate('l_e_mail_est_re_04856'))
                .required(this.translationService.translate('l_e_mail_est_re_04856')),
            ...this.userInfoFields()
        });
    }

    updateUserInfoSchema(formElements: FieldFormElement[]) {
        return yup.object().shape({
            userInfo: this.formUserInfoInputSchema(formElements)
        });
    }

    updateProfileSchema() {
        return yup.object().shape({
            userInfo: this.userInfoProfileInputSchema()
        });
    }

    userInfoAndTermsInputDefault(query: UserInfoQuery['user']): UserInfoAndTermsInput {
        return { ...omit(query.userInfo, ['id']), termsAccepted: false };
    }

    userInfoAndTermsInputSchema() {
        return yup.object().shape({
            firstName: yup.string().nullable(),
            lastName: yup.string().nullable(),
            phone: this.phoneInputService.phoneInputSchemaNotRequired(),
            termsAccepted: yup
                .boolean()
                .oneOf([true], this.translationService.translate('il_est_n_cessai_83896'))
                .required(this.translationService.translate('il_est_n_cessai_83896'))
        });
    }

    updateUserInfoAndTermsSchema() {
        return yup.object().shape({
            userInfoAndTerms: this.userInfoAndTermsInputSchema()
        });
    }
}
