import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I, IIcon } from 'common/src/designSystem/components/i';
import { Link } from 'common/src/designSystem/components/link';
import * as React from 'react';

interface IEventMenuGroupProps {
    children?: React.ReactNode;
    icon: IIcon;
    isMenuOpen: boolean;
    title: string;
    to?: string;

    isActive?(pathname: string): boolean;
}

export const EventMenuGroup = (props: IEventMenuGroupProps) => {
    const isActive = props.isActive
        ? props.isActive(location.pathname)
        : location.pathname === props.to;
    const [isOpen, setIsOpen] = React.useState(isActive);
    const showMenu = !!props.children && (props.isMenuOpen ? isOpen : true);

    const menuComponent = () => (
        <Flex
            align="center"
            className={isActive ? 'h-event-menu-group-active' : ''}
            css={{
                color: '$gray700',
                cursor: 'pointer',
                padding: '$2 $3',
                '&:hover, &.h-event-menu-group-active': {
                    background: '$primary50',
                    borderRadius: '6px',
                    color: '$primary700'
                }
            }}
            gap="3"
            height={props.isMenuOpen ? undefined : 40}
            width={1}
            onClick={() => {
                if (props.isMenuOpen) {
                    setIsOpen(!isOpen);
                }
            }}
        >
            <I icon={props.icon} />

            {props.isMenuOpen && (
                <Box css={{ flex: '1' }} fontWeight="medium">
                    {props.title}
                </Box>
            )}

            {props.isMenuOpen && props.children && (
                <Box fontSize="textXs">
                    <I icon={isOpen ? 'chevron-up' : 'chevron-down'} />
                </Box>
            )}
        </Flex>
    );

    return (
        <Flex
            className={props.isMenuOpen ? 'h-event-menu-open' : 'h-event-menu-collapsed'}
            css={{
                position: 'relative',
                '&.h-event-menu-collapsed > .h-event-menu-group': {
                    display: 'none'
                },
                '&.h-event-menu-collapsed:hover > .h-event-menu-group': {
                    display: 'flex'
                }
            }}
            direction="column"
            gap="2"
            width={1}
        >
            {props.to ? <Link to={props.to}>{menuComponent()}</Link> : menuComponent()}

            {showMenu && (
                <Flex
                    className="h-event-menu-group"
                    css={
                        props.isMenuOpen
                            ? {
                                  paddingLeft: '36px'
                              }
                            : {
                                  left: '28px',
                                  position: 'absolute',
                                  paddingLeft: '12px',
                                  top: '-8px',
                                  width: '192px',
                                  zIndex: '10'
                              }
                    }
                >
                    <Flex
                        css={
                            props.isMenuOpen
                                ? {}
                                : {
                                      background: 'white',
                                      bd: 'gray200 2 md',
                                      py: '$2',
                                      width: '100%'
                                  }
                        }
                        direction="column"
                    >
                        {!props.isMenuOpen && (
                            <Box css={{ padding: '$2 $3' }} font="gray900 textSm semiBold">
                                {props.title}
                            </Box>
                        )}

                        {props.children}
                    </Flex>
                </Flex>
            )}
        </Flex>
    );
};
