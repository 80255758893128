import { Button } from 'common-front/src/designSystem/components/button';
import { IconBackground } from 'common-front/src/designSystem/components/iconBackground';
import { Modal } from 'common-front/src/designSystem/components/modal';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    EventId,
    FormId,
    MassActionSelectedsInput,
    OrganizationEventLevel,
    OrganizationId
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';
import {
    useDelegationsMassMoveInfosQuery,
    useDelegationsMassMoveMutation
} from '../../generated/graphqlHooks';

interface IDelegationsMassMoveModalProps {
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;
    selecteds: MassActionSelectedsInput;

    onClose(): void;
    onSuccess(): void;
}

export const DelegationsMassMoveModal = (props: IDelegationsMassMoveModalProps) => {
    const { translate } = useHeavent();
    const { mutate, isLoading } = useDelegationsMassMoveMutation();
    const [eventsIds, setEventsIds] = React.useState<EventId[]>([]);
    const [formsIds, setFormsIds] = React.useState<FormId[]>([]);
    const { data } = useDelegationsMassMoveInfosQuery({
        organizationId: props.organizationId,
        level: props.eventId ? OrganizationEventLevel.Organization : OrganizationEventLevel.Event,
        eventId: eventsIds[0]
    });
    const onMove = async () => {
        await mutate({
            organizationId: props.organizationId,
            eventId: props.eventId,
            massMove: {
                eventId: props.eventId ? null : eventsIds[0],
                formId: formsIds[0],
                selecteds: props.selecteds
            }
        });

        props.onSuccess();
        props.onClose();
    };
    const isValid =
        (props.eventId ? true : isNonEmptyArray(eventsIds)) && isNonEmptyArray(formsIds);

    return (
        <Modal size="md" onClose={props.onClose}>
            <Flex justify="center">
                <IconBackground color="primary" icon="clone" />
            </Flex>

            <Spacer height="4" />

            <Box font="gray900 textLg medium" textAlign="center">
                {translate('copier_les_d_l_43406')}
            </Box>

            {props.eventId && (
                <>
                    <Spacer height="1" />

                    <Box color="gray500" textAlign="center">
                        {translate('cela_liera_vos_60643')}
                    </Box>
                </>
            )}

            <Spacer height="7" />

            {!props.eventId && (
                <>
                    <RichSelect
                        isSearchVisible={true}
                        label={translate('_v_nement_22345')}
                        values={eventsIds}
                        onChange={setEventsIds}
                    >
                        {(data.organization?.events.nodes ?? []).map((event) => (
                            <option key={event.id} value={event.id}>
                                {event.name}
                            </option>
                        ))}
                    </RichSelect>

                    <Spacer height="4" />
                </>
            )}

            {(props.eventId || isNonEmptyArray(eventsIds)) && (
                <>
                    <RichSelect
                        isSearchVisible={true}
                        label={translate('formulaire_ut_59771')}
                        values={formsIds}
                        onChange={setFormsIds}
                    >
                        {(data.organization?.forms ?? []).map((form) => (
                            <option key={form.id} value={form.id}>
                                {form.name}
                            </option>
                        ))}
                    </RichSelect>
                </>
            )}

            <Spacer height="7" />

            <Flex gap="3" justify="end">
                <Button disabled={!isValid} isLoading={isLoading} onClick={onMove}>
                    {translate('copier_75889')}
                </Button>

                <Button color="white" onClick={props.onClose}>
                    {translate('annuler_48254')}
                </Button>
            </Flex>
        </Modal>
    );
};
