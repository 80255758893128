import { CSS } from '@stitches/react';
import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';

interface IMetricCardProps {
    button?: React.ReactNode;
    css?: CSS;
    children: React.ReactNode;
}

export const MetricCard = (props: IMetricCardProps) => (
    <Flex
        css={{
            background: 'white',
            bd: true,
            flex: '1',
            ...(props.css as any)
        }}
        direction="column"
    >
        <Flex
            css={{
                flex: '1',
                padding: '$6'
            }}
            direction="column"
            gap="2"
        >
            {props.children}
        </Flex>

        {props.button && (
            <Flex css={{ borderTop: '1px solid $gray200', padding: '$4 $6' }} justify="end">
                {props.button}
            </Flex>
        )}
    </Flex>
);
