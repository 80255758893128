import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { copy } from 'common-front/src/util/commands';
import { Avatar } from 'common/src/components/avatar/avatar';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Link } from 'common/src/designSystem/components/link';
import {
    AccreditationState,
    UserInfoOverlayFragment,
    VolunteerRegistrationState
} from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import { isNonEmptyString } from 'common/src/util/string';
import {
    getAccreditationStateBadgeColorIcon,
    getStateBadgeColorIcon
} from 'common/src/vo/volunteerRegistration';
import * as React from 'react';
import { UseroverlayEditEmailModal } from '../useroverlayEditEmailModal';

interface IUserHeaderProps {
    accreditationState: Emptyable<AccreditationState>;
    assignmentState: Emptyable<VolunteerRegistrationState>;
    canEditEmail: boolean;
    userInfo: UserInfoOverlayFragment;

    reload(): void;
}

export const UserHeader = (props: IUserHeaderProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const [isEditEmailOpen, setIsEditEmailOpen] = React.useState(false);

    return (
        <>
            <Flex align="center" gap="4" width={1}>
                <Avatar
                    email={props.userInfo.email}
                    image={props.userInfo.picture?.url}
                    name={props.userInfo.name}
                    size={88}
                />

                <Flex css={{ flex: '1' }} direction="column" gap="3">
                    <Box font="gray800 textXl medium">{props.userInfo.name}</Box>

                    <Flex gap="7">
                        <Flex direction="column" gap="1">
                            <Box font="gray800 textSm medium">{translate('Email')}</Box>

                            <Dropdown>
                                <Trigger>
                                    <Badge
                                        color="white"
                                        cursor="pointer"
                                        leftIcon="at"
                                        rightIcon="chevron-down"
                                        size="md"
                                    >
                                        {props.userInfo.email}
                                    </Badge>
                                </Trigger>

                                <Menu placement="bottom-start">
                                    <ItemIcon
                                        icon="copy"
                                        onClick={() => {
                                            copy(props.userInfo.email);
                                        }}
                                    >
                                        {translate('copier_1_59210', props.userInfo.email)}
                                    </ItemIcon>

                                    {props.canEditEmail && (
                                        <ItemIcon
                                            icon="pen"
                                            onClick={() => {
                                                setIsEditEmailOpen(true);
                                            }}
                                        >
                                            {translate('modifier_1_38587', props.userInfo.email)}
                                        </ItemIcon>
                                    )}

                                    <Link to={`mailto:${props.userInfo.email}`}>
                                        <ItemIcon icon="at">
                                            {translate('_crire_1_90053', props.userInfo.email)}
                                        </ItemIcon>
                                    </Link>
                                </Menu>
                            </Dropdown>
                        </Flex>

                        <Flex direction="column" gap="1">
                            <Box font="gray800 textSm medium">{translate('t_l_phone_31755')}</Box>

                            {isNonEmptyString(props.userInfo.phone?.internationalFormat) ? (
                                <Dropdown>
                                    <Trigger>
                                        <Badge
                                            color="white"
                                            cursor="pointer"
                                            leftIcon="phone"
                                            rightIcon="chevron-down"
                                            size="md"
                                        >
                                            {props.userInfo.phone?.internationalFormat ?? '-'}
                                        </Badge>
                                    </Trigger>

                                    <Menu placement="bottom-start">
                                        <ItemIcon
                                            color="default"
                                            icon="copy"
                                            onClick={() =>
                                                copy(props.userInfo.phone!.internationalFormat)
                                            }
                                        >
                                            {translate(
                                                'copier_1_59210',
                                                props.userInfo.phone?.internationalFormat ?? '-'
                                            )}
                                        </ItemIcon>

                                        <Link
                                            to={{
                                                pathname: `tel:${props.userInfo.phone!.internationalFormat.replaceAll(
                                                    ' ',
                                                    ''
                                                )}`
                                            }}
                                        >
                                            <ItemIcon color="default" icon="phone">
                                                {translate(
                                                    'appeler_1_57163',
                                                    props.userInfo.phone?.internationalFormat ?? '-'
                                                )}
                                            </ItemIcon>
                                        </Link>
                                    </Menu>
                                </Dropdown>
                            ) : (
                                <Box color="gray500">-</Box>
                            )}
                        </Flex>

                        <Flex direction="column" gap="1">
                            <Box font="gray800 textSm medium">{translate('population_38615')}</Box>

                            <Box color="gray500">
                                {isNonEmptyString(props.userInfo.population)
                                    ? props.userInfo.population
                                    : '-'}
                            </Box>
                        </Flex>

                        {props.assignmentState && (
                            <>
                                <Flex direction="column" gap="1">
                                    <Box font="gray800 textSm medium">
                                        {translate('status_d_affect_75769')}
                                    </Box>

                                    <Flex>
                                        <Badge {...getStateBadgeColorIcon(props.assignmentState)}>
                                            {translate(props.assignmentState)}
                                        </Badge>
                                    </Flex>
                                </Flex>
                            </>
                        )}

                        {props.accreditationState && (
                            <>
                                <Flex direction="column" gap="1">
                                    <Box font="gray800 textSm medium">
                                        {translate('statut_d_accr_d_75527')}
                                    </Box>

                                    <Flex>
                                        <Badge
                                            {...getAccreditationStateBadgeColorIcon(
                                                props.accreditationState
                                            )}
                                        >
                                            {translate(props.accreditationState)}
                                        </Badge>
                                    </Flex>
                                </Flex>
                            </>
                        )}
                    </Flex>
                </Flex>
            </Flex>

            {isEditEmailOpen && (
                <UseroverlayEditEmailModal
                    email={props.userInfo.email}
                    eventId={eventId}
                    organizationId={organizationId}
                    userInfoId={props.userInfo.id}
                    onClose={() => {
                        setIsEditEmailOpen(false);
                    }}
                    onSuccess={props.reload}
                />
            )}
        </>
    );
};
