import { Alert } from 'common-front/src/designSystem/components/alert';
import { Button } from 'common-front/src/designSystem/components/button';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CommonEnvVars } from 'common/src/envVars';
import { CustomFieldSlug } from 'common/src/generated/types';
import * as React from 'react';
import { useUserFieldUpdateQuery } from './generated/graphqlHooks';

export const UserFieldUpdateByTicketId = () => {
    const {
        translate,
        params: { organizationId, customFieldSlug }
    } = useHeavent<{ customFieldSlug: CustomFieldSlug; value: any }>();
    const { data, loader } = useUserFieldUpdateQuery({ organizationId, slug: customFieldSlug });
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSuccessOpen, setIsSuccessOpen] = React.useState(false);
    const [isErrorOpen, setIsErrorOpen] = React.useState(false);
    const [ticketId, setTicketId] = React.useState('');
    const [value, setValue] = React.useState('');
    const name = data.organization?.customField.name ?? '';
    const description = data.organization?.customField.description ?? '';
    const link = `${CommonEnvVars.HEAVENT_API_URL}/users/organization/${organizationId}/update-by-ticket-id`;
    const onClick = async () => {
        setIsLoading(true);
        setIsSuccessOpen(false);
        setIsErrorOpen(false);

        const response = await fetch(link, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ticketId,
                slug: customFieldSlug,
                value
            })
        });

        if (response.status === 200) {
            setIsSuccessOpen(true);
            setTicketId('');
            setValue('');
        } else {
            setIsErrorOpen(true);
        }

        setIsLoading(false);
    };

    return (
        <Flex
            align="center"
            css={{
                background: '$gray50',
                overflow: 'hidden'
            }}
            height={1}
            justify="center"
            width={1}
        >
            {loader || (
                <Flex
                    align="center"
                    css={{
                        background: 'white',
                        bd: true,
                        padding: '$7'
                    }}
                    direction="column"
                    width={640}
                >
                    {isSuccessOpen && (
                        <>
                            <Flex width={1}>
                                <Alert
                                    color="success"
                                    leftIcon="check"
                                    rightIcon="xmark"
                                    rightIconClick={() => {
                                        setIsSuccessOpen(false);
                                    }}
                                    width={1}
                                >
                                    {translate('le_champ_1_36490', name)}
                                </Alert>
                            </Flex>

                            <Spacer height="6" />
                        </>
                    )}

                    {isErrorOpen && (
                        <>
                            <Flex width={1}>
                                <Alert
                                    color="error"
                                    leftIcon="circle-exclamation"
                                    rightIcon="xmark"
                                    rightIconClick={() => {
                                        setIsErrorOpen(false);
                                    }}
                                    width={1}
                                >
                                    {translate('le_champ_1_46567', name)}
                                </Alert>
                            </Flex>

                            <Spacer height="6" />
                        </>
                    )}

                    <TextInput
                        label={translate('code_barres_60437')}
                        value={ticketId}
                        onChange={setTicketId}
                    />

                    <Spacer height="6" />

                    <TextInput hint={description} label={name} value={value} onChange={setValue} />

                    <Spacer height="7" />

                    <Flex justify="center" width={1}>
                        <Button isLoading={isLoading} textAlign="center" onClick={onClick}>
                            {translate('mettre_jour_34131', name)}
                        </Button>
                    </Flex>
                </Flex>
            )}
        </Flex>
    );
};
