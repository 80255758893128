import { Accordion } from 'common-front/src/designSystem/components/accordion';
import { Button } from 'common-front/src/designSystem/components/button';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { DocumentContainerElement } from 'common/src/vo/documentBuilder';
import * as React from 'react';
import { useDocumentBuilderContext } from '../documentBuilderContext';
import { DocumentBuilderLeftPanelAddElement } from './documentBuilderLeftPanelAddElement';
import { DocumentBuilderLeftPanelElement } from './documentBuilderLeftPanelElement';

interface IDocumentBuilderLeftPanelContainerProps {
    container: DocumentContainerElement;
}

export const DocumentBuilderLeftPanelContainer = (
    props: IDocumentBuilderLeftPanelContainerProps
) => {
    const { translate } = useHeavent();
    const { getSelectedPage, selectPage, updateElementProperty } = useDocumentBuilderContext();

    return (
        <Flex
            css={{ boxShadow: '$sm', overflow: 'hidden auto', paddingBottom: '$11' }}
            direction="column"
            height={1}
            width={1}
        >
            <Flex css={{ padding: '$2 $6' }}>
                <Button
                    color="invisible"
                    leftIcon="arrow-left"
                    onClick={() => {
                        selectPage(getSelectedPage()!.id);
                    }}
                >
                    {translate('retour_92202')}
                </Button>
            </Flex>

            <Accordion css={{ padding: '$4 $6' }} title={translate('initialisation_38640')}>
                <TextInput
                    label={translate('nom_08311')}
                    value={props.container.name}
                    onChange={(newName) => {
                        updateElementProperty(props.container.id, 'name', newName);
                    }}
                />

                <DocumentBuilderLeftPanelAddElement container={props.container} />
            </Accordion>

            <Accordion css={{ padding: '$4 $6' }} title={translate('conteneur_33078')}>
                <Select
                    label={translate('direction_39738')}
                    value={props.container.direction}
                    onChange={(newDirection) => {
                        updateElementProperty(props.container.id, 'direction', newDirection);
                    }}
                >
                    <option value="column">{translate('de_haut_en_bas_72752')}</option>
                    <option value="row">{translate('de_gauche_dro_21330')}</option>
                </Select>

                <TextInput
                    label={translate('_cart_entre_les_62547')}
                    shouldParseAsInt={true}
                    value={props.container.gap}
                    onChange={(newGap) => {
                        updateElementProperty(props.container.id, 'gap', newGap);
                    }}
                />

                <Flex direction="column" gap="1">
                    <Box font="gray700 textSm medium">{translate('alignement_54247')}</Box>

                    <Flex gap="4">
                        <Flex>
                            <Button
                                color={props.container.justify === 'start' ? 'primary' : 'white'}
                                css={{
                                    borderBottomRightRadius: '0',
                                    borderTopRightRadius: '0'
                                }}
                                leftIcon="align-left"
                                onClick={() => {
                                    updateElementProperty(props.container.id, 'justify', 'start');
                                }}
                            />
                            <Button
                                color={props.container.justify === 'center' ? 'primary' : 'white'}
                                css={{
                                    borderLeft: 'none',
                                    borderRadius: '0',
                                    borderRight: 'none'
                                }}
                                leftIcon="align-center"
                                onClick={() => {
                                    updateElementProperty(props.container.id, 'justify', 'center');
                                }}
                            />
                            <Button
                                color={props.container.justify === 'end' ? 'primary' : 'white'}
                                css={{
                                    borderBottomLeftRadius: '0',
                                    borderTopLeftRadius: '0'
                                }}
                                leftIcon="align-right"
                                onClick={() => {
                                    updateElementProperty(props.container.id, 'justify', 'end');
                                }}
                            />
                        </Flex>

                        <Flex>
                            <Button
                                color={props.container.align === 'start' ? 'primary' : 'white'}
                                css={{
                                    borderBottomRightRadius: '0',
                                    borderTopRightRadius: '0'
                                }}
                                leftIcon="objects-align-top"
                                onClick={() => {
                                    updateElementProperty(props.container.id, 'align', 'start');
                                }}
                            />
                            <Button
                                color={props.container.align === 'center' ? 'primary' : 'white'}
                                css={{
                                    borderLeft: 'none',
                                    borderRadius: '0',
                                    borderRight: 'none'
                                }}
                                leftIcon="objects-align-center-vertical"
                                onClick={() => {
                                    updateElementProperty(props.container.id, 'align', 'center');
                                }}
                            />
                            <Button
                                color={props.container.align === 'end' ? 'primary' : 'white'}
                                css={{
                                    borderBottomLeftRadius: '0',
                                    borderTopLeftRadius: '0'
                                }}
                                leftIcon="objects-align-bottom"
                                onClick={() => {
                                    updateElementProperty(props.container.id, 'align', 'end');
                                }}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </Accordion>

            <DocumentBuilderLeftPanelElement element={props.container} />
        </Flex>
    );
};
