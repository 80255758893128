import { useUserOverlaySlotsQuery } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePermissions } from 'common-front/src/hooks/usePermissions';
import { UserPositionsWishedSlots } from 'common-front/src/users/information/userPositionsWishedSlots';
import { canUserInfoSlotsUpdateFn } from 'common/src/graphql/permissions/checks';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';

export const UserOverlaySlots = () => {
    const {
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();

    const { data, loader } = useUserOverlaySlotsQuery({ organizationId, eventId, userInfoId });
    const {
        permissions: [canUpdate],
        isLoading: permissionsQueryIsLoading
    } = usePermissions(canUserInfoSlotsUpdateFn({ eventId, userInfoId }));

    if (loader || permissionsQueryIsLoading) {
        return loader;
    } else {
        return (
            <>
                {data.event.hasFormWithSlotDisplay && (
                    <>
                        <UserPositionsWishedSlots
                            editPath={HeaventPaths.EDIT_USER_SLOTS(
                                organizationId,
                                eventId,
                                userInfoId
                            )}
                            eventId={eventId}
                            formsUsersInfos={data.organization.userInfo.formsUsersInfos}
                            organizationId={organizationId}
                            showEditButton={canUpdate}
                            userInfoId={userInfoId}
                        />
                    </>
                )}
            </>
        );
    }
};
