import { Alert } from 'common-front/src/designSystem/components/alert';
import { Button } from 'common-front/src/designSystem/components/button';
import { IconBackground } from 'common-front/src/designSystem/components/iconBackground';
import { Modal } from 'common-front/src/designSystem/components/modal';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    EventId,
    FormId,
    MassActionSelectedsInput,
    OrganizationEventLevel,
    OrganizationId
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';
import {
    useFormsQuery,
    useUsersInfosMassFormRemoveMutation,
    useVolunteersRegistrationsMassFormRemoveMutation
} from '../../generated/graphqlHooks';

interface IMassFormRemoveModalProps {
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;
    selecteds: MassActionSelectedsInput;

    onClose(): void;
    onSuccess(): void;
}

export const MassFormRemoveModal = (props: IMassFormRemoveModalProps) => {
    const translate = useTranslate();
    const {
        mutate: volunteersRegistrationsMassFormRemove,
        isLoading: isVolunteersRegistrationsMassFormRemoveLoading
    } = useVolunteersRegistrationsMassFormRemoveMutation();
    const { mutate: usersInfosMassFormRemove, isLoading: isUsersInfosMassFormRemoveLoading } =
        useUsersInfosMassFormRemoveMutation();
    const { data } = useFormsQuery({
        organizationId: props.organizationId,
        level: props.eventId ? OrganizationEventLevel.Event : OrganizationEventLevel.Organization,
        eventId: props.eventId
    });
    const forms = React.useMemo(() => data.organization?.forms ?? [], [data.organization]);
    const [formsIds, setFormsIds] = React.useState<FormId[]>([]);
    const valid = isNonEmptyArray(formsIds);
    const onUpdate = async () => {
        if (isNonEmptyArray(formsIds)) {
            if (props.eventId) {
                await volunteersRegistrationsMassFormRemove({
                    eventId: props.eventId,
                    selecteds: props.selecteds,
                    formId: formsIds[0]
                });
            } else {
                await usersInfosMassFormRemove({
                    organizationId: props.organizationId,
                    selecteds: props.selecteds,
                    formId: formsIds[0]
                });
            }

            props.onSuccess();
            props.onClose();
        }
    };

    return (
        <Modal size="md" onClose={props.onClose}>
            <Flex justify="center">
                <IconBackground color="primary" icon="file-lines" />
            </Flex>

            <Spacer height="4" />

            <Box font="gray900 textLg medium" textAlign="center">
                {translate('marquer_un_form_10633')}
            </Box>

            <Spacer height="7" />

            <RichSelect
                isSearchVisible={true}
                label={translate('Form')}
                values={formsIds}
                onChange={setFormsIds}
            >
                {forms.map((form) => (
                    <option key={form.id} value={form.id}>
                        {form.name}
                    </option>
                ))}
            </RichSelect>

            <Spacer height="4" />

            <Alert color="error" leftIcon="triangle-exclamation">
                {props.eventId
                    ? translate('cette_action_su_82938')
                    : translate('cette_action_su_88097')}
            </Alert>

            <Spacer height="7" />

            <Flex gap="3" justify="end">
                <Button
                    color="error"
                    disabled={!valid}
                    isLoading={
                        isVolunteersRegistrationsMassFormRemoveLoading ||
                        isUsersInfosMassFormRemoveLoading
                    }
                    onClick={onUpdate}
                >
                    {translate('marquer_comme_n_20227')}
                </Button>

                <Button color="white" onClick={props.onClose}>
                    {translate('annuler_48254')}
                </Button>
            </Flex>
        </Modal>
    );
};
