/* eslint-disable react/no-unused-prop-types */
import { SegmentId } from 'common/src/generated/types';
import * as React from 'react';

interface ICampaignContext {
    campaignRoutePath: string;
    campaignDashboardPath: string;
    campaignDashboardRoutePath: string;
    campaignDashboardUserRoutePath: string;
    campaignPreviewPath: string;
    campaignPreviewRoutePath: string;
    campaignsPath: string;
    isOrganizationCampaign: boolean;

    getVolunteersSegmentPath(segmentId: SegmentId): string;
}

const CampaignContext = React.createContext<ICampaignContext>({} as any);

interface ICampaignContextProviderProps extends ICampaignContext {
    children: React.ReactNode;
}

export const CampaignContextProvider = (props: ICampaignContextProviderProps) => (
    <CampaignContext.Provider
        value={{
            ...props
        }}
    >
        {props.children}
    </CampaignContext.Provider>
);

export function useCampaignContext(): ICampaignContext {
    return React.useContext(CampaignContext);
}
