import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { Table } from 'common/src/designSystem/components/table/table';
import * as React from 'react';
import { useUserHistoryCampaignsQuery } from '../../../generated/graphqlHooks';
import { UserHistoryCampaignRow } from './userHistoryCampaignRow';

export const UserHistoryCampaigns = () => {
    const {
        translate,
        params: { organizationId, userInfoId }
    } = useHeavent();
    const { data, isLoading } = useUserHistoryCampaignsQuery({ organizationId, userInfoId });

    return (
        <Table hideBorder={true}>
            <HeaderRow>
                <HeaderCell>{translate('nom_de_la_campa_45063')}</HeaderCell>
                <HeaderCell width={150}>{translate('type_35427')}</HeaderCell>
                <HeaderCell width={200}>{translate('status_06428')}</HeaderCell>
                <HeaderCell>{translate('_v_nement_22345')}</HeaderCell>
            </HeaderRow>

            {isLoading ? (
                <>
                    <RowSkeleton />
                    <RowSkeleton />
                    <RowSkeleton />
                </>
            ) : (
                data.organization.userInfo.campaigns.nodes.map((campaign) => (
                    <UserHistoryCampaignRow key={campaign.id} campaign={campaign} />
                ))
            )}
        </Table>
    );
};
