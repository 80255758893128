import { CustomFieldPrivacy, FormId, UserInfoCommunityFragment } from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { DEFAULT_PAGINATION_LIMIT } from 'common/src/vo/pagination';
import {
    columnsToIncludes,
    UsersSegmentsService
} from 'common/src/vo/segments/usersSegmentsService';
import { compact } from 'lodash-es';
import * as React from 'react';
import { Loader } from '../../components/loader/loader';
import {
    executeUserQuery,
    executeUsersQuery,
    useOrganizationDelegationMembersInfosQuery
} from '../../generated/graphqlHooks';
import { useHeavent } from '../../hooks/useHeavent';
import { useLocalOrganizationState } from '../../hooks/useLocalOrganization';
import { useSlugToCustomField } from '../../hooks/useSlugToCustomField';
import { RichTable } from '../../richTable/richTableContext';
import { CommunityUserCells } from '../../users/list/communityUserCells';
import { DelegationMemberRowDropdown } from './delegationMemberRowDropdown';
import { DelegationMembersActions } from './delegationMembersActions';

interface IOrganizationDelegationMembersProps {
    canClickMemberName: boolean;
    canEditMembers: boolean;
    customBadges: string[];
    isOrganizationAdmin: boolean;
    formId: FormId;
    showDocumentsDownload: boolean;

    getEditPath(member: UserInfoCommunityFragment): string;
}

export const OrganizationDelegationMembers = (props: IOrganizationDelegationMembersProps) => {
    const {
        params: { organizationId, delegationId }
    } = useHeavent();
    const usersSegmentsService = useService(UsersSegmentsService);
    const [columns, setColumns, isColumnsLoading] = useLocalOrganizationState(
        organizationId,
        'organizationDelegationMembers.columns',
        ['name']
    );
    const [limit, setLimit, isLimitLoading] = useLocalOrganizationState(
        organizationId,
        'organizationDelegationMembers.limit',
        DEFAULT_PAGINATION_LIMIT
    );
    const [predicates, setPredicates, isPredicatesLoading] = useLocalOrganizationState(
        organizationId,
        'organizationDelegationMembers.predicates',
        []
    );
    const [sort, setSort, isSortLoading] = useLocalOrganizationState(
        organizationId,
        'organizationDelegationMembers.sort',
        null
    );
    const { data, isLoading: areInfosLoading } = useOrganizationDelegationMembersInfosQuery({
        organizationId,
        privacy: props.isOrganizationAdmin ? CustomFieldPrivacy.All : CustomFieldPrivacy.Public,
        delegationId
    });
    const isLoading =
        isColumnsLoading ||
        isLimitLoading ||
        isPredicatesLoading ||
        isSortLoading ||
        areInfosLoading;
    const customFields = React.useMemo(() => {
        if (props.isOrganizationAdmin) {
            return data.organization?.customFields.nodes ?? [];
        } else {
            return compact(
                data.organization?.delegation.form.elements.map((e) => e.customField) || []
            );
        }
    }, [data.organization]);
    const slugToCustomField = useSlugToCustomField(customFields);
    const filters = React.useMemo(
        () => usersSegmentsService.getDelegationMembersFilters(customFields),
        [customFields]
    );
    const possibleColumns = React.useMemo(
        () => usersSegmentsService.getDelegationMembersPossibleColumns(customFields),
        [customFields]
    );

    return isLoading ? (
        <Loader />
    ) : (
        <RichTable
            columns={columns}
            columnsSearch={[]}
            columnsToIncludes={columnsToIncludes}
            eventId={null}
            filters={filters}
            getEditPath={props.getEditPath}
            limit={limit}
            loadDataList={executeUsersQuery}
            loadDataListParams={{ delegationId }}
            loadRow={executeUserQuery}
            organizationId={organizationId}
            possibleColumns={possibleColumns}
            predicates={predicates}
            renderActions={(massActions, _totalCount, reload) => (
                <DelegationMembersActions
                    customFields={customFields}
                    massActions={massActions}
                    showWishedAccreditationsEdit={false}
                    onMassEditSuccess={reload}
                />
            )}
            renderCell={(column, row: UserInfoCommunityFragment, reload) => (
                <CommunityUserCells
                    canClickMemberName={props.canClickMemberName}
                    column={column}
                    customField={slugToCustomField.get(column.slug)}
                    reloadRow={reload}
                    row={row}
                    updatable={props.canEditMembers}
                />
            )}
            renderRowDropdown={(member: UserInfoCommunityFragment, reload) => (
                <DelegationMemberRowDropdown
                    accreditationState={null}
                    allowedCustomDocuments={props.customBadges}
                    canAccredit={false}
                    canEditMembers={props.canEditMembers}
                    customDocuments={[]}
                    deleteFromEvent={false}
                    formId={props.formId}
                    member={member}
                    reload={reload}
                    showDeleteFromEvent={false}
                    showDocumentsDownload={props.showDocumentsDownload}
                    volunteerRegistrationId={null}
                />
            )}
            setColumns={setColumns}
            setLimit={setLimit}
            setPredicates={setPredicates}
            setSort={setSort}
            showEditColumns={true}
            showEditMode={props.isOrganizationAdmin}
            showFilters={true}
            showMassActions={true}
            showRowDropdown={true}
            showRowEdit={props.canEditMembers}
            showSearchColumns={false}
            sort={sort}
        />
    );
};
