import { ColorInput } from 'common-front/src/designSystem/components/colorInput';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { copy } from 'common-front/src/util/commands';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Position, PositionProperty } from 'common/src/generated/types';
import * as React from 'react';
import { PositionsUpdateCell } from './positionsUpdateCell';

interface IPositionsColorCellProps {
    position: Pick<Position, 'id' | 'color'>;
    updatable: boolean;

    reload(): void;
}

export const PositionsColorCell = (props: IPositionsColorCellProps) => {
    const { translate } = useHeavent();

    return (
        <PositionsUpdateCell
            cellCss={{ flex: '0 80px' }}
            initialValue={props.position.color}
            positionId={props.position.id}
            property={PositionProperty.Color}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <ColorInput label={translate('Color')} value={value} onChange={setValue} />
            )}
            renderValue={() => (
                <Flex align="center" gap="3" width={1}>
                    <Tooltip>
                        <Trigger>
                            <Box
                                css={{
                                    background: props.position.color,
                                    borderRadius: '2px',
                                    height: '24px',
                                    width: '24px'
                                }}
                                onClick={() => {
                                    copy(props.position.color);
                                }}
                            />
                        </Trigger>

                        <Content placement="top">{props.position.color}</Content>
                    </Tooltip>
                </Flex>
            )}
            updatable={props.updatable}
        />
    );
};
