import { AvatarNameEmail } from 'common-front/src/components/avatarNameEmail';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import {
    UserInformationsEventQuery,
    UserInformationsOrganizationQuery
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import * as React from 'react';

interface IUserInformationsProfilesProps {
    usersInfos:
        | UserInformationsEventQuery['organization']['userInfo']['user']['usersInfos']
        | UserInformationsOrganizationQuery['organization']['userInfo']['user']['usersInfos'];
}

export const UserInformationsProfiles = (props: IUserInformationsProfilesProps) => {
    const { translate } = useHeavent();

    return (
        <Flex
            css={{
                background: 'white',
                bd: true,
                padding: '$5 $6'
            }}
            direction="column"
            gap="3"
        >
            <Flex align="center" gap="2">
                <I icon="users-viewfinder" />

                <Box font="gray900 textMd medium">{translate('profils_li_s_03417')}</Box>
            </Flex>

            {props.usersInfos.map((ui) => (
                <Flex key={ui.id} direction="column" gap="1">
                    <AvatarNameEmail size={32} userInfo={ui} />

                    {isNonEmptyArray((ui as any).delegations) && (
                        <Flex css={{ paddingLeft: '44px' }} gap="2" wrap="wrap">
                            {(ui as any).delegations.map((delegation: any) => (
                                <Badge key={delegation.id} color="white" size="md">
                                    {delegation.name}
                                </Badge>
                            ))}
                        </Flex>
                    )}
                </Flex>
            ))}
        </Flex>
    );
};
