import { DropdownEllipsis } from 'common-front/src/designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { OrganizationCategoriesAccreditationsQuery } from 'common/src/generated/types';
import * as React from 'react';
import { UpdateAccreditationCategory } from '../../accreditationsCategories/create/createUpdateAccreditationCategory';
import { DeleteAccreditationCategory } from '../../accreditationsCategories/delete/deleteAccreditationCategory';

interface IOrganizationCategoriesAccreditationRowDropdownProps {
    accreditationCategory: OrganizationCategoriesAccreditationsQuery['organization']['accreditationsCategories']['nodes'][0];

    reload(): void;
}

export const OrganizationCategoriesAccreditationRowDropdown = (
    props: IOrganizationCategoriesAccreditationRowDropdownProps
) => {
    const { translate } = useHeavent();
    const [isRenameOpen, setIsRenameOpen] = React.useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

    return (
        <>
            <DropdownEllipsis>
                <ItemIcon
                    icon="pen"
                    onClick={() => {
                        setIsRenameOpen(true);
                    }}
                >
                    {translate('renommer_42135')}
                </ItemIcon>

                <ItemIcon
                    color="red"
                    icon="trash-can"
                    onClick={() => {
                        setIsDeleteOpen(true);
                    }}
                >
                    {translate('supprimer_43083')}
                </ItemIcon>
            </DropdownEllipsis>

            {isRenameOpen && (
                <UpdateAccreditationCategory
                    accreditationCategory={props.accreditationCategory}
                    eventId={props.accreditationCategory.eventId}
                    onClose={() => {
                        setIsRenameOpen(false);
                    }}
                    onSuccess={props.reload}
                />
            )}

            {isDeleteOpen && (
                <DeleteAccreditationCategory
                    accreditationCategory={props.accreditationCategory}
                    eventId={props.accreditationCategory.eventId}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                    onSuccess={props.reload}
                />
            )}
        </>
    );
};
