import { Form } from 'common-front/src/components/form/form';
import { Button } from 'common-front/src/designSystem/components/button';
import { RadioText } from 'common-front/src/designSystem/components/radio';
import { HorizontalSpacerSeparator } from 'common-front/src/designSystem/components/separator';
import { RichEditor } from 'common-front/src/designSystem/form/richEditor';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { FormParametersBroadcastOrganizationQuery, Segment } from 'common/src/generated/types';
import {
    FormParametersBroadcastInputService,
    IUpdateFormParametersBroadcastValues
} from 'common/src/input/formParametersBroadcastInput';
import { ValidateService } from 'common/src/services/validateService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';
import {
    useFormParametersBroadcastEventQuery,
    useFormParametersBroadcastOrganizationQuery,
    useFormParametersBroadcastUpdateMutation
} from '../../../../generated/graphqlHooks';
import { FormContainer } from '../../../show/formContainer';
import { FormParametersContainer } from '../formParametersContainer';
import { useFormParametersClose } from '../useFormParametersClose';

interface IFormParametersBroadcastInnerProps {
    eventSegments: Array<Pick<Segment, 'id' | 'name'>>;
    initialValues: IUpdateFormParametersBroadcastValues;
    isMutateLoading: boolean;
    loader: React.JSX.Element | null;
    name: string;
    organizationSegments: Array<Pick<Segment, 'id' | 'name'>>;
    values: IUpdateFormParametersBroadcastValues;

    change(name: string, value: any): void;
    handleSubmit(): void;
}

const FormParametersBroadcastInner = (props: IFormParametersBroadcastInnerProps) => {
    const translate = useTranslate();
    const { isNotSavedModalOpen, onClose, onNotSavedClose, onSave } = useFormParametersClose(
        props.initialValues,
        props.values,
        props.handleSubmit
    );
    const [onlyViews, setOnlyViews] = React.useState(
        isNonEmptyArray(props.initialValues.form.segmentsIds)
    );
    const segments = React.useMemo(() => {
        if (isNonEmptyArray(props.eventSegments) && isNonEmptyArray(props.organizationSegments)) {
            return (
                <>
                    <optgroup label={translate('vues_de_l_v_ne_57031')}>
                        {props.eventSegments.map((segment) => (
                            <option key={segment.id} value={segment.id}>
                                {segment.name}
                            </option>
                        ))}
                    </optgroup>

                    <optgroup label={translate('vues_de_l_organ_32624')}>
                        {props.organizationSegments.map((segment) => (
                            <option key={segment.id} value={segment.id}>
                                {segment.name}
                            </option>
                        ))}
                    </optgroup>
                </>
            );
        } else if (isNonEmptyArray(props.eventSegments)) {
            return (
                <>
                    {props.eventSegments.map((segment) => (
                        <option key={segment.id} value={segment.id}>
                            {segment.name}
                        </option>
                    ))}
                </>
            );
        } else if (isNonEmptyArray(props.organizationSegments)) {
            return (
                <>
                    {props.organizationSegments.map((segment) => (
                        <option key={segment.id} value={segment.id}>
                            {segment.name}
                        </option>
                    ))}
                </>
            );
        } else {
            return <></>;
        }
    }, [props.eventSegments, props.organizationSegments]);

    React.useEffect(() => {
        setOnlyViews(isNonEmptyArray(props.initialValues.form.segmentsIds));
    }, [props.initialValues.form.segmentsIds]);

    return (
        <FormContainer
            button={
                <Button isLoading={props.isMutateLoading} onClick={onSave}>
                    {translate('enregistrer_06519')}
                </Button>
            }
            title={props.name}
            onClose={onClose}
        >
            <FormParametersContainer
                isNotSavedOpen={isNotSavedModalOpen}
                onNotSavedClose={onNotSavedClose}
            >
                {props.loader || (
                    <>
                        <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                            {translate('diffusion_du_fo_47764')}
                        </Box>

                        <Spacer height="1" />

                        <Box color="gray500">{translate('personnalisez_l_57514')}</Box>

                        <Spacer height="4" />

                        <Box color="gray800" fontWeight="medium">
                            {translate('_qui_s_adresse_82507')}
                        </Box>

                        <Spacer height="3" />

                        <RadioText
                            state={onlyViews ? 'unchecked' : 'checked'}
                            onClick={() => {
                                setOnlyViews(false);
                                props.change('form.segmentsIds', []);
                            }}
                        >
                            {translate('_tout_le_monde_35921')}
                        </RadioText>

                        <Spacer height="3" />

                        <RadioText
                            state={onlyViews ? 'checked' : 'unchecked'}
                            onClick={() => {
                                setOnlyViews(true);
                            }}
                        >
                            {translate('_certaines_vue_55512')}
                        </RadioText>

                        {onlyViews && (
                            <>
                                <Spacer height="2" />

                                <Flex>
                                    <Spacer width="6" />

                                    <RichSelect
                                        isSearchVisible={true}
                                        multiple={true}
                                        name="form.segmentsIds"
                                    >
                                        {segments}
                                    </RichSelect>
                                </Flex>

                                <HorizontalSpacerSeparator height="6" />

                                <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                                    {translate('personnalisatio_15893')}
                                </Box>

                                <Spacer height="4" />

                                <TextInput
                                    label={translate('nom_de_l_tape_85862')}
                                    name="form.noAccessTitle"
                                />

                                <Spacer height="6" />

                                <RichEditor
                                    label={translate('message_affich_57692')}
                                    name="form.noAccessMessage"
                                />
                            </>
                        )}
                    </>
                )}
            </FormParametersContainer>
        </FormContainer>
    );
};

interface IFormParametersBroadcastProps {
    form: Emptyable<FormParametersBroadcastOrganizationQuery['organization']['form']>;
    eventSegments: Array<Pick<Segment, 'id' | 'name'>>;
    loader: React.JSX.Element | null;
    organizationSegments: Array<Pick<Segment, 'id' | 'name'>>;
}

const FormParametersBroadcast = (props: IFormParametersBroadcastProps) => {
    const {
        params: { organizationId, eventId, formId }
    } = useHeavent();
    const validateService = useService(ValidateService);
    const formParametersBroadcastInput = useService(FormParametersBroadcastInputService);
    const { mutate, isLoading } = useFormParametersBroadcastUpdateMutation();
    const initialValues = React.useMemo(
        () => ({
            form: formParametersBroadcastInput.formParametersBroadcastInputDefault(props.form)
        }),
        [props.form]
    );

    return (
        <Form
            direction="column"
            height={1}
            initialValues={initialValues}
            render={({ form, handleSubmit, values }) => (
                <FormParametersBroadcastInner
                    change={form.change}
                    eventSegments={props.eventSegments}
                    handleSubmit={handleSubmit}
                    initialValues={initialValues}
                    isMutateLoading={isLoading}
                    loader={props.loader}
                    name={props.form?.name ?? ''}
                    organizationSegments={props.organizationSegments}
                    values={values}
                />
            )}
            validate={validateService.validateForForm(
                formParametersBroadcastInput.updateFormParametersBroadcastSchema()
            )}
            width={1}
            onSubmit={(values: IUpdateFormParametersBroadcastValues) =>
                mutate({
                    organizationId,
                    eventId,
                    formId,
                    form: values.form
                })
            }
        />
    );
};

export const FormParametersBroadcastOrganization = () => {
    const {
        params: { organizationId, formId }
    } = useHeavent();
    const { data, loader } = useFormParametersBroadcastOrganizationQuery({
        organizationId,
        formId
    });
    useTitle(data.organization?.form.name ?? '');

    return (
        <FormParametersBroadcast
            eventSegments={[]}
            form={data.organization?.form}
            loader={loader}
            organizationSegments={data.organization?.segments ?? []}
        />
    );
};

export const FormParametersBroadcastEvent = () => {
    const {
        params: { organizationId, eventId, formId }
    } = useHeavent();
    const { data, loader } = useFormParametersBroadcastEventQuery({
        organizationId,
        eventId: eventId!,
        formId
    });
    useTitle(data.organization?.form.name ?? '');

    return (
        <FormParametersBroadcast
            eventSegments={data.event?.segments ?? []}
            form={data.organization?.form}
            loader={loader}
            organizationSegments={data.organization?.segments ?? []}
        />
    );
};
