import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Badge } from 'common/src/designSystem/components/badge';
import { LinkText } from 'common/src/designSystem/components/linkText';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import { BackofficeEventQuery, EventState, OrganizationId } from 'common/src/generated/types';
import { EventsPaths } from 'common/src/util/paths/eventsPaths';
import { getStateColor } from 'common/src/vo/event';
import * as React from 'react';
import { useBackofficeEventUnarchiveMutation } from '../../../generated/graphqlHooks';

interface IBackofficeEventRowProps {
    event: BackofficeEventQuery['backoffice']['events']['nodes'][number];
    organizationId: OrganizationId;

    reload(): void;
}

export const BackofficeEventRow = (props: IBackofficeEventRowProps) => {
    const { translate } = useHeavent();
    const { mutate } = useBackofficeEventUnarchiveMutation();

    return (
        <Row>
            <Cell>
                <LinkText
                    to={EventsPaths.EVENT({
                        organizationId: props.organizationId,
                        eventId: props.event.id
                    })}
                >
                    {props.event.name}
                </LinkText>
            </Cell>

            <Cell>
                <Badge color={getStateColor(props.event.state)}>
                    {translate(props.event.state)}
                </Badge>
            </Cell>

            <Cell>
                {props.event.state === EventState.Archived && (
                    <Button
                        color="invisible"
                        onClick={async () => {
                            await mutate({ eventId: props.event.id });

                            props.reload();
                        }}
                    >
                        {translate('d_sarchiver_76082')}
                    </Button>
                )}
            </Cell>
        </Row>
    );
};
