import { injectable } from 'inversify';
import * as yup from 'yup';
import {
    ALL_FORMPOSITIONRANKING,
    ALL_REGISTERPOSITIONDISPLAY,
    ALL_REGISTERPOSITIONFILTER,
    FormParametersMissionsInput,
    FormParametersMissionsQuery,
    FormPositionRanking,
    RegisterPositionDisplay,
    RegisterPositionFilter
} from '../generated/types';
import { TranslationService } from '../services/translationService';
import { InputService } from './inputService';

export interface IUpdateFormParametersMissionsValues {
    form: FormParametersMissionsInput;
}

@injectable()
export class FormParametersMissionsInputService extends InputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    formParametersMissionsInputDefault(
        form?: FormParametersMissionsQuery['organization']['form']
    ): FormParametersMissionsInput {
        return {
            positionDisplay: form?.positionDisplay || RegisterPositionDisplay.None,
            positionFilter: form?.positionFilter || RegisterPositionFilter.FilterWithSlots,
            positionRanking: form?.positionRanking || FormPositionRanking.Unranked,
            positionLimit: form?.positionLimit || 3,
            hiddenPositionsCategoriesIds: form?.hiddenPositionsCategoriesIds || [],
            hiddenPositionsIds: form?.hiddenPositionsIds || [],
            hiddenPositionsSlotsIds: form?.hiddenPositionsSlotsIds || [],
            displayedPositionsCategoriesIds: form?.displayedPositionsCategoriesIds || [],
            displayedPositionsIds: form?.displayedPositionsIds || [],
            displayedPositionsSlotsIds: form?.displayedPositionsSlotsIds || [],
            showFullPosition: form?.showFullPosition ?? true,
            showPositionCategoryDescription: form?.showPositionCategoryDescription ?? false,
            showPositionDescription: form?.showPositionDescription ?? false,
            positionTitle: form?.positionTitle || '',
            positionSubtitle: form?.positionSubtitle || '',
            willAutoAssign: form?.willAutoAssign ?? false
        };
    }

    formParametersMissionsInputSchema() {
        return yup.object().shape({
            positionDisplay: yup
                .string()
                .required(this.t('la_strat_gie_d_44170'))
                .oneOf(ALL_REGISTERPOSITIONDISPLAY, this.t('la_strat_gie_d_44170')),
            positionFilter: yup
                .string()
                .required(this.t('la_strat_gie_de_13294'))
                .oneOf(ALL_REGISTERPOSITIONFILTER, this.t('la_strat_gie_de_13294')),
            positionRanking: yup
                .string()
                .required(this.t('le_type_de_clas_66819'))
                .oneOf(ALL_FORMPOSITIONRANKING, this.t('le_type_de_clas_66819')),
            positionLimit: yup
                .number()
                .required(this.t('le_nombre_maxim_38249'))
                .min(1, this.t('le_nombre_de_ch_27910')),
            hiddenPositionsCategoriesIds: yup.array().of(yup.number()),
            hiddenPositionsIds: yup.array().of(yup.number()),
            hiddenPositionsSlotsIds: yup.array().of(yup.number()),
            displayedPositionsCategoriesIds: yup.array().of(yup.number()),
            displayedPositionsIds: yup.array().of(yup.number()),
            displayedPositionsSlotsIds: yup.array().of(yup.number()),
            showFullPosition: yup.boolean().required(),
            showPositionCategoryDescription: yup.boolean().required(),
            showPositionDescription: yup.boolean().required(),
            positionTitle: yup.string(),
            positionSubtitle: yup.string(),
            willAutoAssign: yup.boolean().required()
        });
    }

    updateFormParametersMissionsSchema() {
        return yup.object().shape({
            form: this.formParametersMissionsInputSchema()
        });
    }
}
