export const Images = {
    Aso: {
        HomeBackground: require('../../../../assets/img/aso/asoHomeBackground.jpg'),
        Logo: require('../../../../assets/img/aso/asoLogo.png')
    },
    Blanks: {
        Accreditation: require('../../../../assets/img/blanks/accreditation.png'),
        Member: require('../../../../assets/img/blanks/member.png')
    },
    Maps: {
        GooglePlan: require('../../../../assets/img/maps/googlePlan.png'),
        GoogleSatellite: require('../../../../assets/img/maps/googleSatellite.png'),
        IgnPlan: require('../../../../assets/img/maps/ignPlan.png')
    },
    AmicaleLogo: require('../../../../assets/img/amicaleLogo.png'),
    AvatarGroup: require('../../../../assets/img/avatarGroup.png'),
    Background404: require('../../../../assets/img/background404.jpg'),
    FormPositionRanked: require('../../../../assets/img/formPositionRanked.png'),
    FormPositionUnranked: require('../../../../assets/img/formPositionUnranked.png'),
    Google: require('../../../../assets/img/google.png'),
    GoogleMaps: require('../../../../assets/img/googleMaps.png'),
    Ign: require('../../../../assets/img/ign.png'),
    Login: require('../../../../assets/img/login.png'),
    Login2x: require('../../../../assets/img/login2x.png'),
    LogoWeezeventSquare: require('../../../../assets/img/logoWeezeventSquare.png'),
    MsoCreatePositions: require('../../../../assets/img/msoCreatePositions.png'),
    MsoLogo1: require('../../../../assets/img/msoLogo1.png'),
    MsoLogo2: require('../../../../assets/img/msoLogo2.png'),
    MsoLogo3: require('../../../../assets/img/msoLogo3.png'),
    MsoVolunteers: require('../../../../assets/img/msoVolunteers.png'),
    UserProfile: require('../../../../assets/img/userProfile.png')
};

export const Svgs = {
    AccreditationsBlank: require('../../../../assets/svg/accreditationsBlank.svg'),
    Alert: require('../../../../assets/svg/alert.svg'),
    AssignmentsBlank: require('../../../../assets/svg/assignmentsBlank.svg'),
    Check: require('../../../../assets/svg/check.svg'),
    DnsBlank: require('../../../../assets/svg/dnsBlank.svg'),
    DnsWrongDomain: require('../../../../assets/svg/dnsWrongDomain.svg'),
    Done: require('../../../../assets/svg/done.svg'),
    Done2: require('../../../../assets/svg/done2.svg'),
    EmailsSendersBlank: require('../../../../assets/svg/emailsSendersBlank.svg'),
    FormsBlank: require('../../../../assets/svg/formsBlank.svg'),
    FormsElementsBlank: require('../../../../assets/svg/formsElementsBlank.svg'),
    MemberAssignmentsBlank: require('../../../../assets/svg/memberAssignmentsBlank.svg'),
    MembersBlank: require('../../../../assets/svg/membersBlank.svg'),
    MsoLogin: require('../../../../assets/svg/msoLogin.svg'),
    PlanningBlank: require('../../../../assets/svg/planningBlank.svg'),
    PositionsBlank: require('../../../../assets/svg/positionsBlank.svg'),
    RecrewteerMarker: require('../../../../assets/svg/recrewteerMarker.svg'),
    Sad: require('../../../../assets/svg/sad.svg'),
    WeezeventBlank: require('../../../../assets/svg/weezeventBlank.svg')
};
