export enum PositionPermission {
    READ_POSITION,
    EDIT_POSITION,
    EDIT_MEMBER,
    EDIT_ASSIGNMENT_STATE,
    READ_COMMENTS,
    ADD_COMMENTS,
    EDIT_AVAILABILITIES,
    ASSIGN_MEMBER
}
