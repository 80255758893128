import { HeaventEnv } from './heaventEnv';
import { decodeBase64 } from './util/base64';
import { isNonEmptyString } from './util/string';
import { Url } from './util/url';

interface IAdmin {
    id: number;
    email: string;
}

const heaventSecrets =
    typeof process !== 'undefined' && isNonEmptyString(process?.env?.HEAVENT_SECRETS)
        ? JSON.parse(decodeBase64(process.env.HEAVENT_SECRETS))
        : null;

const read = <T = string>(name: string): T => {
    if (typeof window === 'object' && (window as any)[name] !== undefined) {
        return (window as any)[name];
    } else if (heaventSecrets?.[name] !== undefined) {
        return heaventSecrets[name];
    } else {
        throw new Error(`Missing env. var. ${name}`);
    }
};

const readMaybe = <T = string>(name: string): T | undefined | null => {
    if (typeof window === 'object' && (window as any)[name] !== undefined) {
        return (window as any)[name];
    } else if (heaventSecrets?.[name] !== undefined) {
        return isNonEmptyString(heaventSecrets[name]) ? (heaventSecrets[name] as T) : null;
    } else {
        return;
    }
};

export const readBoolean = (name: string): boolean => {
    const value = read<any>(name);

    if (typeof value === 'boolean') {
        return value;
    } else {
        return value === 'true';
    }
};

export const HEAVENT_APP_SUBDOMAIN = readMaybe('HEAVENT_APP_SUBDOMAIN') ?? 'app';
export const HEAVENT_API_SUBDOMAIN = readMaybe('HEAVENT_API_SUBDOMAIN') ?? 'api';
export const HEAVENT_PUBLIC_SUBDOMAIN = readMaybe('HEAVENT_PUBLIC_SUBDOMAIN') ?? 'event';
export const HEAVENT_DOMAIN = read('HEAVENT_DOMAIN');
const HEAVENT_BASENAME = readMaybe('HEAVENT_BASENAME') ?? '';

export const CommonEnvVars = {
    // Environment
    HEAVENT_ENV: read<HeaventEnv>('HEAVENT_ENV'),

    // Admin contact details
    ADMINS: read<IAdmin[]>('HEAVENT_ADMINS'),
    EMAIL_FROM: read('HEAVENT_EMAIL_FROM'),

    // App domains
    HEAVENT_DOMAIN,
    HEAVENT_BASENAME,
    HEAVENT_APP_URL: new Url(
        readMaybe('HEAVENT_APP_DOMAIN') ?? HEAVENT_DOMAIN,
        undefined,
        HEAVENT_APP_SUBDOMAIN,
        readMaybe('HEAVENT_APP_BASENAME') ?? HEAVENT_BASENAME
    ),
    HEAVENT_API_URL: new Url(
        readMaybe('HEAVENT_API_DOMAIN') ?? HEAVENT_DOMAIN,
        undefined,
        HEAVENT_API_SUBDOMAIN,
        readMaybe('HEAVENT_API_BASENAME') ?? HEAVENT_BASENAME
    ),
    HEAVENT_PUBLIC_URL: new Url(
        readMaybe('HEAVENT_PUBLIC_DOMAIN') ?? HEAVENT_DOMAIN,
        undefined,
        HEAVENT_PUBLIC_SUBDOMAIN,
        readMaybe('HEAVENT_PUBLIC_BASENAME') ?? HEAVENT_BASENAME
    ),

    // Features toggle
    USE_HUBSPOT: read<boolean>('HEAVENT_USE_HUBSPOT'),

    // Cognito
    COGNITO_USER_POOL_ID: read('HEAVENT_COGNITO_USER_POOL_ID'),
    COGNITO_USER_POOL_CLIENT_ID: read('HEAVENT_COGNITO_USER_POOL_CLIENT_ID'),
    COGNITO_DOMAIN_URL: read('HEAVENT_COGNITO_DOMAIN_URL'),
    COGNITO_CLIENT_CALLBACK_URL: read<string[]>('HEAVENT_COGNITO_CLIENT_CALLBACK_URL')[0],

    // Mixpanel
    USE_MIXPANEL: readBoolean('HEAVENT_USE_MIXPANEL'),
    MIXPANEL_TOKEN: readBoolean('HEAVENT_MIXPANEL_TOKEN'),

    // S3 buckets
    HEAVENT_AWS_UPLOADS_BUCKET: read('HEAVENT_AWS_UPLOADS_BUCKET'),

    // Should log token?
    LOG_TOKEN: read('HEAVENT_LOG_TOKEN'),

    // Weezevent
    WEEZEVENT_DOMAIN: read('HEAVENT_WEEZEVENT_DOMAIN')
};

export function getAdminsEmails() {
    return CommonEnvVars.ADMINS.map(({ email }) => email);
}
