import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';
import { useDocumentBuilderContext } from '../documentBuilderContext';
import { DocumentBuilderRightPanelPage } from './documentBuilderRightPanelPage';

export const DocumentBuilderRightPanel = () => {
    const { customDocument } = useDocumentBuilderContext();

    return (
        <Flex css={{ overflowY: 'auto' }} direction="column" height={1} width={1}>
            {customDocument.configuration.pages.map((page, pageIndex) => (
                <DocumentBuilderRightPanelPage key={page.id} index={pageIndex} page={page} />
            ))}
        </Flex>
    );
};
