/// <reference types='google.maps' />
import { getIGNPlanLayer } from 'common-front/src/components/map/ignLayers';
import { AcronymIcon } from 'common-front/src/localDatabase';
import { Box } from 'common/src/designSystem/components/box';
import { fromIconVO } from 'common/src/designSystem/components/i';
import { CommonEnvVars } from 'common/src/envVars';
import {
    MapLayer,
    PositionPositionsMapFragment,
    TraceId,
    TracePositionsMapFragment
} from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import {
    DEFAULT_CENTER,
    DEFAULT_ZOOM,
    extractCoordinates,
    getMapTypeId,
    IGoogleCoordinates,
    toGoogleCoordinates
} from 'common/src/util/map';
import { isEqual, noop } from 'lodash-es';
import * as React from 'react';

interface IPositionsMapProps {
    acronymIcon: AcronymIcon;
    hiddenTracesIds: TraceId[];
    initialCenter: Emptyable<IGoogleCoordinates>;
    initialZoom: Emptyable<number>;
    layer: MapLayer;
    mapOptions?: google.maps.MapOptions;
    positions: PositionPositionsMapFragment[];
    showMarker: boolean;
    traces: TracePositionsMapFragment[];

    onMapCenterChanged?(center: IGoogleCoordinates): void;
    onMapDrag?(): void;
    onMapZoomchanged?(zoom: number): void;
    onMapTilesloaded?(): void;
    onMarkerDragEnd?(latitude: number, longitude: number): void;
    onPositionClick?(position: PositionPositionsMapFragment, point: google.maps.Point): void;
}

export const PositionsMap = React.memo((props: IPositionsMapProps) => {
    const divRef = React.useCallback((node) => {
        if (node) {
            if (map.current) {
                overlay.current?.setMap(null);
                marker.current?.setMap(null);
                map.current = null;
            }

            map.current = new google.maps.Map(node, {
                ...(props.mapOptions ?? {}),
                zoom: props.initialZoom ?? DEFAULT_ZOOM,
                center: props.initialCenter ?? DEFAULT_CENTER,
                fullscreenControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                mapTypeId:
                    props.layer === MapLayer.Google
                        ? 'roadmap'
                        : props.layer === MapLayer.GoogleSatellite
                          ? 'satellite'
                          : props.layer,
                styles: [
                    {
                        featureType: 'poi',
                        stylers: [{ visibility: 'off' }]
                    },
                    {
                        featureType: 'poi.government',
                        stylers: [{ visibility: 'on' }]
                    },
                    {
                        featureType: 'poi.park',
                        stylers: [{ visibility: 'on' }]
                    }
                ]
            });
            map.current.mapTypes.set(MapLayer.IgnPlan, getIGNPlanLayer());

            overlay.current = new google.maps.OverlayView();
            overlay.current.draw = noop;
            overlay.current.setMap(map.current);

            map.current.addListener('drag', () => {
                props.onMapDrag?.();
            });
            map.current.addListener('center_changed', () => {
                props.onMapCenterChanged?.({
                    lat: map.current!.getCenter()!.lat(),
                    lng: map.current!.getCenter()!.lng()
                });
            });
            map.current.addListener('zoom_changed', () => {
                props.onMapZoomchanged?.(map.current!.getZoom()!);
            });
            map.current.addListener('click', (e: any) => {
                if (props.showMarker) {
                    marker.current?.setMap(null);

                    marker.current = new google.maps.Marker({
                        position: e.latLng,
                        map: map.current!,
                        draggable: true
                    });

                    props.onMarkerDragEnd?.(e.latLng.lat(), e.latLng.lng());

                    marker.current!.addListener('dragend', () => {
                        const position = marker.current!.getPosition()!;

                        props.onMarkerDragEnd?.(position.lat(), position.lng());
                    });
                }
            });
            map.current.addListener('tilesloaded', () => {
                setTimeout(() => {
                    props.onMapTilesloaded?.();
                }, 1_000);
            });

            placeMarkers();
            placeTraces();
        }
    }, []);
    const map = React.useRef<google.maps.Map | null>(null);
    const overlay = React.useRef<google.maps.OverlayView | null>(null);
    const marker = React.useRef<google.maps.Marker | null>(null);
    const markers = React.useRef<google.maps.Marker[]>([]);
    const lines = React.useRef<google.maps.Polyline[]>([]);
    const placeMarkers = () => {
        markers.current.forEach((m) => m.setMap(null));

        markers.current = props.positions.map((position) => {
            const coordinates = toGoogleCoordinates(extractCoordinates(position))!;

            const newMarker = new google.maps.Marker({
                position: coordinates,
                map: map.current!,
                icon:
                    props.acronymIcon === 'acronym'
                        ? `${CommonEnvVars.HEAVENT_API_URL}/icons/acronym.svg?color=${encodeURIComponent(
                              position.color
                          )}&text=${position.acronym}`
                        : `${CommonEnvVars.HEAVENT_API_URL}/icons/${fromIconVO(
                              position.icon
                          )}.svg?color=${encodeURIComponent(position.color)}`
            });

            newMarker.addListener('click', () => {
                const point = overlay.current
                    ?.getProjection()
                    ?.fromLatLngToContainerPixel(
                        new google.maps.LatLng(coordinates.lat, coordinates.lng)
                    );

                if (point) {
                    props.onPositionClick?.(position, point);
                }
            });

            return newMarker;
        });
    };
    const placeTraces = () => {
        lines.current.forEach((l) => l.setMap(null));

        lines.current = props.traces.map((trace) => {
            const path = trace.points.map(([lat, lng]: number[]) => ({ lat, lng }));

            const line = new google.maps.Polyline({
                path,
                geodesic: true,
                strokeColor: trace.color,
                strokeOpacity: 1,
                strokeWeight: 4,
                visible: !props.hiddenTracesIds.includes(trace.id)
            });

            line.setMap(map.current);

            return line;
        });
    };

    React.useEffect(() => {
        placeMarkers();
    }, [JSON.stringify(props.positions), props.acronymIcon]);

    React.useEffect(() => {
        map.current?.setMapTypeId(getMapTypeId(props.layer));
    }, [props.layer]);

    React.useEffect(() => {
        placeTraces();
    }, [JSON.stringify(props.traces), props.hiddenTracesIds]);

    return <Box ref={divRef} height={1} width={1} />;
}, isEqual);

PositionsMap.displayName = 'PositionsMap';
