import { Avatar } from 'common/src/components/avatar/avatar';
import { Box } from 'common/src/designSystem/components/box';
import { Link } from 'common/src/designSystem/components/link';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { VolunteerRegistrationFragment } from 'common/src/generated/types';
import { UsersPaths } from 'common/src/util/paths/usersPaths';
import { normalize } from 'common/src/util/string';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { Content } from '../../../designSystem/components/tooltip/content';
import { Tooltip } from '../../../designSystem/components/tooltip/tooltip';
import { Trigger } from '../../../designSystem/components/tooltip/trigger';
import { useHeavent } from '../../../hooks/useHeavent';

interface IVolunteerRegistrationTeamMembersCellProps {
    volunteerRegistration: VolunteerRegistrationFragment;
}

export const VolunteerRegistrationTeamMembersCell = (
    props: IVolunteerRegistrationTeamMembersCellProps
) => {
    const {
        params: { organizationId, eventId }
    } = useHeavent();
    const teamMembers = React.useMemo(
        () =>
            sortBy(
                props.volunteerRegistration.userInfo.formsUsersInfos.flatMap(
                    (fui) => fui.teamMembers || []
                ),
                (tm) => normalize(tm.name.toLowerCase())
            ),
        [props.volunteerRegistration]
    );

    return (
        <Cell>
            {teamMembers.map((tm, index) => (
                <Tooltip key={tm.id}>
                    <Trigger>
                        <Box
                            css={{
                                cursor: 'pointer',
                                position: 'relative',
                                left: `${-4 * index}px`
                            }}
                        >
                            <Link
                                to={UsersPaths.USER_INFORMATIONS({
                                    organizationId,
                                    eventId,
                                    userInfoId: tm.id
                                })}
                            >
                                <Avatar
                                    email={tm.email}
                                    image={tm.picture?.url}
                                    name={tm.name}
                                    size={24}
                                />
                            </Link>
                        </Box>
                    </Trigger>

                    <Content placement="top">{tm.name}</Content>
                </Tooltip>
            ))}
        </Cell>
    );
};
