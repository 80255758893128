import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { SegmentFolderReorderInput } from 'common/src/generated/types';
import { produce } from 'immer';
import * as React from 'react';
import { Draggable, DraggableProvided, Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { Button } from '../../../designSystem/components/button';
import { TextInput } from '../../../designSystem/components/textInput';
import { SegmentsFoldersReorderSegment } from './segmentsFoldersReorderSegment';

interface ISegmentsFoldersReorderSegmentFolderProps {
    canDelete: boolean;
    index: number;
    segmentFolder: SegmentFolderReorderInput;
    setSegmentsFolders: React.Dispatch<React.SetStateAction<SegmentFolderReorderInput[]>>;
}

export const SegmentsFoldersReorderSegmentFolder = (
    props: ISegmentsFoldersReorderSegmentFolderProps
) => {
    const [isEdit, setIsEdit] = React.useState(false);
    const onNameChange = React.useCallback(
        (newName: string) => {
            props.setSegmentsFolders(
                produce((currentSegmentsFolders: SegmentFolderReorderInput[]) => {
                    currentSegmentsFolders[props.index].name = newName;
                })
            );
        },
        [props.index, props.setSegmentsFolders]
    );

    return (
        <Draggable draggableId={`sf-${props.segmentFolder.id}`} index={props.index}>
            {(draggableProvided: DraggableProvided) => (
                <Flex
                    css={{
                        background: 'white',
                        border: '1px solid $gray200',
                        borderRadius: '$2',
                        boxShadow: '$sm',
                        overflow: 'hidden'
                    }}
                    direction="column"
                    {...draggableProvided.draggableProps}
                    ref={draggableProvided.innerRef}
                >
                    <Flex
                        align="center"
                        css={{
                            padding: '$4'
                        }}
                        gap="3"
                    >
                        <Box
                            color="gray300"
                            css={{ cursor: 'pointer' }}
                            fontSize="textMd"
                            {...draggableProvided.dragHandleProps}
                        >
                            <I icon="grip-vertical" iconStyle="solid" />
                        </Box>

                        {isEdit ? (
                            <Box css={{ flex: '1' }}>
                                <TextInput
                                    value={props.segmentFolder.name}
                                    onChange={onNameChange}
                                />
                            </Box>
                        ) : (
                            <Box css={{ flex: '1' }} font="gray800 textMd medium">
                                {props.segmentFolder.name}
                            </Box>
                        )}

                        <Button
                            color="white"
                            leftIcon={isEdit ? 'check' : 'pen'}
                            size="sm"
                            onClick={() => setIsEdit(!isEdit)}
                        />

                        <Button
                            color="white"
                            disabled={!props.canDelete}
                            leftIcon="trash-can"
                            size="sm"
                            onClick={() => {
                                props.setSegmentsFolders(
                                    (currentSegmentsFolders: SegmentFolderReorderInput[]) =>
                                        currentSegmentsFolders.filter(
                                            (sf) => sf !== props.segmentFolder
                                        )
                                );
                            }}
                        />
                    </Flex>

                    <Droppable droppableId={`drop-sf-${props.segmentFolder.id}`} type="segment">
                        {(droppableProvided: DroppableProvided) => (
                            <Flex
                                css={{
                                    background: '$gray100',
                                    padding: '$4'
                                }}
                                direction="column"
                                gap="2"
                                {...droppableProvided.droppableProps}
                                ref={droppableProvided.innerRef}
                            >
                                {props.segmentFolder.segments.map((segment, index) => (
                                    <SegmentsFoldersReorderSegment
                                        key={segment.id}
                                        canDelete={props.segmentFolder.segments.length > 1}
                                        index={index}
                                        segment={segment}
                                        segmentFolderIndex={props.index}
                                        setSegmentsFolders={props.setSegmentsFolders}
                                    />
                                ))}

                                {droppableProvided.placeholder}
                            </Flex>
                        )}
                    </Droppable>
                </Flex>
            )}
        </Draggable>
    );
};
