import { executeDelegationsMassEditInfosQuery } from 'common-front/src/generated/graphqlHooks';
import { useCachedDataLoading } from 'common-front/src/hooks/useCachedDataLoading';
import {
    DelegationId,
    DelegationsMassEditInfosQuery,
    EventId,
    Form,
    OrganizationEventLevel,
    OrganizationId,
    TagsQuery
} from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';

interface IDelegationsContext {
    getDelegationsCategories(): Promise<
        DelegationsMassEditInfosQuery['organization']['delegationsCategories']['nodes']
    >;
    getEditPath(delegationId: DelegationId): string;
    getShowPath(delegationId: DelegationId): string;
    getForms(): Promise<Array<Pick<Form, 'id' | 'name'>>>;
    getTags(): Promise<TagsQuery['organization']['tags']['nodes']>;
}

const DelegationsContext = React.createContext<IDelegationsContext>({} as IDelegationsContext);

interface IDelegationsContextProviderProps {
    children: React.ReactNode;
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;

    getEditPath(delegationId: DelegationId): string;
    getShowPath(delegationId: DelegationId): string;
}

export const DelegationsContextProvider = (props: IDelegationsContextProviderProps) => {
    const getDelegationsCategories = useCachedDataLoading(
        executeDelegationsMassEditInfosQuery,
        {
            organizationId: props.organizationId,
            eventId: props.eventId,
            level: props.eventId
                ? OrganizationEventLevel.Event
                : OrganizationEventLevel.Organization
        },
        (d) => d.organization.delegationsCategories.nodes
    );
    const getForms = useCachedDataLoading(
        executeDelegationsMassEditInfosQuery,
        {
            organizationId: props.organizationId,
            eventId: props.eventId,
            level: props.eventId
                ? OrganizationEventLevel.Event
                : OrganizationEventLevel.Organization
        },
        (d) => d.organization.forms
    );
    const getTags = useCachedDataLoading(
        executeDelegationsMassEditInfosQuery,
        {
            organizationId: props.organizationId,
            eventId: props.eventId,
            level: props.eventId
                ? OrganizationEventLevel.Event
                : OrganizationEventLevel.Organization
        },
        (d) => d.organization.tags.nodes
    );

    return (
        <DelegationsContext.Provider
            value={{
                getDelegationsCategories,
                getEditPath: props.getEditPath,
                getShowPath: props.getShowPath,
                getForms,
                getTags
            }}
        >
            {props.children}
        </DelegationsContext.Provider>
    );
};

export function useDelegationsContext() {
    return React.useContext(DelegationsContext);
}
