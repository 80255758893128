import { DropdownEllipsis } from 'common-front/src/designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Icon } from 'common-front/src/designSystem/components/icon';
import { useVolunteersRegistrationsMassAccreditMutation } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { LinkText } from 'common/src/designSystem/components/linkText';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import { MassAssignStrategy, UserProfileAccreditationsQuery } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { AccreditationsPaths } from 'common/src/util/paths/accreditationsPaths';
import { fullName } from 'common/src/vo/accreditationSlot';
import * as React from 'react';

interface IUserAccreditationsAssignedListRowProps {
    aui: UserProfileAccreditationsQuery['event']['volunteerRegistration']['accreditationsUsersInfos'][number];
    isWeezeventConnected: boolean;

    reload(): void;
}

export const UserAccreditationsAssignedListRow = (
    props: IUserAccreditationsAssignedListRowProps
) => {
    const {
        translate,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const { mutate: massAccredit } = useVolunteersRegistrationsMassAccreditMutation();
    const slotName = React.useMemo(
        () => fullName(dateTimeService, props.aui.accreditationSlot, props.aui.accreditation.name),
        [props.aui]
    );

    return (
        <Row>
            <Cell align="center" gap="3">
                <Icon color={props.aui.accreditation.color} icon={props.aui.accreditation.icon} />

                <Flex direction="column">
                    <LinkText
                        to={AccreditationsPaths.ACCREDITATION({
                            organizationId,
                            eventId,
                            accreditationId: props.aui.accreditation.id
                        })}
                    >
                        {props.aui.accreditation.name}
                    </LinkText>

                    <Box color="gray500">{slotName}</Box>
                </Flex>
            </Cell>

            <Cell>
                {props.aui.isAutoAccredit && (
                    <Box color="success700" fontSize="textMd">
                        <I icon="check" />
                    </Box>
                )}
            </Cell>

            {props.isWeezeventConnected && (
                <Cell>
                    {props.aui.scannedAt?.isValid &&
                        dateTimeService.toLocaleString(
                            props.aui.scannedAt.toLocal(),
                            LocaleFormats.DateTime
                        )}
                </Cell>
            )}

            <CellControls justify="end">
                <DropdownEllipsis>
                    <ItemIcon
                        color="red"
                        icon="trash-can"
                        onClick={async () => {
                            await massAccredit({
                                eventId,
                                massAccredit: {
                                    accreditationsSlotsIds: [props.aui.accreditationSlot.id],
                                    selecteds: { ids: [userInfoId] },
                                    strategy: MassAssignStrategy.Delete
                                }
                            });

                            props.reload();
                        }}
                    >
                        {translate('d_saffecter_du_47122')}
                    </ItemIcon>
                </DropdownEllipsis>
            </CellControls>
        </Row>
    );
};
