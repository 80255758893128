import { Empty } from 'common-front/src/components/empty/empty';
import { NoFeature } from 'common-front/src/components/noFeature';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { Flex } from 'common/src/designSystem/components/flex';
import { Feature } from 'common/src/generated/types';
import { CommunityPaths } from 'common/src/util/paths/communityPaths';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import { FormsPaths } from 'common/src/util/paths/formsPaths';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { UsersPaths } from 'common/src/util/paths/usersPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
    CreateOrganizationCampaign,
    UpdateOrganizationCampaign
} from '../campaigns/create/createUpdateCampaign';
import { OrganizationCampaign } from '../campaigns/show/campaign';
import {
    CreateOrganizationDelegation,
    UpdateOrganizationDelegation
} from '../delegations/create/createUpdateDelegation';
import { DelegationLoader } from '../delegations/show/delegationLoader';
import { DelegationCategoryLoader } from '../delegationsCategories/show/delegationCategoryLoader';
import { FilesImport } from '../files/import/filesImport';
import { OrganizationForms } from '../forms/list/forms';
import { FormIntegration } from '../forms/show/integration/formIntegration';
import { FormPreview } from '../forms/show/preview/formPreview';
import { FormElements } from '../forms/update/elements/formElements';
import { FormEmails } from '../forms/update/emails/formEmails';
import { FormParametersBroadcastOrganization } from '../forms/update/parameters/broadcast/formParametersBroadcast';
import { FormParametersDesign } from '../forms/update/parameters/design/formParametersDesign';
import { FormParametersGeneral } from '../forms/update/parameters/general/formParametersGeneral';
import { FormParametersState } from '../forms/update/parameters/state/formParametersState';
import { OrganizationComponent } from '../organization/organizationComponent';
import { useOrganizationContext } from '../organization/organizationContext';
import { UserOverlayOrganization } from '../users/overlay/userOverlay';
import { UpdateUserLoaderOrganization } from '../users/update/updateUserLoader';
import { OrganizationVolunteersImport } from '../volunteers/import/volunteersImport';
import { CommunityCampaignsLoader } from './campaigns/list/communityCampaignsLoader';
import { CommunityContainer } from './communityContainer';
import { CommunityDashboard } from './dashboard/communityDashboard';
import { CommunityDelegationsHasFeature } from './delegations/list/communityDelegationsHasFeature';
import { CommunityUsersLoader } from './users/communityUsersLoader';
import { CommunityUsersDuplicates } from './usersDuplicates/communityUsersDuplicates';

export const Community = () => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();
    useTitle(translate('communaut_20896'));
    const { hasFeature } = useOrganizationContext();

    return hasFeature(Feature.Community) ? (
        <Switch>
            <Route path={CommunityPaths.COMMUNITY_DASHBOARD({ organizationId: ':organizationId' })}>
                <CommunityContainer>
                    <CommunityDashboard />
                </CommunityContainer>
            </Route>

            <Route path={HeaventPaths.COMMUNITY_USERS_DUPLICATES(':organizationId')}>
                <CommunityUsersDuplicates />
            </Route>

            <Route path={CommunityPaths.COMMUNITY_USERS({ organizationId: ':organizationId' })}>
                <CommunityContainer>
                    <CommunityUsersLoader />
                </CommunityContainer>
            </Route>

            <Route
                path={[
                    HeaventPaths.COMMUNITY_EDIT_USER(':organizationId', ':userInfoId'),
                    HeaventPaths.COMMUNITY_EDIT_USER_DELEGATION(
                        ':organizationId',
                        ':delegationId',
                        ':userInfoId'
                    )
                ]}
            >
                <OrganizationComponent>
                    <UpdateUserLoaderOrganization />
                </OrganizationComponent>
            </Route>

            <Route path={HeaventPaths.COMMUNITY_CREATE_CAMPAIGN(':organizationId', ':options?')}>
                <CreateOrganizationCampaign />
            </Route>

            <Route path={HeaventPaths.COMMUNITY_EDIT_CAMPAIGN(':organizationId', ':campaignId')}>
                <UpdateOrganizationCampaign />
            </Route>

            <Route path={HeaventPaths.COMMUNITY_CAMPAIGNS(':organizationId')}>
                <CommunityContainer>
                    <CommunityCampaignsLoader />
                </CommunityContainer>
            </Route>

            <Route path={HeaventPaths.COMMUNITY_CAMPAIGN(':organizationId', ':campaignId')}>
                <CommunityContainer>
                    <OrganizationCampaign />
                </CommunityContainer>
            </Route>

            <Route path={FormsPaths.FORMS({ organizationId: ':organizationId' })}>
                <CommunityContainer>
                    <OrganizationForms />
                </CommunityContainer>
            </Route>

            <Route
                path={FormsPaths.FORM_ELEMENTS({
                    organizationId: ':organizationId',
                    formId: ':formId'
                })}
            >
                <FormElements />
            </Route>

            <Route
                path={FormsPaths.FORM_PARAMETERS_GENERAL({
                    organizationId: ':organizationId',
                    formId: ':formId'
                })}
            >
                <FormParametersGeneral />
            </Route>

            <Route
                path={FormsPaths.FORM_PARAMETERS_DESIGN({
                    organizationId: ':organizationId',
                    formId: ':formId'
                })}
            >
                <FormParametersDesign />
            </Route>

            <Route
                path={FormsPaths.FORM_PARAMETERS_BROADCAST({
                    organizationId: ':organizationId',
                    formId: ':formId'
                })}
            >
                <FormParametersBroadcastOrganization />
            </Route>

            <Route
                path={FormsPaths.FORM_PARAMETERS_STATE({
                    organizationId: ':organizationId',
                    formId: ':formId'
                })}
            >
                <FormParametersState />
            </Route>

            <Route
                path={FormsPaths.FORM_INTEGRATION({
                    organizationId: ':organizationId',
                    formId: ':formId'
                })}
            >
                <FormIntegration />
            </Route>

            <Route
                path={FormsPaths.FORM_EMAILS({
                    organizationId: ':organizationId',
                    formId: ':formId'
                })}
            >
                <FormEmails />
            </Route>

            <Route
                path={FormsPaths.FORM_PREVIEW({
                    organizationId: ':organizationId',
                    formId: ':formId'
                })}
            >
                <FormPreview />
            </Route>

            <Route path={HeaventPaths.COMMUNITY_VOLUNTEERS_IMPORT(':organizationId')}>
                <OrganizationVolunteersImport />
            </Route>

            <Route path={HeaventPaths.COMMUNITY_FILES_IMPORT(':organizationId')}>
                <FilesImport />
            </Route>

            <Route
                path={UsersPaths.USER({
                    organizationId: ':organizationId',
                    userInfoId: ':userInfoId'
                })}
            >
                <CommunityContainer>
                    <UserOverlayOrganization />
                </CommunityContainer>
            </Route>

            {hasFeature(Feature.Delegation) && (
                <>
                    <Route
                        path={HeaventPaths.COMMUNITY_CREATE_DELEGATION(
                            ':organizationId',
                            ':options?'
                        )}
                    >
                        <CreateOrganizationDelegation />
                    </Route>

                    <Route
                        path={HeaventPaths.COMMUNITY_EDIT_DELEGATION(
                            ':organizationId',
                            ':delegationId'
                        )}
                    >
                        <UpdateOrganizationDelegation />
                    </Route>

                    <Route path={HeaventPaths.COMMUNITY_DELEGATIONS(':organizationId')}>
                        <CommunityContainer>
                            <CommunityDelegationsHasFeature />
                        </CommunityContainer>
                    </Route>

                    <Route
                        path={DelegationsPaths.DELEGATION_CATEGORY({
                            organizationId: ':organizationId',
                            delegationCategoryId: ':delegationCategoryId'
                        })}
                    >
                        <CommunityContainer>
                            <DelegationCategoryLoader isEvent={false} />
                        </CommunityContainer>
                    </Route>

                    <Route
                        path={HeaventPaths.COMMUNITY_DELEGATION(':organizationId', ':delegationId')}
                    >
                        <CommunityContainer>
                            <DelegationLoader />
                        </CommunityContainer>
                    </Route>
                </>
            )}

            <Route>
                <Empty
                    path={CommunityPaths.COMMUNITY_DASHBOARD({ organizationId })}
                    replace={true}
                />
            </Route>
        </Switch>
    ) : (
        <CommunityContainer>
            <Flex align="center" height={1} justify="center" width={1}>
                <NoFeature
                    icon="crown"
                    subtitle={translate('contactez_nous_69860')}
                    title={translate('pour_en_profite_62789')}
                />
            </Flex>
        </CommunityContainer>
    );
};
