import { injectable } from 'inversify';
import * as yup from 'yup';
import { CustomFieldId, PositionsImportInput } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { CsvInputService, CsvSettings } from './csvInput';
import { InputService } from './inputService';

export const POSITIONS_IMPORT_FIELDS_DEFAULT = {
    /* Required */
    SlotStart: 'slotStart',
    SlotEnd: 'slotEnd',
    MaxResources: 'slotMaxResources',
    PositionName: 'positionName',
    PositionCategoryName: 'positionCategoryName',

    /* Optional */
    SlotName: 'slotName',
    Acronym: 'acronym',
    Color: 'color',
    Icon: 'icon',
    Description: 'description',
    Address: 'address',
    Latitude: 'latitude',
    Longitude: 'longitude',
    MapLayer: 'mapLayer',
    MapZoom: 'mapZoom',
    LeadersEmails: 'leadersEmails',
    LeadersFirstNames: 'leadersFirstnames',
    LeadersLastNames: 'leadersLastnames',
    PrivateNote: 'privateNote',
    Tags: 'tags'
};

export const POSITIONS_IMPORT_FIELDS_REQUIRED = [
    POSITIONS_IMPORT_FIELDS_DEFAULT.SlotStart,
    POSITIONS_IMPORT_FIELDS_DEFAULT.SlotEnd,
    POSITIONS_IMPORT_FIELDS_DEFAULT.MaxResources,
    POSITIONS_IMPORT_FIELDS_DEFAULT.PositionName,
    POSITIONS_IMPORT_FIELDS_DEFAULT.PositionCategoryName
];

@injectable()
export class PositionsImportInputService extends InputService {
    constructor(
        private csvInputService: CsvInputService,
        translationService: TranslationService
    ) {
        super(translationService);
    }

    default(): { input: PositionsImportInput } {
        return {
            input: {
                csv: this.csvInputService.csvDocumentInputDefault(),
                mappings: [],
                presets: []
            }
        };
    }

    schema({ delimiter, newline, quoteChar }: CsvSettings) {
        return yup.object().shape({
            input: yup.object().shape({
                csv: this.csvInputService.schema({
                    defaultFields: POSITIONS_IMPORT_FIELDS_DEFAULT,
                    requiredFields: POSITIONS_IMPORT_FIELDS_REQUIRED,
                    delimiter,
                    newline,
                    quoteChar
                }),
                mappings: yup.array().of(yup.string().nullable()),
                presets: yup.array().of(
                    yup.object().shape({
                        customFieldId: yup.number().positive(this.t('le_choix_du_typ_07079')),
                        value: yup.mixed().when('customFieldId', {
                            is: (customFieldId: CustomFieldId) => customFieldId > -1,
                            then: (schema: yup.MixedSchema) =>
                                schema.required(this.t('la_valeur_du_ch_65599'))
                        })
                    })
                )
            })
        });
    }
}
