import { Checkbox, ICheckboxState } from 'common-front/src/designSystem/components/checkbox';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { CommunityUserCells } from 'common-front/src/users/list/communityUserCells';
import { Box } from 'common/src/designSystem/components/box';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    SegmentCustomFieldFragment,
    UserInfoCommunityFragment,
    UsersInfoId
} from 'common/src/generated/types';
import { preventDefault } from 'common/src/util/links';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';

interface ICommunityUserRowProps {
    columns: PossibleColumn[];
    customFields: SegmentCustomFieldFragment[];
    state: ICheckboxState;
    userInfo: UserInfoCommunityFragment;

    reloadUserInfo(id: UsersInfoId): void;
    toggleRow(key: string, newState: ICheckboxState): void;
}

export const CommunityUserRow = (props: ICommunityUserRowProps) => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();
    const slugToCustomField = React.useMemo(
        () => new Map(props.customFields.map((cf) => [cf.slug, cf])),
        [props.customFields]
    );
    const reloadUserInfo = React.useCallback(() => {
        props.reloadUserInfo(props.userInfo.id);
    }, [props.userInfo, props.reloadUserInfo]);

    return (
        <Row
            css={{
                background: props.state === 'checked' ? '$primary100' : 'white',
                '&:hover': {
                    background: props.state === 'checked' ? '$primary100' : '$gray100'
                }
            }}
        >
            <Cell css={{ paddingRight: 0 }} width={48}>
                <Checkbox
                    state={props.state}
                    onClick={(newState, e) => {
                        preventDefault(e);

                        props.toggleRow(`ui${props.userInfo.id}`, newState);
                    }}
                />
            </Cell>

            {props.columns.map((field) => {
                const customField = slugToCustomField.get(field.slug);

                return (
                    <CommunityUserCells
                        key={field.slug}
                        canClickMemberName={true}
                        column={field}
                        customField={customField}
                        reloadRow={reloadUserInfo}
                        row={props.userInfo}
                        updatable={true}
                    />
                );
            })}

            <CellControls justify="center">
                <Tooltip>
                    <Trigger>
                        <Box>
                            <I
                                icon="pen"
                                to={HeaventPaths.COMMUNITY_EDIT_USER(
                                    organizationId,
                                    props.userInfo.id
                                )}
                            />
                        </Box>
                    </Trigger>

                    <Content placement="top">{translate('_diter_62574')}</Content>
                </Tooltip>
            </CellControls>
        </Row>
    );
};
