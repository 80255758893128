import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    CheckInState,
    CheckInStrategy,
    EventId,
    PositionsSlotsUsersInfoId
} from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Button } from '../../designSystem/components/button';
import { DatetimeInput } from '../../designSystem/components/date/datetimeInput';
import { Modal } from '../../designSystem/components/modal/modal';
import { RadioText } from '../../designSystem/components/radio';
import { usePositionsSlotsUsersInfosCheckInMutation } from '../../generated/graphqlHooks';
import { useHeavent } from '../../hooks/useHeavent';

interface IUpdateCheckInModalProps {
    currentState?: CheckInState;
    isOpen: boolean;
    positionSlotUserInfo: {
        id: PositionsSlotsUsersInfoId;
        checkInAt?: Emptyable<DateTime>;
        checkOutAt?: Emptyable<DateTime>;
        eventId: EventId;
        state?: CheckInState;
    };

    onClose(): void;
}

export const UpdateCheckInModal = (props: IUpdateCheckInModalProps) => {
    const { translate } = useHeavent();
    const { mutate: checkIn } = usePositionsSlotsUsersInfosCheckInMutation();
    const [strategy, setStrategy] = React.useState<CheckInStrategy>(CheckInStrategy.CheckIn);
    const [checkInAt, setCheckInAt] = React.useState<DateTime>(
        props.positionSlotUserInfo.checkInAt?.toLocal() ?? DateTime.now()
    );
    const [checkOutAt, setCheckOutAt] = React.useState<DateTime>(
        props.positionSlotUserInfo.checkOutAt?.toLocal() ?? DateTime.now()
    );
    const isCheckOutDisabled = props.currentState === CheckInState.Waiting;

    return (
        <Modal.Root isOpen={props.isOpen} onClose={props.onClose}>
            <Modal.Portal>
                <Modal.Header icon="sliders">{translate('mise_jour_du_76261')}</Modal.Header>

                <Modal.Content gap="2">
                    <Box font="gray700 textSm medium">{translate('que_souhaitez_v_87607')}</Box>

                    <RadioText
                        state={strategy === CheckInStrategy.CheckIn ? 'checked' : 'unchecked'}
                        onClick={() => {
                            setStrategy(CheckInStrategy.CheckIn);
                        }}
                    >
                        {translate('mettre_jour_l_75073')}
                    </RadioText>

                    {strategy === CheckInStrategy.CheckIn && (
                        <Flex css={{ paddingBottom: '$3', paddingLeft: '$6' }}>
                            <DatetimeInput value={checkInAt} onChange={setCheckInAt} />
                        </Flex>
                    )}

                    <RadioText
                        disabled={isCheckOutDisabled}
                        state={strategy === CheckInStrategy.CheckOut ? 'checked' : 'unchecked'}
                        onClick={() => {
                            setStrategy(CheckInStrategy.CheckOut);
                        }}
                    >
                        {translate('mettre_jour_l_19856')}
                    </RadioText>

                    {strategy === CheckInStrategy.CheckOut && (
                        <Flex css={{ paddingBottom: '$3', paddingLeft: '$6' }}>
                            <DatetimeInput value={checkOutAt} onChange={setCheckOutAt} />
                        </Flex>
                    )}

                    <RadioText
                        state={strategy === CheckInStrategy.Reset ? 'checked' : 'unchecked'}
                        onClick={() => {
                            setStrategy(CheckInStrategy.Reset);
                        }}
                    >
                        {translate('remettre_z_ro_11572')}
                    </RadioText>
                </Modal.Content>

                <Modal.Buttons>
                    {(onClose) => (
                        <Button
                            onClick={async () => {
                                await checkIn({
                                    eventId: props.positionSlotUserInfo.eventId,
                                    checkIn: {
                                        dateTime: (strategy === CheckInStrategy.CheckIn
                                            ? checkInAt
                                            : checkOutAt
                                        ).toUTC(),
                                        strategy,
                                        positionsSlotsUsersInfosIds: [props.positionSlotUserInfo.id]
                                    }
                                });

                                onClose();
                            }}
                        >
                            {translate('mettre_jour_85141')}
                        </Button>
                    )}
                </Modal.Buttons>
            </Modal.Portal>
        </Modal.Root>
    );
};
