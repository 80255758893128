import { UUID } from 'common/src/util/uuid';
import { DocumentElement } from 'common/src/vo/documentBuilder';
import * as React from 'react';
import { DocumentBuilderContainer } from './documentBuilderContainer';
import { DocumentBuilderImage } from './documentBuilderImage';
import { DocumentBuilderText } from './documentBuilderText';

interface IDocumentBuilderElementProps {
    element: DocumentElement;
    pageId: UUID;
}

export const DocumentBuilderElement = (props: IDocumentBuilderElementProps) => {
    if (props.element.type === 'container') {
        return <DocumentBuilderContainer container={props.element} pageId={props.pageId} />;
    } else if (props.element.type === 'image') {
        return <DocumentBuilderImage image={props.element} pageId={props.pageId} />;
    } else if (props.element.type === 'text') {
        return <DocumentBuilderText pageId={props.pageId} text={props.element} />;
    } else {
        return null;
    }
};
