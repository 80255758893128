import { Flex } from 'common/src/designSystem/components/flex';
import { fromIconVO, I } from 'common/src/designSystem/components/i';
import { styled } from 'common/src/designSystem/components/stitches';
import { Icon as IconVO } from 'common/src/generated/types';
import { getColorPalette } from 'common/src/util/color';
import * as React from 'react';

const _Icon = styled(Flex, {
    borderRadius: '$2',
    fontSize: '$textSm',
    height: '32px',
    width: '32px',
    '@tablet': {
        borderRadius: '$3',
        fontSize: '$textMd',
        height: '40px',
        width: '40px'
    }
});

interface IIconProps {
    color: string;
    icon: IconVO;
}

export const Icon = (props: IIconProps) => {
    const palette = React.useMemo(() => getColorPalette(props.color), [props.color]);

    return (
        <_Icon
            align="center"
            css={{
                background: palette['100'],
                color: palette['700']
            }}
            justify="center"
        >
            <I icon={fromIconVO(props.icon)} />
        </_Icon>
    );
};
