import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { Row } from 'common/src/designSystem/components/table/row';
import { UserPositionsQuery } from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import { noop } from 'lodash-es';
import * as React from 'react';

interface IUserPositionsPreassignedProps {
    volunteerRegistration: UserPositionsQuery['event']['volunteerRegistration'];
}

export const UserPositionsPreassigned = (props: IUserPositionsPreassignedProps) => {
    const {
        history,
        params: { organizationId, eventId },
        translate
    } = useHeavent();
    const intervalService = useService(IntervalService);
    const preassigneds = React.useMemo(() => {
        const positions = props.volunteerRegistration.preassignPositions.map((position) => ({
            name: position.name,
            range: position.range ? intervalService.toDisplayString(position.range) : '-',
            path: PositionsPaths.POSITION({
                organizationId,
                eventId,
                positionId: position.id
            })
        }));
        const categories = props.volunteerRegistration.preassignPositionsCategories.map(
            (category) => ({
                name: category.name,
                range: category.range ? intervalService.toDisplayString(category.range) : '-',
                path: PositionsPaths.POSITION_CATEGORY({
                    organizationId,
                    eventId,
                    positionCategoryId: category.id
                })
            })
        );

        return positions.concat(categories);
    }, [props.volunteerRegistration]);

    return isNonEmptyArray(preassigneds) ? (
        <TableFilters
            filters={<></>}
            headerCells={
                <>
                    <HeaderCell>{translate('nom_de_la_missi_64605')}</HeaderCell>
                    <HeaderCell>{translate('Date')}</HeaderCell>
                </>
            }
            numberOfPages={0}
            offset={0}
            rows={
                <>
                    {preassigneds.map((preassigned, index) => (
                        <Row key={index}>
                            <Cell>
                                <Box
                                    color="gray900"
                                    css={{ cursor: 'pointer' }}
                                    fontWeight="medium"
                                    onClick={() => {
                                        history.push(preassigned.path);
                                    }}
                                >
                                    {preassigned.name}
                                </Box>
                            </Cell>
                            <Cell>{preassigned.range}</Cell>
                        </Row>
                    ))}
                </>
            }
            setOffset={noop}
            title={translate('pr_affectation_68999')}
            totalCount={0}
        />
    ) : null;
};
