import { Planning } from 'common-front/src/components/planning/planning';
import { PaginationCell } from 'common-front/src/designSystem/components/pagination/paginationCell';
import { PaginationRow } from 'common-front/src/designSystem/components/pagination/paginationRow';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Flex } from 'common/src/designSystem/components/flex';
import { isNonEmptyString } from 'common/src/util/string';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useVolunteersPlanningQuery } from '../../../generated/graphqlHooks';
import { localStateDateTimeSerde, useLocalEventState } from '../../../hooks/useLocalState';
import { VolunteersPlanningLeftCell } from './volunteersPlanningLeftCell';
import { VolunteersPlanningSlot } from './volunteersPlanningSlot';

interface IVolunteersPlanningProps {
    endAt: DateTime;
    startAt: DateTime;
}

export const VolunteersPlanning = (props: IVolunteersPlanningProps) => {
    const {
        params: { eventId }
    } = useHeavent();
    const { limit, nameDebounced, offset, predicates, setLimit, setOffset } = useSegmentsContext();
    const { data, loader } = useVolunteersPlanningQuery({
        eventId,
        predicates,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        limit,
        offset
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.data?.rows);
    const members = React.useMemo(
        () =>
            (data.data?.rows.nodes ?? []).map((vr) => ({
                ...vr,
                slots: vr.slots
                    .map(
                        (slot) =>
                            ({
                                type: 'availability',
                                range: slot.range
                            }) as any
                    )
                    .concat(
                        vr.positionsSlotsUsersInfos.map((psui) => ({
                            type: 'assignment',
                            range: psui.positionSlot.range,
                            positionCategory: psui.positionCategory,
                            position: psui.position,
                            positionSlot: psui.positionSlot
                        }))
                    )
            })),
        [data.data]
    );
    const [startAt, setStartAt] = useLocalEventState(
        'volunteers.planning.startAt',
        props.startAt,
        localStateDateTimeSerde
    );

    if (loader) {
        return loader;
    } else {
        return (
            <Flex css={{ flex: '1' }} direction="column" width={1}>
                <Flex css={{ background: 'white', flex: '1' }}>
                    <Planning.Root
                        data={members}
                        date={startAt}
                        maxDate={props.endAt}
                        minDate={props.startAt}
                        renderLeftCell={(vr: any) => <VolunteersPlanningLeftCell vr={vr} />}
                        renderSlot={(slot: any) => <VolunteersPlanningSlot slot={slot} />}
                        showPlus={false}
                        onChange={setStartAt}
                    />
                </Flex>

                <PaginationRow>
                    <PaginationCell
                        limit={limit}
                        numberOfPages={numberOfPages}
                        offset={offset}
                        setLimit={setLimit}
                        setOffset={setOffset}
                        showLimits={true}
                        totalCount={totalCount}
                    />
                </PaginationRow>
            </Flex>
        );
    }
};
