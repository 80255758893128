import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { UserAccreditationsWished } from 'common-front/src/users/information/userAccreditationsWished';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { UserProfileAccreditationsQuery } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { useUserProfileAccreditationsQuery } from '../../../generated/graphqlHooks';
import { UserAccreditationsAssigned } from './userAccreditationsAssigned';

interface IUserAccreditationsComponentProps {
    event: UserProfileAccreditationsQuery['event'];
    organization: UserProfileAccreditationsQuery['organization'];

    reload(): void;
}

const UserAccreditationsComponent = (props: IUserAccreditationsComponentProps) => {
    const {
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const showWished = React.useMemo(
        () =>
            isNonEmptyArray(
                props.organization.userInfo.formsUsersInfos.filter((fui) =>
                    isNonEmptyArray(fui.accreditationsSlots)
                )
            ) ||
            (props.event?.hasFormWithAccreditationDisplay ?? false),
        [props.organization, props.event]
    );

    return (
        <Flex direction="column" gap="6" width={1}>
            {isNonEmptyArray(props.event.volunteerRegistration.accreditationsUsersInfos) && (
                <UserAccreditationsAssigned event={props.event} reload={props.reload} />
            )}

            {showWished && (
                <UserAccreditationsWished
                    editPath={HeaventPaths.EDIT_USER_ACCREDITATIONS(
                        organizationId,
                        eventId,
                        userInfoId
                    )}
                    formsUsersInfos={props.organization.userInfo.formsUsersInfos}
                />
            )}

            <Spacer height="8" />
        </Flex>
    );
};

export const UserAccreditations = () => {
    const { organizationId, eventId, userInfoId } = useParams();
    const { data, loader, reload } = useUserProfileAccreditationsQuery({
        organizationId,
        eventId,
        userInfoId
    });

    return (
        loader || (
            <UserAccreditationsComponent
                event={data.event}
                organization={data.organization}
                reload={reload}
            />
        )
    );
};
