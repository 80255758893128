import { CreateUpdateCategoryModal } from 'common-front/src/components/createUpdateCategoryModal';
import { DelegationsCategory, EventId, OrganizationId } from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';
import {
    useDelegationCategoryCreateMutation,
    useDelegationCategoryUpdateMutation
} from '../../generated/graphqlHooks';

interface ICreateDelegationCategoryProps {
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;

    onClose(): void;
    onSuccess(category: Pick<DelegationsCategory, 'id' | 'name'>): void;
}

export const CreateDelegationCategory = (props: ICreateDelegationCategoryProps) => {
    const { mutate } = useDelegationCategoryCreateMutation();

    return (
        <CreateUpdateCategoryModal
            initialName=""
            isEdit={false}
            mutate={async (name: string) =>
                (
                    await mutate({
                        organizationId: props.organizationId,
                        eventId: props.eventId,
                        delegationCategory: { name }
                    })
                ).delegationCategoryCreate
            }
            onClose={props.onClose}
            onSuccess={props.onSuccess}
        />
    );
};

interface IUpdateDelegationCategoryProps {
    delegationCategory: Pick<DelegationsCategory, 'id' | 'name'>;
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;

    onClose(): void;
    onSuccess(category: Pick<DelegationsCategory, 'id' | 'name'>): void;
}

export const UpdateDelegationCategory = (props: IUpdateDelegationCategoryProps) => {
    const { mutate } = useDelegationCategoryUpdateMutation();

    return (
        <CreateUpdateCategoryModal
            initialName={props.delegationCategory.name}
            isEdit={true}
            mutate={async (name: string) =>
                (
                    await mutate({
                        organizationId: props.organizationId,
                        eventId: props.eventId,
                        delegationCategoryId: props.delegationCategory.id,
                        delegationCategory: { name }
                    })
                ).delegationCategoryUpdate
            }
            onClose={props.onClose}
            onSuccess={props.onSuccess}
        />
    );
};
