import { groupBy, min, sortBy } from 'lodash-es';
import { Interval } from 'luxon';
import {
    Accreditation,
    AccreditationsSlotFragment,
    DelegationAccreditationSlotFragment,
    FormAccreditationsOptionsFragment,
    FormRegisterAccreditationFragment,
    RegisterAccreditationDisplay,
    VolunteerRegistrationFragment,
    VolunteersRegistrationsSlotInput
} from '../generated/types';
import { isNonEmptyString } from '../util/string';
import { canSelect as canSelectSlot } from './accreditationSlot';
import { Fields } from './field';
import { meetPositionCustomFieldsConditions } from './position';

export function fullName(accreditation: Pick<Accreditation, 'name' | 'acronym'>): string {
    return `${accreditation.name}${isNonEmptyString(accreditation.acronym) ? ` (${accreditation.acronym})` : ''}`;
}

export function canSelect(
    accreditation: FormRegisterAccreditationFragment,
    ranges: Array<Interval | VolunteersRegistrationsSlotInput>,
    userFields: Fields,
    options: FormAccreditationsOptionsFragment,
    delegationAccreditationsSlots: DelegationAccreditationSlotFragment[]
): boolean {
    return (
        (options.accreditationDisplay !== RegisterAccreditationDisplay.None &&
            !options.hiddenAccreditationsIds.includes(accreditation.id) &&
            (options.displayedAccreditationsIds.length === 0 ||
                options.displayedAccreditationsIds.includes(accreditation.id)) &&
            meetPositionCustomFieldsConditions(accreditation, userFields) &&
            (!accreditation.hasSlots ||
                accreditation.slots.some((slot) =>
                    canSelectSlot(slot, ranges, options, delegationAccreditationsSlots)
                ))) ||
        delegationAccreditationsSlots.some(
            (da) =>
                da.accreditationId === accreditation.id &&
                (!accreditation.hasSlots ||
                    accreditation.slots.some((slot) =>
                        canSelectSlot(slot, ranges, options, delegationAccreditationsSlots)
                    ))
        )
    );
}

export function getAccreditationsBadges(
    auis: VolunteerRegistrationFragment['accreditationsUsersInfos']
) {
    return getWishedAccreditationsBadges(auis.map(({ accreditationSlot }) => accreditationSlot));
}

export function getWishedAccreditationsBadges(accreditationsSlots: AccreditationsSlotFragment[]) {
    return sortBy(
        Object.values(groupBy(accreditationsSlots, (as) => as.accreditation.id)).map((slots) => {
            const category = slots[0].accreditationCategory;
            const accreditation = slots[0].accreditation;
            const dates = slots.map((s) => s.date?.toMillis() ?? 1);

            return {
                id: accreditation.id,
                category: category.name,
                text: isNonEmptyString(accreditation.acronym)
                    ? accreditation.acronym
                    : accreditation.name,
                minDate: min(dates)!,
                color: accreditation.color,
                count: slots.length,
                icon: accreditation.icon
            };
        }),
        ({ category, text, minDate }) => [category.toLowerCase(), text.toLowerCase(), minDate]
    );
}
