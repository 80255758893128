import { Button } from 'common-front/src/designSystem/components/button';
import { DatePrevNextInput } from 'common-front/src/designSystem/components/date/datePrevNextInput';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { UserProfileAccreditationsQuery } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { useLocalEventState } from '../../../hooks/useLocalState';
import { UserAccreditationsAssignedCalendar } from './userAccreditationsAssignedCalendar';
import { UserAccreditationsAssignedList } from './userAccreditationsAssignedList';

interface IUserAccreditationsAssignedProps {
    event: UserProfileAccreditationsQuery['event'];

    reload(): void;
}

export const UserAccreditationsAssigned = (props: IUserAccreditationsAssignedProps) => {
    const {
        translate,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const [accreditationsDisplay, setAccreditationsDisplay] = useLocalEventState(
        'user.accreditations.display',
        'calendar'
    );

    const [selectedDay, setSelectedDay] = React.useState(props.event.startAt);

    return (
        <Flex
            css={{
                background: 'white',
                bd: true,
                overflow: 'hidden'
            }}
            direction="column"
            justify="center"
        >
            <Flex align="center" css={{ padding: '$5 $6' }}>
                <Box css={{ flex: '1' }} font="gray800 textMd medium">
                    {translate('accr_ditations_33155')}
                </Box>

                <Flex gap="6">
                    {accreditationsDisplay === 'calendar' && (
                        <DatePrevNextInput
                            max={props.event.endAt}
                            min={props.event.startAt}
                            value={selectedDay}
                            onChange={setSelectedDay}
                        />
                    )}

                    <Box width={200}>
                        <Select value={accreditationsDisplay} onChange={setAccreditationsDisplay}>
                            <option value="calendar">{translate('calendrier_34445')}</option>
                            <option value="list">{translate('liste_06141')}</option>
                        </Select>
                    </Box>

                    <Button to={HeaventPaths.EDIT_ACCREDIT(organizationId, eventId, userInfoId)}>
                        {translate('_diter_les_accr_86042')}
                    </Button>
                </Flex>
            </Flex>

            {accreditationsDisplay === 'calendar' ? (
                <UserAccreditationsAssignedCalendar event={props.event} selectedDay={selectedDay} />
            ) : (
                <UserAccreditationsAssignedList event={props.event} reload={props.reload} />
            )}
        </Flex>
    );
};
