import linkifyStr from 'linkify-string';
import * as React from 'react';
import { Box } from '../../designSystem/components/box';
import { Flex } from '../../designSystem/components/flex';
import {
    AccreditationsSlotId,
    CustomBadgeVolunteerRegistrationFragment,
    CustomFieldWithConditionFragment,
    Event,
    PositionsSlotId
} from '../../generated/types';
import { TemplateService } from '../../services/templateService';
import { useService } from '../../util/dependencies/dependencies';
import { isNonEmptyString } from '../../util/string';
import { getBarcodeHref } from '../../util/url';
import { DocumentElement, shouldDisplayElement } from '../../vo/documentBuilder';

const getSrc = (source: string, vr: CustomBadgeVolunteerRegistrationFragment) => {
    if (source === '{user.picture}') {
        return vr.userInfo.picture?.url || '';
    } else if (source === '{user.qrcode}') {
        const ticketId = vr.weezeventParticipantInfo?.idBarcode ?? vr.ticketId;

        return getBarcodeHref(ticketId, { height: 100, width: 100 });
    } else {
        return source;
    }
};

interface IUserCustomDocumentElementProps {
    accreditationsSlotsIds: AccreditationsSlotId[];
    customFields: CustomFieldWithConditionFragment[];
    element: DocumentElement;
    event: Pick<Event, 'id' | 'organizationId' | 'name' | 'startAt' | 'endAt'>;
    positionsSlotsIds: PositionsSlotId[];
    vr: CustomBadgeVolunteerRegistrationFragment;
}

export const UserCustomDocumentElement = (props: IUserCustomDocumentElementProps) => {
    const templateService = useService(TemplateService);

    if (
        !shouldDisplayElement(
            props.element,
            props.accreditationsSlotsIds,
            props.positionsSlotsIds,
            props.vr.userInfo.fields
        )
    ) {
        return null;
    } else if (props.element.type === 'container') {
        return (
            <Flex
                align={props.element.align}
                css={{
                    backgroundColor: props.element.hasBackground
                        ? props.element.backgroundColor
                        : 'none',
                    border:
                        props.element.borderSize > 0
                            ? `${props.element.borderSize}px solid ${props.element.borderColor}`
                            : '',
                    borderRadius:
                        props.element.borderRadius > 0 ? `${props.element.borderRadius}px` : '0',
                    gap: `${props.element.gap}px`,
                    left: `${props.element.left}px`,
                    overflow: 'hidden',
                    paddingBottom: `${props.element.py || 0}px`,
                    paddingLeft: `${props.element.px || 0}px`,
                    paddingRight: `${props.element.px || 0}px`,
                    paddingTop: `${props.element.py || 0}px`,
                    position: props.element.inContainer ? 'static' : 'absolute',
                    top: `${props.element.top}px`,
                    transform: props.element.hasRotate
                        ? `rotate(${props.element.rotateDegree}deg)`
                        : undefined,
                    transformOrigin: props.element.hasRotate ? 'top left' : undefined,
                    whiteSpace: 'pre-wrap',
                    zIndex: props.element.zIndex
                }}
                direction={props.element.direction}
                height={props.element.height}
                justify={props.element.justify}
                width={props.element.width}
            >
                {props.element.elements.map((element) => (
                    <UserCustomDocumentElement
                        key={element.id}
                        accreditationsSlotsIds={props.accreditationsSlotsIds}
                        customFields={props.customFields}
                        element={element}
                        event={props.event}
                        positionsSlotsIds={props.positionsSlotsIds}
                        vr={props.vr}
                    />
                ))}
            </Flex>
        );
    } else if (props.element.type === 'image') {
        return (
            <Box
                css={{
                    border:
                        props.element.borderSize > 0
                            ? `${props.element.borderSize}px solid ${props.element.borderColor}`
                            : '',
                    borderRadius:
                        props.element.borderRadius > 0 ? `${props.element.borderRadius}px` : '0',
                    left: `${props.element.left}px`,
                    overflow: 'hidden',
                    paddingBottom: `${props.element.py || 0}px`,
                    paddingLeft: `${props.element.px || 0}px`,
                    paddingRight: `${props.element.px || 0}px`,
                    paddingTop: `${props.element.py || 0}px`,
                    position: props.element.inContainer ? 'static' : 'absolute',
                    top: `${props.element.top}px`,
                    transform: props.element.hasRotate
                        ? `rotate(${props.element.rotateDegree}deg)`
                        : undefined,
                    transformOrigin: props.element.hasRotate ? 'top left' : undefined,
                    zIndex: props.element.zIndex
                }}
                height={props.element.height}
                width={props.element.width}
            >
                {isNonEmptyString(props.element.source) &&
                    isNonEmptyString(getSrc(props.element.source, props.vr)) && (
                        <img
                            height="100%"
                            src={getSrc(props.element.source, props.vr)}
                            width="100%"
                        />
                    )}
            </Box>
        );
    } else if (props.element.type === 'text') {
        const renderedText = linkifyStr(
            templateService.renderText(
                props.element.text,
                props.vr.userInfo,
                null,
                props.event,
                props.customFields,
                props.vr
            ),
            { target: '_blank' }
        );

        return (
            <Flex
                align={props.element.align}
                css={{
                    backgroundColor: props.element.hasBackground
                        ? props.element.backgroundColor
                        : 'none',
                    border:
                        props.element.borderSize > 0
                            ? `${props.element.borderSize}px solid ${props.element.borderColor}`
                            : '',
                    borderRadius:
                        props.element.borderRadius > 0 ? `${props.element.borderRadius}px` : '0',
                    color: props.element.color,
                    fontFamily: props.element.fontFamily,
                    fontSize: `${props.element.fontSize}px`,
                    fontWeight: props.element.fontWeight,
                    left: `${props.element.left}px`,
                    lineHeight: props.element.lineHeight || 1.4,
                    overflow: 'hidden',
                    paddingBottom: `${props.element.py || 0}px`,
                    paddingLeft: `${props.element.px || 0}px`,
                    paddingRight: `${props.element.px || 0}px`,
                    paddingTop: `${props.element.py || 0}px`,
                    position: props.element.inContainer ? 'static' : 'absolute',
                    textAlign: props.element.justify,
                    textTransform: props.element.textTransform,
                    top: `${props.element.top}px`,
                    transform: props.element.hasRotate
                        ? `rotate(${props.element.rotateDegree}deg)`
                        : undefined,
                    transformOrigin: props.element.hasRotate ? 'top left' : undefined,
                    whiteSpace: 'pre-wrap',
                    zIndex: props.element.zIndex
                }}
                dangerouslySetInnerHTML={{
                    __html: renderedText
                }}
                height={props.element.height}
                justify={props.element.justify}
                width={props.element.width}
            />
        );
    } else {
        return null;
    }
};
