import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    EventId,
    OrganizationId,
    SegmentFolderReorderInput,
    SegmentsFolderId,
    SegmentsFoldersSegmentsFragment,
    SegmentType
} from 'common/src/generated/types';
import { reorder } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { produce } from 'immer';
import { minBy, without } from 'lodash-es';
import * as React from 'react';
import { DragDropContext, Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { CenteredContainer } from '../../../components/centeredContainer/centeredContainer';
import { FullScreenPopup } from '../../../components/fullScreenPopup/fullScreenPopup';
import { Button } from '../../../designSystem/components/button';
import { HorizontalSpacerSeparator } from '../../../designSystem/components/separator';
import { useSegmentsFoldersReorderMutation } from '../../../generated/graphqlHooks';
import { SegmentsFoldersReorderSegmentFolder } from './segmentsFoldersReorderSegmentFolder';

interface ISegmentsFoldersReorderModalProps {
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;
    segmentType: SegmentType;
    segmentsFolders: SegmentsFoldersSegmentsFragment[];

    onClose(): void;
    onSuccess(): void;
}

export const SegmentsFoldersReorderModal = (props: ISegmentsFoldersReorderModalProps) => {
    const translate = useTranslate();
    const { mutate, isLoading } = useSegmentsFoldersReorderMutation();
    const [segmentsFolders, setSegmentsFolders] = React.useState<SegmentFolderReorderInput[]>(
        props.segmentsFolders.map((sf) => ({
            id: sf.id,
            name: sf.name,
            segments: sf.segments.map((s) => ({ id: s.id, name: s.name }))
        }))
    );
    const onSave = React.useCallback(async () => {
        await mutate({
            organizationId: props.organizationId,
            eventId: props.eventId,
            segmentType: props.segmentType,
            segmentsFolders: segmentsFolders.map((sf) => ({
                ...sf,
                id: !sf.id || sf.id < 0 ? undefined : sf.id
            }))
        });

        props.onClose();
        props.onSuccess();
    }, [props.organizationId, props.eventId, props.segmentType, segmentsFolders]);
    const onDragEnd = React.useCallback(
        ({ reason, source, destination }) => {
            if (
                reason === 'DROP' &&
                destination &&
                source.index !== destination.index &&
                source.droppableId === 'main' &&
                destination.droppableId === 'main'
            ) {
                setSegmentsFolders(reorder(segmentsFolders, source.index, destination.index));
            } else if (
                reason === 'DROP' &&
                destination &&
                (source.droppableId !== destination.droppableId ||
                    source.index !== destination.index) &&
                source.droppableId.startsWith('drop-sf-') &&
                destination.droppableId.startsWith('drop-sf-')
            ) {
                const sourceSegmentFolderId = parseInt(
                    source.droppableId.substring(8),
                    10
                ) as SegmentsFolderId;
                const destinationSegmentFolderId = parseInt(
                    destination.droppableId.substring(8),
                    10
                ) as SegmentsFolderId;

                if (sourceSegmentFolderId !== destinationSegmentFolderId) {
                    setSegmentsFolders(
                        produce((currentSegmentsFolders: SegmentFolderReorderInput[]) => {
                            const sourceSegmentFolder = currentSegmentsFolders.find(
                                (sf) => sf.id === sourceSegmentFolderId
                            )!;
                            const destinationSegmentFolder = currentSegmentsFolders.find(
                                (sf) => sf.id === destinationSegmentFolderId
                            )!;
                            const segment = sourceSegmentFolder.segments.find(
                                (_, segmentIndex) => segmentIndex === source.index
                            )!;

                            sourceSegmentFolder.segments = without(
                                sourceSegmentFolder.segments,
                                segment
                            );
                            destinationSegmentFolder.segments.splice(destination.index, 0, segment);
                        })
                    );
                } else {
                    setSegmentsFolders(
                        produce((currentSegmentsFolders: SegmentFolderReorderInput[]) => {
                            const segmentFolder = currentSegmentsFolders.find(
                                (sf) => sf.id === sourceSegmentFolderId
                            )!;

                            segmentFolder.segments = reorder(
                                segmentFolder.segments,
                                source.index,
                                destination.index
                            );
                        })
                    );
                }
            }
        },
        [segmentsFolders, setSegmentsFolders]
    );

    return (
        <FullScreenPopup
            button={
                <Button isLoading={isLoading} onClick={onSave}>
                    {translate('enregistrer_06519')}
                </Button>
            }
            category={translate('vues_personnali_80652')}
            title={translate('param_tres_de_v_66378')}
            onClose={props.onClose}
        >
            <CenteredContainer css={{ background: 'white' }}>
                <Flex align="center" gap="6" justify="between">
                    <Box font="gray900 textLg semiBold">{translate('organisation_de_95776')}</Box>

                    <Button
                        color="white"
                        leftIcon="folder-plus"
                        onClick={() => {
                            const minId = Math.min(
                                minBy(segmentsFolders, (sf) => sf.id)?.id ?? 0,
                                0
                            );

                            setSegmentsFolders([
                                ...segmentsFolders,
                                {
                                    id: (minId - 1) as SegmentsFolderId,
                                    name: translate('nouveau_dossier_94479'),
                                    segments: []
                                }
                            ]);
                        }}
                    >
                        {translate('nouveau_dossier_94479')}
                    </Button>
                </Flex>

                <HorizontalSpacerSeparator height="5" />

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="main" type="segmentFolder">
                        {(droppableProvided: DroppableProvided) => (
                            <Flex
                                direction="column"
                                gap="6"
                                width={1}
                                {...droppableProvided.droppableProps}
                                ref={droppableProvided.innerRef}
                            >
                                {segmentsFolders.map((segmentFolder, index) => (
                                    <SegmentsFoldersReorderSegmentFolder
                                        key={index}
                                        canDelete={segmentsFolders.length > 1}
                                        index={index}
                                        segmentFolder={segmentFolder}
                                        setSegmentsFolders={setSegmentsFolders}
                                    />
                                ))}

                                {droppableProvided.placeholder}
                            </Flex>
                        )}
                    </Droppable>
                </DragDropContext>
            </CenteredContainer>
        </FullScreenPopup>
    );
};
