import * as yup from 'yup';
import { intercalate, isNonEmptyArray } from './array';

export function normalize(str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export enum SlugReplacer {
    Dash,
    Empty,
    Underscore
}

export interface ISlugOptions {
    replaceStartingNumber?: boolean;
    replacer?: SlugReplacer;
}

export function slug(str: string, options: ISlugOptions = {}): string {
    const replacer = options.replacer ?? SlugReplacer.Dash;
    const newStr = normalize(str).trim().toLowerCase().replace(/['"]+/gi, '');
    let replacedString: string;

    if (replacer === SlugReplacer.Dash) {
        replacedString = newStr
            .replace(/[^a-z0-9\\-]+/gu, '-')
            .replace(/-+$/, '')
            .replace(/^-+/, '');
    } else if (replacer === SlugReplacer.Underscore) {
        replacedString = newStr
            .replace(/[^a-z0-9_]+/gu, '_')
            .replace(/_+$/, '')
            .replace(/^_+/, '');
    } else {
        replacedString = newStr.replace(/[^a-z0-9]+/gu, '').trim();
    }

    if (options?.replaceStartingNumber) {
        return replacedString.replace(/^(\d)/, '_$1');
    } else {
        return replacedString;
    }
}

export function isNonEmptyString(str: any): str is string {
    return typeof str === 'string' && str.trim().length > 0;
}

export function isEmptyString(str: any): boolean {
    return !isNonEmptyString(str);
}

const capitalizeFirstLetter = (str: string) => {
    if (isNonEmptyString(str)) {
        return str[0].toUpperCase() + str.slice(1);
    } else {
        return '';
    }
};

export function capitalizeName(name?: string | null): string {
    return (name ?? '')
        .trim()
        .toLowerCase()
        .replace(/\s+/g, ' ')
        .split(' ')
        .map((part) => capitalizeFirstLetter(part))
        .join(' ')
        .split('-')
        .map((part) => capitalizeFirstLetter(part.trim()))
        .join('-');
}

export function isInteger(str: any): str is number {
    if (typeof str === 'number' && isFinite(str)) {
        return true;
    } else if (typeof str === 'string' && parseInt(str, 10).toString(10) === str) {
        return true;
    } else {
        return false;
    }
}

export function isValidEmail(email: string): boolean {
    return yup.string().email().required().isValidSync(email);
}

export function isFuzzyMatch(haystack: string, needle: string): boolean {
    return haystack.toUpperCase().includes(needle.toUpperCase());
}

const _split = (str: string, separator: string) =>
    intercalate(
        str.split(new RegExp(separator)).filter((part) => isNonEmptyString(part)),
        separator
    );

export function splitAt(str: string, separators: string[]): string[] {
    if (isNonEmptyArray(separators)) {
        const [firstSeparator, ...remainingSeparators] = separators;
        const result = _split(str, firstSeparator);

        if (isNonEmptyArray(remainingSeparators)) {
            return result.flatMap((part) => splitAt(part, remainingSeparators));
        } else {
            return result;
        }
    } else {
        return [str];
    }
}

// The maximum edit distance between two strings, after which we don't care.
export const MAX_EDIT_DISTANCE = 4;

// Calculates the Levenshtein distance between two strings using a highly
// optimised implementation of the Wagner–Fischer algorithm.
// {@link https://github.com/gustf/js-levenshtein/tree/master Source}
export function getEditDistance(a: string, b: string) {
    const _min = (d0: number, d1: number, d2: number, bx: number, ay: number) =>
        d0 < d1 || d2 < d1 ? (d0 > d2 ? d2 + 1 : d0 + 1) : bx === ay ? d1 : d1 + 1;

    if (a === b) {
        return 0;
    }

    if (a.length > b.length) {
        const tmp = a;
        a = b;
        b = tmp;
    }

    let la = a.length;
    let lb = b.length;

    while (la > 0 && a.charCodeAt(la - 1) === b.charCodeAt(lb - 1)) {
        la--;
        lb--;
    }

    let offset = 0;

    while (offset < la && a.charCodeAt(offset) === b.charCodeAt(offset)) {
        offset++;
    }

    la -= offset;
    lb -= offset;

    if (la === 0 || lb < 3) {
        return lb;
    }

    let x = 0;
    let y: number;
    let d0: number;
    let d1: number;
    let d2: number;
    let d3: number;
    let dd!: number;
    let dy: number;
    let ay: number;
    let bx0: number;
    let bx1: number;
    let bx2: number;
    let bx3: number;

    const vector = [];

    for (y = 0; y < la; y++) {
        vector.push(y + 1);
        vector.push(a.charCodeAt(offset + y));
    }

    const len = vector.length - 1;

    for (; x < lb - 3; ) {
        bx0 = b.charCodeAt(offset + (d0 = x));
        bx1 = b.charCodeAt(offset + (d1 = x + 1));
        bx2 = b.charCodeAt(offset + (d2 = x + 2));
        bx3 = b.charCodeAt(offset + (d3 = x + 3));
        dd = x += 4;
        for (y = 0; y < len; y += 2) {
            dy = vector[y];
            ay = vector[y + 1];
            d0 = _min(dy, d0, d1, bx0, ay);
            d1 = _min(d0, d1, d2, bx1, ay);
            d2 = _min(d1, d2, d3, bx2, ay);
            dd = _min(d2, d3, dd, bx3, ay);
            vector[y] = dd;
            d3 = d2;
            d2 = d1;
            d1 = d0;
            d0 = dy;
        }
    }

    for (; x < lb; ) {
        bx0 = b.charCodeAt(offset + (d0 = x));
        dd = ++x;
        for (y = 0; y < len; y += 2) {
            dy = vector[y];
            vector[y] = dd = _min(dy, d0, dd, bx0, vector[y + 1]);
            d0 = dy;
        }
    }

    return dd;
}
