import { ColorInput } from 'common-front/src/designSystem/components/colorInput';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { copy } from 'common-front/src/util/commands';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Accreditation, AccreditationProperty } from 'common/src/generated/types';
import * as React from 'react';
import { AccreditationsUpdateCell } from './accreditationsUpdateCell';

interface IAccreditationsColorCellProps {
    accreditation: Pick<Accreditation, 'id' | 'color'>;

    reload(): void;
}

export const AccreditationsColorCell = (props: IAccreditationsColorCellProps) => {
    const { translate } = useHeavent();

    return (
        <AccreditationsUpdateCell
            accreditationId={props.accreditation.id}
            cellCss={{ flex: '0 80px' }}
            initialValue={props.accreditation.color}
            property={AccreditationProperty.Color}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <ColorInput label={translate('Color')} value={value} onChange={setValue} />
            )}
            renderValue={() => (
                <Flex align="center" gap="3" width={1}>
                    <Tooltip>
                        <Trigger>
                            <Box
                                css={{
                                    background: props.accreditation.color,
                                    borderRadius: '2px',
                                    height: '24px',
                                    width: '24px'
                                }}
                                onClick={() => {
                                    copy(props.accreditation.color);
                                }}
                            />
                        </Trigger>

                        <Content placement="top">{props.accreditation.color}</Content>
                    </Tooltip>
                </Flex>
            )}
        />
    );
};
