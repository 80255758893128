import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { AccreditationsSlotId } from 'common/src/generated/types';
import * as React from 'react';
import { Button } from '../../../designSystem/components/button';
import { useHeavent } from '../../../hooks/useHeavent';
import {
    AccreditationState,
    useAccreditationsRightPanelContext
} from './accreditationsRightPanelContext';

interface IAccreditationsRightPanelSlotActionsProps {
    accreditationSlotId: AccreditationsSlotId;
    state: AccreditationState;
}

export const AccreditationsRightPanelSlotActions = (
    props: IAccreditationsRightPanelSlotActionsProps
) => {
    const { translate } = useHeavent();
    const {
        assignedSlotsIds,
        assignSlot,
        canAccredit,
        canEditWished,
        defaultAccreditationsSlotsIds,
        deleteAssignedSlot,
        deleteWishedSlot
    } = useAccreditationsRightPanelContext();
    const [isLoading, setIsLoading] = React.useState(false);

    return props.state === 'assigned' && canAccredit ? (
        <Flex css={{ flex: '1', px: '$4' }} justify="end">
            {isLoading ? (
                <Skeleton borderRadius="$1" height={20}>
                    {translate('retirer_05155')}
                </Skeleton>
            ) : (
                <Box
                    css={{ cursor: 'pointer' }}
                    font="error700 textSm medium"
                    onClick={async () => {
                        setIsLoading(true);
                        await deleteAssignedSlot(props.accreditationSlotId);
                    }}
                >
                    {translate('retirer_05155')}
                </Box>
            )}
        </Flex>
    ) : props.state === 'wished' && canAccredit ? (
        <Flex css={{ flex: '1', px: '$4' }} justify="end">
            {assignedSlotsIds.includes(props.accreditationSlotId) ? (
                <Flex align="center" gap="1">
                    <Box color="success500">
                        <I icon="badge-check" iconStyle="solid" />
                    </Box>
                    <Box font="gray800 textXs medium">{translate('d_j_attribu_e_06450')}</Box>
                </Flex>
            ) : (
                <Button
                    isLoading={isLoading}
                    size="sm"
                    onClick={async () => {
                        setIsLoading(true);
                        await assignSlot(props.accreditationSlotId);
                        setIsLoading(false);
                    }}
                >
                    {translate('ajouter_97327')}
                </Button>
            )}
        </Flex>
    ) : props.state === 'wished' &&
      canEditWished &&
      !defaultAccreditationsSlotsIds.includes(props.accreditationSlotId) ? (
        <Flex css={{ flex: '1', px: '$4' }} justify="end">
            {isLoading ? (
                <Skeleton borderRadius="$1" height={20}>
                    {translate('retirer_05155')}
                </Skeleton>
            ) : (
                <Box
                    css={{ cursor: 'pointer' }}
                    font="error700 textSm medium"
                    onClick={async () => {
                        setIsLoading(true);
                        await deleteWishedSlot(props.accreditationSlotId);
                    }}
                >
                    {translate('retirer_05155')}
                </Box>
            )}
        </Flex>
    ) : null;
};
