import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import * as React from 'react';
import { useDocumentBuilderContext } from '../documentBuilderContext';
import { DocumentLeftPanelPage } from './documentLeftPanelPage';

export const DocumentBuilderLeftPanelDesign = () => {
    const { translate } = useHeavent();
    const { getSelectedPage } = useDocumentBuilderContext();
    const selectedPage = getSelectedPage();

    return selectedPage ? (
        <DocumentLeftPanelPage />
    ) : (
        <Flex css={{ px: '$4' }} height={1} width={1}>
            <Flex
                align="center"
                css={{
                    bd: true,
                    px: '$9'
                }}
                direction="column"
                gap="4"
                height={1}
                justify="center"
                width={1}
            >
                <Flex
                    css={{
                        border: '1px solid $gray200',
                        borderRadius: '10px',
                        padding: '$3'
                    }}
                >
                    <I icon="hand-pointer" />
                </Flex>

                <Box font="gray800 textMd medium" textAlign="center">
                    {translate('pour_ajouter_de_11528')}
                </Box>
            </Flex>
        </Flex>
    );
};
