import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { Fields } from '../../../components/form/fields';
import { isInvalid } from '../../../util/isInvalid';
import {
    FileS3Input as FileS3InputComponent,
    IFileS3InputProps
} from '../../components/file/fileS3Input';
import { FileS3InputValue } from '../../components/file/fileS3InputValue';
import { IFormInputProps } from '../../components/input/commonInputProps';

interface IFileS3FormInputProps extends Omit<IFormInputProps<IFileS3InputProps>, 'name'> {
    allowCrop?: boolean;
    prefix: string;
    withFileField?: boolean;

    onChange?: (file: File) => void | Promise<void>;
}

export const FileS3Input = ({
    onChange,
    prefix,
    state,
    withFileField,
    ...rest
}: IFileS3FormInputProps) => {
    const key = `${prefix}key`;
    const name = `${prefix}name`;
    const acl = `${prefix}acl`;
    const names = [key, name, acl];
    let file: string;
    if (withFileField) {
        file = `${prefix}file`;
        names.push(file);
    }

    return (
        <Fields
            names={names}
            render={(fieldsProps) => {
                let value: FileS3InputValue | null = null;

                if (
                    isNonEmptyString(fieldsProps[key].input.value) &&
                    isNonEmptyString(fieldsProps[name].input.value) &&
                    isNonEmptyString(fieldsProps[acl].input.value)
                ) {
                    value = {
                        key: fieldsProps[key].input.value,
                        name: fieldsProps[name].input.value,
                        acl: fieldsProps[acl].input.value
                    };
                }

                const { invalid, hint } = Object.values(fieldsProps).reduce(
                    (acc, fieldProps) => {
                        if (acc.invalid) {
                            return acc;
                        } else {
                            if (isInvalid(fieldProps.meta)) {
                                return {
                                    invalid: true,
                                    hint: fieldProps.meta.error || fieldProps.meta.submitError
                                };
                            } else {
                                return acc;
                            }
                        }
                    },
                    { invalid: false, hint: undefined }
                );

                return (
                    <FileS3InputComponent
                        hint={invalid ? hint : hint}
                        state={invalid ? 'error' : state}
                        value={value}
                        onChange={(newValue) => {
                            fieldsProps[key].input.onChange(newValue?.key ?? '');
                            fieldsProps[key].input.onBlur();
                            fieldsProps[name].input.onChange(newValue?.name ?? '');
                            fieldsProps[name].input.onBlur();
                            fieldsProps[acl].input.onChange(newValue?.acl ?? '');
                            fieldsProps[acl].input.onBlur();
                            if (withFileField) {
                                fieldsProps[file].input.onChange(newValue?.file);
                                fieldsProps[file].input.onBlur();
                                if (newValue?.file) {
                                    onChange?.(newValue.file);
                                }
                            }
                        }}
                        {...rest}
                    />
                );
            }}
        />
    );
};
