import { Form } from 'common-front/src/components/form/form';
import { Button } from 'common-front/src/designSystem/components/button';
import { HorizontalSpacerSeparator } from 'common-front/src/designSystem/components/separator';
import { CheckboxText as CheckboxTextForm } from 'common-front/src/designSystem/form/checkbox';
import { RadioText } from 'common-front/src/designSystem/form/radio';
import { RichEditor } from 'common-front/src/designSystem/form/richEditor';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { ToggleText } from 'common-front/src/designSystem/form/toggle';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { Images } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    FormPositionRanking,
    RegisterPositionDisplay,
    RegisterPositionFilter,
    RegisterSlotDisplay
} from 'common/src/generated/types';
import {
    FormParametersMissionsInputService,
    IUpdateFormParametersMissionsValues
} from 'common/src/input/formParametersMissionsInput';
import { ValidateService } from 'common/src/services/validateService';
import { useService } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import {
    useFormParametersMissionsQuery,
    useFormParametersMissionsUpdateMutation
} from '../../../../generated/graphqlHooks';
import { FormContainer } from '../../../show/formContainer';
import { FormParametersContainer } from '../formParametersContainer';
import { useFormParametersClose } from '../useFormParametersClose';
import { FormParametersMissionsPositionRanking } from './formParametersMissionsPositionRanking';
import { FormParametersMissionsHiddenDisplayed } from './hiddenDisplayed/formParametersMissionsHiddenDisplayed';

interface IFormParametersMissionsInnerProps {
    initialValues: IUpdateFormParametersMissionsValues;
    isMutateLoading: boolean;
    loader: React.JSX.Element | null;
    name: string;
    slotDisplay: RegisterSlotDisplay;
    values: IUpdateFormParametersMissionsValues;

    change(name: string, value: any): void;
    handleSubmit(): void;
}

const FormParametersMissionsInner = (props: IFormParametersMissionsInnerProps) => {
    const {
        translate,
        params: { eventId }
    } = useHeavent();
    const { isNotSavedModalOpen, onClose, onNotSavedClose, onSave } = useFormParametersClose(
        props.initialValues,
        props.values,
        props.handleSubmit
    );
    const showPositionFilter =
        props.slotDisplay !== RegisterSlotDisplay.Hide &&
        props.values.form.positionDisplay !== RegisterPositionDisplay.None;

    return (
        <FormContainer
            button={
                <Button isLoading={props.isMutateLoading} onClick={onSave}>
                    {translate('enregistrer_06519')}
                </Button>
            }
            title={props.name}
            onClose={onClose}
        >
            <FormParametersContainer
                isNotSavedOpen={isNotSavedModalOpen}
                onNotSavedClose={onNotSavedClose}
            >
                {props.loader || (
                    <>
                        <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                            {translate('param_tres_des_93365')}
                        </Box>

                        <Spacer height="1" />

                        <Box color="gray500">{translate('d_cider_du_type_01830')}</Box>

                        <Spacer height="6" />

                        <Flex gap="8">
                            <FormParametersMissionsPositionRanking
                                isSelected={
                                    props.values.form.positionRanking ===
                                    FormPositionRanking.Unranked
                                }
                                name={translate('choix_illimit_s_72460')}
                                src={Images.FormPositionUnranked}
                                onSelect={() => {
                                    props.change(
                                        'form.positionRanking',
                                        FormPositionRanking.Unranked
                                    );
                                }}
                            />

                            <FormParametersMissionsPositionRanking
                                isSelected={
                                    props.values.form.positionRanking === FormPositionRanking.Ranked
                                }
                                name={translate('classement_des_43897')}
                                src={Images.FormPositionRanked}
                                onSelect={() => {
                                    props.change(
                                        'form.positionRanking',
                                        FormPositionRanking.Ranked
                                    );

                                    if (
                                        props.values.form.positionDisplay ===
                                            RegisterPositionDisplay.None ||
                                        props.values.form.positionDisplay ===
                                            RegisterPositionDisplay.Slot
                                    ) {
                                        props.change(
                                            'form.positionDisplay',
                                            RegisterPositionDisplay.Position
                                        );
                                    }
                                }}
                            />
                        </Flex>

                        {props.values.form.positionRanking === FormPositionRanking.Ranked && (
                            <>
                                <Spacer height="6" />

                                <TextInput
                                    label={translate('nombre_de_choix_19811')}
                                    name="form.positionLimit"
                                    shouldParseAsInt={true}
                                />
                            </>
                        )}

                        <HorizontalSpacerSeparator height="6" />

                        <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                            {translate('affichage_des_m_65982')}
                        </Box>

                        <Spacer height="1" />

                        <Box color="gray500">{translate('personnaliser_l_26207')}</Box>

                        <Spacer height="4" />

                        <Box color="gray800" fontWeight="medium">
                            {translate('comment_souhait_23788')}
                        </Box>

                        <Spacer height="2" />

                        <RadioText
                            name="form.positionDisplay"
                            value={RegisterPositionDisplay.Category}
                        >
                            {translate('afficher_les_ca_26287')}
                        </RadioText>

                        <Spacer height="3" />

                        <RadioText
                            name="form.positionDisplay"
                            value={RegisterPositionDisplay.Position}
                        >
                            {translate('afficher_les_mi_31841')}
                        </RadioText>

                        {props.values.form.positionRanking === FormPositionRanking.Unranked && (
                            <>
                                <Spacer height="3" />

                                <RadioText
                                    name="form.positionDisplay"
                                    value={RegisterPositionDisplay.Slot}
                                >
                                    {translate('afficher_les_cr_32311')}
                                </RadioText>

                                <Spacer height="3" />

                                <RadioText
                                    name="form.positionDisplay"
                                    value={RegisterPositionDisplay.None}
                                >
                                    {translate('ne_rien_affiche_81467')}
                                </RadioText>
                            </>
                        )}

                        {props.values.form.positionDisplay !== RegisterPositionDisplay.None && (
                            <>
                                <Spacer height="6" />

                                <Box color="gray800" fontWeight="medium">
                                    {translate('personnalisatio_94071')}
                                </Box>

                                <Spacer height="2" />

                                <ToggleText name="form.showFullPosition">
                                    {translate('afficher_les_mi_35510')}
                                </ToggleText>

                                <Spacer height="3" />

                                <FormParametersMissionsHiddenDisplayed
                                    change={props.change}
                                    eventId={eventId}
                                    values={props.values}
                                />

                                {props.values.form.positionRanking !==
                                    FormPositionRanking.Ranked && (
                                    <>
                                        <Spacer height="6" />

                                        <Box color="gray800" fontWeight="medium">
                                            {translate('affichage_des_d_57491')}
                                        </Box>

                                        <Spacer height="2" />

                                        <CheckboxTextForm name="form.showPositionCategoryDescription">
                                            {translate('afficher_la_des_67852')}
                                        </CheckboxTextForm>

                                        {props.values.form.positionDisplay !==
                                            RegisterPositionDisplay.Category && (
                                            <>
                                                <Spacer height="3" />

                                                <CheckboxTextForm name="form.showPositionDescription">
                                                    {translate('afficher_la_des_21998')}
                                                </CheckboxTextForm>
                                            </>
                                        )}
                                    </>
                                )}

                                <HorizontalSpacerSeparator height="6" />

                                <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                                    {translate('automatisation_49411')}
                                </Box>

                                <Spacer height="1" />

                                <Box color="gray500">{translate('d_cider_si_l_af_60345')}</Box>

                                <Spacer height="4" />

                                <ToggleText name="form.willAutoAssign">
                                    {props.values.form.positionDisplay ===
                                    RegisterPositionDisplay.Category
                                        ? translate('la_pr_affectat_61978')
                                        : props.values.form.positionDisplay ===
                                            RegisterPositionDisplay.Position
                                          ? translate('la_pr_affectat_20431')
                                          : translate('l_affectation_s_68435')}
                                </ToggleText>
                            </>
                        )}

                        {showPositionFilter && (
                            <>
                                <HorizontalSpacerSeparator height="6" />

                                <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                                    {translate('filtrer_les_cat_63783')}
                                </Box>

                                <Spacer height="1" />

                                <Box color="gray500">{translate('d_cider_si_le_c_98644')}</Box>

                                <Spacer height="2" />

                                <RadioText
                                    name="form.positionFilter"
                                    value={RegisterPositionFilter.FilterWithSlots}
                                >
                                    {translate('filtrer_les_cat_52780')}
                                </RadioText>

                                <Spacer height="3" />

                                <RadioText
                                    name="form.positionFilter"
                                    value={RegisterPositionFilter.None}
                                >
                                    {translate('ne_pas_filtrer_73952')}
                                </RadioText>
                            </>
                        )}

                        {props.values.form.positionDisplay !== RegisterPositionDisplay.None && (
                            <>
                                <HorizontalSpacerSeparator height="6" />

                                <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                                    {translate('personnalisatio_15893')}
                                </Box>

                                <Spacer height="1" />

                                <Box color="gray500">{translate('personnaliser_l_44808')}</Box>

                                <Spacer height="4" />

                                <TextInput
                                    label={translate('nom_de_l_tape_85862')}
                                    name="form.positionTitle"
                                />

                                <Spacer height="6" />

                                <RichEditor
                                    label={translate('description_58935')}
                                    name="form.positionSubtitle"
                                />
                            </>
                        )}
                    </>
                )}
            </FormParametersContainer>
        </FormContainer>
    );
};

export const FormParametersMissions = () => {
    const {
        params: { organizationId, eventId, formId }
    } = useHeavent();
    const { data, loader } = useFormParametersMissionsQuery({
        organizationId,
        formId
    });
    useTitle(data.organization?.form.name ?? '');
    const validateService = useService(ValidateService);
    const formParametersMissionsInput = useService(FormParametersMissionsInputService);
    const { mutate, isLoading } = useFormParametersMissionsUpdateMutation();
    const initialValues = React.useMemo(
        () => ({
            form: formParametersMissionsInput.formParametersMissionsInputDefault(
                data.organization?.form
            )
        }),
        [data.organization]
    );

    return (
        <Form
            direction="column"
            height={1}
            initialValues={initialValues}
            render={({ form, handleSubmit, values }) => (
                <FormParametersMissionsInner
                    change={form.change}
                    handleSubmit={handleSubmit}
                    initialValues={initialValues}
                    isMutateLoading={isLoading}
                    loader={loader}
                    name={data.organization?.form.name ?? ''}
                    slotDisplay={data.organization?.form.slotDisplay ?? RegisterSlotDisplay.Hide}
                    values={values}
                />
            )}
            validate={validateService.validateForForm(
                formParametersMissionsInput.updateFormParametersMissionsSchema()
            )}
            width={1}
            onSubmit={(values: IUpdateFormParametersMissionsValues) =>
                mutate({
                    organizationId,
                    eventId,
                    formId,
                    form: values.form
                })
            }
        />
    );
};
