import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

const Card = styled(Flex, {
    bd: true,
    padding: '$4 $3'
});

export const CardListItem = ({ children, ...props }: React.ComponentProps<typeof Flex>) => (
    <Card align="center" {...props}>
        <Box css={{ flex: 1 }}>{children}</Box>
        <Box color="gray700" css={{ padding: '0 10px 0 $4' }}>
            <I icon="chevron-right" />
        </Box>
    </Card>
);
