import { Form } from 'common-front/src/components/form/form';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { DelegationEventInfosQuery, DelegationsCategoryId } from 'common/src/generated/types';
import {
    DelegationInputService,
    ICreateUpdateDelegationValues
} from 'common/src/input/delegationInput';
import { ValidateService } from 'common/src/services/validateService';
import { decodeBase64 } from 'common/src/util/base64';
import { useService } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { parseInt } from 'lodash-es';
import * as React from 'react';
import {
    useDelegationCreateMutation,
    useDelegationEventInfosQuery,
    useDelegationEventToEditQuery,
    useDelegationOrganizationInfosQuery,
    useDelegationOrganizationToEditQuery,
    useDelegationUpdateMutation
} from '../../generated/graphqlHooks';
import {
    CreateUpdateDelegationForm,
    CreateUpdateDelegationProps
} from './createUpdateDelegationForm';

const CreateUpdateDelegation = (props: CreateUpdateDelegationProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const delegationInput = useService(DelegationInputService);
    const validateService = useService(ValidateService);
    const centeredContainerRef = React.useRef<HTMLDivElement | null>(null);
    const title = React.useMemo(
        () =>
            props.isEdit
                ? translate('mise_jour_de_17055', props.initialValues.delegation.name)
                : translate('cr_ation_d_une_07120'),
        [props.isEdit, props.initialValues]
    );
    useTitle(title);

    return (
        <Form
            height={1}
            hideErrors={true}
            initialValues={props.initialValues}
            render={({ form, handleSubmit, submitting, values }) => (
                <CreateUpdateDelegationForm
                    {...props}
                    centeredContainerRef={centeredContainerRef}
                    form={form}
                    handleSubmit={handleSubmit}
                    submitting={submitting}
                    values={values}
                />
            )}
            validate={validateService.validateForForm(
                delegationInput.createUpdateDelegationSchema()
            )}
            width={1}
            onShowErrors={() => {
                if (centeredContainerRef.current) {
                    centeredContainerRef.current.scrollTop = 0;
                }
            }}
            onSubmit={async (values: ICreateUpdateDelegationValues) => {
                await props.mutate({
                    organizationId,
                    eventId,
                    delegation: values.delegation
                });

                history.goBack(DelegationsPaths.DELEGATIONS({ organizationId, eventId }));
            }}
        />
    );
};

const parseThirdParameter = (
    param: Emptyable<string>,
    delegations: DelegationEventInfosQuery['organization']['delegations']['nodes']
) => {
    try {
        if (isNonEmptyString(param) && !isNaN(parseInt(param, 10))) {
            return { delegationCategoryId: parseInt(param, 10) as DelegationsCategoryId };
        } else if (isNonEmptyString(param)) {
            const parsedOptions = JSON.parse(decodeBase64(param));
            const parentId = parsedOptions.parentId;
            const parentDelegation = parsedOptions.applyParentSettings
                ? delegations.find((d) => d.id === parentId)
                : null;

            return { parentId, parentDelegation };
        } else {
            return {};
        }
    } catch {
        return {};
    }
};

export const CreateEventDelegation = () => {
    const {
        params: { organizationId, eventId, options }
    } = useHeavent();
    const delegationInput = useService(DelegationInputService);
    const { data, loader } = useDelegationEventInfosQuery({ organizationId, eventId });
    const { mutate } = useDelegationCreateMutation();
    const initialValues = React.useMemo(() => {
        if (data.organization) {
            const { delegationCategoryId, parentId, parentDelegation } = parseThirdParameter(
                options,
                data.organization.delegations.nodes
            );

            return {
                delegation: delegationInput.delegationInputDefault(null, {
                    delegationCategoryId,
                    delegationsCategories: data.organization.delegationsCategories.nodes,
                    forms: data.event.forms,
                    parentId,
                    parentDelegation
                })
            };
        } else {
            return {} as any;
        }
    }, [options, data]);

    return (
        loader || (
            <CreateUpdateDelegation
                accreditationsCategories={data.event.accreditationsCategories}
                categories={data.organization.delegationsCategories.nodes}
                closePath={DelegationsPaths.DELEGATIONS({ organizationId, eventId })}
                customFields={data.organization.customFields.nodes}
                delegationCustomFields={data.organization.delegationCustomFields.nodes}
                delegationType="event"
                eventEndAt={data.event.endAt}
                eventStartAt={data.event.startAt}
                forms={data.event.forms}
                initialValues={initialValues}
                isEdit={false}
                mutate={async (values) => (await mutate(values)).delegationCreate.id}
                organizationDelegations={data.organization.delegations.nodes}
                tags={data.organization.tags.nodes}
            />
        )
    );
};

export const CreateOrganizationDelegation = () => {
    const {
        params: { organizationId, options }
    } = useHeavent();
    const delegationInput = useService(DelegationInputService);
    const { data, loader } = useDelegationOrganizationInfosQuery({ organizationId });
    const { mutate } = useDelegationCreateMutation();
    const initialValues = React.useMemo(() => {
        const { delegationCategoryId } = parseThirdParameter(options, []);

        return {
            delegation: delegationInput.delegationInputDefault(null, { delegationCategoryId })
        };
    }, []);

    return (
        loader || (
            <CreateUpdateDelegation
                categories={data.organization.delegationsCategories.nodes}
                closePath={HeaventPaths.COMMUNITY_DELEGATIONS(organizationId)}
                customFields={data.organization.customFields.nodes}
                delegationCustomFields={data.organization.delegationCustomFields.nodes}
                delegationType="organization"
                forms={data.organization.forms}
                initialValues={initialValues}
                isEdit={false}
                mutate={async (values) => (await mutate(values)).delegationCreate.id}
                tags={data.organization.tags.nodes}
            />
        )
    );
};

export const UpdateEventDelegation = () => {
    const {
        params: { organizationId, eventId, delegationId }
    } = useHeavent();
    const delegationInput = useService(DelegationInputService);
    const { data, loader } = useDelegationEventToEditQuery({
        organizationId,
        eventId,
        delegationId
    });
    const { mutate } = useDelegationUpdateMutation();

    return (
        loader || (
            <CreateUpdateDelegation
                accreditationsCategories={data.event.accreditationsCategories}
                categories={data.organization.delegationsCategories.nodes}
                closePath={DelegationsPaths.DELEGATION({ organizationId, eventId, delegationId })}
                customFields={data.organization.customFields.nodes}
                delegationCustomFields={data.organization.delegationCustomFields.nodes}
                delegationType="event"
                eventEndAt={data.event.endAt}
                eventStartAt={data.event.startAt}
                forms={data.event.forms}
                initialValues={{
                    delegation: delegationInput.delegationInputDefault(data.event.delegation, null)
                }}
                isEdit={true}
                mutate={async (values) =>
                    (
                        await mutate({
                            ...values,
                            delegationId
                        })
                    ).delegationUpdate.id
                }
                organizationDelegations={data.organization.delegations.nodes}
                tags={data.organization.tags.nodes}
            />
        )
    );
};

export const UpdateOrganizationDelegation = () => {
    const {
        params: { organizationId, delegationId }
    } = useHeavent();
    const delegationInput = useService(DelegationInputService);
    const { data, loader } = useDelegationOrganizationToEditQuery({ organizationId, delegationId });
    const { mutate } = useDelegationUpdateMutation();

    return (
        loader || (
            <CreateUpdateDelegation
                categories={data.organization.delegationsCategories.nodes}
                closePath={HeaventPaths.COMMUNITY_DELEGATION(organizationId, delegationId)}
                customFields={data.organization.customFields.nodes}
                delegationCustomFields={data.organization.delegationCustomFields.nodes}
                delegationType="organization"
                forms={data.organization.forms}
                initialValues={{
                    delegation: delegationInput.delegationInputDefault(
                        {
                            ...data.organization.delegation,
                            accreditations: [],
                            accreditationsSlots: []
                        },
                        null
                    )
                }}
                isEdit={true}
                mutate={async (values) =>
                    (
                        await mutate({
                            ...values,
                            delegationId
                        })
                    ).delegationUpdate.id
                }
                tags={data.organization.tags.nodes}
            />
        )
    );
};
