import { BadgesPlus } from 'common-front/src/components/badgesPlus/badgesPlus';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import { UserHistoryEventsQuery } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { isNonEmptyString } from 'common/src/util/string';
import { groupBy } from 'lodash-es';
import * as React from 'react';

interface IUserHistoryEventRowProps {
    vr: UserHistoryEventsQuery['organization']['userInfo']['volunteersRegistrations'][number];
}

export const UserHistoryEventRow = (props: IUserHistoryEventRowProps) => {
    const { translate } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const positions = React.useMemo(
        () =>
            Object.values(
                groupBy(props.vr.positionsSlotsUsersInfos, (psui) => psui.position.id)
            ).map((psuis) => {
                const position = psuis[0].position;

                return {
                    id: position.id,
                    text: isNonEmptyString(position.acronym) ? position.acronym : position.name,
                    color: position.color,
                    count: psuis.length,
                    icon: position.icon
                };
            }),
        [props.vr]
    );
    const accreditations = React.useMemo(
        () =>
            Object.values(
                groupBy(props.vr.accreditationsUsersInfos, (aui) => aui.accreditation.id)
            ).map((auis) => {
                const accreditation = auis[0].accreditation;

                return {
                    id: accreditation.id,
                    text: isNonEmptyString(accreditation.acronym)
                        ? accreditation.acronym
                        : accreditation.name,
                    color: accreditation.color,
                    count: auis.length,
                    icon: accreditation.icon
                };
            }),
        [props.vr]
    );

    return (
        <Row>
            <Cell align="start" direction="column" justify="center">
                <Box>{props.vr.event.name}</Box>

                <Box color="gray500">
                    {translate(
                        'inscrit_le_1_12018',
                        dateTimeService.toLocaleString(
                            props.vr.insertedAt.toLocal(),
                            LocaleFormats.DateTime
                        )
                    )}
                </Box>
            </Cell>

            <Cell>
                <BadgesPlus badges={positions} />
            </Cell>

            <Cell>
                <BadgesPlus badges={accreditations} />
            </Cell>

            <Cell>
                {props.vr.delegation && <Badge ellipsis={true}>{props.vr.delegation.name}</Badge>}
            </Cell>
        </Row>
    );
};
