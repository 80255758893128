import { AvatarNameEmail } from 'common-front/src/components/avatarNameEmail';
import { HorizontalSpacerSeparator } from 'common-front/src/designSystem/components/separator';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { LeaderInfosFragment } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

export const PositionCategoryLeadersList = ({ leaders }: { leaders: LeaderInfosFragment[] }) => {
    const translate = useTranslate();

    return isNonEmptyArray(leaders) ? (
        <>
            {leaders.map((leader, index) => (
                <React.Fragment key={leader.userInfo.id}>
                    {index !== 0 && <HorizontalSpacerSeparator height="4" />}

                    <AvatarNameEmail
                        badge={
                            leader.canEdit ? (
                                <Badge color="gray">{translate(leader.role.name)}</Badge>
                            ) : (
                                <Badge color="purple">{translate(leader.role.name)}</Badge>
                            )
                        }
                        userInfo={leader.userInfo}
                    />
                </React.Fragment>
            ))}
        </>
    ) : (
        <Box color="gray500" textAlign="center" width={1}>
            {translate('aucun_reponsabl_38504')}
        </Box>
    );
};
