import { OrganizationId } from 'common/src/generated/types';
import { assign } from 'lodash-es';
import * as React from 'react';
import { localDatabase, LocalOrganization } from '../localDatabase';

interface IUseLocalOrganizationReturn {
    getLocalOrganization(): Promise<LocalOrganization>;
    updateLocalOrganization(
        localOrganization: Partial<LocalOrganization>
    ): Promise<LocalOrganization>;
}

function useLocalOrganization(organizationId: OrganizationId): IUseLocalOrganizationReturn {
    const getLocalOrganization = React.useCallback(async () => {
        let o = await localDatabase.organizations.get(organizationId);

        if (!o) {
            o = { id: organizationId };

            await localDatabase.organizations.put(o);
        }

        return o;
    }, [organizationId]);
    const updateLocalOrganization = React.useCallback(
        async (updates: Partial<LocalOrganization>) => {
            const localOrganization = await getLocalOrganization();
            const newLocalOrganization = assign(localOrganization, { id: organizationId }, updates);

            await localDatabase.organizations.put(newLocalOrganization);

            return newLocalOrganization;
        },
        [organizationId, getLocalOrganization]
    );

    return { getLocalOrganization, updateLocalOrganization };
}

export function useLocalOrganizationState<S>(
    organizationId: OrganizationId,
    key: keyof LocalOrganization,
    initialState: S | (() => S)
): [S, React.Dispatch<S>, boolean] {
    const { getLocalOrganization, updateLocalOrganization } = useLocalOrganization(organizationId);
    const [state, _setState] = React.useState(initialState);
    const [isLoading, setIsLoading] = React.useState(true);
    const setState = React.useCallback(
        async (newState: S) => {
            _setState(newState);
            await updateLocalOrganization({ [key]: newState });
        },
        [_setState, updateLocalOrganization]
    );

    React.useEffect(() => {
        (async () => {
            const localOrganization = await getLocalOrganization();

            if (localOrganization[key]) {
                _setState(localOrganization[key] as S);
            }

            setIsLoading(false);
        })();
    }, [organizationId, getLocalOrganization, _setState, setIsLoading]);

    return [state, setState, isLoading];
}
