import { getAccreditationLimits } from 'common-front/src/delegations/accreditations/getAccreditationLimits';
import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { DropdownEllipsis } from 'common-front/src/designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { ProgressBar } from 'common-front/src/designSystem/components/progressBar';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    AccreditationSlotsQuery,
    AccreditationsSlotInput,
    Event
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { NumberService } from 'common/src/services/numberService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { AccreditationsPaths } from 'common/src/util/paths/accreditationsPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { fullName } from 'common/src/vo/accreditationSlot';
import * as React from 'react';
import {
    useAccreditationSlotDeleteMutation,
    useAccreditationSlotUpdateMutation
} from '../../../../generated/graphqlHooks';
import { CreateAccreditationCreateSlotModal } from '../../../create/createAccreditationCreateSlotModal';

interface IAccreditationSlotsSlotRowProps {
    event: Pick<Event, 'startAt' | 'endAt'>;
    slot: AccreditationSlotsQuery['event']['accreditationsSlots']['nodes'][0];

    reload(): void;
}

export const AccreditationSlotsSlotRow = ({
    event,
    slot,
    reload
}: IAccreditationSlotsSlotRowProps) => {
    const {
        history,
        params: { organizationId, eventId, accreditationId },
        translate
    } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const numberService = useService(NumberService);
    const { assignedResources, maxResources, percent } = getAccreditationLimits(undefined, slot);
    const { mutate: accreditationSlotUpdate } = useAccreditationSlotUpdateMutation();
    const { mutate: accreditationSlotDelete } = useAccreditationSlotDeleteMutation();
    const [isEditOpen, setIsEditOpen] = React.useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

    return (
        <>
            <Row
                css={{
                    cursor: 'pointer',
                    userSelect: 'none',
                    '&:hover': {
                        background: '$gray100'
                    }
                }}
                onClick={() =>
                    history.push(
                        AccreditationsPaths.ACCREDITATION_SLOT({
                            organizationId,
                            eventId,
                            accreditationId,
                            accreditationSlotId: slot.id
                        })
                    )
                }
            >
                <Cell>
                    <Box font="gray900 textSm medium">
                        {isNonEmptyString(slot.name) ? slot.name : '-'}
                    </Box>
                </Cell>

                <Cell>
                    {slot.date
                        ? dateTimeService.toLocaleString(
                              slot.date,
                              LocaleFormats.DateOnly.WeekdayLongMonthLong
                          )
                        : '-'}
                </Cell>

                <Cell>
                    <Flex
                        align="center"
                        css={{
                            '& > div': {
                                width: '100%'
                            }
                        }}
                        gap="2"
                        width={1}
                    >
                        <Box>
                            {translate(
                                '_1_2_membres_04485',
                                assignedResources,
                                numberService.toNumberOrInfinity(maxResources)
                            )}
                        </Box>

                        {maxResources && <ProgressBar percent={percent!} />}
                    </Flex>
                </Cell>

                <CellControls justify="end">
                    <DropdownEllipsis>
                        <ItemIcon
                            icon="pen"
                            onClick={() => {
                                setIsEditOpen(true);
                            }}
                        >
                            {translate('_diter_62574')}
                        </ItemIcon>

                        <ItemIcon
                            color={slot.assignedResources > 0 ? 'disabled' : 'red'}
                            icon="trash-can"
                            onClick={() => {
                                setIsDeleteModalOpen(true);
                            }}
                        >
                            {translate('supprimer_43083')}
                        </ItemIcon>
                    </DropdownEllipsis>
                </CellControls>
            </Row>

            {isEditOpen && (
                <CreateAccreditationCreateSlotModal
                    event={event}
                    slot={slot}
                    onClose={() => {
                        setIsEditOpen(false);
                    }}
                    onSuccess={async (updatedSlot: AccreditationsSlotInput) => {
                        await accreditationSlotUpdate({
                            eventId,
                            accreditationSlotId: slot.id,
                            accreditationSlot: updatedSlot
                        });

                        reload();
                    }}
                />
            )}

            {isDeleteModalOpen && (
                <DeleteModal
                    buttonText={translate('supprimer_43083')}
                    subtext={[translate('_tes_vous_s_r_d_48876')]}
                    text={translate('suppression_de_73669', fullName(dateTimeService, slot, ''))}
                    onClose={() => {
                        setIsDeleteModalOpen(false);
                    }}
                    onDelete={async () => {
                        await accreditationSlotDelete({
                            eventId,
                            accreditationSlotId: slot.id
                        });

                        reload();
                    }}
                />
            )}
        </>
    );
};
