import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { CSS } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

interface IAccordionProps {
    children: React.ReactNode;
    css?: CSS;
    isOpen?: boolean;
    title: React.ReactNode;
    titleCss?: CSS;

    setIsOpen?(isOpen: boolean): void;
}

export const Accordion = (props: IAccordionProps) => {
    const [isOpen, _setIsOpen] = React.useState(props.isOpen ?? true);
    const setIsOpen = React.useCallback(
        (newIsOpen: boolean) => {
            props.setIsOpen?.(newIsOpen);
            _setIsOpen(newIsOpen);
        },
        [props.setIsOpen, _setIsOpen]
    );

    React.useEffect(() => {
        if (typeof props.isOpen === 'boolean') {
            _setIsOpen(props.isOpen);
        }
    }, [props.isOpen]);

    return (
        <Flex
            css={{
                borderTop: '1px solid $gray200',
                padding: '$6',
                ...(props.css as any)
            }}
            direction="column"
            gap="4"
        >
            <Flex
                align="center"
                css={{ cursor: 'pointer', userSelect: 'none', ...(props.titleCss as any) }}
                gap="4"
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            >
                <Box color="gray500" fontSize="textXs">
                    <I icon={isOpen ? 'chevron-down' : 'chevron-right'} />
                </Box>

                <Flex
                    css={{
                        color: '$gray800',
                        flex: '1',
                        fontWeight: '$semiBold'
                    }}
                >
                    {props.title}
                </Flex>
            </Flex>

            {isOpen && props.children}
        </Flex>
    );
};
