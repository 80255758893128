import { injectable } from 'inversify';
import * as yup from 'yup';
import { FormParametersGeneralInput, FormParametersGeneralQuery } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { InputService } from './inputService';

export interface IUpdateFormParametersGeneralValues {
    form: FormParametersGeneralInput;
}

@injectable()
export class FormParametersGeneralInputService extends InputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    formParametersGeneralInputDefault(
        form?: FormParametersGeneralQuery['organization']['form']
    ): FormParametersGeneralInput {
        return {
            name: form?.name ?? '',
            allowMultiProfiles: form?.allowMultiProfiles ?? false,
            setAssignmentStateToWaiting: form?.setAssignmentStateToWaiting ?? false,
            setAccreditationStateToWaiting: form?.setAccreditationStateToWaiting ?? false,
            description: form?.description || '',
            successMessage: form?.successMessage || '',
            customFields: form?.customFields || [],
            areTeamsAllowed: form?.areTeamsAllowed ?? false,
            maxTeamMembers: form?.maxTeamMembers,
            teamMemberFillFullForm: form?.teamMemberFillFullForm ?? true,
            emailTitle: form?.emailTitle || '',
            informationTitle: form?.informationTitle || '',
            registerButtonText: form?.registerButtonText || '',
            isEditableInMemberSpace: form?.isEditableInMemberSpace ?? true
        };
    }

    formParametersGeneralInputSchema() {
        return yup.object().shape({
            name: yup.string().required(this.t('le_nom_est_requ_61536')),
            allowMultiProfiles: yup.boolean().required(),
            setAssignmentStateToWaiting: yup.boolean().required(),
            setAccreditationStateToWaiting: yup.boolean().required(),
            description: yup.string(),
            successMessage: yup.string(),
            areTeamsAllowed: yup.boolean().required(),
            maxTeamMembers: yup.number().nullable(),
            teamMemberFillFullForm: yup.boolean().required(),
            informationTitle: yup.string(),
            registerButtonText: yup.string()
        });
    }

    updateFormParametersGeneralSchema() {
        return yup.object().shape({
            form: this.formParametersGeneralInputSchema()
        });
    }
}
