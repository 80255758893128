import { Avatar } from 'common/src/components/avatar/avatar';
import { Box } from 'common/src/designSystem/components/box';
import { Cell } from 'common/src/designSystem/components/table/cell';
import {
    CustomFieldSlug,
    SegmentCustomFieldFragment,
    UserInfoCommunityFragment
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { LocaleFormats } from 'common/src/util/luxon';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import { BadgesPlus } from '../../components/badgesPlus/badgesPlus';
import { UpdateCustomFieldCell } from '../../components/cells/updateCustomFieldCell';
import { UpdateNameCell } from '../../components/cells/updateNameCell';
import { useUserInfoUpdateFieldMutation } from '../../generated/graphqlHooks';
import { useHeavent } from '../../hooks/useHeavent';

interface ICommunityUserCellsProps {
    canClickMemberName: boolean;
    column: PossibleColumn;
    customField: Emptyable<SegmentCustomFieldFragment>;
    row: UserInfoCommunityFragment;
    updatable: boolean;

    reloadRow(): void;
}

export const CommunityUserCells = (props: ICommunityUserCellsProps) => {
    const {
        params: { organizationId }
    } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const { mutate: userInfoUpdateField } = useUserInfoUpdateFieldMutation();
    const updateField = React.useCallback(
        (value: any, slug: CustomFieldSlug) =>
            userInfoUpdateField({
                organizationId,
                userInfoId: props.row.id,
                slug,
                value
            }),
        [props.row, userInfoUpdateField]
    );
    const formsBadges = React.useMemo(
        () =>
            (props.row.formsUsersInfos || []).flatMap((fui) => {
                if (fui.form) {
                    return [
                        {
                            id: fui.form.id,
                            color: 'gray',
                            icon: null,
                            text: fui.form.name
                        }
                    ];
                } else {
                    return [];
                }
            }),
        [props.row.formsUsersInfos]
    );
    const formsInsertedAt = React.useMemo(
        () =>
            (props.row.formsUsersInfos || [])
                .flatMap((fui) => {
                    if (fui.form) {
                        const name = fui.form.name;
                        const insertedAt = dateTimeService.toLocaleString(
                            fui.insertedAt.toLocal(),
                            LocaleFormats.DateTime
                        );

                        return [`${name} : ${insertedAt}`];
                    } else {
                        return [];
                    }
                })
                .join(', '),
        [props.row.formsUsersInfos]
    );
    const delegationsBadges = React.useMemo(
        () =>
            (props.row.delegations || []).map((delegation) => ({
                id: delegation.id,
                color: 'primary',
                icon: null,
                text: delegation.name
            })),
        [props.row.delegations]
    );

    return props.column.slug === 'name' ? (
        <UpdateNameCell
            canClickMemberName={props.canClickMemberName}
            mutate={updateField}
            reload={props.reloadRow}
            updatable={props.updatable}
            userInfo={props.row}
        />
    ) : props.column.slug === 'id' ? (
        <Cell width={100}>{props.row.id}</Cell>
    ) : props.column.slug === 'picture' ? (
        <Cell width={80}>
            <Avatar
                email={props.row.email}
                image={props.row.picture?.url}
                name={props.row.name}
                size={32}
            />
        </Cell>
    ) : props.column.slug === 'email' ? (
        <Cell>{props.row.email}</Cell>
    ) : props.column.slug === 'events' ? (
        <Cell>
            <Box css={{ ellipsis: true }} title={props.row.eventsNames}>
                {props.row.eventsNames}
            </Box>
        </Cell>
    ) : props.column.slug === 'number_of_events' ? (
        <Cell width={230}>{props.row.numberOfEvents}</Cell>
    ) : props.column.slug === 'forms' ? (
        <Cell>
            <BadgesPlus badges={formsBadges} />
        </Cell>
    ) : props.column.slug === 'forms_inserted_at' ? (
        <Cell>
            <Box css={{ ellipsis: true }} title={formsInsertedAt}>
                {formsInsertedAt}
            </Box>
        </Cell>
    ) : props.column.slug === 'delegations' ? (
        <Cell>
            <BadgesPlus badges={delegationsBadges} />
        </Cell>
    ) : props.column.slug === 'inserted_at' ? (
        <Cell width={230}>
            {dateTimeService.toLocaleString(props.row.insertedAt.toLocal(), LocaleFormats.DateTime)}
        </Cell>
    ) : (
        <UpdateCustomFieldCell
            customField={props.customField}
            fields={props.row.fields}
            mutate={updateField}
            reload={props.reloadRow}
            updatable={props.updatable}
        />
    );
};
