import { EventAccreditationsFragment } from 'common/src/generated/types';
import { ICreateUpdateDelegationValues } from 'common/src/input/delegationInput';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { fullName } from 'common/src/vo/accreditationSlot';
import { difference } from 'lodash-es';
import * as React from 'react';

export function useDeletedAccreditationsSlots(
    allAccreditationCategories: EventAccreditationsFragment['accreditationsCategories'],
    initialValues: ICreateUpdateDelegationValues,
    values: ICreateUpdateDelegationValues
) {
    const dateTimeService = useService(DateTimeService);
    const deletedAccreditationsSlotsIds = React.useMemo(() => {
        const initialAccreditationsSlotsIds = initialValues.delegation.accreditationsSlots.map(
            (as) => as.accreditationSlotId
        );
        const accreditationsSlotsIds = values.delegation.accreditationsSlots.map(
            (as) => as.accreditationSlotId
        );

        return difference(initialAccreditationsSlotsIds, accreditationsSlotsIds);
    }, [initialValues, values]);

    return React.useMemo(
        () =>
            allAccreditationCategories.flatMap((category) =>
                category.accreditations.flatMap((accreditation) =>
                    accreditation.slots.flatMap((slot) => {
                        if (deletedAccreditationsSlotsIds.includes(slot.id)) {
                            return [
                                fullName(dateTimeService, slot, accreditation.name, {
                                    accreditation: { includeName: true }
                                })
                            ];
                        } else {
                            return [];
                        }
                    })
                )
            ),
        [deletedAccreditationsSlotsIds, allAccreditationCategories]
    );
}
