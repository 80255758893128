import { PhoneInput } from 'common-front/src/designSystem/form/phoneInput';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

export const OrganizationForm = React.memo(function OrganizationForm() {
    const translate = useTranslate();

    return (
        <>
            <TextInput label={translate('nom_de_l_organi_49583')} name="organization.name" />
            <Spacer height="4" />
            <Flex gap="4">
                <TextInput label={translate('pr_nom_66988')} name="organization.adminFirstName" />

                <TextInput label={translate('last_name')} name="organization.adminLastName" />
            </Flex>
            <Spacer height="4" />
            <PhoneInput label={translate('Phone')} prefix="organization.adminPhone." />
        </>
    );
});
