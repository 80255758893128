import { buildPath, commonSlugs as CommonPathComponents, UserParams } from './commonPaths';
import { EventParams, EventsPaths } from './eventsPaths';
import {
    PositionCategoryIdPathParam,
    PositionIdPathParam,
    PositionSlotIdPathParam
} from './pathsTypes';

const slugs = {
    ITEMS: {
        POSITIONS_CATEGORIES: {
            PLURAL: 'position-categories',
            SINGLE: 'position-category'
        },
        POSITIONS: {
            PLURAL: 'positions',
            SINGLE: 'position'
        },
        POSITION_SLOTS: {
            PLURAL: 'slots',
            SINGLE: 'slot'
        }
    },
    ACTIONS: {
        IMPORT: {
            CSV: `${CommonPathComponents.ACTIONS.IMPORT}/csv`
        }
    },
    SECTIONS: {
        PREASSIGNED_MEMBERS: 'preassigned-members'
    }
} as const;

const itemPaths = {
    POSITION_CATEGORIES: slugs.ITEMS.POSITIONS_CATEGORIES.PLURAL,
    POSITION_CATEGORY: (positionCategoryId: PositionCategoryIdPathParam) =>
        buildPath([slugs.ITEMS.POSITIONS_CATEGORIES.SINGLE, positionCategoryId]),

    POSITIONS: slugs.ITEMS.POSITIONS.PLURAL,
    POSITION: (positionId: PositionIdPathParam) =>
        buildPath([slugs.ITEMS.POSITIONS.SINGLE, positionId]),

    POSITION_SLOTS: slugs.ITEMS.POSITION_SLOTS.PLURAL,
    POSITION_SLOT: (positionSlotId: PositionSlotIdPathParam) =>
        buildPath([slugs.ITEMS.POSITION_SLOTS.SINGLE, positionSlotId])
};

export type PositionCategoryParams = EventParams & {
    positionCategoryId: PositionCategoryIdPathParam;
};

export type PositionParams = EventParams &
    Partial<PositionCategoryParams> & {
        positionId: PositionIdPathParam;
    };

type PositionSlotParams = PositionParams & {
    positionSlotId: PositionSlotIdPathParam;
};

const positionCategoriesPaths = {
    //
    // CREATE
    //

    //
    // READ
    //

    POSITION_CATEGORY: ({ positionCategoryId, ...params }: PositionCategoryParams) =>
        buildPath([EventsPaths.EVENT(params), itemPaths.POSITION_CATEGORY(positionCategoryId)]),

    // Backoffice space

    POSITION_CATEGORY_DASHBOARD: (params: PositionCategoryParams) =>
        buildPath([
            positionCategoriesPaths.POSITION_CATEGORY(params),
            CommonPathComponents.SECTIONS.DASHBOARD
        ]),
    POSITION_CATEGORY_MEMBERS: (params: PositionCategoryParams) =>
        buildPath([
            positionCategoriesPaths.POSITION_CATEGORY(params),
            CommonPathComponents.SECTIONS.MEMBERS
        ]),
    POSITION_CATEGORY_PREASSIGNED_MEMBERS: (params: PositionCategoryParams) =>
        buildPath([
            positionCategoriesPaths.POSITION_CATEGORY(params),
            slugs.SECTIONS.PREASSIGNED_MEMBERS
        ])

    //
    // UPDATE
    //

    //
    // DELETE
    //
};

const positionsPaths = {
    //
    // CREATE
    //

    //
    // READ
    //

    POSITIONS: (params: EventParams) => buildPath([EventsPaths.EVENT(params), itemPaths.POSITIONS]),
    POSITION: ({ positionId, positionCategoryId, ...params }: PositionParams) =>
        positionCategoryId
            ? buildPath([
                  positionCategoriesPaths.POSITION_CATEGORY({ positionCategoryId, ...params }),
                  itemPaths.POSITION(positionId)
              ])
            : buildPath([EventsPaths.EVENT(params), itemPaths.POSITION(positionId)]),

    // Backoffice space

    POSITION_DASHBOARD: (params: PositionParams) =>
        buildPath([positionsPaths.POSITION(params), CommonPathComponents.SECTIONS.DASHBOARD]),
    POSITION_MEMBERS: (params: PositionParams) =>
        buildPath([positionsPaths.POSITION(params), CommonPathComponents.SECTIONS.MEMBERS]),
    POSITION_PREASSIGNED_MEMBERS: (params: PositionParams) =>
        buildPath([positionsPaths.POSITION(params), slugs.SECTIONS.PREASSIGNED_MEMBERS]),

    // Member overlay

    POSITION_MEMBER: ({ userInfoId, ...params }: UserParams<PositionParams>) =>
        buildPath([
            positionsPaths.POSITION_MEMBERS(params),
            CommonPathComponents.SECTIONS.MEMBER,
            userInfoId
        ]),
    POSITION_MEMBER_INFORMATION: (params: UserParams<PositionParams>) =>
        buildPath([
            positionsPaths.POSITION_MEMBER(params),
            CommonPathComponents.SECTIONS.INFORMATION
        ]),

    //
    // UPDATE
    //

    // Imports
    POSITION_IMPORT_CSV: (params: EventParams) =>
        buildPath([EventsPaths.EVENT(params), slugs.ACTIONS.IMPORT.CSV])

    //
    // DELETE
    //
};

const positionSlotsPaths = {
    //
    // CREATE
    //

    //
    // READ
    //

    POSITION_SLOTS: (params: PositionParams) =>
        buildPath([positionsPaths.POSITION(params), itemPaths.POSITION_SLOTS]),
    POSITION_SLOT: ({ positionSlotId, ...params }: PositionSlotParams) =>
        buildPath([positionsPaths.POSITION(params), itemPaths.POSITION_SLOT(positionSlotId)])

    //
    // UPDATE
    //

    //
    // DELETE
    //
};

export const PositionsPaths = {
    ...positionCategoriesPaths,
    ...positionsPaths,
    ...positionSlotsPaths
};
