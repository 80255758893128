import { CommonEnvVars } from 'common/src/envVars';

export async function reportLog(logs: any) {
    await fetch(`${CommonEnvVars.HEAVENT_API_URL}/log/report`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(logs)
    });
}
