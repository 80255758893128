import { Item } from 'common-front/src/designSystem/components/leftMenu/item';
import { LeftMenu } from 'common-front/src/designSystem/components/leftMenu/leftMenu';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Grid } from 'common/src/designSystem/components/grid';
import { Feature } from 'common/src/generated/types';
import { FormsPaths } from 'common/src/util/paths/formsPaths';
import * as React from 'react';
import { NotSavedModal } from '../../../documentBuilder/notSavedModal';
import { useOrganizationContext } from '../../../organization/organizationContext';

interface IFormParametersContainerProps {
    children: React.ReactNode;
    isNotSavedOpen: boolean;

    onNotSavedClose(): void;
}

export const FormParametersContainer = (props: IFormParametersContainerProps) => {
    const { translate, params } = useHeavent();
    const { hasFeature } = useOrganizationContext();

    return (
        <>
            <Grid
                css={{ py: '$8' }}
                gridtemplatecolumns="1fr 210px 80px 880px 1fr"
                gridtemplaterows="1fr"
                width={1}
            >
                <Box css={{ gridColumn: '2 / 3' }}>
                    <LeftMenu>
                        <Item to={FormsPaths.FORM_PARAMETERS_GENERAL(params)}>
                            {translate('g_n_ral_64953')}
                        </Item>

                        <Item to={FormsPaths.FORM_PARAMETERS_DESIGN(params)}>
                            {translate('design_15043')}
                        </Item>

                        {params.eventId && (
                            <Item to={FormsPaths.FORM_PARAMETERS_AVAILABILITIES(params)}>
                                {translate('disponibilit_s_49923')}
                            </Item>
                        )}

                        {params.eventId && (
                            <Item to={FormsPaths.FORM_PARAMETERS_MISSIONS(params)}>
                                {translate('missions_63972')}
                            </Item>
                        )}

                        {params.eventId && hasFeature(Feature.Accreditation) && (
                            <Item to={FormsPaths.FORM_PARAMETERS_ACCREDITATIONS(params)}>
                                {translate('accr_ditations_39450')}
                            </Item>
                        )}

                        <Item to={FormsPaths.FORM_PARAMETERS_BROADCAST(params)}>
                            {translate('diffusion_56689')}
                        </Item>

                        <Item to={FormsPaths.FORM_PARAMETERS_STATE(params)}>
                            {translate('cl_turer_88223')}
                        </Item>
                    </LeftMenu>
                </Box>

                <Box css={{ gridColumn: '4 / 5' }} width={1}>
                    {props.children}
                </Box>
            </Grid>

            <NotSavedModal
                fallbackPath={FormsPaths.FORMS({
                    organizationId: params.organizationId,
                    eventId: params.eventId
                })}
                isOpen={props.isNotSavedOpen}
                onClose={props.onNotSavedClose}
            />
        </>
    );
};
