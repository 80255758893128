import { Accreditation } from 'common-front/src/components/accreditations/v2/accreditation';
import { Checkbox } from 'common-front/src/designSystem/components/checkbox';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Table } from 'common/src/designSystem/components/table/table';
import { MassAssignSlotsQuery, PositionsSlotId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { difference, intersection, sortBy, uniq } from 'lodash-es';
import * as React from 'react';
import { MassAssignPositionSlot } from './massAssignPositionSlot';

interface IMassAssignPositionProps {
    position: MassAssignSlotsQuery['event']['positionsSlots']['nodes'][number]['position'];
    slots: MassAssignSlotsQuery['event']['positionsSlots']['nodes'];
    positionsSlotsIds: PositionsSlotId[];

    change(name: string, value: any): void;
}

export const MassAssignPosition = (props: IMassAssignPositionProps) => {
    const translate = useTranslate();
    const slots = React.useMemo(
        () =>
            sortBy(
                props.slots.filter((s) => s.position.id === props.position.id),
                (s) => s.range.start!.toMillis()
            ),
        [props.slots, props.position]
    );
    const slotsIds = React.useMemo(() => slots.map((s) => s.id), [slots]);
    const state = React.useMemo(() => {
        const selecteds = intersection(props.positionsSlotsIds, slotsIds);

        return selecteds.length === slotsIds.length
            ? 'checked'
            : selecteds.length === 0
              ? 'unchecked'
              : 'indeterminate';
    }, [slotsIds, props.positionsSlotsIds]);

    return (
        <Accreditation
            accreditation={props.position}
            displaySlots={true}
            numberOfSlots={slots.length}
        >
            <Table hideBorder={true}>
                <HeaderRow>
                    <HeaderCell justify="center" width={48}>
                        <Checkbox
                            state={state}
                            onClick={(newState) => {
                                if (newState === 'checked') {
                                    props.change(
                                        'massAssign.positionsSlotsIds',
                                        uniq(props.positionsSlotsIds.concat(slotsIds))
                                    );
                                } else {
                                    props.change(
                                        'massAssign.positionsSlotsIds',
                                        difference(props.positionsSlotsIds, slotsIds)
                                    );
                                }
                            }}
                        />
                    </HeaderCell>
                    <HeaderCell>{translate('date_du_cr_neau_82295')}</HeaderCell>
                    <HeaderCell>{translate('nom_du_cr_neau_54351')}</HeaderCell>
                    <HeaderCell />
                </HeaderRow>

                {slots.map((slot) => (
                    <MassAssignPositionSlot
                        key={slot.id}
                        change={props.change}
                        positionsSlotsIds={props.positionsSlotsIds}
                        slot={slot}
                    />
                ))}
            </Table>
        </Accreditation>
    );
};
