import { CenteredContainer } from 'common-front/src/components/centeredContainer/centeredContainer';
import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import { Button } from 'common-front/src/designSystem/components/button';
import { Flex } from 'common/src/designSystem/components/flex';
import { CustomFieldVariety, PositionsImportInput } from 'common/src/generated/types';
import { DEFAULT_CSV_SETTINGS } from 'common/src/input/csvInput';
import {
    POSITIONS_IMPORT_FIELDS_DEFAULT,
    POSITIONS_IMPORT_FIELDS_REQUIRED,
    PositionsImportInputService
} from 'common/src/input/positionsImportInput';
import { ValidateService } from 'common/src/services/validateService';
import { useParams, useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { FormApi } from 'final-form';
import * as React from 'react';
import { CSVFormBox } from '../../../common/import/csvFormBox';
import { ImportProcessingAlert } from '../../../common/import/importProcessingAlert';
import { MappingsFormBoxes } from '../../../common/import/mappingsFormBoxes';
import {
    useOrganizationImportInfosQuery,
    usePositionsImportMutation
} from '../../../generated/graphqlHooks';
import { useNotificationContext } from '../../../notifications/notificationContext';

const isFileValid = (form: FormApi<{ input: PositionsImportInputWithFile }>): boolean =>
    !!form.getFieldState('input.csv.key')?.valid && !!form.getFieldState('input.csv.file')?.valid;

const removeFileFromInput = (input: PositionsImportInputWithFile) => {
    const csvCopy = { ...input.csv };

    delete csvCopy.file;

    return {
        ...input,
        csv: csvCopy
    };
};

type PositionsImportInputWithFile = PositionsImportInput & {
    csv: {
        file?: File;
    };
};

export const PositionsCsvImport = () => {
    const translate = useTranslate();
    const positionsImportInput = useService(PositionsImportInputService);
    const validateService = useService(ValidateService);
    const centeredContainerRef = React.useRef<HTMLDivElement | null>(null);
    const { eventId, organizationId } = useParams();
    const { data, loader } = useOrganizationImportInfosQuery({
        organizationId,
        variety: CustomFieldVariety.Position
    });
    const { mutate } = usePositionsImportMutation();
    const { checkPositionsImport } = useNotificationContext();

    if (loader) {
        return loader;
    } else {
        const customFields = data.organization.customFields.nodes;

        return (
            <Form
                height={1}
                hideErrors={true}
                initialValues={{
                    input: positionsImportInput.default().input as PositionsImportInputWithFile
                }}
                render={({ form, handleSubmit, submitting, values }) => (
                    <FullScreenPopup
                        button={
                            <Button isLoading={submitting} onClick={handleSubmit}>
                                {translate('importer_la_lis_12866')}
                            </Button>
                        }
                        category={translate('importing_tasks_31757')}
                        color="dark"
                        title={translate('nouvel_import_48306')}
                    >
                        <CenteredContainer ref={centeredContainerRef}>
                            {form.getState().submitSucceeded && <ImportProcessingAlert />}
                            <FormErrors />

                            <Flex direction="column" gap="5">
                                <CSVFormBox />

                                {values.input.csv?.file && isFileValid(form) && (
                                    <MappingsFormBoxes<PositionsImportInputWithFile>
                                        accreditations={[]}
                                        customFields={customFields}
                                        defaultFields={POSITIONS_IMPORT_FIELDS_DEFAULT}
                                        file={values.input.csv.file}
                                        form={form}
                                        requiredFieldSlugs={POSITIONS_IMPORT_FIELDS_REQUIRED}
                                        values={values}
                                    />
                                )}
                            </Flex>
                        </CenteredContainer>
                    </FullScreenPopup>
                )}
                validate={({ input }) =>
                    validateService.validateForForm(
                        positionsImportInput.schema({
                            delimiter: input.csv.delimiter ?? DEFAULT_CSV_SETTINGS.delimiter,
                            quoteChar: input.csv.quoteChar ?? undefined,
                            newline: (input.csv.newline as any) ?? undefined // `any` cast needed because `newline` here might be any string
                        })
                    )({ input })
                }
                onShowErrors={() => {
                    if (centeredContainerRef.current) {
                        centeredContainerRef.current.scrollTop = 0;
                    }
                }}
                onSubmit={async ({ input }) => {
                    const inputWithoutFile = removeFileFromInput(input);

                    try {
                        const { jobId } = await mutate({
                            eventId,
                            organizationId,
                            input: inputWithoutFile
                        });
                        if (centeredContainerRef.current) {
                            centeredContainerRef.current.scrollTop = 0;
                        }
                        checkPositionsImport(organizationId, jobId);
                    } catch (e) {
                        /* Already displayed in <FormErrors />. */
                    }
                }}
            />
        );
    }
};
