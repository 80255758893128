import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Table } from 'common/src/designSystem/components/table/table';
import { UserProfileAccreditationsQuery } from 'common/src/generated/types';
import * as React from 'react';
import { UserAccreditationsAssignedListRow } from './userAccreditationsAssignedListRow';

interface IUserAccreditationsAssignedListProps {
    event: UserProfileAccreditationsQuery['event'];

    reload(): void;
}

export const UserAccreditationsAssignedList = (props: IUserAccreditationsAssignedListProps) => {
    const { translate } = useHeavent();

    return (
        <Table css={{ borderTop: '1px solid $gray200' }} hideBorder={true}>
            <HeaderRow>
                <HeaderCell>{translate('Accreditation')}</HeaderCell>
                <HeaderCell>{translate('affect_e_automa_74071')}</HeaderCell>
                {props.event.isWeezeventConnected && (
                    <HeaderCell>{translate('scann_e_le_27812')}</HeaderCell>
                )}
                <HeaderCell width={100} />
            </HeaderRow>

            {props.event.volunteerRegistration.accreditationsUsersInfos.map((aui) => (
                <UserAccreditationsAssignedListRow
                    key={aui.id}
                    aui={aui}
                    isWeezeventConnected={props.event.isWeezeventConnected}
                    reload={props.reload}
                />
            ))}
        </Table>
    );
};
