import { interfaces } from 'inversify';
import * as React from 'react';
import { IFragment } from '../../generated/fragments';
import {
    AccreditationId,
    AccreditationsCategoryId,
    AccreditationsSlotId,
    CampaignId,
    CustomBadge,
    CustomDocumentId,
    CustomFieldId,
    DelegationId,
    DelegationsCategoryId,
    EmailsSenderId,
    EventId,
    FormId,
    OrganizationId,
    PositionId,
    PositionsCategoryId,
    PositionsSlotId,
    SegmentId,
    TeamCode,
    UsersInfoId,
    VolunteersRegistrationId,
    WeezeventRuleId
} from '../../generated/types';
import { IStorage } from '../../hooks/types';
import { NodeEnv } from '../../nodeEnv';
import { TranslateFn, TranslationService } from '../../services/translationService';
import { IUseMutationOptions, IUseMutationReturns, IUseQueryReturns } from '../graphql/types';
import { DependenciesContext } from './dependenciesContext';

export function useService<T>(serviceIdentifier: interfaces.ServiceIdentifier<T>): T {
    return React.useContext(DependenciesContext).container.get(serviceIdentifier);
}

export function useTranslate(): TranslateFn {
    const translationService =
        React.useContext(DependenciesContext).container.get(TranslationService);

    return translationService.translate.bind(translationService);
}

export function useQuery<TResult, TParams extends Record<string, any>>(
    _query: string,
    variables: TParams,
    fragments: IFragment[] = []
): IUseQueryReturns<TResult> {
    // Required because Jest GraphQL parser returns objects in Jest 28+
    const query = process.env.NODE_ENV !== NodeEnv.Test ? _query : (_query as any).loc.source.body;

    return React.useContext(DependenciesContext).useQuery(query, variables, fragments);
}

export function useMutation<TResult, TParams extends Record<string, any>>(
    query: string,
    args: IUseMutationOptions = {}
): IUseMutationReturns<TResult, TParams> {
    return React.useContext(DependenciesContext).useMutation(query, args);
}

export type UseParamsReturn = {
    accreditationsCategoryId: AccreditationsCategoryId;
    accreditationId: AccreditationId;
    accreditationsSlotId: AccreditationsSlotId;
    badgeType: CustomBadge;
    campaignId: CampaignId;
    customDocumentId: CustomDocumentId;
    customFieldId: CustomFieldId;
    delegationCategoryId: DelegationsCategoryId;
    delegationId: DelegationId;
    emailSenderId: EmailsSenderId;
    eventId: EventId;
    formId: FormId;
    options: string;
    organizationId: OrganizationId;
    positionCategoryId: PositionsCategoryId;
    positionId: PositionId;
    positionSlotId: PositionsSlotId;
    ruleId: WeezeventRuleId;
    segmentId: SegmentId;
    teamCode: TeamCode;
    userInfoId: UsersInfoId;
    volunteerRegistrationId: VolunteersRegistrationId;
};

export function useParams<T>(): T & UseParamsReturn {
    return React.useContext(DependenciesContext).useParams();
}

export function useHistory() {
    return React.useContext(DependenciesContext).useHistory();
}

export function useLocation() {
    return React.useContext(DependenciesContext).useLocation();
}

export function useLocalStorage(): IStorage {
    return React.useContext(DependenciesContext).useLocalStorage();
}
