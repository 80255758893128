import { Page } from 'common-front/src/components/page/page';
import { Tab } from 'common-front/src/designSystem/components/tabs/tab';
import { TabList } from 'common-front/src/designSystem/components/tabs/tabList';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { PositionCategoryQuery } from 'common/src/generated/types';
import { useLocation } from 'common/src/util/dependencies/dependencies';
import { PositionCategoryParams, PositionsPaths } from 'common/src/util/paths/positionsPaths';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { PositionMembers } from '../../positions/show/members/positionMembers';
import { PositionCategoryDashboard } from './dashboard/positionCategoryDashboard';
import { PositionCategoryHeader } from './positionCategoryHeader';

interface IPositionCategoryProps {
    event: PositionCategoryQuery['event'];
    roles: PositionCategoryQuery['organization']['roles'];

    reload(): void;
}

export const PositionCategory = ({ event, roles, reload }: IPositionCategoryProps) => {
    const {
        translate,
        params: { organizationId, eventId, positionCategoryId }
    } = useHeavent();
    useTitle(event.positionCategory.name);
    const location = useLocation();
    const { updateLocalEvent } = useEventContext();

    React.useEffect(() => {
        updateLocalEvent({ positionBackPath: location.pathname });
    }, []);

    const getPositionsCategoryParams = (isRouteComponent: boolean): PositionCategoryParams => ({
        organizationId: isRouteComponent ? ':organizationId' : organizationId,
        eventId: isRouteComponent ? ':eventId' : eventId,
        positionCategoryId: isRouteComponent ? ':positionCategoryId' : positionCategoryId
    });

    return (
        <Page.Root>
            <PositionCategoryHeader category={event.positionCategory} reload={reload} />

            <Tabs css={{ flex: '1' }}>
                <TabList css={{ px: '$7' }}>
                    <Tab
                        path={PositionsPaths.POSITION_CATEGORY_DASHBOARD(
                            getPositionsCategoryParams(false)
                        )}
                    >
                        {translate('tableau_de_bord_24627')}
                    </Tab>

                    <Tab
                        path={PositionsPaths.POSITION_CATEGORY_MEMBERS(
                            getPositionsCategoryParams(false)
                        )}
                    >
                        {translate('membres_125561', event.positionCategory.assignedResources)}
                    </Tab>

                    <Tab
                        path={PositionsPaths.POSITION_CATEGORY_PREASSIGNED_MEMBERS(
                            getPositionsCategoryParams(false)
                        )}
                    >
                        {translate(
                            'membres_pr_aff_93093',
                            event.positionCategory.preassignedResources
                        )}
                    </Tab>
                </TabList>

                <TabPanel
                    path={PositionsPaths.POSITION_CATEGORY_DASHBOARD(
                        getPositionsCategoryParams(true)
                    )}
                >
                    <PositionCategoryDashboard
                        positionCategory={event.positionCategory}
                        reload={reload}
                        roles={roles}
                    />
                </TabPanel>

                <TabPanel
                    path={PositionsPaths.POSITION_CATEGORY_MEMBERS(
                        getPositionsCategoryParams(true)
                    )}
                >
                    <PositionMembers positionCategoryId={positionCategoryId} />
                </TabPanel>

                <TabPanel
                    path={PositionsPaths.POSITION_CATEGORY_PREASSIGNED_MEMBERS(
                        getPositionsCategoryParams(true)
                    )}
                >
                    <PositionMembers preassignedPositionCategoryId={positionCategoryId} />
                </TabPanel>
            </Tabs>
        </Page.Root>
    );
};
