import { injectable } from 'inversify';
import * as yup from 'yup';
import {
    ALL_FORMSTATE,
    FormParametersStateInput,
    FormParametersStateQuery,
    FormState
} from '../generated/types';
import { TranslationService } from '../services/translationService';
import { DateTimeSchemaType } from '../util/yup/dateTimeSchemaType';
import { InputService } from './inputService';

export interface IUpdateFormParametersStateValues {
    form: FormParametersStateInput;
}

@injectable()
export class FormParametersStateInputService extends InputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    formParametersStateInputDefault(
        form?: FormParametersStateQuery['organization']['form']
    ): FormParametersStateInput {
        return {
            state: form?.state || FormState.Open,
            closedTitle: form?.closedTitle || '',
            closedMessage: form?.closedMessage || '',
            closedAt: form?.closedAt || null
        };
    }

    formParametersStateInputSchema() {
        return yup.object().shape({
            state: yup
                .string()
                .required(this.t('cl_turer_le_for_10541'))
                .oneOf(ALL_FORMSTATE, this.t('cl_turer_le_for_10541')),
            closedMessage: yup.string(),
            closedAt: DateTimeSchemaType.when('state', {
                is: FormState.ClosedAt,
                then: (s) =>
                    s
                        .required(this.t('la_date_de_cl_t_63358'))
                        .typeError(this.t('la_date_de_cl_t_63358')),
                otherwise: (s) => s.nullable()
            })
        });
    }

    updateFormParametersStateSchema() {
        return yup.object().shape({
            form: this.formParametersStateInputSchema()
        });
    }
}
