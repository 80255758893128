import { Button } from 'common-front/src/designSystem/components/button';
import {
    useVolunteersRegistrationsMassAssignMutation,
    useVolunteersRegistrationsMassStateUpdateMutation
} from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    MassAssignStrategy,
    MassPreAssignLevel,
    UsersInfoId,
    VolunteerRegistrationState,
    VolunteersRegistrationId
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import {
    useVolunteerRegistrationAssignmentRefuseMutation,
    useVolunteersRegistrationsMassPreAssignMutation
} from '../../generated/graphqlHooks';
import { useAssignmentContext } from './assignmentContext';

interface IAssignmentButtonsProps {
    isEdit: boolean;
    userInfoId: UsersInfoId;
    volunteerRegistrationId: VolunteersRegistrationId;
}

export const AssignmentButtons = (props: IAssignmentButtonsProps) => {
    const {
        history,
        params: { organizationId, eventId },
        translate
    } = useHeavent();
    const {
        isPreAssign,
        positionsIds,
        positionsCategoriesIds,
        positionsSlotsIds,
        showNextVolunteer
    } = useAssignmentContext();
    const { mutate: massAssign, isLoading: massAssignIsLoading } =
        useVolunteersRegistrationsMassAssignMutation();
    const { mutate: massPreAssign, isLoading: massPreAssignIsLoading } =
        useVolunteersRegistrationsMassPreAssignMutation();
    const { mutate: refuse, isLoading: refuseIsLoading } =
        useVolunteerRegistrationAssignmentRefuseMutation();
    const { mutate: massStateUpdate, isLoading: massStateUpdateIsLoading } =
        useVolunteersRegistrationsMassStateUpdateMutation();

    const onSuccess = React.useCallback(() => {
        if (props.isEdit) {
            history.goBack(HeaventPaths.ASSIGNMENTS(organizationId, eventId));
        } else {
            showNextVolunteer();
        }
    }, [props.isEdit, showNextVolunteer]);

    return (
        <Flex
            align="center"
            css={{
                borderTop: '1px solid $gray200',
                px: '$7'
            }}
            gap="4"
            height={1}
            width={1}
        >
            <Box
                color="error700"
                css={{ cursor: 'pointer' }}
                onClick={async () => {
                    if (
                        !massAssignIsLoading &&
                        !massPreAssignIsLoading &&
                        !refuseIsLoading &&
                        !massStateUpdateIsLoading
                    ) {
                        await refuse({
                            eventId,
                            volunteerRegistrationId: props.volunteerRegistrationId
                        });

                        onSuccess();
                    }
                }}
            >
                {translate('refuser_92819')}
            </Box>

            <Box css={{ flex: '1' }} />

            <Button
                color="primary"
                isLoading={massAssignIsLoading || massPreAssignIsLoading}
                onClick={async () => {
                    if (isPreAssign) {
                        if (
                            isNonEmptyArray(positionsIds) &&
                            !massAssignIsLoading &&
                            !massPreAssignIsLoading &&
                            !refuseIsLoading &&
                            !massStateUpdateIsLoading
                        ) {
                            await massPreAssign({
                                eventId,
                                massPreAssign: {
                                    level: MassPreAssignLevel.Positions,
                                    positionsIds,
                                    positionsCategoriesIds: [],
                                    selecteds: { ids: [props.userInfoId] },
                                    strategy: MassAssignStrategy.Replace
                                }
                            });

                            onSuccess();
                        }
                        if (
                            isNonEmptyArray(positionsCategoriesIds) &&
                            !massAssignIsLoading &&
                            !massPreAssignIsLoading &&
                            !refuseIsLoading &&
                            !massStateUpdateIsLoading
                        ) {
                            await massPreAssign({
                                eventId,
                                massPreAssign: {
                                    level: MassPreAssignLevel.Categories,
                                    positionsIds: [],
                                    positionsCategoriesIds,
                                    selecteds: { ids: [props.userInfoId] },
                                    strategy: MassAssignStrategy.Replace
                                }
                            });

                            onSuccess();
                        }
                    } else if (
                        !massAssignIsLoading &&
                        !massPreAssignIsLoading &&
                        !refuseIsLoading &&
                        !massStateUpdateIsLoading
                    ) {
                        if (isNonEmptyArray(positionsSlotsIds)) {
                            await massAssign({
                                eventId,
                                massAssign: {
                                    positionsSlotsIds,
                                    selecteds: { ids: [props.userInfoId] },
                                    strategy: MassAssignStrategy.Replace
                                }
                            });
                        } else {
                            await massStateUpdate({
                                eventId,
                                massStateUpdate: {
                                    selecteds: { ids: [props.userInfoId] },
                                    state: VolunteerRegistrationState.WaitingAssignment
                                }
                            });
                        }

                        onSuccess();
                    }
                }}
            >
                {props.isEdit
                    ? translate('mettre_jour_l_30423')
                    : isPreAssign
                      ? translate('pr_affecter_17434')
                      : translate('affecter_94255')}
            </Button>

            <Button color="white" onClick={onSuccess}>
                {props.isEdit ? translate('annuler_48254') : translate('affecter_plus_t_97778')}
            </Button>
        </Flex>
    );
};
