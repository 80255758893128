import { Flex } from 'common/src/designSystem/components/flex';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { getGoogleCenter } from 'common/src/vo/event';
import { Predicates } from 'common/src/vo/segment';
import * as React from 'react';
import { usePositionsQuery } from '../../../generated/graphqlHooks';
import { PositionsMap } from './positionsMap';

interface IPositionsMapProps {
    name: string;
    predicates: Predicates;
}

export const PositionsMapContainer = (props: IPositionsMapProps) => {
    const { eventId } = useParams();
    const { data, loader, reload } = usePositionsQuery({
        eventId,
        includeCheckedInRate: false,
        includeConditions: false,
        includeCreatedBy: false,
        includeLeaders: false,
        includeTags: false,
        includeTraces: true,
        name: isNonEmptyString(props.name) ? props.name : null,
        predicates: props.predicates,
        hasCoordinates: true
    });

    if (loader) {
        return <Flex css={{ flex: '1' }}>{loader}</Flex>;
    } else {
        return (
            <PositionsMap
                center={getGoogleCenter(data.data)}
                layer={data.data.layer}
                positions={data.data.rows.nodes}
                reload={reload}
                traces={data.data.traces}
            />
        );
    }
};
