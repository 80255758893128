import { Box } from 'common/src/designSystem/components/box';
import { Flex, IFlexProps } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { CommonEnvVars } from 'common/src/envVars';
import { isNonEmptyString } from 'common/src/util/string';
import { getS3Url } from 'common/src/vo/document';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { FileViewer } from '../../../components/fileViewer/fileViewer';
import { FullScreenPopup } from '../../../components/fullScreenPopup/fullScreenPopup';
import { useMobileQuery } from '../../../hooks/useMobileQuery';
import { Acl, download } from '../../../util/file';
import { FileS3InputValue } from './fileS3InputValue';

interface IFileProps extends Omit<IFlexProps, 'ref'> {
    acl: Acl;
    document: FileS3InputValue;

    onDelete?(): void;
}

export const File = ({ acl, document: _document, onDelete, ...props }: IFileProps) => {
    const [isFileViewOpen, setIsFileViewOpen] = React.useState(false);
    const onMobile = useMobileQuery();
    const canViewDownload = acl === 'public-read' || isNonEmptyString(_document.url);
    const url = isNonEmptyString(_document.url)
        ? _document.url
        : getS3Url(_document, CommonEnvVars.HEAVENT_AWS_UPLOADS_BUCKET);

    return (
        <Flex
            align="center"
            css={{
                background: 'white',
                border: '1px solid $gray200',
                borderRadius: '$1',
                boxShadow: '$xs',
                fontSize: '$textSm',
                overflow: 'hidden',
                padding: '$3',
                minHeight: '72px',
                '@tablet': {
                    border: '1px solid $gray300',
                    borderRadius: '$2',
                    fontSize: '$textMd',
                    padding: '$3'
                }
            }}
            gap="4"
            width={1}
            {...(onMobile && {
                onClick: () => {
                    download(url, _document.name);
                }
            })}
            {...props}
        >
            <Flex css={{ flex: '1', minHeight: 0, overflow: 'hidden' }}>
                <Box
                    color={{ '@initial': 'gray800', '@tablet': 'gray700' }}
                    css={{ ellipsis: true }}
                    width={1}
                >
                    {_document.name}
                </Box>
            </Flex>

            {canViewDownload && (
                <>
                    {onMobile === false && (
                        <Box
                            css={{ cursor: 'pointer' }}
                            onClick={() => {
                                setIsFileViewOpen(true);
                            }}
                        >
                            <I icon="eye" />
                        </Box>
                    )}

                    <Box
                        css={{ cursor: 'pointer' }}
                        {...(onMobile === false && {
                            onClick: () => {
                                download(url, _document.name);
                            }
                        })}
                    >
                        <I icon="download" />
                    </Box>
                </>
            )}

            {onDelete && (
                <Box css={{ cursor: 'pointer' }} onClick={onDelete}>
                    <I icon="trash-can" />
                </Box>
            )}

            {isFileViewOpen &&
                createPortal(
                    <Flex
                        css={{
                            background: 'white',
                            left: '0',
                            position: 'absolute',
                            top: '0',
                            zIndex: '250'
                        }}
                        height={1}
                        width={1}
                    >
                        <FullScreenPopup
                            title={_document.name}
                            onClose={() => setIsFileViewOpen(false)}
                        >
                            <FileViewer fileUrl={url} />
                        </FullScreenPopup>
                    </Flex>,
                    document.body
                )}
        </Flex>
    );
};
