import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { DocumentUsersPlanningFragment } from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { getColorPalette } from 'common/src/util/color';
import { useService } from 'common/src/util/dependencies/dependencies';
import { fullName } from 'common/src/vo/positionSlot';
import { Interval } from 'luxon';
import * as React from 'react';
import { PositionsPlanningSlotModal } from '../../../positions/list/planning/positionsPlanningSlotModal';

type AssignmentSlot = {
    type: 'assignment';
    range: Interval;
    positionCategory: DocumentUsersPlanningFragment['positionsSlotsUsersInfos'][number]['positionCategory'];
    position: DocumentUsersPlanningFragment['positionsSlotsUsersInfos'][number]['position'];
    positionSlot: DocumentUsersPlanningFragment['positionsSlotsUsersInfos'][number]['positionSlot'];
};

type AvailabilitySlot = {
    type: 'availability';
    range: Interval;
};

type VolunteersPlanningslot = AssignmentSlot | AvailabilitySlot;

const VolunteersPlanningAvailabilitySlot = () => {
    const { translate } = useHeavent();

    return (
        <Flex
            align="center"
            css={{
                background: 'white',
                border: '1px solid $gray200',
                borderRadius: '$1',
                px: '$2'
            }}
            height={1}
            width={1}
        >
            <Box css={{ ellipsis: true }} font="gray800 textXs medium" textAlign="center" width={1}>
                {translate('disponible_69523')}
            </Box>
        </Flex>
    );
};

interface IVolunteersPlanningAssignmentSlotProps {
    slot: AssignmentSlot;
}

const VolunteersPlanningAssignmentSlot = (props: IVolunteersPlanningAssignmentSlotProps) => {
    const intervalService = useService(IntervalService);
    const palette = React.useMemo(() => getColorPalette(props.slot.position.color), [props.slot]);
    const name = React.useMemo(
        () =>
            fullName(intervalService, props.slot.positionSlot, props.slot.position.name, {
                position: { includeName: true },
                slot: { includeRange: true }
            }),
        [props.slot]
    );

    return (
        <Dropdown>
            <Trigger>
                <Flex
                    align="center"
                    css={{
                        background: palette['50'],
                        borderRadius: '$1',
                        color: palette['700'],
                        cursor: 'pointer',
                        px: '$2'
                    }}
                    height={1}
                    width={1}
                >
                    <Box
                        css={{ ellipsis: true }}
                        fontSize="textXs"
                        fontWeight="medium"
                        textAlign="center"
                        title={name}
                        width={1}
                    >
                        {name}
                    </Box>
                </Flex>
            </Trigger>

            <Menu css={{ padding: '0' }} placement="bottom-start" renderOnPortal={true} width={460}>
                <PositionsPlanningSlotModal
                    category={props.slot.positionCategory}
                    position={props.slot.position}
                    slot={props.slot.positionSlot}
                />
            </Menu>
        </Dropdown>
    );
};

interface IVolunteersPlanningSlotProps {
    slot: VolunteersPlanningslot;
}

export const VolunteersPlanningSlot = (props: IVolunteersPlanningSlotProps) =>
    props.slot.type === 'assignment' ? (
        <VolunteersPlanningAssignmentSlot slot={props.slot} />
    ) : (
        <VolunteersPlanningAvailabilitySlot />
    );
