import { Button } from 'common-front/src/designSystem/components/button';
import { Modal } from 'common-front/src/designSystem/components/modal/modal';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';

interface ICreateUpdateDelelgationSaveValidateModalProps {
    deletedAccreditationsSlots: string[];
    isOpen: boolean;
    submitting: boolean;

    handleSubmit(): void;
    onClose(): void;
}

export const CreateUpdateDelelgationSaveValidateModal = (
    props: ICreateUpdateDelelgationSaveValidateModalProps
) => {
    const { translate } = useHeavent();

    return (
        <Modal.Root isOpen={props.isOpen} onClose={props.onClose}>
            <Modal.Portal>
                <Modal.Header icon="triangle-exclamation">
                    {translate('accr_ditations_34551')}
                </Modal.Header>

                <Modal.Content>
                    <Flex align="center" direction="column" gap="1" width={1}>
                        <Box font="gray900 textLg medium">{translate('voulez_vous_met_24993')}</Box>

                        <Box color="gray500" textAlign="center">
                            {translate(
                                'les_accr_ditati_76418',
                                props.deletedAccreditationsSlots.join(', ')
                            )}
                        </Box>
                    </Flex>
                </Modal.Content>

                <Modal.Buttons>
                    <Button isLoading={props.submitting} onClick={props.handleSubmit}>
                        {translate('mettre_jour_l_69948')}
                    </Button>
                </Modal.Buttons>
            </Modal.Portal>
        </Modal.Root>
    );
};
