export enum BackofficeInvoicesColumns {
    Amount = 'amount',
    AmountTTC = 'amountTTC',
    CustomerName = 'customerName',
    Date = 'date',
    Organization = 'organization',
    PaidAt = 'paidAt',
    State = 'state',
    TransactionId = 'transactionId'
}
