import {
    CheckInState,
    CheckInStrategy,
    MassAssignStrategy,
    PositionsSlotsUsersInfoId,
    UserPositionSlotUserInfoFragment
} from 'common/src/generated/types';
import * as React from 'react';
import { DropdownEllipsis } from '../designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from '../designSystem/components/dropdown/item';
import {
    usePositionsSlotsUsersInfosCheckInMutation,
    useVolunteersRegistrationsMassAssignMutation
} from '../generated/graphqlHooks';
import { useHeavent } from '../hooks/useHeavent';
import { UpdateCheckInModal } from './checkIn/updateCheckInModal';

interface IUserPositionDropdownItemsProps {
    canCheckIn: boolean;
    canDelete: boolean;
    psui: UserPositionSlotUserInfoFragment;
    triggerElem?: React.ReactNode;

    onDelete(id: PositionsSlotsUsersInfoId): void;
    reloadCheckIn(id: PositionsSlotsUsersInfoId): void;
}

export const UserPositionDropdown = (props: IUserPositionDropdownItemsProps) => {
    const { translate } = useHeavent();
    const { mutate: checkIn } = usePositionsSlotsUsersInfosCheckInMutation();
    const { mutate: massAssign } = useVolunteersRegistrationsMassAssignMutation();
    const [isCheckInModalOpen, setIsCheckInModalOpen] = React.useState(false);

    return (
        <>
            <DropdownEllipsis triggerElem={props.triggerElem}>
                {props.canCheckIn && (
                    <ItemIcon
                        icon="circle-check"
                        onClick={() => {
                            setIsCheckInModalOpen(true);
                        }}
                    >
                        {translate('mettre_jour_l_00128')}
                    </ItemIcon>
                )}

                {props.canCheckIn && props.psui.state !== CheckInState.Waiting && (
                    <ItemIcon
                        icon="arrows-rotate"
                        onClick={async () => {
                            await checkIn({
                                eventId: props.psui.eventId,
                                checkIn: {
                                    strategy: CheckInStrategy.Reset,
                                    positionsSlotsUsersInfosIds: [props.psui.id]
                                }
                            });

                            props.reloadCheckIn(props.psui.id);
                        }}
                    >
                        {translate('remettre_z_ro_11572')}
                    </ItemIcon>
                )}

                {props.canDelete && (
                    <ItemIcon
                        color="red"
                        icon="user-xmark"
                        onClick={async () => {
                            await massAssign({
                                eventId: props.psui.eventId,
                                massAssign: {
                                    positionsSlotsIds: [props.psui.positionSlot.id],
                                    selecteds: {
                                        ids: [props.psui.userInfoId]
                                    },
                                    strategy: MassAssignStrategy.Delete
                                }
                            });

                            props.onDelete(props.psui.id);
                        }}
                    >
                        {translate('d_saffecter_du_47122')}
                    </ItemIcon>
                )}
            </DropdownEllipsis>

            <UpdateCheckInModal
                currentState={props.psui.state}
                isOpen={isCheckInModalOpen}
                positionSlotUserInfo={props.psui}
                onClose={() => {
                    props.reloadCheckIn(props.psui.id);

                    setIsCheckInModalOpen(false);
                }}
            />
        </>
    );
};
