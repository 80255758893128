// /!\ THIS FILE IS AUTOMATICALLY GENERATED, DO NOT EDIT /!\
/* tslint:disable */
import { executeQuery } from '../components/graphql/graphql';
import {
    IUseMutationOptions,
    IUseMutationReturns,
    IUseQueryReturns
} from 'common/src/util/graphql/types';
import { useMutation, useQuery } from 'common/src/util/dependencies/dependencies';

import AccreditAssignWaitingMembers from '../accreditAssign/accreditAssignWaitingMembers.graphql';
import {
    AccreditAssignWaitingMembersQuery,
    AccreditAssignWaitingMembersQueryVariables
} from 'common/src/generated/types';
import VolunteersRegistrationsMassAccredit from '../components/accreditations/volunteersRegistrationsMassAccredit.graphql';
import {
    VolunteersRegistrationsMassAccreditMutation,
    VolunteersRegistrationsMassAccreditMutationVariables
} from 'common/src/generated/types';
import AccreditationsMassEdit from '../components/massEdit/accreditationsMassEdit.graphql';
import {
    AccreditationsMassEditMutation,
    AccreditationsMassEditMutationVariables
} from 'common/src/generated/types';
import AccreditationsMassEditInfos from '../components/massEdit/accreditationsMassEditInfos.graphql';
import {
    AccreditationsMassEditInfosQuery,
    AccreditationsMassEditInfosQueryVariables
} from 'common/src/generated/types';
import CustomFieldsMassEdit from '../components/massEdit/customFieldsMassEdit.graphql';
import {
    CustomFieldsMassEditMutation,
    CustomFieldsMassEditMutationVariables
} from 'common/src/generated/types';
import CustomFieldsMassEditInfos from '../components/massEdit/customFieldsMassEditInfos.graphql';
import {
    CustomFieldsMassEditInfosQuery,
    CustomFieldsMassEditInfosQueryVariables
} from 'common/src/generated/types';
import DelegationsMassEdit from '../components/massEdit/delegationsMassEdit.graphql';
import {
    DelegationsMassEditMutation,
    DelegationsMassEditMutationVariables
} from 'common/src/generated/types';
import DelegationsMassEditInfos from '../components/massEdit/delegationsMassEditInfos.graphql';
import {
    DelegationsMassEditInfosQuery,
    DelegationsMassEditInfosQueryVariables
} from 'common/src/generated/types';
import PositionsMassEdit from '../components/massEdit/positionsMassEdit.graphql';
import {
    PositionsMassEditMutation,
    PositionsMassEditMutationVariables
} from 'common/src/generated/types';
import PositionsMassEditInfos from '../components/massEdit/positionsMassEditInfos.graphql';
import {
    PositionsMassEditInfosQuery,
    PositionsMassEditInfosQueryVariables
} from 'common/src/generated/types';
import UsersInfosMassEdit from '../components/massEdit/usersInfosMassEdit.graphql';
import {
    UsersInfosMassEditMutation,
    UsersInfosMassEditMutationVariables
} from 'common/src/generated/types';
import UsersInfosMassEditInfos from '../components/massEdit/usersInfosMassEditInfos.graphql';
import {
    UsersInfosMassEditInfosQuery,
    UsersInfosMassEditInfosQueryVariables
} from 'common/src/generated/types';
import EventDelegationMembersInfos from '../delegations/members/eventDelegationMembersInfos.graphql';
import {
    EventDelegationMembersInfosQuery,
    EventDelegationMembersInfosQueryVariables
} from 'common/src/generated/types';
import OrganizationDelegationMembersInfos from '../delegations/members/organizationDelegationMembersInfos.graphql';
import {
    OrganizationDelegationMembersInfosQuery,
    OrganizationDelegationMembersInfosQueryVariables
} from 'common/src/generated/types';
import DelegationAccreditationSlotMember from '../delegations/rightPanel/delegationAccreditationSlotMember.graphql';
import {
    DelegationAccreditationSlotMemberQuery,
    DelegationAccreditationSlotMemberQueryVariables
} from 'common/src/generated/types';
import DelegationAccreditationSlotMembers from '../delegations/rightPanel/delegationAccreditationSlotMembers.graphql';
import {
    DelegationAccreditationSlotMembersQuery,
    DelegationAccreditationSlotMembersQueryVariables
} from 'common/src/generated/types';
import DelegationAccreditationSlotStats from '../delegations/show/accreditations/delegationAccreditationSlotStats.graphql';
import {
    DelegationAccreditationSlotStatsQuery,
    DelegationAccreditationSlotStatsQueryVariables
} from 'common/src/generated/types';
import DelegationAccreditations from '../delegations/show/accreditations/delegationAccreditations.graphql';
import {
    DelegationAccreditationsQuery,
    DelegationAccreditationsQueryVariables
} from 'common/src/generated/types';
import EventDocumentsExport from '../graphql/mutations/eventDocumentsExport.graphql';
import {
    EventDocumentsExportMutation,
    EventDocumentsExportMutationVariables
} from 'common/src/generated/types';
import UserCreate from '../graphql/mutations/userCreate.graphql';
import { UserCreateMutation, UserCreateMutationVariables } from 'common/src/generated/types';
import UserInfoDelegationDelete from '../graphql/mutations/userInfoDelegationDelete.graphql';
import {
    UserInfoDelegationDeleteMutation,
    UserInfoDelegationDeleteMutationVariables
} from 'common/src/generated/types';
import VolunteerRegistrationUpdate from '../graphql/mutations/volunteerRegistrationUpdate.graphql';
import {
    VolunteerRegistrationUpdateMutation,
    VolunteerRegistrationUpdateMutationVariables
} from 'common/src/generated/types';
import UserPermission from '../organization/userPermission.graphql';
import { UserPermissionQuery, UserPermissionQueryVariables } from 'common/src/generated/types';
import PositionsSlotsUsersInfosCheckIn from '../positions/checkIn/positionsSlotsUsersInfosCheckIn.graphql';
import {
    PositionsSlotsUsersInfosCheckInMutation,
    PositionsSlotsUsersInfosCheckInMutationVariables
} from 'common/src/generated/types';
import PositionSlotUserInfo from '../positions/positionSlotUserInfo.graphql';
import {
    PositionSlotUserInfoQuery,
    PositionSlotUserInfoQueryVariables
} from 'common/src/generated/types';
import UserPositions from '../positions/userPositions.graphql';
import { UserPositionsQuery, UserPositionsQueryVariables } from 'common/src/generated/types';
import ConditionsCustomFields from '../segments/filters/data/conditionsCustomFields.graphql';
import {
    ConditionsCustomFieldsQuery,
    ConditionsCustomFieldsQueryVariables
} from 'common/src/generated/types';
import Tags from '../segments/filters/data/tags.graphql';
import { TagsQuery, TagsQueryVariables } from 'common/src/generated/types';
import UsersSegmentsCampaigns from '../segments/filters/data/usersSegmentsCampaigns.graphql';
import {
    UsersSegmentsCampaignsQuery,
    UsersSegmentsCampaignsQueryVariables
} from 'common/src/generated/types';
import UsersSegmentsDelegations from '../segments/filters/data/usersSegmentsDelegations.graphql';
import {
    UsersSegmentsDelegationsQuery,
    UsersSegmentsDelegationsQueryVariables
} from 'common/src/generated/types';
import UsersSegmentsEvents from '../segments/filters/data/usersSegmentsEvents.graphql';
import {
    UsersSegmentsEventsQuery,
    UsersSegmentsEventsQueryVariables
} from 'common/src/generated/types';
import UsersSegmentsForms from '../segments/filters/data/usersSegmentsForms.graphql';
import {
    UsersSegmentsFormsQuery,
    UsersSegmentsFormsQueryVariables
} from 'common/src/generated/types';
import VolunteersSegmentsAccreditations from '../segments/filters/data/volunteersSegmentsAccreditations.graphql';
import {
    VolunteersSegmentsAccreditationsQuery,
    VolunteersSegmentsAccreditationsQueryVariables
} from 'common/src/generated/types';
import VolunteersSegmentsAccreditationsCategories from '../segments/filters/data/volunteersSegmentsAccreditationsCategories.graphql';
import {
    VolunteersSegmentsAccreditationsCategoriesQuery,
    VolunteersSegmentsAccreditationsCategoriesQueryVariables
} from 'common/src/generated/types';
import VolunteersSegmentsAccreditationsSegments from '../segments/filters/data/volunteersSegmentsAccreditationsSegments.graphql';
import {
    VolunteersSegmentsAccreditationsSegmentsQuery,
    VolunteersSegmentsAccreditationsSegmentsQueryVariables
} from 'common/src/generated/types';
import VolunteersSegmentsAccreditationsSlots from '../segments/filters/data/volunteersSegmentsAccreditationsSlots.graphql';
import {
    VolunteersSegmentsAccreditationsSlotsQuery,
    VolunteersSegmentsAccreditationsSlotsQueryVariables
} from 'common/src/generated/types';
import VolunteersSegmentsCampaigns from '../segments/filters/data/volunteersSegmentsCampaigns.graphql';
import {
    VolunteersSegmentsCampaignsQuery,
    VolunteersSegmentsCampaignsQueryVariables
} from 'common/src/generated/types';
import VolunteeersSegmentsCustomSlotsPeriods from '../segments/filters/data/volunteersSegmentsCustomSlotsPeriods.graphql';
import {
    VolunteeersSegmentsCustomSlotsPeriodsQuery,
    VolunteeersSegmentsCustomSlotsPeriodsQueryVariables
} from 'common/src/generated/types';
import VolunteersSegmentsDelegations from '../segments/filters/data/volunteersSegmentsDelegations.graphql';
import {
    VolunteersSegmentsDelegationsQuery,
    VolunteersSegmentsDelegationsQueryVariables
} from 'common/src/generated/types';
import VolunteersSegmentsDelegationsSegments from '../segments/filters/data/volunteersSegmentsDelegationsSegments.graphql';
import {
    VolunteersSegmentsDelegationsSegmentsQuery,
    VolunteersSegmentsDelegationsSegmentsQueryVariables
} from 'common/src/generated/types';
import VolunteersSegmentsForms from '../segments/filters/data/volunteersSegmentsForms.graphql';
import {
    VolunteersSegmentsFormsQuery,
    VolunteersSegmentsFormsQueryVariables
} from 'common/src/generated/types';
import VolunteersSegmentsPositions from '../segments/filters/data/volunteersSegmentsPositions.graphql';
import {
    VolunteersSegmentsPositionsQuery,
    VolunteersSegmentsPositionsQueryVariables
} from 'common/src/generated/types';
import VolunteersSegmentsPositionsCategories from '../segments/filters/data/volunteersSegmentsPositionsCategories.graphql';
import {
    VolunteersSegmentsPositionsCategoriesQuery,
    VolunteersSegmentsPositionsCategoriesQueryVariables
} from 'common/src/generated/types';
import VolunteersSegmentsPositionsSegments from '../segments/filters/data/volunteersSegmentsPositionsSegments.graphql';
import {
    VolunteersSegmentsPositionsSegmentsQuery,
    VolunteersSegmentsPositionsSegmentsQueryVariables
} from 'common/src/generated/types';
import VolunteersSegmentsPositionsSlots from '../segments/filters/data/volunteersSegmentsPositionsSlots.graphql';
import {
    VolunteersSegmentsPositionsSlotsQuery,
    VolunteersSegmentsPositionsSlotsQueryVariables
} from 'common/src/generated/types';
import VolunteersSegmentsUsersSegments from '../segments/filters/data/volunteersSegmentsUsersSegments.graphql';
import {
    VolunteersSegmentsUsersSegmentsQuery,
    VolunteersSegmentsUsersSegmentsQueryVariables
} from 'common/src/generated/types';
import SegmentCreate from '../segments/graphql/segmentCreate.graphql';
import { SegmentCreateMutation, SegmentCreateMutationVariables } from 'common/src/generated/types';
import SegmentDelete from '../segments/graphql/segmentDelete.graphql';
import { SegmentDeleteMutation, SegmentDeleteMutationVariables } from 'common/src/generated/types';
import SegmentFolderCreate from '../segments/graphql/segmentFolderCreate.graphql';
import {
    SegmentFolderCreateMutation,
    SegmentFolderCreateMutationVariables
} from 'common/src/generated/types';
import SegmentFolderDelete from '../segments/graphql/segmentFolderDelete.graphql';
import {
    SegmentFolderDeleteMutation,
    SegmentFolderDeleteMutationVariables
} from 'common/src/generated/types';
import SegmentFolderMoveDown from '../segments/graphql/segmentFolderMoveDown.graphql';
import {
    SegmentFolderMoveDownMutation,
    SegmentFolderMoveDownMutationVariables
} from 'common/src/generated/types';
import SegmentFolderMoveUp from '../segments/graphql/segmentFolderMoveUp.graphql';
import {
    SegmentFolderMoveUpMutation,
    SegmentFolderMoveUpMutationVariables
} from 'common/src/generated/types';
import SegmentFolderUpdateName from '../segments/graphql/segmentFolderUpdateName.graphql';
import {
    SegmentFolderUpdateNameMutation,
    SegmentFolderUpdateNameMutationVariables
} from 'common/src/generated/types';
import SegmentMoveDown from '../segments/graphql/segmentMoveDown.graphql';
import {
    SegmentMoveDownMutation,
    SegmentMoveDownMutationVariables
} from 'common/src/generated/types';
import SegmentMoveFolder from '../segments/graphql/segmentMoveFolder.graphql';
import {
    SegmentMoveFolderMutation,
    SegmentMoveFolderMutationVariables
} from 'common/src/generated/types';
import SegmentMoveUp from '../segments/graphql/segmentMoveUp.graphql';
import { SegmentMoveUpMutation, SegmentMoveUpMutationVariables } from 'common/src/generated/types';
import SegmentUpdate from '../segments/graphql/segmentUpdate.graphql';
import { SegmentUpdateMutation, SegmentUpdateMutationVariables } from 'common/src/generated/types';
import SegmentUpdateName from '../segments/graphql/segmentUpdateName.graphql';
import {
    SegmentUpdateNameMutation,
    SegmentUpdateNameMutationVariables
} from 'common/src/generated/types';
import SegmentsFoldersReorder from '../segments/list/reorderModal/segmentsFoldersReorder.graphql';
import {
    SegmentsFoldersReorderMutation,
    SegmentsFoldersReorderMutationVariables
} from 'common/src/generated/types';
import VolunteersRegistrationsMassDelete from '../users/delete/volunteersRegistrationsMassDelete.graphql';
import {
    VolunteersRegistrationsMassDeleteMutation,
    VolunteersRegistrationsMassDeleteMutationVariables
} from 'common/src/generated/types';
import User from '../users/list/user.graphql';
import { UserQuery, UserQueryVariables } from 'common/src/generated/types';
import UserInfoUpdateField from '../users/list/userInfoUpdateField.graphql';
import {
    UserInfoUpdateFieldMutation,
    UserInfoUpdateFieldMutationVariables
} from 'common/src/generated/types';
import Users from '../users/list/users.graphql';
import { UsersQuery, UsersQueryVariables } from 'common/src/generated/types';
import UsersSegments from '../users/list/usersSegments.graphql';
import { UsersSegmentsQuery, UsersSegmentsQueryVariables } from 'common/src/generated/types';
import Profile from '../users/profile/profile.graphql';
import { ProfileQuery, ProfileQueryVariables } from 'common/src/generated/types';
import UserProfileUpdate from '../users/profile/userProfileUpdate.graphql';
import {
    UserProfileUpdateMutation,
    UserProfileUpdateMutationVariables
} from 'common/src/generated/types';
import EmailUpdate from '../users/update/emailUpdate.graphql';
import { EmailUpdateMutation, EmailUpdateMutationVariables } from 'common/src/generated/types';
import UserInfoUpdate from '../users/update/userInfoUpdate.graphql';
import {
    UserInfoUpdateMutation,
    UserInfoUpdateMutationVariables
} from 'common/src/generated/types';
import UserInfoAccreditationsUpdate from '../users/updateAccreditations/userInfoAccreditationsUpdate.graphql';
import {
    UserInfoAccreditationsUpdateMutation,
    UserInfoAccreditationsUpdateMutationVariables
} from 'common/src/generated/types';
import UserUpdateAccreditationsInfos from '../users/updateAccreditations/userUpdateAccreditationsInfos.graphql';
import {
    UserUpdateAccreditationsInfosQuery,
    UserUpdateAccreditationsInfosQueryVariables
} from 'common/src/generated/types';
import UserInfoPositionsUpdate from '../users/updateMissions/userInfoPositionsUpdate.graphql';
import {
    UserInfoPositionsUpdateMutation,
    UserInfoPositionsUpdateMutationVariables
} from 'common/src/generated/types';
import UserUpdateMissionsInfos from '../users/updateMissions/userUpdateMissionsInfos.graphql';
import {
    UserUpdateMissionsInfosQuery,
    UserUpdateMissionsInfosQueryVariables
} from 'common/src/generated/types';
import UserInfoSlotsUpdate from '../users/updateSlots/userInfoSlotsUpdate.graphql';
import {
    UserInfoSlotsUpdateMutation,
    UserInfoSlotsUpdateMutationVariables
} from 'common/src/generated/types';
import UserUpdateSlotsInfos from '../users/updateSlots/userUpdateSlotsInfos.graphql';
import {
    UserUpdateSlotsInfosQuery,
    UserUpdateSlotsInfosQueryVariables
} from 'common/src/generated/types';
import AwsS3PostCredentials from '../util/awsS3PostCredentials.graphql';
import {
    AwsS3PostCredentialsQuery,
    AwsS3PostCredentialsQueryVariables
} from 'common/src/generated/types';
import AccreditationDuplicate from '../vo/accreditationDuplicate.graphql';
import {
    AccreditationDuplicateMutation,
    AccreditationDuplicateMutationVariables
} from 'common/src/generated/types';
import CurrentUser from '../vo/currentUser.graphql';
import { CurrentUserQuery, CurrentUserQueryVariables } from 'common/src/generated/types';
import LoginCodeEmailCreate from '../vo/loginCodeEmailCreate.graphql';
import {
    LoginCodeEmailCreateMutation,
    LoginCodeEmailCreateMutationVariables
} from 'common/src/generated/types';
import PositionDuplicate from '../vo/positionDuplicate.graphql';
import {
    PositionDuplicateMutation,
    PositionDuplicateMutationVariables
} from 'common/src/generated/types';
import EmailResend from '../volunteers/graphql/emailResend.graphql';
import { EmailResendMutation, EmailResendMutationVariables } from 'common/src/generated/types';
import UserOverlaySlots from '../volunteers/graphql/userOverlaySlots.graphql';
import { UserOverlaySlotsQuery, UserOverlaySlotsQueryVariables } from 'common/src/generated/types';
import VolunteersRegistrationsAccreditationsValidate from '../volunteers/graphql/volunteersRegistrationsAccreditationsValidate.graphql';
import {
    VolunteersRegistrationsAccreditationsValidateMutation,
    VolunteersRegistrationsAccreditationsValidateMutationVariables
} from 'common/src/generated/types';
import VolunteersRegistrationsMassAccreditationStateUpdate from '../volunteers/graphql/volunteersRegistrationsMassAccreditationStateUpdate.graphql';
import {
    VolunteersRegistrationsMassAccreditationStateUpdateMutation,
    VolunteersRegistrationsMassAccreditationStateUpdateMutationVariables
} from 'common/src/generated/types';
import VolunteersRegistrationsMassAssign from '../volunteers/graphql/volunteersRegistrationsMassAssign.graphql';
import {
    VolunteersRegistrationsMassAssignMutation,
    VolunteersRegistrationsMassAssignMutationVariables
} from 'common/src/generated/types';
import VolunteersRegistrationsMassStateUpdate from '../volunteers/graphql/volunteersRegistrationsMassStateUpdate.graphql';
import {
    VolunteersRegistrationsMassStateUpdateMutation,
    VolunteersRegistrationsMassStateUpdateMutationVariables
} from 'common/src/generated/types';
import VolunteersSegments from '../volunteers/graphql/volunteersSegments.graphql';
import {
    VolunteersSegmentsQuery,
    VolunteersSegmentsQueryVariables
} from 'common/src/generated/types';
import UpdateCellAccreditationsSlots from '../volunteers/list/cells/updateCellAccreditationsSlots.graphql';
import {
    UpdateCellAccreditationsSlotsQuery,
    UpdateCellAccreditationsSlotsQueryVariables
} from 'common/src/generated/types';
import UpdateCellDelegations from '../volunteers/list/cells/updateCellDelegations.graphql';
import {
    UpdateCellDelegationsQuery,
    UpdateCellDelegationsQueryVariables
} from 'common/src/generated/types';
import UpdateCellPositionsSlots from '../volunteers/list/cells/updateCellPositionsSlots.graphql';
import {
    UpdateCellPositionsSlotsQuery,
    UpdateCellPositionsSlotsQueryVariables
} from 'common/src/generated/types';
import UserInfoDelegationUpdate from '../volunteers/list/cells/userInfoDelegationUpdate.graphql';
import {
    UserInfoDelegationUpdateMutation,
    UserInfoDelegationUpdateMutationVariables
} from 'common/src/generated/types';
import VolunteerRegistrationUpdateField from '../volunteers/list/cells/volunteerRegistrationUpdateField.graphql';
import {
    VolunteerRegistrationUpdateFieldMutation,
    VolunteerRegistrationUpdateFieldMutationVariables
} from 'common/src/generated/types';
import Volunteer from '../volunteers/list/volunteer.graphql';
import { VolunteerQuery, VolunteerQueryVariables } from 'common/src/generated/types';
import Volunteers from '../volunteers/list/volunteers.graphql';
import { VolunteersQuery, VolunteersQueryVariables } from 'common/src/generated/types';
import MassAccreditSlots from '../volunteers/massAccredit/massAccreditSlots.graphql';
import {
    MassAccreditSlotsQuery,
    MassAccreditSlotsQueryVariables
} from 'common/src/generated/types';
import VolunteersRegistrationsMassWishedAccreditationsUpdate from '../volunteers/massWishedAccreditationsUpdate/volunteersRegistrationsMassWishedAccreditationsUpdate.graphql';
import {
    VolunteersRegistrationsMassWishedAccreditationsUpdateMutation,
    VolunteersRegistrationsMassWishedAccreditationsUpdateMutationVariables
} from 'common/src/generated/types';

import { Fragments } from 'common/src/generated/fragments';
import { IFragment } from 'common/src/generated/fragments';

export interface IQueryFragments {
    query: string;
    fragments: IFragment[];
}

export const Queries = {
    AccreditAssignWaitingMembers,
    AccreditationsMassEditInfos,
    CustomFieldsMassEditInfos,
    DelegationsMassEditInfos,
    PositionsMassEditInfos,
    UsersInfosMassEditInfos,
    EventDelegationMembersInfos,
    OrganizationDelegationMembersInfos,
    DelegationAccreditationSlotMember,
    DelegationAccreditationSlotMembers,
    DelegationAccreditationSlotStats,
    DelegationAccreditations,
    UserPermission,
    PositionSlotUserInfo,
    UserPositions,
    ConditionsCustomFields,
    Tags,
    UsersSegmentsCampaigns,
    UsersSegmentsDelegations,
    UsersSegmentsEvents,
    UsersSegmentsForms,
    VolunteersSegmentsAccreditations,
    VolunteersSegmentsAccreditationsCategories,
    VolunteersSegmentsAccreditationsSegments,
    VolunteersSegmentsAccreditationsSlots,
    VolunteersSegmentsCampaigns,
    VolunteeersSegmentsCustomSlotsPeriods,
    VolunteersSegmentsDelegations,
    VolunteersSegmentsDelegationsSegments,
    VolunteersSegmentsForms,
    VolunteersSegmentsPositions,
    VolunteersSegmentsPositionsCategories,
    VolunteersSegmentsPositionsSegments,
    VolunteersSegmentsPositionsSlots,
    VolunteersSegmentsUsersSegments,
    User,
    Users,
    UsersSegments,
    Profile,
    UserUpdateAccreditationsInfos,
    UserUpdateMissionsInfos,
    UserUpdateSlotsInfos,
    AwsS3PostCredentials,
    CurrentUser,
    UserOverlaySlots,
    VolunteersSegments,
    UpdateCellAccreditationsSlots,
    UpdateCellDelegations,
    UpdateCellPositionsSlots,
    Volunteer,
    Volunteers,
    MassAccreditSlots
};

export const Mutations = {
    VolunteersRegistrationsMassAccredit,
    AccreditationsMassEdit,
    CustomFieldsMassEdit,
    DelegationsMassEdit,
    PositionsMassEdit,
    UsersInfosMassEdit,
    EventDocumentsExport,
    UserCreate,
    UserInfoDelegationDelete,
    VolunteerRegistrationUpdate,
    PositionsSlotsUsersInfosCheckIn,
    SegmentCreate,
    SegmentDelete,
    SegmentFolderCreate,
    SegmentFolderDelete,
    SegmentFolderMoveDown,
    SegmentFolderMoveUp,
    SegmentFolderUpdateName,
    SegmentMoveDown,
    SegmentMoveFolder,
    SegmentMoveUp,
    SegmentUpdate,
    SegmentUpdateName,
    SegmentsFoldersReorder,
    VolunteersRegistrationsMassDelete,
    UserInfoUpdateField,
    UserProfileUpdate,
    EmailUpdate,
    UserInfoUpdate,
    UserInfoAccreditationsUpdate,
    UserInfoPositionsUpdate,
    UserInfoSlotsUpdate,
    AccreditationDuplicate,
    LoginCodeEmailCreate,
    PositionDuplicate,
    EmailResend,
    VolunteersRegistrationsAccreditationsValidate,
    VolunteersRegistrationsMassAccreditationStateUpdate,
    VolunteersRegistrationsMassAssign,
    VolunteersRegistrationsMassStateUpdate,
    UserInfoDelegationUpdate,
    VolunteerRegistrationUpdateField,
    VolunteersRegistrationsMassWishedAccreditationsUpdate
};

export function useAccreditAssignWaitingMembersQuery(
    args: AccreditAssignWaitingMembersQueryVariables
): IUseQueryReturns<AccreditAssignWaitingMembersQuery> {
    return useQuery(Queries.AccreditAssignWaitingMembers, args, [
        Fragments.VolunteerRegistrationWaitingAssignmentFragment
    ]);
}

export function executeAccreditAssignWaitingMembersQuery(
    args: AccreditAssignWaitingMembersQueryVariables,
    token: string | null | undefined
): Promise<AccreditAssignWaitingMembersQuery> {
    return executeQuery<AccreditAssignWaitingMembersQuery>(Queries.AccreditAssignWaitingMembers, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.VolunteerRegistrationWaitingAssignmentFragment]
    });
}

export function useVolunteersRegistrationsMassAccreditMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteersRegistrationsMassAccreditMutation,
    VolunteersRegistrationsMassAccreditMutationVariables
> {
    return useMutation(Mutations.VolunteersRegistrationsMassAccredit, args);
}

export function useAccreditationsMassEditMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<AccreditationsMassEditMutation, AccreditationsMassEditMutationVariables> {
    return useMutation(Mutations.AccreditationsMassEdit, args);
}

export function useAccreditationsMassEditInfosQuery(
    args: AccreditationsMassEditInfosQueryVariables
): IUseQueryReturns<AccreditationsMassEditInfosQuery> {
    return useQuery(Queries.AccreditationsMassEditInfos, args, [
        Fragments.MassEditCustomFieldFragment
    ]);
}

export function executeAccreditationsMassEditInfosQuery(
    args: AccreditationsMassEditInfosQueryVariables,
    token: string | null | undefined
): Promise<AccreditationsMassEditInfosQuery> {
    return executeQuery<AccreditationsMassEditInfosQuery>(Queries.AccreditationsMassEditInfos, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.MassEditCustomFieldFragment]
    });
}

export function useCustomFieldsMassEditMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<CustomFieldsMassEditMutation, CustomFieldsMassEditMutationVariables> {
    return useMutation(Mutations.CustomFieldsMassEdit, args);
}

export function useCustomFieldsMassEditInfosQuery(
    args: CustomFieldsMassEditInfosQueryVariables
): IUseQueryReturns<CustomFieldsMassEditInfosQuery> {
    return useQuery(Queries.CustomFieldsMassEditInfos, args, []);
}

export function executeCustomFieldsMassEditInfosQuery(
    args: CustomFieldsMassEditInfosQueryVariables,
    token: string | null | undefined
): Promise<CustomFieldsMassEditInfosQuery> {
    return executeQuery<CustomFieldsMassEditInfosQuery>(Queries.CustomFieldsMassEditInfos, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useDelegationsMassEditMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<DelegationsMassEditMutation, DelegationsMassEditMutationVariables> {
    return useMutation(Mutations.DelegationsMassEdit, args);
}

export function useDelegationsMassEditInfosQuery(
    args: DelegationsMassEditInfosQueryVariables
): IUseQueryReturns<DelegationsMassEditInfosQuery> {
    return useQuery(Queries.DelegationsMassEditInfos, args, [
        Fragments.MassEditCustomFieldFragment
    ]);
}

export function executeDelegationsMassEditInfosQuery(
    args: DelegationsMassEditInfosQueryVariables,
    token: string | null | undefined
): Promise<DelegationsMassEditInfosQuery> {
    return executeQuery<DelegationsMassEditInfosQuery>(Queries.DelegationsMassEditInfos, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.MassEditCustomFieldFragment]
    });
}

export function usePositionsMassEditMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<PositionsMassEditMutation, PositionsMassEditMutationVariables> {
    return useMutation(Mutations.PositionsMassEdit, args);
}

export function usePositionsMassEditInfosQuery(
    args: PositionsMassEditInfosQueryVariables
): IUseQueryReturns<PositionsMassEditInfosQuery> {
    return useQuery(Queries.PositionsMassEditInfos, args, [Fragments.MassEditCustomFieldFragment]);
}

export function executePositionsMassEditInfosQuery(
    args: PositionsMassEditInfosQueryVariables,
    token: string | null | undefined
): Promise<PositionsMassEditInfosQuery> {
    return executeQuery<PositionsMassEditInfosQuery>(Queries.PositionsMassEditInfos, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.MassEditCustomFieldFragment]
    });
}

export function useUsersInfosMassEditMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UsersInfosMassEditMutation, UsersInfosMassEditMutationVariables> {
    return useMutation(Mutations.UsersInfosMassEdit, args);
}

export function useUsersInfosMassEditInfosQuery(
    args: UsersInfosMassEditInfosQueryVariables
): IUseQueryReturns<UsersInfosMassEditInfosQuery> {
    return useQuery(Queries.UsersInfosMassEditInfos, args, [Fragments.MassEditCustomFieldFragment]);
}

export function executeUsersInfosMassEditInfosQuery(
    args: UsersInfosMassEditInfosQueryVariables,
    token: string | null | undefined
): Promise<UsersInfosMassEditInfosQuery> {
    return executeQuery<UsersInfosMassEditInfosQuery>(Queries.UsersInfosMassEditInfos, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.MassEditCustomFieldFragment]
    });
}

export function useEventDelegationMembersInfosQuery(
    args: EventDelegationMembersInfosQueryVariables
): IUseQueryReturns<EventDelegationMembersInfosQuery> {
    return useQuery(Queries.EventDelegationMembersInfos, args, [
        Fragments.SegmentCustomFieldFragment,
        Fragments.CustomDocumentPopulationFragment
    ]);
}

export function executeEventDelegationMembersInfosQuery(
    args: EventDelegationMembersInfosQueryVariables,
    token: string | null | undefined
): Promise<EventDelegationMembersInfosQuery> {
    return executeQuery<EventDelegationMembersInfosQuery>(Queries.EventDelegationMembersInfos, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.SegmentCustomFieldFragment,
            Fragments.CustomDocumentPopulationFragment
        ]
    });
}

export function useOrganizationDelegationMembersInfosQuery(
    args: OrganizationDelegationMembersInfosQueryVariables
): IUseQueryReturns<OrganizationDelegationMembersInfosQuery> {
    return useQuery(Queries.OrganizationDelegationMembersInfos, args, [
        Fragments.SegmentCustomFieldFragment
    ]);
}

export function executeOrganizationDelegationMembersInfosQuery(
    args: OrganizationDelegationMembersInfosQueryVariables,
    token: string | null | undefined
): Promise<OrganizationDelegationMembersInfosQuery> {
    return executeQuery<OrganizationDelegationMembersInfosQuery>(
        Queries.OrganizationDelegationMembersInfos,
        {
            variables: args,
            token: token || undefined,
            fragments: [Fragments.SegmentCustomFieldFragment]
        }
    );
}

export function useDelegationAccreditationSlotMemberQuery(
    args: DelegationAccreditationSlotMemberQueryVariables
): IUseQueryReturns<DelegationAccreditationSlotMemberQuery> {
    return useQuery(Queries.DelegationAccreditationSlotMember, args, [
        Fragments.DelegationAccreditationSlotStatsFragment,
        Fragments.VolunteerRegistrationDelegationAccreditationSlotFragment
    ]);
}

export function executeDelegationAccreditationSlotMemberQuery(
    args: DelegationAccreditationSlotMemberQueryVariables,
    token: string | null | undefined
): Promise<DelegationAccreditationSlotMemberQuery> {
    return executeQuery<DelegationAccreditationSlotMemberQuery>(
        Queries.DelegationAccreditationSlotMember,
        {
            variables: args,
            token: token || undefined,
            fragments: [
                Fragments.DelegationAccreditationSlotStatsFragment,
                Fragments.VolunteerRegistrationDelegationAccreditationSlotFragment
            ]
        }
    );
}

export function useDelegationAccreditationSlotMembersQuery(
    args: DelegationAccreditationSlotMembersQueryVariables
): IUseQueryReturns<DelegationAccreditationSlotMembersQuery> {
    return useQuery(Queries.DelegationAccreditationSlotMembers, args, [
        Fragments.DelegationAccreditationSlotStatsFragment,
        Fragments.VolunteerRegistrationDelegationAccreditationSlotFragment
    ]);
}

export function executeDelegationAccreditationSlotMembersQuery(
    args: DelegationAccreditationSlotMembersQueryVariables,
    token: string | null | undefined
): Promise<DelegationAccreditationSlotMembersQuery> {
    return executeQuery<DelegationAccreditationSlotMembersQuery>(
        Queries.DelegationAccreditationSlotMembers,
        {
            variables: args,
            token: token || undefined,
            fragments: [
                Fragments.DelegationAccreditationSlotStatsFragment,
                Fragments.VolunteerRegistrationDelegationAccreditationSlotFragment
            ]
        }
    );
}

export function useDelegationAccreditationSlotStatsQuery(
    args: DelegationAccreditationSlotStatsQueryVariables
): IUseQueryReturns<DelegationAccreditationSlotStatsQuery> {
    return useQuery(Queries.DelegationAccreditationSlotStats, args, [
        Fragments.DelegationAccreditationSlotStatsFragment
    ]);
}

export function executeDelegationAccreditationSlotStatsQuery(
    args: DelegationAccreditationSlotStatsQueryVariables,
    token: string | null | undefined
): Promise<DelegationAccreditationSlotStatsQuery> {
    return executeQuery<DelegationAccreditationSlotStatsQuery>(
        Queries.DelegationAccreditationSlotStats,
        {
            variables: args,
            token: token || undefined,
            fragments: [Fragments.DelegationAccreditationSlotStatsFragment]
        }
    );
}

export function useDelegationAccreditationsQuery(
    args: DelegationAccreditationsQueryVariables
): IUseQueryReturns<DelegationAccreditationsQuery> {
    return useQuery(Queries.DelegationAccreditations, args, [
        Fragments.DelegationAccreditationSlotStatsFragment
    ]);
}

export function executeDelegationAccreditationsQuery(
    args: DelegationAccreditationsQueryVariables,
    token: string | null | undefined
): Promise<DelegationAccreditationsQuery> {
    return executeQuery<DelegationAccreditationsQuery>(Queries.DelegationAccreditations, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.DelegationAccreditationSlotStatsFragment]
    });
}

export function useEventDocumentsExportMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<EventDocumentsExportMutation, EventDocumentsExportMutationVariables> {
    return useMutation(Mutations.EventDocumentsExport, args);
}

export function useUserCreateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UserCreateMutation, UserCreateMutationVariables> {
    return useMutation(Mutations.UserCreate, args);
}

export function useUserInfoDelegationDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    UserInfoDelegationDeleteMutation,
    UserInfoDelegationDeleteMutationVariables
> {
    return useMutation(Mutations.UserInfoDelegationDelete, args);
}

export function useVolunteerRegistrationUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteerRegistrationUpdateMutation,
    VolunteerRegistrationUpdateMutationVariables
> {
    return useMutation(Mutations.VolunteerRegistrationUpdate, args);
}

export function useUserPermissionQuery(
    args: UserPermissionQueryVariables
): IUseQueryReturns<UserPermissionQuery> {
    return useQuery(Queries.UserPermission, args, []);
}

export function executeUserPermissionQuery(
    args: UserPermissionQueryVariables,
    token: string | null | undefined
): Promise<UserPermissionQuery> {
    return executeQuery<UserPermissionQuery>(Queries.UserPermission, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function usePositionsSlotsUsersInfosCheckInMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    PositionsSlotsUsersInfosCheckInMutation,
    PositionsSlotsUsersInfosCheckInMutationVariables
> {
    return useMutation(Mutations.PositionsSlotsUsersInfosCheckIn, args);
}

export function usePositionSlotUserInfoQuery(
    args: PositionSlotUserInfoQueryVariables
): IUseQueryReturns<PositionSlotUserInfoQuery> {
    return useQuery(Queries.PositionSlotUserInfo, args, [
        Fragments.UserPositionSlotUserInfoFragment
    ]);
}

export function executePositionSlotUserInfoQuery(
    args: PositionSlotUserInfoQueryVariables,
    token: string | null | undefined
): Promise<PositionSlotUserInfoQuery> {
    return executeQuery<PositionSlotUserInfoQuery>(Queries.PositionSlotUserInfo, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.UserPositionSlotUserInfoFragment]
    });
}

export function useUserPositionsQuery(
    args: UserPositionsQueryVariables
): IUseQueryReturns<UserPositionsQuery> {
    return useQuery(Queries.UserPositions, args, [
        Fragments.FormsUsersInfosWishedFragment,
        Fragments.UserPositionSlotUserInfoFragment
    ]);
}

export function executeUserPositionsQuery(
    args: UserPositionsQueryVariables,
    token: string | null | undefined
): Promise<UserPositionsQuery> {
    return executeQuery<UserPositionsQuery>(Queries.UserPositions, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.FormsUsersInfosWishedFragment,
            Fragments.UserPositionSlotUserInfoFragment
        ]
    });
}

export function useConditionsCustomFieldsQuery(
    args: ConditionsCustomFieldsQueryVariables
): IUseQueryReturns<ConditionsCustomFieldsQuery> {
    return useQuery(Queries.ConditionsCustomFields, args, []);
}

export function executeConditionsCustomFieldsQuery(
    args: ConditionsCustomFieldsQueryVariables,
    token: string | null | undefined
): Promise<ConditionsCustomFieldsQuery> {
    return executeQuery<ConditionsCustomFieldsQuery>(Queries.ConditionsCustomFields, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useTagsQuery(args: TagsQueryVariables): IUseQueryReturns<TagsQuery> {
    return useQuery(Queries.Tags, args, []);
}

export function executeTagsQuery(
    args: TagsQueryVariables,
    token: string | null | undefined
): Promise<TagsQuery> {
    return executeQuery<TagsQuery>(Queries.Tags, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useUsersSegmentsCampaignsQuery(
    args: UsersSegmentsCampaignsQueryVariables
): IUseQueryReturns<UsersSegmentsCampaignsQuery> {
    return useQuery(Queries.UsersSegmentsCampaigns, args, [Fragments.SegmentCampaignFragment]);
}

export function executeUsersSegmentsCampaignsQuery(
    args: UsersSegmentsCampaignsQueryVariables,
    token: string | null | undefined
): Promise<UsersSegmentsCampaignsQuery> {
    return executeQuery<UsersSegmentsCampaignsQuery>(Queries.UsersSegmentsCampaigns, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.SegmentCampaignFragment]
    });
}

export function useUsersSegmentsDelegationsQuery(
    args: UsersSegmentsDelegationsQueryVariables
): IUseQueryReturns<UsersSegmentsDelegationsQuery> {
    return useQuery(Queries.UsersSegmentsDelegations, args, []);
}

export function executeUsersSegmentsDelegationsQuery(
    args: UsersSegmentsDelegationsQueryVariables,
    token: string | null | undefined
): Promise<UsersSegmentsDelegationsQuery> {
    return executeQuery<UsersSegmentsDelegationsQuery>(Queries.UsersSegmentsDelegations, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useUsersSegmentsEventsQuery(
    args: UsersSegmentsEventsQueryVariables
): IUseQueryReturns<UsersSegmentsEventsQuery> {
    return useQuery(Queries.UsersSegmentsEvents, args, [Fragments.SegmentEventFragment]);
}

export function executeUsersSegmentsEventsQuery(
    args: UsersSegmentsEventsQueryVariables,
    token: string | null | undefined
): Promise<UsersSegmentsEventsQuery> {
    return executeQuery<UsersSegmentsEventsQuery>(Queries.UsersSegmentsEvents, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.SegmentEventFragment]
    });
}

export function useUsersSegmentsFormsQuery(
    args: UsersSegmentsFormsQueryVariables
): IUseQueryReturns<UsersSegmentsFormsQuery> {
    return useQuery(Queries.UsersSegmentsForms, args, [Fragments.SegmentFormFragment]);
}

export function executeUsersSegmentsFormsQuery(
    args: UsersSegmentsFormsQueryVariables,
    token: string | null | undefined
): Promise<UsersSegmentsFormsQuery> {
    return executeQuery<UsersSegmentsFormsQuery>(Queries.UsersSegmentsForms, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.SegmentFormFragment]
    });
}

export function useVolunteersSegmentsAccreditationsQuery(
    args: VolunteersSegmentsAccreditationsQueryVariables
): IUseQueryReturns<VolunteersSegmentsAccreditationsQuery> {
    return useQuery(Queries.VolunteersSegmentsAccreditations, args, []);
}

export function executeVolunteersSegmentsAccreditationsQuery(
    args: VolunteersSegmentsAccreditationsQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsAccreditationsQuery> {
    return executeQuery<VolunteersSegmentsAccreditationsQuery>(
        Queries.VolunteersSegmentsAccreditations,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useVolunteersSegmentsAccreditationsCategoriesQuery(
    args: VolunteersSegmentsAccreditationsCategoriesQueryVariables
): IUseQueryReturns<VolunteersSegmentsAccreditationsCategoriesQuery> {
    return useQuery(Queries.VolunteersSegmentsAccreditationsCategories, args, []);
}

export function executeVolunteersSegmentsAccreditationsCategoriesQuery(
    args: VolunteersSegmentsAccreditationsCategoriesQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsAccreditationsCategoriesQuery> {
    return executeQuery<VolunteersSegmentsAccreditationsCategoriesQuery>(
        Queries.VolunteersSegmentsAccreditationsCategories,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useVolunteersSegmentsAccreditationsSegmentsQuery(
    args: VolunteersSegmentsAccreditationsSegmentsQueryVariables
): IUseQueryReturns<VolunteersSegmentsAccreditationsSegmentsQuery> {
    return useQuery(Queries.VolunteersSegmentsAccreditationsSegments, args, []);
}

export function executeVolunteersSegmentsAccreditationsSegmentsQuery(
    args: VolunteersSegmentsAccreditationsSegmentsQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsAccreditationsSegmentsQuery> {
    return executeQuery<VolunteersSegmentsAccreditationsSegmentsQuery>(
        Queries.VolunteersSegmentsAccreditationsSegments,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useVolunteersSegmentsAccreditationsSlotsQuery(
    args: VolunteersSegmentsAccreditationsSlotsQueryVariables
): IUseQueryReturns<VolunteersSegmentsAccreditationsSlotsQuery> {
    return useQuery(Queries.VolunteersSegmentsAccreditationsSlots, args, []);
}

export function executeVolunteersSegmentsAccreditationsSlotsQuery(
    args: VolunteersSegmentsAccreditationsSlotsQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsAccreditationsSlotsQuery> {
    return executeQuery<VolunteersSegmentsAccreditationsSlotsQuery>(
        Queries.VolunteersSegmentsAccreditationsSlots,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useVolunteersSegmentsCampaignsQuery(
    args: VolunteersSegmentsCampaignsQueryVariables
): IUseQueryReturns<VolunteersSegmentsCampaignsQuery> {
    return useQuery(Queries.VolunteersSegmentsCampaigns, args, [Fragments.SegmentCampaignFragment]);
}

export function executeVolunteersSegmentsCampaignsQuery(
    args: VolunteersSegmentsCampaignsQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsCampaignsQuery> {
    return executeQuery<VolunteersSegmentsCampaignsQuery>(Queries.VolunteersSegmentsCampaigns, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.SegmentCampaignFragment]
    });
}

export function useVolunteeersSegmentsCustomSlotsPeriodsQuery(
    args: VolunteeersSegmentsCustomSlotsPeriodsQueryVariables
): IUseQueryReturns<VolunteeersSegmentsCustomSlotsPeriodsQuery> {
    return useQuery(Queries.VolunteeersSegmentsCustomSlotsPeriods, args, []);
}

export function executeVolunteeersSegmentsCustomSlotsPeriodsQuery(
    args: VolunteeersSegmentsCustomSlotsPeriodsQueryVariables,
    token: string | null | undefined
): Promise<VolunteeersSegmentsCustomSlotsPeriodsQuery> {
    return executeQuery<VolunteeersSegmentsCustomSlotsPeriodsQuery>(
        Queries.VolunteeersSegmentsCustomSlotsPeriods,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useVolunteersSegmentsDelegationsQuery(
    args: VolunteersSegmentsDelegationsQueryVariables
): IUseQueryReturns<VolunteersSegmentsDelegationsQuery> {
    return useQuery(Queries.VolunteersSegmentsDelegations, args, []);
}

export function executeVolunteersSegmentsDelegationsQuery(
    args: VolunteersSegmentsDelegationsQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsDelegationsQuery> {
    return executeQuery<VolunteersSegmentsDelegationsQuery>(Queries.VolunteersSegmentsDelegations, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useVolunteersSegmentsDelegationsSegmentsQuery(
    args: VolunteersSegmentsDelegationsSegmentsQueryVariables
): IUseQueryReturns<VolunteersSegmentsDelegationsSegmentsQuery> {
    return useQuery(Queries.VolunteersSegmentsDelegationsSegments, args, []);
}

export function executeVolunteersSegmentsDelegationsSegmentsQuery(
    args: VolunteersSegmentsDelegationsSegmentsQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsDelegationsSegmentsQuery> {
    return executeQuery<VolunteersSegmentsDelegationsSegmentsQuery>(
        Queries.VolunteersSegmentsDelegationsSegments,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useVolunteersSegmentsFormsQuery(
    args: VolunteersSegmentsFormsQueryVariables
): IUseQueryReturns<VolunteersSegmentsFormsQuery> {
    return useQuery(Queries.VolunteersSegmentsForms, args, [Fragments.SegmentFormFragment]);
}

export function executeVolunteersSegmentsFormsQuery(
    args: VolunteersSegmentsFormsQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsFormsQuery> {
    return executeQuery<VolunteersSegmentsFormsQuery>(Queries.VolunteersSegmentsForms, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.SegmentFormFragment]
    });
}

export function useVolunteersSegmentsPositionsQuery(
    args: VolunteersSegmentsPositionsQueryVariables
): IUseQueryReturns<VolunteersSegmentsPositionsQuery> {
    return useQuery(Queries.VolunteersSegmentsPositions, args, []);
}

export function executeVolunteersSegmentsPositionsQuery(
    args: VolunteersSegmentsPositionsQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsPositionsQuery> {
    return executeQuery<VolunteersSegmentsPositionsQuery>(Queries.VolunteersSegmentsPositions, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useVolunteersSegmentsPositionsCategoriesQuery(
    args: VolunteersSegmentsPositionsCategoriesQueryVariables
): IUseQueryReturns<VolunteersSegmentsPositionsCategoriesQuery> {
    return useQuery(Queries.VolunteersSegmentsPositionsCategories, args, []);
}

export function executeVolunteersSegmentsPositionsCategoriesQuery(
    args: VolunteersSegmentsPositionsCategoriesQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsPositionsCategoriesQuery> {
    return executeQuery<VolunteersSegmentsPositionsCategoriesQuery>(
        Queries.VolunteersSegmentsPositionsCategories,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useVolunteersSegmentsPositionsSegmentsQuery(
    args: VolunteersSegmentsPositionsSegmentsQueryVariables
): IUseQueryReturns<VolunteersSegmentsPositionsSegmentsQuery> {
    return useQuery(Queries.VolunteersSegmentsPositionsSegments, args, []);
}

export function executeVolunteersSegmentsPositionsSegmentsQuery(
    args: VolunteersSegmentsPositionsSegmentsQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsPositionsSegmentsQuery> {
    return executeQuery<VolunteersSegmentsPositionsSegmentsQuery>(
        Queries.VolunteersSegmentsPositionsSegments,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useVolunteersSegmentsPositionsSlotsQuery(
    args: VolunteersSegmentsPositionsSlotsQueryVariables
): IUseQueryReturns<VolunteersSegmentsPositionsSlotsQuery> {
    return useQuery(Queries.VolunteersSegmentsPositionsSlots, args, []);
}

export function executeVolunteersSegmentsPositionsSlotsQuery(
    args: VolunteersSegmentsPositionsSlotsQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsPositionsSlotsQuery> {
    return executeQuery<VolunteersSegmentsPositionsSlotsQuery>(
        Queries.VolunteersSegmentsPositionsSlots,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useVolunteersSegmentsUsersSegmentsQuery(
    args: VolunteersSegmentsUsersSegmentsQueryVariables
): IUseQueryReturns<VolunteersSegmentsUsersSegmentsQuery> {
    return useQuery(Queries.VolunteersSegmentsUsersSegments, args, []);
}

export function executeVolunteersSegmentsUsersSegmentsQuery(
    args: VolunteersSegmentsUsersSegmentsQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsUsersSegmentsQuery> {
    return executeQuery<VolunteersSegmentsUsersSegmentsQuery>(
        Queries.VolunteersSegmentsUsersSegments,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useSegmentCreateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SegmentCreateMutation, SegmentCreateMutationVariables> {
    return useMutation(Mutations.SegmentCreate, args);
}

export function useSegmentDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SegmentDeleteMutation, SegmentDeleteMutationVariables> {
    return useMutation(Mutations.SegmentDelete, args);
}

export function useSegmentFolderCreateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SegmentFolderCreateMutation, SegmentFolderCreateMutationVariables> {
    return useMutation(Mutations.SegmentFolderCreate, args);
}

export function useSegmentFolderDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SegmentFolderDeleteMutation, SegmentFolderDeleteMutationVariables> {
    return useMutation(Mutations.SegmentFolderDelete, args);
}

export function useSegmentFolderMoveDownMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SegmentFolderMoveDownMutation, SegmentFolderMoveDownMutationVariables> {
    return useMutation(Mutations.SegmentFolderMoveDown, args);
}

export function useSegmentFolderMoveUpMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SegmentFolderMoveUpMutation, SegmentFolderMoveUpMutationVariables> {
    return useMutation(Mutations.SegmentFolderMoveUp, args);
}

export function useSegmentFolderUpdateNameMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SegmentFolderUpdateNameMutation, SegmentFolderUpdateNameMutationVariables> {
    return useMutation(Mutations.SegmentFolderUpdateName, args);
}

export function useSegmentMoveDownMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SegmentMoveDownMutation, SegmentMoveDownMutationVariables> {
    return useMutation(Mutations.SegmentMoveDown, args);
}

export function useSegmentMoveFolderMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SegmentMoveFolderMutation, SegmentMoveFolderMutationVariables> {
    return useMutation(Mutations.SegmentMoveFolder, args);
}

export function useSegmentMoveUpMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SegmentMoveUpMutation, SegmentMoveUpMutationVariables> {
    return useMutation(Mutations.SegmentMoveUp, args);
}

export function useSegmentUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SegmentUpdateMutation, SegmentUpdateMutationVariables> {
    return useMutation(Mutations.SegmentUpdate, args);
}

export function useSegmentUpdateNameMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SegmentUpdateNameMutation, SegmentUpdateNameMutationVariables> {
    return useMutation(Mutations.SegmentUpdateName, args);
}

export function useSegmentsFoldersReorderMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SegmentsFoldersReorderMutation, SegmentsFoldersReorderMutationVariables> {
    return useMutation(Mutations.SegmentsFoldersReorder, args);
}

export function useVolunteersRegistrationsMassDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteersRegistrationsMassDeleteMutation,
    VolunteersRegistrationsMassDeleteMutationVariables
> {
    return useMutation(Mutations.VolunteersRegistrationsMassDelete, args);
}

export function useUserQuery(args: UserQueryVariables): IUseQueryReturns<UserQuery> {
    return useQuery(Queries.User, args, [Fragments.UserInfoCommunityFragment]);
}

export function executeUserQuery(
    args: UserQueryVariables,
    token: string | null | undefined
): Promise<UserQuery> {
    return executeQuery<UserQuery>(Queries.User, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.UserInfoCommunityFragment]
    });
}

export function useUserInfoUpdateFieldMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UserInfoUpdateFieldMutation, UserInfoUpdateFieldMutationVariables> {
    return useMutation(Mutations.UserInfoUpdateField, args);
}

export function useUsersQuery(args: UsersQueryVariables): IUseQueryReturns<UsersQuery> {
    return useQuery(Queries.Users, args, [Fragments.UserInfoCommunityFragment]);
}

export function executeUsersQuery(
    args: UsersQueryVariables,
    token: string | null | undefined
): Promise<UsersQuery> {
    return executeQuery<UsersQuery>(Queries.Users, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.UserInfoCommunityFragment]
    });
}

export function useUsersSegmentsQuery(
    args: UsersSegmentsQueryVariables
): IUseQueryReturns<UsersSegmentsQuery> {
    return useQuery(Queries.UsersSegments, args, [
        Fragments.SegmentCustomFieldFragment,
        Fragments.SegmentsFoldersSegmentsFragment
    ]);
}

export function executeUsersSegmentsQuery(
    args: UsersSegmentsQueryVariables,
    token: string | null | undefined
): Promise<UsersSegmentsQuery> {
    return executeQuery<UsersSegmentsQuery>(Queries.UsersSegments, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.SegmentCustomFieldFragment, Fragments.SegmentsFoldersSegmentsFragment]
    });
}

export function useProfileQuery(args: ProfileQueryVariables): IUseQueryReturns<ProfileQuery> {
    return useQuery(Queries.Profile, args, []);
}

export function executeProfileQuery(
    args: ProfileQueryVariables,
    token: string | null | undefined
): Promise<ProfileQuery> {
    return executeQuery<ProfileQuery>(Queries.Profile, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useUserProfileUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UserProfileUpdateMutation, UserProfileUpdateMutationVariables> {
    return useMutation(Mutations.UserProfileUpdate, args);
}

export function useEmailUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<EmailUpdateMutation, EmailUpdateMutationVariables> {
    return useMutation(Mutations.EmailUpdate, args);
}

export function useUserInfoUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UserInfoUpdateMutation, UserInfoUpdateMutationVariables> {
    return useMutation(Mutations.UserInfoUpdate, args);
}

export function useUserInfoAccreditationsUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    UserInfoAccreditationsUpdateMutation,
    UserInfoAccreditationsUpdateMutationVariables
> {
    return useMutation(Mutations.UserInfoAccreditationsUpdate, args);
}

export function useUserUpdateAccreditationsInfosQuery(
    args: UserUpdateAccreditationsInfosQueryVariables
): IUseQueryReturns<UserUpdateAccreditationsInfosQuery> {
    return useQuery(Queries.UserUpdateAccreditationsInfos, args, [
        Fragments.MemberRegistrationUserInfoFragment,
        Fragments.FormsUsersInfosWishedFragment,
        Fragments.DelegationAccreditationSlotFragment,
        Fragments.FormMemberRegistrationFragment,
        Fragments.FormRegisterAccreditationCategoryFragment
    ]);
}

export function executeUserUpdateAccreditationsInfosQuery(
    args: UserUpdateAccreditationsInfosQueryVariables,
    token: string | null | undefined
): Promise<UserUpdateAccreditationsInfosQuery> {
    return executeQuery<UserUpdateAccreditationsInfosQuery>(Queries.UserUpdateAccreditationsInfos, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.MemberRegistrationUserInfoFragment,
            Fragments.FormsUsersInfosWishedFragment,
            Fragments.DelegationAccreditationSlotFragment,
            Fragments.FormMemberRegistrationFragment,
            Fragments.FormRegisterAccreditationCategoryFragment
        ]
    });
}

export function useUserInfoPositionsUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UserInfoPositionsUpdateMutation, UserInfoPositionsUpdateMutationVariables> {
    return useMutation(Mutations.UserInfoPositionsUpdate, args);
}

export function useUserUpdateMissionsInfosQuery(
    args: UserUpdateMissionsInfosQueryVariables
): IUseQueryReturns<UserUpdateMissionsInfosQuery> {
    return useQuery(Queries.UserUpdateMissionsInfos, args, [
        Fragments.MemberRegistrationUserInfoFragment,
        Fragments.FormsUsersInfosWishedFragment,
        Fragments.FormMemberRegistrationFragment,
        Fragments.FormRegisterCategoryFragment
    ]);
}

export function executeUserUpdateMissionsInfosQuery(
    args: UserUpdateMissionsInfosQueryVariables,
    token: string | null | undefined
): Promise<UserUpdateMissionsInfosQuery> {
    return executeQuery<UserUpdateMissionsInfosQuery>(Queries.UserUpdateMissionsInfos, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.MemberRegistrationUserInfoFragment,
            Fragments.FormsUsersInfosWishedFragment,
            Fragments.FormMemberRegistrationFragment,
            Fragments.FormRegisterCategoryFragment
        ]
    });
}

export function useUserInfoSlotsUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UserInfoSlotsUpdateMutation, UserInfoSlotsUpdateMutationVariables> {
    return useMutation(Mutations.UserInfoSlotsUpdate, args);
}

export function useUserUpdateSlotsInfosQuery(
    args: UserUpdateSlotsInfosQueryVariables
): IUseQueryReturns<UserUpdateSlotsInfosQuery> {
    return useQuery(Queries.UserUpdateSlotsInfos, args, [
        Fragments.MemberRegistrationUserInfoFragment,
        Fragments.FormsUsersInfosWishedFragment,
        Fragments.FormMemberRegistrationFragment
    ]);
}

export function executeUserUpdateSlotsInfosQuery(
    args: UserUpdateSlotsInfosQueryVariables,
    token: string | null | undefined
): Promise<UserUpdateSlotsInfosQuery> {
    return executeQuery<UserUpdateSlotsInfosQuery>(Queries.UserUpdateSlotsInfos, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.MemberRegistrationUserInfoFragment,
            Fragments.FormsUsersInfosWishedFragment,
            Fragments.FormMemberRegistrationFragment
        ]
    });
}

export function useAwsS3PostCredentialsQuery(
    args: AwsS3PostCredentialsQueryVariables
): IUseQueryReturns<AwsS3PostCredentialsQuery> {
    return useQuery(Queries.AwsS3PostCredentials, args, []);
}

export function executeAwsS3PostCredentialsQuery(
    args: AwsS3PostCredentialsQueryVariables,
    token: string | null | undefined
): Promise<AwsS3PostCredentialsQuery> {
    return executeQuery<AwsS3PostCredentialsQuery>(Queries.AwsS3PostCredentials, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useAccreditationDuplicateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<AccreditationDuplicateMutation, AccreditationDuplicateMutationVariables> {
    return useMutation(Mutations.AccreditationDuplicate, args);
}

export function useCurrentUserQuery(
    args: CurrentUserQueryVariables
): IUseQueryReturns<CurrentUserQuery> {
    return useQuery(Queries.CurrentUser, args, []);
}

export function executeCurrentUserQuery(
    args: CurrentUserQueryVariables,
    token: string | null | undefined
): Promise<CurrentUserQuery> {
    return executeQuery<CurrentUserQuery>(Queries.CurrentUser, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useLoginCodeEmailCreateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<LoginCodeEmailCreateMutation, LoginCodeEmailCreateMutationVariables> {
    return useMutation(Mutations.LoginCodeEmailCreate, args);
}

export function usePositionDuplicateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<PositionDuplicateMutation, PositionDuplicateMutationVariables> {
    return useMutation(Mutations.PositionDuplicate, args);
}

export function useEmailResendMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<EmailResendMutation, EmailResendMutationVariables> {
    return useMutation(Mutations.EmailResend, args);
}

export function useUserOverlaySlotsQuery(
    args: UserOverlaySlotsQueryVariables
): IUseQueryReturns<UserOverlaySlotsQuery> {
    return useQuery(Queries.UserOverlaySlots, args, [Fragments.FormsUsersInfosWishedFragment]);
}

export function executeUserOverlaySlotsQuery(
    args: UserOverlaySlotsQueryVariables,
    token: string | null | undefined
): Promise<UserOverlaySlotsQuery> {
    return executeQuery<UserOverlaySlotsQuery>(Queries.UserOverlaySlots, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.FormsUsersInfosWishedFragment]
    });
}

export function useVolunteersRegistrationsAccreditationsValidateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteersRegistrationsAccreditationsValidateMutation,
    VolunteersRegistrationsAccreditationsValidateMutationVariables
> {
    return useMutation(Mutations.VolunteersRegistrationsAccreditationsValidate, args);
}

export function useVolunteersRegistrationsMassAccreditationStateUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteersRegistrationsMassAccreditationStateUpdateMutation,
    VolunteersRegistrationsMassAccreditationStateUpdateMutationVariables
> {
    return useMutation(Mutations.VolunteersRegistrationsMassAccreditationStateUpdate, args);
}

export function useVolunteersRegistrationsMassAssignMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteersRegistrationsMassAssignMutation,
    VolunteersRegistrationsMassAssignMutationVariables
> {
    return useMutation(Mutations.VolunteersRegistrationsMassAssign, args);
}

export function useVolunteersRegistrationsMassStateUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteersRegistrationsMassStateUpdateMutation,
    VolunteersRegistrationsMassStateUpdateMutationVariables
> {
    return useMutation(Mutations.VolunteersRegistrationsMassStateUpdate, args);
}

export function useVolunteersSegmentsQuery(
    args: VolunteersSegmentsQueryVariables
): IUseQueryReturns<VolunteersSegmentsQuery> {
    return useQuery(Queries.VolunteersSegments, args, [
        Fragments.SegmentCustomFieldFragment,
        Fragments.SegmentsFoldersSegmentsFragment
    ]);
}

export function executeVolunteersSegmentsQuery(
    args: VolunteersSegmentsQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsQuery> {
    return executeQuery<VolunteersSegmentsQuery>(Queries.VolunteersSegments, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.SegmentCustomFieldFragment, Fragments.SegmentsFoldersSegmentsFragment]
    });
}

export function useUpdateCellAccreditationsSlotsQuery(
    args: UpdateCellAccreditationsSlotsQueryVariables
): IUseQueryReturns<UpdateCellAccreditationsSlotsQuery> {
    return useQuery(Queries.UpdateCellAccreditationsSlots, args, []);
}

export function executeUpdateCellAccreditationsSlotsQuery(
    args: UpdateCellAccreditationsSlotsQueryVariables,
    token: string | null | undefined
): Promise<UpdateCellAccreditationsSlotsQuery> {
    return executeQuery<UpdateCellAccreditationsSlotsQuery>(Queries.UpdateCellAccreditationsSlots, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useUpdateCellDelegationsQuery(
    args: UpdateCellDelegationsQueryVariables
): IUseQueryReturns<UpdateCellDelegationsQuery> {
    return useQuery(Queries.UpdateCellDelegations, args, []);
}

export function executeUpdateCellDelegationsQuery(
    args: UpdateCellDelegationsQueryVariables,
    token: string | null | undefined
): Promise<UpdateCellDelegationsQuery> {
    return executeQuery<UpdateCellDelegationsQuery>(Queries.UpdateCellDelegations, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useUpdateCellPositionsSlotsQuery(
    args: UpdateCellPositionsSlotsQueryVariables
): IUseQueryReturns<UpdateCellPositionsSlotsQuery> {
    return useQuery(Queries.UpdateCellPositionsSlots, args, []);
}

export function executeUpdateCellPositionsSlotsQuery(
    args: UpdateCellPositionsSlotsQueryVariables,
    token: string | null | undefined
): Promise<UpdateCellPositionsSlotsQuery> {
    return executeQuery<UpdateCellPositionsSlotsQuery>(Queries.UpdateCellPositionsSlots, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useUserInfoDelegationUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    UserInfoDelegationUpdateMutation,
    UserInfoDelegationUpdateMutationVariables
> {
    return useMutation(Mutations.UserInfoDelegationUpdate, args);
}

export function useVolunteerRegistrationUpdateFieldMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteerRegistrationUpdateFieldMutation,
    VolunteerRegistrationUpdateFieldMutationVariables
> {
    return useMutation(Mutations.VolunteerRegistrationUpdateField, args);
}

export function useVolunteerQuery(args: VolunteerQueryVariables): IUseQueryReturns<VolunteerQuery> {
    return useQuery(Queries.Volunteer, args, [Fragments.VolunteerRegistrationFragment]);
}

export function executeVolunteerQuery(
    args: VolunteerQueryVariables,
    token: string | null | undefined
): Promise<VolunteerQuery> {
    return executeQuery<VolunteerQuery>(Queries.Volunteer, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.VolunteerRegistrationFragment]
    });
}

export function useVolunteersQuery(
    args: VolunteersQueryVariables
): IUseQueryReturns<VolunteersQuery> {
    return useQuery(Queries.Volunteers, args, [Fragments.VolunteerRegistrationFragment]);
}

export function executeVolunteersQuery(
    args: VolunteersQueryVariables,
    token: string | null | undefined
): Promise<VolunteersQuery> {
    return executeQuery<VolunteersQuery>(Queries.Volunteers, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.VolunteerRegistrationFragment]
    });
}

export function useMassAccreditSlotsQuery(
    args: MassAccreditSlotsQueryVariables
): IUseQueryReturns<MassAccreditSlotsQuery> {
    return useQuery(Queries.MassAccreditSlots, args, []);
}

export function executeMassAccreditSlotsQuery(
    args: MassAccreditSlotsQueryVariables,
    token: string | null | undefined
): Promise<MassAccreditSlotsQuery> {
    return executeQuery<MassAccreditSlotsQuery>(Queries.MassAccreditSlots, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useVolunteersRegistrationsMassWishedAccreditationsUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteersRegistrationsMassWishedAccreditationsUpdateMutation,
    VolunteersRegistrationsMassWishedAccreditationsUpdateMutationVariables
> {
    return useMutation(Mutations.VolunteersRegistrationsMassWishedAccreditationsUpdate, args);
}
