import { Item } from 'common-front/src/designSystem/components/topMenu/item';
import { TopMenu } from 'common-front/src/designSystem/components/topMenu/topMenu';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { FormsPaths } from 'common/src/util/paths/formsPaths';
import * as React from 'react';

export const FormMenu = () => {
    const { translate, params } = useHeavent();

    return (
        <TopMenu height={1} width={1}>
            <Item to={FormsPaths.FORM_ELEMENTS(params)}>{translate('champs_91671')}</Item>

            <Item
                isActive={(pathname) => pathname.match(/\/form\/\d+\/parameters/) !== null}
                to={FormsPaths.FORM_PARAMETERS_GENERAL(params)}
            >
                {translate('param_tres_78420')}
            </Item>

            <Item to={FormsPaths.FORM_INTEGRATION(params)}>{translate('int_gration_10662')}</Item>

            <Item to={FormsPaths.FORM_EMAILS(params)}>{translate('mod_les_e_mail_91257')}</Item>

            <Item to={FormsPaths.FORM_PREVIEW(params)}>{translate('aper_u_25595')}</Item>
        </TopMenu>
    );
};
