import { Page } from 'common-front/src/components/page/page';
import { Tab } from 'common-front/src/designSystem/components/tabs/tab';
import { TabList } from 'common-front/src/designSystem/components/tabs/tabList';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { AccreditationQuery } from 'common/src/generated/types';
import {
    AccreditationParams,
    AccreditationsPaths
} from 'common/src/util/paths/accreditationsPaths';
import * as React from 'react';
import { AccreditationHeader } from './accreditationHeader';
import { AccreditationDashboard } from './dashboard/accreditationDashboard';
import { AccreditationMembers } from './members/accreditationMembers';

interface IAccreditationProps {
    accreditation: AccreditationQuery['event']['accreditation'];
}

export const Accreditation = (props: IAccreditationProps) => {
    const {
        params: { organizationId, eventId, accreditationId },
        translate
    } = useHeavent();
    useTitle(props.accreditation.name);
    const getAccreditationParams = (isRouteComponent: boolean): AccreditationParams => ({
        organizationId: isRouteComponent ? ':organizationId' : organizationId,
        eventId: isRouteComponent ? ':eventId' : eventId,
        accreditationId: isRouteComponent ? ':accreditationId' : accreditationId
    });

    return (
        <Page.Root>
            <AccreditationHeader accreditation={props.accreditation} />

            <Tabs css={{ flex: '1' }}>
                <TabList css={{ px: '$7' }}>
                    <Tab
                        path={AccreditationsPaths.ACCREDITATION_DASHBOARD(
                            getAccreditationParams(false)
                        )}
                    >
                        {translate('tableau_de_bord_24627')}
                    </Tab>

                    <Tab
                        path={AccreditationsPaths.ACCREDITATION_MEMBERS(
                            getAccreditationParams(false)
                        )}
                    >
                        {translate('membres_125561', props.accreditation.assignedResources)}
                    </Tab>
                </TabList>

                <TabPanel
                    path={AccreditationsPaths.ACCREDITATION_DASHBOARD(getAccreditationParams(true))}
                >
                    <AccreditationDashboard />
                </TabPanel>

                <TabPanel
                    path={AccreditationsPaths.ACCREDITATION_MEMBERS(getAccreditationParams(true))}
                >
                    <AccreditationMembers />
                </TabPanel>
            </Tabs>
        </Page.Root>
    );
};
