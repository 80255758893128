import { CenteredContainer } from 'common-front/src/components/centeredContainer/centeredContainer';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import { Button } from 'common-front/src/designSystem/components/button';
import { EmptyFormBox, FormBox } from 'common-front/src/designSystem/components/formBox';
import { LabelOptional } from 'common-front/src/designSystem/components/input/labelOptional';
import { RichEditor } from 'common-front/src/designSystem/form/richEditor';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    CreateDelegationFormFragment,
    CreatePositionTagsFragment,
    CustomFieldWithConditionFragment,
    Delegation,
    DelegationId,
    DelegationInput,
    DelegationsCategory,
    EventAccreditationsFragment,
    EventId,
    OrganizationId,
    SegmentCustomFieldFragment
} from 'common/src/generated/types';
import { ICreateUpdateDelegationValues } from 'common/src/input/delegationInput';
import { isNonEmptyArray } from 'common/src/util/array';
import { isNonEmptyString } from 'common/src/util/string';
import { FormApi } from 'final-form';
import { DateTime } from 'luxon';
import * as React from 'react';
import { TagsForm } from '../../accreditations/create/tagsForm';
import { FormParametersGeneralCustomFields } from '../../forms/update/parameters/general/formParametersGeneralCustomFields';
import { CreatePositionCustomFields } from '../../positions/create/createPositionCustomFields';
import { AccreditationsSelectPanel } from './accreditations/accreditationsSelectPanel';
import { AccreditationsForm } from './accreditationsForm';
import {
    CreateEventDelegationInformations,
    CreateOrganizationDelegationInformations
} from './createDelegationInformations';
import { CreateDelegationParameters } from './createDelegationParameters';
import { CreateUpdateDelelgationSaveValidateModal } from './createUpdateDelelgationSaveValidateModal';
import { useDeletedAccreditationsSlots } from './useDeletedAccreditationsSlots';

type CreateUpdateDelegationBaseProps = {
    closePath: string;
    categories: Array<Pick<DelegationsCategory, 'id' | 'name'>>;
    customFields: SegmentCustomFieldFragment[];
    delegationCustomFields: CustomFieldWithConditionFragment[];
    forms: CreateDelegationFormFragment[];
    initialValues: ICreateUpdateDelegationValues;
    isEdit: boolean;
    tags: CreatePositionTagsFragment['tags']['nodes'];

    mutate(args: {
        organizationId: OrganizationId;
        eventId: EventId;
        delegation: DelegationInput;
    }): Promise<DelegationId>;
};

type CreateUpdateEventDelegationProps = CreateUpdateDelegationBaseProps & {
    accreditationsCategories: EventAccreditationsFragment['accreditationsCategories'];
    delegationType: 'event';
    eventStartAt: DateTime;
    eventEndAt: DateTime;
    organizationDelegations: Array<Pick<Delegation, 'id' | 'name'>>;
};

type CreateUpdateOrganizationDelegationProps = CreateUpdateDelegationBaseProps & {
    delegationType: 'organization';
};

export type CreateUpdateDelegationProps =
    | CreateUpdateEventDelegationProps
    | CreateUpdateOrganizationDelegationProps;

type CreateUpdateDelegationFormProps = CreateUpdateDelegationProps & {
    centeredContainerRef: React.Ref<HTMLDivElement>;
    form: FormApi<ICreateUpdateDelegationValues>;
    submitting: boolean;
    values: ICreateUpdateDelegationValues;

    handleSubmit(): void;
};

export const CreateUpdateDelegationForm = (props: CreateUpdateDelegationFormProps) => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();
    const isPrivateNoteOpen = React.useMemo(
        () =>
            isNonEmptyString(props.initialValues.delegation.description) ||
            isNonEmptyString(props.initialValues.delegation.privateNote),
        [props.initialValues]
    );
    const areAccreditationsOpen = React.useMemo(
        () => props.isEdit && isNonEmptyArray(props.initialValues.delegation.accreditationsSlots),
        [props.initialValues, props.isEdit]
    );
    const areFieldsOpen = React.useMemo(
        () => props.isEdit && isNonEmptyArray(Object.keys(props.initialValues.delegation.fields)),
        [props.initialValues, props.isEdit]
    );
    const areCustomFieldsOpen = React.useMemo(
        () => props.isEdit && isNonEmptyArray(props.initialValues.delegation.customFields),
        [props.initialValues, props.isEdit]
    );
    const areTagsOpen = React.useMemo(
        () => isNonEmptyArray(props.initialValues.delegation.tagsIds),
        [props.initialValues]
    );
    const deletedAccreditationsSlots = useDeletedAccreditationsSlots(
        props.delegationType === 'event' ? props.accreditationsCategories : [],
        props.initialValues,
        props.values
    );
    const [isAddAccreditationOpen, setIsAddAccreditationOpen] = React.useState(false);
    const [isSaveValidateModalOpen, setIsSaveValidateModalOpen] = React.useState(false);
    const handleSubmit = React.useCallback(() => {
        if (isNonEmptyArray(deletedAccreditationsSlots)) {
            setIsSaveValidateModalOpen(true);
        } else {
            props.handleSubmit();
        }
    }, [deletedAccreditationsSlots, setIsSaveValidateModalOpen, props.handleSubmit]);

    return (
        <>
            <FullScreenPopup
                button={
                    props.isEdit ? (
                        <Button isLoading={props.submitting} onClick={handleSubmit}>
                            {translate('mettre_jour_l_69948')}
                        </Button>
                    ) : null
                }
                category={
                    props.isEdit
                        ? translate('mise_jour_de_17055', props.initialValues.delegation.name)
                        : translate('cr_ation_d_une_07120')
                }
                color="dark"
                title={
                    props.isEdit
                        ? props.initialValues.delegation.name
                        : translate('nouvelle_d_l_ga_42871')
                }
            >
                <CenteredContainer ref={props.centeredContainerRef}>
                    <FormErrors />

                    <FormBox
                        hideToggle={true}
                        initialIsOpen={true}
                        title={translate('informations_su_34607')}
                    >
                        {props.delegationType === 'event' ? (
                            <CreateEventDelegationInformations
                                categories={props.categories}
                                change={props.form.change}
                                initialParentId={
                                    props.initialValues.delegation.parentId as DelegationId
                                }
                                leadersIds={props.values.delegation.leadersIds}
                                organizationDelegations={props.organizationDelegations}
                            />
                        ) : (
                            <CreateOrganizationDelegationInformations
                                categories={props.categories}
                                change={props.form.change}
                                leadersIds={props.values.delegation.leadersIds}
                            />
                        )}
                    </FormBox>

                    <Spacer height="5" />

                    <FormBox
                        hideToggle={true}
                        initialIsOpen={true}
                        title={translate('param_tres_de_l_81810')}
                    >
                        <CreateDelegationParameters
                            change={props.form.change}
                            customFields={props.customFields}
                            forms={props.forms}
                            initialValues={props.initialValues}
                            values={props.values}
                        />
                    </FormBox>

                    <Spacer height="5" />

                    {props.delegationType === 'event' && (
                        <>
                            <FormBox
                                initialIsOpen={areAccreditationsOpen}
                                subtitle={translate('delegation_accreditations_desc')}
                                title={translate('accr_ditations_39450')}
                                onToggle={(isOpen) => {
                                    if (!isOpen) {
                                        props.form.change('delegation.accreditationsSlots', []);
                                    }
                                }}
                            >
                                <AccreditationsForm
                                    allAccreditationCategories={props.accreditationsCategories}
                                    change={props.form.change}
                                    setIsAddAccreditationOpen={setIsAddAccreditationOpen}
                                    values={props.values.delegation}
                                />
                            </FormBox>

                            <Spacer height="5" />
                        </>
                    )}

                    <FormBox
                        initialIsOpen={areFieldsOpen}
                        subtitle={translate('ajouter_des_inf_57824')}
                        title={translate('champs_de_formu_64126')}
                        onToggle={(isOpen) => {
                            if (!isOpen) {
                                props.form.change('delegation.fields', {});
                            }
                        }}
                    >
                        <CreatePositionCustomFields
                            change={props.form.change}
                            customFields={props.delegationCustomFields}
                            prefix="delegation."
                        />
                    </FormBox>

                    <Spacer height="5" />

                    <FormBox
                        initialIsOpen={areCustomFieldsOpen}
                        subtitle={translate('choisissez_une_24029')}
                        title={translate('d_finir_la_vale_27863')}
                        onToggle={(isOpen) => {
                            if (!isOpen) {
                                props.form.change('delegation.customFields', []);
                            }
                        }}
                    >
                        <FormParametersGeneralCustomFields
                            change={props.form.change}
                            customFields={props.customFields}
                            name="delegation.customFields"
                            values={props.values.delegation.customFields}
                        />
                    </FormBox>

                    <Spacer height="5" />

                    <FormBox
                        initialIsOpen={isPrivateNoteOpen}
                        subtitle={translate('ajoutez_une_des_54307')}
                        title={translate('description_et_59312')}
                        onToggle={(isOpen) => {
                            if (!isOpen) {
                                props.form.change('delegation.description', '');
                                props.form.change('delegation.privateNote', '');
                            }
                        }}
                    >
                        <RichEditor
                            label={<LabelOptional>{translate('description_58935')}</LabelOptional>}
                            name="delegation.description"
                        />

                        <Spacer height="6" />

                        <RichEditor
                            label={<LabelOptional>{translate('note_priv_e_06303')}</LabelOptional>}
                            name="delegation.privateNote"
                        />
                    </FormBox>

                    <Spacer height="5" />

                    <FormBox
                        initialIsOpen={areTagsOpen}
                        subtitle={translate('ajouter_des_tag_35833')}
                        title={translate('tags_79499')}
                        onToggle={(isOpen) => {
                            if (!isOpen) {
                                props.form.change('delegation.tagsIds', []);
                            }
                        }}
                    >
                        <TagsForm
                            change={props.form.change}
                            name="delegation."
                            organizationId={organizationId}
                            tags={props.tags}
                            tagsIds={props.values.delegation.tagsIds}
                        />
                    </FormBox>

                    <Spacer height="5" />

                    <EmptyFormBox>
                        <Button
                            isLoading={props.submitting}
                            textAlign="center"
                            onClick={handleSubmit}
                        >
                            {props.isEdit
                                ? translate('mettre_jour_l_69948')
                                : translate('cr_er_la_d_l_ga_53268')}
                        </Button>
                    </EmptyFormBox>
                </CenteredContainer>
            </FullScreenPopup>

            {props.delegationType === 'event' && isAddAccreditationOpen && (
                <AccreditationsSelectPanel
                    allAccreditationCategories={props.accreditationsCategories}
                    change={props.form.change}
                    eventEndAt={props.eventEndAt}
                    eventStartAt={props.eventStartAt}
                    setIsAddAccreditationOpen={setIsAddAccreditationOpen}
                    values={props.values.delegation}
                />
            )}

            <CreateUpdateDelelgationSaveValidateModal
                deletedAccreditationsSlots={deletedAccreditationsSlots}
                handleSubmit={props.handleSubmit}
                isOpen={isSaveValidateModalOpen}
                submitting={props.submitting}
                onClose={() => {
                    setIsSaveValidateModalOpen(false);
                }}
            />
        </>
    );
};
