import { sortBy, uniqBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { getBarcodeHref } from '../../../util/url';

export const CerclePressAndPartenaire = (props: { event: DocumentUserCustomBadgeFragment }) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const delegation = vr.delegation;
    const days = sortBy(
        uniqBy(
            ui.formsUsersInfos.flatMap((fui) =>
                fui.slots.map((s) => s.range.start!.startOf('day'))
            ),
            (d) => d.toMillis()
        ) /*.concat([
            DateTime.fromObject({ year: 2025, month: 1, day: 3 }),
            DateTime.fromObject({ year: 2025, month: 1, day: 4 })
        ])*/,
        (d) => d.toMillis()
    );

    return (
        <>
            <Flex
                css={{
                    background: `url(https://assets.recrewteer.com/badges/cercle/fdr_press_et_partenaire_1.jpg) no-repeat`,
                    backgroundSize: 'cover',
                    position: 'relative'
                }}
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
            >
                <Box
                    css={{
                        fontFamily: '$kabel',
                        fontSize: '20px',
                        fontWeight: '700',
                        left: '46px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '306px',
                        width: '582px'
                    }}
                >
                    {ui.name}
                </Box>

                <Box
                    css={{
                        color: '#1e3159',
                        fontSize: '17px',
                        fontWeight: '600',
                        left: '46px',
                        position: 'absolute',
                        top: '338px',
                        width: '582px'
                    }}
                >
                    Nom du responsable : {delegation?.leaders?.[0]?.name}{' '}
                    {delegation?.leaders?.[0]?.phone?.internationalFormat}
                </Box>

                <Box
                    css={{
                        left: '658px',
                        position: 'absolute',
                        top: '288px'
                    }}
                    height={90}
                    width={90}
                >
                    <img
                        height="100%"
                        src={getBarcodeHref(vr.ticketId, { height: 90, width: 90 })}
                        width="100%"
                    />
                </Box>

                <Flex
                    css={{
                        gap: '6px',
                        left: '46px',
                        position: 'absolute',
                        top: '641px'
                    }}
                >
                    {days.map((d, index) => (
                        <Flex
                            key={index}
                            align="center"
                            css={{
                                border: '1px solid black',
                                borderRadius: '$1',
                                fontFamily: '$montserrat',
                                padding: '4px 6px'
                            }}
                            direction="column"
                            justify="center"
                        >
                            <Box css={{ fontSize: '16px', fontWeight: '700' }}>
                                {d.toFormat('dd')}
                            </Box>
                            <Box css={{ fontSize: '10px', fontWeight: '500' }}>
                                {d.toFormat('MMM', { locale: 'fr' })}
                            </Box>
                        </Flex>
                    ))}
                </Flex>
            </Flex>

            <Flex
                css={{
                    background:
                        'url(https://assets.recrewteer.com/badges/cercle/fdr_press_et_partenaire_2.jpg) no-repeat',
                    backgroundSize: 'cover',
                    pageBreakBefore: 'always'
                }}
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
            />

            <Flex
                css={{
                    background:
                        'url(https://assets.recrewteer.com/badges/cercle/fdr_press_et_partenaire_3.jpg) no-repeat',
                    backgroundSize: 'cover',
                    pageBreakBefore: 'always'
                }}
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
            />
        </>
    );
};
