import { CenteredContainer } from 'common-front/src/components/centeredContainer/centeredContainer';
import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import { Button } from 'common-front/src/designSystem/components/button';
import { EmptyFormBox, FormBox } from 'common-front/src/designSystem/components/formBox';
import { LabelOptional } from 'common-front/src/designSystem/components/input/labelOptional';
import { RichEditor } from 'common-front/src/designSystem/form/richEditor';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    AccreditationId,
    AccreditationInput,
    AccreditationsCategoryId,
    AccreditationsSlotInput,
    CreateAccreditationEventFragment,
    CreatePositionCustomFieldsFragment,
    CreatePositionTagsFragment,
    CustomFieldWithConditionFragment,
    EventId
} from 'common/src/generated/types';
import {
    AccreditationInputService,
    ICreateUpdateAccreditationValues
} from 'common/src/input/accreditationInput';
import { ValidateService } from 'common/src/services/validateService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useParams, useService } from 'common/src/util/dependencies/dependencies';
import { DEFAULT_ZOOM } from 'common/src/util/map';
import { AccreditationsPaths } from 'common/src/util/paths/accreditationsPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { omit } from 'lodash-es';
import * as React from 'react';
import {
    useAccreditationCreateMutation,
    useAccreditationEventInfosQuery,
    useAccreditationToEditQuery,
    useAccreditationUpdateMutation
} from '../../generated/graphqlHooks';
import { CreatePositionCustomFields } from '../../positions/create/createPositionCustomFields';
import { ConditionsForm } from './conditionsForm';
import { CreateAccreditationAutoAccredit } from './createAccreditationAutoAccredit';
import { CreateAccreditationInformations } from './createAccreditationInformations';
import { CreateAccreditationSlots } from './createAccreditationSlots';
import { CreateAccreditationWeezaccess } from './createAccreditationWeezaccess';
import { LocationForm } from './locationForm';
import { TagsForm } from './tagsForm';

interface ICreateUpdateAccreditationProps {
    accreditationCustomFields: CustomFieldWithConditionFragment[];
    customFields: CreatePositionCustomFieldsFragment['customFields'];
    event: CreateAccreditationEventFragment;
    initialValues: ICreateUpdateAccreditationValues;
    isEdit: boolean;
    tags: CreatePositionTagsFragment['tags']['nodes'];

    mutate(args: { eventId: EventId; accreditation: AccreditationInput }): Promise<AccreditationId>;
}

const CreateUpdateAccreditation = (props: ICreateUpdateAccreditationProps) => {
    const {
        history,
        params: { organizationId, eventId },
        translate
    } = useHeavent();
    const accreditationInput = useService(AccreditationInputService);
    const validateService = useService(ValidateService);
    const centeredContainerRef = React.useRef<HTMLDivElement | null>(null);
    const category = React.useMemo(
        () =>
            props.isEdit
                ? translate('mise_jour_de_17055', props.initialValues.accreditation.name)
                : translate('cr_ation_d_une_01473'),
        [props.isEdit, props.initialValues]
    );
    useTitle(category);
    const {
        areSlotsOpen,
        isLocalisationOpen,
        areConditionsOpen,
        areCustomFieldsOpen,
        isPrivateNoteOpen,
        areTagsOpen
    } = React.useMemo(() => {
        const initialAccreditation = props.initialValues.accreditation;

        return {
            areSlotsOpen: props.isEdit && isNonEmptyArray(initialAccreditation.slots),
            isLocalisationOpen:
                props.isEdit &&
                (isNonEmptyString(initialAccreditation.address) ||
                    typeof initialAccreditation.latitude === 'number' ||
                    typeof initialAccreditation.longitude === 'number'),
            areConditionsOpen:
                props.isEdit && isNonEmptyArray(initialAccreditation.accreditationsCustomFields),
            areCustomFieldsOpen:
                props.isEdit && isNonEmptyArray(Object.keys(initialAccreditation.fields)),
            isPrivateNoteOpen: props.isEdit && isNonEmptyString(initialAccreditation.privateNote),
            areTagsOpen: props.isEdit && isNonEmptyArray(initialAccreditation.tagsIds)
        };
    }, [props.initialValues, props.isEdit]);

    return (
        <Form
            height={1}
            hideErrors={true}
            initialValues={props.initialValues}
            render={({ form, handleSubmit, submitting, values }) => (
                <FullScreenPopup
                    button={
                        props.isEdit ? (
                            <Button isLoading={submitting} onClick={handleSubmit}>
                                {translate('mettre_jour_l_92576')}
                            </Button>
                        ) : null
                    }
                    category={category}
                    color="dark"
                    title={
                        props.isEdit
                            ? props.initialValues.accreditation.name
                            : translate('nouvelle_accr_d_32707')
                    }
                >
                    <CenteredContainer ref={centeredContainerRef}>
                        <FormErrors />

                        <FormBox
                            hideToggle={true}
                            initialIsOpen={true}
                            title={translate('informations_su_35382')}
                        >
                            <CreateAccreditationInformations
                                change={form.change}
                                event={props.event}
                            />
                        </FormBox>

                        <Spacer height="5" />

                        <FormBox
                            hideToggle={true}
                            initialIsOpen={true}
                            subtitle={translate('attribuer_autom_55909')}
                            title={translate('automatisation_47772')}
                        >
                            <CreateAccreditationAutoAccredit />
                        </FormBox>

                        <Spacer height="5" />

                        {props.event.isWeezeventConnected && (
                            <>
                                <FormBox
                                    initialIsOpen={values.accreditation.weezeventIsSynchronized}
                                    subtitle={translate('d_finir_les_opt_34833')}
                                    title={translate('synchronisation_80488')}
                                    onToggle={(isOpen) => {
                                        form.change(
                                            'accreditation.weezeventIsSynchronized',
                                            isOpen
                                        );
                                    }}
                                >
                                    <CreateAccreditationWeezaccess
                                        change={form.change}
                                        event={props.event}
                                        values={values}
                                    />
                                </FormBox>

                                <Spacer height="5" />
                            </>
                        )}

                        <FormBox
                            initialIsOpen={areSlotsOpen}
                            subtitle={translate('ajouter_les_dif_76832')}
                            title={translate('cr_neaux_33401')}
                            onToggle={(isOpen) => {
                                if (!isOpen) {
                                    form.change('accreditation.slots', []);
                                }
                            }}
                        >
                            <CreateAccreditationSlots
                                change={form.change}
                                event={props.event}
                                values={values}
                            />
                        </FormBox>

                        <Spacer height="5" />

                        <FormBox
                            initialIsOpen={isLocalisationOpen}
                            subtitle={translate('indiquez_la_loc_61935')}
                            title={translate('localisation_38416')}
                            onToggle={(isOpen) => {
                                if (!isOpen) {
                                    form.change('accreditation.layer', props.event.layer);
                                    form.change('accreditation.zoom', DEFAULT_ZOOM);
                                    form.change('accreditation.address', null);
                                    form.change('accreditation.latitude', null);
                                    form.change('accreditation.longitude', null);
                                }
                            }}
                        >
                            <LocationForm
                                center={null}
                                layer={values.accreditation.layer}
                                name="accreditation."
                                point={values.accreditation}
                                traces={[]}
                                tracesIds={[]}
                            />
                        </FormBox>

                        <Spacer height="5" />

                        <FormBox
                            initialIsOpen={areConditionsOpen}
                            subtitle={translate('ajoutez_des_con_94296')}
                            title={translate('conditions_77756')}
                            onToggle={(isOpen) => {
                                if (!isOpen) {
                                    form.change('accreditation.accreditationsCustomFields', []);
                                }
                            }}
                        >
                            <ConditionsForm
                                change={form.change}
                                conditionsOperator={values.accreditation.conditionsOperator}
                                customFields={props.customFields}
                                customFieldsValues={values.accreditation.accreditationsCustomFields}
                                name="accreditation.accreditationsCustomFields"
                                prefix="accreditation."
                            />
                        </FormBox>

                        <Spacer height="5" />

                        <FormBox
                            initialIsOpen={areCustomFieldsOpen}
                            subtitle={translate('ajouter_des_inf_30856')}
                            title={translate('champs_de_formu_64126')}
                            onToggle={(isOpen) => {
                                if (!isOpen) {
                                    form.change('accreditation.fields', {});
                                }
                            }}
                        >
                            <CreatePositionCustomFields
                                change={form.change}
                                customFields={props.accreditationCustomFields}
                                prefix="accreditation."
                            />
                        </FormBox>

                        <Spacer height="5" />

                        <FormBox
                            initialIsOpen={isPrivateNoteOpen}
                            subtitle={translate('ajoutez_une_not_89122')}
                            title={translate('note_priv_e_06303')}
                            onToggle={(isOpen) => {
                                if (!isOpen) {
                                    form.change('accreditation.privateNote', '');
                                }
                            }}
                        >
                            <RichEditor
                                label={
                                    <LabelOptional>{translate('note_priv_e_06303')}</LabelOptional>
                                }
                                name="accreditation.privateNote"
                            />
                        </FormBox>

                        <Spacer height="5" />

                        <FormBox
                            initialIsOpen={areTagsOpen}
                            subtitle={translate('ajouter_des_tag_77298')}
                            title={translate('tags_79499')}
                            onToggle={(isOpen) => {
                                if (!isOpen) {
                                    form.change('accreditation.tagsIds', []);
                                }
                            }}
                        >
                            <TagsForm
                                change={form.change}
                                name="accreditation."
                                organizationId={organizationId}
                                tags={props.tags}
                                tagsIds={values.accreditation.tagsIds}
                            />
                        </FormBox>

                        <Spacer height="5" />

                        <EmptyFormBox>
                            <Button isLoading={submitting} onClick={handleSubmit}>
                                <Box textAlign="center">
                                    {props.isEdit
                                        ? translate('mettre_jour_l_92576')
                                        : translate('cr_er_l_accr_di_16123')}
                                </Box>
                            </Button>
                        </EmptyFormBox>
                    </CenteredContainer>
                </FullScreenPopup>
            )}
            validate={validateService.validateForForm(
                accreditationInput.createUpdateAccreditationSchema()
            )}
            width={1}
            onShowErrors={() => {
                if (centeredContainerRef.current) {
                    centeredContainerRef.current.scrollTop = 0;
                }
            }}
            onSubmit={async (values: ICreateUpdateAccreditationValues) => {
                const id = await props.mutate({
                    eventId,
                    accreditation: {
                        ...values.accreditation,
                        slots: values.accreditation.slots.map(
                            (slot) => omit(slot, ['index']) as AccreditationsSlotInput
                        )
                    }
                });

                if (props.isEdit) {
                    history.goBack(
                        AccreditationsPaths.ACCREDITATION({
                            organizationId,
                            eventId,
                            accreditationId: id
                        })
                    );
                } else {
                    history.replace(
                        AccreditationsPaths.ACCREDITATION({
                            organizationId,
                            eventId,
                            accreditationId: id
                        })
                    );
                }
            }}
        />
    );
};

export const CreateAccreditation = () => {
    const { organizationId, eventId, options } = useParams<{ options: string }>();
    const accreditationInput = useService(AccreditationInputService);
    const { data, loader } = useAccreditationEventInfosQuery({ organizationId, eventId });
    const { mutate } = useAccreditationCreateMutation();
    const accreditationCategoryId = isNonEmptyString(options)
        ? (parseInt(options, 10) as AccreditationsCategoryId)
        : null;

    return (
        loader || (
            <CreateUpdateAccreditation
                accreditationCustomFields={data.organization.accreditationCustomFields.nodes}
                customFields={data.organization.customFields}
                event={data.event}
                initialValues={{
                    accreditation: accreditationInput.accreditationInputDefault(
                        data.event.layer,
                        null,
                        accreditationCategoryId
                    )
                }}
                isEdit={false}
                mutate={async (values) => (await mutate(values)).accreditationCreate.id}
                tags={data.organization.tags.nodes}
            />
        )
    );
};

export const UpdateAccreditation = () => {
    const { organizationId, eventId, accreditationId } = useParams();
    const accreditationInput = useService(AccreditationInputService);
    const { data, loader } = useAccreditationToEditQuery({
        organizationId,
        eventId,
        accreditationId
    });
    const { mutate } = useAccreditationUpdateMutation();

    return (
        loader || (
            <CreateUpdateAccreditation
                accreditationCustomFields={data.organization.accreditationCustomFields.nodes}
                customFields={data.organization.customFields}
                event={data.event}
                initialValues={{
                    accreditation: accreditationInput.accreditationInputDefault(
                        data.event.layer,
                        data.event.accreditation,
                        null
                    )
                }}
                isEdit={true}
                mutate={async (values) =>
                    (
                        await mutate({
                            ...values,
                            accreditationId
                        })
                    ).accreditationUpdate.id
                }
                tags={data.organization.tags.nodes}
            />
        )
    );
};
