import { ColorInput } from 'common-front/src/designSystem/components/colorInput';
import { FileInput, FileInputValue } from 'common-front/src/designSystem/components/file/fileInput';
import { RichEditor } from 'common-front/src/designSystem/components/richEditor/richEditor';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { ToggleText } from 'common-front/src/designSystem/components/toggle';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Accept } from 'common-front/src/util/accept';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { UserAssignments } from 'common/src/documents/userAssignments/userAssignments';
import { DocumentType, UserAssignmentsDocumentQuery } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { usePossibleFontsOptions } from 'common/src/util/font';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { getDocumentHref } from 'common/src/util/url';
import {
    getDefaultFileOptions,
    getDefaultUserAssignmentsOptions
} from 'common/src/vo/eventDocument';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { useUserAssignmentsDocumentQuery } from '../../generated/graphqlHooks';
import { DocumentHeader } from './documentHeader';
import { DocumentOptions, useDocumentOptions } from './documentOptions';
import { DocumentsAssignedUserSelector } from './documentsAssignedUserSelector';
import { DocumentShadow } from './documentShadow';

interface IUserAssignmentsContentProps {
    data: UserAssignmentsDocumentQuery;
}

const UserAssignmentsContent = (props: IUserAssignmentsContentProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const [options, setOptions] = useDocumentOptions(
        props.data.event.document.options,
        getDefaultUserAssignmentsOptions(),
        eventId,
        props.data.event.document.id
    );
    const fontsOptions = usePossibleFontsOptions();
    const customFields = React.useMemo(
        () => sortBy(props.data.organization.customFields.nodes, (cf) => cf.name.toLowerCase()),
        [props.data]
    );
    const ui = props.data.organization.userInfo;

    return (
        <Flex css={{ flex: '1', overflow: 'hidden' }} gap="7" width={1}>
            <DocumentOptions>
                <Flex align="center" gap="3">
                    <FileInput
                        accept={Accept.Images}
                        format="base64"
                        label={translate('fond_du_documen_51758')}
                        placeholder={translate('fond_du_documen_51758')}
                        value={options.background}
                        onChange={(background: FileInputValue) => {
                            setOptions({ background });
                        }}
                    />

                    {isNonEmptyString(options.background?.name) && (
                        <Box
                            css={{ cursor: 'pointer' }}
                            font="gray700 textSm medium"
                            onClick={() => {
                                setOptions({ background: getDefaultFileOptions() });
                            }}
                        >
                            {translate('r_initialiser_77538')}
                        </Box>
                    )}
                </Flex>

                <TextInput
                    label={translate('titre_du_docume_33508')}
                    value={options.title}
                    onChange={(title) => {
                        setOptions({ title });
                    }}
                />

                <Separator direction="horizontal" />

                <Select
                    label="Police"
                    value={options.fontFamily}
                    onChange={(fontFamily) => {
                        setOptions({ fontFamily });
                    }}
                >
                    {fontsOptions}
                </Select>

                <Select
                    label={translate('police_des_titr_37497')}
                    value={options.titleFontFamily}
                    onChange={(titleFontFamily) => {
                        setOptions({ titleFontFamily });
                    }}
                >
                    {fontsOptions}
                </Select>

                <ColorInput
                    label={translate('couleur_du_titr_08509')}
                    value={options.titleColor}
                    onChange={(titleColor: string) => {
                        setOptions({ titleColor });
                    }}
                />

                <ColorInput
                    label={translate('couleur_des_sou_18968')}
                    value={options.subtitleColor}
                    onChange={(subtitleColor: string) => {
                        setOptions({ subtitleColor });
                    }}
                />

                <ColorInput
                    label={translate('couleur_du_text_32021')}
                    value={options.textColor}
                    onChange={(textColor: string) => {
                        setOptions({ textColor });
                    }}
                />

                <ColorInput
                    label={translate('couleur_du_cont_17150')}
                    value={options.headerColor}
                    onChange={(headerColor: string) => {
                        setOptions({ headerColor });
                    }}
                />

                <Separator direction="horizontal" />

                <ToggleText
                    value={options.showInformations}
                    onChange={(showInformations) => {
                        setOptions({ showInformations });
                    }}
                >
                    {translate('afficher_la_sec_84744')}
                </ToggleText>

                {options.showInformations && (
                    <Flex
                        css={{
                            background: '$gray50',
                            border: '1px solid $gray200',
                            borderRadius: '$1',
                            padding: '$4'
                        }}
                        direction="column"
                        gap="3"
                    >
                        <TextInput
                            label={translate('titre_de_la_sec_41575')}
                            value={options.informationsTitle}
                            onChange={(informationsTitle) => {
                                setOptions({ informationsTitle });
                            }}
                        />

                        <RichEditor
                            initialValue={options.informationsContent}
                            label={translate('contenu_de_la_s_43102')}
                            onChange={(informationsContent) => {
                                setOptions({ informationsContent });
                            }}
                        />
                    </Flex>
                )}

                <ToggleText
                    value={options.showInformations2}
                    onChange={(showInformations2) => {
                        setOptions({ showInformations2 });
                    }}
                >
                    {translate('afficher_la_sec_67554')}
                </ToggleText>

                {options.showInformations2 && (
                    <Flex
                        css={{
                            background: '$gray50',
                            border: '1px solid $gray200',
                            borderRadius: '$1',
                            padding: '$4'
                        }}
                        direction="column"
                        gap="3"
                    >
                        <TextInput
                            label={translate('titre_de_la_sec_41575')}
                            value={options.informationsTitle2}
                            onChange={(informationsTitle2) => {
                                setOptions({ informationsTitle2 });
                            }}
                        />

                        <RichEditor
                            initialValue={options.informationsContent2}
                            label={translate('contenu_de_la_s_43102')}
                            onChange={(informationsContent2) => {
                                setOptions({ informationsContent2 });
                            }}
                        />
                    </Flex>
                )}

                <ToggleText
                    value={options.showQrCode}
                    onChange={(showQrCode) => {
                        setOptions({ showQrCode });
                    }}
                >
                    {translate('afficher_le_qr_15635')}
                </ToggleText>

                <ToggleText
                    value={options.showLeaders}
                    onChange={(showLeaders) => {
                        setOptions({ showLeaders });
                    }}
                >
                    {translate('afficher_les_re_08705')}
                </ToggleText>

                {options.showLeaders && (
                    <Flex
                        css={{
                            background: '$gray50',
                            border: '1px solid $gray200',
                            borderRadius: '$1',
                            padding: '$4'
                        }}
                        direction="column"
                        gap="3"
                    >
                        <Select
                            label={translate('donn_es_des_res_45809')}
                            value={options.leadersData}
                            onChange={(newLeadersData) => {
                                setOptions({ leadersData: newLeadersData });
                            }}
                        >
                            <option value="both">{translate('afficher_le_num_37296')}</option>
                            <option value="phone">{translate('afficher_unique_41829')}</option>
                            <option value="email">{translate('afficher_unique_97964')}</option>
                            <option value="none">{translate('afficher_aucune_38167')}</option>
                        </Select>
                    </Flex>
                )}

                <Separator direction="horizontal" />

                <RichEditor
                    initialValue={options.signature}
                    label={translate('signature_67521')}
                    onChange={(signature) => {
                        setOptions({ signature });
                    }}
                />

                {isNonEmptyArray(customFields) && (
                    <RichSelect
                        label={translate('liste_des_champ_40847')}
                        multiple={true}
                        values={options.positionCustomFields}
                        onChange={(positionCustomFields) => {
                            setOptions({ positionCustomFields });
                        }}
                    >
                        {customFields.map((cf) => (
                            <option key={cf.id} value={cf.id}>
                                {cf.name}
                            </option>
                        ))}
                    </RichSelect>
                )}
            </DocumentOptions>

            <Flex css={{ flex: '1' }} direction="column">
                <DocumentsAssignedUserSelector
                    downloadPath={getDocumentHref(ui.links?.userAssignmentsLink ?? '', 'pdf')}
                    getPath={(userInfoId) =>
                        HeaventPaths.DOCUMENTS_ASSIGNMENTS(organizationId, eventId, userInfoId)
                    }
                />

                <DocumentShadow>
                    <UserAssignments assignmentsInfos={props.data} options={options} />
                </DocumentShadow>
            </Flex>
        </Flex>
    );
};

export const UserAssignmentsDocument = () => {
    const {
        translate,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const { data, loader } = useUserAssignmentsDocumentQuery({
        organizationId,
        eventId,
        userInfoId
    });

    return (
        <Flex css={{ padding: '$7' }} direction="column" height={1} width={1}>
            <DocumentHeader
                documentType={DocumentType.Assignments}
                endAt={data.event?.endAt}
                eventId={eventId}
                languages={data.event?.languages ?? []}
                organizationId={organizationId}
                segmentsFolders={data.event?.segmentsFolders ?? []}
                showDate={true}
                startAt={data.event?.startAt}
                title={translate('fiches_d_affect_98583')}
            />

            <Spacer height="7" />

            {loader || <UserAssignmentsContent data={data} />}
        </Flex>
    );
};
