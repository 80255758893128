import {
    EventId,
    FormsUsersInfosWishedFragment,
    OrganizationId,
    PositionId,
    PositionsCategoryId,
    PositionsSlotsUsersInfoId,
    UserPositionSlotUserInfoFragment,
    VolunteerRegistrationFragment
} from 'common/src/generated/types';
import { sortBy, uniqBy } from 'lodash-es';
import * as React from 'react';
import {
    executePositionSlotUserInfoQuery,
    useUserPositionsQuery
} from '../../generated/graphqlHooks';
import { getToken } from '../../util/aws/cognito';

interface IPositionsRightPanelContext {
    assignedCategories: Array<UserPositionSlotUserInfoFragment['positionCategory']>;
    assignedPsuis: UserPositionSlotUserInfoFragment[];
    canCheckIn: boolean | ((categoryId: PositionsCategoryId, positionId: PositionId) => boolean);
    canDelete: (categoryId: PositionsCategoryId, positionId: PositionId) => boolean;
    formsUsersInfos: FormsUsersInfosWishedFragment['formsUsersInfos'];

    onAssignedPsuiDelete(id: PositionsSlotsUsersInfoId): void;
    reloadCheckIn(id: PositionsSlotsUsersInfoId): void;
}

const PositionsRightPanelContext = React.createContext<IPositionsRightPanelContext>({} as any);

interface IPositionsRightPanelContextProviderProps {
    canCheckIn: boolean | ((categoryId: PositionsCategoryId, positionId: PositionId) => boolean);
    canDelete: (categoryId: PositionsCategoryId, positionId: PositionId) => boolean;
    children: React.ReactNode;
    eventId: EventId;
    organizationId: OrganizationId;
    volunteerRegistration: VolunteerRegistrationFragment;
}

export const PositionsRightPanelContextProvider = (
    props: IPositionsRightPanelContextProviderProps
) => {
    const { data } = useUserPositionsQuery({
        organizationId: props.organizationId,
        eventId: props.eventId,
        userInfoId: props.volunteerRegistration.userInfo.id
    });
    const [assignedPsuis, setAssignedPsuis] = React.useState(
        props.volunteerRegistration.positionsSlotsUsersInfos
    );
    const assignedCategories = React.useMemo(
        () =>
            sortBy(
                uniqBy(
                    assignedPsuis.map((psui) => psui.positionCategory),
                    (c) => c.id
                ),
                (c) => c.name.toLowerCase()
            ),
        [assignedPsuis]
    );
    const onAssignedPsuiDelete = React.useCallback(
        (id: PositionsSlotsUsersInfoId) => {
            setAssignedPsuis((psuis) => psuis.filter((psui) => psui.id !== id));
        },
        [setAssignedPsuis]
    );
    const reloadCheckIn = React.useCallback(
        async (id: PositionsSlotsUsersInfoId) => {
            const psui = assignedPsuis.find((assignedPsui) => assignedPsui.id === id)!;

            const {
                event: {
                    volunteerRegistration: { positionSlotUserInfo }
                }
            } = await executePositionSlotUserInfoQuery(
                {
                    eventId: props.eventId,
                    volunteerRegistrationId: props.volunteerRegistration.id,
                    positionSlotId: psui.positionSlot.id
                },
                await getToken()
            );

            setAssignedPsuis((psuis) =>
                psuis.map((assignedPsui) =>
                    assignedPsui.id === id ? positionSlotUserInfo! : assignedPsui
                )
            );
        },
        [props.eventId, props.volunteerRegistration, assignedPsuis, setAssignedPsuis]
    );

    return (
        <PositionsRightPanelContext.Provider
            value={{
                assignedCategories,
                assignedPsuis,
                canCheckIn: props.canCheckIn,
                canDelete: props.canDelete,
                formsUsersInfos: data.organization?.userInfo.formsUsersInfos ?? [],
                onAssignedPsuiDelete,
                reloadCheckIn
            }}
        >
            {props.children}
        </PositionsRightPanelContext.Provider>
    );
};

export function usePositionsRightPanelContext() {
    return React.useContext(PositionsRightPanelContext);
}
