import { Blank } from 'common-front/src/components/blank/blank';
import { Button } from 'common-front/src/designSystem/components/button';
import { ButtonDropdown } from 'common-front/src/designSystem/components/buttonDropdown';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Svgs } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    FormCustomFieldFragment,
    FormElementsElementFragment,
    FormElementType
} from 'common/src/generated/types';
import { reorder } from 'common/src/util/array';
import { uuidv4 } from 'common/src/util/uuid';
import { produce } from 'immer';
import * as React from 'react';
import { CreateCustomField } from '../../../customFields/create/createUpdateCustomField';
import { AddFieldsMenu } from './addFieldsMenu';
import { AddSectionModal } from './addSectionModal';
import { AddTextModal } from './addTextModal';
import { FormElementsList } from './formElementsList';

interface FormElementsContent {
    customFields: FormCustomFieldFragment[];
    elements: FormElementsElementFragment[];
    setElements: React.Dispatch<React.SetStateAction<FormElementsElementFragment[]>>;

    onSave(): Promise<any>;
}

export const FormElementsContent = (props: FormElementsContent) => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();
    const [addSectionVisible, setAddSectionVisible] = React.useState(false);
    const [addTextVisible, setAddTextVisible] = React.useState(false);
    const [isCreatecustomFieldOpen, setIsCreatecustomFieldOpen] = React.useState(false);
    const [customFields, setCustomFields] = React.useState(props.customFields);
    const selectedCustomFieldsIds = React.useMemo(
        () =>
            new Set(
                props.elements.flatMap((element) => {
                    if (element.elementType === FormElementType.Field) {
                        return [element.customFieldId!];
                    } else {
                        return [];
                    }
                })
            ),
        [props.elements]
    );
    const onDragEnd = React.useCallback(
        ({ reason, source, destination }) => {
            if (reason === 'DROP' && destination && source.index !== destination.index) {
                props.setElements(reorder(props.elements, source.index, destination.index));
            }
        },
        [props.elements, props.setElements]
    );
    const fieldToggle = React.useCallback(
        (field: FormCustomFieldFragment) => {
            if (selectedCustomFieldsIds.has(field.id)) {
                props.setElements(
                    props.elements.filter((e) => !e.customFieldId || e.customFieldId !== field.id)
                );
            } else {
                props.setElements([
                    ...props.elements,
                    {
                        id: uuidv4(),
                        elementType: FormElementType.Field,
                        customFieldId: field.id,
                        isMandatory: true
                    }
                ]);
            }
        },
        [props.elements, props.setElements, selectedCustomFieldsIds]
    );
    const fieldsToggleMandatory = React.useCallback(
        (index: number, isMandatory: boolean) => {
            props.setElements(
                produce((currentElements) => {
                    currentElements[index].isMandatory = isMandatory;
                })
            );
        },
        [props.setElements]
    );
    const sectionOnAdd = React.useCallback(
        (name: string) => {
            props.setElements([
                ...props.elements,
                {
                    id: uuidv4(),
                    elementType: FormElementType.Section,
                    section: name
                }
            ]);
        },
        [props.elements, props.setElements]
    );
    const sectionOnRename = React.useCallback(
        (index: number, name: string) => {
            props.setElements(
                produce((currentElements) => {
                    currentElements[index].section = name;
                })
            );
        },
        [props.setElements]
    );
    const textOnAdd = React.useCallback(
        (text: string) => {
            props.setElements([
                ...props.elements,
                {
                    id: uuidv4(),
                    elementType: FormElementType.Text,
                    text
                }
            ]);
        },
        [props.elements, props.setElements]
    );
    const textOnEdit = React.useCallback(
        (index: number, text: string) => {
            props.setElements(
                produce((currentElements) => {
                    currentElements[index].text = text;
                })
            );
        },
        [props.setElements]
    );
    const onDelete = React.useCallback(
        (index: number) => {
            props.setElements(
                produce((currentElements) => {
                    currentElements.splice(index, 1);
                })
            );
        },
        [props.setElements]
    );
    const updateCustomField = React.useCallback(
        (customField: FormCustomFieldFragment) => {
            setCustomFields(
                customFields.map((cf) => (cf.id === customField.id ? customField : cf))
            );
        },
        [customFields]
    );

    return (
        <>
            {props.elements.length === 0 ? (
                <Box height={0.5} width={1}>
                    <Blank
                        imageSrc={Svgs.FormsElementsBlank}
                        subtitle={translate('ajouter_des_cha_37944')}
                        title={translate('aucun_champ_ou_18338')}
                    />
                </Box>
            ) : (
                <FormElementsList
                    customFields={customFields}
                    elements={props.elements}
                    fieldsToggleMandatory={fieldsToggleMandatory}
                    sectionOnRename={sectionOnRename}
                    textOnEdit={textOnEdit}
                    updateCustomField={updateCustomField}
                    onDelete={onDelete}
                    onDragEnd={onDragEnd}
                />
            )}

            <Spacer height="6" />

            <Flex gap="4" justify={props.elements.length === 0 ? 'center' : 'start'} width={1}>
                <Dropdown>
                    <Trigger>
                        <ButtonDropdown
                            menu={
                                <>
                                    <ItemIcon
                                        icon="pen-field"
                                        onClick={() => {
                                            setIsCreatecustomFieldOpen(true);
                                        }}
                                    >
                                        {translate('cr_eer_un_champ_08883')}
                                    </ItemIcon>
                                </>
                            }
                        >
                            {translate('ajouter_des_cha_35843')}
                        </ButtonDropdown>
                    </Trigger>

                    <Menu css={{ padding: '0' }} placement="top-start" width={400}>
                        <AddFieldsMenu
                            customFields={customFields}
                            selectedCustomFieldsIds={selectedCustomFieldsIds}
                            toggle={fieldToggle}
                        />
                    </Menu>
                </Dropdown>

                <Button
                    color="white"
                    onClick={() => {
                        setAddSectionVisible(true);
                    }}
                >
                    {translate('ajouter_une_sec_90063')}
                </Button>

                <Button
                    color="white"
                    onClick={() => {
                        setAddTextVisible(true);
                    }}
                >
                    {translate('ajouter_un_cham_31151')}
                </Button>
            </Flex>

            {addSectionVisible && (
                <AddSectionModal
                    initialName=""
                    onAdd={sectionOnAdd}
                    onClose={() => {
                        setAddSectionVisible(false);
                    }}
                />
            )}

            {addTextVisible && (
                <AddTextModal
                    initialText=""
                    onAdd={textOnAdd}
                    onClose={() => {
                        setAddTextVisible(false);
                    }}
                />
            )}

            {isCreatecustomFieldOpen && (
                <CreateCustomField
                    isFromForm={true}
                    organizationId={organizationId}
                    onClose={() => {
                        setIsCreatecustomFieldOpen(false);
                    }}
                    onSuccess={async (customField: FormCustomFieldFragment) => {
                        setCustomFields([...customFields, customField]);

                        setTimeout(() => {
                            fieldToggle(customField);
                            props.onSave();
                        });

                        setIsCreatecustomFieldOpen(false);
                    }}
                />
            )}
        </>
    );
};
