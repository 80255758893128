import { LinkText } from 'common/src/designSystem/components/linkText';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { VolunteerRegistrationFragment } from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import { getWeezTicketParticipantHref } from 'common/src/util/url';
import * as React from 'react';

interface IVolunteerRegistrationBarcodeCellProps {
    volunteerRegistration: VolunteerRegistrationFragment;
    weezevent: Emptyable<{
        eventId: number;
        organizationId: number;
    }>;
}

export const VolunteerRegistrationBarcodeCell = (props: IVolunteerRegistrationBarcodeCellProps) => {
    const ticketId =
        props.volunteerRegistration.weezeventParticipantInfo?.idBarcode ??
        props.volunteerRegistration.ticketId;
    const link = props.weezevent
        ? getWeezTicketParticipantHref(
              props.weezevent.organizationId,
              props.weezevent.eventId,
              props.volunteerRegistration.weezeventParticipantId,
              props.volunteerRegistration.ticketId
          )
        : null;

    return (
        <Cell>
            {link ? (
                <LinkText css={{ fontWeight: 'normal' }} to={link}>
                    {ticketId}
                </LinkText>
            ) : (
                ticketId
            )}
        </Cell>
    );
};
