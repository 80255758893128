import * as React from 'react';

const FONTS_WEIGHTS = `400,500,600,700,800,900`;

export const POSSIBLE_FONTS = [
    `Archivo`,
    `Arial`,
    `Arimo`,
    `Barlow`,
    `Bebas Neue`,
    `Chivo`,
    `Fira Sans`,
    `Georgia`,
    `IBM Plex Sans`,
    `Inter`,
    `Lato`,
    `Merriweather`,
    `Montserrat`,
    `Noto Sans`,
    `Nunito`,
    `Open Sans`,
    `Oswald`,
    `PT Sans`,
    `Playfair Display`,
    `Poppins`,
    `Raleway`,
    `Roboto`,
    `Rubik`
];

export function usePossibleFontsOptions() {
    return React.useMemo(
        () =>
            POSSIBLE_FONTS.map((font) => (
                <option key={font} value={font}>
                    {font}
                </option>
            )),
        []
    );
}

export function getPossibleFontsLinks() {
    return POSSIBLE_FONTS.map(
        (font) =>
            `<link href="https://fonts.googleapis.com/css?family=${font.replaceAll(' ', '+')}:${FONTS_WEIGHTS}" rel="stylesheet">`
    ).join(' ');
}
