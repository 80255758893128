import { CreateUpdateCategoryModal } from 'common-front/src/components/createUpdateCategoryModal';
import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { Button } from 'common-front/src/designSystem/components/button';
import { LabelOptional } from 'common-front/src/designSystem/components/input/labelOptional';
import { RightPanel } from 'common-front/src/designSystem/components/rightPanel/rightPanel';
import { RightPanelBody } from 'common-front/src/designSystem/components/rightPanel/rightPanelBody';
import { RightPanelFooter } from 'common-front/src/designSystem/components/rightPanel/rightPanelFooter';
import { RightPanelHeader } from 'common-front/src/designSystem/components/rightPanel/rightPanelHeader';
import { RightPanelLoader } from 'common-front/src/designSystem/components/rightPanel/rightPanelLoader';
import { HorizontalSpacerSeparator } from 'common-front/src/designSystem/components/separator';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { CheckboxText } from 'common-front/src/designSystem/form/checkbox';
import { FileS3Input } from 'common-front/src/designSystem/form/file/fileS3Input';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { Select } from 'common-front/src/designSystem/form/select/select';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { useCustomFieldOptions } from 'common-front/src/hooks/useCustomFieldOptions';
import { useEnumToOptions } from 'common-front/src/hooks/useEnumToOptions';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { Accept } from 'common-front/src/util/accept';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    CreateCustomFieldInfosFragment,
    CustomFieldId,
    CustomFieldInput,
    CustomFieldsCategoryId,
    CustomFieldVariety,
    FieldType,
    FormCustomFieldFragment,
    OrganizationId
} from 'common/src/generated/types';
import {
    CustomFieldInputService,
    ICreateUpdateCustomFieldValues
} from 'common/src/input/customFieldInput';
import { ValidateService } from 'common/src/services/validateService';
import { useParams, useService } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { CREATABLE_FIELD_TYPES } from 'common/src/vo/customField';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { OnChange } from 'react-final-form-listeners';
import {
    useCustomFieldCategoryCreateMutation,
    useCustomFieldCreateMutation,
    useCustomFieldToEditQuery,
    useCustomFieldUpdateMutation,
    useOrganizationInfosQuery
} from '../../generated/graphqlHooks';
import { CreateUpdateSelectValues } from './createUpdateSelectValues';

interface ICreateUpdateCustomFieldProps {
    customFieldId: Emptyable<CustomFieldId>;
    initialValues: ICreateUpdateCustomFieldValues;
    isEdit: boolean;
    isFromForm?: boolean;
    isSelectV2: boolean;
    organization: CreateCustomFieldInfosFragment;
    organizationId: OrganizationId;

    mutate(args: {
        organizationId: OrganizationId;
        customField: CustomFieldInput;
    }): Promise<{ customField: FormCustomFieldFragment }>;
    onClose(): void;
    onSuccess(customField: FormCustomFieldFragment): void;
}

const CreateUpdateCustomField = (props: ICreateUpdateCustomFieldProps) => {
    const { translate } = useHeavent();
    const customFieldInput = useService(CustomFieldInputService);
    const enumToOptions = useEnumToOptions();
    const validateService = useService(ValidateService);
    const { mutate: create } = useCustomFieldCategoryCreateMutation();
    const panelBodyRef = React.useRef<HTMLDivElement | null>(null);
    const title = React.useMemo(
        () =>
            props.isEdit
                ? translate('mise_jour_de_17055', props.initialValues.customField.name)
                : translate('nouveau_champ_p_27686'),
        [props.isEdit, props.initialValues]
    );
    useTitle(title);
    const otherCustomFields = React.useMemo(
        () =>
            sortBy(
                props.organization.customFields.nodes.filter((cf) => cf.id !== props.customFieldId),
                (cf) => cf.name.toLowerCase()
            ),
        [props.customFieldId, props.organization]
    );
    const [categories, setCategories] = React.useState(
        props.organization.customFieldsCategories.nodes
    );
    const [currentFieldType, setFieldType] = React.useState(
        props.initialValues.customField.fieldType
    );
    const [currentHasCondition, setHasCondition] = React.useState(
        props.initialValues.customField.hasCondition
    );
    const [conditionCustomField, setConditionCustomField] = React.useState<
        (typeof otherCustomFields)[0] | undefined
    >(
        otherCustomFields.find(
            (customField) =>
                customField.id === props.initialValues.customField.conditionCustomFieldId
        )
    );
    const [isCreateCategoryOpen, setIsCreateCategoryOpen] = React.useState(false);
    const isVarietyVisible = !props.isFromForm;
    const isConditionCheckbox = conditionCustomField?.fieldType === FieldType.Checkbox;
    const options = useCustomFieldOptions(conditionCustomField);

    return (
        <Form
            hideErrors={true}
            initialValues={props.initialValues}
            render={({ form, handleSubmit, submitting, values }) => (
                <>
                    <RightPanel size="md" onClose={props.onClose}>
                        <RightPanelHeader>{title}</RightPanelHeader>

                        <RightPanelBody ref={panelBodyRef}>
                            <OnChange name="customField.fieldType">
                                {(fieldType: FieldType) => {
                                    setFieldType(fieldType);

                                    if (fieldType === FieldType.Validation) {
                                        form.change('customField.isPrivate', false);
                                    }
                                }}
                            </OnChange>

                            <OnChange name="customField.hasCondition">
                                {(hasCondition: boolean) => {
                                    form.change(
                                        'customField.conditionCustomFieldId',
                                        otherCustomFields[0].id
                                    );

                                    setHasCondition(hasCondition);
                                }}
                            </OnChange>

                            <OnChange name="customField.conditionCustomFieldId">
                                {(conditionCustomFieldId: CustomFieldId) => {
                                    const currentCustomField = otherCustomFields.find(
                                        (customField) => customField.id === conditionCustomFieldId
                                    );

                                    if (currentCustomField) {
                                        form.change(
                                            'customField.conditionValue',
                                            currentCustomField.fieldType === FieldType.Checkbox
                                                ? true
                                                : []
                                        );

                                        setConditionCustomField(currentCustomField);
                                    }
                                }}
                            </OnChange>

                            <Spacer height="7" />

                            <FormErrors />

                            {isVarietyVisible && (
                                <>
                                    <Select
                                        label={translate('typologie_34041')}
                                        name="customField.variety"
                                        state={props.isEdit ? 'disabled' : 'active'}
                                    >
                                        <option value={CustomFieldVariety.Accreditation}>
                                            {translate('accr_ditations_39450')}
                                        </option>
                                        <option value={CustomFieldVariety.Delegation}>
                                            {translate('d_l_gations_78318')}
                                        </option>
                                        <option value={CustomFieldVariety.Position}>
                                            {translate('missions_63972')}
                                        </option>
                                        <option value={CustomFieldVariety.UserInfo}>
                                            {translate('membres_11310')}
                                        </option>
                                    </Select>

                                    <Spacer height="6" />
                                </>
                            )}

                            <TextInput
                                hint={translate('titre_de_la_que_69079')}
                                label={translate('nom_du_champ_48329')}
                                name="customField.name"
                            />

                            <Spacer height="6" />

                            <TextInput
                                hint={translate('nom_technique_u_74183')}
                                label={<LabelOptional>{translate('label_08243')}</LabelOptional>}
                                name="customField.label"
                            />

                            <Spacer height="6" />

                            <TextInput
                                hint={translate('sous_titre_de_l_90967')}
                                label={
                                    <LabelOptional>{translate('sous_titre_97472')}</LabelOptional>
                                }
                                name="customField.description"
                            />

                            <Spacer height="6" />

                            <Select
                                label={translate('type_35427')}
                                name="customField.fieldType"
                                state={props.isEdit ? 'disabled' : 'active'}
                            >
                                {enumToOptions(CREATABLE_FIELD_TYPES)}
                            </Select>

                            <Spacer height="6" />

                            <RichSelect
                                isCreateVisible={true}
                                isSearchVisible={true}
                                label={translate('cat_gorie_00291')}
                                name="customField.customFieldCategoryId"
                                placeholder={translate('rechercher_ou_c_16469')}
                                onCreateClick={() => {
                                    setIsCreateCategoryOpen(true);
                                }}
                            >
                                {categories.map((category) => (
                                    <option key={category.id} value={category.id}>
                                        {category.name}
                                    </option>
                                ))}
                            </RichSelect>

                            {currentFieldType === FieldType.Select && (
                                <>
                                    <Spacer height="6" />

                                    <CreateUpdateSelectValues
                                        change={form.change}
                                        isEdit={props.isEdit}
                                        isSelectV2={props.isSelectV2}
                                        values={values.customField.values}
                                    />

                                    <>
                                        <Spacer height="6" />

                                        <Box color="gray800" fontWeight="semiBold">
                                            {translate('est_ce_qu_il_es_10680')}
                                        </Box>

                                        <Spacer height="2" />

                                        <CheckboxText
                                            disabled={props.isEdit}
                                            name="customField.canSelectMultiple"
                                        >
                                            {translate('oui_il_est_pos_25233')}
                                        </CheckboxText>
                                    </>
                                </>
                            )}

                            {currentFieldType === FieldType.Validation && (
                                <>
                                    <Spacer height="6" />

                                    <FileS3Input
                                        accept={[Accept.Images, Accept.Pdf]}
                                        acl="public-read"
                                        label={
                                            <LabelOptional>
                                                {translate('document_87149')}
                                            </LabelOptional>
                                        }
                                        organizationId={props.organizationId}
                                        prefix="customField.document."
                                    />
                                </>
                            )}

                            {values.customField.variety === CustomFieldVariety.UserInfo && (
                                <>
                                    <HorizontalSpacerSeparator height="6" />

                                    <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                                        {translate('conditions_77756')}
                                    </Box>

                                    <Spacer height="4" />

                                    {currentFieldType !== FieldType.Validation && (
                                        <>
                                            <Box color="gray800" fontWeight="semiBold">
                                                {translate('ce_champ_est_il_98722')}
                                            </Box>

                                            <Spacer height="2" />

                                            {props.isFromForm ? (
                                                <Tooltip>
                                                    <Trigger>
                                                        <Box>
                                                            <CheckboxText
                                                                disabled={true}
                                                                name="customField.isPrivate"
                                                            >
                                                                {translate('oui_le_champ_n_99925')}
                                                            </CheckboxText>
                                                        </Box>
                                                    </Trigger>

                                                    <Content placement="top">
                                                        {translate('vous_ne_pouvez_76741')}
                                                    </Content>
                                                </Tooltip>
                                            ) : (
                                                <CheckboxText name="customField.isPrivate">
                                                    {translate('oui_le_champ_n_99925')}
                                                </CheckboxText>
                                            )}
                                        </>
                                    )}

                                    {currentFieldType !== FieldType.Validation &&
                                        otherCustomFields.length > 0 && <Spacer height="6" />}

                                    {otherCustomFields.length > 0 && (
                                        <>
                                            <Box color="gray800" fontWeight="semiBold">
                                                {translate('l_affichage_de_99189')}
                                            </Box>

                                            <Spacer height="2" />

                                            <CheckboxText name="customField.hasCondition">
                                                {translate('oui_le_champ_d_00513')}
                                            </CheckboxText>
                                        </>
                                    )}

                                    {currentHasCondition && (
                                        <Flex css={{ paddingLeft: '$6' }} direction="column">
                                            <Spacer height="4" />

                                            <RichSelect
                                                isSearchVisible={true}
                                                label={translate('nom_du_champ_do_17104')}
                                                name="customField.conditionCustomFieldId"
                                            >
                                                {otherCustomFields.map((customField) => (
                                                    <option
                                                        key={customField.id}
                                                        value={customField.id}
                                                    >
                                                        {customField.name}
                                                    </option>
                                                ))}
                                            </RichSelect>

                                            <Spacer height="4" />

                                            {conditionCustomField && (
                                                <>
                                                    {isConditionCheckbox ? (
                                                        <Select
                                                            label={translate(
                                                                'pour_quelle_val_60529',
                                                                conditionCustomField.name
                                                            )}
                                                            name="customField.conditionValue"
                                                            shouldParseAsBoolean={true}
                                                        >
                                                            <option value="true">
                                                                {translate('true')}
                                                            </option>
                                                            <option value="false">
                                                                {translate('false')}
                                                            </option>
                                                        </Select>
                                                    ) : (
                                                        <RichSelect
                                                            key={conditionCustomField.id}
                                                            isSearchVisible={true}
                                                            label={translate(
                                                                'pour_quelle_val_60529',
                                                                conditionCustomField.name
                                                            )}
                                                            multiple={true}
                                                            name="customField.conditionValue"
                                                        >
                                                            {options}
                                                        </RichSelect>
                                                    )}
                                                </>
                                            )}
                                        </Flex>
                                    )}
                                </>
                            )}

                            <Spacer height="7" />
                        </RightPanelBody>

                        <RightPanelFooter>
                            <Flex gap="3" justify="end">
                                <Button isLoading={submitting} onClick={handleSubmit}>
                                    {props.isEdit
                                        ? translate('mettre_jour_l_31784')
                                        : translate('cr_er_le_champ_05516')}
                                </Button>

                                <Button color="white" onClick={props.onClose}>
                                    {translate('annuler_48254')}
                                </Button>
                            </Flex>
                        </RightPanelFooter>
                    </RightPanel>

                    {isCreateCategoryOpen && (
                        <CreateUpdateCategoryModal
                            initialName={''}
                            isEdit={false}
                            mutate={async (name) => {
                                const { customFieldCategoryCreate } = await create({
                                    organizationId: props.organizationId,
                                    category: { name }
                                });

                                return customFieldCategoryCreate;
                            }}
                            onClose={() => {
                                setIsCreateCategoryOpen(false);
                            }}
                            onSuccess={(category: { id: CustomFieldsCategoryId; name: string }) => {
                                setCategories([...categories, category]);

                                form.change('customField.customFieldCategoryId', category.id);
                            }}
                        />
                    )}
                </>
            )}
            validate={validateService.validateForForm(
                customFieldInput.createUpdateCustomFieldSchema()
            )}
            onShowErrors={() => {
                if (panelBodyRef.current) {
                    panelBodyRef.current.scrollTop = 0;
                }
            }}
            onSubmit={async (values: ICreateUpdateCustomFieldValues) => {
                const { customField } = await props.mutate({
                    organizationId: props.organizationId,
                    customField: {
                        ...values.customField,
                        description: values.customField.description || ''
                    }
                });

                props.onClose();
                props.onSuccess(customField);
            }}
        />
    );
};

interface ICreateCustomFieldProps {
    isFromForm?: boolean;
    organizationId: OrganizationId;

    onClose(): void;
    onSuccess(customField: FormCustomFieldFragment): void;
}

export const CreateCustomField = (props: ICreateCustomFieldProps) => {
    const { organizationId } = useParams();
    const customFieldInput = useService(CustomFieldInputService);
    const { data, loader } = useOrganizationInfosQuery({ organizationId: props.organizationId });
    const { mutate } = useCustomFieldCreateMutation({ redirectOnSuccess: true });

    if (loader) {
        return <RightPanelLoader returnPathFallback={HeaventPaths.CUSTOM_FIELDS(organizationId)} />;
    } else {
        return (
            <CreateUpdateCustomField
                customFieldId={null}
                initialValues={{
                    customField: customFieldInput.customFieldInputDefault(
                        null,
                        data.organization.customFieldsCategories.nodes
                    )
                }}
                isEdit={false}
                isFromForm={props.isFromForm}
                isSelectV2={true}
                mutate={mutate}
                organization={data.organization}
                organizationId={props.organizationId}
                onClose={props.onClose}
                onSuccess={props.onSuccess}
            />
        );
    }
};

interface IUpdateCustomFieldProps {
    customFieldId: CustomFieldId;
    organizationId: OrganizationId;

    onClose(): void;
    onSuccess(customField: FormCustomFieldFragment): void;
}

export const UpdateCustomFieldWithParams = (props: IUpdateCustomFieldProps) => {
    const { organizationId } = useParams();
    const customFieldInput = useService(CustomFieldInputService);
    const { data, loader } = useCustomFieldToEditQuery({
        organizationId: props.organizationId,
        customFieldId: props.customFieldId
    });
    const { mutate } = useCustomFieldUpdateMutation({ redirectOnSuccess: true });

    if (loader) {
        return <RightPanelLoader returnPathFallback={HeaventPaths.CUSTOM_FIELDS(organizationId)} />;
    } else {
        return (
            <CreateUpdateCustomField
                customFieldId={props.customFieldId}
                initialValues={{
                    customField: customFieldInput.customFieldInputDefault(
                        data.organization.customField,
                        data.organization.customFieldsCategories.nodes
                    )
                }}
                isEdit={true}
                isSelectV2={data.organization.customField.isSelectV2}
                mutate={(values) =>
                    mutate({
                        ...values,
                        customFieldId: props.customFieldId
                    })
                }
                organization={data.organization}
                organizationId={props.organizationId}
                onClose={props.onClose}
                onSuccess={props.onSuccess}
            />
        );
    }
};

export const UpdateCustomField = (
    props: Omit<IUpdateCustomFieldProps, 'customFieldId' | 'organizationId'>
) => {
    const {
        params: { organizationId, customFieldId }
    } = useHeavent();
    return (
        <UpdateCustomFieldWithParams
            customFieldId={customFieldId}
            organizationId={organizationId}
            {...props}
        />
    );
};
