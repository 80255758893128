import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I, IIcon } from 'common/src/designSystem/components/i';
import { CSS } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { useMobileQuery } from '../../hooks/useMobileQuery';
import { Dropdown } from './dropdown/dropdown';
import { Menu } from './dropdown/menu';
import { Trigger } from './dropdown/trigger';

interface IDetailBoxProps {
    children: React.ReactNode;
    css?: CSS;
    // eslint-disable-next-line react/no-unused-prop-types
    id?: string; // Unknown why this causes linter error
    infoPanel?: React.ReactNode;
    // eslint-disable-next-line react/no-unused-prop-types
    items?: React.ReactNode; // Unknown why this causes linter error
    // eslint-disable-next-line react/no-unused-prop-types
    rightIcon?: IIcon; // Unknown why this causes linter error
    select?: React.ReactNode;
    title: React.ReactNode;

    // eslint-disable-next-line react/no-unused-prop-types
    rightIconClick?(e: React.MouseEvent<HTMLDivElement>): void; // Unknown why this causes linter error
}

const DetailDesktopBox = (props: IDetailBoxProps) => (
    <Flex
        css={{
            background: 'white',
            bd: true,
            ...(props.css as any)
        }}
        direction="column"
        id={props.id}
        width={1}
    >
        <Flex>
            <Flex direction="column" width={props.infoPanel ? 0.75 : 1}>
                <Flex
                    css={{ borderBottom: '1px solid $gray200', padding: '$5 $6' }}
                    gap="3"
                    width={1}
                >
                    <Box
                        color="gray900"
                        css={{ flex: '1', alignContent: 'center' }}
                        fontSize="textMd"
                        fontWeight="semiBold"
                    >
                        {props.title}
                    </Box>

                    {props.rightIcon && (
                        <Box
                            color="gray900"
                            css={{ cursor: 'pointer' }}
                            fontSize="textMd"
                            fontWeight="semiBold"
                            textAlign="center"
                            width={20}
                            onClick={(e) => {
                                props.rightIconClick?.(e);
                            }}
                        >
                            <I icon={props.rightIcon} />
                        </Box>
                    )}

                    {props.items && (
                        <Dropdown>
                            <Trigger>
                                <Box
                                    color="gray900"
                                    css={{ cursor: 'pointer' }}
                                    fontSize="textMd"
                                    fontWeight="semiBold"
                                    textAlign="center"
                                    width={20}
                                >
                                    <I icon="ellipsis-vertical" />
                                </Box>
                            </Trigger>

                            <Menu placement="bottom-end">{props.items}</Menu>
                        </Dropdown>
                    )}

                    {props.select}
                </Flex>

                <Box className="h-detail-box-content" css={{ flex: '1', padding: '$6' }} width={1}>
                    {props.children}
                </Box>
            </Flex>

            {props.infoPanel && (
                <Flex
                    css={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'stretch',
                        paddingInline: '$7 $6',
                        borderInlineStart: '1px solid $gray200'
                    }}
                    direction="column"
                    gap="2"
                    width={0.25}
                >
                    {props.infoPanel}
                </Flex>
            )}
        </Flex>
    </Flex>
);

const DetailMobileBox = ({ css, title, children }: IDetailBoxProps) => (
    <Flex direction="column" gap="3">
        <Box color="gray900" fontSize="textLg" fontWeight="semiBold">
            {title}
        </Box>
        <Flex
            css={
                {
                    bd: true,
                    padding: '$4',
                    ...css
                } as CSS
            }
            direction="column"
        >
            {children}
        </Flex>
    </Flex>
);

export const DetailBox = ({ children, ...props }: IDetailBoxProps) => {
    const onMobile = useMobileQuery();

    return onMobile ? (
        <DetailMobileBox {...props}>{children}</DetailMobileBox>
    ) : (
        <DetailDesktopBox {...props}>{children}</DetailDesktopBox>
    );
};
