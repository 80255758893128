import { useHistory, useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { UserSheetMobile } from '../../../../users/mobile/show/userSheetMobile';

export const PositionSlotUserSheetMobile = () => {
    const { organizationId, eventId, positionId, positionSlotId, userInfoId } = useParams();
    const history = useHistory();
    const onClose = () => {
        history.push(
            HeaventPaths.POSITION_SLOT_MEMBERS(organizationId, eventId, positionId, positionSlotId)
        );
    };
    const basePath = HeaventPaths.POSITION_SLOT_MEMBERS_USER(
        organizationId,
        eventId,
        positionId,
        positionSlotId,
        userInfoId
    );

    return <UserSheetMobile basePath={basePath} onClose={onClose} />;
};
