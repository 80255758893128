import { Button } from 'common-front/src/designSystem/components/button';
import { Modal } from 'common-front/src/designSystem/components/modal/modal';
import { Textarea } from 'common-front/src/designSystem/components/textarea';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePermissions } from 'common-front/src/hooks/usePermissions';
import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { canCommentFn } from 'common/src/graphql/permissions/checks';
import { isEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useCommentCreateMutation, useCommentsQuery } from '../../../generated/graphqlHooks';
import { AssignmentUserPanelComment } from './assignmentUserPanelComment';

export const AssignmentUserPanelComments = () => {
    const {
        translate,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const {
        data,
        isLoading: commentsQueryIsLoading,
        reload
    } = useCommentsQuery({ organizationId, userInfoId });
    const { mutate, isLoading: isCommentCreateLoading } = useCommentCreateMutation();
    const [newComment, setNewComment] = React.useState('');
    const {
        permissions: [canComment]
    } = usePermissions(canCommentFn({ organizationId, eventId, userInfoId }));

    return (
        <Flex
            css={{
                padding: '0 $6 $4 $6'
            }}
            direction="column"
            gap="3"
        >
            {commentsQueryIsLoading ? (
                <Skeleton borderRadius="$1" height={40} variant="rectangular" width={1} />
            ) : (
                data.organization.userInfo.comments.map((comment) => (
                    <AssignmentUserPanelComment key={comment.id} comment={comment} />
                ))
            )}

            {canComment && (
                <Modal.Root>
                    <Modal.Trigger>
                        <Button color="invisible" leftIcon="plus">
                            {translate('ajouter_un_comm_27671')}
                        </Button>
                    </Modal.Trigger>

                    <Modal.Portal>
                        <Modal.Header icon="message">
                            {translate('ajouter_un_comm_27671')}
                        </Modal.Header>

                        <Modal.Content>
                            <Textarea
                                label={translate('commentaire_08754')}
                                value={newComment}
                                onChange={setNewComment}
                            />
                        </Modal.Content>

                        <Modal.Buttons>
                            {(onClose) => (
                                <Button
                                    disabled={isEmptyString(newComment)}
                                    isLoading={isCommentCreateLoading}
                                    onClick={async () => {
                                        await mutate({
                                            organizationId,
                                            eventId,
                                            userInfoId,
                                            comment: { content: newComment }
                                        });

                                        reload();
                                        onClose();
                                    }}
                                >
                                    {translate('ajouter_97327')}
                                </Button>
                            )}
                        </Modal.Buttons>
                    </Modal.Portal>
                </Modal.Root>
            )}
        </Flex>
    );
};
