import { executeCustomFieldsMassEditInfosQuery } from 'common-front/src/generated/graphqlHooks';
import { useCachedDataLoading } from 'common-front/src/hooks/useCachedDataLoading';
import { CustomFieldsMassEditInfosQuery, OrganizationId } from 'common/src/generated/types';
import * as React from 'react';

interface ICustomFieldsContext {
    getCustomFieldsCategories(): Promise<
        CustomFieldsMassEditInfosQuery['organization']['customFieldsCategories']['nodes']
    >;
}

const CustomFieldsContext = React.createContext<ICustomFieldsContext>({} as ICustomFieldsContext);

interface ICustomFieldsContextProviderProps {
    children: React.ReactNode;
    organizationId: OrganizationId;
}

export const CustomFieldsContextProvider = (props: ICustomFieldsContextProviderProps) => {
    const getCustomFieldsCategories = useCachedDataLoading(
        executeCustomFieldsMassEditInfosQuery,
        {
            organizationId: props.organizationId
        },
        (d) => d.organization.customFieldsCategories.nodes
    );

    return (
        <CustomFieldsContext.Provider
            value={{
                getCustomFieldsCategories
            }}
        >
            {props.children}
        </CustomFieldsContext.Provider>
    );
};

export function useCustomFieldsContext() {
    return React.useContext(CustomFieldsContext);
}
