import { FormBox, IFormBoxProps } from 'common-front/src/designSystem/components/formBox';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { FormParametersGeneralCustomFields } from '../../forms/update/parameters/general/formParametersGeneralCustomFields';

export const PresetsFormBox = ({
    file,
    onToggle,
    ...props
}: Omit<React.ComponentProps<typeof FormParametersGeneralCustomFields>, 'name'> &
    Pick<IFormBoxProps, 'onToggle'> & {
        file: File;
        onToggle?: (isOpen: boolean) => void;
    }) => {
    const translate = useTranslate();

    return (
        <FormBox
            initialIsOpen={isNonEmptyArray(props.values) ?? false}
            subtitle={translate('d_finissez_dire_31742')}
            title={translate('attribution_d_u_50979')}
            onToggle={onToggle}
        >
            <FormParametersGeneralCustomFields name="input.presets" {...props} />
        </FormBox>
    );
};
