import { AvatarNameEmail } from 'common-front/src/components/avatarNameEmail';
import { Page } from 'common-front/src/components/page/page';
import { Field } from 'common-front/src/components/users/show/field';
import { DetailBox } from 'common-front/src/designSystem/components/detailBox';
import { ProgressGauge } from 'common-front/src/designSystem/components/progressGauge';
import {
    HorizontalSpacerSeparator,
    Separator
} from 'common-front/src/designSystem/components/separator';
import { useDelegationsMassEditMutation } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Link } from 'common/src/designSystem/components/link';
import { LinkText } from 'common/src/designSystem/components/linkText';
import {
    CustomFieldWithConditionFragment,
    DelegationDashboardQuery,
    DelegationProperty,
    Feature,
    MassAssignStrategy
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import { getFormHref, getSpaceHref } from 'common/src/util/url';
import * as React from 'react';
import { DescriptionCard } from '../../../common/cards/descriptionCard';
import { PrivateNoteCard } from '../../../common/cards/privateNoteCard';
import { TagsCard } from '../../../common/cards/tagsCard';
import { useDelegationDashboardQuery } from '../../../generated/graphqlHooks';
import { useOrganizationContext } from '../../../organization/organizationContext';

interface IDelegationDashboardComponentProps {
    customFields: CustomFieldWithConditionFragment[];
    delegation: DelegationDashboardQuery['organization']['delegation'];

    reload(): void;
}

const DelegationDashboardComponent = ({
    customFields,
    delegation,
    reload
}: IDelegationDashboardComponentProps) => {
    const {
        translate,
        params: { organizationId, eventId, delegationId }
    } = useHeavent();
    const { hasFeature } = useOrganizationContext();
    const dateTimeService = useService(DateTimeService);
    const { isOrganizationAdmin } = useOrganizationContext();
    const { mutate: massEdit } = useDelegationsMassEditMutation();

    const formLink = React.useMemo(
        () =>
            getFormHref({
                organizationId,
                eventId,
                delegationId,
                formId: delegation?.form.id
            }),
        [delegation]
    );

    const delegationSpaceLink = React.useMemo(
        () =>
            getSpaceHref(
                organizationId,
                DelegationsPaths.DELEGATION({ organizationId, eventId, delegationId }),
                false
            ),
        [delegation]
    );

    const numberWithSeparatingDots = (label: string, number: number) => (
        <Flex>
            <Box textAlign="start">{label}</Box>
            <Box
                css={{
                    borderBlockEnd: '2px dotted $gray500',
                    flex: '1',
                    marginBlockEnd: '$1'
                }}
            />
            <Box
                color="gray900"
                css={{
                    fontSize: '$textMd'
                }}
                textAlign="end"
            >
                {number}
            </Box>
        </Flex>
    );

    return (
        <Page.Content>
            <Flex direction="column" gap="4" width={1}>
                <Flex gap="6">
                    <Flex
                        css={{
                            flex: '3',
                            height: '100%'
                        }}
                        direction="column"
                        gap="6"
                    >
                        <DetailBox
                            css={{
                                flex: '1 1'
                            }}
                            title={translate('limite_de_membr_42978')}
                        >
                            <Flex
                                css={{ justifyContent: 'space-between' }}
                                direction="column"
                                height={'100%'}
                            >
                                <Box css={{ margin: 'auto' }}>
                                    <ProgressGauge
                                        count={delegation.numberOfResources}
                                        total={delegation.maxResources ?? Infinity}
                                    />
                                </Box>

                                <Flex direction="column" gap="4">
                                    {hasFeature(Feature.Accreditation) && (
                                        <>
                                            <Box
                                                color="gray700"
                                                css={{
                                                    fontSize: '$textXs',
                                                    fontWeight: 600,
                                                    fontFamily: 'Open Sans',
                                                    lineHeight: '18px',
                                                    textTransform: 'uppercase'
                                                }}
                                            >
                                                {translate('dont_76603')}
                                            </Box>

                                            <Flex
                                                color="gray500"
                                                css={{
                                                    fontSize: '$textSm',
                                                    fontWeight: '$medium',
                                                    lineHeight: '20px',
                                                    justifyContent: 'space-between'
                                                }}
                                                direction="column"
                                                gap="2"
                                            >
                                                {numberWithSeparatingDots(
                                                    translate('membres_accr_di_73664'),
                                                    delegation.numberOfMembersAccredited
                                                )}

                                                {numberWithSeparatingDots(
                                                    translate('membres_en_atte_57336'),
                                                    delegation.numberOfMembersWaitingAccreditation
                                                )}

                                                {numberWithSeparatingDots(
                                                    translate('membres_refus_31757'),
                                                    delegation.numberOfMembersRefusedAccreditation
                                                )}
                                            </Flex>
                                        </>
                                    )}
                                </Flex>
                            </Flex>
                        </DetailBox>
                    </Flex>

                    <Flex css={{ flex: '6' }} direction="column" gap="6">
                        <DetailBox title={translate('param_tres_78420')}>
                            <Flex direction="column" gap="4" width={1}>
                                {isOrganizationAdmin && delegation.parent && (
                                    <>
                                        <Flex gap="3" justify="between">
                                            <Box color="gray500">
                                                {translate('d_l_gation_pare_68507')}
                                            </Box>

                                            <Flex
                                                align="center"
                                                css={{
                                                    color: '$primary700',
                                                    fontWeight: '$medium',
                                                    '&:hover': {
                                                        textDecoration: 'underline'
                                                    }
                                                }}
                                                gap="2"
                                            >
                                                <Link
                                                    to={DelegationsPaths.DELEGATION({
                                                        organizationId,
                                                        delegationId: delegation.parent.id
                                                    })}
                                                >
                                                    <Badge
                                                        key={delegation.parent.id}
                                                        color="white"
                                                        cursor="pointer"
                                                        size="md"
                                                    >
                                                        {delegation.parent.name}
                                                    </Badge>
                                                </Link>
                                            </Flex>
                                        </Flex>

                                        <Separator direction="horizontal" />
                                    </>
                                )}

                                <Flex gap="3" justify="between">
                                    <Box color="gray500">{translate('status_06428')}</Box>

                                    <Box color="gray800">
                                        <Badge color="success">{translate(delegation.state)}</Badge>
                                    </Box>
                                </Flex>

                                <Separator direction="horizontal" />

                                <Flex gap="3" justify="between">
                                    <Box color="gray500">{translate('date_limite_d_i_79779')}</Box>

                                    <Box color="gray800">
                                        {delegation.deadlineDate
                                            ? dateTimeService.toLocaleString(
                                                  delegation.deadlineDate,
                                                  LocaleFormats.DateOnly.MonthLong
                                              )
                                            : '-'}
                                    </Box>
                                </Flex>

                                <Separator direction="horizontal" />

                                <Flex gap="3" justify="between">
                                    <Box color="gray500">{translate('Form')}</Box>

                                    <LinkText css={{ color: '$primary700' }} to={formLink}>
                                        {delegation.form.name}
                                    </LinkText>
                                </Flex>

                                <Separator direction="horizontal" />

                                <Flex gap="3" justify="between">
                                    <Box color="gray500">{translate('lien_de_l_espac_46578')}</Box>

                                    <LinkText
                                        css={{ color: '$primary700' }}
                                        to={delegationSpaceLink}
                                    >
                                        {translate('portail_d_l_gat_06817')}
                                    </LinkText>
                                </Flex>

                                {customFields.map((cf) => (
                                    <React.Fragment key={cf.slug}>
                                        <Separator direction="horizontal" />

                                        <Flex align="center" gap="3" justify="between">
                                            <Box color="gray500" css={{ flex: '1' }}>
                                                {cf.name}
                                            </Box>

                                            <Flex justify="end" width={300}>
                                                <Field field={cf} fields={delegation.fields} />
                                            </Flex>
                                        </Flex>
                                    </React.Fragment>
                                ))}
                            </Flex>
                        </DetailBox>
                    </Flex>
                </Flex>

                <Flex gap="6">
                    <Flex direction="column" gap="4" width={1}>
                        <DetailBox title={translate('reponsable_82458_plural')}>
                            {isNonEmptyArray(delegation.leaders) ? (
                                delegation.leaders.map((userInfo, index) => (
                                    <React.Fragment key={userInfo.id}>
                                        {index !== 0 && <HorizontalSpacerSeparator height="4" />}

                                        <AvatarNameEmail userInfo={userInfo} />
                                    </React.Fragment>
                                ))
                            ) : (
                                <Box color="gray500" textAlign="center" width={1}>
                                    {translate('aucun_reponsabl_38504')}
                                </Box>
                            )}
                        </DetailBox>

                        <TagsCard
                            associateTags={(tagsIds) =>
                                massEdit({
                                    organizationId,
                                    eventId,
                                    massEdit: {
                                        reset: false,
                                        selecteds: { ids: [delegationId] },
                                        slug: DelegationProperty.Tag,
                                        strategy: MassAssignStrategy.Replace,
                                        value: tagsIds
                                    }
                                })
                            }
                            modalSubtitle={translate('ajouter_des_tag_35833')}
                            reload={reload}
                            tags={delegation.tags}
                        />
                    </Flex>

                    <Flex direction="column" gap="4" width={1}>
                        <DescriptionCard description={delegation.description} />

                        <PrivateNoteCard privateNote={delegation.privateNote} />
                    </Flex>
                </Flex>
            </Flex>
        </Page.Content>
    );
};

export const DelegationDashboard = () => {
    const {
        params: { organizationId, delegationId }
    } = useHeavent();

    const { data, loader, reload } = useDelegationDashboardQuery({
        organizationId,
        delegationId
    });

    return (
        loader || (
            <DelegationDashboardComponent
                customFields={data.organization.customFields.nodes}
                delegation={data.organization.delegation}
                reload={reload}
            />
        )
    );
};
