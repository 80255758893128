import { Page } from 'common-front/src/components/page/page';
import { KpiCard } from 'common-front/src/designSystem/components/kpiCard';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { PositionCategoryQuery } from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { useService } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { usePositionsCategoryLeadersAssignMutation } from '../../../generated/graphqlHooks';
import { PositionCategoryDescription } from '../positionCategoryDescription';
import { PositionCategoryLeaders } from '../positionCategoryLeaders';
import { PositionCategoryPositions } from '../positions/positionCategoryPositions';

interface IPositionCategoryDashboardProps {
    positionCategory: PositionCategoryQuery['event']['positionCategory'];
    roles: PositionCategoryQuery['organization']['roles'];

    reload(): void;
}

export const PositionCategoryDashboard = (props: IPositionCategoryDashboardProps) => {
    const {
        translate,
        params: { eventId, positionCategoryId }
    } = useHeavent();
    const intervalService = useService(IntervalService);
    const { mutate: leadersAssign } = usePositionsCategoryLeadersAssignMutation();

    return (
        <Page.Content>
            <Flex direction="column" gap="6">
                <Flex gap="6" width={1}>
                    <KpiCard
                        iconColor="primary"
                        text={translate('nombre_de_missi_56719')}
                        value={props.positionCategory.numberOfPositions}
                    />

                    <KpiCard
                        iconColor="primary"
                        text={translate('number_of_membe_24698')}
                        value={props.positionCategory.assignedResources}
                    />

                    <KpiCard
                        iconColor="primary"
                        text={translate('nombre_de_membr_74699')}
                        value={props.positionCategory.preassignedResources}
                    />

                    <KpiCard
                        iconColor="primary"
                        text={translate('Date')}
                        value={
                            props.positionCategory.range
                                ? intervalService.toLocaleString(
                                      props.positionCategory.range,
                                      undefined,
                                      {
                                          day: 'numeric',
                                          month: 'short',
                                          year: 'numeric'
                                      }
                                  )
                                : '-'
                        }
                    />
                </Flex>

                <Flex gap="7">
                    <Flex direction="column" gap="6" width={470}>
                        <PositionCategoryLeaders
                            leaders={props.positionCategory.leaders}
                            reload={props.reload}
                            roles={props.roles}
                            onSave={(leaders) =>
                                leadersAssign({
                                    eventId,
                                    positionCategoryId,
                                    leaders
                                })
                            }
                        />

                        <PositionCategoryDescription
                            category={props.positionCategory}
                            eventId={eventId}
                            reload={props.reload}
                        />
                    </Flex>

                    <Box css={{ flex: '1' }}>
                        <PositionCategoryPositions />
                    </Box>
                </Flex>
            </Flex>
        </Page.Content>
    );
};
