import { Button } from 'common-front/src/designSystem/components/button';
import { Icon } from 'common-front/src/designSystem/components/icon';
import { usePositionsSlotsUsersInfosCheckInMutation } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { UserPositionDropdown } from 'common-front/src/positions/userPositionDropdown';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { LinkText } from 'common/src/designSystem/components/linkText';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import { CheckInState, CheckInStrategy, UserPositionsQuery } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { DurationService } from 'common/src/services/durationService';
import { IntervalService } from 'common/src/services/intervalService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import { fullName } from 'common/src/vo/positionSlot';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';

interface IUserPositionsAssignedListRowProps {
    psui: UserPositionsQuery['event']['volunteerRegistration']['positionsSlotsUsersInfos'][number];

    reload(): void;
}

export const UserPositionsAssignedListRow = (props: IUserPositionsAssignedListRowProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { isPositionAdmin, isPositionViewer } = useEventContext();
    const dateTimeService = useService(DateTimeService);
    const durationService = useService(DurationService);
    const intervalService = useService(IntervalService);
    const { mutate: checkIn } = usePositionsSlotsUsersInfosCheckInMutation();
    const slotName = React.useMemo(
        () =>
            fullName(intervalService, props.psui.positionSlot, '', {
                slot: { includeRange: true }
            }),
        [props.psui]
    );
    const timeDifference = React.useMemo(() => {
        if (props.psui.checkInAt && props.psui.checkOutAt) {
            return durationService.minutesToHoursMinutesString(
                Math.round(
                    props.psui.checkOutAt.diff(props.psui.checkInAt).shiftTo('minutes').minutes
                )
            );
        } else {
            return '';
        }
    }, [props.psui]);
    const canCheckIn = isPositionViewer(props.psui.positionCategoryId, props.psui.position.id);

    return (
        <Row>
            <Cell align="center" gap="3">
                <Icon color={props.psui.position.color} icon={props.psui.position.icon} />

                <Flex direction="column">
                    <LinkText
                        to={PositionsPaths.POSITION({
                            organizationId,
                            eventId,
                            positionId: props.psui.position.id
                        })}
                    >
                        {props.psui.position.name}
                    </LinkText>

                    <Box color="gray500">{slotName}</Box>
                </Flex>
            </Cell>

            <Cell>
                {props.psui.state === CheckInState.CheckedOut ? (
                    <Badge leftIcon="clock">{translate('temps_total_79448', timeDifference)}</Badge>
                ) : props.psui.state === CheckInState.CheckedIn ? (
                    <Badge color="success" leftIcon="circle" leftIconStyle="solid">
                        {translate(
                            'point_le_1_63576',
                            dateTimeService.toLocaleString(
                                props.psui.checkInAt!.toLocal(),
                                LocaleFormats.DateTime
                            )
                        )}
                    </Badge>
                ) : (
                    <Badge color="warning" leftIcon="circle" leftIconStyle="solid">
                        {translate('non_point_42145')}
                    </Badge>
                )}
            </Cell>

            <CellControls justify="end" width={200}>
                {canCheckIn &&
                    (props.psui.state === CheckInState.Waiting ? (
                        <Button
                            color="white"
                            size="sm"
                            onClick={async () => {
                                await checkIn({
                                    eventId: props.psui.eventId,
                                    checkIn: {
                                        strategy: CheckInStrategy.CheckIn,
                                        positionsSlotsUsersInfosIds: [props.psui.id]
                                    }
                                });

                                props.reload();
                            }}
                        >
                            {translate('pointer_80927')}
                        </Button>
                    ) : props.psui.state === CheckInState.CheckedIn ? (
                        <Button
                            color="white"
                            size="sm"
                            onClick={async () => {
                                await checkIn({
                                    eventId: props.psui.eventId,
                                    checkIn: {
                                        strategy: CheckInStrategy.CheckOut,
                                        positionsSlotsUsersInfosIds: [props.psui.id]
                                    }
                                });

                                props.reload();
                            }}
                        >
                            {translate('d_pointer_25328')}
                        </Button>
                    ) : null)}

                <UserPositionDropdown
                    canCheckIn={isPositionViewer(
                        props.psui.positionCategoryId,
                        props.psui.position.id
                    )}
                    canDelete={isPositionAdmin()}
                    psui={props.psui}
                    reloadCheckIn={props.reload}
                    onDelete={props.reload}
                />
            </CellControls>
        </Row>
    );
};
