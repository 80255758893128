import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import {
    EventId,
    VolunteerRegistrationState,
    VolunteersRegistrationId
} from 'common/src/generated/types';
import { canRegistrationStateUpdateFn } from 'common/src/graphql/permissions/checks';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { preventDefault } from 'common/src/util/links';
import { PossibleColumn } from 'common/src/vo/segment';
import { getStateBadgeColorIcon } from 'common/src/vo/volunteerRegistration';
import * as React from 'react';
import { Dropdown } from '../../../designSystem/components/dropdown/dropdown';
import { Menu } from '../../../designSystem/components/dropdown/menu';
import { Trigger } from '../../../designSystem/components/dropdown/trigger';
import { useVolunteerRegistrationUpdateFieldMutation } from '../../../generated/graphqlHooks';
import { usePermissionsWithoutCaching } from '../../../hooks/usePermissions';
import { useRichTableContext } from '../../../richTable/richTableContext';
import { useSegmentsContext } from '../../../segments/segmentsContext';

interface IDropdownCellProps {
    eventId: EventId;
    state: VolunteerRegistrationState;
    volunteerRegistrationId: VolunteersRegistrationId;

    close(): void;
    reload(): void;
}

const DropdownCell = (props: IDropdownCellProps) => {
    const translate = useTranslate();
    const { mutate } = useVolunteerRegistrationUpdateFieldMutation();

    return (
        <Flex
            css={{
                cursor: 'pointer',
                padding: '$3',
                '&:hover': {
                    background: '$gray100'
                }
            }}
            onClick={async () => {
                await mutate({
                    eventId: props.eventId,
                    volunteerRegistrationId: props.volunteerRegistrationId,
                    slug: 'volunteer_registration_state',
                    value: props.state
                });

                props.reload();
                props.close();
            }}
        >
            <Badge cursor="pointer" {...getStateBadgeColorIcon(props.state)}>
                {translate(props.state)}
            </Badge>
        </Flex>
    );
};

const DropdownCells = (
    props: Omit<IVolunteerRegistrationStateCellProps, 'updatable'> & { close: () => void }
) => {
    const {
        permissions: [canChangeToPreAssigned, canChangeToOthers],
        isLoading
    } = usePermissionsWithoutCaching(
        canRegistrationStateUpdateFn(
            props.eventId,
            VolunteerRegistrationState.PreAssigned,
            props.volunteerRegistrationId
        ),
        canRegistrationStateUpdateFn(
            props.eventId,
            VolunteerRegistrationState.Assigned /* Same rule for Refused, etc. */,
            props.volunteerRegistrationId
        )
    );

    return isLoading ? null : (
        <>
            {canChangeToOthers && props.state !== VolunteerRegistrationState.WaitingAssignment && (
                <DropdownCell
                    close={props.close}
                    eventId={props.eventId}
                    reload={props.reload}
                    state={VolunteerRegistrationState.WaitingAssignment}
                    volunteerRegistrationId={props.volunteerRegistrationId}
                />
            )}

            {canChangeToPreAssigned && (
                <DropdownCell
                    close={props.close}
                    eventId={props.eventId}
                    reload={props.reload}
                    state={VolunteerRegistrationState.PreAssigned}
                    volunteerRegistrationId={props.volunteerRegistrationId}
                />
            )}

            {canChangeToOthers && props.state !== VolunteerRegistrationState.Refused && (
                <DropdownCell
                    close={props.close}
                    eventId={props.eventId}
                    reload={props.reload}
                    state={VolunteerRegistrationState.Refused}
                    volunteerRegistrationId={props.volunteerRegistrationId}
                />
            )}

            {canChangeToOthers && props.state !== VolunteerRegistrationState.NotApplicable && (
                <DropdownCell
                    close={props.close}
                    eventId={props.eventId}
                    reload={props.reload}
                    state={VolunteerRegistrationState.NotApplicable}
                    volunteerRegistrationId={props.volunteerRegistrationId}
                />
            )}
        </>
    );
};

interface IVolunteerRegistrationStateCellProps {
    eventId: EventId;
    field: PossibleColumn;
    firstCellCss?: any;
    state: VolunteerRegistrationState;
    updatable: boolean;
    volunteerRegistrationId: VolunteersRegistrationId;

    reload(): void;
}

export const VolunteerRegistrationStateCell = (props: IVolunteerRegistrationStateCellProps) => {
    const translate = useTranslate();
    // TODO: temporary solution
    // TODO: use only useRichTableContext() once refacto is done
    const { isEditMode: segmentsIsEditMode } = useSegmentsContext();
    const { isEditMode: richTableIsEditMode } = useRichTableContext();
    const isEditMode = segmentsIsEditMode || richTableIsEditMode;
    const [isOpen, _setIsOpen] = React.useState(false);
    const setIsOpen = React.useCallback(
        (newIsOpen: boolean) => {
            if (isEditMode) {
                _setIsOpen(newIsOpen);
            }
        },
        [isEditMode, _setIsOpen]
    );
    const close = React.useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    if (isEditMode && props.updatable) {
        return (
            <Cell
                css={{
                    border: '2px solid transparent',
                    '&:hover': {
                        border: '2px solid $primary700'
                    }
                }}
                width={230}
                onClick={preventDefault}
            >
                <Dropdown isOpen={isOpen} onStateChange={setIsOpen}>
                    <Trigger>
                        <Flex align="center" gap="3" height={1} justify="between" width={1}>
                            <Badge
                                {...getStateBadgeColorIcon(props.state)}
                                cursor="text"
                                ellipsis={true}
                            >
                                {translate(props.state)}
                            </Badge>

                            {isOpen && (
                                <Box>
                                    <I icon="chevron-down" />
                                </Box>
                            )}
                        </Flex>
                    </Trigger>

                    <Menu offset={2} placement="bottom" width={230}>
                        <DropdownCells {...{ close, ...props }} />
                    </Menu>
                </Dropdown>
            </Cell>
        );
    } else {
        return (
            <Cell
                css={{
                    border: '2px solid transparent'
                }}
                width={230}
            >
                <Badge {...getStateBadgeColorIcon(props.state)}>{translate(props.state)}</Badge>
            </Cell>
        );
    }
};
