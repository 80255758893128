import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Grid } from 'common/src/designSystem/components/grid';
import { CSS } from 'common/src/designSystem/components/stitches';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { NominalType } from 'common/src/util/nominalType';
import { padStart, range } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Button } from '../../designSystem/components/button';
import { DateInput } from '../../designSystem/components/date/dateInput';
import { PlanningRow } from './planningRow';
import { PlanningRow as PlanningRowType, PlanningSlot } from './types';

interface IRootProps<T extends { id: TId }, TId extends NominalType<number, any>, U> {
    css?: CSS;
    data: Array<PlanningRowType<T, TId, U>>;
    date: DateTime;
    minDate: DateTime;
    maxDate: DateTime;
    showPlus: boolean;

    onChange(date: DateTime): void;
    onPlus?(startDate: DateTime, row: PlanningRowType<T, TId, U>): void;
    renderLeftCell(row: PlanningRowType<T, TId, U>): React.ReactNode;
    renderSlot(slot: PlanningSlot<U>, row: PlanningRowType<T, TId, U>): React.ReactNode;
}

export const Root = <T extends { id: TId }, TId extends NominalType<number, any>, U>(
    props: IRootProps<T, TId, U>
) => {
    const dateTimeService = useService(DateTimeService);
    const isPrevDisabled = props.date.startOf('day').equals(props.minDate.startOf('day'));
    const isNextDisabled = props.date.endOf('day').equals(props.maxDate.endOf('day'));

    return (
        <Flex
            css={{
                border: '1px solid $gray200',
                ...(props.css as any)
            }}
            direction="column"
            width={1}
        >
            <Flex css={{ borderBottom: '1px solid $gray200' }} height={52} width={1}>
                <Flex
                    align="center"
                    css={{
                        borderRight: '1px solid $gray200',
                        px: '$4',
                        width: 'calc(2 * $cellWidth)'
                    }}
                    height={1}
                >
                    <DateInput
                        max={props.maxDate}
                        min={props.minDate}
                        value={props.date}
                        onChange={props.onChange}
                    />
                </Flex>

                <Flex align="center" css={{ flex: '1', px: '$2' }} gap="3">
                    <Button
                        color="white"
                        disabled={isPrevDisabled}
                        leftIcon="chevron-left"
                        onClick={() => {
                            props.onChange(props.date.minus({ day: 1 }));
                        }}
                    />

                    <Box
                        css={{ flex: '1', textTransform: 'capitalize' }}
                        font="gray800 textSm medium"
                        textAlign="center"
                    >
                        {dateTimeService.toLocaleString(
                            props.date,
                            LocaleFormats.DateOnly.WeekdayLongMonthLong
                        )}
                    </Box>

                    <Button
                        color="white"
                        disabled={isNextDisabled}
                        leftIcon="chevron-right"
                        onClick={() => {
                            props.onChange(props.date.plus({ day: 1 }));
                        }}
                    />
                </Flex>
            </Flex>

            <Flex css={{ borderBottom: '1px solid $gray200' }} height={24} width={1}>
                <Flex css={{ borderRight: '1px solid $gray200', width: 'calc(2 * $cellWidth)' }} />

                <Flex css={{ flex: '1' }}>
                    <Grid gridtemplatecolumns="repeat(1440, 1fr)" height={1} width={1}>
                        <Box css={{ gridColumn: 'span 60' }} />

                        {range(2, 24, 2).map((i) => (
                            <Flex
                                key={i}
                                align="center"
                                css={{
                                    fontSize: '$textXs',
                                    gridColumn: 'span 120'
                                }}
                                justify="center"
                            >
                                {padStart(i.toString(10), 2, '0')}:00
                            </Flex>
                        ))}
                    </Grid>
                </Flex>
            </Flex>

            {props.data.map((row) => (
                <PlanningRow
                    key={row.id.toString(10)}
                    date={props.date}
                    renderLeftCell={props.renderLeftCell}
                    renderSlot={props.renderSlot}
                    row={row}
                    showPlus={props.showPlus}
                    onPlus={props.onPlus}
                />
            ))}
        </Flex>
    );
};
