import { PositionsMap as CommonPositionsMap } from 'common-front/src/components/map/positionsMap';
import { Alert } from 'common-front/src/designSystem/components/alert';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { AcronymIcon } from 'common-front/src/localDatabase';
import { Images } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CommonEnvVars } from 'common/src/envVars';
import {
    MapLayer,
    PositionsPositionFragment,
    TraceFragment,
    TraceId
} from 'common/src/generated/types';
import { toggle } from 'common/src/util/array';
import { Emptyable } from 'common/src/util/emptyable';
import { DEFAULT_ZOOM, IGoogleCoordinates } from 'common/src/util/map';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { useEventContext } from '../../../events/show/eventContext';
import { useEventLayerUpdateMutation } from '../../../generated/graphqlHooks';
import { useLocalEventState } from '../../../hooks/useLocalState';
import { usePositionsContext } from '../positionsContext';
import { AcronymIconSelector } from './acronymIconSelector';
import { AddTrace } from './addTrace';
import { ExportFormatSelector } from './exportFormatSelector';
import { LayerSelector } from './layerSelector';
import { MapOptions } from './mapOptions';
import { POSITION_POPUP_HEIGHT, PositionPopup } from './positionPopup';
import { Traces } from './traces';

interface ISelectedPosition {
    position: PositionsPositionFragment;
    left: number;
    top: number;
}

interface IPositionsMapProps {
    center: Emptyable<IGoogleCoordinates>;
    layer: MapLayer;
    positions: PositionsPositionFragment[];
    traces: TraceFragment[];

    reload(): void;
}

export const PositionsMap = (props: IPositionsMapProps) => {
    const {
        translate,
        params: { eventId, segmentId }
    } = useHeavent();
    const { isEventAdmin, localEvent, updateLocalEvent } = useEventContext();
    const { mutate } = useEventLayerUpdateMutation();
    const { setNewPositionLatLng } = usePositionsContext();
    const [layer, setLayer] = React.useState(props.layer);
    const [hiddenTracesIds, setHiddenTracesIds] = React.useState<TraceId[]>([]);
    const [clickedPosition, setClickedPosition] = React.useState<ISelectedPosition | null>(null);
    const [isLayerSelectorOpen, setIsLayerSelectorOpen] = React.useState(false);
    const [areTracesOpen, setAreTracesOpen] = React.useState(false);
    const [isAcronymIconOpen, setIsAcronymIconOpen] = React.useState(false);
    const [isExportOpen, setIsExportOpen] = React.useState(false);
    const [center, setCenter] = useLocalEventState('positionsMap.center', props.center);
    const [zoom, setZoom] = useLocalEventState('positionsMap.zoom', DEFAULT_ZOOM);
    const [acronymIcon, setAcronymIcon] = useLocalEventState<AcronymIcon>(
        'positionsMap.acronymIcon',
        'icon'
    );
    const [addTraceOpen, setAddTraceOpen] = React.useState(false);
    const [isAlertVisible, setIsAlertVisible] = React.useState(
        localEvent?.isPositionsMapAlertVisible ?? true
    );
    const divRef = React.useRef<HTMLDivElement | null>(null);
    const onExport = React.useCallback(
        (format: string) => {
            const centerStr = center ? `${center.lat},${center.lng}` : '';
            const searchParams = new URLSearchParams();

            searchParams.set('format', format);
            searchParams.set('hiddenTracesIds', hiddenTracesIds.join(','));
            searchParams.set('center', centerStr);
            searchParams.set('acronymIcon', acronymIcon);
            searchParams.set('zoom', zoom.toString(10));

            window.open(
                `${CommonEnvVars.HEAVENT_API_URL}/image/event/${eventId}/positions/${segmentId}/map.png?${searchParams.toString()}`
            );
        },
        [center, zoom, hiddenTracesIds, acronymIcon]
    );

    return (
        <Flex css={{ flex: '1', position: 'relative' }} direction="column" width={1}>
            {isEventAdmin && isAlertVisible && (
                <>
                    <Alert
                        leftIcon="circle-exclamation"
                        rightIcon="xmark"
                        rightIconClick={async () => {
                            await updateLocalEvent({ isPositionsMapAlertVisible: false });

                            setIsAlertVisible(false);
                        }}
                    >
                        {translate('pour_cr_er_une_11133')}
                    </Alert>

                    <Spacer height="6" />
                </>
            )}

            <Box ref={divRef} css={{ flex: '1', borderRadius: '$1' }} width={1}>
                <CommonPositionsMap
                    acronymIcon={acronymIcon}
                    hiddenTracesIds={hiddenTracesIds}
                    initialCenter={center}
                    initialZoom={zoom}
                    layer={layer}
                    positions={props.positions}
                    showMarker={isEventAdmin}
                    traces={props.traces}
                    onMapCenterChanged={setCenter}
                    onMapDrag={() => {
                        setClickedPosition(null);
                    }}
                    onMapZoomchanged={(zoom) => {
                        setClickedPosition(null);
                        setZoom(zoom);
                    }}
                    onMarkerDragEnd={(latitude, longitude) => {
                        setNewPositionLatLng({ latitude, longitude });
                    }}
                    onPositionClick={(position: PositionsPositionFragment, point) => {
                        setClickedPosition({
                            position,
                            left: point.x,
                            top: point.y
                        });
                    }}
                />
            </Box>

            {isEventAdmin && (
                <Flex
                    css={{
                        bottom: '12px',
                        left: '12px',
                        position: 'absolute',
                        zIndex: 10
                    }}
                    gap="2"
                >
                    <Flex
                        align="center"
                        css={{
                            background: 'white',
                            borderRadius: '$2',
                            boxShadow: '$md',
                            cursor: 'pointer',
                            fontSize: '$textLg'
                        }}
                        height={48}
                        justify="center"
                        width={48}
                        onClick={() => {
                            divRef.current?.requestFullscreen();
                        }}
                    >
                        <I icon="expand" />
                    </Flex>

                    <MapOptions
                        isOpen={isLayerSelectorOpen}
                        onClick={() => {
                            setIsLayerSelectorOpen(!isLayerSelectorOpen);
                        }}
                    >
                        <Box
                            css={{
                                borderRadius: '$1',
                                height: '32px',
                                overflow: 'hidden',
                                width: '32px'
                            }}
                        >
                            <img
                                alt="Layer"
                                height="32px"
                                src={
                                    layer === MapLayer.Google
                                        ? Images.Maps.GooglePlan
                                        : layer === MapLayer.GoogleSatellite
                                          ? Images.Maps.GoogleSatellite
                                          : Images.Maps.IgnPlan
                                }
                                width="32px"
                            />
                        </Box>

                        <Box color="gray900" css={{ flex: '1' }}>
                            {layer === MapLayer.Google
                                ? 'Plan'
                                : layer === MapLayer.GoogleSatellite
                                  ? translate('satellite_82253')
                                  : 'IGN Plan'}
                        </Box>
                    </MapOptions>

                    <MapOptions
                        isOpen={areTracesOpen}
                        onClick={() => {
                            setAreTracesOpen(!areTracesOpen);
                        }}
                    >
                        <Flex align="center" css={{ flex: '1' }} gap="1">
                            <Box color="gray900">{translate('trac_s_41454')}</Box>

                            <Spacer width="1" />

                            {props.traces.map((trace) => (
                                <Box
                                    key={trace.id}
                                    css={{
                                        background: trace.color,
                                        borderRadius: '$1',
                                        height: '16px',
                                        width: '16px'
                                    }}
                                />
                            ))}
                        </Flex>
                    </MapOptions>

                    <MapOptions
                        isOpen={isAcronymIconOpen}
                        onClick={() => {
                            setIsAcronymIconOpen(!isAcronymIconOpen);
                        }}
                    >
                        <Flex align="center" css={{ flex: '1' }} gap="2">
                            <Box fontSize="textMd">
                                <I icon={acronymIcon === 'acronym' ? 'text' : 'location-dot'} />
                            </Box>

                            <Box color="gray900">
                                {acronymIcon === 'acronym'
                                    ? translate('acronymes_11499')
                                    : translate('ic_nes_16022')}
                            </Box>
                        </Flex>
                    </MapOptions>

                    <MapOptions
                        isOpen={isExportOpen}
                        onClick={() => {
                            setIsExportOpen(!isExportOpen);
                        }}
                    >
                        <Flex align="center" css={{ flex: '1' }} gap="2">
                            <Box fontSize="textMd">
                                <I icon="file-export" />
                            </Box>

                            <Box color="gray900">{translate('exporter_59120')}</Box>
                        </Flex>
                    </MapOptions>
                </Flex>
            )}

            {isLayerSelectorOpen && (
                <Box
                    css={{
                        bottom: '64px',
                        left: '12px',
                        position: 'absolute',
                        zIndex: 10
                    }}
                >
                    <LayerSelector
                        selectedLayer={layer}
                        onChange={async (newLayer: MapLayer) => {
                            setLayer(newLayer);

                            await mutate({
                                eventId,
                                layer: newLayer
                            });
                        }}
                        onClose={() => {
                            setIsLayerSelectorOpen(false);
                        }}
                    />
                </Box>
            )}

            {areTracesOpen && (
                <Box
                    css={{
                        bottom: '64px',
                        left: '200px',
                        position: 'absolute',
                        zIndex: 10
                    }}
                >
                    <Traces
                        eventId={eventId}
                        hiddenTracesIds={hiddenTracesIds}
                        reload={props.reload}
                        toggleVisible={(id: TraceId) => {
                            setHiddenTracesIds(toggle(hiddenTracesIds, id));
                        }}
                        traces={props.traces}
                        onAddTrace={() => {
                            setAddTraceOpen(true);
                        }}
                        onClose={() => {
                            setAreTracesOpen(false);
                        }}
                    />
                </Box>
            )}

            {addTraceOpen && (
                <AddTrace
                    eventId={eventId}
                    reload={props.reload}
                    onClose={() => {
                        setAddTraceOpen(false);
                    }}
                />
            )}

            {isAcronymIconOpen && (
                <Box
                    css={{
                        bottom: '64px',
                        left: '388px',
                        position: 'absolute',
                        zIndex: 10
                    }}
                >
                    <AcronymIconSelector
                        selectedAcronymIcon={acronymIcon}
                        onChange={setAcronymIcon}
                        onClose={() => {
                            setIsAcronymIconOpen(false);
                        }}
                    />
                </Box>
            )}

            {isExportOpen && (
                <Box
                    css={{
                        bottom: '64px',
                        left: '576px',
                        position: 'absolute',
                        zIndex: 10
                    }}
                >
                    <ExportFormatSelector
                        onClose={() => {
                            setIsExportOpen(false);
                        }}
                        onExport={onExport}
                    />
                </Box>
            )}

            {divRef.current &&
                clickedPosition &&
                createPortal(
                    <Box
                        css={{
                            left: `${clickedPosition.left + 32}px`,
                            position: 'absolute',
                            top: `${clickedPosition.top - POSITION_POPUP_HEIGHT / 2 - 16}px`,
                            zIndex: 10
                        }}
                    >
                        <PositionPopup position={clickedPosition.position} />
                    </Box>,
                    divRef.current
                )}
        </Flex>
    );
};
