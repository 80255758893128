import { Button } from 'common-front/src/designSystem/components/button';
import { DatePrevNextInput } from 'common-front/src/designSystem/components/date/datePrevNextInput';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { UserPositionsQuery } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useLocalEventState } from '../../../hooks/useLocalState';
import { UserPositionsAssignedCalendar } from './userPositionsAssignedCalendar';
import { UserPositionsAssignedList } from './userPositionsAssignedList';
import { useEventContext } from '../../../events/show/eventContext';

interface IUserPositionsAssignedProps {
    assignments: UserPositionsQuery['event']['volunteerRegistration']['positionsSlotsUsersInfos'];
    endAt: DateTime;
    startAt: DateTime;

    reload(): void;
}

export const UserPositionsAssigned = (props: IUserPositionsAssignedProps) => {
    const {
        translate,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const { isEventAdmin } = useEventContext();
    const [positionsDisplay, setPositionsDisplay] = useLocalEventState(
        'user.positions.display',
        'calendar'
    );
    const [selectedDay, setSelectedDay] = React.useState(props.startAt);

    return (
        <Flex
            css={{
                background: 'white',
                bd: true,
                overflow: 'hidden'
            }}
            direction="column"
            justify="center"
        >
            <Flex align="center" css={{ padding: '$5 $6' }}>
                <Box css={{ flex: '1' }} font="gray800 textMd medium">
                    {translate('missions_affect_97811')}
                </Box>

                <Flex gap="3">
                    {positionsDisplay === 'calendar' && (
                        <DatePrevNextInput
                            max={props.endAt}
                            min={props.startAt}
                            value={selectedDay}
                            onChange={setSelectedDay}
                        />
                    )}

                    <Box width={200}>
                        <Select value={positionsDisplay} onChange={setPositionsDisplay}>
                            <option value="calendar">{translate('calendrier_34445')}</option>
                            <option value="list">{translate('liste_06141')}</option>
                        </Select>
                    </Box>

                    {isEventAdmin && (
                        <Button
                            to={HeaventPaths.EDIT_ASSIGNMENT(organizationId, eventId, userInfoId)}
                        >
                            {translate('_diter_les_affe_42283')}
                        </Button>
                    )}
                </Flex>
            </Flex>

            {positionsDisplay === 'calendar' ? (
                <UserPositionsAssignedCalendar
                    assignments={props.assignments}
                    endAt={props.endAt}
                    selectedDay={selectedDay}
                    startAt={props.startAt}
                />
            ) : (
                <UserPositionsAssignedList assignments={props.assignments} reload={props.reload} />
            )}
        </Flex>
    );
};
