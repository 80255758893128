import { Avatar } from 'common/src/components/avatar/avatar';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { CommentsQuery } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useParams, useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import * as React from 'react';
import { DeleteComment } from './deleteComment';

interface ICommentProps {
    comment: CommentsQuery['organization']['userInfo']['comments'][0];
    showEvent: boolean;

    reload(): void;
}

export const Comment = (props: ICommentProps) => {
    const { organizationId, eventId } = useParams();
    const dateTimeService = useService(DateTimeService);
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
    const event = props.comment.event;
    const writtenBy = props.comment.writtenBy;

    return (
        <Flex gap="3">
            <Avatar
                email={writtenBy.email}
                image={writtenBy.userInfo.picture?.url}
                name={writtenBy.userInfo.name}
                size={40}
            />

            <Flex css={{ flex: '1' }} direction="column" gap="2">
                <Flex gap="2">
                    <Box color="gray800" css={{ flex: '1' }} fontWeight="medium">
                        {writtenBy.userInfo.nameOrEmail}
                    </Box>

                    <Box color="gray500">
                        {dateTimeService.toLocaleString(
                            props.comment.insertedAt.toLocal(),
                            LocaleFormats.DateTime
                        )}
                    </Box>

                    {props.showEvent && (
                        <>
                            <Box color="gray500">&#8226;</Box>

                            <Box color="gray500">{event?.name ?? 'Communauté'}</Box>
                        </>
                    )}

                    <Box color="gray500">&#8226;</Box>

                    <Box
                        color="gray500"
                        css={{ cursor: 'pointer' }}
                        onClick={() => {
                            setIsDeleteOpen(true);
                        }}
                    >
                        <I icon="trash-can" />
                    </Box>
                </Flex>

                <Box
                    color="gray800"
                    css={{
                        background: '$gray100',
                        borderRadius: '0 $2 $2 $2',
                        boxShadow: '$sm',
                        padding: '$3',
                        whiteSpace: 'pre-line'
                    }}
                >
                    {props.comment.content}
                </Box>
            </Flex>

            {isDeleteOpen && (
                <DeleteComment
                    commentId={props.comment.id}
                    eventId={eventId}
                    organizationId={organizationId}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                    onSuccess={props.reload}
                />
            )}
        </Flex>
    );
};
