import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Grid } from 'common/src/designSystem/components/grid';
import { CSS } from 'common/src/designSystem/components/stitches';
import { Emptyable } from 'common/src/util/emptyable';
import { range } from 'lodash-es';
import { DateTime, Interval } from 'luxon';
import * as React from 'react';

interface ICalendarContext {
    days: DateTime[];
    interval: Interval;
}

const CalendarContext = React.createContext<ICalendarContext>({} as any);

export function useCalendarContext() {
    return React.useContext(CalendarContext);
}

interface IRootProps {
    children?: React.ReactNode;
    css?: CSS;
    date: DateTime;
    minDate: DateTime;
    maxDate: DateTime;
    showBorder?: boolean;
}

export const Root = (props: IRootProps) => {
    const days = React.useMemo(() => {
        const startOfWeek = props.date.startOf('week');

        return range(0, 7).map((i) => startOfWeek.plus({ day: i }));
    }, [props.date]);
    const interval = React.useMemo(
        () =>
            Interval.fromDateTimes(
                props.minDate?.startOf('day') ??
                    DateTime.fromObject({ year: 1900, month: 1, day: 1 }),
                props.maxDate?.endOf('day') ??
                    DateTime.fromObject({ year: 2099, month: 12, day: 31 })
            ),
        [props.minDate, props.maxDate]
    );
    const [height, setHeight] = React.useState(300);
    const eventsRef = React.useCallback(
        (node: Emptyable<HTMLDivElement>) => {
            if (node) {
                const newHeight = Math.max(Math.ceil(node.getBoundingClientRect().height), 300);

                setHeight(newHeight);
            }
        },
        [props.date, setHeight]
    );
    const css = props.showBorder
        ? {
              bd: true,
              overflow: 'hidden'
          }
        : {};

    return (
        <CalendarContext.Provider
            value={{
                days,
                interval
            }}
        >
            <Flex
                css={{
                    ...css,
                    ...(props.css as any)
                }}
                direction="column"
            >
                <Flex css={{ borderBottom: '1px solid $gray200' }} height={60} width={1}>
                    {days.map((day) => (
                        <Flex
                            key={day.toISO()}
                            align="center"
                            css={{ background: '$gray50' }}
                            direction="column"
                            height={1}
                            justify="center"
                            width={1}
                        >
                            <Box css={{ textTransform: 'capitalize' }} font="gray500 textXs medium">
                                {day.toLocaleString({ weekday: 'long' })}
                            </Box>

                            <Box font="gray800 textXl semiBold">{day.day}</Box>
                        </Flex>
                    ))}
                </Flex>

                <Flex
                    css={{
                        position: 'relative'
                    }}
                    direction="column"
                    height={height}
                    width={1}
                >
                    <Grid
                        css={{
                            flex: '1',
                            '& > div:last-child': {
                                borderRight: 'none'
                            }
                        }}
                        gridtemplatecolumns="repeat(7, 1fr)"
                        gridtemplaterows="1fr"
                        width={1}
                    >
                        {days.map((day, index) => (
                            <Flex
                                key={day.toISO()}
                                css={{
                                    background: interval.contains(day) ? 'white' : '$primary50',
                                    borderRight: '1px solid $gray200',
                                    gridColumn: `${index + 1} / ${index + 2}`,
                                    gridRow: '1 / 2'
                                }}
                            />
                        ))}
                    </Grid>

                    <Grid
                        ref={eventsRef}
                        css={{
                            gridAutoFlow: 'column',
                            rowGap: '$2',
                            position: 'absolute',
                            top: '0',
                            py: '$2'
                        }}
                        gridtemplatecolumns="repeat(7, 1fr)"
                        width={1}
                    >
                        {props.children}
                    </Grid>
                </Flex>
            </Flex>
        </CalendarContext.Provider>
    );
};
