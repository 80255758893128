import {
    AccreditationsSlotId,
    DelegationId,
    EventId,
    OrganizationId,
    PositionId,
    PositionsCategoryId,
    VolunteerRegistrationFragment
} from 'common/src/generated/types';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AccreditationsRightPanel } from '../../components/accreditations/rightPanel/accreditationsRightPanel';
import { useHeavent } from '../../hooks/useHeavent';
import { PositionsRightPanel } from '../../positions/rightPanel/positionsRightPanel';
import { AvailabilitiesRightPanel } from '../rightPanels/availabilitiesRightPanel';

interface IVolunteerPanelsProps {
    // These must be string, not functions that can return route matching paths (with e.g. `:organizationId`)
    // because otherwise the path to render the panels matches for all users simulteneously.
    // @see comment on {@link fullScreenPopup.tsx} for more about our use of `<Route />`s for things like panels.
    accreditationsPanelPath: string;
    availabilitiesPanelPath: string;
    canAccredit: boolean;
    canCheckIn: boolean | ((categoryId: PositionsCategoryId, positionId: PositionId) => boolean);
    canDeletePosition: (categoryId: PositionsCategoryId, positionId: PositionId) => boolean;
    canEditWishedAccreditations: boolean;
    defaultAccreditationsSlotsIds: AccreditationsSlotId[];
    delegationId?: DelegationId;
    eventId: EventId;
    organizationId: OrganizationId;
    positionsPanelPath: string;
    returnPathFallback: string;
    volunteerRegistration: VolunteerRegistrationFragment;

    reloadRow(): void;
}

export const VolunteerPanels = (props: IVolunteerPanelsProps) => {
    const { history } = useHeavent();

    return (
        <Switch>
            <Route path={props.accreditationsPanelPath}>
                <AccreditationsRightPanel
                    basePath={props.accreditationsPanelPath}
                    canAccredit={props.canAccredit}
                    canEditWished={props.canEditWishedAccreditations}
                    defaultAccreditationsSlotsIds={props.defaultAccreditationsSlotsIds}
                    delegationId={props.delegationId}
                    eventId={props.eventId}
                    userInfoId={props.volunteerRegistration.userInfo.id}
                    volunteerRegistration={props.volunteerRegistration}
                    onClose={() => {
                        props.reloadRow();

                        history.goBack(props.returnPathFallback);
                    }}
                />
            </Route>

            <Route path={props.availabilitiesPanelPath}>
                <AvailabilitiesRightPanel
                    delegationId={props.delegationId}
                    eventId={props.eventId}
                    organizationId={props.organizationId}
                    returnPathFallback={props.availabilitiesPanelPath}
                    userInfoId={props.volunteerRegistration.userInfo.id}
                />
            </Route>

            <Route path={props.positionsPanelPath}>
                <PositionsRightPanel
                    basePath={props.positionsPanelPath}
                    canCheckIn={props.canCheckIn}
                    canDelete={props.canDeletePosition}
                    eventId={props.eventId}
                    organizationId={props.organizationId}
                    volunteerRegistration={props.volunteerRegistration}
                    onClose={() => {
                        props.reloadRow();

                        history.goBack(props.returnPathFallback);
                    }}
                />
            </Route>
        </Switch>
    );
};
