import { uniqueId } from 'lodash';
import * as React from 'react';

type Group = {
    id: string;
    type: 'group';
    text: string;
};

type Option<T> = {
    id: string;
    type: 'option';
    text: string;
    value: T;
    selected?: boolean;
    radio?: string;
    style?: Pick<React.HTMLAttributes<HTMLOptionElement>, 'style'>;
};

type ParseSelectChildrenResult<T> = Array<Group | Option<T>>;

export function parseSelectChildren<T>(children: React.ReactNode): ParseSelectChildrenResult<T> {
    return React.Children.toArray(children)
        .map((child: any) => {
            if (child.type === 'optgroup') {
                return [
                    {
                        id: uniqueId(),
                        type: 'group',
                        text: child.props.label
                    }
                ].concat(parseSelectChildren(child.props.children));
            } else if (child.type === 'option') {
                return {
                    id: uniqueId(),
                    type: 'option',
                    text: child.props.children,
                    value: child.props.value,
                    selected: child.props.selected,
                    radio: child.props['data-radio'],
                    style: child.props.style
                };
            } else if (child.type === Symbol.for('react.fragment')) {
                return parseSelectChildren(child.props.children);
            } else {
                throw new Error('Unexpected child type');
            }
        })
        .flat() as ParseSelectChildrenResult<T>;
}

export function getValueToName<T>(parseSelectChildrenResult: ParseSelectChildrenResult<T>) {
    return Object.fromEntries(
        parseSelectChildrenResult
            .filter((c) => c.type === 'option')
            .map((c: any) => [c.value, c.text])
    );
}
