import { Accordion } from 'common-front/src/designSystem/components/accordion';
import { ColorInput } from 'common-front/src/designSystem/components/colorInput';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { ToggleText } from 'common-front/src/designSystem/components/toggle';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { DocumentElement } from 'common/src/vo/documentBuilder';
import * as React from 'react';
import { useDocumentBuilderContext } from '../documentBuilderContext';
import { DocumentBuilderLeftPanelConditions } from './documentBuilderLeftPanelConditions';

interface IDocumentBuilderLeftPanelElementProps {
    element: DocumentElement;
}

export const DocumentBuilderLeftPanelElement = (props: IDocumentBuilderLeftPanelElementProps) => {
    const { translate } = useHeavent();
    const { updateElementProperty } = useDocumentBuilderContext();

    return (
        <>
            <Accordion css={{ padding: '$4 $6' }} title={translate('apparence_75510')}>
                <Flex direction="column" gap="4">
                    <Flex gap="4">
                        <TextInput
                            label={translate('largeur_54184')}
                            rightText={props.element.width <= 1 ? '%' : 'px'}
                            shouldParseAsInt={true}
                            value={props.element.width}
                            onChange={(newWidth) => {
                                updateElementProperty(props.element.id, 'width', newWidth);
                            }}
                        />

                        <TextInput
                            label={translate('hauteur_56576')}
                            rightText={props.element.height <= 1 ? '%' : 'px'}
                            shouldParseAsInt={true}
                            value={props.element.height}
                            onChange={(newHeight) => {
                                updateElementProperty(props.element.id, 'height', newHeight);
                            }}
                        />
                    </Flex>

                    {!props.element.inContainer && (
                        <Flex gap="4">
                            <TextInput
                                label="X"
                                rightText="px"
                                shouldParseAsInt={true}
                                value={props.element.left}
                                onChange={(newLeft) => {
                                    updateElementProperty(props.element.id, 'left', newLeft);
                                }}
                            />

                            <TextInput
                                label="Y"
                                rightText="px"
                                shouldParseAsInt={true}
                                value={props.element.top}
                                onChange={(newTop) => {
                                    updateElementProperty(props.element.id, 'top', newTop);
                                }}
                            />
                        </Flex>
                    )}

                    {!props.element.inContainer && (
                        <TextInput
                            hint={translate('la_position_z_d_29473')}
                            label={translate('position_z_du_c_46647')}
                            shouldParseAsInt={true}
                            value={props.element.zIndex}
                            onChange={(newZIndex) => {
                                updateElementProperty(props.element.id, 'zIndex', newZIndex);
                            }}
                        />
                    )}

                    {(props.element.type === 'image' || props.element.type === 'text') &&
                        !props.element.inContainer && (
                            <>
                                <ToggleText
                                    value={props.element.hasRotate}
                                    onChange={(newhasRotate) => {
                                        updateElementProperty(
                                            props.element.id,
                                            'hasRotate',
                                            newhasRotate
                                        );
                                    }}
                                >
                                    {translate('faire_pivoter_06431')}
                                </ToggleText>

                                {props.element.hasRotate && (
                                    <TextInput
                                        label={translate('degr_79492')}
                                        rightText="deg"
                                        shouldParseAsInt={true}
                                        value={props.element.rotateDegree}
                                        onChange={(newRotateDegree) => {
                                            updateElementProperty(
                                                props.element.id,
                                                'rotateDegree',
                                                newRotateDegree
                                            );
                                        }}
                                    />
                                )}
                            </>
                        )}

                    {(props.element.type === 'container' || props.element.type === 'text') && (
                        <>
                            <ToggleText
                                value={props.element.hasBackground}
                                onChange={(newHasBackground) => {
                                    updateElementProperty(
                                        props.element.id,
                                        'hasBackground',
                                        newHasBackground
                                    );
                                }}
                            >
                                {translate('afficher_un_arr_96006')}
                            </ToggleText>

                            {props.element.hasBackground && (
                                <ColorInput
                                    label={translate('couleur_de_fond_75677')}
                                    value={props.element.backgroundColor}
                                    onChange={(newColor) => {
                                        updateElementProperty(
                                            props.element.id,
                                            'backgroundColor',
                                            newColor
                                        );
                                    }}
                                />
                            )}
                        </>
                    )}

                    <Flex gap="4">
                        <TextInput
                            label={translate('taille_de_la_bo_84060')}
                            rightText="px"
                            shouldParseAsInt={true}
                            value={props.element.borderSize}
                            onChange={(newBorderSize) => {
                                updateElementProperty(
                                    props.element.id,
                                    'borderSize',
                                    newBorderSize
                                );
                            }}
                        />

                        <ColorInput
                            label={translate('couleur_de_la_b_84227')}
                            value={props.element.borderColor}
                            onChange={(newBorderColor) => {
                                updateElementProperty(
                                    props.element.id,
                                    'borderColor',
                                    newBorderColor
                                );
                            }}
                        />
                    </Flex>

                    <TextInput
                        label={translate('rayon_de_la_bor_26070')}
                        rightText="px"
                        shouldParseAsInt={true}
                        value={props.element.borderRadius}
                        onChange={(newBorderRadius) => {
                            updateElementProperty(
                                props.element.id,
                                'borderRadius',
                                newBorderRadius
                            );
                        }}
                    />

                    <Flex gap="4">
                        <TextInput
                            label={translate('padding_horizon_14268')}
                            rightText="px"
                            shouldParseAsInt={true}
                            value={props.element.px}
                            onChange={(newPx) => {
                                updateElementProperty(props.element.id, 'px', newPx);
                            }}
                        />

                        <TextInput
                            label={translate('padding_vertica_78457')}
                            rightText="px"
                            shouldParseAsInt={true}
                            value={props.element.py}
                            onChange={(newPy) => {
                                updateElementProperty(props.element.id, 'py', newPy);
                            }}
                        />
                    </Flex>
                </Flex>
            </Accordion>

            <DocumentBuilderLeftPanelConditions
                element={props.element}
                elementId={props.element.id}
                updateProperty={updateElementProperty}
            />
        </>
    );
};
