import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Table } from 'common/src/designSystem/components/table/table';
import { UserPositionsQuery } from 'common/src/generated/types';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import { UserPositionsAssignedListRow } from './userPositionsAssignedListRow';

interface IUserPositionsAssignedListProps {
    assignments: UserPositionsQuery['event']['volunteerRegistration']['positionsSlotsUsersInfos'];

    reload(): void;
}

export const UserPositionsAssignedList = (props: IUserPositionsAssignedListProps) => {
    const { translate } = useHeavent();
    const { isPositionAdmin, isPositionViewer } = useEventContext();
    const showActions = isPositionAdmin() || isPositionViewer();

    return (
        <Table css={{ borderTop: '1px solid $gray200' }} hideBorder={true}>
            <HeaderRow>
                <HeaderCell>{translate('Position')}</HeaderCell>
                <HeaderCell>{translate('statut_du_point_52451')}</HeaderCell>
                {showActions && <HeaderCell width={200} />}
            </HeaderRow>

            {props.assignments.map((psui) => (
                <UserPositionsAssignedListRow key={psui.id} psui={psui} reload={props.reload} />
            ))}
        </Table>
    );
};
