import { useUserPositionsQuery } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { UserPositionsWished } from 'common-front/src/users/information/userPositionsWished';
import { Flex } from 'common/src/designSystem/components/flex';
import { UserPositionsQuery } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import { UserPositionsAssigned } from './userPositionsAssigned';
import { UserPositionsAvailablePositionsSlots } from './userPositionsAvailablePositionsSlots';
import { UserPositionsPreassigned } from './userPositionsPreassigned';
import { UserPositionsStats } from './userPositionsStats';

interface IUserPositionsComponentProps {
    event: UserPositionsQuery['event'];
    organization: UserPositionsQuery['organization'];

    reload(): void;
    reloadHeader(): void;
}

const UserPositionsComponent = (props: IUserPositionsComponentProps) => {
    const {
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const { isPositionAdmin } = useEventContext();
    const assignments = React.useMemo(
        () =>
            sortBy(props.event.volunteerRegistration.positionsSlotsUsersInfos, (psui) =>
                psui.positionSlot.range.start!.toMillis()
            ),
        [props.event]
    );

    return (
        <Flex direction="column" gap="6" width={1}>
            {isNonEmptyArray(assignments) && (
                <UserPositionsAssigned
                    assignments={assignments}
                    endAt={props.event.endAt}
                    reload={() => {
                        props.reload();

                        if (assignments.length <= 1) {
                            props.reloadHeader();
                        }
                    }}
                    startAt={props.event.startAt}
                />
            )}

            <UserPositionsPreassigned volunteerRegistration={props.event.volunteerRegistration} />

            {props.event.hasFormWithPositionDisplay && (
                <UserPositionsWished
                    editPath={HeaventPaths.EDIT_USER_MISSIONS(organizationId, eventId, userInfoId)}
                    formsUsersInfos={props.organization.userInfo.formsUsersInfos}
                />
            )}

            {isPositionAdmin() && (
                <UserPositionsAvailablePositionsSlots
                    reload={props.reload}
                    userInfoId={userInfoId}
                />
            )}

            {isNonEmptyArray(assignments) && (
                <UserPositionsStats
                    numberOfAssignments={assignments.length}
                    volunteerRegistration={props.event.volunteerRegistration}
                />
            )}
        </Flex>
    );
};

interface IUserPositionsProps {
    reload(): void;
}

export const UserPositions = (props: IUserPositionsProps) => {
    const {
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const { data, loader, reload } = useUserPositionsQuery({
        organizationId,
        eventId,
        userInfoId
    });

    return (
        loader || (
            <UserPositionsComponent
                event={data.event}
                organization={data.organization}
                reload={reload}
                reloadHeader={props.reload}
            />
        )
    );
};
