import { FieldName } from 'common-front/src/components/users/show/fieldName';
import { UserField } from 'common-front/src/components/users/show/userField';
import { Accordion } from 'common-front/src/designSystem/components/accordion';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { CustomFieldWithConditionFragment, FormId, UsersInfo } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { getFormField } from 'common/src/vo/form';
import * as React from 'react';
import { useLocalEventState } from '../../../hooks/useLocalState';

interface IAssignmentUserPanelInfosProps {
    customFields: CustomFieldWithConditionFragment[];
    formsIds: FormId[];
    title: string;
    userInfo: Pick<UsersInfo, 'email' | 'fields'>;
}

export const AssignmentUserPanelInfos = (props: IAssignmentUserPanelInfosProps) => {
    const translate = useTranslate();
    const [isOpen, setIsOpen] = useLocalEventState(
        'assignment.rightPanel.areInformationsOpen',
        true
    );

    return (
        <Accordion isOpen={isOpen} setIsOpen={setIsOpen} title={props.title}>
            <Flex direction="column" gap="1">
                <FieldName>{translate('Email')}</FieldName>
                <Box
                    color="gray500"
                    css={{
                        ellipsis: true,
                        fontSize: '$textMd',
                        '@tablet': {
                            fontSize: '$textSm'
                        }
                    }}
                >
                    {props.userInfo.email}
                </Box>
            </Flex>

            {props.customFields.map((customField) => (
                <UserField
                    key={customField.id}
                    field={customField}
                    fields={props.userInfo.fields}
                />
            ))}

            {props.formsIds.map((formId) => {
                const formField = getFormField(formId, translate);

                if (isNonEmptyString(props.userInfo.fields[formField.slug])) {
                    return (
                        <UserField
                            key={formField.slug}
                            field={formField}
                            fields={props.userInfo.fields}
                        />
                    );
                } else {
                    return null;
                }
            })}
        </Accordion>
    );
};
