import { Box } from 'common/src/designSystem/components/box';
import {
    AccreditationProperty,
    ALL_LANGUAGE,
    ALL_SEX,
    CustomFieldProperty,
    DelegationProperty,
    FieldProperty,
    FieldType,
    Form,
    MassEditCustomFieldFragment,
    PositionProperty,
    Tag,
    Trace
} from 'common/src/generated/types';
import { DocumentInputService } from 'common/src/input/documentInput';
import { PhoneInputService } from 'common/src/input/phoneInput';
import { CountriesService } from 'common/src/services/countriesService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { isNonEmptyString } from 'common/src/util/string';
import { USABLE_LANGUAGES } from 'common/src/vo/supportedLanguage';
import { DateTime } from 'luxon';
import * as React from 'react';
import { ColorInput } from '../../designSystem/form/colorInput';
import { DateInput } from '../../designSystem/form/date/dateInput';
import { DatetimeInput } from '../../designSystem/form/date/datetimeInput';
import { TimeInput } from '../../designSystem/form/date/timeInput';
import { FileS3Input } from '../../designSystem/form/file/fileS3Input';
import { FloatInput } from '../../designSystem/form/floatInput';
import { IconSelect } from '../../designSystem/form/iconSelect';
import { PhoneInput } from '../../designSystem/form/phoneInput';
import { RichEditor } from '../../designSystem/form/richEditor';
import { RichSelect } from '../../designSystem/form/richSelect';
import { Select } from '../../designSystem/form/select/select';
import { Textarea } from '../../designSystem/form/textarea';
import { TextInput } from '../../designSystem/form/textInput';
import { useEnumToOptions } from '../../hooks/useEnumToOptions';
import { useHeavent } from '../../hooks/useHeavent';
import { Accept } from '../../util/accept';

interface IMassEditFieldInputProps {
    categories: Array<{ id: number; name: string }>;
    customField: Emptyable<MassEditCustomFieldFragment>;
    forms: Array<Pick<Form, 'id' | 'name'>>;
    name: string;
    slug: string;
    tags: Array<Pick<Tag, 'id' | 'name'>>;
    traces: Array<Pick<Trace, 'id' | 'name'>>;
    value: any;

    change(name: string, value: any): void;
}

export const MassEditFieldInput = (props: IMassEditFieldInputProps) => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();
    const documentInput = useService(DocumentInputService);
    const phoneInput = useService(PhoneInputService);
    const countriesService = useService(CountriesService);
    const enumToOptions = useEnumToOptions();

    React.useEffect(() => {
        if (
            props.slug === AccreditationProperty.Acronym ||
            props.slug === AccreditationProperty.Address ||
            props.slug === AccreditationProperty.Color ||
            props.slug === AccreditationProperty.Description ||
            props.slug === AccreditationProperty.Icon ||
            props.slug === AccreditationProperty.Name ||
            props.slug === AccreditationProperty.WeezeventChecklistName ||
            props.slug === PositionProperty.Acronym ||
            props.slug === PositionProperty.Address ||
            props.slug === PositionProperty.Color ||
            props.slug === PositionProperty.Description ||
            props.slug === PositionProperty.Icon ||
            props.slug === PositionProperty.Name ||
            props.slug === DelegationProperty.Name ||
            props.customField?.fieldType === FieldType.Address ||
            props.customField?.fieldType === FieldType.Country ||
            props.customField?.fieldType === FieldType.Language ||
            props.customField?.fieldType === FieldType.Nationality ||
            props.customField?.fieldType === FieldType.Sex ||
            props.customField?.fieldType === FieldType.Text ||
            props.customField?.fieldType === FieldType.Textarea ||
            props.customField?.fieldType === FieldType.Time
        ) {
            if (typeof props.value !== 'string') {
                props.change(props.name, '');
            }
        } else if (
            props.slug === AccreditationProperty.AutoAccredit ||
            props.slug === AccreditationProperty.WeezeventIsSynchronized ||
            props.slug === CustomFieldProperty.Privacy ||
            props.slug === DelegationProperty.IsLocked ||
            props.slug === DelegationProperty.ShowInDelegationSpace ||
            props.slug === DelegationProperty.CanLeadersAddMembers ||
            props.slug === DelegationProperty.CanLeadersEditCustomFields ||
            props.slug === DelegationProperty.CanLeadersEditMembers ||
            props.slug === DelegationProperty.ShowFormLinkInDelegationSpace ||
            props.customField?.fieldType === FieldType.Checkbox ||
            props.customField?.fieldType === FieldType.Validation
        ) {
            if (typeof props.value !== 'boolean') {
                props.change(props.name, false);
            }
        } else if (
            props.slug === AccreditationProperty.Category ||
            props.slug === DelegationProperty.Category ||
            props.slug === PositionProperty.Category
        ) {
            if (typeof props.value !== 'number') {
                props.change(props.name, props.categories[0]?.id);
            }
        } else if (
            props.slug === AccreditationProperty.Latitude ||
            props.slug === AccreditationProperty.Longitude ||
            props.slug === PositionProperty.Latitude ||
            props.slug === PositionProperty.Longitude ||
            props.slug === DelegationProperty.MaxResources ||
            props.customField?.fieldType === FieldType.Number
        ) {
            if (typeof props.value !== 'number') {
                props.change(props.name, 0);
            }
        } else if (
            props.slug === AccreditationProperty.Tag ||
            props.slug === PositionProperty.Tag ||
            props.slug === DelegationProperty.Tag ||
            props.slug === PositionProperty.Traces
        ) {
            if (!Array.isArray(props.value)) {
                props.change(props.name, []);
            }
        } else if (props.slug === AccreditationProperty.WeezeventNumberOfAccess) {
            if (typeof props.value !== 'number') {
                props.change(props.name, 1);
            }
        } else if (
            props.slug === DelegationProperty.DeadlineDate ||
            props.customField?.fieldType === FieldType.Date ||
            props.customField?.fieldType === FieldType.Datetime
        ) {
            if (!DateTime.isDateTime(props.value) && !isNonEmptyString(props.value)) {
                props.change(props.name, DateTime.invalid('Invalid'));
            }
        } else if (props.customField?.fieldType === FieldType.File) {
            if (props.value === null || typeof props.value !== 'object') {
                props.change(props.name, documentInput.documentInputDefault());
            }
        } else if (props.customField?.fieldType === FieldType.Phone) {
            if (props.value === null || typeof props.value !== 'object') {
                props.change(props.name, phoneInput.basePhoneInputDefault());
            }
        } else if (props.customField?.fieldType === FieldType.Select) {
            if (props.customField.canSelectMultiple && !Array.isArray(props.value)) {
                props.change(props.name, []);
            } else if (!props.customField.canSelectMultiple && typeof props.value !== 'number') {
                props.change(props.name, '');
            }
        } else if (props.slug === DelegationProperty.Form) {
            if (typeof props.value !== 'number') {
                props.change('massEdit.value', props.forms[0].id);
            }
        } else {
            // do nothing
        }
    }, [props.customField, props.slug]);

    if (
        props.slug === AccreditationProperty.Acronym ||
        props.slug === PositionProperty.Acronym ||
        props.slug === AccreditationProperty.Address ||
        props.slug === PositionProperty.Address ||
        props.slug === AccreditationProperty.WeezeventChecklistName ||
        props.customField?.fieldType === FieldType.Address ||
        props.customField?.fieldType === FieldType.Text
    ) {
        return <TextInput name={props.name} />;
    } else if (
        props.slug === AccreditationProperty.AutoAccredit ||
        props.slug === AccreditationProperty.WeezeventIsSynchronized ||
        props.slug === DelegationProperty.IsLocked ||
        props.slug === DelegationProperty.ShowFormLinkInDelegationSpace ||
        props.slug === DelegationProperty.ShowInDelegationSpace ||
        props.slug === DelegationProperty.CanLeadersAddMembers ||
        props.slug === DelegationProperty.CanLeadersEditCustomFields ||
        props.slug === DelegationProperty.CanLeadersEditMembers ||
        props.customField?.fieldType === FieldType.Checkbox ||
        props.customField?.fieldType === FieldType.Validation
    ) {
        return (
            <Select name={props.name} shouldParseAsBoolean={true}>
                <option value="true">{translate('oui_54361')}</option>
                <option value="false">{translate('non_33516')}</option>
            </Select>
        );
    } else if (props.slug === CustomFieldProperty.Privacy) {
        return (
            <Select name={props.name} shouldParseAsBoolean={true}>
                <option value="true">{translate('Private')}</option>
                <option value="false">{translate('Public')}</option>
            </Select>
        );
    } else if (
        props.slug === AccreditationProperty.Category ||
        props.slug === DelegationProperty.Category ||
        props.slug === PositionProperty.Category
    ) {
        return (
            <RichSelect isSearchVisible={true} name={props.name}>
                {props.categories.map((category) => (
                    <option key={category.id} value={category.id}>
                        {category.name}
                    </option>
                ))}
            </RichSelect>
        );
    } else if (
        props.slug === AccreditationProperty.Color ||
        props.slug === PositionProperty.Color
    ) {
        return <ColorInput name={props.name} />;
    } else if (
        props.slug === AccreditationProperty.Description ||
        props.slug === PositionProperty.Description
    ) {
        return (
            <Box css={{ overflow: 'hidden auto' }} width={1}>
                <RichEditor name={props.name} />
            </Box>
        );
    } else if (props.slug === AccreditationProperty.Icon || props.slug === PositionProperty.Icon) {
        return <IconSelect name={props.name} />;
    } else if (
        props.slug === AccreditationProperty.Latitude ||
        props.slug === PositionProperty.Latitude
    ) {
        return <FloatInput name={props.name} />;
    } else if (
        props.slug === AccreditationProperty.Longitude ||
        props.slug === PositionProperty.Longitude
    ) {
        return <FloatInput name={props.name} />;
    } else if (props.slug === AccreditationProperty.Name || props.slug === PositionProperty.Name) {
        throw new Error('Should not happen');
    } else if (
        props.slug === AccreditationProperty.Tag ||
        props.slug === PositionProperty.Tag ||
        props.slug === DelegationProperty.Tag
    ) {
        return (
            <RichSelect isSearchVisible={true} multiple={true} name={props.name}>
                {props.tags.map((tag) => (
                    <option key={tag.id} value={tag.id}>
                        {tag.name}
                    </option>
                ))}
            </RichSelect>
        );
    } else if (props.slug === PositionProperty.Traces) {
        return (
            <RichSelect isSearchVisible={true} multiple={true} name={props.name}>
                {props.traces.map((trace) => (
                    <option key={trace.id} value={trace.id}>
                        {trace.name}
                    </option>
                ))}
            </RichSelect>
        );
    } else if (
        props.slug === AccreditationProperty.WeezeventNumberOfAccess ||
        props.slug === DelegationProperty.MaxResources ||
        props.customField?.fieldType === FieldType.Number
    ) {
        return <TextInput name={props.name} shouldParseAsInt={true} />;
    } else if (props.customField?.fieldType === FieldType.Country) {
        return (
            <RichSelect isSearchVisible={true} name={props.name}>
                {countriesService.getCountriesIdName().map(({ id, name }) => (
                    <option key={id} value={id}>
                        {name}
                    </option>
                ))}
            </RichSelect>
        );
    } else if (
        props.slug === DelegationProperty.DeadlineDate ||
        props.customField?.fieldType === FieldType.Date
    ) {
        return <DateInput name={props.name} />;
    } else if (props.customField?.fieldType === FieldType.Datetime) {
        return <DatetimeInput name={props.name} />;
    } else if (props.customField?.fieldType === FieldType.File) {
        const accept =
            props.customField.fieldProperty === FieldProperty.Picture
                ? [Accept.Images]
                : [Accept.Images, Accept.Pdf];
        const acl =
            props.customField.fieldProperty === FieldProperty.Picture ? 'public-read' : 'private';

        return (
            <FileS3Input
                accept={accept}
                acl={acl}
                organizationId={organizationId}
                prefix={`${props.name}.`}
            />
        );
    } else if (props.customField?.fieldType === FieldType.Language) {
        if (props.customField.fieldProperty === FieldProperty.Language) {
            return (
                <Select name={props.name}>
                    <option value="">{translate('s_lectionnez_un_31086')}</option>
                    {enumToOptions(USABLE_LANGUAGES)}
                </Select>
            );
        } else {
            return (
                <Select name={props.name}>
                    <option value="">{translate('s_lectionnez_un_31086')}</option>
                    {enumToOptions(ALL_LANGUAGE)}
                </Select>
            );
        }
    } else if (props.customField?.fieldType === FieldType.Nationality) {
        return (
            <RichSelect isSearchVisible={true} name={props.name}>
                {countriesService.getNationalitiesIdName().map(({ id, name }) => (
                    <option key={id} value={id}>
                        {name}
                    </option>
                ))}
            </RichSelect>
        );
    } else if (props.customField?.fieldType === FieldType.Phone) {
        return <PhoneInput prefix={`${props.name}.`} />;
    } else if (props.customField?.fieldType === FieldType.Select) {
        if (props.customField.canSelectMultiple) {
            return (
                <RichSelect isSearchVisible={true} multiple={true} name={props.name}>
                    {props.customField.values.map(({ id, value }) => (
                        <option key={id} value={id}>
                            {value}
                        </option>
                    ))}
                </RichSelect>
            );
        } else {
            return (
                <RichSelect
                    isSearchVisible={true}
                    name={props.name}
                    placeholder={translate('s_lectionnez_un_44130')}
                >
                    {props.customField.values.map(({ id, value }) => (
                        <option key={id} value={id}>
                            {value}
                        </option>
                    ))}
                </RichSelect>
            );
        }
    } else if (props.customField?.fieldType === FieldType.Sex) {
        return (
            <Select name={props.name}>
                <option value="">{translate('s_lectionnez_un_43620')}</option>
                {enumToOptions(ALL_SEX)}
            </Select>
        );
    } else if (props.customField?.fieldType === FieldType.Textarea) {
        return <Textarea name={props.name} />;
    } else if (props.customField?.fieldType === FieldType.Time) {
        return <TimeInput name={props.name} />;
    } else if (props.slug === DelegationProperty.Form) {
        return (
            <RichSelect isSearchVisible={true} multiple={false} name="massEdit.value">
                {props.forms.map((form) => (
                    <option key={form.id} value={form.id}>
                        {form.name}
                    </option>
                ))}
            </RichSelect>
        );
    } else {
        return null;
    }
};
