import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { copy } from 'common-front/src/util/commands';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { LinkText } from 'common/src/designSystem/components/linkText';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { getBarcodeHref, getFormHref } from 'common/src/util/url';
import * as React from 'react';
import { useFormQuery } from '../../../generated/graphqlHooks';
import { FormContainer } from '../formContainer';

export const FormIntegration = () => {
    const {
        translate,
        params: { organizationId, eventId, formId }
    } = useHeavent();
    const { data } = useFormQuery({ organizationId, formId });
    useTitle(data.organization?.form.name ?? '');
    const [isLoading, setIsLoading] = React.useState(false);
    const link = getFormHref({ organizationId, eventId, formId });
    const iframe = `<iframe
        height="100%"
        width="100%"
        src="${link}"
        style="border: none;"></iframe>`.replace(/\s+/g, ' ');

    return (
        <FormContainer title={data.organization?.form.name ?? '-'}>
            <Flex justify="center" width={1}>
                <Flex direction="column" width="$container">
                    <Spacer height="8" />

                    <Box color="gray700" fontWeight="medium">
                        {translate('lien_du_formula_51477')}
                    </Box>

                    <Spacer height="1" />

                    <LinkText css={{ color: '$primary700' }} to={link}>
                        {link}
                    </LinkText>

                    <Spacer height="6" />

                    <Box color="gray700" fontWeight="medium">
                        {translate('code_html_pour_00029')}
                    </Box>

                    <Spacer height="1" />

                    <Box
                        css={{
                            '& pre': {
                                border: '1px solid $gray300',
                                borderRadius: '$2',
                                padding: '$3'
                            }
                        }}
                    >
                        <pre>{iframe}</pre>
                    </Box>

                    <Spacer height="2" />

                    <Flex>
                        <Button
                            isLoading={isLoading}
                            leftIcon="copy"
                            onClick={() => {
                                setIsLoading(true);
                                copy(iframe);

                                setTimeout(() => {
                                    setIsLoading(false);
                                }, 500);
                            }}
                        >
                            {translate('copier_le_code_99588')}
                        </Button>
                    </Flex>

                    <Spacer height="6" />

                    <Box color="gray700" fontWeight="medium">
                        {translate('qr_code_du_lien_34278')}
                    </Box>

                    <Spacer height="1" />

                    <Box height={100} width={100}>
                        <img
                            height="100%"
                            src={getBarcodeHref(link, { height: 100, width: 100 })}
                            width="100%"
                        />
                    </Box>
                </Flex>
            </Flex>
        </FormContainer>
    );
};
