import * as React from 'react';
import { Flex } from '../../designSystem/components/flex';
import {
    CustomBadgeVolunteerRegistrationFragment,
    CustomDocument as CustomDocumentVO,
    CustomFieldWithConditionFragment,
    Event
} from '../../generated/types';
import { CustomDocument, shouldDisplayElement } from '../../vo/documentBuilder';
import { UserCustomDocumentElement } from './userCustomDocumentElement';

interface IUserCustomDocumentProps {
    customDocument: Pick<CustomDocumentVO, 'configuration'>;
    customFields: CustomFieldWithConditionFragment[];
    event: Pick<Event, 'id' | 'organizationId' | 'name' | 'startAt' | 'endAt'>;
    volunteerRegistration: CustomBadgeVolunteerRegistrationFragment;
}

export const UserCustomDocument = (props: IUserCustomDocumentProps) => {
    const configuration: CustomDocument['configuration'] = props.customDocument.configuration;
    const accreditationsSlotsIds = props.volunteerRegistration.accreditationsUsersInfos.map(
        (aui) => aui.accreditationSlot.id
    );
    const positionsSlotsIds = props.volunteerRegistration.positionsSlotsUsersInfos.map(
        (psui) => psui.positionSlot.id
    );

    return (
        <>
            {configuration.pages.map((page) => {
                if (
                    !shouldDisplayElement(
                        page,
                        accreditationsSlotsIds,
                        positionsSlotsIds,
                        props.volunteerRegistration.userInfo.fields
                    )
                ) {
                    return null;
                } else {
                    return (
                        <Flex
                            key={page.id}
                            css={{
                                background: 'white',
                                pageBreakBefore: 'always',
                                position: 'relative'
                            }}
                            height={configuration.height}
                            width={configuration.width}
                        >
                            {page.elements.map((element) => (
                                <UserCustomDocumentElement
                                    key={element.id}
                                    accreditationsSlotsIds={accreditationsSlotsIds}
                                    customFields={props.customFields}
                                    element={element}
                                    event={props.event}
                                    positionsSlotsIds={positionsSlotsIds}
                                    vr={props.volunteerRegistration}
                                />
                            ))}
                        </Flex>
                    );
                }
            })}
        </>
    );
};
