import { DateTime } from 'luxon';
import * as React from 'react';
import { Box } from '../../designSystem/components/box';
import { Flex } from '../../designSystem/components/flex';
import { Grid } from '../../designSystem/components/grid';
import { CertificateDocumentOptions, DocumentUserCertificateFragment } from '../../generated/types';
import { DateTimeService } from '../../services/dateTimeService';
import { IntervalService } from '../../services/intervalService';
import { useService, useTranslate } from '../../util/dependencies/dependencies';
import { LocaleFormats } from '../../util/luxon';
import { isNonEmptyString } from '../../util/string';

interface IUserCertificateProps {
    certificateDocumentFragment: DocumentUserCertificateFragment;
    options: CertificateDocumentOptions;
}

export const UserCertificate = (props: IUserCertificateProps) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);
    const intervalService = useService(IntervalService);
    const now = DateTime.local();
    const userInfo = props.certificateDocumentFragment.volunteerRegistration.userInfo;

    return (
        <Flex
            css={{ background: 'white', padding: '$7 56px' }}
            direction="column"
            gap="5"
            width={1}
        >
            {isNonEmptyString(props.options.header.content) && (
                <Box
                    css={{
                        borderRadius: '$1',
                        boxShadow: '$xs',
                        overflow: 'hidden'
                    }}
                    height={260}
                >
                    <img height="100%" src={props.options.header.content} width="100%" />
                </Box>
            )}

            <Box color="gray900" fontSize="textXl" fontWeight="semiBold" textAlign="center">
                {translate('attestation_de_92734')}
            </Box>

            <Flex
                css={{
                    bd: true,
                    padding: '$4'
                }}
                direction="column"
                gap="6"
            >
                <Box color="gray800" fontWeight="semiBold">
                    {translate('je_soussign_e_04371', props.options.name)} <br />
                    {props.options.title}
                </Box>

                <Box
                    css={{
                        '& > span': {
                            fontWeight: '$semiBold'
                        }
                    }}
                >
                    {translate('certifie_que_13123')} <span>{userInfo.name}</span>
                    <br />
                    {translate('n_e_le_14393')}{' '}
                    <span>
                        {isNonEmptyString(userInfo.dateOfBirth)
                            ? dateTimeService.toLocaleString(
                                  DateTime.fromISO(userInfo.dateOfBirth),
                                  LocaleFormats.DateOnly.MonthLong
                              )
                            : ''}
                    </span>
                    <br />
                    {translate('demeurant_78485')}{' '}
                    <span>{isNonEmptyString(userInfo.street) ? userInfo.street : ''}</span>
                    <br />
                    {translate('commune_48881')}{' '}
                    <span>{isNonEmptyString(userInfo.city) ? userInfo.city : ''}</span>
                    <br />
                    {translate('code_postal_74563')}{' '}
                    <span>{isNonEmptyString(userInfo.postalCode) ? userInfo.postalCode : ''}</span>
                </Box>

                <Box color="gray800" fontWeight="semiBold">
                    {translate('a_t_b_n_vole_58773')}
                </Box>

                <Box
                    dangerouslySetInnerHTML={{
                        __html: props.certificateDocumentFragment.volunteerRegistration.positionsSlotsUsersInfos
                            .map(
                                ({ position, positionSlot }) =>
                                    `${position.name} : ${intervalService.toDisplayString(
                                        positionSlot.range
                                    )}`
                            )
                            .join('<br />')
                    }}
                />

                <Box
                    color="gray800"
                    css={{
                        whiteSpace: 'pre-wrap',
                        '& > span': {
                            fontWeight: '$semiBold'
                        }
                    }}
                >
                    <span>{translate('nom_et_adresse_96441')}</span>
                    <br />
                    {props.options.address}
                </Box>

                {isNonEmptyString(props.options.infos) && (
                    <Box color="gray800" css={{ whiteSpace: 'pre-wrap' }}>
                        {props.options.infos}
                    </Box>
                )}
            </Flex>

            <Box color="gray800">{translate('atteste_sur_l_h_37408')}</Box>

            <Grid gap="6" gridtemplatecolumns="repeat(3, 1fr)">
                <Flex direction="column" gap="3">
                    <Box color="gray800">{translate('signature_67521')}</Box>

                    {isNonEmptyString(props.options.signature.content) && (
                        <Box width={1}>
                            <img src={props.options.signature.content} width="100%" />
                        </Box>
                    )}
                </Flex>

                <Box color="gray800">{translate('fait_1_39349', props.options.place)}</Box>

                <Box color="gray800">
                    {dateTimeService.toLocaleString(now, LocaleFormats.DateOnly.MonthLong)}
                </Box>
            </Grid>
        </Flex>
    );
};
