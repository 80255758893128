import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { AccreditationsSlotId, MassAccreditSlotsQuery } from 'common/src/generated/types';
import { NumberService } from 'common/src/services/numberService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { fullName } from 'common/src/vo/accreditation';
import { noop } from 'lodash-es';
import * as React from 'react';
import { Checkbox } from '../../designSystem/components/checkbox';
import { useHeavent } from '../../hooks/useHeavent';

interface IMassAccreditAccreditationHiddenSlotProps {
    accreditation: MassAccreditSlotsQuery['event']['accreditationsSlots']['nodes'][number]['accreditation'];
    accreditationsSlotsIds: AccreditationsSlotId[];
    slot: MassAccreditSlotsQuery['event']['accreditationsSlots']['nodes'][number];

    onClick(slotId: AccreditationsSlotId, isSelected: boolean): void;
}

export const MassAccreditAccreditationHiddenSlot = (
    props: IMassAccreditAccreditationHiddenSlotProps
) => {
    const { translate } = useHeavent();
    const numberService = useService(NumberService);
    const name = React.useMemo(() => fullName(props.accreditation), [props.accreditation]);
    const state = props.accreditationsSlotsIds.includes(props.slot.id) ? 'checked' : 'unchecked';

    return (
        <Flex
            align="center"
            css={{
                background: 'white',
                bd: true,
                cursor: 'pointer',
                userSelect: 'none'
            }}
            gap="2"
            height={64}
            onClick={() => {
                props.onClick(props.slot.id, state === 'unchecked');
            }}
        >
            <Flex justify="center" width={48}>
                <Checkbox shouldPreventDefault={false} state={state} onClick={noop} />
            </Flex>

            <Box css={{ flex: '1' }} font="gray800 textSm semiBold">
                {name}
            </Box>

            <Flex css={{ px: '$3' }} width={310}>
                <Badge
                    color={
                        props.slot.assignedResources >= (props.slot.maxResources || Infinity)
                            ? 'success'
                            : 'warning'
                    }
                    leftIcon="user-group"
                >
                    {translate(
                        '_1_2_membres_04485',
                        props.slot.assignedResources,
                        numberService.toNumberOrInfinity(props.slot.maxResources)
                    )}
                </Badge>
            </Flex>
        </Flex>
    );
};
