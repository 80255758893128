import { Flex } from 'common/src/designSystem/components/flex';
import { DelegationAccreditationsQuery } from 'common/src/generated/types';
import * as React from 'react';
import { Calendar } from '../../../components/calendar/calendar';
import { DatePrevNextInput } from '../../../designSystem/components/date/datePrevNextInput';
import { DelegationAccreditationsAccreditation } from './delegationAccreditationsAccreditation';

interface IDelegationAccreditationsProps {
    event: DelegationAccreditationsQuery['event'];
    isEventAdmin?: boolean;
}

export const DelegationAccreditations = (props: IDelegationAccreditationsProps) => {
    const isSameDay = React.useMemo(
        () => props.event.startAt.startOf('day').equals(props.event.endAt.startOf('day')),
        [props.event]
    );
    const allEventSlots = React.useMemo(
        () =>
            props.event.delegation.accreditationsSlots.filter(
                (as) => !as.accreditationSlot.date?.isValid
            ),
        [props.event]
    );
    const daysSlots = React.useMemo(
        () =>
            props.event.delegation.accreditationsSlots.filter(
                (as) => as.accreditationSlot.date?.isValid
            ),
        [props.event]
    );
    const [selectedDay, setSelectedDay] = React.useState(
        props.event.delegation.minAccreditationSlotDate || props.event.startAt
    );

    return (
        <Flex
            css={{
                background: 'white',
                bd: true,
                overflow: 'hidden'
            }}
            direction="column"
        >
            <Flex
                css={{
                    background: 'white',
                    padding: '$5 $5'
                }}
                justify="between"
            >
                <DatePrevNextInput
                    max={props.event.endAt}
                    min={props.event.startAt}
                    value={selectedDay}
                    onChange={setSelectedDay}
                />
            </Flex>

            <Calendar.Root
                css={{
                    borderTop: '1px solid $gray200'
                }}
                date={selectedDay}
                maxDate={props.event.endAt}
                minDate={props.event.startAt}
            >
                {allEventSlots.map((das) => (
                    <Calendar.Event key={das.accreditationSlot.id}>
                        <DelegationAccreditationsAccreditation
                            das={das}
                            display={isSameDay ? 'vertical' : 'horizontal'}
                            isEventAdmin={props.isEventAdmin}
                            isWeezeentSynchronized={props.event.isWeezeventConnected}
                        />
                    </Calendar.Event>
                ))}

                {daysSlots.map((das) => (
                    <Calendar.Event
                        key={das.accreditationSlot.id}
                        endAt={das.accreditationSlot.date!}
                        startAt={das.accreditationSlot.date!}
                    >
                        <DelegationAccreditationsAccreditation
                            das={das}
                            display="vertical"
                            isEventAdmin={props.isEventAdmin}
                            isWeezeentSynchronized={props.event.isWeezeventConnected}
                        />
                    </Calendar.Event>
                ))}
            </Calendar.Root>
        </Flex>
    );
};
