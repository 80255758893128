import { DropdownEllipsis } from 'common-front/src/designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import {
    usePositionsSlotsUsersInfosCheckInMutation,
    useVolunteersRegistrationsMassAssignMutation
} from 'common-front/src/generated/graphqlHooks';
import {
    CheckInState,
    CheckInStrategy,
    EventId,
    MassAssignStrategy,
    PositionSlotVolunteersQuery
} from 'common/src/generated/types';
import { PositionPermission } from 'common/src/graphql/permissions/dsl';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';

interface IPositionPositionSlotVolunteerRowDropdownProps {
    eventId: EventId;
    positionSlot: Pick<
        PositionSlotVolunteersQuery['event']['positionSlot'],
        'id' | 'positionCategoryId' | 'positionId'
    >;
    volunteerRegistration: PositionSlotVolunteersQuery['event']['volunteersRegistrations']['nodes'][0];

    reload(): void;
}

export const PositionPositionSlotVolunteerRowDropdown = (
    props: IPositionPositionSlotVolunteerRowDropdownProps
) => {
    const translate = useTranslate();
    const { hasPositionPermissions } = useEventContext();
    const [canAssign] = hasPositionPermissions([PositionPermission.ASSIGN_MEMBER], {
        positionCategoryId: props.positionSlot.positionCategoryId,
        positionId: props.positionSlot.positionId
    });
    const { mutate: positionSlotUserInfoCheckIn } = usePositionsSlotsUsersInfosCheckInMutation();
    const { mutate: massAssign } = useVolunteersRegistrationsMassAssignMutation();
    const psui = props.volunteerRegistration.positionSlotUserInfo!;

    return (
        <DropdownEllipsis>
            {psui.state === CheckInState.Waiting && (
                <ItemIcon
                    icon="clock"
                    onClick={async () => {
                        await positionSlotUserInfoCheckIn({
                            eventId: props.eventId,
                            checkIn: {
                                strategy: CheckInStrategy.CheckIn,
                                positionsSlotsUsersInfosIds: [psui.id]
                            }
                        });

                        props.reload();
                    }}
                >
                    {translate('pointer_1_57919', props.volunteerRegistration.userInfo.name)}
                </ItemIcon>
            )}

            {psui.state === CheckInState.CheckedIn && (
                <ItemIcon
                    icon="clock"
                    onClick={async () => {
                        await positionSlotUserInfoCheckIn({
                            eventId: props.eventId,
                            checkIn: {
                                strategy: CheckInStrategy.CheckOut,
                                positionsSlotsUsersInfosIds: [psui.id]
                            }
                        });

                        props.reload();
                    }}
                >
                    {translate('d_pointer_1_08337', props.volunteerRegistration.userInfo.name)}
                </ItemIcon>
            )}

            {psui.state === CheckInState.CheckedOut && (
                <ItemIcon
                    icon="clock"
                    onClick={async () => {
                        await positionSlotUserInfoCheckIn({
                            eventId: props.eventId,
                            checkIn: {
                                strategy: CheckInStrategy.Reset,
                                positionsSlotsUsersInfosIds: [psui.id]
                            }
                        });

                        props.reload();
                    }}
                >
                    {translate('remettre_z_ro_11572')}
                </ItemIcon>
            )}

            {canAssign && (
                <ItemIcon
                    color="red"
                    icon="user-xmark"
                    onClick={async () => {
                        await massAssign({
                            eventId: props.eventId,
                            massAssign: {
                                positionsSlotsIds: [props.positionSlot.id],
                                selecteds: {
                                    ids: [props.volunteerRegistration.userInfo.id]
                                },
                                strategy: MassAssignStrategy.Delete
                            }
                        });

                        props.reload();
                    }}
                >
                    {translate('d_saffecter_du_47122')}
                </ItemIcon>
            )}
        </DropdownEllipsis>
    );
};
