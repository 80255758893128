import { Accreditation } from 'common-front/src/components/accreditations/v2/accreditation';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { ToggleText } from 'common-front/src/designSystem/form/toggle';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Table } from 'common/src/designSystem/components/table/table';
import { EventAccreditationsFragment } from 'common/src/generated/types';
import { ICreateUpdateDelegationValues } from 'common/src/input/delegationInput';
import { preventDefault } from 'common/src/util/links';
import { sortedSlots } from 'common/src/vo/accreditationSlot';
import * as React from 'react';
import { DelegationAccreditationSlot } from './delegationAccreditationSlot';

interface IDelegationAccreditationProps {
    accreditation: EventAccreditationsFragment['accreditationsCategories'][0]['accreditations'][0];
    slots: EventAccreditationsFragment['accreditationsCategories'][0]['accreditations'][0]['slots'];
    values: ICreateUpdateDelegationValues['delegation'];

    change(name: string, value: any): void;
}

export const DelegationAccreditation = (props: IDelegationAccreditationProps) => {
    const { translate } = useHeavent();
    const index = React.useMemo(
        () =>
            props.values.accreditations.findIndex(
                (a) => a.accreditationId === props.accreditation.id
            ),
        [props.values, props.accreditation]
    );
    const slots = React.useMemo(
        () => sortedSlots(props.slots.filter((s) => s.accreditationId === props.accreditation.id)),
        [props.slots, props.accreditation]
    );
    const prefix = `delegation.accreditations[${index}]`;

    return (
        <Accreditation
            accreditation={props.accreditation}
            displaySlots={true}
            numberOfSlots={slots.length}
            renderActions={() => (
                <Flex align="center" gap="6" onClick={preventDefault}>
                    {props.accreditation.hasSlots && (
                        <Flex align="center" gap="2">
                            <Box>{translate('limite_totale_35352')}</Box>

                            <Box width={60}>
                                <TextInput name={`${prefix}.maxResources`} />
                            </Box>
                        </Flex>
                    )}

                    <ToggleText name={`${prefix}.willAutoAccredit`}>
                        {translate('attribuer_autom_13895')}
                    </ToggleText>
                </Flex>
            )}
        >
            <Table hideBorder={true}>
                <HeaderRow>
                    <HeaderCell>{translate('date_du_cr_neau_82295')}</HeaderCell>
                    <HeaderCell>{translate('nom_du_cr_neau_54351')}</HeaderCell>
                    <HeaderCell>{translate('accr_ditation_p_28030')}</HeaderCell>
                    <HeaderCell width={100}>{translate('Privacy')}</HeaderCell>
                    <HeaderCell width={130}>{translate('total_07173')}</HeaderCell>
                </HeaderRow>

                {slots.map((slot) => (
                    <DelegationAccreditationSlot
                        key={slot.id}
                        accreditationDisplay={props.accreditation.accreditationDisplay}
                        change={props.change}
                        slot={slot}
                        values={props.values}
                    />
                ))}
            </Table>
        </Accreditation>
    );
};
