import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { SegmentFolderReorderInput, SegmentReorderInput } from 'common/src/generated/types';
import { produce } from 'immer';
import * as React from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { Button } from '../../../designSystem/components/button';
import { TextInput } from '../../../designSystem/components/textInput';

interface ISegmentsFoldersReorderSegmentProps {
    canDelete: boolean;
    index: number;
    segment: SegmentReorderInput;
    segmentFolderIndex: number;
    setSegmentsFolders: React.Dispatch<React.SetStateAction<SegmentFolderReorderInput[]>>;
}

export const SegmentsFoldersReorderSegment = (props: ISegmentsFoldersReorderSegmentProps) => {
    const [isEdit, setIsEdit] = React.useState(false);
    const onNameChange = React.useCallback(
        (newName: string) => {
            props.setSegmentsFolders(
                produce((currentSegmentsFolders: SegmentFolderReorderInput[]) => {
                    currentSegmentsFolders[props.segmentFolderIndex].segments[props.index].name =
                        newName;
                })
            );
        },
        [props.segmentFolderIndex, props.index, props.setSegmentsFolders]
    );
    const onDelete = React.useCallback(
        () =>
            props.setSegmentsFolders((currentSegmentsFolders: SegmentFolderReorderInput[]) =>
                currentSegmentsFolders.map((sf) => ({
                    ...sf,
                    segments: sf.segments.filter((s) => s.id !== props.segment.id)
                }))
            ),
        [props.segment, props.setSegmentsFolders]
    );

    return (
        <Draggable draggableId={`s-${props.segment.id}`} index={props.index}>
            {(draggableProvided: DraggableProvided) => (
                <Flex
                    css={{
                        background: 'white',
                        border: '1px solid $gray200',
                        borderRadius: '$1',
                        padding: '$3'
                    }}
                    gap="3"
                    {...draggableProvided.draggableProps}
                    ref={draggableProvided.innerRef}
                    align="center"
                >
                    <Box
                        color="gray300"
                        css={{ cursor: 'pointer' }}
                        {...draggableProvided.dragHandleProps}
                    >
                        <I icon="grip-vertical" iconStyle="solid" />
                    </Box>

                    {isEdit ? (
                        <Box css={{ flex: '1' }}>
                            <TextInput value={props.segment.name} onChange={onNameChange} />
                        </Box>
                    ) : (
                        <Box css={{ flex: '1' }} font="gray900 textSm medium">
                            {props.segment.name}
                        </Box>
                    )}

                    <Button
                        color="white"
                        leftIcon={isEdit ? 'check' : 'pen'}
                        size="sm"
                        onClick={() => setIsEdit(!isEdit)}
                    />

                    <Button
                        color="white"
                        disabled={!props.canDelete}
                        leftIcon="trash-can"
                        size="sm"
                        onClick={onDelete}
                    />
                </Flex>
            )}
        </Draggable>
    );
};
