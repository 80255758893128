import { UpdateCustomFieldCell } from 'common-front/src/components/cells/updateCustomFieldCell';
import { useDelegationsMassEditMutation } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Badge } from 'common/src/designSystem/components/badge';
import { Cell } from 'common/src/designSystem/components/table/cell';
import {
    CustomFieldSlug,
    DelegationsDelegationFragment,
    EventId,
    MassAssignStrategy,
    OrganizationId,
    SegmentCustomFieldFragment
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { LocaleFormats } from 'common/src/util/luxon';
import { getStateBadgeColorIcon } from 'common/src/vo/delegation';
import { PossibleColumn } from 'common/src/vo/segment';
import { DelegationDefaultColumns } from 'common/src/vo/segments/delegationsSegmentsService';
import * as React from 'react';
import { PositionCreatedByCell } from '../../positions/list/list/cells/positionCreatedByCell';
import { PositionRowResources } from '../../positions/list/list/positionRowResources';
import { DelegationsCategoryCell } from './cells/delegationsCategoryCell';
import { DelegationsDeadlineDateCell } from './cells/delegationsDeadlineDateCell';
import { DelegationsFormCell } from './cells/delegationsFormCell';
import { DelegationsIsLockedCell } from './cells/delegationsIsLockedCell';
import { DelegationsLeadersCell } from './cells/delegationsLeadersCell';
import { DelegationsMaxResourcesCell } from './cells/delegationsMaxResourcesCell';
import { DelegationsNameCell } from './cells/delegationsNameCell';
import { DelegationsTagsCell } from './cells/delegationsTagsCell';

interface IDelegationCellsProps {
    column: PossibleColumn;
    customField: Emptyable<SegmentCustomFieldFragment>;
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;
    row: DelegationsDelegationFragment;

    reloadRow(): void;
}

export const DelegationCells = (props: IDelegationCellsProps) => {
    const { translate } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const { mutate: massEdit } = useDelegationsMassEditMutation();
    const updateField = React.useCallback(
        (value: any, slug: CustomFieldSlug) =>
            massEdit({
                organizationId: props.organizationId,
                eventId: props.eventId,
                massEdit: {
                    reset: false,
                    selecteds: { ids: [props.row.id] },
                    slug,
                    strategy: MassAssignStrategy.Replace,
                    value
                }
            }),
        [props.row, massEdit]
    );

    return props.column.slug === DelegationDefaultColumns.Id ? (
        <Cell width={100}>{props.row.id}</Cell>
    ) : props.column.slug === DelegationDefaultColumns.Name ? (
        <DelegationsNameCell delegation={props.row} reload={props.reloadRow} />
    ) : props.column.slug === DelegationDefaultColumns.DelegationCategory ? (
        <DelegationsCategoryCell delegation={props.row} reload={props.reloadRow} />
    ) : props.column.slug === DelegationDefaultColumns.State ? (
        <Cell>
            <Badge {...getStateBadgeColorIcon(props.row.state)}>{translate(props.row.state)}</Badge>
        </Cell>
    ) : props.column.slug === DelegationDefaultColumns.Leaders ? (
        <DelegationsLeadersCell delegation={props.row} />
    ) : props.column.slug === DelegationDefaultColumns.Form ? (
        <DelegationsFormCell delegation={props.row} reload={props.reloadRow} />
    ) : props.column.slug === DelegationDefaultColumns.DeadlineDate ? (
        <DelegationsDeadlineDateCell delegation={props.row} reload={props.reloadRow} />
    ) : props.column.slug === DelegationDefaultColumns.MaxResources ? (
        <DelegationsMaxResourcesCell delegation={props.row} reload={props.reloadRow} />
    ) : props.column.slug === DelegationDefaultColumns.AssignedResources ? (
        <Cell>
            <PositionRowResources
                assignedResources={props.row.numberOfResources}
                resources={props.row.maxResources}
            />
        </Cell>
    ) : props.column.slug === DelegationDefaultColumns.Tags ? (
        <DelegationsTagsCell delegation={props.row} reload={props.reloadRow} />
    ) : props.column.slug === DelegationDefaultColumns.FillingRate ? (
        <Cell>{props.row.fillingRate}%</Cell>
    ) : props.column.slug === DelegationDefaultColumns.CreatedBy ? (
        <PositionCreatedByCell createdBy={props.row.createdBy} />
    ) : props.column.slug === DelegationDefaultColumns.ShowInDelegation ? (
        <Cell>
            {props.row.showInDelegationSpace ? translate('oui_54361') : translate('non_33516')}
        </Cell>
    ) : props.column.slug === DelegationDefaultColumns.CanLeadersAddMembers ? (
        <Cell>
            {props.row.canLeadersAddMembers ? translate('oui_54361') : translate('non_33516')}
        </Cell>
    ) : props.column.slug === DelegationDefaultColumns.CanLeadersEditMembers ? (
        <Cell>
            {props.row.canLeadersEditMembers ? translate('oui_54361') : translate('non_33516')}
        </Cell>
    ) : props.column.slug === DelegationDefaultColumns.CanLeadersEditCustomFields ? (
        <Cell>
            {props.row.canLeadersEditCustomFields ? translate('oui_54361') : translate('non_33516')}
        </Cell>
    ) : props.column.slug === DelegationDefaultColumns.IsLocked ? (
        <DelegationsIsLockedCell delegation={props.row} reload={props.reloadRow} />
    ) : props.column.slug === DelegationDefaultColumns.CreatedAt ? (
        <Cell>
            {dateTimeService.toLocaleString(props.row.insertedAt.toLocal(), LocaleFormats.DateTime)}
        </Cell>
    ) : (
        <UpdateCustomFieldCell
            customField={props.customField}
            fields={props.row.fields}
            mutate={updateField}
            reload={props.reloadRow}
            updatable={true}
        />
    );
};
