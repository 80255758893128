import { LinkText } from 'common/src/designSystem/components/linkText';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CommonEnvVars } from 'common/src/envVars';
import { AccreditationsAccreditationSlotFragment } from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import { Url } from 'common/src/util/url';
import * as React from 'react';

interface IAccreditationSlotWeezeventChecklistIdCellProps {
    accreditationSlot: AccreditationsAccreditationSlotFragment;
    weezeventOrganizationId: Emptyable<number>;
}

export const AccreditationSlotWeezeventChecklistIdCell = (
    props: IAccreditationSlotWeezeventChecklistIdCellProps
) => {
    if (
        typeof props.weezeventOrganizationId === 'number' &&
        typeof props.accreditationSlot.weezeventChecklistId === 'number'
    ) {
        const link = new Url(
            `https://admin.${CommonEnvVars.WEEZEVENT_DOMAIN}`,
            undefined,
            undefined,
            `access/O${props.weezeventOrganizationId}/checkpoints/edit/${props.accreditationSlot.weezeventChecklistId}`
        ).href;

        return (
            <Cell>
                <LinkText to={link}>{props.accreditationSlot.weezeventChecklistId}</LinkText>
            </Cell>
        );
    } else {
        return <Cell>-</Cell>;
    }
};
