import { Checkbox } from 'common-front/src/designSystem/components/checkbox';
import { PaginationCell } from 'common-front/src/designSystem/components/pagination/paginationCell';
import { PaginationRow } from 'common-front/src/designSystem/components/pagination/paginationRow';
import { executeUserQuery, useUsersQuery } from 'common-front/src/generated/graphqlHooks';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { getToken } from 'common-front/src/util/aws/cognito';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { TableGrid } from 'common/src/designSystem/components/table/tableGrid';
import {
    SegmentCustomFieldFragment,
    UserInfoCommunityFragment,
    UsersInfoId,
    UsersInfosSortAttributes
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { columnsToIncludes } from 'common/src/vo/segments/usersSegmentsService';
import { compact } from 'lodash-es';
import * as React from 'react';
import { CommunityUserRow } from './communityUserRow';

interface ICommunityUsersListProps {
    customFields: SegmentCustomFieldFragment[];
}

export const CommunityUsersList = (props: ICommunityUsersListProps) => {
    const { organizationId } = useParams();
    const {
        columns,
        columnsSearch,
        massActions,
        nameDebounced,
        limit,
        offset,
        possibleColumns,
        predicates,
        reloadKey,
        setLimit,
        setOffset,
        setSort,
        sort
    } = useSegmentsContext<UsersInfoId, UsersInfosSortAttributes>();
    const includes = React.useMemo(() => columnsToIncludes(columns), [columns]);
    const { data, isLoading, reload } = useUsersQuery({
        ...includes,
        organizationId,
        predicates,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : null,
        columnsSearch: isNonEmptyArray(columnsSearch) ? columnsSearch : null,
        limit,
        offset,
        sort
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.data?.rows);
    React.useEffect(() => {
        if (reloadKey > 1) {
            reload();
        }
    }, [reloadKey]);
    const selectedFields = React.useMemo(
        () => columns.flatMap((slug) => compact([possibleColumns.find((pc) => pc.slug === slug)])),
        [possibleColumns, columns]
    );
    const [usersInfos, setUsersInfos] = React.useState<UserInfoCommunityFragment[]>([]);
    const [idToLoading, setIdToLoading] = React.useState<Record<UsersInfoId, boolean>>({});
    const reloadUserInfo = React.useCallback(
        async (id: UsersInfoId) => {
            setIdToLoading((ids) => ({ ...ids, [id]: true }));

            const {
                data: { row }
            } = await executeUserQuery(
                {
                    ...includes,
                    organizationId,
                    id
                },
                await getToken()
            );

            setUsersInfos((uis) => uis.map((ui) => (ui.id === id ? row : ui)));
            setIdToLoading((ids) => ({ ...ids, [id]: false }));
        },
        [includes, setIdToLoading, setUsersInfos]
    );

    React.useEffect(() => {
        setUsersInfos(data.data?.rows.nodes ?? []);
    }, [data.data]);

    React.useEffect(() => {
        massActions.setStates(
            Object.fromEntries(
                (data.data?.rows.nodes ?? []).map(({ id }) => [
                    `ui${id}`,
                    { id, state: 'unchecked' }
                ])
            )
        );
    }, [data.data, massActions.setStates]);

    return (
        <Flex css={{ flex: '1', overflow: 'hidden' }} width={1}>
            <TableGrid
                headerRow={
                    <HeaderRow>
                        <HeaderCell css={{ paddingRight: 0 }} width={48}>
                            <Checkbox
                                state={massActions.selectAllState}
                                onClick={massActions.toggleSelectAll}
                            />
                        </HeaderCell>

                        {selectedFields.map((field) =>
                            field.slug === 'name' ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={UsersInfosSortAttributes.Name}
                                    setSort={setSort}
                                    sort={sort}
                                >
                                    {field.name}
                                </HeaderCellSort>
                            ) : field.slug === 'id' ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={UsersInfosSortAttributes.Id}
                                    setSort={setSort}
                                    sort={sort}
                                    width={100}
                                >
                                    {field.name}
                                </HeaderCellSort>
                            ) : field.slug === 'firstName' ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={UsersInfosSortAttributes.FirstName}
                                    setSort={setSort}
                                    sort={sort}
                                >
                                    {field.name}
                                </HeaderCellSort>
                            ) : field.slug === 'lastName' ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={UsersInfosSortAttributes.LastName}
                                    setSort={setSort}
                                    sort={sort}
                                >
                                    {field.name}
                                </HeaderCellSort>
                            ) : field.slug === 'picture' ? (
                                <HeaderCell key={field.slug} width={80}>
                                    {field.name}
                                </HeaderCell>
                            ) : field.slug === 'number_of_events' ? (
                                <HeaderCell key={field.slug} width={230}>
                                    {field.name}
                                </HeaderCell>
                            ) : field.slug === 'inserted_at' ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={UsersInfosSortAttributes.InsertedAt}
                                    setSort={setSort}
                                    sort={sort}
                                    width={230}
                                >
                                    {field.name}
                                </HeaderCellSort>
                            ) : field.isSortable ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={field.slug}
                                    setSort={setSort}
                                    sort={sort}
                                >
                                    <Box css={{ ellipsis: true }} title={field.name}>
                                        {field.name}
                                    </Box>
                                </HeaderCellSort>
                            ) : (
                                <HeaderCell key={field.slug}>
                                    <Box css={{ ellipsis: true }} title={field.name}>
                                        {field.name}
                                    </Box>
                                </HeaderCell>
                            )
                        )}

                        <HeaderCell width={100} />
                    </HeaderRow>
                }
                paginationRow={
                    <PaginationRow css={{ borderTop: '1px solid $gray200' }}>
                        <PaginationCell
                            limit={limit}
                            numberOfPages={numberOfPages}
                            offset={offset}
                            setLimit={setLimit}
                            setOffset={setOffset}
                            showLimits={true}
                            totalCount={totalCount}
                        />
                    </PaginationRow>
                }
            >
                {isLoading ? (
                    <>
                        <RowSkeleton />
                        <RowSkeleton />
                        <RowSkeleton />
                    </>
                ) : (
                    usersInfos.map((userInfo) => {
                        if (idToLoading[userInfo.id]) {
                            return <RowSkeleton key={userInfo.id} />;
                        } else {
                            return (
                                <CommunityUserRow
                                    key={userInfo.id}
                                    columns={selectedFields}
                                    customFields={props.customFields}
                                    reloadUserInfo={reloadUserInfo}
                                    state={
                                        massActions.states[`ui${userInfo.id}`]?.state ?? 'unchecked'
                                    }
                                    toggleRow={massActions.toggleRow}
                                    userInfo={userInfo}
                                />
                            );
                        }
                    })
                )}
            </TableGrid>
        </Flex>
    );
};
