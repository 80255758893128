import { EmailType, EventId, OrganizationId, UsersInfoId } from 'common/src/generated/types';
import * as React from 'react';
import { useEmailResendMutation } from '../generated/graphqlHooks';

export function useEmailEventInviteResend(
    organizationId: OrganizationId,
    eventId: EventId,
    userInfoId: UsersInfoId
) {
    const { mutate: emailResend } = useEmailResendMutation();

    return React.useCallback(
        () =>
            Promise.all(
                [
                    EmailType.PositionCategoryLeaderAssign,
                    EmailType.PositionLeaderAssign,
                    EmailType.DelegationLeaderAssign,
                    EmailType.EventInvite
                ].map((emailType) =>
                    emailResend({
                        organizationId,
                        eventId,
                        userInfoId,
                        emailType
                    })
                )
            ),
        [emailResend, organizationId, eventId, userInfoId]
    );
}
