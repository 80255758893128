import { Flex } from 'common/src/designSystem/components/flex';
import { Link } from 'common/src/designSystem/components/link';
import * as React from 'react';

interface IEventMenuItemProps {
    children: string;
    to: string;

    isActive(pathname: string): boolean;
    onClick?(): Promise<any>;
}

export const EventMenuItem = (props: IEventMenuItemProps) => {
    const isActive = props.isActive(location.pathname);

    return (
        <Link
            aria-label={props.children}
            aria-selected={isActive}
            role="menuitem"
            to={props.to}
            onClick={async () => await props.onClick?.()}
        >
            <Flex
                className={isActive ? 'h-event-menu-item-active' : ''}
                css={{
                    color: '$gray800',
                    cursor: 'pointer',
                    padding: '6px $3',
                    '&:hover, &.h-event-menu-item-active': {
                        color: '$primary700'
                    }
                }}
            >
                {props.children}
            </Flex>
        </Link>
    );
};
