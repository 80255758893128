import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { DocumentsQuery, UsersInfoId } from 'common/src/generated/types';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { DocumentsList } from '../documentsList';
import { CustomDocumentRow } from './customDocumentRow';

interface ICustomDocumentsListProps {
    customDocuments: DocumentsQuery['event']['customDocuments'];
    numberOfVolunteersRegistrations: number;
    userInfoId: UsersInfoId;

    reload(): void;
}

export const CustomDocumentsList = (props: ICustomDocumentsListProps) => {
    const { translate } = useHeavent();
    const customDocuments = React.useMemo(
        () => sortBy(props.customDocuments, (cd) => cd.name.toLowerCase()),
        [props.customDocuments]
    );

    return (
        <DocumentsList title={translate('documents_perso_87374')}>
            {customDocuments.map((customDocument) => (
                <CustomDocumentRow
                    key={customDocument.id}
                    customDocument={customDocument}
                    disabled={props.numberOfVolunteersRegistrations === 0}
                    reload={props.reload}
                    userInfoId={props.userInfoId}
                />
            ))}
        </DocumentsList>
    );
};
