import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Delegation } from 'common/src/generated/types';
import * as React from 'react';
import { useDelegationsMassDeleteMutation } from '../../generated/graphqlHooks';

interface IDelegationDeleteModalProps {
    delegation: Pick<Delegation, 'id' | 'name'>;

    onClose(): void;
    onSuccess(): void;
}

export const DelegationDeleteModal = (props: IDelegationDeleteModalProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { mutate } = useDelegationsMassDeleteMutation();

    return (
        <DeleteModal
            buttonText={translate('supprimer_43083')}
            subtext={translate('_tes_vous_s_r_d_66412')}
            text={translate('suppression_de_73669', props.delegation.name)}
            onClose={props.onClose}
            onDelete={async () => {
                await mutate({
                    organizationId,
                    eventId,
                    selecteds: { ids: [props.delegation.id] }
                });

                props.onSuccess();
            }}
        />
    );
};
