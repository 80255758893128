import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSlugToCustomField } from 'common-front/src/hooks/useSlugToCustomField';
import { Box } from 'common/src/designSystem/components/box';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    AccreditationsAccreditationSlotFragment,
    AccreditationsSlotId,
    AccreditationsSlotInput,
    Event,
    SegmentCustomFieldFragment
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { LocaleFormats } from 'common/src/util/luxon';
import { FieldService } from 'common/src/vo/field';
import { PossibleColumn } from 'common/src/vo/segment';
import { AccreditationDefaultColumns } from 'common/src/vo/segments/accreditationsSegmentsService';
import * as React from 'react';
import { CreateAccreditationCreateSlotModal } from '../../accreditations/create/createAccreditationCreateSlotModal';
import { AccreditationsAcronymCell } from '../../accreditations/list/cells/accreditationsAcronymCell';
import { AccreditationsCategoryCell } from '../../accreditations/list/cells/accreditationsCategoryCell';
import { AccreditationsColorCell } from '../../accreditations/list/cells/accreditationsColorCell';
import { AccreditationsConditionsCell } from '../../accreditations/list/cells/accreditationsConditionsCell';
import { AccreditationsIconCell } from '../../accreditations/list/cells/accreditationsIconCell';
import { AccreditationsNameCell } from '../../accreditations/list/cells/accreditationsNameCell';
import { AccreditationsTagsCell } from '../../accreditations/list/cells/accreditationsTagsCell';
import { useAccreditationSlotUpdateMutation } from '../../generated/graphqlHooks';
import { PositionCreatedByCell } from '../../positions/list/list/cells/positionCreatedByCell';
import { PositionRowResources } from '../../positions/list/list/positionRowResources';
import { AccreditationSlotDateCell } from './cells/accreditationSlotDateCell';
import { AccreditationSlotNameCell } from './cells/accreditationSlotNameCell';
import { AccreditationSlotWeezeventChecklistIdCell } from './cells/accreditationSlotWeezeventChecklistIdCell';

interface IAccreditationSlotRowProps {
    accreditationSlot: AccreditationsAccreditationSlotFragment;
    columns: PossibleColumn[];
    customFields: SegmentCustomFieldFragment[];
    event: Pick<Event, 'startAt' | 'endAt'>;
    weezeventOrganizationId: Emptyable<number>;

    reloadAccreditationSlot(id: AccreditationsSlotId): void;
}

export const AccreditationSlotRow = (props: IAccreditationSlotRowProps) => {
    const {
        translate,
        params: { eventId }
    } = useHeavent();
    const { mutate: accreditationSlotUpdate } = useAccreditationSlotUpdateMutation();
    const dateTimeService = useService(DateTimeService);
    const fieldService = useService(FieldService);
    const slugToCustomField = useSlugToCustomField(props.customFields);
    const reloadAccreditationSlot = React.useCallback(() => {
        props.reloadAccreditationSlot(props.accreditationSlot.id);
    }, [props.accreditationSlot, props.reloadAccreditationSlot]);
    const [isEditOpen, setIsEditOpen] = React.useState(false);

    return (
        <>
            <Row
                css={{
                    '&:hover': {
                        background: '$gray100'
                    }
                }}
            >
                {props.columns.map((field) => {
                    const customField = slugToCustomField.get(field.slug);

                    return field.slug === AccreditationDefaultColumns.Id ? (
                        <Cell key={field.slug} width={100}>
                            {props.accreditationSlot.id}
                        </Cell>
                    ) : field.slug === AccreditationDefaultColumns.Category ? (
                        <AccreditationsCategoryCell
                            key={field.slug}
                            accreditationCategory={props.accreditationSlot.accreditationCategory}
                            accreditationId={props.accreditationSlot.accreditation.id}
                            reload={reloadAccreditationSlot}
                        />
                    ) : field.slug === AccreditationDefaultColumns.Name ? (
                        <AccreditationsNameCell
                            key={field.slug}
                            accreditation={props.accreditationSlot.accreditation}
                            reload={reloadAccreditationSlot}
                        />
                    ) : field.slug === AccreditationDefaultColumns.SlotName ? (
                        props.accreditationSlot.isHidden ? (
                            <Cell key={field.slug}>-</Cell>
                        ) : (
                            <AccreditationSlotNameCell
                                key={field.slug}
                                accreditationSlot={props.accreditationSlot}
                                reload={reloadAccreditationSlot}
                            />
                        )
                    ) : field.slug === AccreditationDefaultColumns.SlotDate ? (
                        props.accreditationSlot.isHidden ? (
                            <Cell key={field.slug}>-</Cell>
                        ) : (
                            <AccreditationSlotDateCell
                                key={field.slug}
                                accreditationSlot={props.accreditationSlot}
                                reload={reloadAccreditationSlot}
                            />
                        )
                    ) : field.slug === AccreditationDefaultColumns.Resources ? (
                        <Cell key={field.slug} width={256}>
                            <PositionRowResources
                                assignedResources={props.accreditationSlot.assignedResources}
                                resources={props.accreditationSlot.maxResources}
                            />
                        </Cell>
                    ) : field.slug === AccreditationDefaultColumns.Tags ? (
                        <AccreditationsTagsCell
                            key={field.slug}
                            accreditation={props.accreditationSlot.accreditation}
                            reload={reloadAccreditationSlot}
                        />
                    ) : field.slug === AccreditationDefaultColumns.Conditions ? (
                        <AccreditationsConditionsCell
                            key={field.slug}
                            accreditation={props.accreditationSlot.accreditation}
                        />
                    ) : field.slug === AccreditationDefaultColumns.Address ? (
                        <Cell key={field.slug}>
                            {props.accreditationSlot.accreditation.address}
                        </Cell>
                    ) : field.slug === AccreditationDefaultColumns.Latitude ? (
                        <Cell key={field.slug}>
                            {props.accreditationSlot.accreditation.latitude}
                        </Cell>
                    ) : field.slug === AccreditationDefaultColumns.Longitude ? (
                        <Cell key={field.slug}>
                            {props.accreditationSlot.accreditation.longitude}
                        </Cell>
                    ) : field.slug === AccreditationDefaultColumns.Acronym ? (
                        <AccreditationsAcronymCell
                            key={field.slug}
                            accreditation={props.accreditationSlot.accreditation}
                            reload={reloadAccreditationSlot}
                        />
                    ) : field.slug === AccreditationDefaultColumns.Color ? (
                        <AccreditationsColorCell
                            key={field.slug}
                            accreditation={props.accreditationSlot.accreditation}
                            reload={reloadAccreditationSlot}
                        />
                    ) : field.slug === AccreditationDefaultColumns.Icon ? (
                        <AccreditationsIconCell
                            key={field.slug}
                            accreditation={props.accreditationSlot.accreditation}
                            reload={reloadAccreditationSlot}
                        />
                    ) : field.slug === AccreditationDefaultColumns.FillingRate ? (
                        <Cell key={field.slug}>{props.accreditationSlot.fillingRate}%</Cell>
                    ) : field.slug === AccreditationDefaultColumns.CreatedBy ? (
                        <PositionCreatedByCell
                            key={field.slug}
                            createdBy={props.accreditationSlot.accreditation.createdBy}
                        />
                    ) : field.slug === AccreditationDefaultColumns.CreatedAt ? (
                        <Cell>
                            {dateTimeService.toLocaleString(
                                props.accreditationSlot.insertedAt.toLocal(),
                                LocaleFormats.DateTime
                            )}
                        </Cell>
                    ) : field.slug === AccreditationDefaultColumns.WeezeventChecklistId ? (
                        <AccreditationSlotWeezeventChecklistIdCell
                            accreditationSlot={props.accreditationSlot}
                            weezeventOrganizationId={props.weezeventOrganizationId}
                        />
                    ) : (
                        <Cell key={field.slug}>
                            {customField
                                ? fieldService.getValueString(
                                      customField,
                                      props.accreditationSlot.accreditation.fields,
                                      { fileReturnValue: 'name' }
                                  )
                                : ''}
                        </Cell>
                    );
                })}

                {props.accreditationSlot.isHidden ? (
                    <Cell width={100} />
                ) : (
                    <CellControls justify="center">
                        <Tooltip>
                            <Trigger>
                                <Box>
                                    <I
                                        icon="pen"
                                        onClick={() => {
                                            setIsEditOpen(true);
                                        }}
                                    />
                                </Box>
                            </Trigger>

                            <Content placement="top">{translate('_diter_62574')}</Content>
                        </Tooltip>
                    </CellControls>
                )}
            </Row>

            {isEditOpen && (
                <CreateAccreditationCreateSlotModal
                    event={props.event}
                    slot={props.accreditationSlot}
                    onClose={() => {
                        setIsEditOpen(false);
                    }}
                    onSuccess={async (updatedSlot: AccreditationsSlotInput) => {
                        await accreditationSlotUpdate({
                            eventId,
                            accreditationSlotId: props.accreditationSlot.id,
                            accreditationSlot: updatedSlot
                        });

                        reloadAccreditationSlot();
                    }}
                />
            )}
        </>
    );
};
