import {
    EventId,
    OrganizationId,
    PositionId,
    PositionsCategoryId,
    VolunteerRegistrationFragment
} from 'common/src/generated/types';
import * as React from 'react';
import { RightPanel } from '../../designSystem/components/rightPanel/rightPanel';
import { RightPanelBody } from '../../designSystem/components/rightPanel/rightPanelBody';
import { RightPanelHeader } from '../../designSystem/components/rightPanel/rightPanelHeader';
import { Tab } from '../../designSystem/components/tabs/tab';
import { TabList } from '../../designSystem/components/tabs/tabList';
import { TabPanel } from '../../designSystem/components/tabs/tabPanel';
import { Tabs } from '../../designSystem/components/tabs/tabs';
import { useHeavent } from '../../hooks/useHeavent';
import { PositionsRightPanelAssignedTab } from './positionsRightPanelAssignedTab';
import { PositionsRightPanelContextProvider } from './positionsRightPanelContext';
import { PositionsRightPanelWishedTab } from './positionsRightPanelWishedTab';

interface IPositionsRightPanelProps {
    basePath: string;
    canCheckIn: boolean | ((categoryId: PositionsCategoryId, positionId: PositionId) => boolean);
    canDelete: (categoryId: PositionsCategoryId, positionId: PositionId) => boolean;
    eventId: EventId;
    organizationId: OrganizationId;
    volunteerRegistration: VolunteerRegistrationFragment;

    onClose(): void;
}

export const PositionsRightPanel = (props: IPositionsRightPanelProps) => {
    const { translate } = useHeavent();

    return (
        <PositionsRightPanelContextProvider
            canCheckIn={props.canCheckIn}
            canDelete={props.canDelete}
            eventId={props.eventId}
            organizationId={props.organizationId}
            volunteerRegistration={props.volunteerRegistration}
        >
            <RightPanel size="md" onClose={props.onClose}>
                <RightPanelHeader>{translate('missions_63972')}</RightPanelHeader>

                <RightPanelBody css={{ padding: '0' }}>
                    <Tabs height={1}>
                        <TabList center={true}>
                            <Tab path={`${props.basePath}/assigned`}>
                                {translate('attribu_es_48167')}
                            </Tab>

                            <Tab path={`${props.basePath}/wished`}>
                                {translate('souhait_es_78101')}
                            </Tab>
                        </TabList>

                        <TabPanel path={`${props.basePath}/assigned`}>
                            <PositionsRightPanelAssignedTab />
                        </TabPanel>

                        <TabPanel path={`${props.basePath}/wished`}>
                            <PositionsRightPanelWishedTab />
                        </TabPanel>
                    </Tabs>
                </RightPanelBody>
            </RightPanel>
        </PositionsRightPanelContextProvider>
    );
};
