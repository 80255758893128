import { Blank } from 'common-front/src/components/blank/blank';
import { Button } from 'common-front/src/designSystem/components/button';
import { CheckboxText } from 'common-front/src/designSystem/components/checkbox';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import {
    FormUserOverlay,
    UserInformationForm
} from 'common-front/src/users/information/userInformationForm';
import { Svgs } from 'common-front/src/util/assets';
import { toFormElements } from 'common-front/src/vo/customField';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    CustomFieldSlug,
    CustomFieldWithConditionFragment,
    DelegationUserProfileFragment,
    FormId,
    FormUserInfoUserProfileFragment,
    UserInfoOverlayFragment,
    UserInformationsEventQuery,
    UserInformationsOrganizationQuery
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { compact, sortBy } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import {
    useEventProfilePrivateFieldsUpdateMutation,
    useOrganizationProfilePrivateFieldsUpdateMutation,
    useUserInformationsEventQuery,
    useUserInformationsOrganizationQuery
} from '../../../generated/graphqlHooks';
import { useOrganizationContext } from '../../../organization/organizationContext';
import { UserInformationsDelegations } from './userInformationsDelegations';
import { UserInformationsPrivateFields } from './userInformationsPrivateFields';
import { UserInformationsProfiles } from './userInformationsProfiles';
import { UserInformationsTeam } from './userInformationsTeam';
import { UserInformationsWeezevent } from './userInformationsWeezevent';

interface IUserInformationsProps {
    customFields: CustomFieldWithConditionFragment[];
    delegations: DelegationUserProfileFragment[];
    forms: FormUserOverlay[];
    formsUsersInfos: FormUserInfoUserProfileFragment[];
    initialShowNotFilled: boolean;
    insertedAt: DateTime;
    profilePrivateFields: CustomFieldSlug[];
    userInfo: UserInfoOverlayFragment;
    usersInfos:
        | UserInformationsEventQuery['organization']['userInfo']['user']['usersInfos']
        | UserInformationsOrganizationQuery['organization']['userInfo']['user']['usersInfos'];
    weezevent: Emptyable<{
        eventId: number;
        organizationId: number;
        participantId: Emptyable<number>;
        ticketId: string;
        shortTag: Emptyable<string>;
    }>;

    getEditUserFormPath(formId: FormId | 'all' | 'private'): string;
    profilePrivateFieldsUpdate(profilePrivateFields: CustomFieldSlug[]): Promise<any>;
    reload(): void;
    setShowNotFilled(showNotFilled: boolean): void;
}

const UserInformations = (props: IUserInformationsProps) => {
    const {
        translate,
        params: { userInfoId }
    } = useHeavent();
    const [name, setName] = React.useState('');
    const [showNotFilled, _setShowNotFilled] = React.useState(props.initialShowNotFilled);
    const setShowNotFilled = React.useCallback(
        (newShowNotFilled) => {
            _setShowNotFilled(newShowNotFilled);
            props.setShowNotFilled(newShowNotFilled);
        },
        [props.setShowNotFilled, _setShowNotFilled]
    );
    const formIdToFilledAt = React.useMemo(
        () => Object.fromEntries(props.formsUsersInfos.map((fui) => [fui.formId, fui.insertedAt])),
        [props.formsUsersInfos]
    );
    const forms = React.useMemo(
        () =>
            sortBy(
                props.forms.filter((form) => {
                    if (!showNotFilled && formIdToFilledAt[form.id] === undefined) {
                        return false;
                    } else if (isNonEmptyString(name)) {
                        return form.name.toLowerCase().includes(name.toLowerCase().trim());
                    } else {
                        return true;
                    }
                }),
                [(f) => (formIdToFilledAt[f.id] !== undefined ? 1 : 2), (f) => f.name]
            ),
        [props.forms, formIdToFilledAt, name, showNotFilled]
    );
    const teams = React.useMemo(
        () =>
            props.formsUsersInfos.filter(
                (
                    fui
                ): fui is FormUserInfoUserProfileFragment &
                    Required<Pick<FormUserInfoUserProfileFragment, 'teamCode'>> =>
                    isNonEmptyString(fui.teamCode)
            ),
        [props.formsUsersInfos]
    );
    const otherUsersInfos = React.useMemo(
        () => props.usersInfos.filter((ui) => ui.id !== userInfoId),
        [props.usersInfos, userInfoId]
    );

    return (
        <Flex gap="6" width={1}>
            <Flex css={{ flex: '1', overflow: 'hidden' }} direction="column" gap="4">
                {props.weezevent && <UserInformationsWeezevent weezevent={props.weezevent} />}

                <Flex
                    align="center"
                    css={{
                        background: 'white',
                        bd: true,
                        padding: '$4'
                    }}
                    gap="2"
                >
                    <Box css={{ flex: '1' }}>
                        <TextInput
                            icon="magnifying-glass"
                            placeholder={translate('rechercher_un_f_28561')}
                            value={name}
                            onChange={setName}
                        />
                    </Box>

                    <CheckboxText
                        state={showNotFilled ? 'checked' : 'unchecked'}
                        onClick={(state) => {
                            setShowNotFilled(state === 'checked');
                        }}
                    >
                        {translate('afficher_les_fo_84832')}
                    </CheckboxText>
                </Flex>

                <Flex direction="column" gap="3">
                    {forms.length === 0 ? (
                        <Flex
                            css={{
                                background: 'white',
                                bd: true
                            }}
                            height={400}
                        >
                            <Blank
                                button={
                                    <Button to={props.getEditUserFormPath('all')}>
                                        {translate('_diter_les_cham_66020')}
                                    </Button>
                                }
                                imageSrc={Svgs.FormsElementsBlank}
                                title={translate('aucun_formulair_41857')}
                            />
                        </Flex>
                    ) : (
                        forms.map((form) => (
                            <UserInformationForm
                                key={form.id}
                                filledAt={formIdToFilledAt[form.id]}
                                form={form}
                                getEditUserFormPath={props.getEditUserFormPath}
                                showIsFilled={form.id !== 'all'}
                                userInfo={props.userInfo}
                            />
                        ))
                    )}
                </Flex>
            </Flex>

            <Flex direction="column" gap="4" width={348}>
                {isNonEmptyArray(otherUsersInfos) && (
                    <UserInformationsProfiles usersInfos={otherUsersInfos} />
                )}

                {isNonEmptyArray(props.delegations) && (
                    <UserInformationsDelegations delegations={props.delegations} />
                )}

                {teams.map((team, index) => (
                    <UserInformationsTeam key={index} team={team} />
                ))}

                <UserInformationsPrivateFields
                    customFields={props.customFields}
                    getEditUserFormPath={props.getEditUserFormPath}
                    insertedAt={props.insertedAt}
                    profilePrivateFields={props.profilePrivateFields}
                    profilePrivateFieldsUpdate={props.profilePrivateFieldsUpdate}
                    reload={props.reload}
                    userInfo={props.userInfo}
                />
            </Flex>
        </Flex>
    );
};

export const UserInformationsEvent = () => {
    const { organizationId, eventId, userInfoId } = useParams();
    const { localEvent, updateLocalEvent } = useEventContext();
    const { mutate } = useEventProfilePrivateFieldsUpdateMutation();
    const { data, loader, reload } = useUserInformationsEventQuery({
        organizationId,
        eventId,
        userInfoId
    });
    const forms = React.useMemo(() => sortBy(data.event?.forms ?? [], (f) => f.name), [data.event]);
    const delegations = React.useMemo(
        () => compact([data.event?.volunteerRegistration.delegation]),
        [data.event]
    );
    const weezevent = React.useMemo(() => {
        if (data.organization?.weezeventOrganizationId && data.event?.weezevent?.id) {
            return {
                eventId: data.event.weezevent.id,
                organizationId: data.organization.weezeventOrganizationId,
                participantId: data.event.volunteerRegistration.weezeventParticipantId,
                ticketId: data.event.volunteerRegistration.ticketId,
                shortTag: data.event.volunteerRegistration.shortTag
            };
        } else {
            return null;
        }
    }, [data.organization, data.event]);

    return (
        loader || (
            <UserInformations
                customFields={data.organization.customFields.nodes}
                delegations={delegations}
                forms={forms}
                formsUsersInfos={data.organization?.userInfo.formsUsersInfos ?? []}
                getEditUserFormPath={(formId) =>
                    HeaventPaths.EDIT_USER_FORM(organizationId, eventId, userInfoId, formId)
                }
                initialShowNotFilled={localEvent?.showNotFilled ?? false}
                insertedAt={data.event.volunteerRegistration.insertedAt}
                profilePrivateFields={data.event.profilePrivateFields}
                profilePrivateFieldsUpdate={(profilePrivateFields) =>
                    mutate({
                        eventId,
                        profilePrivateFields
                    })
                }
                reload={reload}
                setShowNotFilled={(showNotFilled) => {
                    updateLocalEvent({ showNotFilled });
                }}
                userInfo={data.organization.userInfo}
                usersInfos={data.organization.userInfo.user.usersInfos}
                weezevent={weezevent}
            />
        )
    );
};

export const UserInformationsOrganization = () => {
    const {
        params: { organizationId, userInfoId },
        translate
    } = useHeavent();
    const { localOrganization, updateLocalOrganization } = useOrganizationContext();
    const { mutate } = useOrganizationProfilePrivateFieldsUpdateMutation();
    const { data, loader, reload } = useUserInformationsOrganizationQuery({
        organizationId,
        userInfoId
    });
    const forms = React.useMemo(() => {
        if (data.organization) {
            const allElements = toFormElements(data.organization.customFields.nodes);
            const form: FormUserOverlay = {
                id: 'all',
                name: translate('tous_les_champs_57939'),
                elements: allElements
            };

            return sortBy(data.organization.forms as FormUserOverlay[], (f) => f.name).concat([
                form
            ]);
        } else {
            return [];
        }
    }, [data.organization, translate]);

    return (
        loader || (
            <UserInformations
                customFields={data.organization.customFields.nodes}
                delegations={data.organization.userInfo.delegations}
                forms={forms}
                formsUsersInfos={data.organization?.userInfo.formsUsersInfos ?? []}
                getEditUserFormPath={(formId) =>
                    HeaventPaths.COMMUNITY_EDIT_USER_FORM(organizationId, userInfoId, formId)
                }
                initialShowNotFilled={localOrganization?.showNotFilled ?? false}
                insertedAt={data.organization.userInfo.insertedAt}
                profilePrivateFields={data.organization.profilePrivateFields}
                profilePrivateFieldsUpdate={(profilePrivateFields) =>
                    mutate({
                        organizationId,
                        profilePrivateFields
                    })
                }
                reload={reload}
                setShowNotFilled={(showNotFilled) => {
                    updateLocalOrganization({ showNotFilled });
                }}
                userInfo={data.organization.userInfo}
                usersInfos={data.organization.userInfo.user.usersInfos}
                weezevent={null}
            />
        )
    );
};
