import { PermissionCheck, UserPermissionQueryVariables } from 'common/src/generated/types';
import { useEffect, useState } from 'react';
import { executeUserPermissionQuery } from '../generated/graphqlHooks';
import { getToken } from '../util/aws/cognito';

async function query(args: UserPermissionQueryVariables): Promise<boolean[]> {
    try {
        const token = await getToken();
        const data = await executeUserPermissionQuery(args, token);
        return data.user.permission;
    } catch {
        return args.checks.map((_) => false);
    }
}

const cache = new Map<string, Promise<boolean>>();

function getPermissionPromise({ checks }: UserPermissionQueryVariables): Promise<boolean[]> {
    const pairs = checks.map<[PermissionCheck, string]>((check) => [check, JSON.stringify(check)]);

    const toQuery = pairs.filter(([_, k]: [PermissionCheck, string]) => !cache.has(k));

    if (toQuery.length > 0) {
        const p = query({ checks: toQuery.map(([check, _]) => check) });
        toQuery.forEach(([_, k], i) => {
            cache.set(
                k,
                p.then((array) => array[i])
            );
        });
    }

    return Promise.all(pairs.map(([_, k]) => cache.get(k)!));
}

function _usePermissions({
    withCaching,
    checks
}: {
    withCaching: boolean;
    checks: PermissionCheck[];
}): {
    isLoading: boolean;
    permissions: boolean[];
} {
    const [{ isLoading, permissions }, setState] = useState({
        isLoading: true,
        permissions: new Array(checks.length).fill(false)
    });

    const fn = withCaching ? getPermissionPromise : query;

    useEffect(() => {
        fn({ checks }).then(
            (permissions) => {
                setState({ isLoading: false, permissions });
            },
            () => {
                setState({ isLoading: false, permissions });
            }
        );
    }, []);

    return { isLoading, permissions };
}

export function usePermissions(...checks: PermissionCheck[]): {
    isLoading: boolean;
    permissions: boolean[];
} {
    return _usePermissions({ withCaching: true, checks });
}

export function usePermissionsWithoutCaching(...checks: PermissionCheck[]): {
    isLoading: boolean;
    permissions: boolean[];
} {
    return _usePermissions({ withCaching: false, checks });
}
