import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { fromIconVO, I } from 'common/src/designSystem/components/i';
import { Link } from 'common/src/designSystem/components/link';
import { UserProfileAccreditationsQuery } from 'common/src/generated/types';
import { getColorPalette } from 'common/src/util/color';
import { AccreditationsPaths } from 'common/src/util/paths/accreditationsPaths';
import * as React from 'react';

interface IUserAccreditationsAssignedCalendarAccreditationProps {
    aui: UserProfileAccreditationsQuery['event']['volunteerRegistration']['accreditationsUsersInfos'][0];
}

export const UserAccreditationsAssignedCalendarAccreditation = (
    props: IUserAccreditationsAssignedCalendarAccreditationProps
) => {
    const {
        params: { organizationId, eventId }
    } = useHeavent();
    const colorCss = React.useMemo(() => {
        const palette = getColorPalette(props.aui.accreditation.color);

        return {
            background: palette['50'],
            color: palette['700']
        };
    }, [props.aui]);

    return (
        <Link
            style={{ width: '100%' }}
            to={AccreditationsPaths.ACCREDITATION({
                organizationId,
                eventId,
                accreditationId: props.aui.accreditation.id
            })}
        >
            <Flex
                align="center"
                css={{ ...colorCss, borderRadius: '$1', padding: '$1 $2' }}
                gap="1"
                width={1}
            >
                <Box fontSize="textXs">
                    <I icon={fromIconVO(props.aui.accreditation.icon)} />
                </Box>

                <Box fontSize="textXs" fontWeight="medium">
                    {props.aui.accreditation.name}
                </Box>

                {props.aui.isAutoAccredit && (
                    <Box fontSize="textXs">
                        <I icon="bolt-auto" />
                    </Box>
                )}
            </Flex>
        </Link>
    );
};
