import { Box } from 'common/src/designSystem/components/box';
import { theme } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

export function percentColor(percent: number): string {
    if (percent <= 0.2) {
        return theme.colors.error500.value;
    } else if (percent < 0.7) {
        return theme.colors.warning500.value;
    } else if (percent <= 1) {
        return theme.colors.success500.value;
    } else {
        return theme.colors.blueGray500.value;
    }
}

interface IProgressBarProps {
    percent: number;
    prospectivePercent?: number;
}

export const ProgressBar = ({ percent, prospectivePercent }: IProgressBarProps) => (
    <Box
        css={{
            background: '$gray100',
            borderRadius: '$1',
            position: 'relative'
        }}
        height={8}
        width={1}
    >
        <Box
            css={{
                background: percentColor(percent),
                borderRadius: '$1',
                left: 0,
                maxWidth: '100%',
                position: 'absolute',
                top: 0,
                width: `${percent * 100}%`
            }}
            height={1}
        />
        {prospectivePercent !== undefined && (
            <Box
                css={{
                    background: percentColor(prospectivePercent),
                    borderRadius: '$1',
                    left: 0,
                    opacity: 0.4,
                    maxWidth: '100%',
                    position: 'absolute',
                    top: 0,
                    width: `${prospectivePercent * 100}%`
                }}
                height={1}
            />
        )}
    </Box>
);
