import { AvatarNameEmail } from 'common-front/src/components/avatarNameEmail';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    AccreditationsSlotId,
    UsersInfoId,
    VolunteerRegistrationDelegationAccreditationSlotFragment
} from 'common/src/generated/types';
import * as React from 'react';
import { Button } from '../../designSystem/components/button';
import { Dropdown } from '../../designSystem/components/dropdown/dropdown';
import { ItemIcon } from '../../designSystem/components/dropdown/item';
import { Menu } from '../../designSystem/components/dropdown/menu';
import { Trigger } from '../../designSystem/components/dropdown/trigger';
import { useHeavent } from '../../hooks/useHeavent';

interface IDelegationAccreditationSlotMemberProps {
    accreditationSlotId: AccreditationsSlotId;
    member: VolunteerRegistrationDelegationAccreditationSlotFragment;

    addAccreditationSlot(
        userInfoId: UsersInfoId,
        accreditationSlotId: AccreditationsSlotId
    ): Promise<any>;
    deleteAccreditationSlot(
        userInfoId: UsersInfoId,
        accreditationSlotId: AccreditationsSlotId
    ): Promise<any>;
}

export const DelegationAccreditationSlotMember = (
    props: IDelegationAccreditationSlotMemberProps
) => {
    const { translate } = useHeavent();
    const isAssigned = props.member.accreditationsUsersInfos.some(
        (aui) => aui.accreditationSlotId === props.accreditationSlotId
    );

    return (
        <Row>
            <Cell>
                <Box css={{ flex: '1' }}>
                    <AvatarNameEmail userInfo={props.member.userInfo} />
                </Box>

                <Flex align="center" gap="3">
                    {isAssigned ? (
                        <>
                            <Flex align="center" gap="1">
                                <Box color="success500">
                                    <I icon="badge-check" iconStyle="solid" />
                                </Box>
                                <Box font="gray800 textXs medium">
                                    {translate('d_j_attribu_e_06450')}
                                </Box>
                            </Flex>

                            <Dropdown>
                                <Trigger>
                                    <Button color="white" leftIcon="ellipsis-vertical" size="sm" />
                                </Trigger>

                                <Menu placement="bottom-end">
                                    <ItemIcon
                                        color="red"
                                        icon="trash-can"
                                        onClick={() =>
                                            props.deleteAccreditationSlot(
                                                props.member.userInfo.id,
                                                props.accreditationSlotId
                                            )
                                        }
                                    >
                                        {translate('retirer_l_accr_62187')}
                                    </ItemIcon>
                                </Menu>
                            </Dropdown>
                        </>
                    ) : (
                        <Button
                            size="sm"
                            onClick={() =>
                                props.addAccreditationSlot(
                                    props.member.userInfo.id,
                                    props.accreditationSlotId
                                )
                            }
                        >
                            {translate('ajouter_97327')}
                        </Button>
                    )}
                </Flex>
            </Cell>
        </Row>
    );
};
