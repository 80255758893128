import { styled } from 'common/src/designSystem/components/stitches';
import {
    AccreditationImportFragment,
    CustomFieldImportFragment,
    VolunteersImportInput
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { fullName } from 'common/src/vo/accreditationSlot';
import { groupBy } from 'lodash-es';
import * as React from 'react';
import { ColumnMapping } from './columnMapping';
import { ImportFieldCategory } from './types';

const Grid = styled('div', {
    display: 'grid',
    gridTemplateColumns: '[column] 1fr auto [field] 1fr',
    gap: '$4 $5',
    alignItems: 'center'
});

const ColumnHeader = styled('div', {
    color: '$gray800',
    gridColumn: 'column',
    fontWeight: '$medium'
});

const FieldHeader = styled('div', {
    color: '$gray800',
    gridColumn: 'field',
    fontWeight: '$medium'
});

interface IColumnMappingsProps {
    accreditations: AccreditationImportFragment[];
    columns: string[];
    customFields: CustomFieldImportFragment[];
    defaultFields: Record<string, string>;
    requiredFieldSlugs: string[];
    values: VolunteersImportInput['mappings'];
}

export const ColumnMappings = ({
    accreditations,
    columns,
    customFields,
    defaultFields,
    requiredFieldSlugs,
    values
}: IColumnMappingsProps) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);
    const categories = React.useMemo(() => {
        const customFieldsCategories: ImportFieldCategory[] = Object.entries(
            groupBy(customFields, (cf) => cf.customFieldCategoryId)
        ).map(([_categoryId, fields]) => {
            const field = fields[0];

            return {
                id: field.customFieldCategoryId,
                name: field.customFieldCategoryName,
                fields: fields
            };
        });
        const accreditationsCategories: ImportFieldCategory[] = accreditations.map(
            (accreditation) => ({
                ...accreditation,
                fields: accreditation.slots.map((slot) => ({
                    id: slot.id,
                    slug: `accreditation_slot_${slot.id}`,
                    name: fullName(dateTimeService, slot, accreditation.name, {
                        accreditation: { includeNameAtEnd: true }
                    })
                }))
            })
        );

        return customFieldsCategories.concat(accreditationsCategories);
    }, [accreditations, customFields]);

    return (
        <Grid>
            <ColumnHeader>{translate('titres_de_vos_c_31388')}</ColumnHeader>
            <FieldHeader>{translate('champs_de_formu_64126')}</FieldHeader>
            {columns.map((name, index) => (
                <ColumnMapping
                    key={index}
                    categories={categories}
                    column={{ name, index }}
                    defaultFields={defaultFields}
                    requiredFieldSlugs={requiredFieldSlugs}
                    values={values}
                />
            ))}
        </Grid>
    );
};
