import { CommonEnvVars } from 'common/src/envVars';
import { IFragment } from 'common/src/generated/fragments';
import { allFragments } from 'common/src/graphql/allFragments';
import { parseGraphqlResult } from 'common/src/graphql/parseResult';
import { isNonEmptyArray } from 'common/src/util/array';
import { COMMIT_SHA } from 'common/src/util/commitSha';
import { NEED_LOGGED_IN_MESSAGE } from 'common/src/util/error';
import { HeaventHeaders } from 'common/src/util/headers';
import { uuidv4 } from 'common/src/util/uuid';
import { keys } from 'lodash-es';
import * as React from 'react';
import { getToken } from '../../util/aws/cognito';

export const H_NEW_VERSION = 'h-new-version';

export async function executeQuery<T>(
    query: string,
    options: {
        variables?: Record<string, any>;
        token?: string;
        fragments?: IFragment[];
        reloadOnNewVersion?: boolean;
    } = {}
): Promise<T> {
    let url = CommonEnvVars.HEAVENT_API_URL.href;
    const currentUrlSearchParams = new URL(location.href).searchParams;
    const finalQuery = `${query} ${
        isNonEmptyArray(options.fragments) ? allFragments(options.fragments) : ''
    }`;
    const headers: any = {
        'Content-Type': 'application/json',
        [HeaventHeaders.CommitSha]: COMMIT_SHA
    };

    if (currentUrlSearchParams.has('lang')) {
        url = `${url}?lang=${currentUrlSearchParams.get('lang')}`;
    }

    if (options.token) {
        headers[HeaventHeaders.UserToken] = options.token;
    }

    const response = await fetch(url, {
        method: 'post',
        headers,
        body: JSON.stringify({
            query: finalQuery,
            variables: options.variables || {},
            uuid: uuidv4()
        })
    });

    if (response.ok) {
        if (
            options.reloadOnNewVersion &&
            response.headers.get(HeaventHeaders.NewVersion) === 'true'
        ) {
            document.body.dispatchEvent(new CustomEvent(H_NEW_VERSION, { bubbles: true }));
        }

        const result = await response.json();

        if (result.errors) {
            const message = result.errors[0]?.message ?? '';

            if (message === NEED_LOGGED_IN_MESSAGE) {
                localStorage.clear();
                location.href = '/';

                return {} as any;
            } else if (message.startsWith('You do not have access to this')) {
                location.href = '/';

                return {} as any;
            } else {
                throw result.errors;
            }
        } else {
            const dataKeys = keys(result.data);
            const results: any = {};

            dataKeys.forEach((dataKey) => {
                results[dataKey] = parseGraphqlResult(result.data[dataKey]);
            });

            return results;
        }
    } else {
        throw new Error(response.statusText);
    }
}

export function useExecuteQueryImpl<TResult, TParams extends { [key: string]: any }>(
    query: string,
    variables: TParams,
    fragments: IFragment[]
): Promise<TResult> {
    const variablesString = Object.keys(variables)
        .sort()
        .map((key: string) => {
            const value = variables[key];

            return `"${key}":${JSON.stringify(value)}`;
        })
        .join(',');

    return React.useMemo(
        () =>
            getToken().then((token) =>
                executeQuery<TResult>(query, {
                    variables,
                    token: token || undefined,
                    fragments
                })
            ),
        [query, variablesString]
    );
}
