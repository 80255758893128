import { Accordion } from 'common-front/src/designSystem/components/accordion';
import { Button } from 'common-front/src/designSystem/components/button';
import { FileS3Input } from 'common-front/src/designSystem/components/file/fileS3Input';
import { FileS3InputValue } from 'common-front/src/designSystem/components/file/fileS3InputValue';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Accept } from 'common-front/src/util/accept';
import { Flex } from 'common/src/designSystem/components/flex';
import { CommonEnvVars } from 'common/src/envVars';
import { DocumentImageElement } from 'common/src/vo/documentBuilder';
import * as React from 'react';
import { useDocumentBuilderContext } from '../documentBuilderContext';
import { DocumentBuilderLeftPanelElement } from './documentBuilderLeftPanelElement';

interface IDocumentBuilderLeftPanelImageProps {
    image: DocumentImageElement;
}

export const DocumentBuilderLeftPanelImage = (props: IDocumentBuilderLeftPanelImageProps) => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();
    const { getSelectedPage, selectPage, updateElementProperty } = useDocumentBuilderContext();
    const onFilechange = (newFile: FileS3InputValue | null) => {
        if (newFile) {
            const newSource = `https://${CommonEnvVars.HEAVENT_AWS_UPLOADS_BUCKET}.s3.eu-west-1.amazonaws.com/${newFile.key}`;

            updateElementProperty(props.image.id, 'source', newSource);
        }
    };

    return (
        <Flex
            css={{ boxShadow: '$sm', overflow: 'hidden auto', paddingBottom: '$11' }}
            direction="column"
            height={1}
            width={1}
        >
            <Flex css={{ padding: '$2 $6' }}>
                <Button
                    color="invisible"
                    leftIcon="arrow-left"
                    onClick={() => {
                        selectPage(getSelectedPage()!.id);
                    }}
                >
                    {translate('retour_92202')}
                </Button>
            </Flex>

            <Accordion css={{ padding: '$4 $6' }} title={translate('initialisation_38640')}>
                <TextInput
                    label={translate('nom_08311')}
                    value={props.image.name}
                    onChange={(newName) => {
                        updateElementProperty(props.image.id, 'name', newName);
                    }}
                />

                <TextInput
                    hint={translate('utilisez_user_81685')}
                    label={translate('source_16397')}
                    value={props.image.source || ''}
                    onChange={(newSource) => {
                        updateElementProperty(props.image.id, 'source', newSource);
                    }}
                />

                <FileS3Input
                    accept={Accept.Images}
                    acl="public-read"
                    label={translate('File')}
                    organizationId={organizationId}
                    value={null}
                    onChange={onFilechange}
                />
            </Accordion>

            <DocumentBuilderLeftPanelElement element={props.image} />
        </Flex>
    );
};
