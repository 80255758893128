import { injectable } from 'inversify';
import * as yup from 'yup';
import {
    ALL_REGISTERACCREDITATIONDISPLAY,
    ALL_REGISTERPOSITIONFILTER,
    FormParametersAccreditationsInput,
    FormParametersAccreditationsQuery,
    RegisterAccreditationDisplay,
    RegisterPositionFilter
} from '../generated/types';
import { TranslationService } from '../services/translationService';
import { InputService } from './inputService';

export interface IUpdateFormParametersAccreditationsValues {
    form: FormParametersAccreditationsInput;
}

@injectable()
export class FormParametersAccreditationsInputService extends InputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    formParametersAccreditationsInputDefault(
        form?: FormParametersAccreditationsQuery['organization']['form']
    ): FormParametersAccreditationsInput {
        return {
            accreditationDisplay: form?.accreditationDisplay || RegisterAccreditationDisplay.None,
            accreditationFilter: form?.accreditationFilter || RegisterPositionFilter.None,
            hiddenAccreditationsCategoriesIds: form?.hiddenAccreditationsCategoriesIds || [],
            hiddenAccreditationsIds: form?.hiddenAccreditationsIds || [],
            hiddenAccreditationsSlotsIds: form?.hiddenAccreditationsSlotsIds || [],
            displayedAccreditationsCategoriesIds: form?.displayedAccreditationsCategoriesIds || [],
            displayedAccreditationsIds: form?.displayedAccreditationsIds || [],
            displayedAccreditationsSlotsIds: form?.displayedAccreditationsSlotsIds || [],
            showFullAccreditation: form?.showFullAccreditation ?? true,
            showAccreditationDescription: form?.showAccreditationDescription ?? false,
            accreditationTitle: form?.accreditationTitle || '',
            accreditationSubtitle: form?.accreditationSubtitle || ''
        };
    }

    formParametersAccreditationsInputSchema() {
        return yup.object().shape({
            accreditationDisplay: yup
                .string()
                .required(this.t('la_strat_gie_d_70458'))
                .oneOf(ALL_REGISTERACCREDITATIONDISPLAY, this.t('la_strat_gie_d_70458')),
            accreditationFilter: yup
                .string()
                .required(this.t('la_strat_gie_de_28051'))
                .oneOf(ALL_REGISTERPOSITIONFILTER, this.t('la_strat_gie_de_28051')),
            hiddenAccreditationsIds: yup.array().of(yup.number()),
            showFullAccreditation: yup.boolean().required(),
            showAccreditationDescription: yup.boolean().required(),
            accreditationTitle: yup.string(),
            accreditationSubtitle: yup.string()
        });
    }

    updateFormParametersAccreditationsSchema() {
        return yup.object().shape({
            form: this.formParametersAccreditationsInputSchema()
        });
    }
}
