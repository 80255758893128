import { Box } from 'common/src/designSystem/components/box';
import * as React from 'react';

interface IUpdateLinkTextCellProps {
    children: React.ReactNode;
    isEditMode: boolean;
    title: string;
    updatable: boolean;
}

export const UpdateLinkTextCell = ({
    children,
    isEditMode,
    title,
    updatable
}: IUpdateLinkTextCellProps) =>
    isEditMode && updatable ? (
        <Box css={{ ellipsis: true }} font="gray900 textSm medium" title={title} width={1}>
            {title}
        </Box>
    ) : (
        <>{children}</>
    );
