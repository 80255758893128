import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { preventDefault } from 'common/src/util/links';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useUniqueIds } from '../../hooks/useUniqueIds';
import { ITextInputProps } from './input/commonInputProps';
import { Description } from './input/description';
import { Hint } from './input/hint';
import { Label } from './input/label';
import { StyledInputContainer } from './input/styledInputContainer';

export const TextInput = React.forwardRef(
    (
        {
            autoComplete,
            isEmail,
            shouldParseAsInt,
            css,
            label,
            description,
            hint,
            onRightIconClick,
            state,
            type,
            onChange,
            rightIcon,
            rightText,
            icon,
            ...rest
        }: ITextInputProps,
        _ref: React.Ref<HTMLInputElement>
    ) => {
        const rootRef = React.useRef<any>();
        const newRef = React.useRef<any>();
        const ref = _ref ?? newRef;

        const { inputId, descId, errorId } = useUniqueIds();

        return (
            <Flex ref={rootRef} css={css} direction="column" width={1}>
                <Label htmlFor={inputId}>{label}</Label>

                <Description id={descId}>{description}</Description>

                {(label || description) && <Spacer height="1" />}

                <StyledInputContainer
                    cursor="text"
                    icon={icon}
                    rightIcon={rightIcon}
                    rightText={rightText}
                    state={state}
                    onClick={() => (ref as any).current?.focus()}
                    onRightIconClick={(e) => {
                        preventDefault(e);

                        onRightIconClick?.(e);
                    }}
                >
                    <Box
                        color="inherit"
                        css={{
                            flex: '1',
                            height: '100%',
                            '& input': {
                                background: 'transparent',
                                border: 'none',
                                color: '$gray800',
                                height: '100%',
                                outline: 'none',
                                width: '100%'
                            },
                            '& input::placeholder': {
                                color: state === 'search' ? '$gray400' : '$gray500',
                                opacity: 1
                            },
                            '& input::-ms-input-placeholder ': {
                                color: state === 'search' ? '$gray400' : '$gray500',
                                opacity: 1
                            }
                        }}
                    >
                        <input
                            ref={ref}
                            aria-describedby={description ? descId : undefined}
                            aria-errormessage={state === 'error' ? errorId : undefined}
                            aria-invalid={state === 'error'}
                            autoComplete={autoComplete ?? 'off'}
                            disabled={state === 'disabled'}
                            id={inputId}
                            type={
                                type ?? (isEmail ? 'email' : shouldParseAsInt ? 'number' : 'text')
                            }
                            onChange={(e) => {
                                if (isEmail && isNonEmptyString(e.target.value)) {
                                    onChange(e.target.value.toLowerCase());
                                } else if (shouldParseAsInt === true) {
                                    const intValue = parseInt(e.target.value, 10);

                                    if (isNaN(intValue)) {
                                        onChange(0);
                                    } else {
                                        onChange(intValue);
                                    }
                                } else {
                                    onChange(e.target.value);
                                }
                            }}
                            {...rest}
                        />
                    </Box>
                </StyledInputContainer>

                <Hint id={errorId} state={state}>
                    {hint}
                </Hint>
            </Flex>
        );
    }
);
TextInput.displayName = 'TextInput';
