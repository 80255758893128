import { Accordion } from 'common-front/src/designSystem/components/accordion';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { ToggleText } from 'common-front/src/designSystem/components/toggle';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { CustomFieldSlug, FieldType } from 'common/src/generated/types';
import { UUID } from 'common/src/util/uuid';
import { DocumentConditionsPoperties } from 'common/src/vo/documentBuilder';
import { produce } from 'immer';
import { difference } from 'lodash-es';
import * as React from 'react';
import { useDocumentBuilderContext } from '../documentBuilderContext';

interface IDocumentBuilderLeftPanelConditionsProps {
    element: DocumentConditionsPoperties;
    elementId: UUID;

    updateProperty(id: UUID, key: string, value: any): void;
}

export const DocumentBuilderLeftPanelConditions = (
    props: IDocumentBuilderLeftPanelConditionsProps
) => {
    const { translate } = useHeavent();
    const { accreditationsOptions, customFields, positionsOptions } = useDocumentBuilderContext();
    const conditionsOptions = React.useMemo(
        () =>
            customFields
                .filter(
                    (cf) =>
                        cf.fieldType === FieldType.Checkbox ||
                        cf.fieldType === FieldType.Select ||
                        cf.fieldType === FieldType.Text ||
                        cf.fieldType === FieldType.Validation
                )
                .map((cf) => (
                    <option key={cf.slug} value={cf.slug}>
                        {cf.name}
                    </option>
                )),
        [customFields]
    );
    const conditionsCustomFields = props.element.conditionsCustomFields || [];
    const customFieldsSlugs = conditionsCustomFields.map((cf) => cf.slug);
    const onCustomFieldsChange = (newCustomFieldsSlugs: CustomFieldSlug[]) => {
        const toAddSlugs = difference(newCustomFieldsSlugs, customFieldsSlugs);
        const toAddFields = customFields.filter((cf) => toAddSlugs.includes(cf.slug));
        const toRemove = difference(customFieldsSlugs, newCustomFieldsSlugs);
        const newConditionsCustomFields = conditionsCustomFields
            .filter((cf) => !toRemove.includes(cf.slug))
            .concat(
                toAddFields.map((cf) => ({
                    slug: cf.slug,
                    value:
                        cf.fieldType === FieldType.Checkbox || cf.fieldType === FieldType.Validation
                            ? true
                            : cf.fieldType === FieldType.Text
                              ? 'isNotEmpty'
                              : []
                }))
            );

        props.updateProperty(props.elementId, 'conditionsCustomFields', newConditionsCustomFields);
    };
    const onValueChange = (newValue: any, index: number) => {
        const newConditionsCustomFields = produce(
            conditionsCustomFields,
            (currentConditionsCustomFields) => {
                currentConditionsCustomFields[index].value = newValue;
            }
        );

        props.updateProperty(props.elementId, 'conditionsCustomFields', newConditionsCustomFields);
    };
    const selectedCustomFields = customFields.filter((cf) => customFieldsSlugs.includes(cf.slug));

    return (
        <Accordion css={{ padding: '$4 $6' }} title={translate('condition_d_aff_68185')}>
            <Flex direction="column" gap="4">
                <ToggleText
                    value={props.element.hasConditions}
                    onChange={(newHasConditions) => {
                        if (!newHasConditions) {
                            props.updateProperty(props.elementId, 'accreditationsSlotsIds', []);
                            props.updateProperty(props.elementId, 'positionsSlotsIds', []);
                        }

                        props.updateProperty(props.elementId, 'hasConditions', newHasConditions);
                    }}
                >
                    {translate('afficher_en_fon_47208')}
                </ToggleText>

                {props.element.hasConditions && (
                    <Flex
                        css={{
                            background: '$gray100',
                            border: '1px solid $gray200',
                            borderRadius: '$1',
                            boxShadow: '$xs',
                            padding: '$3 $4'
                        }}
                        direction="column"
                        gap="2"
                    >
                        <RichSelect
                            isSearchVisible={true}
                            label={translate('afficher_en_fon_89756')}
                            multiple={true}
                            values={props.element.accreditationsSlotsIds || []}
                            onChange={(newAccreditationsSlotsIds) => {
                                props.updateProperty(
                                    props.elementId,
                                    'accreditationsSlotsIds',
                                    newAccreditationsSlotsIds
                                );
                            }}
                        >
                            {accreditationsOptions}
                        </RichSelect>

                        <RichSelect
                            isSearchVisible={true}
                            label={translate('afficher_en_fon_19925')}
                            multiple={true}
                            values={props.element.positionsSlotsIds || []}
                            onChange={(newPositionsSlotsIds) => {
                                props.updateProperty(
                                    props.elementId,
                                    'positionsSlotsIds',
                                    newPositionsSlotsIds
                                );
                            }}
                        >
                            {positionsOptions}
                        </RichSelect>

                        <RichSelect
                            isSearchVisible={true}
                            label={translate('afficher_en_fon_56078')}
                            multiple={true}
                            values={customFieldsSlugs}
                            onChange={onCustomFieldsChange}
                        >
                            {conditionsOptions}
                        </RichSelect>

                        {selectedCustomFields.map((cf, index) =>
                            cf.fieldType === FieldType.Checkbox ||
                            cf.fieldType === FieldType.Validation ? (
                                <Select
                                    key={cf.id}
                                    label={translate('afficher_si_la_42654', cf.name)}
                                    shouldParseAsBoolean={true}
                                    value={conditionsCustomFields[index].value}
                                    onChange={(newValue) => {
                                        onValueChange(newValue, index);
                                    }}
                                >
                                    <option value="true">{translate('true')}</option>
                                    <option value="false">{translate('false')}</option>
                                </Select>
                            ) : cf.fieldType === FieldType.Text ? (
                                <Select
                                    key={cf.id}
                                    label={translate('afficher_si_la_42654', cf.name)}
                                    value={conditionsCustomFields[index].value}
                                    onChange={(newValue) => {
                                        onValueChange(newValue, index);
                                    }}
                                >
                                    <option value="isEmpty">{translate('est_vide_96759')}</option>
                                    <option value="isNotEmpty">
                                        {translate('n_est_pas_vide_45804')}
                                    </option>
                                </Select>
                            ) : (
                                <RichSelect
                                    key={cf.id}
                                    isSearchVisible={true}
                                    label={translate('afficher_si_la_42654', cf.name)}
                                    multiple={true}
                                    values={conditionsCustomFields[index].value || []}
                                    onChange={(newValues) => {
                                        onValueChange(newValues, index);
                                    }}
                                >
                                    {cf.values.map(({ id, value }) => (
                                        <option key={id} value={id}>
                                            {value}
                                        </option>
                                    ))}
                                </RichSelect>
                            )
                        )}
                    </Flex>
                )}
            </Flex>
        </Accordion>
    );
};
