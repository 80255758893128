import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { LinkText } from 'common/src/designSystem/components/linkText';
import { PositionPlanningFragment } from 'common/src/generated/types';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import * as React from 'react';

interface IPositionsPlanningLeftCellProps {
    position: PositionPlanningFragment;
}

export const PositionsPlanningLeftCell = (props: IPositionsPlanningLeftCellProps) => {
    const {
        params: { organizationId, eventId }
    } = useHeavent();

    return (
        <Flex css={{ padding: '$1 $4' }} direction="column" height={1} justify="center" width={1}>
            <LinkText
                css={{ width: '100%' }}
                to={PositionsPaths.POSITION({
                    organizationId,
                    eventId,
                    positionId: props.position.id
                })}
            >
                <Box css={{ ellipsis: true }} title={props.position.name} width={1}>
                    {props.position.name}
                </Box>
            </LinkText>

            <Box
                color="gray500"
                css={{ ellipsis: true }}
                title={props.position.category.name}
                width={1}
            >
                {props.position.category.name}
            </Box>
        </Flex>
    );
};
