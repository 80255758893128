import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { Row } from 'common/src/designSystem/components/table/row';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { OrganizationEventLevel } from 'common/src/generated/types';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { getStateBadgeColorIcon } from 'common/src/vo/delegation';
import * as React from 'react';
import { useDelegationCategoryDelegationsQuery } from '../../../generated/graphqlHooks';

export const DelegationCategoryDelegations = () => {
    const {
        history,
        translate,
        params: { organizationId, eventId, delegationCategoryId }
    } = useHeavent();
    const [name, nameDebounced, setName] = useStateDebounce('');
    const [offset, setOffset] = React.useState(0);
    const { data, isLoading } = useDelegationCategoryDelegationsQuery({
        organizationId,
        eventId,
        level: eventId ? OrganizationEventLevel.Event : OrganizationEventLevel.Organization,
        delegationCategoryId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        offset
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.organization?.delegations);

    return (
        <TableFilters
            filters={
                <>
                    <Box width={320}>
                        <TextInput
                            icon="magnifying-glass"
                            placeholder={translate('rechercher_une_31213')}
                            value={name}
                            onChange={setName}
                        />
                    </Box>
                </>
            }
            headerCells={
                <>
                    <HeaderCell>{translate('nom_de_la_d_l_g_28087')}</HeaderCell>
                    <HeaderCell>{translate('status_06428')}</HeaderCell>
                </>
            }
            numberOfPages={numberOfPages}
            offset={offset}
            rows={
                isLoading ? (
                    <>
                        <RowSkeleton bx={true} />
                        <RowSkeleton bx={true} />
                        <RowSkeleton bx={true} />
                    </>
                ) : (
                    data.organization.delegations.nodes.map((delegation) => (
                        <Row
                            key={delegation.id}
                            css={{ cursor: 'pointer' }}
                            onClick={() => {
                                history.push(
                                    DelegationsPaths.DELEGATION({
                                        organizationId,
                                        eventId,
                                        delegationId: delegation.id
                                    })
                                );
                            }}
                        >
                            <Cell>
                                <Box font="gray900 textSm medium">{delegation.name}</Box>
                            </Cell>

                            <Cell>
                                <Badge {...getStateBadgeColorIcon(delegation.state)}>
                                    {translate(delegation.state)}
                                </Badge>
                            </Cell>
                        </Row>
                    ))
                )
            }
            setOffset={setOffset}
            title={`Liste des délégations`}
            totalCount={totalCount}
        />
    );
};
