import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { CommentFragment } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import * as React from 'react';

interface IAssignmentUserPanelCommentProps {
    comment: CommentFragment;
}

export const AssignmentUserPanelComment = (props: IAssignmentUserPanelCommentProps) => {
    const dateTimeService = useService(DateTimeService);

    return (
        <Flex direction="column" gap="1">
            <Flex justify="between">
                <Box font="gray800 textXs medium">{props.comment.writtenBy.userInfo.name}</Box>

                <Box font="gray500 textXs regular">
                    {dateTimeService.toLocaleString(props.comment.insertedAt, {
                        ...LocaleFormats.DateTime,
                        month: 'numeric'
                    })}
                </Box>
            </Flex>

            <Flex
                css={{
                    background: '$gray100',
                    bd: true,
                    borderTopLeftRadius: '0',
                    padding: '$2 $3',
                    whiteSpace: 'pre-wrap'
                }}
            >
                {props.comment.content}
            </Flex>
        </Flex>
    );
};
