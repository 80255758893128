import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { SegmentsGrid } from 'common-front/src/segments/segmentsGrid';
import {
    DelegationId,
    DelegationsSegmentsQuery,
    SegmentId,
    SegmentType
} from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { CommonPaths } from 'common/src/util/paths/commonPaths';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { DEFAULT_PAGINATION_LIMIT } from 'common/src/vo/pagination';
import { DelegationsSegmentsService } from 'common/src/vo/segments/delegationsSegmentsService';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { DelegationsContent } from './delegationsContent';
import { DelegationsContextProvider } from './delegationsContext';

interface IDelegationsProps {
    event: DelegationsSegmentsQuery['event'];
    organization: DelegationsSegmentsQuery['organization'];

    reload(): void;
}

export const Delegations = (props: IDelegationsProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId, segmentId }
    } = useHeavent();
    const { isEventAdmin, localEvent, updateLocalEvent } = useEventContext();
    const segmentsService = useService(DelegationsSegmentsService);
    const filters = React.useMemo(
        () =>
            segmentsService.getDelegationsFilters(
                props.event.organizationId,
                eventId,
                props.organization.customFields.nodes,
                props.organization.delegationsCategories.nodes
            ),
        [props.event, props.organization, eventId]
    );
    const possibleColumns = React.useMemo(
        () => segmentsService.getDelegationsPossibleColumns(props.organization.customFields.nodes),
        [props.organization]
    );

    return (
        <DelegationsContextProvider
            eventId={eventId}
            getEditPath={(id: DelegationId) =>
                CommonPaths.EDIT(
                    DelegationsPaths.DELEGATION({
                        organizationId,
                        eventId,
                        delegationId: id
                    })
                )
            }
            getShowPath={(id: DelegationId) =>
                DelegationsPaths.DELEGATION({
                    organizationId,
                    eventId,
                    delegationId: id
                })
            }
            organizationId={organizationId}
        >
            <SegmentsGrid
                eventId={eventId}
                filters={filters}
                filtersButtonText={translate('filtrer_les_d_l_04628')}
                filtersSubtitle={translate('appliquer_des_f_65318')}
                getSegmentPath={(id: SegmentId) =>
                    HeaventPaths.DELEGATIONS_SEGMENT(organizationId, eventId, id)
                }
                hideSegments={props.event.numberOfDelegations === 0}
                initialLimit={localEvent?.delegationsLimit ?? DEFAULT_PAGINATION_LIMIT}
                initialSegmentsOpen={localEvent?.areDelegationsSegmentsOpen ?? true}
                isAdmin={isEventAdmin}
                organizationId={organizationId}
                possibleColumns={possibleColumns}
                reload={props.reload}
                segmentId={segmentId}
                segmentType={SegmentType.Delegations}
                segmentsFolders={props.event.segmentsFolders}
                setLimit={(delegationsLimit) => {
                    updateLocalEvent({ delegationsLimit });
                }}
                onSegmentClick={(selectedSegmentId) => {
                    updateLocalEvent({ delegationsSegmentId: selectedSegmentId });
                }}
                onSegmentDelete={async () => {
                    await updateLocalEvent({ delegationsSegmentId: null });

                    history.replace(DelegationsPaths.DELEGATIONS({ organizationId, eventId }));
                }}
                onSegmentsToggle={async (areSegmentsOpen) => {
                    await updateLocalEvent({ areDelegationsSegmentsOpen: areSegmentsOpen });
                }}
            >
                <DelegationsContent
                    customFields={props.organization.customFields.nodes}
                    numberOfDelegations={props.event.numberOfDelegations}
                />
            </SegmentsGrid>
        </DelegationsContextProvider>
    );
};
