import { Select } from 'common-front/src/designSystem/components/select/select';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Badge } from 'common/src/designSystem/components/badge';
import { CustomFieldFragment, CustomFieldProperty } from 'common/src/generated/types';
import * as React from 'react';
import { CustomFieldsUpdateCell } from './customFieldsUpdateCell';

interface ICustomFieldsPrivacyCellProps {
    customField: CustomFieldFragment;

    reload(): void;
}

export const CustomFieldsPrivacyCell = (props: ICustomFieldsPrivacyCellProps) => {
    const { translate } = useHeavent();

    return (
        <CustomFieldsUpdateCell
            cellCss={{ width: '200px' }}
            customFieldId={props.customField.id}
            initialValue={props.customField.isPrivate}
            property={CustomFieldProperty.Privacy}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <Select
                    label={translate('Privacy')}
                    shouldParseAsBoolean={true}
                    value={value}
                    onChange={setValue}
                >
                    <option value="true">{translate('Private')}</option>
                    <option value="false">{translate('Public')}</option>
                </Select>
            )}
            renderValue={() =>
                props.customField.isPrivate ? (
                    <Badge color="gray" leftIcon="lock">
                        {translate('Private')}
                    </Badge>
                ) : (
                    <Badge color="primary" leftIcon="globe">
                        {translate('Public')}
                    </Badge>
                )
            }
        />
    );
};
