import { Form } from 'common-front/src/components/form/form';
import { Button } from 'common-front/src/designSystem/components/button';
import { HorizontalSpacerSeparator } from 'common-front/src/designSystem/components/separator';
import { CheckboxText } from 'common-front/src/designSystem/form/checkbox';
import { RichEditor } from 'common-front/src/designSystem/form/richEditor';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { ToggleText } from 'common-front/src/designSystem/form/toggle';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { MassEditCustomFieldFragment } from 'common/src/generated/types';
import {
    FormParametersGeneralInputService,
    IUpdateFormParametersGeneralValues
} from 'common/src/input/formParametersGeneralInput';
import { ValidateService } from 'common/src/services/validateService';
import { useService } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import {
    useFormParametersGeneralQuery,
    useFormParametersGeneralUpdateMutation
} from '../../../../generated/graphqlHooks';
import { FormContainer } from '../../../show/formContainer';
import { FormParametersContainer } from '../formParametersContainer';
import { useFormParametersClose } from '../useFormParametersClose';
import { FormParametersGeneralCustomFieldsToggler } from './formParametersGeneralCustomFieldsToggler';

interface IFormParametersGeneralInnerProps {
    customFields: MassEditCustomFieldFragment[];
    isMutateLoading: boolean;
    initialValues: IUpdateFormParametersGeneralValues;
    loader: React.JSX.Element | null;
    name: string;
    values: IUpdateFormParametersGeneralValues;

    change(name: string, value: any): void;
    handleSubmit(): void;
}

const FormParametersGeneralInner = (props: IFormParametersGeneralInnerProps) => {
    const {
        translate,
        params: { eventId }
    } = useHeavent();
    const { isNotSavedModalOpen, onClose, onNotSavedClose, onSave } = useFormParametersClose(
        props.initialValues,
        props.values,
        props.handleSubmit
    );

    return (
        <FormContainer
            button={
                <Button isLoading={props.isMutateLoading} onClick={onSave}>
                    {translate('enregistrer_06519')}
                </Button>
            }
            title={props.name}
            onClose={onClose}
        >
            <FormParametersContainer
                isNotSavedOpen={isNotSavedModalOpen}
                onNotSavedClose={onNotSavedClose}
            >
                {props.loader || (
                    <>
                        <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                            {translate('initialisation_29288')}
                        </Box>

                        <Spacer height="1" />

                        <Box color="gray500">{translate('personnaliser_l_27381')}</Box>

                        <Spacer height="4" />

                        <TextInput label={translate('nom_du_formulai_14952')} name="form.name" />

                        <Spacer height="6" />

                        <Box color="gray800" fontWeight="semiBold">
                            {translate('inscriptions_mu_35073')}
                        </Box>

                        {eventId && (
                            <>
                                <Spacer height="6" />

                                <Box color="gray800" fontWeight="semiBold">
                                    {translate('affectation_des_81808')}
                                </Box>

                                <Spacer height="2" />

                                <CheckboxText name="form.setAssignmentStateToWaiting">
                                    {translate('les_membres_ser_52180')}
                                </CheckboxText>

                                <Spacer height="6" />

                                <Box color="gray800" fontWeight="semiBold">
                                    {translate('accr_ditation_d_48426')}
                                </Box>

                                <Spacer height="2" />

                                <CheckboxText name="form.setAccreditationStateToWaiting">
                                    {translate('les_membres_ser_34727')}
                                </CheckboxText>
                            </>
                        )}

                        <HorizontalSpacerSeparator height="7" />

                        <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                            {translate('param_trage_de_16132')}
                        </Box>

                        <Spacer height="1" />

                        <Box color="gray500">{translate('donner_la_possi_60365')}</Box>

                        <Spacer height="4" />

                        <Box font="gray800 textSm semiBold">
                            {translate('inscriptions_mu_35073')}
                        </Box>

                        <Spacer height="2" />

                        <ToggleText name="form.allowMultiProfiles">
                            {translate('autoriser_les_i_26880')}
                        </ToggleText>

                        {eventId && (
                            <>
                                <Spacer height="4" />

                                <Box font="gray800 textSm semiBold">
                                    {translate('inscriptions_en_77739')}
                                </Box>

                                <Spacer height="2" />

                                <ToggleText name="form.areTeamsAllowed">
                                    {translate('permettre_aux_m_56083')}
                                </ToggleText>

                                {props.values.form.areTeamsAllowed && (
                                    <>
                                        <Spacer height="4" />

                                        <ToggleText name="form.teamMemberFillFullForm">
                                            {translate('tous_les_membre_59937')}
                                        </ToggleText>

                                        <Spacer height="4" />

                                        <TextInput
                                            label={translate('nombre_maximum_14615')}
                                            name="form.maxTeamMembers"
                                            shouldParseAsInt={true}
                                        />
                                    </>
                                )}
                            </>
                        )}

                        <HorizontalSpacerSeparator height="7" />

                        <FormParametersGeneralCustomFieldsToggler
                            change={props.change}
                            customFields={props.customFields}
                            name="form.customFields"
                            values={props.values.form.customFields}
                        />

                        <HorizontalSpacerSeparator height="7" />

                        <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                            {translate('texte_de_l_tap_00197')}
                        </Box>

                        <Spacer height="1" />

                        <Box color="gray500">{translate('personnaliser_l_68212')}</Box>

                        <Spacer height="2" />

                        <TextInput name="form.emailTitle" />

                        <Spacer height="6" />

                        <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                            {translate('description_de_80800')}
                        </Box>

                        <Spacer height="1" />

                        <Box color="gray500">{translate('la_description_13257')}</Box>

                        <Spacer height="2" />

                        <RichEditor name="form.description" />

                        <Spacer height="6" />

                        <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                            {translate('texte_de_l_tap_92536')}
                        </Box>

                        <Spacer height="1" />

                        <Box color="gray500">{translate('personnaliser_l_66470')}</Box>

                        <Spacer height="2" />

                        <TextInput name="form.informationTitle" />

                        <Spacer height="6" />

                        <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                            {translate('texte_du_bouton_05866')}
                        </Box>

                        <Spacer height="1" />

                        <Box color="gray500">{translate('personnaliser_l_02611')}</Box>

                        <Spacer height="2" />

                        <TextInput name="form.registerButtonText" />

                        <Spacer height="6" />

                        <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                            {translate('message_de_succ_99510')}
                        </Box>

                        <Spacer height="1" />

                        <Box color="gray500">{translate('personnaliser_l_55334')}</Box>

                        <Spacer height="2" />

                        <RichEditor name="form.successMessage" />

                        <HorizontalSpacerSeparator height="7" />

                        <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                            {translate('_dition_des_inf_90758')}
                        </Box>

                        <Box color="gray500">{translate('donner_la_possi_56683')}</Box>

                        <Spacer height="6" />

                        <Box color="gray800" fontWeight="semiBold">
                            {translate('espace_membre_06532')}
                        </Box>

                        <Spacer height="2" />

                        <ToggleText name="form.isEditableInMemberSpace">
                            {translate('permettre_aux_m_40630')}
                        </ToggleText>
                    </>
                )}
            </FormParametersContainer>
        </FormContainer>
    );
};

export const FormParametersGeneral = () => {
    const {
        params: { organizationId, eventId, formId }
    } = useHeavent();
    const { data, loader } = useFormParametersGeneralQuery({ organizationId, formId });
    useTitle(data.organization?.form.name ?? '');
    const validateService = useService(ValidateService);
    const formParametersGeneralInput = useService(FormParametersGeneralInputService);
    const { mutate, isLoading } = useFormParametersGeneralUpdateMutation();
    const initialValues = React.useMemo(
        () => ({
            form: formParametersGeneralInput.formParametersGeneralInputDefault(
                data.organization?.form
            )
        }),
        [data.organization]
    );

    return (
        <>
            <Form
                direction="column"
                height={1}
                initialValues={initialValues}
                render={({ form, handleSubmit, values }) => (
                    <FormParametersGeneralInner
                        change={form.change}
                        customFields={data.organization?.customFields.nodes ?? []}
                        handleSubmit={handleSubmit}
                        initialValues={initialValues}
                        isMutateLoading={isLoading}
                        loader={loader}
                        name={data.organization?.form.name ?? ''}
                        values={values}
                    />
                )}
                validate={validateService.validateForForm(
                    formParametersGeneralInput.updateFormParametersGeneralSchema()
                )}
                width={1}
                onSubmit={(values: IUpdateFormParametersGeneralValues) =>
                    mutate({
                        organizationId,
                        eventId,
                        formId,
                        form: {
                            ...values.form,
                            description: values.form.description || '',
                            successMessage: values.form.successMessage || ''
                        }
                    })
                }
            />
        </>
    );
};
