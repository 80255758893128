import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { OnboardingView } from 'common-front/src/components/onboardingView/onboardingView';
import { Button } from 'common-front/src/designSystem/components/button';
import { EmptyFormBox, FormBox } from 'common-front/src/designSystem/components/formBox';
import { LabelOptional } from 'common-front/src/designSystem/components/input/labelOptional';
import { CheckboxText } from 'common-front/src/designSystem/form/checkbox';
import { PhoneInput } from 'common-front/src/designSystem/form/phoneInput';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { signOut } from 'common-front/src/util/aws/cognito';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    IUpdateUserInfoAndTermsValues,
    UserInfoInputService
} from 'common/src/input/userInfoInput';
import { ValidateService } from 'common/src/services/validateService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { OrganizationsPaths } from 'common/src/util/paths/organizationsPaths';
import * as React from 'react';
import { useUserInfoAndTermsUpdateMutation, useUserInfoQuery } from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../organizationContext';
import { TermsCheckboxLabel } from './termsCheckboxLabel';

export const AcceptTerms = () => {
    const {
        history,
        translate,
        params: { organizationId }
    } = useHeavent();
    const { data, loader } = useUserInfoQuery({ organizationId });
    const userInfoInput = useService(UserInfoInputService);
    const validateService = useService(ValidateService);
    const { mutate } = useUserInfoAndTermsUpdateMutation();
    const onboardingViewRef = React.useRef<HTMLDivElement | null>(null);
    const { reload } = useOrganizationContext();

    return (
        loader || (
            <Form
                height={1}
                hideErrors={true}
                initialValues={{
                    userInfoAndTerms: userInfoInput.userInfoAndTermsInputDefault(data.user)
                }}
                render={({ handleSubmit }) => (
                    <OnboardingView ref={onboardingViewRef} onClose={signOut}>
                        <FormErrors />
                        <FormBox
                            centerTitleAndSubtitle={true}
                            hideToggle={true}
                            initialIsOpen={true}
                            subtitle={translate('accepter_nos_co_49122')}
                            title={translate('conditions_g_n_79662')}
                        >
                            <CheckboxText
                                name="userInfoAndTerms.termsAccepted"
                                shouldPreventDefault={false}
                            >
                                <TermsCheckboxLabel />
                            </CheckboxText>
                            <Spacer height="4" />
                            <Flex gap="4">
                                <TextInput
                                    label={
                                        <LabelOptional>{translate('pr_nom_66988')}</LabelOptional>
                                    }
                                    name="userInfoAndTerms.firstName"
                                />

                                <TextInput
                                    label={<LabelOptional>{translate('last_name')}</LabelOptional>}
                                    name="userInfoAndTerms.lastName"
                                />
                            </Flex>
                            <Spacer height="4" />
                            <PhoneInput
                                label={<LabelOptional>{translate('Phone')}</LabelOptional>}
                                prefix="userInfoAndTerms.phone."
                            />
                        </FormBox>
                        <EmptyFormBox>
                            <Button textAlign="center" onClick={handleSubmit}>
                                {translate('mettre_jour_85141')}
                            </Button>
                        </EmptyFormBox>
                    </OnboardingView>
                )}
                validate={validateService.validateForForm(
                    userInfoInput.updateUserInfoAndTermsSchema()
                )}
                width={1}
                onShowErrors={() => {
                    if (onboardingViewRef.current) {
                        onboardingViewRef.current.scrollTop = 0;
                    }
                }}
                onSubmit={async (values: IUpdateUserInfoAndTermsValues) => {
                    await mutate({
                        userInfoId: data.user.userInfo.id,
                        userInfoAndTermsInput: values.userInfoAndTerms
                    });

                    reload();

                    if (history.location.state) {
                        // Came from <TermsAccepted>. Go to where the user intended to go.
                        history.push(history.location.state);
                    } else {
                        // Go to the org’s home.
                        history.push(OrganizationsPaths.ORGANIZATION({ organizationId }));
                    }
                }}
            />
        )
    );
};
