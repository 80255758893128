import { sortBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { Span } from '../../../designSystem/components/span';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { getBarcodeHref } from '../../../util/url';

interface IAnimajeConventionProps {
    event: DocumentUserCustomBadgeFragment;
}

export const AnimajeConvention = (props: IAnimajeConventionProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const psuis = sortBy(vr.positionsSlotsUsersInfos, (psui) =>
        psui.positionSlot.range.start!.toMillis()
    );

    return (
        <>
            <Flex
                css={{
                    background: `url(https://assets.recrewteer.com/badges/animaje/convention-page1.jpg) no-repeat`,
                    backgroundSize: 'cover',
                    color: '#040f29',
                    fontFamily: '$neufileGrotesk',
                    pageBreakBefore: 'always',
                    position: 'relative'
                }}
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
            >
                <Box
                    css={{
                        fontSize: '24px',
                        fontWeight: '700',
                        left: '72px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '334px'
                    }}
                >
                    {ui.firstName} {ui.lastName}
                </Box>

                <Box
                    css={{
                        fontSize: '14px',
                        fontWeight: '500',
                        left: '72px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '364px'
                    }}
                >
                    {ui.fields?.phone?.internationalFormat || ''}
                </Box>

                <Box
                    css={{
                        left: '650px',
                        position: 'absolute',
                        top: '322px'
                    }}
                    height={72}
                    width={72}
                >
                    <img
                        height="100%"
                        src={getBarcodeHref(vr.ticketId, {
                            height: 80,
                            width: 80
                        })}
                        width="100%"
                    />
                </Box>

                <Flex
                    css={{
                        left: '72px',
                        position: 'absolute',
                        top: '558px'
                    }}
                    direction="column"
                    gap="1"
                >
                    {psuis.map((psui, index) => (
                        <Box key={index} css={{ fontSize: '12px', fontWeight: '500' }}>
                            <Span css={{ fontWeight: '700', textTransform: 'capitalize' }}>
                                {psui.positionSlot.range.start!.toFormat(
                                    `cccc dd MMMM 'de' HH'h'mm`,
                                    {
                                        locale: 'fr'
                                    }
                                )}{' '}
                                à {psui.positionSlot.range.end!.toFormat(`HH'h'mm`)}
                            </Span>{' '}
                            : {psui.position.name}
                        </Box>
                    ))}
                </Flex>
            </Flex>

            <Flex
                css={{
                    background: `url(https://assets.recrewteer.com/badges/animaje/convention-page2.jpg) no-repeat`,
                    backgroundSize: 'cover',
                    color: '#040f29',
                    fontFamily: '$neufileGrotesk',
                    pageBreakBefore: 'always',
                    position: 'relative'
                }}
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
            >
                <Box
                    css={{
                        fontSize: '24px',
                        fontWeight: '700',
                        left: '72px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '222px'
                    }}
                >
                    {ui.firstName} {ui.lastName}
                </Box>

                <Box
                    css={{
                        fontSize: '14px',
                        fontWeight: '500',
                        left: '72px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '252px'
                    }}
                >
                    {ui.fields?.phone?.internationalFormat || ''}
                </Box>

                <Box
                    css={{
                        left: '650px',
                        position: 'absolute',
                        top: '210px'
                    }}
                    height={72}
                    width={72}
                >
                    <img
                        height="100%"
                        src={getBarcodeHref(vr.ticketId, {
                            height: 80,
                            width: 80
                        })}
                        width="100%"
                    />
                </Box>

                <Box
                    css={{
                        fontSize: '12px',
                        fontWeight: '700',
                        left: '178px',
                        position: 'absolute',
                        top: '455px'
                    }}
                >
                    {ui.firstName} {ui.lastName}
                </Box>

                <Box
                    css={{
                        fontSize: '12px',
                        fontWeight: '700',
                        left: '72px',
                        position: 'absolute',
                        top: '537px'
                    }}
                >
                    {ui.firstName} {ui.lastName}
                </Box>

                <Box
                    css={{
                        fontSize: '12px',
                        fontWeight: '700',
                        left: '72px',
                        position: 'absolute',
                        top: '617px'
                    }}
                >
                    {ui.firstName} {ui.lastName}
                </Box>
            </Flex>
        </>
    );
};
