import { Button } from 'common-front/src/designSystem/components/button';
import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger as TooltipTrigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { DocumentsQuery, UsersInfoId } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { getFormat } from 'common/src/vo/documentBuilder';
import * as React from 'react';
import {
    useCustomDocumentDeleteMutation,
    useCustomDocumentDuplicateMutation
} from '../../../generated/graphqlHooks';

interface ICustomDocumentRowProps {
    customDocument: DocumentsQuery['event']['customDocuments'][number];
    disabled: boolean;
    userInfoId: UsersInfoId;

    reload(): void;
}

export const CustomDocumentRow = (props: ICustomDocumentRowProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { mutate: customDocumentDuplicate } = useCustomDocumentDuplicateMutation();
    const { mutate: customDocumentDelete } = useCustomDocumentDeleteMutation();
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
    const format = getFormat(
        props.customDocument.configuration.height,
        props.customDocument.configuration.width
    );
    const populations = props.customDocument.populations.join(', ');

    return (
        <>
            <Flex
                align="center"
                css={{
                    borderBottom: '1px solid $gray200',
                    px: '$6'
                }}
                gap="4"
                height={74}
                width={1}
            >
                <Flex
                    align="center"
                    css={{
                        background: '$success100',
                        borderRadius: '$3',
                        color: '$success700',
                        fontSize: '$textMd'
                    }}
                    height={40}
                    justify="center"
                    width={40}
                >
                    <I icon="pen-ruler" />
                </Flex>

                <Flex css={{ flex: '1' }} direction="column" gap="1">
                    <Box
                        css={
                            props.disabled
                                ? {}
                                : {
                                      cursor: 'pointer',
                                      '&:hover': {
                                          textDecoration: 'underline'
                                      }
                                  }
                        }
                        font="gray800 textMd medium"
                        onClick={() => {
                            if (!props.disabled) {
                                history.push(
                                    HeaventPaths.DOCUMENTS_USER_CUSTOM_DOCUMENT(
                                        organizationId,
                                        eventId,
                                        props.userInfoId,
                                        props.customDocument.id
                                    )
                                );
                            }
                        }}
                    >
                        {props.customDocument.name}
                    </Box>

                    <Box color="gray500">
                        {translate('cr_par_1_69201', props.customDocument.createdBy.nameOrEmail)}
                    </Box>
                </Flex>

                <Flex direction="column" gap="1" width={100}>
                    <Box font="gray500 textXs regular">{translate('populations_48141')}</Box>

                    <Box
                        css={{ ellipsis: true }}
                        font="gray800 textSm medium"
                        title={populations}
                        width={1}
                    >
                        {isNonEmptyString(populations) ? populations : '-'}
                    </Box>
                </Flex>

                <Flex direction="column" gap="1" width={85}>
                    <Box font="gray500 textXs regular">{translate('format_54368')}</Box>

                    <Box font="gray800 textSm medium">
                        {format === 'custom'
                            ? translate('personnalis_40360')
                            : format.toUpperCase()}
                    </Box>
                </Flex>

                <Flex direction="column" gap="1" width={280}>
                    <Box font="gray500 textXs regular">{translate('nom_de_la_varia_81999')}</Box>

                    <Box
                        css={{ ellipsis: true }}
                        font="gray800 textSm medium"
                        title={`{user.documents.${props.customDocument.slug}}`}
                        width={1}
                    >{`{user.documents.${props.customDocument.slug}}`}</Box>
                </Flex>

                <Tooltip>
                    <TooltipTrigger>
                        <Button
                            color="white"
                            leftIcon="pen"
                            size="sm"
                            onClick={() => {
                                history.push(
                                    HeaventPaths.EDIT_CUSTOM_DOCUMENT(
                                        organizationId,
                                        eventId,
                                        props.customDocument.id
                                    )
                                );
                            }}
                        />
                    </TooltipTrigger>

                    <Content placement="top">{translate('_diter_62574')}</Content>
                </Tooltip>

                <Dropdown>
                    <Trigger>
                        <Button color="white" leftIcon="ellipsis-vertical" size="sm" />
                    </Trigger>

                    <Menu placement="bottom-end">
                        <ItemIcon
                            icon="clone"
                            onClick={async () => {
                                await customDocumentDuplicate({
                                    eventId,
                                    customDocumentId: props.customDocument.id
                                });

                                props.reload();
                            }}
                        >
                            {translate('dupliquer_28256')}
                        </ItemIcon>

                        <ItemIcon
                            color="red"
                            icon="trash-can"
                            onClick={() => {
                                setIsDeleteOpen(true);
                            }}
                        >
                            {translate('supprimer_43083')}
                        </ItemIcon>
                    </Menu>
                </Dropdown>
            </Flex>

            {isDeleteOpen && (
                <DeleteModal
                    buttonText={translate('supprimer_43083')}
                    subtext={translate('_tes_vous_s_r_d_81333')}
                    text={translate('suppression_de_73669', props.customDocument.name)}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                    onDelete={async () => {
                        await customDocumentDelete({
                            eventId,
                            customDocumentId: props.customDocument.id
                        });

                        props.reload();
                    }}
                />
            )}
        </>
    );
};
