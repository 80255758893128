import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import * as React from 'react';
import { Dropdown } from './dropdown';
import { Menu } from './menu';
import { Trigger } from './trigger';

interface IDropdownEllipsisProps {
    children: React.ReactNode;
    isOpen?: boolean;
    triggerElem?: React.ReactNode;

    onStateChange?(isOpen: boolean): void;
}

export const DropdownEllipsis = (props: IDropdownEllipsisProps) => (
    <Dropdown isOpen={props.isOpen} onStateChange={props.onStateChange}>
        <Trigger>
            {props.triggerElem || (
                <Flex align="center" height={20} justify="center" width={20}>
                    <I icon="ellipsis-vertical" />
                </Flex>
            )}
        </Trigger>

        <Menu placement="bottom-end">{props.children}</Menu>
    </Dropdown>
);
