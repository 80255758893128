import { useBodyClick } from 'common-front/src/hooks/useBodyClick';
import { useEsc } from 'common-front/src/hooks/useEsc';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { EventId, PositionsQuery, TraceId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { Trace } from './trace';

interface ITracesProps {
    eventId: EventId;
    hiddenTracesIds: TraceId[];
    traces: PositionsQuery['data']['traces'];

    onAddTrace(): void;
    onClose(): void;
    reload(): void;
    toggleVisible(id: TraceId): void;
}

export const Traces = (props: ITracesProps) => {
    const translate = useTranslate();

    useEsc(props.onClose);
    useBodyClick(props.onClose);

    return (
        <Flex
            css={{
                background: 'white',
                borderRadius: '$1',
                boxShadow: '$xs',
                padding: '$1 $3',
                width: '240px'
            }}
            direction="column"
            onClick={(e) => {
                e.nativeEvent.stopImmediatePropagation();
            }}
        >
            {props.traces.map((trace) => (
                <Trace
                    key={trace.id}
                    eventId={props.eventId}
                    isHidden={props.hiddenTracesIds.includes(trace.id)}
                    reload={props.reload}
                    toggleVisible={props.toggleVisible}
                    trace={trace}
                />
            ))}

            <Box
                color="primary700"
                css={{ cursor: 'pointer', py: '$2' }}
                onClick={() => {
                    props.onAddTrace();
                    props.onClose();
                }}
            >
                {translate('ajouter_un_trac_05425')}
            </Box>
        </Flex>
    );
};
