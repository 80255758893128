import { DelegationState } from '../generated/types';
import { assertUnreachable } from '../util/assertUnreachable';
import { IBadgeColorIcon } from './volunteerRegistration';

export function getDefaultColumns() {
    return ['name', 'accreditations', 'accreditation_state'];
}

export function getStateBadgeColorIcon(state: DelegationState): IBadgeColorIcon {
    switch (state) {
        case DelegationState.Expired:
            return { color: 'error', leftIcon: 'clock' };
        case DelegationState.Full:
            return { color: 'error', leftIcon: 'hundred-points' };
        case DelegationState.Locked:
            return { color: 'error', leftIcon: 'lock-keyhole' };
        case DelegationState.Ongoing:
            return { color: 'success', leftIcon: 'check' };
        default:
            return assertUnreachable(state);
    }
}
