import { Planning } from 'common-front/src/components/planning/planning';
import { PaginationCell } from 'common-front/src/designSystem/components/pagination/paginationCell';
import { PaginationRow } from 'common-front/src/designSystem/components/pagination/paginationRow';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { getToken } from 'common-front/src/util/aws/cognito';
import { Flex } from 'common/src/designSystem/components/flex';
import { PositionId, PositionPlanningFragment } from 'common/src/generated/types';
import { PositionsSlotInputService } from 'common/src/input/positionsSlotInput';
import { useService } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { DateTime } from 'luxon';
import * as React from 'react';
import {
    executePositionsPlanningPositionQuery,
    usePositionSlotCreateMutation,
    usePositionsPlanningQuery
} from '../../../generated/graphqlHooks';
import { localStateDateTimeSerde, useLocalEventState } from '../../../hooks/useLocalState';
import { CreatePositionCreateSlotModal } from '../../create/createPositionCreateSlotModal';
import { PositionsPlanningLeftCell } from './positionsPlanningLeftCell';
import { PositionsPlanningSlot } from './positionsPlanningSlot';

interface IPositionsPlanningProps {
    endAt: DateTime;
    startAt: DateTime;
}

export const PositionsPlanning = (props: IPositionsPlanningProps) => {
    const {
        params: { eventId }
    } = useHeavent();
    const positionsSlotInput = useService(PositionsSlotInputService);
    const { isEditMode, limit, nameDebounced, offset, predicates, setLimit, setOffset } =
        useSegmentsContext();
    const { mutate: positionSlotCreate } = usePositionSlotCreateMutation();
    const { data, loader } = usePositionsPlanningQuery({
        eventId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        predicates,
        limit,
        offset
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.data?.rows);
    const [positions, setPositions] = React.useState<PositionPlanningFragment[]>([]);
    const [date, setDate] = useLocalEventState(
        'positions.planning.startAt',
        props.startAt,
        localStateDateTimeSerde
    );
    const [isCreateSlotOpen, setIsCreateSlotOpen] = React.useState(false);
    const [startAt, setStartAt] = React.useState(props.startAt);
    const [positionId, setPositionId] = React.useState(-1 as PositionId);
    const reloadPosition = React.useCallback(
        async (positionId: PositionId) => {
            const {
                data: { row }
            } = await executePositionsPlanningPositionQuery(
                { eventId, positionId },
                await getToken()
            );

            setPositions((currentPositions) =>
                currentPositions.map((p) => (p.id === positionId ? row : p))
            );
        },
        [eventId]
    );

    React.useEffect(() => {
        setPositions(data.data?.rows.nodes ?? []);
    }, [data.data]);

    if (loader) {
        return loader;
    } else {
        return (
            <>
                <Flex css={{ flex: '1' }} direction="column" width={1}>
                    <Flex css={{ background: 'white', flex: '1' }}>
                        <Planning.Root
                            data={positions}
                            date={date}
                            maxDate={props.endAt}
                            minDate={props.startAt}
                            renderLeftCell={(position) => (
                                <PositionsPlanningLeftCell position={position} />
                            )}
                            renderSlot={(slot, position) => (
                                <PositionsPlanningSlot position={position} slot={slot} />
                            )}
                            showPlus={isEditMode}
                            onChange={setDate}
                            onPlus={(startDate, position) => {
                                setStartAt(startDate);
                                setIsCreateSlotOpen(true);
                                setPositionId(position.id);
                            }}
                        />
                    </Flex>

                    <PaginationRow>
                        <PaginationCell
                            limit={limit}
                            numberOfPages={numberOfPages}
                            offset={offset}
                            setLimit={setLimit}
                            setOffset={setOffset}
                            showLimits={true}
                            totalCount={totalCount}
                        />
                    </PaginationRow>
                </Flex>

                {isCreateSlotOpen && (
                    <CreatePositionCreateSlotModal
                        event={{
                            endAt: props.endAt,
                            startAt: props.startAt
                        }}
                        slot={positionsSlotInput.positionsSlotInputDefault(startAt)}
                        onClose={() => {
                            setIsCreateSlotOpen(false);
                        }}
                        onSuccess={async (slot) => {
                            await positionSlotCreate({
                                eventId,
                                positionId,
                                positionSlot: slot
                            });

                            return reloadPosition(positionId);
                        }}
                    />
                )}
            </>
        );
    }
};
