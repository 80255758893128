import { CustomFieldFragment, CustomFieldProperty } from 'common/src/generated/types';
import * as React from 'react';
import { CustomFieldsCategoryCellInput } from './customFieldsCategoryCellInput';
import { CustomFieldsUpdateCell } from './customFieldsUpdateCell';

interface ICustomFieldsCategoryCellProps {
    customField: CustomFieldFragment;

    reload(): void;
}

export const CustomFieldsCategoryCell = (props: ICustomFieldsCategoryCellProps) => (
    <CustomFieldsUpdateCell
        customFieldId={props.customField.id}
        initialValue={props.customField.category.id}
        property={CustomFieldProperty.Category}
        reload={props.reload}
        renderInput={(value, setValue) => (
            <CustomFieldsCategoryCellInput setValue={setValue} value={value} />
        )}
        renderValue={props.customField.category.name}
    />
);
