import { LocalEvent } from 'common-front/src/localDatabase';
import { Emptyable } from 'common/src/util/emptyable';
import { isNonEmptyString } from 'common/src/util/string';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useEventContext } from '../events/show/eventContext';

interface IUseLocalEventStateOptions<S> {
    deserialize?(state: Emptyable<string>): S | null;
    serialize?(state: S): string;
}

export const localStateDateTimeSerde = {
    deserialize(state: Emptyable<string>) {
        return isNonEmptyString(state) && DateTime.fromISO(state)?.isValid
            ? DateTime.fromISO(state)
            : null;
    },
    serialize(state: DateTime) {
        return state.toISO()!;
    }
};

export function useLocalEventState<S>(
    key: keyof LocalEvent,
    initialState: S | (() => S),
    options: IUseLocalEventStateOptions<S> = {}
): [S, React.Dispatch<S>] {
    const { localEvent, updateLocalEvent } = useEventContext();
    const [state, _setState] = React.useState(
        typeof options.deserialize === 'function'
            ? options.deserialize(localEvent?.[key] as any) || initialState
            : ((localEvent?.[key] as S) ?? initialState)
    );
    const setState = React.useCallback(
        async (newState: S) => {
            _setState(newState);
            await updateLocalEvent({
                [key]:
                    typeof options.serialize === 'function' ? options.serialize(newState) : newState
            });
        },
        [_setState, updateLocalEvent]
    );

    return [state, setState];
}
