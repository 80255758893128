import { useHistory, useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { UserSheetMobile } from '../../../users/mobile/show/userSheetMobile';

export const VolunteersUserSheetMobile = () => {
    const { organizationId, eventId, segmentId, userInfoId } = useParams();
    const history = useHistory();
    const onClose = () => {
        history.push(HeaventPaths.VOLUNTEERS_SEGMENT(organizationId, eventId, segmentId));
    };
    const basePath = HeaventPaths.VOLUNTEERS_LIST_USER(
        organizationId,
        eventId,
        segmentId,
        userInfoId
    );

    return <UserSheetMobile basePath={basePath} onClose={onClose} />;
};
