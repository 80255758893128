import { UserField } from 'common-front/src/components/users/show/userField';
import { ButtonDropdown } from 'common-front/src/designSystem/components/buttonDropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { OrderRightPanel } from 'common-front/src/designSystem/components/orderRightPanel';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import {
    CustomFieldSlug,
    CustomFieldWithConditionFragment,
    FormId,
    UserInfoOverlayFragment
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { shouldDisplay } from 'common/src/vo/field';
import { sortBy } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';

interface IUserInformationsPrivateFieldsProps {
    customFields: CustomFieldWithConditionFragment[];
    profilePrivateFields: CustomFieldSlug[];
    userInfo: UserInfoOverlayFragment;
    insertedAt: DateTime;

    getEditUserFormPath(formId: FormId | 'all' | 'private'): string;
    profilePrivateFieldsUpdate(profilePrivateFields: CustomFieldSlug[]): Promise<any>;
    reload(): void;
}

export const UserInformationsPrivateFields = (props: IUserInformationsPrivateFieldsProps) => {
    const { history, translate } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const [isOrderPrivateFieldsOpen, setIsOrderPrivateFieldsOpen] = React.useState(false);
    const privateCustomFields = React.useMemo(
        () => props.customFields.filter((c) => c.isPrivate),
        [props.customFields]
    );
    const profilePrivateFields = React.useMemo(
        () =>
            isNonEmptyArray(props.profilePrivateFields)
                ? props.profilePrivateFields
                : privateCustomFields.map((cf) => cf.slug),
        [privateCustomFields, props.profilePrivateFields]
    );
    const displayedPrivateFields = React.useMemo(
        () =>
            sortBy(
                privateCustomFields.filter(
                    (cf) =>
                        profilePrivateFields.includes(cf.slug) &&
                        shouldDisplay(cf, props.userInfo.fields, props.customFields)
                ),
                ({ slug }) => profilePrivateFields.indexOf(slug)
            ),
        [props.customFields, privateCustomFields, profilePrivateFields]
    );
    const orderOnSave = React.useCallback(
        async (profilePrivateFields: CustomFieldSlug[]) => {
            await props.profilePrivateFieldsUpdate(profilePrivateFields);

            props.reload();
        },
        [props.profilePrivateFieldsUpdate, props.reload]
    );

    return (
        <>
            <Flex
                css={{
                    background: 'White',
                    bd: true,
                    padding: '$5 $6'
                }}
                direction="column"
                gap="3"
            >
                <Flex align="center" gap="2">
                    <I icon="lock-keyhole" />

                    <Box font="gray900 textMd medium">{translate('informations_pr_15873')}</Box>
                </Flex>

                {displayedPrivateFields.map((customField) => (
                    <UserField
                        key={customField.slug}
                        field={customField}
                        fields={props.userInfo.fields}
                    />
                ))}

                <Box css={{ textTransform: 'uppercase' }} font="gray500 textXs regular">
                    {translate(
                        'inscrit_le_1_12018',
                        dateTimeService.toLocaleString(
                            props.insertedAt.toLocal(),
                            LocaleFormats.DateTime
                        )
                    )}
                </Box>

                <ButtonDropdown
                    color="white"
                    menu={
                        <>
                            <ItemIcon
                                icon="gear"
                                onClick={() => {
                                    setIsOrderPrivateFieldsOpen(true);
                                }}
                            >
                                {translate('g_rer_les_champ_00431')}
                            </ItemIcon>
                        </>
                    }
                    onClick={() => history.push(props.getEditUserFormPath('private'))}
                >
                    {translate('_diter_62574')}
                </ButtonDropdown>
            </Flex>

            {isOrderPrivateFieldsOpen && (
                <OrderRightPanel
                    columns={privateCustomFields}
                    css={{ zIndex: '210' }}
                    initialSelectedColumns={profilePrivateFields}
                    saveButtonText={translate('mettre_jour_85141')}
                    searchPlaceholder={translate('rechercher_un_c_86475')}
                    subtitle={translate('personnaliser_l_69296')}
                    title={translate('gestion_des_cha_66775')}
                    onClose={() => {
                        setIsOrderPrivateFieldsOpen(false);
                    }}
                    onSave={orderOnSave}
                />
            )}
        </>
    );
};
