import {
    EventId,
    MutationCommentCreateArgs,
    MutationUserInfoSlotsUpdateArgs,
    PermissionCheck,
    UsersInfoId,
    VolunteerRegistrationState,
    VolunteersRegistrationId
} from '../../generated/types';

export type FrontendFn<T extends unknown[]> = (...args: T) => PermissionCheck;

export const canCommentFn: FrontendFn<[Omit<MutationCommentCreateArgs, 'comment'>]> = (
    ...args
) => ({
    name: 'canCommentFn',
    args
});

export const canRegistrationStateUpdateFn: FrontendFn<
    [EventId, VolunteerRegistrationState, VolunteersRegistrationId]
> = (...args) => ({
    name: 'canRegistrationStateUpdateFn',
    args
});

export const canUserInfoAssignFn: FrontendFn<[UsersInfoId]> = (...args) => ({
    name: 'canUserInfoAssignFn',
    args
});

export const canUserInfoSlotsUpdateFn: FrontendFn<
    [Omit<MutationUserInfoSlotsUpdateArgs, 'userInfoSlots'>]
> = (...args) => ({
    name: 'canUserInfoSlotsUpdateFn',
    args
});
