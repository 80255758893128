import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { RichText } from '../../../designSystem/components/richEditor/richText';
import { Spacer } from '../../../designSystem/components/spacer';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment, EventId } from '../../../generated/types';
import { googleMapsHref } from '../../../util/googleMaps';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';
import { getBarcodeHref } from '../../../util/url';
import { hasAddress, hasAddressOrCoordinates, hasCoordinates } from '../../../vo/position';

// 3376: Reims Champagne Run 2024
// 3827: Corrida de Noël d'Issy-Les-Moulineaux 2024
// 3786: Les Grandes Courses de Saint-Denis 2024
// 3823: Semi-Marathon de Boulogne-Billancourt 2024
// 3808: Toulouse Métropole Run Experience 2024
// 5233 : Unicef 2025
// 5158 : Aix Marseille 2025
// 5644-5341 : Montpellier Run festival

const getBackground = (eventId: EventId) => {
    if (eventId === 3376) {
        return 'https://assets.recrewteer.com/badges/playground/fiche_reims_v2.jpg';
    } else if (eventId === 3827) {
        return 'https://assets.recrewteer.com/badges/playground/fiche_corrida.jpg';
    } else if (eventId === 3823) {
        return 'https://assets.recrewteer.com/badges/playground/fiche_semi_boulogne.jpg';
    } else if (eventId === 3808) {
        return 'https://assets.recrewteer.com/badges/playground/fiche_toulouse.jpg';
    } else if (eventId === 5233) {
        return 'https://assets.recrewteer.com/badges/playground/fiche_unicef.jpg';
    } else if (eventId === 5158) {
        return 'https://assets.recrewteer.com/badges/playground/fiche_marseille.jpg';
    } else if (eventId === 5644 || eventId === 5341) {
        return 'https://assets.recrewteer.com/badges/playground/fiche_montpellier.jpg';
    } else {
        return 'https://assets.recrewteer.com/badges/playground/fiche_gcsd_v3.jpg';
    }
};

const getPositionStyle = (eventId: EventId) => {
    if (eventId === 3376) {
        return {
            color: '#c2ab62',
            fontFamily: '$movement',
            fontSize: '24px',
            fontWeight: '900',
            textTransform: 'uppercase'
        };
    } else if (eventId === 3827) {
        return {
            color: '#ec1c24',
            fontFamily: '$graphie',
            fontSize: '24px',
            fontWeight: '800',
            textTransform: 'uppercase'
        };
    } else if (eventId === 3823) {
        return {
            color: '#ff9900',
            fontFamily: '$archerBoldPro',
            fontSize: '24px',
            fontWeight: '700',
            textTransform: 'uppercase'
        };
    } else if (eventId === 3808) {
        return {
            color: '#f39fc5',
            fontFamily: '$helveticaNeue97BlackCondensed',
            fontSize: '24px',
            fontWeight: '900',
            textTransform: 'uppercase'
        };
    } else if (eventId === 5233) {
        return {
            color: '#1eaae3',
            fontFamily: '$roboto',
            fontSize: '24px',
            fontWeight: '800',
            textTransform: 'uppercase'
        };
    } else if (eventId === 5158) {
        return {
            color: '#b3cc1b',
            fontFamily: '$acumin',
            fontSize: '24px',
            fontWeight: '700',
            textTransform: 'uppercase'
        };
    } else if (eventId === 5644 || eventId === 5341) {
        return {
            color: '#662483',
            fontFamily: '$chillax',
            fontSize: '24px',
            fontWeight: '700',
            textTransform: 'uppercase'
        };
    } else {
        return {
            color: '#bfbe00',
            fontFamily: '$atrament',
            fontSize: '24px',
            fontWeight: '700',
            textTransform: 'uppercase'
        };
    }
};

const getTitleStyle = (eventId: EventId) => {
    if (eventId === 3376) {
        return {
            color: '#2b2378',
            fontFamily: '$movement',
            fontSize: '18px',
            fontWeight: '900',
            textTransform: 'uppercase'
        };
    } else if (eventId === 3827) {
        return {
            color: '#434343',
            fontFamily: '$graphie',
            fontSize: '16px',
            fontWeight: '800',
            textTransform: 'uppercase'
        };
    } else if (eventId === 3823) {
        return {
            color: '#1d2b71',
            fontFamily: '$archerBoldPro',
            fontSize: '18px',
            fontWeight: '700',
            textTransform: 'uppercase'
        };
    } else if (eventId === 3808) {
        return {
            color: '#161b4a',
            fontFamily: '$helveticaNeue97BlackCondensed',
            fontSize: '18px',
            fontWeight: '900',
            textTransform: 'uppercase'
        };
    } else if (eventId === 5233) {
        return {
            color: '#434343',
            fontFamily: '$roboto',
            fontSize: '18px',
            fontWeight: '800',
            textTransform: 'uppercase'
        };
    } else if (eventId === 5158) {
        return {
            color: '#161b4a',
            fontFamily: '$acumin',
            fontSize: '18px',
            fontWeight: '700',
            textTransform: 'uppercase'
        };
    } else if (eventId === 5644 || eventId === 5341) {
        return {
            color: '#161b4a',
            fontFamily: '$chillax',
            fontSize: '18px',
            fontWeight: '700',
            textTransform: 'uppercase'
        };
    } else {
        return {
            color: 'black',
            fontFamily: '$atrament',
            fontSize: '18px',
            fontWeight: '700',
            textTransform: 'uppercase'
        };
    }
};

const getQrCodeStyle = (eventId: EventId) => {
    if (eventId === 3827 || eventId === 5233) {
        return { top: '859px' };
    } else if (eventId === 5158) {
        return { top: '746px' };
    } else if (eventId === 5644 || eventId === 5341) {
        return { top: '744px' };
    } else {
        return { top: '915px' };
    }
};

interface IPlaygroundAssignmentPageProps {
    eventId: EventId;
    positionSlotUserInfo: DocumentUserCustomBadgeFragment['volunteerRegistration']['positionsSlotsUsersInfos'][0];
    vr: DocumentUserCustomBadgeFragment['volunteerRegistration'];
}

export const PlaygroundAssignmentPage = (props: IPlaygroundAssignmentPageProps) => {
    const ui = props.vr.userInfo;
    const position = props.positionSlotUserInfo.position;
    const slot = props.positionSlotUserInfo.positionSlot;
    const leader0 = position.leaders[0];
    const leader1 = position.leaders[1];
    const titleStyle = getTitleStyle(props.eventId);

    return (
        <Flex
            css={{
                background: `url(${getBackground(props.eventId)}) no-repeat`,
                backgroundSize: 'cover',
                color: '#333333',
                position: 'relative'
            }}
            direction="column"
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
        >
            <Box
                css={{
                    color: props.eventId === 5233 ? 'white' : '#333333',
                    fontSize: '22px',
                    fontWeight: '700',
                    position: 'absolute',
                    right: '40px',
                    textTransform: 'uppercase',
                    top: '136px'
                }}
            >
                {ui.name}
            </Box>

            <Flex
                css={{
                    left: '210px',
                    padding: '$7 $8 0 $8',
                    position: 'absolute',
                    top: '170px'
                }}
                direction="column"
                width={584}
            >
                <Box css={getPositionStyle(props.eventId)} textAlign="center" width={1}>
                    {position.name}
                </Box>

                <Spacer height="7" />

                <Box css={titleStyle}>Détails de la mission :</Box>

                <Spacer height="2" />

                {isNonEmptyString(position.description) && (
                    <Box
                        css={{
                            '& .h-rich-text > p': {
                                margin: '0'
                            }
                        }}
                    >
                        <RichText text={position.description} />
                    </Box>
                )}

                {hasAddressOrCoordinates(position) && (
                    <>
                        <Spacer height="6" />

                        <Box css={titleStyle}>Lieu de mission :</Box>

                        {hasAddress(position) && (
                            <>
                                <Spacer height="2" />

                                <Box>{position.address}</Box>
                            </>
                        )}

                        {hasCoordinates(position) && (
                            <>
                                <Spacer height="2" />

                                <Box height={180} width={1}>
                                    <a
                                        href={googleMapsHref(
                                            position.latitude!,
                                            position.longitude!
                                        )}
                                        style={{
                                            background: `url(${CommonEnvVars.HEAVENT_API_URL}/image/event/${props.eventId}/position/${position.id}.png)`,
                                            backgroundPosition: 'center',
                                            backgroundSize: 'cover',
                                            display: 'block',
                                            height: '100%',
                                            width: '100%'
                                        }}
                                    />
                                </Box>
                            </>
                        )}
                    </>
                )}

                <Spacer height="6" />

                <Box css={titleStyle}>À cette heure ci :</Box>

                <Spacer height="2" />

                <Flex css={{ gap: '22px' }} width={1}>
                    <Flex css={{ flex: '1' }} direction="column" gap="1">
                        <Box css={{ fontWeight: '700' }}>
                            {slot.range.start!.toFormat('dd MMMM yyyy', { locale: 'fr' })}
                        </Box>

                        <Box>
                            De {slot.range.start!.toFormat(`H'h'mm`)} à{' '}
                            {slot.range.end!.toFormat(`H'h'mm`)}
                        </Box>
                    </Flex>

                    {leader0 && (
                        <Flex css={{ flex: '1' }} direction="column" gap="1">
                            <Box css={{ fontWeight: '700' }}>Mon contact d&apos;urgence</Box>

                            <Box>
                                {leader0.userInfo.firstName} :{' '}
                                {leader0.userInfo.phone?.internationalFormat}
                            </Box>
                            {leader1 && (
                                <Box>
                                    {leader1.userInfo.firstName} :{' '}
                                    {leader1.userInfo.phone?.internationalFormat}
                                </Box>
                            )}
                        </Flex>
                    )}
                </Flex>

                <Spacer height="6" />

                {isNonEmptyString(position.fields.cf38780) && (
                    <>
                        <Box css={titleStyle}>Transports</Box>

                        <Spacer height="2" />

                        <Box
                            dangerouslySetInnerHTML={{
                                __html: position.fields.cf38780.split('\n').join('<br />')
                            }}
                        />
                    </>
                )}
            </Flex>

            <Box
                css={{
                    left: '53px',
                    position: 'absolute',
                    ...getQrCodeStyle(props.eventId)
                }}
                height={104}
                width={104}
            >
                <img
                    height="100%"
                    src={getBarcodeHref(props.vr.ticketId, {
                        height: 104,
                        width: 104
                    })}
                    width="100%"
                />
            </Box>
        </Flex>
    );
};
