import { injectable } from 'inversify';
import { sortBy } from 'lodash-es';
import { DateTime } from 'luxon';
import {
    ALL_ACCREDITATIONSTATE,
    ALL_CHECKINSTATE,
    ALL_EMAILSTATE,
    ALL_VOLUNTEERREGISTRATIONSTATE,
    CustomFieldWithConditionFragment,
    EmailState,
    EventDelegationMembersInfosQuery,
    EventId,
    FilterType,
    OrganizationId,
    RegisterPositionDisplay,
    RegisterSlotDisplay,
    SegmentCustomFieldFragment,
    SegmentId,
    VolunteersRegistrationsSortAttributes,
    VolunteersSegmentsQuery
} from '../../generated/types';
import { CountriesService } from '../../services/countriesService';
import { DateTimeService } from '../../services/dateTimeService';
import { TranslationService } from '../../services/translationService';
import { Filter, PossibleColumn, SegmentService } from '../segment';

interface IGetVolunteersFiltersOptions {
    hasAccreditations: boolean;
    hasDelegations: boolean;
}

type FiltersEventDates = {
    startAt: DateTime;
    endAt: DateTime;
};

export enum VolunteerDefaultColumns {
    AccreditationState = 'accreditation_state',
    Accreditations = 'accreditations',
    AccreditationsCategories = 'accreditations_categories',
    AccreditationsSlots = 'accreditations_slots',
    Availability = 'availability',
    Comments = 'comments',
    CompletedForms = 'forms',
    Delegations = 'delegations',
    Email = 'email',
    FormsInsertedAt = 'forms_inserted_at',
    Id = 'id',
    InsertedAt = 'inserted_at',
    Name = 'name',
    NumberOfAccreditations = 'number_of_accreditations',
    NumberOfAssignments = 'number_of_assignments',
    NumberOfAssignmentsDays = 'number_of_assignments_days',
    NumberOfAvailableDays = 'number_of_available_days',
    Positions = 'positions',
    PositionsCategories = 'positions_categories',
    PreassignedPositions = 'preassigned_positions',
    PreassignedPositionsCategories = 'preassigned_positions_categories',
    TeamCode = 'teamCode',
    TeamMembers = 'teamMembers',
    VolunteerMinutes = 'volunteer_minutes',
    VolunteerRegistrationState = 'volunteer_registration_state',
    WeezeventBarcodeId = 'weezevent_barcode_id',
    WeezeventShortTag = 'weezevent_short_tag',
    WishedAccreditationSlots = 'wished_accreditation_slots',
    WishedPositions = 'wished_positions'
}

export function columnsToIncludes(columns: string[]) {
    return {
        includeAccreditations:
            columns.includes(VolunteerDefaultColumns.Accreditations) ||
            columns.includes(VolunteerDefaultColumns.AccreditationsCategories) ||
            columns.includes(VolunteerDefaultColumns.AccreditationsSlots) ||
            columns.includes(VolunteerDefaultColumns.WishedAccreditationSlots),
        includeAvailability: columns.includes(VolunteerDefaultColumns.Availability),
        includeComments: columns.includes(VolunteerDefaultColumns.Comments),
        includeForms:
            columns.includes(VolunteerDefaultColumns.CompletedForms) ||
            columns.includes(VolunteerDefaultColumns.FormsInsertedAt) ||
            columns.some((c) => c.startsWith('form_inserted_at')) ||
            columns.includes(VolunteerDefaultColumns.Availability),
        includeDelegation: columns.includes(VolunteerDefaultColumns.Delegations),
        includeNumberOfAccreditations: columns.includes(
            VolunteerDefaultColumns.NumberOfAccreditations
        ),
        includeNumberOfAssignments: columns.includes(VolunteerDefaultColumns.NumberOfAssignments),
        includeNumberOfAssignmentsDays: columns.includes(
            VolunteerDefaultColumns.NumberOfAssignmentsDays
        ),
        includeNumberOfAvailableDays: columns.includes(
            VolunteerDefaultColumns.NumberOfAvailableDays
        ),
        includePositions:
            columns.includes(VolunteerDefaultColumns.Positions) ||
            columns.includes(VolunteerDefaultColumns.PositionsCategories) ||
            columns.includes(VolunteerDefaultColumns.WishedPositions),
        includeTeam:
            columns.includes(VolunteerDefaultColumns.TeamCode) ||
            columns.includes(VolunteerDefaultColumns.TeamMembers),
        includeVolunteerMinutes: columns.includes(VolunteerDefaultColumns.VolunteerMinutes),
        includeWishedPositions: columns.includes(VolunteerDefaultColumns.WishedPositions),
        includePreassignedPositions:
            columns.includes(VolunteerDefaultColumns.PreassignedPositionsCategories) ||
            columns.includes(VolunteerDefaultColumns.PreassignedPositions),
        includeWeezevent: columns.includes(VolunteerDefaultColumns.WeezeventBarcodeId)
    };
}

@injectable()
export class VolunteersSegmentsService extends SegmentService {
    constructor(
        countriesService: CountriesService,
        dateTimeService: DateTimeService,
        translationService: TranslationService
    ) {
        super(countriesService, dateTimeService, translationService);
    }

    getVolunteersFilters(
        organizationId: OrganizationId,
        eventId: EventId,
        currentSegmentId: SegmentId,
        customFields: SegmentCustomFieldFragment[],
        eventInfos: VolunteersSegmentsQuery['event'],
        { hasAccreditations, hasDelegations }: IGetVolunteersFiltersOptions
    ): Filter[] {
        return [
            ...this.getBaseFilters(customFields),
            ...this.getAvailabilitiesFilters(eventId, eventInfos),
            ...this.getPositionsFilters(eventId, eventInfos),
            ...(hasAccreditations ? this.getAccreditationsFilters(eventId, eventInfos) : []),
            ...(hasDelegations ? this.getDelegationsFilters(eventId) : []),
            ...this.getSegmentsFilters(organizationId, eventId, currentSegmentId, eventInfos),
            ...this.getFormsFilters(eventId),
            ...this.getTeamsFilters(),
            ...this.getWeezeventFilters(),
            ...this.getEmailsFilters(eventId),
            {
                slug: 'isLeader',
                name: this.t('est_responsable_19679'),
                category: this.t('autres_99130'),
                fieldType: FilterType.Checkbox
            },
            {
                slug: 'isPositionLeader',
                name: this.t('est_responsable_27270'),
                category: this.t('autres_99130'),
                fieldType: FilterType.Checkbox
            },
            {
                slug: 'isPositionCategoryLeader',
                name: this.t('est_responsable_72923'),
                category: this.t('autres_99130'),
                fieldType: FilterType.Checkbox
            },
            {
                slug: 'comments',
                name: this.t('commentaires_96492'),
                category: this.t('autres_99130'),
                fieldType: FilterType.Text
            }
        ];
    }

    getPositionMembersFilters(
        eventId: EventId,
        customFields: SegmentCustomFieldFragment[],
        eventDates: FiltersEventDates
    ): Filter[] {
        return [
            ...this.getBaseFilters(customFields),
            ...this.getAvailabilitiesFilters(eventId, eventDates),
            ...this.getPositionsFilters(eventId, eventDates)
        ];
    }

    getAccreditationMembersFilters(
        eventId: EventId,
        customFields: SegmentCustomFieldFragment[],
        eventInfos: VolunteersSegmentsQuery['event']
    ): Filter[] {
        return [
            ...this.getBaseFilters(customFields),
            ...this.getAccreditationsFilters(eventId, eventInfos)
        ];
    }

    getDelegationMembersFilters(
        eventId: EventId,
        customFields: SegmentCustomFieldFragment[],
        eventDates: FiltersEventDates,
        form: EventDelegationMembersInfosQuery['event']['delegation']['form']
    ): Filter[] {
        return [
            ...this.getBaseFilters(customFields),
            ...this.getAccreditationsFilters(eventId, eventDates),
            ...(form.positionDisplay === RegisterPositionDisplay.None
                ? []
                : this.getPositionMembersFilters(eventId, customFields, eventDates))
        ];
    }

    getVolunteersPossibleColumns(
        customFields: SegmentCustomFieldFragment[] | CustomFieldWithConditionFragment[],
        forms: VolunteersSegmentsQuery['event']['forms'],
        hasAccreditations: boolean
    ): PossibleColumn[] {
        return [
            ...this.getBaseColumns(customFields),
            ...this.getFormsColumns(forms),
            ...this.getPositionsColumns(),
            ...this.getWeezeventColumns(),
            ...(hasAccreditations ? this.getAccreditationsColumns() : []),
            ...this.getAvailabilitiesColumns(),
            {
                slug: VolunteerDefaultColumns.Comments,
                name: this.t('commentaires_96492'),
                isSearchable: true
            },
            {
                slug: VolunteerDefaultColumns.Delegations,
                name: this.t('Delegation'),
                isSearchable: true
            }
        ];
    }

    getPositionMembersPossibleColumns(
        customFields: SegmentCustomFieldFragment[] | CustomFieldWithConditionFragment[]
    ): PossibleColumn[] {
        return [...this.getBaseColumns(customFields), ...this.getPositionsColumns()];
    }

    getAccreditationMembersPossibleColumns(
        customFields: SegmentCustomFieldFragment[] | CustomFieldWithConditionFragment[]
    ): PossibleColumn[] {
        return [...this.getBaseColumns(customFields), ...this.getAccreditationsColumns()];
    }

    getDelegationMembersPossibleColumns(
        customFields: SegmentCustomFieldFragment[],
        form: EventDelegationMembersInfosQuery['event']['delegation']['form']
    ): PossibleColumn[] {
        return [
            ...this.getBaseColumns(customFields),
            ...this.getAccreditationsColumns(),
            ...(form.positionDisplay === RegisterPositionDisplay.None
                ? []
                : this.getPositionsColumns()),
            ...(form.slotDisplay === RegisterSlotDisplay.Hide
                ? []
                : this.getAvailabilitiesColumns())
        ];
    }

    private getBaseColumns(
        customFields: SegmentCustomFieldFragment[] | CustomFieldWithConditionFragment[]
    ): PossibleColumn[] {
        return [
            {
                slug: VolunteerDefaultColumns.Email,
                name: this.t('e_mail_du_membr_69509'),
                isSearchable: true
            },
            {
                slug: VolunteerDefaultColumns.Id,
                name: this.t('id_51738'),
                isSearchable: true,
                isSortable: true,
                sortAttribute: VolunteersRegistrationsSortAttributes.Id,
                width: 100
            },
            {
                slug: VolunteerDefaultColumns.Name,
                name: this.t('full_name'),
                isSearchable: true,
                isSortable: true,
                sortAttribute: VolunteersRegistrationsSortAttributes.Name
            },
            {
                slug: VolunteerDefaultColumns.InsertedAt,
                name: this.t('date_d_inscript_31369'),
                isSortable: true,
                sortAttribute: VolunteersRegistrationsSortAttributes.InsertedAt,
                width: 230
            },
            ...this.customFieldsToPossibleColumns(customFields)
        ];
    }

    private getAvailabilitiesColumns() {
        return [
            {
                slug: VolunteerDefaultColumns.Availability,
                name: this.t('disponibilit_s_49923')
            }
        ];
    }

    private getFormsColumns(forms: VolunteersSegmentsQuery['event']['forms']): PossibleColumn[] {
        return [
            {
                slug: VolunteerDefaultColumns.CompletedForms,
                name: this.t('formulaires_rem_62495')
            },
            {
                slug: VolunteerDefaultColumns.FormsInsertedAt,
                name: this.t('dates_de_rempli_65075')
            },
            ...sortBy(forms, (f) => f.name).map((f) => ({
                slug: `form_inserted_at_${f.id}`,
                name: this.t('date_de_remplis_00881', f.name),
                isSortable: true,
                sortAttribute: `form_inserted_at_${f.id}`,
                width: 230
            })),
            {
                slug: VolunteerDefaultColumns.TeamCode,
                name: this.t('code_quipe_14296'),
                isSearchable: true,
                isSortable: true,
                sortAttribute: VolunteersRegistrationsSortAttributes.TeamCode
            },
            {
                slug: VolunteerDefaultColumns.TeamMembers,
                name: this.t('membres_de_l_q_00891')
            }
        ];
    }

    private getPositionsColumns(): PossibleColumn[] {
        return [
            {
                slug: VolunteerDefaultColumns.NumberOfAssignments,
                name: this.t('nombre_d_affect_31455'),
                width: 230
            },
            {
                slug: VolunteerDefaultColumns.NumberOfAssignmentsDays,
                name: this.t('nombre_de_jours_86280'),
                width: 230
            },
            {
                slug: VolunteerDefaultColumns.NumberOfAvailableDays,
                name: this.t('nombre_de_jours_07962'),
                width: 230
            },
            {
                slug: VolunteerDefaultColumns.Positions,
                name: this.t('missions_affect_97811')
            },
            {
                slug: VolunteerDefaultColumns.PositionsCategories,
                name: this.t('cat_gories_de_m_93035')
            },
            {
                slug: VolunteerDefaultColumns.VolunteerMinutes,
                name: this.t('nombre_de_minut_50497'),
                width: 230
            },
            {
                slug: VolunteerDefaultColumns.VolunteerRegistrationState,
                name: this.t('status_d_affect_75769'),
                isSortable: true,
                sortAttribute: VolunteersRegistrationsSortAttributes.State,
                width: 230
            },
            {
                slug: VolunteerDefaultColumns.WishedPositions,
                name: this.t('missions_souhai_23225')
            },
            {
                slug: VolunteerDefaultColumns.PreassignedPositions,
                name: this.t('missions_pr_af_02950')
            },
            {
                slug: VolunteerDefaultColumns.PreassignedPositionsCategories,
                name: this.t('cat_gories_pr_83825')
            }
        ];
    }

    private getAccreditationsColumns(): PossibleColumn[] {
        return [
            {
                slug: VolunteerDefaultColumns.Accreditations,
                name: this.t('accr_ditations_33155')
            },
            {
                slug: VolunteerDefaultColumns.AccreditationsCategories,
                name: this.t('cat_gories_d_ac_37678')
            },
            {
                slug: VolunteerDefaultColumns.AccreditationState,
                name: this.t('statut_d_accr_d_75527'),
                isSortable: true,
                sortAttribute: VolunteersRegistrationsSortAttributes.AccreditationState,
                width: 240
            },
            {
                slug: VolunteerDefaultColumns.NumberOfAccreditations,
                name: this.t('nombre_d_accr_d_02452'),
                width: 230
            },
            {
                slug: VolunteerDefaultColumns.WishedAccreditationSlots,
                name: this.t('accr_ditations_74375')
            }
        ];
    }

    private getWeezeventColumns(): PossibleColumn[] {
        return [
            {
                slug: VolunteerDefaultColumns.WeezeventBarcodeId,
                name: this.t('code_barres_60437'),
                isSearchable: true
            },
            {
                slug: VolunteerDefaultColumns.WeezeventShortTag,
                name: this.t('puce_50504'),
                isSearchable: true
            }
        ];
    }

    private getBaseFilters(customFields: SegmentCustomFieldFragment[]): Filter[] {
        return [
            {
                slug: 'email',
                name: this.t('Email'),
                category: this.t('champs_de_formu_64126'),
                fieldType: FilterType.Text,
                hideEmpty: true
            },
            {
                slug: 'insertedAt',
                name: this.t('date_d_inscript_31369'),
                category: this.t('champs_de_formu_64126'),
                fieldType: FilterType.Date,
                hideEmpty: true
            },
            ...this.customFieldsToFilters(customFields)
        ];
    }

    private getAvailabilitiesFilters(eventId: EventId, eventDates: FiltersEventDates): Filter[] {
        return [
            {
                slug: 'numberOfAvailableDays',
                name: this.t('nombre_de_jours_07962'),
                category: this.t('disponibilit_s_49923'),
                fieldType: FilterType.Number,
                hideEmpty: true
            },
            {
                slug: 'wishedSlots',
                name: this.t('disponibilit_s_49923'),
                category: this.t('disponibilit_s_49923'),
                fieldType: FilterType.DateTimeRange,
                startAt: eventDates.startAt,
                endAt: eventDates.endAt
            },
            {
                slug: 'wishedSlotsCustom',
                name: this.t('disponibilit_s_26280'),
                category: this.t('disponibilit_s_49923'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                slug: 'numberOfWishedSlots',
                name: this.t('nombre_de_dispo_26405'),
                category: this.t('disponibilit_s_49923'),
                fieldType: FilterType.Number,
                hideEmpty: true
            }
        ];
    }

    private getPositionsFilters(eventId: EventId, eventDates: FiltersEventDates): Filter[] {
        return [
            {
                slug: 'volunteerRegistrationState',
                name: this.t('statut_d_affect_57444'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Select,
                values: ALL_VOLUNTEERREGISTRATIONSTATE.map((state) => ({
                    id: state,
                    name: state
                })),
                hideEmpty: true
            },
            {
                slug: 'position',
                name: this.t('missions_affect_97811'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                slug: 'positionSlotId',
                name: this.t('cr_neau_affect_50400_plural'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                slug: 'positionSlot',
                name: this.t('date_d_affectat_98566'),
                category: this.t('missions_63972'),
                fieldType: FilterType.DateTimeRange,
                startAt: eventDates.startAt,
                endAt: eventDates.endAt
            },
            {
                slug: 'positionCategory',
                name: this.t('cat_gories_de_m_93035'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                slug: 'numberOfAssignments',
                name: this.t('nombre_d_affect_31455'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Number,
                hideEmpty: true
            },
            {
                slug: 'numberOfAssignmentsDays',
                name: this.t('nombre_de_jours_86280'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Number,
                hideEmpty: true
            },
            {
                slug: 'preassignedPosition',
                name: this.t('missions_pr_af_02950'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                slug: 'preassignedPositionCategory',
                name: this.t('cat_gories_pr_83825'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                slug: 'wishedPositionsCategories',
                name: this.t('cat_gories_souh_80851'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                slug: 'numberOfWishedPositionsCategories',
                name: this.t('nombre_de_cat_g_05961'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Number,
                hideEmpty: true
            },
            {
                slug: 'wishedPositions',
                name: this.t('missions_souhai_23225'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                slug: 'numberOfWishedPositions',
                name: this.t('nombre_de_missi_52579'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Number,
                hideEmpty: true
            },
            {
                slug: 'wishedPositionsSlots',
                name: this.t('cr_neaux_souhai_90108'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                slug: 'numberOfWishedPositionsSlots',
                name: this.t('nombre_de_cr_ne_28370'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Number,
                hideEmpty: true
            },
            {
                slug: 'volunteersMinutes',
                name: this.t('nombre_de_minut_50497'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Number,
                hideEmpty: true
            },
            {
                slug: 'checkInAt',
                name: this.t('point_le_93167'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Date
            },
            {
                slug: 'checkOutAt',
                name: this.t('d_point_le_29289'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Date
            },
            {
                slug: 'checkInState',
                name: this.t('statut_de_point_44328'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Select,
                values: ALL_CHECKINSTATE.map((checkinState) => ({
                    id: checkinState,
                    name: this.t(checkinState)
                })),
                hideEmpty: true
            },
            {
                slug: 'overlappingAssignments',
                name: `Affectations se chevauchant`,
                category: this.t('missions_63972'),
                fieldType: FilterType.Checkbox,
                hideEmpty: true
            }
        ];
    }

    private getAccreditationsFilters(eventId: EventId, eventDates: FiltersEventDates): Filter[] {
        return [
            {
                slug: 'accreditationState',
                name: this.t('statut_d_accr_d_75527'),
                category: this.t('accr_ditations_39450'),
                fieldType: FilterType.Select,
                values: ALL_ACCREDITATIONSTATE.map((state) => ({
                    id: state,
                    name: state
                })),
                hideEmpty: true
            },
            {
                slug: 'numberOfAccreditations',
                name: this.t('nombre_d_accr_d_02452'),
                category: this.t('accr_ditations_39450'),
                fieldType: FilterType.Number,
                hideEmpty: true
            },
            {
                slug: 'accreditation',
                name: this.t('accr_ditations_39450'),
                category: this.t('accr_ditations_39450'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                slug: 'accreditationCategory',
                name: this.t('cat_gories_d_ac_37678'),
                category: this.t('accr_ditations_39450'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                slug: 'wishedAccreditationsSlots',
                name: this.t('accr_ditations_74375'),
                category: this.t('accr_ditations_39450'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                slug: 'numberOfWishedAccreditationsSlots',
                name: this.t('nombre_d_accr_d_92021'),
                category: this.t('accr_ditations_39450'),
                fieldType: FilterType.Number,
                hideEmpty: true
            },
            {
                slug: 'accreditationSlotId',
                name: this.t('cr_neaux_accr_d_70934'),
                category: this.t('accr_ditations_39450'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                slug: 'accreditationSlot',
                name: this.t('date_d_accr_dit_26214'),
                category: this.t('accr_ditations_39450'),
                fieldType: FilterType.Date,
                startAt: eventDates.startAt,
                endAt: eventDates.endAt
            }
        ];
    }

    private getDelegationsFilters(eventId: EventId): Filter[] {
        return [
            {
                slug: 'delegation',
                name: this.t('inscrit_via_la_86398'),
                category: this.t('d_l_gations_78318'),
                fieldType: FilterType.Select,
                needLoading: true,
                eventId
            },
            {
                slug: 'isDelegationLeader',
                name: this.t('est_responsable_87662'),
                category: this.t('d_l_gations_78318'),
                fieldType: FilterType.Checkbox
            }
        ];
    }

    private getSegmentsFilters(
        organizationId: OrganizationId,
        eventId: EventId,
        currentSegmentId: SegmentId,
        eventInfos: VolunteersSegmentsQuery['event']
    ): Filter[] {
        return [
            {
                slug: 'segments',
                name: this.t('fait_partie_de_44650'),
                category: this.t('vues_personnali_80652'),
                fieldType: FilterType.Select,
                values: eventInfos.segmentsFolders
                    .flatMap((sf) => sf.segments)
                    .filter((s) => s.id !== currentSegmentId),
                hideEmpty: true
            },
            {
                slug: 'usersSegments',
                name: this.t('fait_partie_de_44893'),
                category: this.t('vues_personnali_80652'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                organizationId
            },
            {
                slug: 'positionsSegments',
                name: this.t('affecter_une_10657'),
                category: this.t('vues_personnali_80652'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                slug: 'accreditationsSegments',
                name: this.t('affecter_une_91431'),
                category: this.t('vues_personnali_80652'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                slug: 'delegationsSegments',
                name: this.t('affecter_une_45240'),
                category: this.t('vues_personnali_80652'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            }
        ];
    }

    private getFormsFilters(eventId: EventId): Filter[] {
        return [
            {
                slug: 'forms',
                name: this.t('formulaires_rem_62495'),
                category: this.t('Form_plural'),
                fieldType: FilterType.Select,
                needLoading: true,
                eventId
            },
            {
                slug: 'formsInsertedAt',
                name: this.t('dates_de_rempli_65075'),
                category: this.t('Form_plural'),
                fieldType: FilterType.Date,
                hideEmpty: true,
                needLoading: true,
                eventId
            }
        ];
    }

    private getTeamsFilters(): Filter[] {
        return [
            {
                slug: 'isTeam',
                name: this.t('est_inscrit_en_86193'),
                category: this.t('_quipes_18817'),
                fieldType: FilterType.Checkbox,
                hideEmpty: true
            },
            {
                slug: 'teamCode',
                name: this.t('code_quipe_14296'),
                category: this.t('_quipes_18817'),
                fieldType: FilterType.Text
            },
            {
                slug: 'numberOfTeamMembers',
                name: this.t('nombre_de_membr_82302'),
                category: this.t('_quipes_18817'),
                fieldType: FilterType.Number
            }
        ];
    }

    private getWeezeventFilters(): Filter[] {
        return [
            {
                slug: VolunteerDefaultColumns.WeezeventBarcodeId,
                name: this.t('code_barres_60437'),
                category: 'Weezevent',
                fieldType: FilterType.Text,
                hideEmpty: true
            },
            {
                slug: VolunteerDefaultColumns.WeezeventShortTag,
                name: this.t('puce_50504'),
                category: 'Weezevent',
                fieldType: FilterType.Text
            }
        ];
    }

    private getEmailsFilters(eventId: EventId): Filter[] {
        return [
            {
                slug: 'campaign',
                name: this.t('campagne_97871'),
                category: this.t('e_mails_09850'),
                fieldType: FilterType.Campaign,
                needLoading: true,
                eventId
            },
            {
                slug: 'assignmentEmailSentAt',
                name: this.t('date_d_envoi_de_01614'),
                category: this.t('e_mails_09850'),
                fieldType: FilterType.Date
            },
            {
                slug: 'assignmentEmailState',
                name: this.t('statut_de_de_l_36346'),
                category: this.t('e_mails_09850'),
                fieldType: FilterType.Select,
                values: ALL_EMAILSTATE.filter((s) => s !== EmailState.Queued).map((emailState) => ({
                    id: emailState,
                    name: this.t(emailState)
                })),
                hideEmpty: true
            },
            {
                slug: 'accreditationEmailState',
                name: this.t('statut_de_l_e_m_97776'),
                category: this.t('e_mails_09850'),
                fieldType: FilterType.Select,
                values: ALL_EMAILSTATE.filter((s) => s !== EmailState.Queued).map((emailState) => ({
                    id: emailState,
                    name: this.t(emailState)
                })),
                hideEmpty: true
            },
            {
                slug: 'emailBlacklist',
                name: this.t('ne_souhaite_plu_08853'),
                category: this.t('e_mails_09850'),
                fieldType: FilterType.Checkbox,
                hideEmpty: true
            }
        ];
    }
}
