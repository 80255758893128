import {
    AccreditationImportFragment,
    CsvDocumentInput,
    CustomFieldImportFragment,
    FormsCustomFieldInput
} from 'common/src/generated/types';
import { FormApi } from 'final-form';
import * as React from 'react';
import { useCSVHeader } from '../../volunteers/import/useCSVHeader';
import { MappingsFormBox } from './mappingsFormBox';
import { PresetsFormBox } from './presetsFormBox';

type ImportInputWithCustomFields = {
    csv: CsvDocumentInput;
    mappings: (string | null)[];
    presets: FormsCustomFieldInput[];
};

interface IMappingsFormBoxes<T> {
    accreditations: AccreditationImportFragment[];
    customFields: CustomFieldImportFragment[];
    defaultFields: Record<string, string>;
    file: File;
    form: FormApi<{ input: T }>;
    modeSelection?: React.ReactNode;
    requiredFieldSlugs: string[];
    values: { input: T };

    onToggle?(isOpen: boolean): void;
}

export function MappingsFormBoxes<T extends ImportInputWithCustomFields>({
    accreditations,
    customFields,
    defaultFields,
    file,
    form,
    modeSelection,
    onToggle,
    requiredFieldSlugs,
    values
}: IMappingsFormBoxes<T>) {
    const { isLoading, columns, errors } = useCSVHeader(file, {
        delimiter: values.input.csv.delimiter,
        quoteChar: values.input.csv.quoteChar ?? undefined,
        newline: (values.input.csv.newline as any) ?? undefined
    });

    if (isLoading || errors) {
        return null; /* Loading doesn’t take long: don’t display a loader. Errors are aldready displayed in the <FileS3Input />. */
    } else {
        return (
            <>
                <MappingsFormBox
                    accreditations={accreditations}
                    columns={columns}
                    customFields={customFields}
                    defaultFields={defaultFields}
                    modeSelection={modeSelection}
                    requiredFieldSlugs={requiredFieldSlugs}
                    values={values.input.mappings}
                />

                <PresetsFormBox
                    change={form.change}
                    customFields={customFields}
                    file={file}
                    values={values.input.presets}
                    onToggle={onToggle}
                />
            </>
        );
    }
}
