import { intersection } from 'lodash-es';
import { AccreditationsSlotId, CustomFieldSlug, PositionsSlotId } from '../generated/types';
import { isNonEmptyArray, toArray } from '../util/array';
import { A4_SIZES, A6_SIZES, CB_SIZES } from '../util/pdf';
import { isEmptyString, isNonEmptyString } from '../util/string';
import { UUID, uuidv4 } from '../util/uuid';
import { Fields } from './field';

export type DocumentConditionsPoperties = {
    accreditationsSlotsIds: AccreditationsSlotId[];
    conditionsCustomFields: Array<{ slug: CustomFieldSlug; value: any }>;
    hasConditions: boolean;
    positionsSlotsIds: PositionsSlotId[];
};

export type BaseElement = DocumentConditionsPoperties & {
    id: UUID;
    borderColor: string;
    borderRadius: number;
    borderSize: number;
    hasRotate: boolean;
    height: number;
    inContainer?: boolean;
    left: number;
    name: string;
    px: number;
    py: number;
    rotateDegree: number;
    top: number;
    width: number;
    zIndex: number;
};

export type DocumentContainerElement = BaseElement & {
    type: 'container';
    align: 'start' | 'end' | 'center';
    backgroundColor: string;
    direction: 'column' | 'row';
    elements: DocumentElement[];
    gap: number;
    hasBackground: boolean;
    justify: 'start' | 'end' | 'center';
};

export type DocumentTextElement = BaseElement & {
    type: 'text';
    text: string;
    align: 'start' | 'end' | 'center';
    backgroundColor: string;
    color: string;
    fontFamily: string;
    fontSize: number;
    fontWeight: number;
    hasBackground: boolean;
    justify: 'start' | 'end' | 'center';
    lineHeight: number;
    textTransform: 'none' | 'uppercase' | 'lowercase' | 'capitalize';
};

export type DocumentImageElement = BaseElement & {
    type: 'image';
    source?: string;
};

export type DocumentElement = DocumentContainerElement | DocumentTextElement | DocumentImageElement;

export type DocumentPage = DocumentConditionsPoperties & {
    id: UUID;
    name?: string;
    elements: DocumentElement[];
};

export type CustomDocument = {
    name: string;
    slug: string;
    populationsIds: number[];
    configuration: {
        height: number;
        pages: DocumentPage[];
        width: number;
    };
};

export function getDocumentAllElements(document: CustomDocument): DocumentElement[] {
    const getFinalElements = (elements: DocumentElement[]): DocumentElement[] =>
        elements.flatMap((element) => {
            if (element.type === 'container') {
                return [element as DocumentElement].concat(getFinalElements(element.elements));
            } else {
                return [element];
            }
        });

    return document.configuration.pages.flatMap((page) => getFinalElements(page.elements));
}

export function duplicateDocumentElement(
    element: DocumentElement,
    newId: UUID,
    newName: string
): DocumentElement {
    const newElement: DocumentElement = {
        ...element,
        id: newId,
        name: newName
    };

    if (newElement.type === 'container') {
        const newSubElements = newElement.elements.map((subElement) =>
            duplicateDocumentElement(subElement, uuidv4(), subElement.name)
        );

        return {
            ...newElement,
            elements: newSubElements
        };
    } else {
        return newElement;
    }
}

export function getFormat(height: number, width: number): 'a4' | 'a6' | 'cb' | 'custom' {
    return height === A4_SIZES['96dpi'].height && width === A4_SIZES['96dpi'].width
        ? 'a4'
        : height === A6_SIZES['96dpi'].height && width === A6_SIZES['96dpi'].width
          ? 'a6'
          : height === CB_SIZES['96dpi'].height && width === CB_SIZES['96dpi'].width
            ? 'cb'
            : 'custom';
}

export function shouldDisplayElement(
    element: DocumentConditionsPoperties,
    accreditationsSlotsIds: AccreditationsSlotId[],
    positionsSlotsIds: PositionsSlotId[],
    userInfoFields: Fields
) {
    if (element.hasConditions) {
        if (isNonEmptyArray(element.accreditationsSlotsIds)) {
            return isNonEmptyArray(
                intersection(element.accreditationsSlotsIds, accreditationsSlotsIds)
            );
        } else if (isNonEmptyArray(element.positionsSlotsIds)) {
            return isNonEmptyArray(intersection(element.positionsSlotsIds, positionsSlotsIds));
        } else if (isNonEmptyArray(element.conditionsCustomFields)) {
            return element.conditionsCustomFields.every(({ slug, value }) => {
                if (value === 'isEmpty') {
                    return isEmptyString(userInfoFields[slug]?.rawValue);
                } else if (value === 'isNotEmpty') {
                    return isNonEmptyString(userInfoFields[slug]?.rawValue);
                } else if (typeof value === 'boolean') {
                    return userInfoFields[slug]?.rawValue === value;
                } else if (Array.isArray(value)) {
                    return isNonEmptyArray(
                        intersection(toArray(userInfoFields[slug]?.rawValue), value)
                    );
                } else {
                    return false;
                }
            });
        } else {
            return false;
        }
    } else {
        return true;
    }
}
