import { useUserContext } from 'common-front/src/userContext';
import { getLanguage } from 'common-front/src/util/language';
import { heaventEnvDisplayName, heaventEnvTermsUrl } from 'common/src/heaventEnv';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

export const TermsCheckboxLabel = () => {
    const translate = useTranslate();
    const { user } = useUserContext();

    return (
        <span
            dangerouslySetInnerHTML={{
                __html: translate(
                    'accepter_a_hre_50341',
                    heaventEnvTermsUrl(
                        getLanguage(user?.language, new URL(location.href).searchParams)
                    ),
                    heaventEnvDisplayName()
                )
            }}
        />
    );
};
