import { Label } from 'common-front/src/designSystem/components/input/label';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePromise } from 'common-front/src/hooks/usePromise';
import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import * as React from 'react';
import { useDelegationsContext } from '../delegationsContext';

interface IDelegationsCategoryCellInputProps {
    value: number;

    setValue(value: number): void;
}

export const DelegationsCategoryCellInput = (props: IDelegationsCategoryCellInputProps) => {
    const { translate } = useHeavent();
    const { getDelegationsCategories } = useDelegationsContext();
    const { result: delegationsCategories, isLoading } = usePromise(getDelegationsCategories);

    return isLoading ? (
        <Flex direction="column" gap="1" width={1}>
            <Label>{translate('Category')}</Label>
            <Skeleton borderRadius="$1" height={38} width={1} />
        </Flex>
    ) : (
        <Select
            label={translate('Category')}
            shouldParseAsInt={true}
            value={props.value}
            onChange={props.setValue}
        >
            {delegationsCategories.map((category) => (
                <option key={category.id} value={category.id}>
                    {category.name}
                </option>
            ))}
        </Select>
    );
};
